import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  makeStyles,
  Typography
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  closeButton: {
    backgroundColor: '#516e76',
    color: 'white',
    '&:hover': {
      backgroundColor: '#3b545e',
    },
  },
  dialog: {
    '& .MuiDialog-paper': {
      padding: theme.spacing(2),
    },
  },
  dateField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#accacd',
      },
      '&:hover fieldset': {
        borderColor: '#779ba2',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#516e76',
      },
    },
  },
  passwordField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#accacd',
      },
      '&:hover fieldset': {
        borderColor: '#779ba2',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#516e76',
      },
    },
  },
  title: {
    color: '#516e76',
  },
  actions: {
    padding: theme.spacing(2),
  },
  cancelButton: {
    color: '#516e76',
    borderColor: '#516e76',
    '&:hover': {
      borderColor: '#3b545e',
      backgroundColor: 'rgba(81, 110, 118, 0.04)',
    },
  },
  submitButton: {
    backgroundColor: '#516e76',
    color: 'white',
    '&:hover': {
      backgroundColor: '#3b545e',
    },
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
  },
  confirmationContent: {
    padding: theme.spacing(2),
  },
}));

const CloseBooks = (props) => {
  console.log('Clsoe books', props)
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const currentClosedDate = props?.configs?.length ? props.configs[0]['booksClosedDate'] : null
  console.log('Current close date', currentClosedDate)
  const handleClickOpen = () => {
    setOpen(true);
    setPassword('');
    setConfirmPassword('');
    setPasswordError('');
  };

  const handleClose = () => {
    setOpen(false);
    setPassword('');
    setConfirmPassword('');
    setPasswordError('');
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    if (confirmPassword && event.target.value !== confirmPassword) {
      setPasswordError('Passwords do not match');
    } else {
      setPasswordError('');
    }
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    if (password && event.target.value !== password) {
      setPasswordError('Passwords do not match');
    } else {
      setPasswordError('');
    }
  };

  const handleProceedToConfirmation = () => {
    if (password === confirmPassword) {
      let newDate = new Date(selectedDate)
      let existingDate = new Date(currentClosedDate)
      let checkPastDates = false
      if (checkPastDates && newDate < existingDate) {
        props.notification({
          type: 'warning',
          title: 'Uh Oh! Are You Sure?',
          message: `We can't close the books earlier than the current close date (${moment(currentClosedDate).format('MM/DD/YYYY')}). Please choose a date later than the current Books Closed Date.`
        })
      } else {
        setOpen(false);
        setConfirmOpen(true);
      }
    }
  };

  const handleConfirmationClose = () => {
    setConfirmOpen(false);
    setPassword('');
    setConfirmPassword('');
  };

  const handleSubmit = () => {
    console.log('Closing books for date:', selectedDate, 'with password:', password);
    const obj = {
      date: selectedDate,
      password
    }
    props.updateParent(obj)
    handleConfirmationClose();

  };

  const isSubmitDisabled = !password || !confirmPassword || password !== confirmPassword;

  return (
  
      <div className={classes.root}>
        <Button
          variant="contained"
          className={classes.closeButton}
          onClick={handleClickOpen}
        >
          Close Books
        </Button>
        
        {/* Main Dialog */}
        <Dialog
          open={open}
          onClose={handleClose}
          className={classes.dialog}
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle>
            <Typography variant="h6" className={classes.title}>
              Close Books
            </Typography>
          </DialogTitle>
          
          <DialogContent>
            <KeyboardDatePicker
              margin="normal"
              id="closing-date"
              label="Select Closing Date"
              format="MM/dd/yyyy"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              maxDate={new Date()}
              className={classes.dateField}
              fullWidth
              variant="inline"
              inputVariant="outlined"
            />
            
            <TextField
              type="password"
              label="Enter Password"
              value={password}
              onChange={handlePasswordChange}
              className={classes.passwordField}
              fullWidth
              variant="outlined"
              required
            />

            <TextField
              type="password"
              label="Confirm Password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              className={classes.passwordField}
              fullWidth
              variant="outlined"
              required
              error={!!passwordError}
              helperText={passwordError}
            />
          </DialogContent>
          
          <DialogActions className={classes.actions}>
            <Button
              onClick={handleClose}
              variant="outlined"
              className={classes.cancelButton}
            >
              Cancel
            </Button>
            <Button
              onClick={handleProceedToConfirmation}
              variant="contained"
              className={classes.submitButton}
              disabled={isSubmitDisabled}
            >
              Continue
            </Button>
          </DialogActions>
        </Dialog>

        {/* Confirmation Dialog */}
        <Dialog
          open={confirmOpen}
          onClose={handleConfirmationClose}
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle>
            <Typography variant="h6" className={classes.title}>
              Confirm Close Books
            </Typography>
          </DialogTitle>
          
          <DialogContent className={classes.confirmationContent}>
            <Typography variant="body1">
              Are you sure you want to close the books for {selectedDate.toLocaleDateString()}?
            </Typography>
            <Typography variant="body2" style={{ marginTop: '1rem', color: '#516e76' }}>
              This action cannot be undone.
            </Typography>
          </DialogContent>
          
          <DialogActions className={classes.actions}>
            <Button
              onClick={handleConfirmationClose}
              variant="outlined"
              className={classes.cancelButton}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              className={classes.submitButton}
            >
              Close Books
            </Button>
          </DialogActions>
        </Dialog>
      </div>
  );
};

export default CloseBooks;
