import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import axios from 'axios';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Divider,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Chip,
  Avatar,
  Tabs,
  Tab,
  CircularProgress,
  Tooltip
} from '@material-ui/core';
import {
  Build as BuildIcon,
  DirectionsCar as CarIcon,
  Category as CategoryIcon,
  Warning as WarningIcon,
  Timeline as TimelineIcon,
  Speed as SpeedIcon,
  ArrowForward as ArrowForwardIcon,
  Error as ErrorIcon,
  Assignment as AssignmentIcon,
  Refresh as RefreshIcon,
  Info as InfoIcon
} from '@material-ui/icons';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip as RechartsTooltip } from 'recharts';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  headerCard: {
    marginBottom: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  headerContent: {
    paddingBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  kpiCard: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  kpiTitle: {
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  kpiValue: {
    fontSize: 24,
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
  },
  kpiIcon: {
    fontSize: 40,
    marginBottom: theme.spacing(1),
  },
  listSection: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    overflow: 'auto',
    maxHeight: 300,
  },
  list: {
    padding: 0,
  },
  listItem: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  tabs: {
    marginBottom: theme.spacing(2),
  },
  criticalChip: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
    fontWeight: 'bold',
  },
  warningChip: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.contrastText,
    fontWeight: 'bold',
  },
  okChip: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.contrastText,
    fontWeight: 'bold',
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  viewAllButton: {
    marginTop: theme.spacing(1),
  },
  chartContainer: {
    height: 200,
    marginTop: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 200,
  },
  cardActions: {
    justifyContent: 'flex-end',
    marginTop: 'auto',
  },
  emergencyChip: {
    marginLeft: theme.spacing(1),
  },
}));

// Define colors for charts
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

const ShopDashboard = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [repairs, setRepairs] = useState([]);
  const [maintenance, setMaintenance] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [alertsTab, setAlertsTab] = useState(0);
  const [vehiclesTab, setVehiclesTab] = useState(0);
  const [refreshing, setRefreshing] = useState(false);

  // Mock data for charts and metrics
  const [dashboardData, setDashboardData] = useState({
    repairsStats: {
      total: 0,
      open: 0,
      highPriority: 0,
      outOfService: 0,
      byStatus: []
    },
    maintenanceStats: {
      totalServices: 0,
      servicesThisMonth: 0,
      upcomingServices: 0,
      averageMileage: 0,
      scheduledMaintenance: []
    },
    inventoryStats: {
      totalItems: 0,
      lowStock: 0,
      outOfStock: 0,
      recentlyUsed: []
    }
  });

  const fetchDashboardData = async () => {
    setLoading(true);
    try {
      // Fetch repair data
      const repairsResponse = await axios.get(`${props.url}/api/equipment/getRepairs`);
      setRepairs(repairsResponse.data);

      // Fetch maintenance data
      const maintenanceResponse = await axios.get(`${props.url}/api/equipment/getMaintenanceHistory`);
      setMaintenance(maintenanceResponse.data);

      // Note: Since inventory endpoint is not shown in your code, we'll use mock data
      setInventory([]);

      // Process data for dashboard metrics
      processData(repairsResponse.data, maintenanceResponse.data, []);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      setLoading(false);
    }
  };

  const processData = (repairsData, maintenanceData, inventoryData) => {
    // Process repairs data
    const totalRepairs = repairsData.length;
    const openRepairs = repairsData.filter(r => r.status !== 'Completed').length;
    const highPriorityRepairs = repairsData.filter(r => r.priority === 'High').length;
    const outOfServiceCount = repairsData.filter(r => r.outOfService).length;

    // Status distribution for pie chart
    const statusData = Object.entries(
      repairsData.reduce((acc, repair) => {
        acc[repair.status] = (acc[repair.status] || 0) + 1;
        return acc;
      }, {})
    ).map(([name, value]) => ({ name, value }));

    // Process maintenance data
    const currentDate = new Date();
    const thisMonth = currentDate.getMonth();
    const thisYear = currentDate.getFullYear();

    const upcomingServices = maintenanceData.filter(record => {
      if (!record?.currentMileage || !record?.nextServiceMileage) return false;
      return record.nextServiceMileage - record.currentMileage <= 500;
    });

    const maintenanceStats = {
      totalServices: maintenanceData.length,
      servicesThisMonth: maintenanceData.filter(record => {
        const recordDate = new Date(record.serviceDate);
        return recordDate.getMonth() === thisMonth && recordDate.getFullYear() === thisYear;
      }).length,
      upcomingServices: upcomingServices.length,
      averageMileage: maintenanceData.length > 0 
        ? Math.round(maintenanceData.reduce((acc, curr) => acc + (curr.currentMileage || 0), 0) / maintenanceData.length)
        : 0,
      scheduledMaintenance: upcomingServices.slice(0, 5)
    };

    // Mock inventory stats
    const inventoryStats = {
      totalItems: 250,
      lowStock: 12,
      outOfStock: 3,
      recentlyUsed: [
        { title: "Oil Filter - Toyota", quantity: 5, date: "2025-03-20" },
        { title: "Brake Pads - F-150", quantity: 2, date: "2025-03-21" },
        { title: "Air Filter - Honda", quantity: 3, date: "2025-03-22" }
      ]
    };

    setDashboardData({
      repairsStats: {
        total: totalRepairs,
        open: openRepairs,
        highPriority: highPriorityRepairs,
        outOfService: outOfServiceCount,
        byStatus: statusData
      },
      maintenanceStats,
      inventoryStats
    });
  };

  // Refresh dashboard data
  const refreshData = () => {
    setRefreshing(true);
    fetchDashboardData().finally(() => {
      setTimeout(() => setRefreshing(false), 1000);
    });
  };

  useEffect(() => {
    fetchDashboardData();
  }, []);

  // Handle alerts tab change
  const handleAlertsTabChange = (event, newValue) => {
    setAlertsTab(newValue);
  };

  // Handle vehicles tab change
  const handleVehiclesTabChange = (event, newValue) => {
    setVehiclesTab(newValue);
  };

  // Navigate to detailed views
  const navigateTo = (route) => {
    window.location.href = route;
  };

  if (loading) {
    return (
      <Container className={classes.root}>
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      </Container>
    );
  }

  return (
    <Container className={classes.root}>
      {/* Dashboard Header */}
      <Card className={classes.headerCard}>
        <CardContent className={classes.headerContent}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box>
              <Typography variant="h4" gutterBottom>
                Shop Dashboard
              </Typography>
              <Typography variant="subtitle1">
                Welcome to the Fleet Shop Management Dashboard
              </Typography>
            </Box>
            <Tooltip title="Refresh Dashboard">
              <IconButton color="inherit" onClick={refreshData} disabled={refreshing}>
                {refreshing ? <CircularProgress size={24} color="inherit" /> : <RefreshIcon />}
              </IconButton>
            </Tooltip>
          </Box>
        </CardContent>
      </Card>

      {/* Quick Stats */}
      <Grid container spacing={3} style={{ marginBottom: '24px' }}>
        {/* Repairs Stats */}
        <Grid item xs={12} md={3}>
          <Card className={classes.kpiCard}>
            <CardContent>
              <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                <div>
                  <Typography className={classes.kpiTitle} color="textSecondary" gutterBottom>
                    Open Repairs
                  </Typography>
                  <Typography className={classes.kpiValue} variant="h4">
                    {dashboardData.repairsStats.open}
                  </Typography>
                </div>
                <BuildIcon className={classes.kpiIcon} color="primary" />
              </Box>
              {dashboardData.repairsStats.highPriority > 0 && (
                <Chip 
                  label={`${dashboardData.repairsStats.highPriority} High Priority`} 
                  className={classes.criticalChip}
                  size="small"
                  style={{ marginTop: 8 }}
                />
              )}
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button 
                size="small" 
                color="primary" 
                endIcon={<ArrowForwardIcon />}
                onClick={() => navigateTo('/vehiclerepairs')}
              >
                View All Repairs
              </Button>
            </CardActions>
          </Card>
        </Grid>
        
        {/* Maintenance Stats */}
        <Grid item xs={12} md={3}>
          <Card className={classes.kpiCard}>
            <CardContent>
              <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                <div>
                  <Typography className={classes.kpiTitle} color="textSecondary" gutterBottom>
                    Upcoming Services
                  </Typography>
                  <Typography className={classes.kpiValue} variant="h4">
                    {dashboardData.maintenanceStats.upcomingServices}
                  </Typography>
                </div>
                <TimelineIcon className={classes.kpiIcon} style={{ color: '#FFB020' }} />
              </Box>
              <Typography variant="body2" color="textSecondary">
                {dashboardData.maintenanceStats.servicesThisMonth} services this month
              </Typography>
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button 
                size="small" 
                color="primary" 
                endIcon={<ArrowForwardIcon />}
                onClick={() => navigateTo('/vehiclemaintenancehistory')}
              >
                View Maintenance History
              </Button>
            </CardActions>
          </Card>
        </Grid>

        {/* Inventory Stats */}
        <Grid item xs={12} md={3}>
          <Card className={classes.kpiCard}>
            <CardContent>
              <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                <div>
                  <Typography className={classes.kpiTitle} color="textSecondary" gutterBottom>
                    Inventory Items
                  </Typography>
                  <Typography className={classes.kpiValue} variant="h4">
                    {dashboardData.inventoryStats.totalItems}
                  </Typography>
                </div>
                <CategoryIcon className={classes.kpiIcon} style={{ color: '#33C9DC' }} />
              </Box>
              <Chip 
                label={`${dashboardData.inventoryStats.lowStock} Low Stock Items`} 
                className={classes.warningChip}
                size="small"
                style={{ marginTop: 8 }}
              />
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button 
                size="small" 
                color="primary" 
                endIcon={<ArrowForwardIcon />}
                onClick={() => navigateTo('/shopinventory')}
              >
                Manage Inventory
              </Button>
            </CardActions>
          </Card>
        </Grid>

        {/* Out of Service */}
        <Grid item xs={12} md={3}>
          <Card className={classes.kpiCard}>
            <CardContent>
              <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                <div>
                  <Typography className={classes.kpiTitle} color="textSecondary" gutterBottom>
                    Out of Service
                  </Typography>
                  <Typography className={classes.kpiValue} variant="h4">
                    {dashboardData.repairsStats.outOfService}
                  </Typography>
                </div>
                <ErrorIcon className={classes.kpiIcon} style={{ color: '#FF6B6B' }} />
              </Box>
              <Typography variant="body2" color="textSecondary">
                Vehicles currently unavailable
              </Typography>
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button 
                size="small" 
                color="primary" 
                endIcon={<ArrowForwardIcon />}
                onClick={() => navigateTo('/vehiclerepairs?filter=outOfService')}
              >
                View Out of Service
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>

      {/* Main Dashboard Content */}
      <Grid container spacing={3}>
        {/* Alerts and Critical Issues */}
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <div className={classes.sectionHeader}>
              <Typography className={classes.title}>
                <WarningIcon style={{ verticalAlign: 'middle', marginRight: 8 }} />
                Alerts & Critical Issues
              </Typography>
            </div>
            
            <Tabs
              value={alertsTab}
              onChange={handleAlertsTabChange}
              indicatorColor="primary"
              textColor="primary"
              className={classes.tabs}
            >
              <Tab label="High Priority Repairs" />
              <Tab label="Maintenance Due" />
              <Tab label="Low Stock Items" />
            </Tabs>

            <div className={classes.listSection}>
              {alertsTab === 0 && (
                <List className={classes.list}>
                  {repairs
                    .filter(repair => repair.priority === 'High')
                    .slice(0, 5)
                    .map((repair, index) => (
                      <ListItem key={index} className={classes.listItem} button>
                        <ListItemIcon>
                          <Avatar className={classes.avatar}>
                            <BuildIcon />
                          </Avatar>
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="subtitle2">
                              {repair?.equipmentId?.name || ''} #{repair?.equipmentId?.number || ''}
                              {repair.outOfService && (
                                <Chip 
                                  label="OOS" 
                                  size="small" 
                                  className={classes.criticalChip}
                                  style={{ marginLeft: 8 }}
                                />
                              )}
                            </Typography>
                          }
                          secondary={
                            <>
                              <Typography variant="body2" component="span">
                                {repair.description}
                              </Typography>
                              <Typography variant="caption" display="block" color="textSecondary">
                                Reported by: {repair.reportedBy}
                              </Typography>
                            </>
                          }
                        />
                        <ListItemSecondaryAction>
                          <Chip
                            label={repair.status}
                            color={repair.status === 'Completed' ? 'primary' : 'default'}
                            size="small"
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  {repairs.filter(repair => repair.priority === 'High').length === 0 && (
                    <ListItem>
                      <ListItemText
                        primary="No high priority repairs"
                        secondary="All repairs are currently medium or low priority"
                      />
                    </ListItem>
                  )}
                </List>
              )}

              {alertsTab === 1 && (
                <List className={classes.list}>
                  {dashboardData.maintenanceStats.scheduledMaintenance.map((service, index) => (
                    <ListItem key={index} className={classes.listItem} button>
                      <ListItemIcon>
                        <Avatar className={classes.avatar}>
                          <SpeedIcon />
                        </Avatar>
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="subtitle2">
                            {service.vehicleInfo?.year} {service.vehicleInfo?.manufacturer} {service.vehicleInfo?.model}
                          </Typography>
                        }
                        secondary={
                          <>
                            <Typography variant="body2" component="span">
                              Current: {service.currentMileage?.toLocaleString() || 0} mi | 
                              Next: {service.nextServiceMileage?.toLocaleString() || 0} mi
                            </Typography>
                            <Typography variant="caption" display="block" color="textSecondary">
                              {service.vehicleInfo?.division || 'Unknown Division'}
                            </Typography>
                          </>
                        }
                      />
                      <ListItemSecondaryAction>
                        <Chip
                          label="Service Due"
                          color="secondary"
                          size="small"
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                  {dashboardData.maintenanceStats.scheduledMaintenance.length === 0 && (
                    <ListItem>
                      <ListItemText
                        primary="No upcoming maintenance"
                        secondary="All vehicles are up to date on service"
                      />
                    </ListItem>
                  )}
                </List>
              )}

              {alertsTab === 2 && (
                <List className={classes.list}>
                  {dashboardData.inventoryStats.recentlyUsed.map((item, index) => (
                    <ListItem key={index} className={classes.listItem} button>
                      <ListItemIcon>
                        <Avatar className={classes.avatar}>
                          <CategoryIcon />
                        </Avatar>
                      </ListItemIcon>
                      <ListItemText
                        primary={item.title}
                        secondary={
                          <>
                            <Typography variant="body2" component="span">
                              Quantity: {item.quantity}
                            </Typography>
                            <Typography variant="caption" display="block" color="textSecondary">
                              Last used: {new Date(item.date).toLocaleDateString()}
                            </Typography>
                          </>
                        }
                      />
                      <ListItemSecondaryAction>
                        <Chip
                          label="Low Stock"
                          className={classes.warningChip}
                          size="small"
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                  {dashboardData.inventoryStats.recentlyUsed.length === 0 && (
                    <ListItem>
                      <ListItemText
                        primary="No low stock items"
                        secondary="All inventory items are stocked adequately"
                      />
                    </ListItem>
                  )}
                </List>
              )}
            </div>

            <Button
              color="primary"
              className={classes.viewAllButton}
              endIcon={<ArrowForwardIcon />}
              onClick={() => {
                if (alertsTab === 0) navigateTo('/vehiclerepairs?priority=High');
                else if (alertsTab === 1) navigateTo('/vehiclemaintenancehistory');
                else navigateTo('/shopinventory?tab=1');
              }}
            >
              View All
            </Button>
          </Paper>
        </Grid>

        {/* Status Overview */}
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <div className={classes.sectionHeader}>
              <Typography className={classes.title}>
                <InfoIcon style={{ verticalAlign: 'middle', marginRight: 8 }} />
                Status Overview
              </Typography>
            </div>

            <Grid container spacing={3}>
              {/* Repair Status Chart */}
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Repair Status Distribution
                </Typography>
                <div className={classes.chartContainer}>
                  {dashboardData.repairsStats.byStatus.length > 0 ? (
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart>
                        <Pie
                          data={dashboardData.repairsStats.byStatus}
                          cx="50%"
                          cy="50%"
                          outerRadius={70}
                          fill="#8884d8"
                          dataKey="value"
                          label={({name, value}) => `${name}: ${value}`}
                        >
                          {dashboardData.repairsStats.byStatus.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <RechartsTooltip />
                      </PieChart>
                    </ResponsiveContainer>
                  ) : (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                      <Typography variant="body2" color="textSecondary">
                        No repair data available
                      </Typography>
                    </Box>
                  )}
                </div>
              </Grid>

              {/* Maintenance Summary */}
              <Grid item xs={12}>
                <Divider className={classes.divider} />
                <Typography variant="subtitle2" gutterBottom>
                  Fleet Status Summary
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Box textAlign="center" p={1}>
                      <Typography variant="body2" color="textSecondary">Total Fleet</Typography>
                      <Typography variant="h6">
                        {repairs.reduce((acc, repair) => {
                          if (!acc.includes(repair?.equipmentId?.number)) {
                            acc.push(repair?.equipmentId?.number);
                          }
                          return acc;
                        }, []).length}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box textAlign="center" p={1}>
                      <Typography variant="body2" color="textSecondary">Operational</Typography>
                      <Typography variant="h6" style={{ color: '#4caf50' }}>
                        {repairs.reduce((acc, repair) => {
                          if (!acc.includes(repair?.equipmentId?.number) && !repair.outOfService) {
                            acc.push(repair?.equipmentId?.number);
                          }
                          return acc;
                        }, []).length}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box textAlign="center" p={1}>
                      <Typography variant="body2" color="textSecondary">Out of Service</Typography>
                      <Typography variant="h6" style={{ color: '#f44336' }}>
                        {dashboardData.repairsStats.outOfService}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Upcoming Work */}
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <div className={classes.sectionHeader}>
              <Typography className={classes.title}>
                <AssignmentIcon style={{ verticalAlign: 'middle', marginRight: 8 }} />
                Upcoming Work Schedule
              </Typography>
            </div>

            <Tabs
              value={vehiclesTab}
              onChange={handleVehiclesTabChange}
              indicatorColor="primary"
              textColor="primary"
              className={classes.tabs}
            >
              <Tab label="Active Repairs" />
              <Tab label="Scheduled Maintenance" />
            </Tabs>

            <div className={classes.listSection} style={{ maxHeight: 250 }}>
              {vehiclesTab === 0 && (
                <List className={classes.list}>
                  {repairs
                    .filter(repair => repair.status !== 'Completed')
                    .slice(0, 8)
                    .map((repair, index) => (
                      <ListItem key={index} className={classes.listItem} button>
                        <ListItemIcon>
                          <CarIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Box display="flex" alignItems="center">
                              <Typography variant="subtitle2">
                                {repair?.equipmentId?.name || ''} #{repair?.equipmentId?.number || ''}
                              </Typography>
                              {repair.outOfService && (
                                <Chip 
                                  label="OOS" 
                                  size="small" 
                                  className={classes.emergencyChip} 
                                  color="secondary"
                                />
                              )}
                            </Box>
                          }
                          secondary={repair.description}
                        />
                        <ListItemSecondaryAction>
                          <Box display="flex" alignItems="center">
                            <Chip
                              label={repair.status}
                              size="small"
                              style={{ marginRight: 8 }}
                            />
                            <Chip
                              label={repair.priority}
                              size="small"
                              className={
                                repair.priority === 'High' 
                                  ? classes.criticalChip 
                                  : repair.priority === 'Medium'
                                    ? classes.warningChip
                                    : classes.okChip
                              }
                            />
                          </Box>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  {repairs.filter(repair => repair.status !== 'Completed').length === 0 && (
                    <ListItem>
                      <ListItemText
                        primary="No active repairs"
                        secondary="All repair work is completed"
                      />
                    </ListItem>
                  )}
                </List>
              )}

              {vehiclesTab === 1 && (
                <List className={classes.list}>
                  {dashboardData.maintenanceStats.scheduledMaintenance.map((service, index) => (
                    <ListItem key={index} className={classes.listItem} button>
                      <ListItemIcon>
                        <CarIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="subtitle2">
                            {service.vehicleInfo?.year} {service.vehicleInfo?.manufacturer} {service.vehicleInfo?.model} 
                            #{service.vehicleInfo?.vehicleNumber}
                          </Typography>
                        }
                        secondary={
                          <>
                            <Typography variant="body2" component="span">
                              Next Service: {service.nextServiceMileage?.toLocaleString() || 0} mi
                            </Typography>
                            <Typography variant="caption" display="block" color="textSecondary">
                              Current: {service.currentMileage?.toLocaleString() || 0} mi | 
                              Remaining: {(service.nextServiceMileage - service.currentMileage)?.toLocaleString() || 0} mi
                            </Typography>
                          </>
                        }
                      />
                      <ListItemSecondaryAction>
                        <Chip
                          label={service.nextServiceMileage - service.currentMileage <= 250 ? "Urgent" : "Upcoming"}
                          className={service.nextServiceMileage - service.currentMileage <= 250 ? classes.criticalChip : classes.warningChip}
                          size="small"
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                  {dashboardData.maintenanceStats.scheduledMaintenance.length === 0 && (
                    <ListItem>
                      <ListItemText
                        primary="No scheduled maintenance"
                        secondary="All vehicles are up to date on service"
                      />
                    </ListItem>
                  )}
                </List>
              )}
            </div>

            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button
                color="primary"
                endIcon={<ArrowForwardIcon />}
                onClick={() => {
                  if (vehiclesTab === 0) navigateTo('/vehiclerepairs');
                  else navigateTo('/vehiclemaintenancehistory');
                }}
              >
                View Full Schedule
              </Button>
            </Box>
          </Paper>
        </Grid>

        {/* Quick Action Buttons */}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" mt={3} mb={1}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<BuildIcon />}
              style={{ margin: '0 8px' }}
              onClick={() => navigateTo('/vehiclerepairs')}
            >
              Manage Repairs
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<TimelineIcon />}
              style={{ margin: '0 8px' }}
              onClick={() => navigateTo('/vehiclemaintenancehistory')}
            >
              View Maintenance
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<CategoryIcon />}
              style={{ margin: '0 8px' }}
              onClick={() => navigateTo('/shopinventory')}
            >
              Manage Inventory
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user
  };
};

export default connect(mapStateToProps)(ShopDashboard);