import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableSortLabel,
  TextField,
  makeStyles,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableContainer: {
    maxHeight: 'calc(100vh - 280px)',
  },
  searchBar: {
    marginBottom: theme.spacing(2),
  },
  filterSection: {
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const WorkorderTable = ({ workorders }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('scheduledDate');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredWorkorders, setFilteredWorkorders] = useState([]);
  const [statusFilter, setStatusFilter] = useState('all');

  useEffect(() => {
    filterWorkorders();
  }, [workorders, searchTerm, statusFilter]);

  const filterWorkorders = () => {
    let filtered = [...workorders];

    // Apply search filter
    if (searchTerm) {
      filtered = filtered.filter((workorder) =>
        Object.values(workorder).some((value) =>
          String(value).toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    // Apply status filter
    if (statusFilter !== 'all') {
      filtered = filtered.filter((workorder) =>
        statusFilter === 'completed' ? workorder.work_complete_at : !workorder.work_complete_at
      );
    }

    // Apply sorting
    filtered.sort((a, b) => {
      const isAsc = order === 'asc';
      if (orderBy === 'scheduledDate') {
        return (new Date(a.scheduledDate) - new Date(b.scheduledDate)) * (isAsc ? 1 : -1);
      }
      return (a[orderBy] < b[orderBy] ? -1 : 1) * (isAsc ? 1 : -1);
    });

    setFilteredWorkorders(filtered);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatDate = (date) => {
    return date ? format(new Date(date), 'MM/dd/yyyy') : '-';
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.filterSection}>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="Search Workorders"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Status Filter</InputLabel>
            <Select
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              label="Status Filter"
            >
              <MenuItem value="all">All Workorders</MenuItem>
              <MenuItem value="completed">Completed</MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Paper className={classes.paper}>
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'number'}
                    direction={orderBy === 'number' ? order : 'asc'}
                    onClick={() => handleRequestSort('number')}
                  >
                    Workorder #
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'name'}
                    direction={orderBy === 'name' ? order : 'asc'}
                    onClick={() => handleRequestSort('name')}
                  >
                    Client Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'jobName'}
                    direction={orderBy === 'jobName' ? order : 'asc'}
                    onClick={() => handleRequestSort('jobName')}
                  >
                    Job Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'scheduledDate'}
                    direction={orderBy === 'scheduledDate' ? order : 'asc'}
                    onClick={() => handleRequestSort('scheduledDate')}
                  >
                    Scheduled Date
                  </TableSortLabel>
                </TableCell>
                <TableCell>Completed Date</TableCell>
                <TableCell>Completed By</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredWorkorders
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((workorder) => (
                  <TableRow key={workorder.number}>
                    <TableCell>{workorder.number}</TableCell>
                    <TableCell>{workorder.name}</TableCell>
                    <TableCell>{workorder.jobName}</TableCell>
                    <TableCell>{formatDate(workorder.scheduledDate)}</TableCell>
                    <TableCell>{formatDate(workorder.work_complete_at)}</TableCell>
                    <TableCell>{workorder.work_completed_by?.displayName || '-'}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={filteredWorkorders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default WorkorderTable;
