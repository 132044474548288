import React, { useState, useEffect } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  TextField,
  IconButton,
  Tooltip,
  Typography,
  Box,
  Chip,
  makeStyles,
  Grid,
  Card,
  CardContent,
} from '@material-ui/core';
import {
  Search as SearchIcon,
  DirectionsCar as CarIcon,
  Build as BuildIcon,
  Speed as SpeedIcon,
  DateRange as DateIcon,
  Info as InfoIcon,
  GetApp as ExportIcon,
} from '@material-ui/icons';
import axios from 'axios';
import { connect } from 'react-redux';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  searchBar: {
    marginBottom: theme.spacing(3),
  },
  tableContainer: {
    maxHeight: 'calc(100vh - 300px)',
  },
  table: {
    minWidth: 750,
  },
  headerCell: {
    fontWeight: 600,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  statusChip: {
    margin: theme.spacing(0.5),
  },
  servicesCell: {
    maxWidth: 200,
  },
  summaryCards: {
    marginBottom: theme.spacing(3),
  },
  summaryCard: {
    height: '100%',
  },
  cardIcon: {
    fontSize: 40,
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  vehicleCell: {
    minWidth: 200,
  },
  searchField: {
    width: 300,
  },
  exportButton: {
    marginLeft: theme.spacing(2),
  },
}));

const MaintenanceHistory = (props) => {
  const classes = useStyles();
  const [maintenanceData, setMaintenanceData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('serviceDate');
  const [order, setOrder] = useState('desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [summaryStats, setSummaryStats] = useState({
    totalServices: 0,
    servicesThisMonth: 0,
    upcomingServices: 0,
    averageMileage: 0,
  });
  const [upcomingServicesList, setUpcomingServicesList] = useState([]);

  useEffect(() => {
    fetchMaintenanceHistory();
  }, []);

  const fetchMaintenanceHistory = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${props.url}/api/equipment/getMaintenanceHistory`);
      setMaintenanceData(response.data);
      calculateSummaryStats(response.data);
    } catch (error) {
      console.error('Error fetching maintenance history:', error);
    }
    setLoading(false);
  };

const calculateSummaryStats = (data) => {
  const currentDate = new Date();
  const thisMonth = currentDate.getMonth();
  const thisYear = currentDate.getFullYear();

  const upcomingServices = data.filter(record => {
    if (!record?.currentMileage || !record?.nextServiceMileage) return false
    return record.nextServiceMileage - record.currentMileage <= 500;
  });

  const stats = {
    totalServices: data.length,
    servicesThisMonth: data.filter(record => {
      const recordDate = new Date(record.serviceDate);
      return recordDate.getMonth() === thisMonth && recordDate.getFullYear() === thisYear;
    }).length,
    upcomingServices: upcomingServices.length,
    averageMileage: data.reduce((acc, curr) => acc + curr.currentMileage, 0) / data.length,
  };

  setUpcomingServicesList(upcomingServices);
  setSummaryStats(stats);
};

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const getServicesPerformed = (services) => {
    return Object.entries(services)
      .filter(([_, service]) => service.performed === 'yes')
      .map(([key]) => formatServiceName(key));
  };

  const formatServiceName = (key) => {
    return key.replace(/([A-Z])/g, ' $1').trim();
  };

  const formatDate = (date) => {
    // console.log('FOrmat date', date)
    return moment(date).add(9, 'hours').format('MM/DD/YYYY');
  };

  const exportToCSV = () => {
    // Implementation for exporting to CSV
    console.log('Exporting to CSV...');
  };

  // Filter and sort data
  const filteredData = maintenanceData.filter((record) => {
    // console.log('Record', record)
    const vehicleInfo = record.vehicleInfo
    // console.log('vehicleInfo', vehicleInfo)
    const searchStr = searchTerm.toLowerCase();
    return (
      record?.vehicleInfo?.name?.manufacturer?.toLowerCase()?.includes(searchStr) ||
      record?.vehicleInfo?.name?.model?.toLowerCase()?.includes(searchStr) ||
      record?.vehicleInfo?.name?.vehicleNumber?.toLowerCase()?.includes(searchStr) ||
      record?.vehicleInfo?.name?.toLowerCase()?.includes(searchStr) ||
      formatDate(record.serviceDate)?.toLowerCase()?.includes(searchStr)
    );
  });
  
  const handleNavToVehicle = async (equipmentId) => {
    console.log('Go to this one.', equipmentId)
    console.log(process.env)
    const equipURL = `./equipment/details/${equipmentId}`
    window.open(equipURL, "_blank")
  }

  const sortedData = filteredData.sort((a, b) => {
    const isAsc = order === 'asc';
    switch (orderBy) {
      case 'serviceDate':
        return isAsc ? new Date(a.serviceDate) - new Date(b.serviceDate) : new Date(b.serviceDate) - new Date(a.serviceDate);
      case 'currentMileage':
        return isAsc ? a.currentMileage - b.currentMileage : b.currentMileage - a.currentMileage;
      default:
        return 0;
    }
  });

  const paginatedData = sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <div >
      {/* Header */}
      <Box className={classes.header}>
        <Typography variant="h4" gutterBottom>
          Vehicle Maintenance History
        </Typography>
      </Box>
      <Paper className={classes.root}>

      {/* Summary Cards */}
      <Grid container spacing={3} className={classes.summaryCards}>
        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.summaryCard}>
            <CardContent>
              <BuildIcon className={classes.cardIcon} />
              <Typography variant="h6">{summaryStats.totalServices}</Typography>
              <Typography color="textSecondary">Total Services</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.summaryCard}>
            <CardContent>
              <DateIcon className={classes.cardIcon} />
              <Typography variant="h6">{summaryStats.servicesThisMonth}</Typography>
              <Typography color="textSecondary">Services This Month</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.summaryCard}>
            <CardContent>
              <SpeedIcon className={classes.cardIcon} />
              <Typography variant="h6">{summaryStats?.averageMileage?.toLocaleString() || ''}</Typography>
              <Typography color="textSecondary">Average Mileage</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.summaryCard}>
          <Tooltip 
                title={
                  <div>
                   
                    {upcomingServicesList.length > 0 ? (
                      <div>
                         <Typography variant="subtitle2">Vehicles Needing Service Soon:</Typography>
                      <ul style={{ margin: '8px 0', paddingLeft: '16px' }}>
                        {upcomingServicesList.map((service, index) => (
                          <li key={index}>
                            <Typography variant="body2">
                              {service.vehicleInfo.manufacturer} {service.vehicleInfo.model} #{service.vehicleInfo.vehicleNumber} 
                              <br />
                              <span style={{ fontSize: '0.8rem' }}>
                                Current: {service.currentMileage.toLocaleString()} mi | 
                                Next: {service.nextServiceMileage.toLocaleString()} mi
                              </span>
                            </Typography>
                          </li>
                        ))}
                      </ul>
                      </div>
                    ) : (
                      <Typography variant="body2">No upcoming services</Typography>
                    )}
                  </div>
                }
              >
            <CardContent>
              <InfoIcon className={classes.cardIcon} />
              <Typography variant="h6">{summaryStats.upcomingServices}</Typography>
              <Typography color="textSecondary">Upcoming Services</Typography>
            </CardContent>
            </Tooltip>
          </Card>
        </Grid>
      </Grid>

      {/* Search Bar */}
      <Box className={classes.searchBar}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <TextField
              className={classes.searchField}
              variant="outlined"
              size="small"
              placeholder="Search maintenance records..."
              InputProps={{
                startAdornment: <SearchIcon color="action" />,
              }}
              onChange={handleSearch}
            />
          </Grid>
          {/* <Grid item>
            <Tooltip title="Export to CSV">
              <IconButton className={classes.exportButton} onClick={exportToCSV}>
                <ExportIcon />
              </IconButton>
            </Tooltip>
          </Grid> */}
        </Grid>
      </Box>

      {/* Maintenance Table */}
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCell}>
                <TableSortLabel
                  active={orderBy === 'serviceDate'}
                  direction={orderBy === 'serviceDate' ? order : 'asc'}
                  onClick={() => handleSort('serviceDate')}
                >
                  Service Date
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.headerCell}>Vehicle</TableCell>
              <TableCell className={classes.headerCell}>
                <TableSortLabel
                  active={orderBy === 'currentMileage'}
                  direction={orderBy === 'currentMileage' ? order : 'asc'}
                  onClick={() => handleSort('currentMileage')}
                >
                  Current Mileage
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.headerCell}>Next Service</TableCell>
              <TableCell className={classes.headerCell}>Services Performed</TableCell>
              <TableCell className={classes.headerCell}>Division</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((record) => (
              <TableRow key={record._id} hover>
                <TableCell>{formatDate(record.serviceDate)}</TableCell>
                <TableCell className={classes.vehicleCell}>
                  <Box display="flex" alignItems="center">
                    <CarIcon color="action" style={{ marginRight: 8 }} />
                    <Tooltip title="Double Click to Open Equipment Details">
                      <Box
                        style={{
                          cursor: 'pointer'
                        }}
                        onDoubleClick={() => handleNavToVehicle(record.equipmentId)}
                      >
                        <Typography variant="body2">
                          {record.vehicleInfo.year} {record.vehicleInfo.manufacturer} {record.vehicleInfo.model}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                        {record?.vehicleInfo?.name || ''} #{record.vehicleInfo.vehicleNumber}
                        </Typography>
                      </Box>
                    </Tooltip>
                  </Box>
                </TableCell>
                <TableCell>{record.currentMileage.toLocaleString()} mi</TableCell>
                <TableCell>{record.nextServiceMileage.toLocaleString()} mi</TableCell>
                <TableCell className={classes.servicesCell}>
                  <Box display="flex" flexWrap="wrap">
                    {getServicesPerformed(record.services).map((service, index) => {
                      // console.log('Service...', service)
                      const label = formatLabel(service)
                      // console.log(label)
                       
                      return (
                      <Chip
                        key={index}
                        label={label}
                        size="small"
                        className={classes.statusChip}
                      />
                    )})}
                  </Box>
                </TableCell>
                <TableCell>{record.vehicleInfo.division}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      </Paper>
    </div>
  );
};

const formatLabel = (serviceKey) => {
  // console.log('Format label', serviceKey)
  let label = serviceKey
  let trimmed = serviceKey.replace(/([A-Z])/g, ' $1').trim()
  // console.log('Trimmed Item', trimmed)

  let firstLetter = trimmed?.charAt(0)?.toUpperCase()
  // console.log('first letter', firstLetter)
  let restOfWord = trimmed?.toString()?.slice(1, trimmed.length)

  // console.log('rest of', restOfWord)
  label = `${firstLetter}${restOfWord}`
  // console.log('label', label)
  return label
}

const mapStateToProps = (state) => ({
  url: state.url,
});

export default connect(mapStateToProps)(MaintenanceHistory);
