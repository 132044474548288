import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import ListOfOpenEstimates from './ListOfOpenEstimates'
import { CircularProgress, Grid, Paper, Button } from '@material-ui/core'
import ChooseEntityComponent from '../../Entities/ChooseEntityComponent'
import ProtectedButton from '../../../actions/protectedButtons'
import CloudIcon from '@material-ui/icons/CloudDownload'

const OpenEstimatesDashboard = (props) => {
  const [state, setState] = React.useState({
    list: [],
    counter: 0
  })
  const [loading, setLoading] = React.useState(false)
  const [list, setList] = React.useState([])
  const [activeEntity, setActiveEntity] = React.useState(null)
  const [estimates, setEstimates] = React.useState([]);

  const fetchEstimates = React.useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${props.url}/api/proposal/getOpenEstimates`);
      if (response?.data) {
        setEstimates(response.data);
      }
    } catch (e) {
      console.error('Error pulling open estimates', e);
    } finally {
      setLoading(false);
    }
  }, [props.url]);

  React.useEffect(() => {
    fetchEstimates();
  }, [fetchEstimates]);

  const handleResponseFromStatusUpdate = React.useCallback((updatedData) => {
    setEstimates(prevEstimates => {
      if (Array.isArray(updatedData)) {
        // Handle bulk updates
        const updatedMap = new Map(updatedData.map(item => [item._id, item]));
        return prevEstimates.map(estimate => 
          updatedMap.has(estimate._id) ? updatedMap.get(estimate._id) : estimate
        ).filter(estimate => !estimate.lost);
      } else {
        // Handle single update
        if (updatedData.lost) {
          return prevEstimates.filter(estimate => estimate._id !== updatedData._id);
        }
        return prevEstimates.map(estimate => 
          estimate._id === updatedData._id ? updatedData : estimate
        );
      }
    });
  }, []);

  const filteredEstimates = React.useMemo(() => {
    if (!activeEntity?._id) return estimates;
    return estimates.filter(item => item.entity === activeEntity._id);
  }, [estimates, activeEntity]);

  const handleUpdateVisibleEntity = (activeEntity) => {
    setActiveEntity(activeEntity)
  }

  const runForecastReport = () => {
    console.log('Run Forecast report...')
    axios({
      method: 'get',
      url:  `${props.url}/api/proposal/generateForecastReport`,
      // data: estimate,
      responseType: 'arraybuffer'
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
      // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
      // console.log('Export response: ', response.data)
      // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
      // text/csv
      let newBlob = new Blob([response.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"})
      // console.log(newBlob)
      const data = window.URL.createObjectURL(newBlob);
      // console.log(data)
      var link = document.createElement('a');
      link.href = data;
      let fileName = 'Forecast_Report'
      link.download=`${fileName}.xlsx`;
      link.click();
      window.URL.revokeObjectURL(data);
    }).catch(error => {
      console.log('ERror exporting estimate', error)
    })
  }

  let theList = list
  if (activeEntity && activeEntity._id) {
    let filteredEstimates = estimates.filter(item => { return item.entity === activeEntity._id })
    theList = filteredEstimates
  }
  return (
    <div style={{ margin: 10 }}>
       <Paper style={{ padding: 20, margin: 10 }}>
            <Grid container spacing={2}>
            {
              (props.user && props.user.entities && props.user.entities.length > 1) ? (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ChooseEntityComponent allowAll={true} title={'Visible Entities'} user={props.user} updateParent={handleUpdateVisibleEntity} />
              </Grid>
              ) : ''
            }
            
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ActionRow generateReport={runForecastReport} perms={props.perms} />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {
                  loading ? <CircularProgress size={85} color="primary" /> : (
                    <ListOfOpenEstimates 
                      list={filteredEstimates}
                      updateParent={handleResponseFromStatusUpdate}
                    />
                  )
                }
              </Grid>
            </Grid>

          </Paper>
    </div>
  )
}

const ActionRow = ({ generateReport, perms }) => {
  const runForecastReport = () => {
    console.log('Generate Forecas Report')
    generateReport()
  }
  return (
    <div>
       <ProtectedButton type="Forecast" kind="view" perms={perms}>
        <Button
            variant="contained"
            color="primary"
            onClick={runForecastReport}
            >Run Forecast</Button>
        </ProtectedButton>
     
    </div>
  )
}

const mapStateToProps = (state) => {
  // console.log('state to props', state)
  return {
    url: state.url,
    user: state.user,
    perms: state.perms,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(OpenEstimatesDashboard)