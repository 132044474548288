// RepairDetailDialog.js
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  Chip,
  Divider,
  makeStyles,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import {
  Close as CloseIcon,
  Build as BuildIcon,
  DateRange as DateIcon,
  Person as PersonIcon,
  AttachMoney as MoneyIcon,
} from '@material-ui/icons';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  section: {
    marginBottom: theme.spacing(3),
  },
  label: {
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
  },
  value: {
    marginTop: theme.spacing(1),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  infoRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  actions: {
    padding: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 200,
  },
}));

const RepairDetailDialog = ({ open, onClose, repair, onUpdate }) => {
  const classes = useStyles();
  const [editMode, setEditMode] = React.useState(false);
  const [editedRepair, setEditedRepair] = React.useState(repair);

  React.useEffect(() => {
    setEditedRepair(repair);
  }, [repair]);

  if (!repair) return null;

  const handleStatusChange = (event) => {
    setEditedRepair({
      ...editedRepair,
      status: event.target.value
    });
  };

  const handlePriorityChange = (event) => {
    setEditedRepair({
      ...editedRepair,
      priority: event.target.value
    });
  };

  const handleSave = () => {
    onUpdate(editedRepair);
    setEditMode(false);
  };

  const formatDate = (date) => {
    try {
      return format(new Date(date), 'MM/dd/yyyy h:mm a');
    } catch {
      return 'N/A';
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant="h6">
          <BuildIcon className={classes.icon} />
          Repair Details
        </Typography>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          {/* Equipment Info */}
          <Grid item xs={12}>
            <Typography variant="h6" color="primary" gutterBottom>
              Equipment Information
            </Typography>
            <Typography variant="body1">
              {repair?.equipmentId?.name || 'N/A'} {repair?.equipmentId?.number || ''}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>

          {/* Status and Priority */}
          <Grid item xs={12} sm={6}>
            {editMode ? (
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel>Status</InputLabel>
                <Select
                  value={editedRepair.status}
                  onChange={handleStatusChange}
                  label="Status"
                >
                  <MenuItem value="Reported">Reported</MenuItem>
                  <MenuItem value="In Progress">In Progress</MenuItem>
                  <MenuItem value="Completed">Completed</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <div className={classes.section}>
                <Typography className={classes.label}>Status</Typography>
                <Chip
                  label={repair.status}
                  color={repair.status === 'Completed' ? 'primary' : 'default'}
                  className={classes.chip}
                />
              </div>
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            {editMode ? (
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel>Priority</InputLabel>
                <Select
                  value={editedRepair.priority}
                  onChange={handlePriorityChange}
                  label="Priority"
                >
                  <MenuItem value="Low">Low</MenuItem>
                  <MenuItem value="Medium">Medium</MenuItem>
                  <MenuItem value="High">High</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <div className={classes.section}>
                <Typography className={classes.label}>Priority</Typography>
                <Chip
                  label={repair.priority}
                  color={repair.priority === 'High' ? 'secondary' : 'default'}
                  className={classes.chip}
                />
              </div>
            )}
          </Grid>

          {/* Description and Notes */}
          <Grid item xs={12}>
            <div className={classes.section}>
              <Typography className={classes.label}>Description</Typography>
              {editMode ? (
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  variant="outlined"
                  value={editedRepair.description}
                  onChange={(e) => setEditedRepair({...editedRepair, description: e.target.value})}
                  className={classes.value}
                />
              ) : (
                <Typography className={classes.value}>{repair.description}</Typography>
              )}
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.section}>
              <Typography className={classes.label}>Notes</Typography>
              {editMode ? (
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  variant="outlined"
                  value={editedRepair.notes || ''}
                  onChange={(e) => setEditedRepair({...editedRepair, notes: e.target.value})}
                  className={classes.value}
                />
              ) : (
                <Typography className={classes.value}>{repair.notes || 'No notes provided'}</Typography>
              )}
            </div>
          </Grid>

          {/* Additional Details */}
          <Grid item xs={12}>
            <Divider className={classes.divider} />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <div className={classes.infoRow}>
                  <PersonIcon className={classes.icon} />
                  <div>
                    <Typography className={classes.label}>Reported By</Typography>
                    <Typography>{repair.reportedBy}</Typography>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={classes.infoRow}>
                  <DateIcon className={classes.icon} />
                  <div>
                    <Typography className={classes.label}>Date Reported</Typography>
                    <Typography>{formatDate(repair.created_at)}</Typography>
                  </div>
                </div>
              </Grid>
              {repair.cost > 0 && (
                <Grid item xs={12} sm={6}>
                  <div className={classes.infoRow}>
                    <MoneyIcon className={classes.icon} />
                    <div>
                      <Typography className={classes.label}>Cost</Typography>
                      <Typography>${repair.cost.toFixed(2)}</Typography>
                    </div>
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions className={classes.actions}>
        {editMode ? (
          <>
            <Button onClick={() => setEditMode(false)} color="default">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary" variant="contained">
              Save Changes
            </Button>
          </>
        ) : (
          <>
            <Button onClick={onClose} color="default">
              Close
            </Button>
            <Button onClick={() => setEditMode(true)} color="primary" variant="contained">
              Edit Repair
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default RepairDetailDialog;
