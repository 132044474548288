import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import { Card, CardContent, Typography } from '@material-ui/core'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import PropTypes from 'prop-types';
import { fade, makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress }  from '@material-ui/core';
import { Link } from 'react-router-dom'
import ChooseEntityComponent from '../Entities/ChooseEntityComponent'
import ARSnapshotDisplay from './ARSnapshot'

function desc(a, b, orderBy, isDate) {
  // console.log('desc: ', a)
  // console.log('desc b: ', b)
  if (isDate) {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      // console.log('B is < A')
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      // console.log('B is > A')
      return 1;
    }
    // console.log('B is ? A')
    // console.log('A: ', a[orderBy])
    // console.log('B: ', b[orderBy])
    return 0;
  } else {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      return 1;
    }
    return 0;
  }
}

function stableSort(array, cmp) {
  // console.log('Stable sort: ', array)
  // console.log('CMP: ', cmp)
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  if (orderBy==='lastUpdated') {
    // console.log('Last Updated')
    return order === 'desc' ? (a, b) => desc(a, b, orderBy, true) : (a, b) => -desc(a, b, orderBy, true);
  } else {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }
  
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
  { id: 'agId', numeric: false, disablePadding: true, label: 'AG ID' },
  { id: 'address', numeric: false, disablePadding: true, label: 'Address' },
  { id: '0-30', numeric: false, disablePadding: true, label: '0-30' },
  { id: '31-60', numeric: false, disablePadding: true, label: '31-60' },
  { id: '61-90', numeric: false, disablePadding: true, label: '61-90' },
  { id: '90+', numeric: false, disablePadding: true, label: '90+' },
  { id: 'totalValue', numeric: false, disablePadding: true, label: 'Total Owed' },
  { id: 'depositBalance', numeric: false, disablePadding: true, label: 'Deposit Balance' }
];

function EnhancedTableHead(props) {
// console.log('Got our props', props)
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    // console.log('Sort Property: ', property)
    // console.log('sort event; ', event)
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all leads' }}
          />
        </TableCell> */}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
                       <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {/* {orderBy === headCell.id ? (
                // <span className={classes.visuallyHidden}>
                //   {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                // </span>
              ) : null} */}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  sweetBorder: {
    border: '1px solid',
    borderRadius: '10px'
  },
  fakeLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue'
  }
}));

const ARComponent = (props) => {
  // console.log('ARComponent Component!!', props)
  const [data, setData] = React.useState([])
  const [arSummary, setArSummary] = React.useState([])
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('-created');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(500);
  const [searchValue, setsearchValue] = React.useState('');
  const [searchMatches, setsearchMatches] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [entity, setEntity] = React.useState({})
  const [showARSnapShots, setShowARSnapShots] = React.useState(false)
  const [snapshots, setSnapshots] = React.useState([])
  const [activeSnapshot, setActiveSnapshot] = React.useState({})

const handleClose = () => {
  setOpen(false);
  // props.onUpdate()
}

const handleOpen = () => {
  setOpen(true);
};

  const getDashboardData = async (entityToUse) => {
    // console.log('Get our AR Data')
    // if (!entityToUse) entityToUse = 'All'
    setRows([])
    setFilteredRows([])
    setLoading(true)
    const response = await axios.get(`${props.url}/api/reports/getARTable?entity=${entityToUse}`)
    if (response && response.data) {
      setRows(response.data)
      setFilteredRows(response.data)
      setLoading(false)
    }

    const arSnapShotResponse = await axios.get(`${props.url}/api/invoice/getARSnapshotData?entity=${entityToUse}`)
    if (arSnapShotResponse && arSnapShotResponse.data) {
      console.log('arSnapShotResponse', arSnapShotResponse)
      if (arSnapShotResponse.data?.length) {
        setSnapshots(arSnapShotResponse.data)
        setActiveSnapshot(arSnapShotResponse.data[arSnapShotResponse.data.length - 1])
      }
    }
  }
  React.useEffect(() => {
    getDashboardData('All')
  }, [])

  function currencyFormat(num) {
    if (!num) num = 0
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }



// function currencyFormat(num) {
//   if (!num) num = 0
//   return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
// }

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = 0// rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const filterOut = (r) => {
    // console.log('FIlter out: ', r)
    let string = searchValue
    let newArr = []
    r.filter(o => {
      // console.log('Object:  ', o)
      Object.keys(o).some(k => {
        // console.log(o[k])
        if(o[k]) {
          // console.log('OK: ', o[k])
          let str = o[k].toString().toLowerCase() 
          // console.log(str)
          if (str.includes(string.toLowerCase())) {
            // console.log('Mathc')
            return newArr.push(o)
          } else {
            return null
          }
        } else {
          return null
        }
      })
      // return newArr
    })
    // return newArr
    setFilteredRows(newArr)
    // setsearchMatches(newArr.length)
    // let newArr = r.filter(i => {
    //   if (i.includes('test')) return i
    // })
    // return newArr
    // return r.filter((obj)=>{
    //   return Object.keys(obj).reduce((acc, curr)=>{
    //         return acc || obj[curr] ? obj[curr].toString().toLowerCase().includes(string) : obj[curr];
    //   }, false);
    // })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (name) => (event) => {
    // console.log('Searching: ', event.target.value)
    setsearchValue(event.target.value)
    if (event.target.value === '') {
      setFilteredRows(rows)
    } else {
      filterOut(rows, event.target.value)
    }
    setPage(0)
  }

  function downloadFile(absoluteUrl) {
    var link = document.createElement('a');
    link.href = absoluteUrl;
    link.download = 'true';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // delete link;
 };

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    // console.log('Set order by property: ' + property + ' in ' + isDesc + ' order')
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n._id);
      // console.log(newSelecteds)
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleUpdateVisibleEntity = (newEntity) => {
  // console.log('Entity Selected', newEntity)
    if (newEntity && newEntity._id) {
      setEntity(newEntity)
      getDashboardData(newEntity._id)
    } else {
      setEntity({})
      getDashboardData('All')
    }
  }
  const toggleARSnapshot = () => {
    setShowARSnapShots(!showARSnapShots)
  }

  return (
      <Card style={{margin: '5px'}}>
        <CardContent>
        {
          (props.user && props.user.entities && props.user.entities.length > 1) ? (
            <Grid container style={{padding: '10px'}}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <ChooseEntityComponent allowAll={true} title={'Visible Entities'} disabled={loading} user={props.user} updateParent={handleUpdateVisibleEntity} />
            </Grid>
          </Grid>
          ) : ''
        }
        <Button
          variant='contained'
          color='primary'
          onClick={toggleARSnapshot}
        >
          {
            showARSnapShots ? `View AR Balances` : `View AR Snapshots`
          }</Button>
         
          
          {
            showARSnapShots ? (
              <div>
               
                {
                  activeSnapshot?._id &&  <ARSnapshotDisplay
                  snapshots={snapshots || []}
                  entity={entity}
                  url={props.url}
                  notification={props.notification}
                />
                }
               
              </div>
            ) : (
              <div>
                 <Grid container>
                    <Grid item lg={6}>
                    <Typography variant="h5" component="h2" gutterBottom>
                    Account Balance Details:
                  </Typography>
                  { loading ?<CircularProgress style={{color: 'green'}} size={ 24 } /> : ''}
                    </Grid>
                    <Grid item lg={6}>

                    </Grid>
                  </Grid>
        <div className={classes.tableWrapper} style={{margin: '10px'}}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size='small'
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody >
              {stableSort(filteredRows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  // console.log('Row map: ', row)
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  // setsearchMatches(rows.length)
                  // console.log('ROW', row)
                  return (
                    <TableRow
                      hover
                      // onClick={event => handleClick(event, row.number)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      selected={isItemSelected}
                      style ={ index % 2? { background : "#ddd" }:{ background : "#fff" }}
                    >
                      <TableCell component="td" id={labelId} scope="row" padding="none">
                        <Link 
                          style={{pointer: 'cursor'}}
                          target="_blank"
                          to={{
                            pathname: '/clients/' + row._id,
                            // search: '?sort=name',
                            // hash: '#the-hash',
                            state: {customer: row, stuff: 'Account Balance Report'}
                          }}> {row.name} ({row.agId})
                        </Link> 
                      </TableCell>
                      <TableCell component="td" id={labelId} scope="row" padding="none">
                        {row.agId}
                      </TableCell>  
                      <TableCell component="td" id={labelId} scope="row" padding="none">
                      {row.address}
                      </TableCell>  
                      <TableCell component="td" id={labelId} scope="row" padding="none">
                        {currencyFormat(row['0-30'])}
                      </TableCell>
                      <TableCell component="td" id={labelId} scope="row" padding="none">
                        {currencyFormat(row['31-60'])}
                      </TableCell>  
                      <TableCell component="td" id={labelId} scope="row" padding="none">
                      {currencyFormat(row['61-90'])}
                      </TableCell>      
                      <TableCell component="td" id={labelId} scope="row" padding="none">
                      {currencyFormat(row['90+'])}
                      </TableCell>      
                      <TableCell component="td" id={labelId} scope="row" padding="none">
                        {currencyFormat(row.totalValue || 0)}
                      </TableCell>    
                      <TableCell component="td" id={labelId} scope="row" padding="none">
                        {currencyFormat(row.depositBalance || 0)}
                      </TableCell>                 
                    </TableRow>
                  );
                })
              }
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
   
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50, 100, 500, 1000, 1500]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        </div>
            )}
        </CardContent>
      </Card>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(ARComponent)