import React, { useState } from 'react';
import { 
  List, 
  ListItem, 
  ListItemText, 
  Collapse, 
  Typography, 
  Paper,
  makeStyles,
  Grid,
  Tabs,
  Tab,
  Box
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { 
  LineChart, 
  Line, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  PieChart, 
  Pie, 
  Cell, 
  BarChart, 
  Bar,
  ResponsiveContainer,
  AreaChart,
  Area,
  Scatter,
  ScatterChart,
  ZAxis,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  ComposedChart
} from 'recharts';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 1200,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  chartContainer: {
    marginTop: theme.spacing(2),
  },
  chartPaper: {
    padding: theme.spacing(2),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  chartTitle: {
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    fontWeight: 500
  },
  tabs: {
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  tabPanel: {
    padding: theme.spacing(2, 0),
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0, 2, 0, 0),
  },
  legendColor: {
    width: 12,
    height: 12,
    marginRight: theme.spacing(1),
    borderRadius: 2,
  },
  legendContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(2),
  },
  summaryCard: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
    height: 116
  },
  summaryValue: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  summaryLabel: {
    fontSize: '0.875rem',
    color: theme.palette.text.secondary,
  },
}));

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];

const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  return new Date(dateString).toLocaleDateString();
};

const getPriorityColor = (priority) => {
  switch(priority) {
    case 'High': return '#f44336';
    case 'Medium': return '#ff9800';
    case 'Low': return '#4caf50';
    default: return '#757575';
  }
};

const getRepairTypeFromDetails = (details) => {
  if (!details || !Array.isArray(details) || details.length === 0) {
    return 'Unspecified';
  }
  return details[0]?.mainCategory || 'Unspecified';
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`repair-tabpanel-${index}`}
      aria-labelledby={`repair-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={props.className}>
          {children}
        </Box>
      )}
    </div>
  );
}

const RepairList = ({ repairs, equipmentName }) => {
  const classes = useStyles();
  const [open, setOpen] = useState({});
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClick = (id) => {
    setOpen((prevOpen) => ({ ...prevOpen, [id]: !prevOpen[id] }));
  };

  // Helper to get month and year
  const getMonthYear = (dateString) => {
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  // Sort repairs by date
  const sortedRepairs = [...repairs]
    .filter(repair => repair.date)
    .sort((a, b) => new Date(a.date) - new Date(b.date));

  // Prepare data for charts
  const lineChartData = sortedRepairs
    .map(repair => ({
      date: formatDate(repair.date),
      cost: repair.cost || 0
    }));

  // Monthly cost aggregation
  const monthlyRepairCosts = sortedRepairs.reduce((acc, repair) => {
    const monthYear = getMonthYear(repair.date);
    if (!acc[monthYear]) {
      acc[monthYear] = { month: monthYear, cost: 0, count: 0 };
    }
    acc[monthYear].cost += (repair.cost || 0);
    acc[monthYear].count += 1;
    return acc;
  }, {});

  const monthlyChartData = Object.values(monthlyRepairCosts)
    .sort((a, b) => {
      const [aMonth, aYear] = a.month.split('/');
      const [bMonth, bYear] = b.month.split('/');
      return (parseInt(aYear) - parseInt(bYear)) || (parseInt(aMonth) - parseInt(bMonth));
    });

  // Repair type distribution
  const repairTypeCount = repairs.reduce((acc, repair) => {
    const type = getRepairTypeFromDetails(repair.details);
    acc[type] = (acc[type] || 0) + 1;
    return acc;
  }, {});
  
  const pieChartData = Object.entries(repairTypeCount)
    .map(([name, value]) => ({ name, value }));

  // Cost by repair type
  const repairTypeCost = repairs.reduce((acc, repair) => {
    const type = getRepairTypeFromDetails(repair.details);
    acc[type] = (acc[type] || 0) + (repair.cost || 0);
    return acc;
  }, {});

  const costByTypeData = Object.entries(repairTypeCost)
    .map(([name, cost]) => ({ name, cost }))
    .sort((a, b) => b.cost - a.cost);

  // Priority distribution
  const priorityCount = repairs.reduce((acc, repair) => {
    const priority = repair.priority || 'Unspecified';
    acc[priority] = (acc[priority] || 0) + 1;
    return acc;
  }, {});

  const priorityData = Object.entries(priorityCount)
    .map(([name, value]) => ({ name, value }));

  // Status distribution
  const statusCount = repairs.reduce((acc, repair) => {
    const status = repair.status || 'Unspecified';
    acc[status] = (acc[status] || 0) + 1;
    return acc;
  }, {});

  const statusData = Object.entries(statusCount)
    .map(([name, value]) => ({ name, value }));
  
  // Repair duration calculation
  const repairDurationData = repairs
    .filter(repair => repair.date && repair.completedDate)
    .map(repair => {
      const startDate = new Date(repair.date);
      const endDate = new Date(repair.completedDate);
      const durationDays = Math.round((endDate - startDate) / (1000 * 60 * 60 * 24));
      return {
        id: repair._id || repair.id,
        type: getRepairTypeFromDetails(repair.details),
        description: repair.description || 'No description',
        duration: durationDays,
        cost: repair.cost || 0,
        priority: repair.priority || 'Low'
      };
    });

  // Radar chart data for repair metrics
  const radarChartData = [
    { subject: 'Cost', A: Math.min(...repairs.map(r => r.cost || 0)), fullMark: Math.max(...repairs.map(r => r.cost || 0)) },
    { subject: 'Duration', A: Math.min(...repairDurationData.map(r => r.duration || 0)), fullMark: Math.max(...repairDurationData.map(r => r.duration || 0)) },
    { subject: 'Priority', A: repairs.filter(r => r.priority === 'Low').length, fullMark: repairs.length },
    { subject: 'Out of Service', A: repairs.filter(r => r.outOfService).length, fullMark: repairs.length },
    { subject: 'Parts Required', A: repairs.filter(r => r.requiresParts).length, fullMark: repairs.length },
  ];

  // Top repeat repairs
  const repeatRepairTypes = repairs.reduce((acc, repair) => {
    const types = repair.details && Array.isArray(repair.details) 
      ? repair.details.map(d => d.mainCategory) 
      : ['Unspecified'];
    
    types.forEach(type => {
      if (!type) return;
      acc[type] = (acc[type] || 0) + 1;
    });
    return acc;
  }, {});

  const repeatRepairsData = Object.entries(repeatRepairTypes)
    .map(([name, count]) => ({ name, count }))
    .sort((a, b) => b.count - a.count)
    .slice(0, 5);
  
  // Cost-benefit analysis (comparing frequency with cost)
  const costFrequencyData = Object.entries(repairTypeCount).map(([name, count]) => ({
    name,
    frequency: count,
    totalCost: repairTypeCost[name] || 0,
    averageCost: Math.round((repairTypeCost[name] || 0) / count)
  }));

  // Calculate summary stats
  const totalRepairs = repairs.length;
  const totalCost = repairs.reduce((sum, repair) => sum + (repair.cost || 0), 0);
  const avgCost = totalRepairs > 0 ? (totalCost / totalRepairs).toFixed(2) : 0;
  const activeRepairs = repairs.filter(r => r.status !== 'Completed').length;
  const avgDuration = repairDurationData.length > 0 
    ? (repairDurationData.reduce((sum, data) => sum + data.duration, 0) / repairDurationData.length).toFixed(1)
    : 'N/A';
  console.log('304')

  // Get purchases from purchaseOrders if they exist
  const getPurchasesFromRepair = (repair) => {
    if (repair.purchaseOrders && Array.isArray(repair.purchaseOrders) && repair.purchaseOrders.length > 0) {
      return repair.purchaseOrders.map((po, index) => ({
        item: po.vendorName || `Purchase Order ${index + 1}`,
        quantity: 1,
        cost: po.amount || 0
      }));
    }
    return [];
  };

  const showTheTabs = false
  return (
    <div className={classes.root}>
      {/* Summary Stats Cards */}
      <Grid container spacing={3}>
        <Grid item xs={6} sm={3}>
          <Paper className={classes.summaryCard}>
            <Typography className={classes.summaryLabel}>Total Repairs</Typography>
            <Typography className={classes.summaryValue}>{totalRepairs}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Paper className={classes.summaryCard}>
            <Typography className={classes.summaryLabel}>Total Cost</Typography>
            <Typography className={classes.summaryValue}>${totalCost.toLocaleString()}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Paper className={classes.summaryCard}>
            <Typography className={classes.summaryLabel}>Avg. Cost per Repair</Typography>
            <Typography className={classes.summaryValue}>${avgCost}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Paper className={classes.summaryCard}>
            <Typography className={classes.summaryLabel}>Avg. Repair Duration</Typography>
            <Typography className={classes.summaryValue}>
              {avgDuration === 'N/A' ? avgDuration : `${avgDuration} days`}
            </Typography>
          </Paper>
        </Grid>
      </Grid>

    {
      showTheTabs && (
        <TabStuff
          handleTabChange={handleTabChange}
          tabValue={tabValue}
          priorityData={priorityData}
          monthlyChartData={monthlyChartData}
          classes={classes}
          costByTypeData={costByTypeData}
          lineChartData={lineChartData}
          statusData={statusData}
          costFrequencyData={costFrequencyData}
          repairDurationData={repairDurationData}
          pieChartData={pieChartData}
        />
      )
    }
      <List component="nav">
        {repairs.map((repair) => (
          <React.Fragment key={repair._id || repair.id}>
            <ListItem 
              button 
              onClick={() => handleClick(repair._id || repair.id)}
              style={{
                borderLeft: `4px solid ${getPriorityColor(repair.priority)}`
              }}
            >
              <ListItemText 
                primary={`${equipmentName} - ${formatDate(repair.date)}`} 
                secondary={`${repair.status} - ${repair.description || 'No description'}`}
              />
              {open[repair._id || repair.id] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open[repair._id || repair.id]} timeout="auto" unmountOnExit>
              <Paper className={classes.paper}>
                <Typography variant="h6">Repair Details</Typography>
                <Typography><strong>Description:</strong> {repair.description || 'No description'}</Typography>
                <Typography><strong>Status:</strong> {repair.status}</Typography>
                <Typography><strong>Priority:</strong> {repair.priority}</Typography>
                <Typography><strong>Cost:</strong> ${repair.cost || 0}</Typography>
                <Typography><strong>Out of Service:</strong> {repair.outOfService ? 'Yes' : 'No'}</Typography>
                <Typography><strong>Requires Parts:</strong> {repair.requiresParts ? 'Yes' : 'No'}</Typography>
                <Typography><strong>Date Reported:</strong> {formatDate(repair.date)}</Typography>
                <Typography><strong>Date Completed:</strong> {formatDate(repair.completedDate)}</Typography>
                <Typography><strong>Reported By:</strong> {repair.reportedBy || 'Unknown'}</Typography>
                
                {repair.details && repair.details.length > 0 && (
                  <>
                    <Typography variant="h6" style={{ marginTop: '16px' }}>Repair Categories</Typography>
                    {repair.details.map((detail, idx) => (
                      <div key={idx}>
                        <Typography><strong>{detail.mainCategory}</strong></Typography>
                        {detail.subCategories && detail.subCategories.length > 0 && (
                          <ul>
                            {detail.subCategories.map((sub, subIdx) => (
                              <li key={subIdx}>{sub}</li>
                            ))}
                          </ul>
                        )}
                      </div>
                    ))}
                  </>
                )}
                
                {repair.notes && (
                  <>
                    <Typography variant="h6" style={{ marginTop: '16px' }}>Notes</Typography>
                    <Typography>{repair.notes}</Typography>
                  </>
                )}
                
                <Typography variant="h6" style={{ marginTop: '16px' }}>Purchase Orders</Typography>
                {repair.purchaseOrders && repair.purchaseOrders.length > 0 ? (
                  <List>
                    {repair.purchaseOrders.map((po, index) => (
                      <ListItem key={index}>
                        <ListItemText 
                          primary={po.vendorName || `Purchase Order ${index + 1}`} 
                          secondary={`Cost: $${po.amount || 0}`} 
                        />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Typography>No purchase orders available</Typography>
                )}
              </Paper>
            </Collapse>
          </React.Fragment>
        ))}
      </List>
    </div>
  );
};

const TabStuff = ({
  handleTabChange,
  tabValue,
  monthlyChartData,
  classes,
  costByTypeData,
  lineChartData,
  statusData,
  costFrequencyData,
  repairDurationData,
  pieChartData,
  priorityData
}) => {

  return (
    <div>
            {/* Dashboard Tabs */}
            <Tabs
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        className={classes.tabs}
      >
        <Tab label="Cost Analysis" />
        <Tab label="Repair Categories" />
        <Tab label="Performance Metrics" />
        <Tab label="Maintenance Patterns" />
      </Tabs>

      {/* Cost Analysis Tab */}
      <TabPanel value={tabValue} index={0} className={classes.tabPanel}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper className={classes.chartPaper}>
              <Typography variant="h6" className={classes.chartTitle}>Monthly Repair Costs</Typography>
              <ResponsiveContainer width="100%" height={300}>
                {monthlyChartData.length > 0 ? (
                  <ComposedChart data={monthlyChartData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis yAxisId="left" />
                    <YAxis yAxisId="right" orientation="right" />
                    <Tooltip />
                    <Legend />
                    <Bar yAxisId="left" dataKey="cost" fill="#4A90E2" name="Total Cost ($)" />
                    <Line yAxisId="right" type="monotone" dataKey="count" stroke="#FF8042" name="Number of Repairs" />
                  </ComposedChart>
                ) : (
                  <Typography color="textSecondary">No monthly cost data available</Typography>
                )}
              </ResponsiveContainer>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper className={classes.chartPaper}>
              <Typography variant="h6" className={classes.chartTitle}>Costs by Repair Type</Typography>
              <ResponsiveContainer width="100%" height={300}>
                {costByTypeData.length > 0 ? (
                  <BarChart data={costByTypeData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip formatter={(value) => [`${value}`, 'Cost']} />
                    <Bar dataKey="cost" fill="#8884d8">
                      {costByTypeData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Bar>
                  </BarChart>
                ) : (
                  <Typography color="textSecondary">No repair cost data available</Typography>
                )}
              </ResponsiveContainer>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.chartPaper}>
              <Typography variant="h6" className={classes.chartTitle}>Repair Cost Timeline</Typography>
              <ResponsiveContainer width="100%" height={300}>
                {lineChartData.length > 0 ? (
                  <AreaChart data={lineChartData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip formatter={(value) => [`${value}`, 'Cost']} />
                    <Legend />
                    <Area type="monotone" dataKey="cost" stroke="#8884d8" fill="#8884d8" fillOpacity={0.3} />
                  </AreaChart>
                ) : (
                  <Typography color="textSecondary">No cost timeline data available</Typography>
                )}
              </ResponsiveContainer>
            </Paper>
          </Grid>
        </Grid>
      </TabPanel>

      {/* Repair Categories Tab */}
      <TabPanel value={tabValue} index={1} className={classes.tabPanel}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper className={classes.chartPaper}>
              <Typography variant="h6" className={classes.chartTitle}>Repair Type Distribution</Typography>
              <ResponsiveContainer width="100%" height={300}>
                {pieChartData.length > 0 ? (
                  <PieChart>
                    <Pie
                      data={pieChartData}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius={100}
                      fill="#8884d8"
                      dataKey="value"
                      label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                    >
                      {pieChartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip formatter={(value, name, props) => [`${value} repairs`, name]} />
                  </PieChart>
                ) : (
                  <Typography color="textSecondary">No repair type data available</Typography>
                )}
              </ResponsiveContainer>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper className={classes.chartPaper}>
              <Typography variant="h6" className={classes.chartTitle}>Repair Priority Distribution</Typography>
              <ResponsiveContainer width="100%" height={300}>
                {priorityData.length > 0 ? (
                  <BarChart data={priorityData} layout="vertical">
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" />
                    <YAxis dataKey="name" type="category" />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="value" fill="#82ca9d" name="Number of Repairs">
                      {priorityData.map((entry, index) => {
                        let color = '#82ca9d';
                        if (entry.name === 'High') color = '#ff8042';
                        else if (entry.name === 'Medium') color = '#ffbb28';
                        return <Cell key={`cell-${index}`} fill={color} />;
                      })}
                    </Bar>
                  </BarChart>
                ) : (
                  <Typography color="textSecondary">No priority data available</Typography>
                )}
              </ResponsiveContainer>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper className={classes.chartPaper}>
              <Typography variant="h6" className={classes.chartTitle}>Repair Status Distribution</Typography>
              <ResponsiveContainer width="100%" height={300}>
                {statusData.length > 0 ? (
                  <PieChart>
                    <Pie
                      data={statusData}
                      cx="50%"
                      cy="50%"
                      labelLine={true}
                      outerRadius={100}
                      fill="#8884d8"
                      dataKey="value"
                      label
                    >
                      {statusData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                ) : (
                  <Typography color="textSecondary">No status data available</Typography>
                )}
              </ResponsiveContainer>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper className={classes.chartPaper}>
              <Typography variant="h6" className={classes.chartTitle}>Cost vs. Frequency Analysis</Typography>
              <ResponsiveContainer width="100%" height={300}>
                {costFrequencyData.length > 0 ? (
                  <ScatterChart>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" dataKey="frequency" name="Repair Frequency" unit=" repairs" />
                    <YAxis type="number" dataKey="averageCost" name="Average Cost" unit="$" />
                    <ZAxis type="number" dataKey="totalCost" range={[50, 400]} name="Total Cost" />
                    <Tooltip 
                      formatter={(value, name, props) => {
                        if (name === 'Average Cost') return [`${value}`, name];
                        if (name === 'Repair Frequency') return [`${value} repairs`, name];
                        return [value, name];
                      }}
                      cursor={{ strokeDasharray: '3 3' }}
                      content={({active, payload}) => {
                        if (active && payload && payload.length) {
                          return (
                            <div style={{background: 'white', padding: '10px', border: '1px solid #ccc'}}>
                              <p>{`${payload[0].payload.name}`}</p>
                              <p>{`Frequency: ${payload[0].value} repairs`}</p>
                              <p>{`Avg Cost: ${payload[0].payload.averageCost}`}</p>
                              <p>{`Total Cost: ${payload[0].payload.totalCost}`}</p>
                            </div>
                          );
                        }
                        return null;
                      }}
                    />
                    <Legend />
                    <Scatter name="Repair Types" data={costFrequencyData} fill="#8884d8">
                      {costFrequencyData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Scatter>
                  </ScatterChart>
                ) : (
                  <Typography color="textSecondary">No cost-frequency data available</Typography>
                )}
              </ResponsiveContainer>
            </Paper>
          </Grid>
        </Grid>
      </TabPanel>

      {/* Performance Metrics Tab */}
      <TabPanel value={tabValue} index={2} className={classes.tabPanel}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper className={classes.chartPaper}>
              <Typography variant="h6" className={classes.chartTitle}>Repair Duration vs. Cost</Typography>
              <ResponsiveContainer width="100%" height={300}>
                {repairDurationData.length > 0 ? (
                  <ScatterChart>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" dataKey="duration" name="Duration" unit=" days" />
                    <YAxis type="number" dataKey="cost" name="Cost" unit="$" />
                    <Tooltip cursor={{ strokeDasharray: '3 3' }} 
                      formatter={(value, name) => {
                        if (name === 'Cost') return [`${value}`, name];
                        if (name === 'Duration') return [`${value} days`, name];
                        return [value, name];
                      }}
                      content={({active, payload}) => {
                        if (active && payload && payload.length) {
                          return (
                            <div style={{background: 'white', padding: '10px', border: '1px solid #ccc'}}>
                              <p>{`${payload[0].payload.description}`}</p>
                              <p>{`Type: ${payload[0].payload.type}`}</p>
                              <p>{`Duration: ${payload[0].payload.duration} days`}</p>
                              <p>{`Cost: ${payload[0].payload.cost}`}</p>
                              <p>{`Priority: ${payload[0].payload.priority}`}</p>
                            </div>
                          );
                        }
                        return null;
                      }}
                    />
                    <Legend />
                    <Scatter name="Repairs" data={repairDurationData} fill="#8884d8">
                      {repairDurationData.map((entry) => {
                        let color = '#4A90E2';
                        if (entry.priority === 'High') color = '#ff8042';
                        else if (entry.priority === 'Medium') color = '#ffbb28';
                        return <Cell key={entry.id} fill={color} />;
                      })}
                    </Scatter>
                  </ScatterChart>
                ) : (
                  <Typography color="textSecondary">No duration data available</Typography>
                )}
              </ResponsiveContainer>
              <Box className={classes.legendContainer}>
                <Box className={classes.legendItem}>
                  <Box className={classes.legendColor} style={{ backgroundColor: '#4A90E2' }}></Box>
                  <Typography variant="body2">Low Priority</Typography>
                </Box>
                <Box className={classes.legendItem}>
                  <Box className={classes.legendColor} style={{ backgroundColor: '#ffbb28' }}></Box>
                  <Typography variant="body2">Medium Priority</Typography>
                </Box>
                <Box className={classes.legendItem}>
                  <Box className={classes.legendColor} style={{ backgroundColor: '#ff8042' }}></Box>
                  <Typography variant="body2">High Priority</Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper className={classes.chartPaper}>
            </Paper>
          </Grid>
        </Grid>
      </TabPanel>
    </div>
  )
}
export default RepairList;