import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import { Card, CardContent, Typography } from '@material-ui/core'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import { fade, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import SearchIcon from '@material-ui/icons/Search';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { socket } from '../../SocketIo/Socket'
import DivisionBreakdownReportTable from '../DivisonBreakdownReportTable'

function desc(a, b, orderBy, isDate) {
  // console.log('desc: ', a)
  // console.log('desc b: ', b)
  if (isDate) {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      // console.log('B is < A')
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      // console.log('B is > A')
      return 1;
    }
    // console.log('B is ? A')
    // console.log('A: ', a[orderBy])
    // console.log('B: ', b[orderBy])
    return 0;
  } else {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      return 1;
    }
    return 0;
  }
}

function stableSort(array, cmp) {
// console.log('Stable sort: ', array)
// console.log('CMP: ', cmp)
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  if (orderBy==='lastUpdated') {
  // console.log('Last Updated')
    return order === 'desc' ? (a, b) => desc(a, b, orderBy, true) : (a, b) => -desc(a, b, orderBy, true);
  } else {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }
  
}

const headCells = [
  { id: 'client', numeric: false, disablePadding: true, label: 'Client Name' },
  { id: 'invoiceNumber', numeric: false, disablePadding: true, label: 'Invoice Number' },
  { id: 'jobSite', numeric: false, disablePadding: true, label: 'Jobsite' },
  { id: 'amount', numeric: false, disablePadding: true, label: 'Amount' },
  { id: 'invoiceDate', numeric: false, disablePadding: true, label: 'Created At' }
];

function EnhancedTableHead(props) {
// console.log('Got our props', props)
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
  // console.log('Sort Property: ', property)
  // console.log('sort event; ', event)
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all leads' }}
          />
        </TableCell> */}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
                       <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {/* {orderBy === headCell.id ? (
                // <span className={classes.visuallyHidden}>
                //   {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                // </span>
              ) : null} */}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  sweetBorder: {
    border: '1px solid',
    borderRadius: '10px'
  },
  fakeLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue'
  },
  tableWrapper: {
    maxHeight: '55vh',
    overflow: 'scroll'
  }
}));

const GrossProfitByDivisionReport = (props) => {
  const classes = useStyles();


  const handleCSVExport = async () => {
    console.log('export our data...')
    try {
      const response = await axios.post(`${props.url}/api/reports/generateACSV`, props.data, {
        responseType: 'blob', // Important: This tells Axios to handle the response as a Blob
      });
  
      if (response.data) {
        // Create a URL for the blob
        const url = window.URL.createObjectURL(new Blob([response.data]));
        // Create a temporary anchor element and trigger a download
        const link = document.createElement('a');
        link.href = url;
        // It's a good idea to name the downloaded file appropriately
        // You might need to adjust the filename based on your requirements or response headers
        link.setAttribute('download', 'file.csv'); // or 'file.xlsx'
        document.body.appendChild(link);
        link.click();
  
        // Clean up by removing the link and revoking the URL
        // link.parentNode.removeChild(link);
        // window.URL.revokeObjectURL(url);
  
        console.log('HMI Mtc EXCEL EXPORT', response.data);
      }
    } catch (error) {
      console.error('Download error', error);
      // Handle errors, such as displaying a notification to the user
    }
  }

  return (
      <Card style={{margin: '5px'}}>
        <CardContent>
          <Grid container>
            <Grid item lg={6}>
              <Typography variant="h5" component="h2" gutterBottom>
                Gross Profit By Division Report:
              </Typography>
            </Grid>
            <Grid item lg={6}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<ImportExportIcon />}
                onClick={handleCSVExport}
              >
                Export to Excel
              </Button>
            </Grid>
            
          </Grid>
          
          
          
        <div className={classes.tableWrapper}>
         <DivisionBreakdownReportTable data={props.data} />
        </div>
        
        </CardContent>
      </Card>
  )
}

  const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(GrossProfitByDivisionReport)
