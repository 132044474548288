import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Paper, Typography, Grid, Box, CircularProgress, Button, makeStyles, ButtonGroup } from '@material-ui/core'; // Added ButtonGroup
import { v4 as uuidv4 } from 'uuid';
import FreeDraw, { CREATE, EDIT, DELETE, APPEND, ALL, NONE } from 'leaflet-freedraw';

// Import Child Components
import MapProviderControls from './MapProviderControls';
import DrawingToolbar from './DrawingToolbar';
import DrawingList from './DrawingList';
import DrawingEditor from './DrawingEditor';
import MapComponent from './MapComponent';

// Import Utilities and Constants
import {
    loadEsriLeaflet, checkYearService, defaultStyle, getGeometryFromLayer,
    googleMapsProvider, googleSatelliteProvider, googleHybridProvider
} from './mapUtils';

// Define Styles
const useStyles = makeStyles((theme) => ({
    mapWrapper: {
        position: 'relative',
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    controlsWrapper: {
        position: 'relative',
        zIndex: 2,
        marginBottom: theme.spacing(2),
    },
    drawingControls: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
    },
    drawingList: {
        maxHeight: 300,
        overflowY: 'auto',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
    },
    colorSwatch: {
        width: 24, height: 24, border: '1px solid #ccc', borderRadius: '4px',
        display: 'inline-block', marginLeft: theme.spacing(1), cursor: 'pointer', verticalAlign: 'middle'
    },
    buttonGroupColumn: { // Renamed for clarity
        display: 'flex', flexDirection: 'column', gap: theme.spacing(1),
    },
    modeSelectionGroup: { // Style for Polygon/Line buttons
        marginBottom: theme.spacing(2),
        '& > *': {
            marginRight: theme.spacing(1),
        },
    }
}));


const DynamicMaricopaMap = () => {
    const classes = useStyles();

    // --- State ---
    const [providers, setProviders] = useState([googleSatelliteProvider, googleMapsProvider]);
    const [currentProvider, setCurrentProvider] = useState(googleSatelliteProvider);
    const [yearProvidersLoading, setYearProvidersLoading] = useState(true);
    const [initError, setInitError] = useState(null);

    const [drawings, setDrawings] = useState([]);
    const [selectedDrawingId, setSelectedDrawingId] = useState(null);
    const [editingShape, setEditingShape] = useState(null);

    const [isDrawingModeActive, setIsDrawingModeActive] = useState(false); // Renamed for clarity
    const [currentDrawTool, setCurrentDrawTool] = useState(null); // e.g., 'polygon', 'edit', 'freedraw-polygon', 'freedraw-line'
    const [drawingMode, setDrawingMode] = useState('polygon'); // ADDED: 'polygon' or 'line' for freehand intent
    const [isHybridMode, setIsHybridMode] = useState(false);

    const [currentZoom, setCurrentZoom] = useState(12);
    const [currentCenter, setCurrentCenter] = useState([33.55, -112.0]);
    const mapBoundsRef = useRef(null);

    const mapInstanceRef = useRef(null);
    const drawnItemsLayerRef = useRef(null); // L.FeatureGroup ref
    const freeDrawInstanceRef = useRef(null);
    const drawControlInstanceRef = useRef(null);

    // --- Effects --- (Keep existing effects for providers, load/save, map view)

    // Load Maricopa providers on mount
    useEffect(() => {
        const fetchProviders = async () => {
            setYearProvidersLoading(true);
            setInitError(null);
             await loadEsriLeaflet();

            const currentActualYear = new Date().getFullYear();
            const yearsToAttempt = [ currentActualYear, currentActualYear - 1, currentActualYear - 2, currentActualYear - 3, currentActualYear - 4, 2019, 2018 ];
            console.log("Checking availability for years:", yearsToAttempt);

            const promises = yearsToAttempt.map(year => checkYearService(year));
            const results = await Promise.all(promises);
            const availableYears = results.filter(Boolean).sort((a, b) => b.year - a.year);

            console.log("Available Maricopa providers:", availableYears.map(p => p.year));
            setProviders(prev => [googleSatelliteProvider, googleMapsProvider, ...availableYears]);

            const latestMaricopa = availableYears[0];
            if (!currentProvider || currentProvider.group === 'google') {
                 setCurrentProvider(latestMaricopa || googleSatelliteProvider);
            }

            setYearProvidersLoading(false);
        };
        fetchProviders().catch(err => {
             console.error("Critical error fetching providers:", err);
             setInitError("Failed to load map services information. Please try refreshing.");
             setYearProvidersLoading(false);
        });
    }, []);

    // Load/Save drawings from/to Local Storage
    useEffect(() => {
        const saved = localStorage.getItem('mapDrawingsV2');
        if (saved) {
            try {
                const parsed = JSON.parse(saved);
                if (Array.isArray(parsed)) setDrawings(parsed);
                else localStorage.removeItem('mapDrawingsV2');
            } catch (e) { console.error("Failed to parse drawings:", e); localStorage.removeItem('mapDrawingsV2'); }
        }
    }, []);

    useEffect(() => {
        if (drawings.length > 0 || localStorage.getItem('mapDrawingsV2') !== null) {
            try { localStorage.setItem('mapDrawingsV2', JSON.stringify(drawings)); }
            catch (e) { console.error("Failed to save drawings (Quota?):", e); }
        }
    }, [drawings]);

     // Restore Map Bounds
     useEffect(() => {
        const storedBoundsData = sessionStorage.getItem('maricopa-map-view');
        if (storedBoundsData) {
            try {
                const data = JSON.parse(storedBoundsData);
                if (data.center?.lat && data.center?.lng && data.zoom) {
                    setCurrentCenter([data.center.lat, data.center.lng]);
                    setCurrentZoom(data.zoom);
                    if(data.bounds) mapBoundsRef.current = data.bounds;
                    console.log('Restored map view from session storage.');
                }
            } catch (e) { console.error('Error parsing stored map view:', e); }
        }
    }, []);

    // Save Map Bounds
    const saveMapBounds = useCallback((view) => {
         if (!view || !view.center || !view.bounds || typeof view.zoom !== 'number') return;
         try {
            const dataToStore = {
                center: view.center,
                zoom: view.zoom,
                bounds: {
                     north: view.bounds.getNorth(), south: view.bounds.getSouth(),
                     east: view.bounds.getEast(), west: view.bounds.getWest(),
                }
            };
            sessionStorage.setItem('maricopa-map-view', JSON.stringify(dataToStore));
        } catch (e) { console.error('Error saving map view:', e); }
    }, []);

    const handleHybridToggle = useCallback((isChecked) => {
        setIsHybridMode(isChecked);
        if (!currentProvider || currentProvider.group !== 'google') return;
        const targetProvider = isChecked ? googleHybridProvider : googleSatelliteProvider;
        if ((isChecked && currentProvider.name === googleSatelliteProvider.name) ||
            (!isChecked && currentProvider.name === googleHybridProvider.name)) {
             setCurrentProvider(targetProvider);
        }
    }, [currentProvider]);

    const handleProviderChange = useCallback((selectedProvider) => {
         if (!selectedProvider) return;
         if (selectedProvider.name === googleSatelliteProvider.name && isHybridMode) {
             setCurrentProvider(googleHybridProvider);
         } else {
             setCurrentProvider(selectedProvider);
             if (selectedProvider.group !== 'google') setIsHybridMode(false);
         }
    }, [isHybridMode]);

    // --- Callbacks from MapComponent ---

    const handleMapReady = useCallback(({ map, drawnItemsLayer, freeDrawInstance, drawControlInstance }) => {
        console.log("DynamicMaricopaMap: Map is ready, received refs.");
        mapInstanceRef.current = map;
        drawnItemsLayerRef.current = drawnItemsLayer; // Store the L.FeatureGroup ref
        freeDrawInstanceRef.current = freeDrawInstance;
        drawControlInstanceRef.current = drawControlInstance;
    }, []);

    const handleMapViewChange = useCallback(({ center, zoom, bounds }) => {
        setCurrentCenter([center.lat, center.lng]);
        setCurrentZoom(zoom);
        saveMapBounds({ center, zoom, bounds });
    }, [saveMapBounds]);

    // Called when ANY drawing interaction starts (L.Draw or Freehand)
    const handleDrawStart = useCallback((event) => {
        // Determine the tool type more accurately
        const tool = event.layerType || (event.type?.includes('edit') ? 'edit' : (event.type?.includes('delete') ? 'delete' : currentDrawTool)); // Fallback to state if needed
        console.log('Parent: Draw Start - Tool:', tool);

        setIsDrawingModeActive(true); // Indicate drawing is happening
        setCurrentDrawTool(tool); // Record the active tool
        setSelectedDrawingId(null);
        setEditingShape(null);

        const freeDraw = freeDrawInstanceRef.current;
        const map = mapInstanceRef.current;

        // Ensure FreeDraw is visually/logically off if an L.Draw tool starts
        if (map && freeDraw && tool !== 'freedraw-polygon' && tool !== 'freedraw-line') {
            if (freeDraw.mode !== NONE) {
                console.log("Parent ensuring FreeDraw mode is NONE because L.Draw started.");
                freeDraw.mode =  NONE
            }
            // Ensure map dragging is enabled if we are stopping FreeDraw implicitly
            if (map.dragging && !map.dragging.enabled()) {
                map.dragging.enable();
                console.log("Parent: Re-enabled map dragging implicitly stopping Freehand for L.Draw.");
            }
        }
    }, [currentDrawTool]); // Include currentDrawTool as it's used as a fallback


    // Called when ANY drawing interaction stops/completes
    const handleDrawStop = useCallback((event) => {
        const stoppedTool = event?.layerType || currentDrawTool; // Get the tool that stopped
        console.log('Parent: Draw Stop - Tool:', stoppedTool);

        const map = mapInstanceRef.current;

        // If the tool that stopped was freehand (line or polygon), re-enable map dragging
        if (map && (stoppedTool === 'freedraw-polygon' || stoppedTool === 'freedraw-line')) {
            if (map.dragging && !map.dragging.enabled()) {
                map.dragging.enable();
                console.log("Parent: Re-enabled map dragging after Freehand stopped.");
            }
        }

        // Reset general drawing state for all tools
        setIsDrawingModeActive(false);
        setCurrentDrawTool(null);
    }, [currentDrawTool]); // Include currentDrawTool

    // Called by MapComponent AFTER it has created the L.Layer (Polygon or Polyline)
    const handleDrawCreated = useCallback((event) => {
        const layer = event.layer;
        const layerType = event.layerType; // 'polygon', 'line', 'marker', etc. (corrected from 'freedraw-line')
        console.log('Parent: Draw CREATED - Type:', layerType, layer);

        if (!layer) {
            console.error("Parent: handleDrawCreated called without a layer.");
            return;
        }

        const geometry = getGeometryFromLayer(layer);
        if (!geometry) {
            console.error("Parent: Failed to get geometry from created layer. Aborting.", layer);
            return;
        }

        const newDrawing = {
            id: uuidv4(),
            name: `Untitled ${layerType}`,
            type: layerType, // Use the specific type ('polygon' or 'line')
            geometry: geometry,
            style: (layerType !== 'marker') ? { ...defaultStyle } : {}
        };

        setDrawings(prev => [...prev, newDrawing]);
        setSelectedDrawingId(newDrawing.id);
        setEditingShape({ ...newDrawing });

        console.log("Parent: Added drawing data to state:", newDrawing.id);

        // Note: We no longer need to add the layer to drawnItemsLayerRef here,
        // MapComponent does that *before* calling this callback.
    }, []); // Add defaultStyle if needed

    const handleDrawEdited = useCallback((event) => {
        console.log('Parent: Draw EDITED event');
        event.layers.eachLayer(layer => {
            const drawingId = layer._drawingId;
            if (drawingId) {
                const newGeometry = getGeometryFromLayer(layer);
                if (!newGeometry) return;

                setDrawings(prev => prev.map(d =>
                    d.id === drawingId ? { ...d, geometry: newGeometry } : d
                ));
                if (selectedDrawingId === drawingId && editingShape?.id === drawingId) {
                    setEditingShape(prev => ({ ...prev, geometry: newGeometry }));
                }
            } else console.warn("Edited layer missing _drawingId");
        });
    }, [selectedDrawingId, editingShape]);

    const handleDrawDeleted = useCallback((event) => {
        console.log('Parent: Draw DELETED event');
        const deletedIds = [];
        event.layers.eachLayer(layer => { if (layer._drawingId) deletedIds.push(layer._drawingId); });

        if (deletedIds.length > 0) {
            console.log("Deleting drawings via map interaction:", deletedIds);
            setDrawings(prev => prev.filter(d => !deletedIds.includes(d.id)));
            if (selectedDrawingId && deletedIds.includes(selectedDrawingId)) {
                setSelectedDrawingId(null); setEditingShape(null);
            }
        }
    }, [selectedDrawingId]);


    // --- UI Interaction Callbacks ---

    // CHANGE: Activate Freehand Drawing Tool
    const activateFreehandTool = useCallback(() => {
        if (isDrawingModeActive) {
            console.warn("Drawing tool already active.");
            return;
        }

        const map = mapInstanceRef.current;
        const freeDraw = freeDrawInstanceRef.current;

        if (!map || !freeDraw) {
            console.error("Cannot activate Freehand: Map or FreeDraw instance not ready.");
            setInitError("Map components not fully loaded. Cannot activate Freehand.");
            return;
        }

        setSelectedDrawingId(null);
        setEditingShape(null);

        // Set parent state
        const toolType = drawingMode === 'line' ? 'freedraw-line' : 'freedraw-polygon';
        setIsDrawingModeActive(true);
        setCurrentDrawTool(toolType);

        // Ensure FreeDraw layer is on map
        if (!map.hasLayer(freeDraw)) {
            map.addLayer(freeDraw);
            console.log("Parent: Added FreeDraw layer to map for activation.");
        }

        // Disable map dragging
        if (map.dragging && map.dragging.enabled()) {
            map.dragging.disable();
            console.log("Parent: Manually disabled map dragging for Freehand.");
        }

        // Set mode to CREATE
        freeDraw.mode = CREATE; // Use mode() method
        console.log(`Parent: Set FreeDraw mode to CREATE (intent: ${drawingMode}).`);

    }, [isDrawingModeActive, drawingMode]); // Add drawingMode dependency

     // CHANGE: Activate L.Draw Tools (keep this mostly the same)
    const activateLDrawTool = useCallback((toolType) => {
        if (isDrawingModeActive) { console.warn("Drawing tool already active."); return; }

        const map = mapInstanceRef.current;
        const drawControl = drawControlInstanceRef.current;
        const freeDraw = freeDrawInstanceRef.current;

        if (!map) { console.error("Cannot activate L.Draw tool: Map not ready."); return; }

        setSelectedDrawingId(null);
        setEditingShape(null);

        // --- Deactivate FreeDraw if necessary ---
        if (freeDraw) {
             if (freeDraw.mode !== NONE) { // Use mode() method
                 freeDraw.mode = NONE
                 console.log("Parent: Set FreeDraw mode to NONE as L.Draw tool activated.");
             }
             // We don't necessarily remove the layer, just ensure mode is NONE
             if (map.dragging && !map.dragging.enabled()) {
                 map.dragging.enable();
                 console.log("Parent: Manually re-enabled map dragging after Freehand deactivated.");
             }
        }

        // --- Activate L.Draw Tool ---
        console.log(`Parent: Activating L.Draw tool '${toolType}'`);
        let handler;
        try {
            const drawOptions = drawControl?.options?.draw;
            switch(toolType) {
                case 'polygon': handler = new L.Draw.Polygon(map, drawOptions?.polygon); break;
                case 'marker': handler = new L.Draw.Marker(map, drawOptions?.marker); break;
                case 'circle': handler = new L.Draw.Circle(map, drawOptions?.circle); break;
                case 'polyline': handler = new L.Draw.Polyline(map, drawOptions?.polyline); break;
                case 'rectangle': handler = new L.Draw.Rectangle(map, drawOptions?.rectangle); break;
                default: console.warn("Unknown L.Draw tool type:", toolType); return;
            }
            if (handler) {
                handler.enable();
                // Set state AFTER enabling the handler
                setIsDrawingModeActive(true);
                setCurrentDrawTool(toolType);
            } else {
                console.error(`Could not create handler for L.Draw tool: ${toolType}`);
            }
        } catch (e) {
             console.error(`Error enabling L.Draw tool ${toolType}:`, e);
             setIsDrawingModeActive(false); setCurrentDrawTool(null); // Reset state on error
        }
    }, [isDrawingModeActive]);

     const handleSelectDrawing = useCallback((drawing) => {
         if (isDrawingModeActive) return;
         setSelectedDrawingId(drawing.id);
         setEditingShape({ ...drawing });
         const layer = drawnItemsLayerRef.current?.getLayers().find(l => l._drawingId === drawing.id);
         if (layer && mapInstanceRef.current) { try {
             if (layer.getBounds) mapInstanceRef.current.fitBounds(layer.getBounds(), { padding: [50, 50], maxZoom: 18 });
             else if (layer.getLatLng) mapInstanceRef.current.setView(layer.getLatLng(), Math.max(mapInstanceRef.current.getZoom(), 16));
         } catch (e) { console.error("Error zooming to selected drawing:", e); } }
    }, [isDrawingModeActive]);

     const handleDeleteDrawing = useCallback((idToDelete) => {
         if (isDrawingModeActive) return;
         const drawingToDelete = drawings.find(d => d.id === idToDelete);
         if (!drawingToDelete || !window.confirm(`Delete "${drawingToDelete.name}"?`)) return;

         const layer = drawnItemsLayerRef.current?.getLayers().find(l => l._drawingId === idToDelete);
         if (layer) drawnItemsLayerRef.current.removeLayer(layer);

         setDrawings(prev => prev.filter(d => d.id !== idToDelete));
         if (selectedDrawingId === idToDelete) { setSelectedDrawingId(null); setEditingShape(null); }
    }, [isDrawingModeActive, drawings, selectedDrawingId]);

    const handleClearAllDrawings = useCallback(() => {
         if (isDrawingModeActive || !window.confirm('Delete ALL saved areas? This cannot be undone.')) return;
         setDrawings([]);
         drawnItemsLayerRef.current?.clearLayers();
         setSelectedDrawingId(null); setEditingShape(null);
         localStorage.removeItem('mapDrawingsV2');
    }, [isDrawingModeActive]);

    // --- Drawing Editor Callbacks --- (Keep existing editor handlers)
    const handleEditorChange = useCallback((event) => {
        const { name, value } = event.target;
        setEditingShape(prev => prev ? { ...prev, [name]: value } : null);
    }, []);

    const handleEditorStyleChange = useCallback((property, value) => {
        setEditingShape(prev => prev ? {
            ...prev,
            style: { ...(prev.style || defaultStyle), [property]: value }
        } : null);
    }, []);

    const handleEditorSave = useCallback(() => {
        if (!editingShape || !selectedDrawingId) return;
        setDrawings(prev => prev.map(d => d.id === selectedDrawingId ? { ...editingShape } : d ));
        const layer = drawnItemsLayerRef.current?.getLayers().find(l => l._drawingId === selectedDrawingId);
        if (layer && layer.setStyle && editingShape.type !== 'marker') {
             try { layer.setStyle(editingShape.style || defaultStyle); }
             catch (e) { console.error("Error applying style on save:", e); }
        }
        setSelectedDrawingId(null); setEditingShape(null);
    }, [editingShape, selectedDrawingId]);

    const handleEditorCancel = useCallback(() => {
        setSelectedDrawingId(null); setEditingShape(null);
    }, []);

    // --- Render Logic --- (Keep existing loading/error/main structure)
    if (yearProvidersLoading && providers.length <= 2) { /* ... loading ... */ }
    if (initError) { /* ... error ... */ }

    return (
        <div>
            <Typography variant="h4" gutterBottom>Aerial Imagery Explorer</Typography>
            <Paper elevation={3} style={{ padding: "20px", margin: "20px 0" }}>
                {/* Provider Controls */}
                <Box className={classes.controlsWrapper}>
                    <MapProviderControls
                        providers={providers}
                        currentProvider={currentProvider}
                        isHybridMode={isHybridMode}
                        isDrawingMode={isDrawingModeActive}
                        onProviderChange={handleProviderChange}
                        onHybridToggle={handleHybridToggle}
                    />
                </Box>

                {/* Map Component */}
                <Box className={classes.mapWrapper}>
                    <MapComponent
                        provider={currentProvider}
                        drawings={drawings}
                        initialCenter={currentCenter}
                        initialZoom={currentZoom}
                        onMapReady={handleMapReady}
                        onMapViewChange={handleMapViewChange}
                        onDrawCreated={handleDrawCreated}
                        onDrawEdited={handleDrawEdited}
                        onDrawDeleted={handleDrawDeleted}
                        onDrawStart={handleDrawStart}
                        onDrawStop={handleDrawStop}
                        drawingMode={drawingMode} // Pass the drawing intent down
                        isDrawingToolActive={isDrawingModeActive}
                    />
                </Box>
                 <Box mt={1} display="flex" justifyContent="space-between" flexWrap="wrap">
                    <Typography variant="caption" color="textSecondary">Zoom: {currentZoom?.toFixed(1)}</Typography>
                    {currentProvider && <Typography variant="caption" color="textSecondary" dangerouslySetInnerHTML={{ __html: currentProvider.attribution }} />}
                </Box>

                {/* Drawing Controls Section */}
                <Box className={classes.drawingControls}>
                    <Typography variant="h6" gutterBottom>Drawing & Areas</Typography>

                    {/* ADDED: Polygon/Line Mode Selection */}
                    {/* <Box className={classes.modeSelectionGroup}>
                         <Typography variant="body2" component="span" style={{ marginRight: 8 }}>Freehand Mode:</Typography>
                         <ButtonGroup size="small" variant="outlined" disabled={isDrawingModeActive}>
                             <Button
                                 onClick={() => setDrawingMode('polygon')}
                                 variant={drawingMode === 'polygon' ? 'contained' : 'outlined'}
                                 color={drawingMode === 'polygon' ? 'primary' : 'default'}
                              >
                                 Polygon (Filled)
                             </Button>
                             <Button
                                 onClick={() => setDrawingMode('line')}
                                 variant={drawingMode === 'line' ? 'contained' : 'outlined'}
                                 color={drawingMode === 'line' ? 'primary' : 'default'}
                              >
                                 Line (Unfilled)
                              </Button>
                         </ButtonGroup>
                    </Box> */}

                    <Grid container spacing={2}>
                        {/* Column 1: Toolbar */}
                        {/* <Grid item xs={12} sm={4} md={3}>
                            <DrawingToolbar
                                onToolActivate={activateLDrawTool} // Pass L.Draw activator
                                onFreehandActivate={activateFreehandTool} // Pass Freehand activator
                                isDrawingMode={isDrawingModeActive}
                                buttonGroupStyle={classes.buttonGroupColumn}
                            />
                        </Grid> */}
                        {/* Column 2: List */}
                        <Grid item xs={12} sm={8} md={4}>
                            <DrawingList
                                drawings={drawings}
                                selectedDrawingId={selectedDrawingId}
                                onSelectDrawing={handleSelectDrawing}
                                onDeleteDrawing={handleDeleteDrawing}
                                onClearAll={handleClearAllDrawings}
                                isDrawingMode={isDrawingModeActive}
                                listStyle={classes.drawingList}
                            />
                        </Grid>
                        {/* Column 3: Editor */}
                        <Grid item xs={12} md={5}>
                            <DrawingEditor
                                editingShape={editingShape}
                                onEditChange={handleEditorChange}
                                onStyleChange={handleEditorStyleChange}
                                onSave={handleEditorSave}
                                onCancel={handleEditorCancel}
                                isDrawingMode={isDrawingModeActive}
                                colorSwatchStyle={classes.colorSwatch}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </div>
    );
};

export default DynamicMaricopaMap;