// src/components/MapWithDrawing/mapUtils.js
import L from "leaflet";

// Helper function to load Esri Leaflet libraries dynamically
export const loadEsriLeaflet = () => {
    // Check if already loaded
    if (window.L && window.L.esri && window.L.esri.dynamicMapLayer) {
        console.log("Esri Leaflet already available.");
        return Promise.resolve();
    }
    console.log("Loading Esri Leaflet from CDN...");
    const loadScript = (src) => new Promise((resolve, reject) => {
        // Avoid adding script if it already exists
        if (document.querySelector(`script[src="${src}"]`)) {
            console.log(`Script ${src} already added to head.`);
            // Attempt to resolve assuming it loaded or will load
            // A more robust check might be needed if load order matters critically
            return resolve();
        }
        const script = document.createElement('script');
        script.src = src; script.async = true; script.onload = resolve; script.onerror = reject;
        document.head.appendChild(script);
    });

    // Load main library first
    return loadScript("https://unpkg.com/esri-leaflet@3.0.10/dist/esri-leaflet.js")
        .then(() => {
            console.log("Esri Leaflet main library potentially loaded.");
            // Check for dynamicMapLayer after loading
            if (!window.L?.esri?.dynamicMapLayer) {
                console.warn("L.esri.dynamicMapLayer not found after loading esri-leaflet. Dynamic layers might require additional plugins or a specific library version.");
            }
        })
        .catch(error => {
            console.error("Failed to load Esri Leaflet:", error);
            // Don't throw, allow app to proceed without dynamic layer support
        });
};

// Check status and details of a Maricopa MapServer
export const checkYearService = async (year) => {
    try {
        const serviceUrl = `https://gis.mcassessor.maricopa.gov/arcgis/rest/services/Aerials${year}/MapServer`;
        // Use a timeout to prevent hanging indefinitely
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 10000); // 10 second timeout

        const response = await fetch(`${serviceUrl}?f=pjson`, { signal: controller.signal });
        clearTimeout(timeoutId); // Clear timeout if fetch completes

        if (!response.ok) {
            console.warn(`Service for ${year} not found or failed: ${response.status} ${response.statusText}`);
            return null;
        }
        const data = await response.json();
        console.log(`${year} service data received.`);

        // --- Simplified parsing logic (same as before) ---
        const isExplicitlyTiled = data.singleFusedMapCache === true;
        const isPotentiallyDynamicCandidate = year < 2020; // Adjust year threshold if needed

        let maxZoom = 21, minZoom = 0, scales = [], origin = null, bounds = null, tileFormat = null;
        let wkid = data.spatialReference?.wkid || 102100;

        if (data.tileInfo?.lods?.length > 0) {
            minZoom = data.tileInfo.lods[0].level;
            maxZoom = data.tileInfo.lods[data.tileInfo.lods.length - 1].level;
            scales = data.tileInfo.lods.map(lod => ({ level: lod.level, scale: lod.scale, resolution: lod.resolution }));
            origin = data.tileInfo.origin;
            tileFormat = data.tileInfo.format;
            console.log(`${year}: Found tileInfo. MinZoom: ${minZoom}, MaxZoom: ${maxZoom}`);
        } else {
            console.log(`${year}: No detailed tileInfo found. Using defaults.`);
            if (data.fullExtent) maxZoom = 21; // Use higher maxZoom if likely vector/dynamic
        }

        if (data.fullExtent && L.Projection.SphericalMercator.unproject) {
            try {
                bounds = L.latLngBounds(
                    L.Projection.SphericalMercator.unproject({x: data.fullExtent.xmin, y: data.fullExtent.ymin}),
                    L.Projection.SphericalMercator.unproject({x: data.fullExtent.xmax, y: data.fullExtent.ymax})
                );
            } catch (projError) { console.warn(`Error unprojecting bounds for ${year}:`, projError); }
        }

        const supportsExport = (data.capabilities || "").includes("Map");
        // Use dynamic IF: It's an older candidate AND it's NOT explicitly tiled AND it supports export
        const useDynamic = isPotentiallyDynamicCandidate && !isExplicitlyTiled && supportsExport;

        let url = `${serviceUrl}/tile/{z}/{y}/{x}`;
        let isExportService = false;

         if (useDynamic) {
             url = serviceUrl; // Base URL for dynamic layer attempt
             isExportService = true;
             console.log(`${year}: Identified as candidate for DynamicMapLayer attempt.`);
         } else {
              console.log(`${year}: Using TileLayer approach. Explicitly Tiled: ${isExplicitlyTiled}, Supports Export: ${supportsExport}`);
         }

        return {
            year, name: `Maricopa County ${year}`, displayName: `Maricopa County (${year})`,
            url, serviceUrl, attribution: data.copyrightText || `© Maricopa County Assessor (${year})`,
            maxZoom, minZoom, wkid, description: data.serviceDescription || data.description || "",
            isTiledService: isExplicitlyTiled, isExportService,
            tileFormat, origin, scales, bounds,
            supportsDynamicLayers: data.supportsDynamicLayers === true, supportsExport,
            group: "maricopa"
        };
    } catch (error) {
        if (error.name === 'AbortError') {
            console.warn(`Timeout checking service for ${year}.`);
        } else {
            console.error(`Error checking ${year} service URL:`, error);
        }
        return null; // Indicate service check failed or timed out
    }
};

// Define Google Maps providers (could also be fetched from config)
export const googleMapsProvider = { name: "Google Maps", displayName: "Google Maps", url: "https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}", attribution: "© Google Maps", maxZoom: 20, minZoom: 0, group: "google" };
export const googleSatelliteProvider = { name: "Google Satellite", displayName: "Google Satellite", url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}", attribution: "© Google Maps", maxZoom: 20, minZoom: 0, group: "google" };
export const googleHybridProvider = { name: "Google Hybrid", displayName: "Google Hybrid", url: "https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}", attribution: "© Google Maps", maxZoom: 20, minZoom: 0, group: "google" };

// Default style for drawings
export const defaultStyle = {
    fillColor: '#3388ff',
    fillOpacity: 0.4,
    color: '#3388ff', // Stroke color
    weight: 3, // Stroke width
    opacity: 0.8 // Stroke opacity
};

// Helper to get geometry from a Leaflet layer
export const getGeometryFromLayer = (layer) => {
    try {
        if (layer instanceof L.Marker) return { latlng: layer.getLatLng() };
        if (layer instanceof L.Circle) return { center: layer.getLatLng(), radius: layer.getRadius() };
        if (layer instanceof L.Polygon || layer instanceof L.Rectangle || layer instanceof L.Polyline) return { latlngs: layer.getLatLngs() };
    } catch (e) {
        console.error("Error getting geometry from layer:", e, layer);
    }
    console.warn("Unknown layer type for geometry extraction:", layer?.constructor?.name);
    return null;
};