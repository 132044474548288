import React from 'react';
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Chip,
  Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import LineItemBuilder from '../Proposals/LineItemBuilder';
import { currencyFormat, currencyFormatWithOutFlair } from '../../services/common'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2)
  },
  tableContainer: {
    marginTop: theme.spacing(2)
  },
  totalRow: {
    backgroundColor: theme.palette.action.hover
  },
  summaryCard: {
    marginBottom: theme.spacing(2)
  },
  chip: {
    margin: theme.spacing(0.5)
  },
  subtotalSection: {
    paddingTop: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`
  },
  cardHeader: {
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
    // margin: 10,
    justifyContent: 'space-between',
    // alignContent: 'space-evenly',
    alignItems: 'center',
    alignSelf: 'stretch',
    // backgroundColor:' blue'
  },
  cardHeaderItem: {
    // marginRight: 25
  },
  cardHeaderRight: {
    // display: 'flex',
    // flexDirection: 'row',
    // justifyContent: 'flex-end',
    // alignItems: 'flex-end',
    // alignSelf: 'flex-end'
  },
  cardHeaderText: {
    fontSize: '1.1em',
    fontWeight: 600,
  }
}));

const defaultStateValues = {
  qty: 0,
  unit: null,
  description: '',
  totalPrice: 0,
  costPer: 0,
  pricePer: 0,
  counter: 0,
  editing: false,
  inventoryItem: null,
  item: null,
  internalNotes: '',
  materialBudget: 0,
  laborHours: 0,
  techHours: 0,
  sprayTechHours: 0,
  operatorHours: 0,
  supervisorHours: 0,
  bobcatDays: 0,
  craneDays: 0,
  smallCraneDays: 0,
  bigCraneDays: 0,
  option: null,
  numberOfDays: 0,
  numberOfMen: 0,
  suggestedLaborTotal: 0,
  lineItemType: 'materials',
  subLineItems: [],
  hasSubLineItems: false,
  subLineItemsTotal: 0
}

const WorkorderLineItems = ({ workorder, user, takeOffList, client, onChange, job }) => {
  const classes = useStyles();

  const [state, setState] = React.useState(defaultStateValues)
  const [showLineItemBuilder, setShowLineItemBuilder] = React.useState(false)

  const currencyFormat = (num) => {
    return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  // Calculate totals
  const getMaterialsTotal = () => {
    if (!workorder?.lineItems?.length) return 0
    return workorder?.lineItems
      .filter(item => item.lineItemType === 'Materials')
      .reduce((sum, item) => sum + parseFloat(item.totalPrice), 0);
  };

  const getLaborTotal = () => {
    if (!workorder?.lineItems?.length) return 0
    return workorder?.lineItems
      .filter(item => item.lineItemType === 'Labor')
      .reduce((sum, item) => sum + parseFloat(item.totalPrice), 0);
  };

  const getSubtotal = () => {
    if (!workorder?.lineItems?.length) return 0
    return workorder?.lineItems?.reduce((sum, item) => sum + parseFloat(item.totalPrice), 0);
  };

  const handleCloseLineItemModal = () => {
    setShowLineItemBuilder(false)
  }

  const handleLineItemUpdate = (items) => {
    console.log('Save our handleLineItemUpdate items..', items)
    const total = items.reduce((sum, item) => {
      let totalToUse = 0
      if (item.type === 'Labor') {
        totalToUse = (parseFloat(item.totalHours || 0) * parseFloat(item.pricePer || 0))
      } else {
      totalToUse = (parseFloat(item.qty || 0) * parseFloat(item.pricePer || 0))
      }
      let newTot = sum + totalToUse
      return newTot
    }, 0);
    console.log('Total:', total)
    console.log('Do we have exsiting items?', workorder)
    onChange({
      lineItems: items,
      totalPrice: currencyFormatWithOutFlair(total),
    })
    // setState(prev => ({
    //   ...prev,
    //   // subLineItems: items,
    //   // hasSubLineItems: items.length > 0,
    //   // subLineItemsTotal: currencyFormatWithOutFlair(total),
    //   totalPrice: currencyFormatWithOutFlair(total),
    //   pricePer: currencyFormatWithOutFlair(total)
    // }));

    setShowLineItemBuilder(false);
  };

  const addNewWOLineItem = () => {
    setShowLineItemBuilder(true)
  }
  console.log('Show the line item builder??', showLineItemBuilder)
  return (
    <div className={classes.root}>
      <LineItemBuilder
        open={showLineItemBuilder}
        onClose={handleCloseLineItemModal}
        onSubmit={handleLineItemUpdate}
        initialLineItems={workorder?.lineItems || []}
        client={client}
        entities={user?.entities}
        takeOffList={takeOffList || []}
      />
      {/* Summary Cards */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card className={classes.summaryCard}>
            <CardHeader title="Materials" />
            <CardContent>
              <Typography variant="h4" color="primary">
                {currencyFormat(getMaterialsTotal())}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {workorder?.lineItems?.filter(item => item.lineItemType === 'Materials').length} items
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item xs={12} md={4}>
          <Card className={classes.summaryCard}>
            <CardHeader title="Labor" />
            <CardContent>
              <Typography variant="h4" color="primary">
                {currencyFormat(getLaborTotal())}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {workorder?.lineItems?.filter(item => item.lineItemType === 'Labor').length} items
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card className={classes.summaryCard}>
            <CardHeader title="Total" />
            <CardContent>
              <Typography variant="h4" color="primary">
                {currencyFormat(getSubtotal())}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {workorder?.lineItems?.length} total items
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Line Items Table */}
      <Card>
        <div className={classes.cardHeader}>
          <div className={classes.cardHeaderItem}>
            <div className={classes.cardHeaderText}>
              Line Items
            </div>
          </div>
          <div className={classes.cardHeaderItem}>
            <div className={classes.cardHeaderRight}>
              {
                (job && job.proposalLineItems && job.proposalLineItems.length) ? null : <Button
                variant='contained'
                size='small'
                color='primary'
                onClick={addNewWOLineItem}
                startIcon={<AddCircleIcon />}
              >Add Line Item</Button>
              }
             
            </div>
          </div>
        </div>
        <CardContent>
         
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell>Unit</TableCell>
                  <TableCell align="right">Price/Unit</TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {workorder?.lineItems?.map((item) => (
                  <TableRow key={item._id}>
                    <TableCell>
                      <Chip
                        label={item.lineItemType}
                        color={item.lineItemType === 'Materials' ? 'primary' : 'secondary'}
                        size="small"
                        className={classes.chip}
                      />
                    </TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell align="right">{parseFloat(item.qty).toFixed(2)}</TableCell>
                    <TableCell>{item.unit}</TableCell>
                    <TableCell align="right">{currencyFormat(item.pricePer)}</TableCell>
                    <TableCell align="right">{currencyFormat(item.totalPrice)}</TableCell>
                  </TableRow>
                ))}
                
                {/* Subtotal Row */}
                <TableRow className={classes.totalRow}>
                  <TableCell colSpan={4} />
                  <TableCell align="right">
                    <strong>Subtotal:</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>{currencyFormat(getSubtotal())}</strong>
                  </TableCell>
                </TableRow>

                {/* Tax Row - if tax exists */}
                {workorder.taxAmount > 0 && (
                  <TableRow className={classes.totalRow}>
                    <TableCell colSpan={4} />
                    <TableCell align="right">Tax:</TableCell>
                    <TableCell align="right">{currencyFormat(workorder.taxAmount)}</TableCell>
                  </TableRow>
                )}

                {/* Grand Total Row */}
                <TableRow className={classes.totalRow}>
                  <TableCell colSpan={4} />
                  <TableCell align="right">
                    <strong>Total:</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong>{currencyFormat(getSubtotal() + (workorder.taxAmount || 0))}</strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </div>
  );
};

export default WorkorderLineItems;
