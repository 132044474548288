import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, CircularProgress }  from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import LaborRates from './LaborRates'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    // margin: '5px'
  }
}));

const defaultStateValue = (client, entity) => {
  // Get default rates from entity if available
  const entityLaborRates = entity?.laborRates || {};
  const entityEquipmentRates = entity?.equipmentRates || {};
  // console.log('Default State valus', client)
  // console.log('enitty rates', entity)
  // Create a nested structure that matches the new format
  const defaultRates = {
    laborRates: {
      labor: { rate: entityLaborRates?.labor?.rate || 60 },
      install: { rate: entityLaborRates?.install?.rate || 70 },
      tech: { rate: entityLaborRates?.tech?.rate || 95 },
      sprayTech: { rate: entityLaborRates?.sprayTech?.rate || 90 },
      supervisor: { rate: entityLaborRates?.supervisor?.rate || 95 },
      operator: { rate: entityLaborRates?.operator?.rate || 90 },
      arborist: { rate: entityLaborRates?.arborist?.rate || 95 },
      treeWorker: { rate: entityLaborRates?.treeWorker?.rate || 85 },
      irrigationTech: { rate: entityLaborRates?.irrigationTech?.rate || 150 },
      lightingTech: { rate: entityLaborRates?.lightingTech?.rate || 95 }
    },
    equipmentRates: {
      bobcat: { rate: entityEquipmentRates?.bobcat?.rate || 300 },
      crane: { rate: entityEquipmentRates?.crane?.rate || 900 },
      tractor: { rate: entityEquipmentRates?.tractor?.rate || 125 },
      bobcatOperator: { rate: entityEquipmentRates?.bobcatOperator?.rate || 300 },
      craneOperator: { rate: entityEquipmentRates?.craneOperator?.rate || 900 },
      tractorOperator: { rate: entityEquipmentRates?.tractorOperator?.rate || 125 },
      truckDriver: { rate: entityEquipmentRates?.truckDriver?.rate || 85 }
    }
  };

  // Merge with client rates if they exist
  const mergedRates = {
    laborRates: {
      ...defaultRates.laborRates,
      ...client?.laborRates
    },
    equipmentRates: {
      ...defaultRates.equipmentRates,
      ...client?.equipmentRates
    }
  };

  return {
    ...client,
    laborRates: mergedRates.laborRates,
    equipmentRates: mergedRates.equipmentRates
  };
};

const EditCustomerModal = (props) => {
  // console.log('EditCustomerModal Modal: ', props)
  const classes = useStyles()
  const [loading, setLoading] = React.useState(false)
  const [client, setClient] = React.useState({})
  const [state, setState] = React.useState(() => defaultStateValue(props.client, props.entity));

  React.useEffect(() => {
    setClient(props.client);
    const newState = defaultStateValue(props.client, props.entity);
    // console.log('New State...105:', newState)
    setState(newState);
  }, [props.client, props.entity]);

  // React.useEffect(() => {
  //   console.log('Use Effect Edit Customer MODAL', props)
  //   setClient(props.client)
  //   let laborRates = props?.entity?.laborRates || defaultRates
  //   console.log('Set these labor rates', laborRates)
  //   if (props.client.laborRates) {
  //     laborRates = {
  //       ...laborRates,
  //       ...props.client.laborRates
  //     }
  //     console.log('Using client labor rates', laborRates)
  //   }

  //   console.log('Set labor rates', laborRates)
  //   setState({
  //     displayName: props.client.displayName,
  //     firstName: props.client.firstName,
  //     lastName: props.client.lastName,
  //     companyName: props.client.companyName,
  //     initials: props.client.initials,
  //     email: props.client.email,
  //     address: props.client.address,
  //     address2: props.client.address2,
  //     city: props.client.city,
  //     state: props.client.state,
  //     testClient: props.client.testClient,
  //     zipcode: props.client.zip,
  //     telephone: props.client.phone,
  //     client: props.client,
  //     HOA: props.client.HOA,
  //     paperInvoice: props.client.paperInvoice,
  //     contractor: props.client.contractor,
  //     government: props.client.government,
  //     builder: props.client.builder,
  //     autopayAll: props.client.autopayAll,
  //     autopayMTC: props.client.autopayMTC,
  //     doNotEmail: props.client.doNotEmail,
  //     active: props.client.active,
  //     hydrawise: props.client.hydrawise,
  //     requiresWorkorder: props.client.requiresWorkorder,
  //     collectionNotifications: props.client.collectionNotifications,
  //     invoiceFirstLine: props.client.invoiceFirstLine,
  //     laborRates: laborRates,
  //     hmiPastDueStatements: props.client.hmiPastDueStatements,
  //     requiresHoursAndRates: props.client.requiresHoursAndRates,
  //     displayJobSiteNameAndBuildingNumber: props.client.displayJobSiteNameAndBuildingNumber
  //   })
  // }, [props.client])

  const handleClose = () => {
    // console.log('Closing invoice modal')
    setLoading(false)
    props.updatedClient('Close')
  }

  const handleChange = (name, value) => {
    // console.log('Update our stuff: ', name)
    // console.log('value: ', value)
    let customer = client
    customer[name] = !customer[name]
    setState({...state, client: customer})
  }

  const handleSave = () => {
    setLoading(true);
    // console.log('Handle Save labor rates', state)
  
    const updateObject = {
      client: {
        ...client,
        laborRates: state.laborRates,
        equipmentRates: state.equipmentRates
      },
      state: {
        ...state
      },
      user: props.user
    };
  
    axios({
      method: 'post',
      url: `${props.url}/api/customer/updateClient`,
      data: updateObject,
    }).then(response => {
      setLoading(false)
      // console.log('Update Client RSPONSE: ', response)
      if (response && response.data && response.data.client) {
        props.updatedClient('saved', response.data.client)
        // let jobsChecked = response.data.jobsChecked
        // let jobsMatched = response.data.jobsMatched
        // let jobsUpdated = response.data.jobsUpdated
        let message = 'Your Updates Were Saved'
        let otherUpdates = response.data.updates

        if (otherUpdates && otherUpdates.jobsChecked && otherUpdates.jobsChecked > 0) {
          message = <div>
            <b>Update Results</b><br />
            <b>Jobs Checked: </b>{otherUpdates.jobsChecked}<br />
            <b>Jobs Matched: </b>{otherUpdates.jobsMatched}<br />
            <b>Jobs Updated: </b>{otherUpdates.jobsUpdated}<br />
            <b>Mtc Agreements Checked: </b>{otherUpdates.mtcChecked}<br />
            <b>Mtc Agreements Matched: </b>{otherUpdates.mtcMatched}<br />
            <b>Mtc Agreements Updated: </b>{otherUpdates.mtcUpdated}<br />
            <b>Invoices Checked: </b>{otherUpdates.invoicesChecked}<br />
            <b>Invoices Matched: </b>{otherUpdates.invoicesMatched}<br />
            <b>Invoices Updated: </b>{otherUpdates.invoicesUpdated}
          </div>
        }
        // console.log('Notification: ',)
        props.notification({
          type: 'lastNameChange',
          title: 'Customer Updated Successfully',
          message: message
        })
      }
    });
  };

  const handleSaveOLD = () => {
    setLoading(true);
    console.log('Handle Save labor rates', state)
    // Structure the labor rates in the new format
    const formattedLaborRates = {
      laborRates: {
        labor: { rate: Number(state.laborRates.laborRate?.rate ) },
        install: { rate: Number(state.laborRates.installRate?.rate) },
        tech: { rate: Number(state.laborRates.techRate?.rate) },
        sprayTech: { rate: Number(state.laborRates.sprayTechRate?.rate) },
        supervisor: { rate: Number(state.laborRates.supervisorRate?.rate) },
        operator: { rate: Number(state.laborRates.operatorRate?.rate) },
        arborist: { rate: Number(state.laborRates.arboristRate?.rate) },
        treeWorker: { rate: Number(state.laborRates.treeWorkerRate?.rate) },
        irrigationTech: { rate: Number(state.laborRates.irrigationTechRate?.rate) },
        lightingTech: { rate: Number(state.laborRates.lightingTechRate?.rate) }
      },
      equipmentRates: {
        bobcat: { rate: Number(state.laborRates.bobcatRate?.rate) },
        crane: { rate: Number(state.laborRates.craneRate?.rate) },
        tractor: { rate: Number(state.laborRates.tractorRate?.rate) },
        bobcatOperator: { rate: Number(state.laborRates.bobcatOperatorRate?.rate) },
        craneOperator: { rate: Number(state.laborRates.craneOperatorRate?.rate) },
        tractorOperator: { rate: Number(state.laborRates.tractorOperatorRate?.rate) },
        truckDriver: { rate: Number(state.laborRates.truckDriverRate?.rate) }
      }
    };
    console.log("Formatted Labor Rates to send 26", formattedLaborRates)
    const updateObject = {
      client: {
        ...client,
        ...formattedLaborRates
      },
      state: {
        ...state,
        laborRates: formattedLaborRates
      },
      user: props.user
    };
    console.log('Formatted Labor rates', formattedLaborRates)
    axios({
      method: 'post',
      url: `${props.url}/api/customer/updateClient`,
      data: updateObject,
    }).then(response => {
      setLoading(false)
      console.log('Update Client RSPONSE: ', response)
      if (response && response.data && response.data.client) {
        props.updatedClient('saved', response.data.client)
        // let jobsChecked = response.data.jobsChecked
        // let jobsMatched = response.data.jobsMatched
        // let jobsUpdated = response.data.jobsUpdated
        let message = 'Your Updates Were Saved'
        let otherUpdates = response.data.updates

        if (otherUpdates && otherUpdates.jobsChecked && otherUpdates.jobsChecked > 0) {
          message = <div>
            <b>Update Results</b><br />
            <b>Jobs Checked: </b>{otherUpdates.jobsChecked}<br />
            <b>Jobs Matched: </b>{otherUpdates.jobsMatched}<br />
            <b>Jobs Updated: </b>{otherUpdates.jobsUpdated}<br />
            <b>Mtc Agreements Checked: </b>{otherUpdates.mtcChecked}<br />
            <b>Mtc Agreements Matched: </b>{otherUpdates.mtcMatched}<br />
            <b>Mtc Agreements Updated: </b>{otherUpdates.mtcUpdated}<br />
            <b>Invoices Checked: </b>{otherUpdates.invoicesChecked}<br />
            <b>Invoices Matched: </b>{otherUpdates.invoicesMatched}<br />
            <b>Invoices Updated: </b>{otherUpdates.invoicesUpdated}
          </div>
        }
        // console.log('Notification: ',)
        props.notification({
          type: 'lastNameChange',
          title: 'Customer Updated Successfully',
          message: message
        })
      }
    })
  };

  const handleUpdateLaborRates = (newRateItem) => {
    console.log('Save labor rates', newRateItem)
    setState(prevState => {
      const updatedState = { ...prevState };
      
      if (newRateItem.laborRates) {
        updatedState.laborRates = {
          ...prevState.laborRates,
          ...newRateItem.laborRates
        };
      }
      
      if (newRateItem.equipmentRates) {
        updatedState.equipmentRates = {
          ...prevState.equipmentRates,
          ...newRateItem.equipmentRates
        };
      }
      
      return updatedState;
    });
  };

  // console.log('325 state', state)

  return (
    <div className={classes.root}>
       <Dialog
        // fullScreen={true}
        maxWidth='sm'
        fullWidth
        open={props.open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Edit Customer</DialogTitle>
        <DialogContent>
        <IconButton onClick={handleClose} style={{ 'position': 'absolute', 'top': '15px', 'right': '30px' }} edge='end' alt="Close" aria-label="Close">
          <CloseIcon />
        </IconButton>
          <Grid container>
            <Grid item lg={12}>
             <h3>Customer Information</h3>
            </Grid>
            <Grid item lg={6}>
              <FormGroup row>
                <FormControlLabel
                    control={<Checkbox checked={state.active} onChange={ e => setState({...state, active: !state.active } ) } name="Active" />}
                    label="Active"
                  />
                </FormGroup>
            </Grid>
            <Grid item lg={6}>
              <FormGroup row>
                <FormControlLabel
                    control={<Checkbox checked={state.testClient} onChange={ e => setState({...state, testClient: !state.testClient } ) } name="Test Client" />}
                    label="Test Client"
                  />
                </FormGroup>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={12}>
            <Grid container>
                <Grid item lg={6} xs={6} className={classes.gridItem}> 
                          <TextField
                            autoFocus
                            label="Customer First Name"
                            onChange={ e => setState({...state, firstName: e.target.value })}
                            name="customerfirstname"
                            value={ state.firstName }
                            margin="normal"
                            fullWidth
                          />
                        </Grid>
                        <Grid item lg={6} xs={6} className={classes.gridItem}> 
                                    
                          <TextField
                          autoFocus
                            label="Customer Last Name"
                            onChange={ e => setState({...state, lastName: e.target.value })}
                            name="customerlastname"
                            value={ state.lastName }
                            margin="normal"
                            fullWidth
                          />
                        </Grid>
                      </Grid>
            </Grid>
            <Grid item lg={12}>
              <TextField
                autoFocus
                label="Company Name"
                onChange={ e => setState({...state, companyName: e.target.value })}
                name="companyName"
                value={ state.companyName }
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item lg={12}>
              <TextField
                autoFocus
                label="Invoice PDF Name"
                onChange={ e => setState({...state, initials: e.target.value })}
                name="companyInitials"
                value={ state.initials }
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item lg={12}>
              <TextField
                autoFocus
                className={classes.paddedStuff}
                margin="dense"
                id="DisplayName"
                label="Display Name"
                type="text"
                onChange={(e) => setState({...state, displayName: e.target.value})}
                value={state.displayName}
                fullWidth
            />
             <TextField
                autoFocus
                className={classes.paddedStuff}
                margin="dense"
                id="Email"
                label="Email"
                type="text"
                onChange={(e) => setState({...state, email: e.target.value})}
                value={state.email}
                fullWidth
            />
             <TextField
                autoFocus
                className={classes.paddedStuff}
                margin="dense"
                id="Telephone"
                label="Telephone Number"
                type="telephone"
                onChange={(e) => setState({...state, telephone: e.target.value})}
                value={state.telephone}
                fullWidth
            />
            </Grid>
            <Grid item lg={12} xs={12} className={classes.gridItem}> 
                        <TextField
                      label="Billing Address"
                      fullWidth
                      value= {state.address}
                        onChange={ e => setState({...state, address:e.target.value } ) }
                      // variant="outlined"
                      // value= {state.service.time}
                      // onChange={ e => handleServiceChange(state.service, 'time', e.target.value, state.serviceIndex) }
                      // defaultValue="Default Value"
                      />
                    </Grid>
                    <Grid item lg={12} xs={12} className={classes.gridItem}> 
                        <TextField
                      label="Billing Address Line 2"
                      fullWidth
                      value= {state.address2}
                        onChange={ e => setState({...state, address2:e.target.value } ) }
                      // variant="outlined"
                      // value= {state.service.time}
                      // onChange={ e => handleServiceChange(state.service, 'time', e.target.value, state.serviceIndex) }
                      // defaultValue="Default Value"
                      />
                    </Grid>
                    <Grid item lg={6} xs={6} className={classes.gridItem}>
                      <TextField
                        label="Billing City"
                        fullWidth
                        value= {state.city}
                        onChange={ e => setState({...state, city:e.target.value } ) }
                        // variant="outlined"
                        // value= {state.service.time}
                        // onChange={ e => handleServiceChange(state.service, 'time', e.target.value, state.serviceIndex) }
                        // defaultValue="Default Value"
                        />
                    </Grid>
                    <Grid item lg={3} xs={6} className={classes.gridItem}>
                      <TextField
                        label="Billing State"
                        fullWidth
                        value= {state.state}
                        onChange={ e => setState({...state, state:e.target.value } ) }
                      />
                    </Grid>
                    <Grid item lg={3} xs={6} className={classes.gridItem}>
                    <TextField
                        label="Billing Zipcode"
                        fullWidth
                        // type="number"
                        // variant="outlined"
                        value= {state.zipcode}
                        onChange={ e => setState({...state, zipcode:e.target.value } ) }
                        />
                    </Grid>
                </Grid>
            <Grid container>
              <Grid item lg={12}>
              <h3>Customer Settings</h3>
              </Grid>
              <Grid item lg={12}>
                <h4>Auto-Pay Options</h4>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormControlLabel
                      control={<Checkbox checked={state.autopayAll} onChange={ e => setState({...state, autopayAll: !state.autopayAll } ) } name="autopayAll" />}
                      label="Autopay (ALL)"
                    />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormControlLabel
                      control={<Checkbox checked={state.autopayMTC} onChange={ e => setState({...state, autopayMTC: !state.autopayMTC } ) } name="autopayMTC" />}
                      label="Autopay (Maintenance Only)"
                    />
              </Grid>
              <Grid item lg={12}>
                <h4>Invoice Email Options</h4>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormControlLabel
                    control={<Checkbox checked={state.paperInvoice} onChange={ e => setState({...state, paperInvoice: !state.paperInvoice } ) } name="paperInvoice" />}
                    label="Paper Invoice"
                  />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormControlLabel
                    control={<Checkbox checked={state.doNotEmail}onChange={ e => setState({...state, doNotEmail: !state.doNotEmail } ) } name="doNotEmail" />}
                    label="Do NOT Email"
                  />
              </Grid>
              <Grid item lg={12}>
                <h4>Special Customer Type</h4>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormControlLabel
                    control={<Checkbox checked={state.HOA} onChange={ e => setState({...state, HOA: !state.HOA } ) } name="HOA" />}
                    label="HOA"
                  />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormControlLabel
                    control={<Checkbox checked={state.contractor} onChange={ e => setState({...state, contractor: !state.contractor } ) } name="contractor" />}
                    label="Contractor"
                  />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormControlLabel
                    control={<Checkbox checked={state.builder} onChange={ e => setState({...state, builder: !state.builder } ) } name="Builder" />}
                    label="Builder"
                  />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormControlLabel
                    control={<Checkbox checked={state.government} onChange={ e => setState({...state, government: !state.government } ) } name="Government" />}
                    label="Government"
                  />
              </Grid>
              <Grid item lg={12}>
                <h4>Collection Notification Options</h4>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormControlLabel
                    control={<Checkbox checked={state.collectionNotifications} onChange={ e => setState({...state, collectionNotifications: !state.collectionNotifications } ) } name="collectionNotifications" />}
                    label="Collections Notifications"
                  />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormControlLabel
                    control={<Checkbox checked={state.hmiPastDueStatements} onChange={ e => setState({...state, hmiPastDueStatements: !state.hmiPastDueStatements } ) } name="hmiPastDueStatements" />}
                    label="Receive HMI Past Due Statement"
                  />
              </Grid>
              <Grid item lg={12}>
                <h4>Customer Up-Charges</h4>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormControlLabel
                    control={<Checkbox checked={state.hydrawise} onChange={ e => setState({...state, hydrawise: !state.hydrawise } ) } name="hydrawise" />}
                    label="Hydrawise"
                  />
              </Grid>
              <Grid item lg={12}>
                <h4>Customer Requirements</h4>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControlLabel
                    control={<Checkbox checked={state.requiresWorkorder} onChange={ e => setState({...state, requiresWorkorder: !state.requiresWorkorder } ) } name="requiresWorkorder" />}
                    label="Requires Customer Generated Workorder"
                  />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControlLabel
                    control={<Checkbox checked={state.displayJobSiteNameAndBuildingNumber} onChange={ e => setState({...state, displayJobSiteNameAndBuildingNumber: !state.displayJobSiteNameAndBuildingNumber } ) } name="displayJobSiteNameAndBuildingNumber" />}
                    label="Requires Building Name and Number on Invoice"
                  />
              </Grid>

              

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <h4>Invoice Extras</h4>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  
                  rows={4}
                  variant="outlined"
                  label="Invoice First Line (PO/PODO) - GOV'T Stuff"
                  onChange={ e => setState({...state, invoiceFirstLine: e.target.value })}
                  name="invoiceFirstLine"
                  multiline
                  value={ state.invoiceFirstLine }
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <h4>Labor Rates</h4>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControlLabel
                    control={<Checkbox checked={state.requiresHoursAndRates} onChange={ e => setState({...state, requiresHoursAndRates: !state.requiresHoursAndRates } ) } name="requiresHoursAndRates" />}
                    label="Requires Labor Hours & Rate on Estimates/Invoices"
                  />
              </Grid>
              <LaborRates 
                laborRates={state.laborRates} 
                equipmentRates={state.equipmentRates} 
                updateParent={handleUpdateLaborRates} 
              />

              {/* <LaborRates laborRates={state.laborRates} updateParent={handleUpdateLaborRates} /> */}


              

              
                {/* <FormGroup row>
                  
                  <FormControlLabel
                      control={<Checkbox checked={state.autopayAll} onChange={ e => setState({...state, autopayAll: !state.autopayAll } ) } name="autopayAll" />}
                      label="Autopay (ALL)"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={state.autopayMTC} onChange={ e => setState({...state, autopayMTC: !state.autopayMTC } ) } name="autopayMTC" />}
                      label="Autopay (Maintenance Only)"
                    />
                </FormGroup> */}
              {/* <FormGroup row>
                <h4>Invoice Options</h4>
                <FormControlLabel
                    control={<Checkbox checked={state.paperInvoice} onChange={ e => setState({...state, paperInvoice: !state.paperInvoice } ) } name="paperInvoice" />}
                    label="Paper Invoice"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={state.doNotEmail}onChange={ e => setState({...state, doNotEmail: !state.doNotEmail } ) } name="doNotEmail" />}
                    label="Do NOT Email"
                  />
              </FormGroup>
              <FormGroup row>
                <FormControlLabel
                    control={<Checkbox checked={state.HOA} onChange={ e => setState({...state, HOA: !state.HOA } ) } name="HOA" />}
                    label="HOA"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={state.contractor} onChange={ e => setState({...state, contractor: !state.contractor } ) } name="contractor" />}
                    label="Contractor"
                  />
              </FormGroup> */}
              
              {/* </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave} color="primary">
            Save { loading ? <CircularProgress /> : '' }
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default React.memo(connect(mapStateToProps)(EditCustomerModal))