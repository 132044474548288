/**
 * Generate a unique ID for polygons
 */
export const generateUniqueId = () => {
  return `polygon-${Date.now()}-${Math.floor(Math.random() * 10000)}`;
};

/**
 * Get polygon center using simple averaging (doesn't rely on Google Maps API)
 */
export const getPolygonCenter = (paths, fallbackCenter) => {
  if (!paths || !Array.isArray(paths) || paths.length === 0) {
    return fallbackCenter;
  }

  try {
    let totalLat = 0;
    let totalLng = 0;
    let validPoints = 0;
    
    for (let i = 0; i < paths.length; i++) {
      const path = paths[i];
      if (path && typeof path.lat === 'number' && typeof path.lng === 'number') {
        totalLat += path.lat;
        totalLng += path.lng;
        validPoints++;
      }
    }
    
    if (validPoints > 0) {
      return {
        lat: totalLat / validPoints,
        lng: totalLng / validPoints
      };
    }
    
    return fallbackCenter;
  } catch (error) {
    console.error('Error calculating polygon center:', error);
    return fallbackCenter;
  }
};

/**
 * Calculate polygon area - only use when Google Maps is loaded
 */
export const calculatePolygonArea = (paths) => {
  if (!window.google?.maps?.geometry || !paths || !Array.isArray(paths) || paths.length < 3) {
    return { areaMeters: 0, areaFeet: 0 };
  }
  
  try {
    // Convert to Google LatLng objects
    const latLngPaths = [];
    for (let i = 0; i < paths.length; i++) {
      const path = paths[i];
      if (path && typeof path.lat === 'number' && typeof path.lng === 'number') {
        latLngPaths.push(new window.google.maps.LatLng(path.lat, path.lng));
      }
    }
    
    // Calculate area
    const areaMeters = window.google.maps.geometry.spherical.computeArea(latLngPaths);
    const areaFeet = areaMeters * 10.7639; // Convert square meters to square feet
    return { areaMeters, areaFeet };
  } catch (err) {
    console.error("Error calculating area:", err);
    return { areaMeters: 0, areaFeet: 0 };
  }
};

/**
 * Get polygon area - only use when Google Maps is loaded
 */
export const getPolygonArea = (paths) => {
  if (window.google && window.google.maps && window.google.maps.geometry) {
    const latLngPaths = paths.map(path => new window.google.maps.LatLng(path.lat, path.lng));
    const areaMeters = window.google.maps.geometry.spherical.computeArea(latLngPaths);
    const areaFeet = areaMeters * 10.7639; // Convert square meters to square feet
    return { areaMeters, areaFeet };
  }
  return { areaMeters: 0, areaFeet: 0 }; // Default area if google maps is not available
};

/**
 * Create label icon for polygon - only use when Google Maps is loaded
 */
export const createLabelIcon = (text) => {
  if (!window.google) return null;
  
  const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="200" height="30">
      <rect x="0" y="0" width="200" height="30" rx="5" ry="5" fill="white" opacity="0.8" />
      <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-family="Arial" font-size="14" fill="black">${text}</text>
    </svg>`;
  
  return {
    url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg),
    scaledSize: new window.google.maps.Size(200, 30),
    anchor: new window.google.maps.Point(100, 15)
  };
};