import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Chip, Slider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 200,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  chip: {
    margin: theme.spacing(0.5),
    width: 'fit-content'
  },
  completeChip: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.contrastText,
  },
  inProgressChip: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.contrastText,
  },
  sliderContainer: {
    position: 'relative',
    width: '100%',
    height: 32,
    marginTop: theme.spacing(1)
  },
  progressValue: {
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    top: '50%',
    transform: 'translateY(-50%)',
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
    fontWeight: 500,
    pointerEvents: 'none',
    zIndex: 1
  },
  valueArea: {
    padding: 5,
    width: '20%',
    margin: 'auto',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    borderRadius: 10,
    border: '1px solid #eee'
  },
  slider: {
    '& .MuiSlider-rail': {
      height: 8,
      borderRadius: 4,
      backgroundColor: theme.palette.grey[200]
    },
    '& .MuiSlider-track': {
      height: 8,
      borderRadius: 4
    },
    '& .MuiSlider-thumb': {
      width: 16,
      height: 16,
      marginTop: -4,
      marginLeft: -8
    }
  },
  greenSlider: {
    '& .MuiSlider-track': {
      backgroundColor: theme.palette.success.main
    },
    '& .MuiSlider-thumb': {
      backgroundColor: theme.palette.success.main
    }
  },
  blueSlider: {
    '& .MuiSlider-track': {
      backgroundColor: theme.palette.info.main
    },
    '& .MuiSlider-thumb': {
      backgroundColor: theme.palette.info.main
    }
  },
  yellowSlider: {
    '& .MuiSlider-track': {
      backgroundColor: theme.palette.warning.main
    },
    '& .MuiSlider-thumb': {
      backgroundColor: theme.palette.warning.main
    }
  },
  orangeSlider: {
    '& .MuiSlider-track': {
      backgroundColor: '#ed6c02'
    },
    '& .MuiSlider-thumb': {
      backgroundColor: '#ed6c02'
    }
  },
  redSlider: {
    '& .MuiSlider-track': {
      backgroundColor: theme.palette.error.main
    },
    '& .MuiSlider-thumb': {
      backgroundColor: theme.palette.error.main
    }
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    // alignContent: 'space-evenly',
    justifyContent: 'center'
  }
}));

const StatusCell = ({ workorder, percentComplete, getPriorityColor }) => {
  const classes = useStyles();
  // const percentComplete = workorder.percentComplete || 0;
  const isCompleted = Boolean(workorder.completedDate);
  // console.log('Status cell', workorder)

  const getSliderClass = (percent) => {
    if (isCompleted) return classes.greenSlider;
    if (percent >= 75) return classes.blueSlider;
    if (percent >= 50) return classes.yellowSlider;
    if (percent >= 25) return classes.orangeSlider;
    return classes.redSlider;
  };
  let priorityColor = workorder?.priority ? getPriorityColor(workorder.priority) : { backgroundColor: '#eee', color: '#000' };


  return (
    <div className={classes.root}>
      <div className={classes.infoRow}>
      <Chip
        label={isCompleted ? 'Completed' : 'In Progress'}
        className={`${classes.chip} ${
          isCompleted ? classes.completeChip : classes.inProgressChip
        }`}
      />
       <Chip
        label={workorder?.priority || 'Normal' }
        className={`${classes.chip}`}
        style={priorityColor}
      />
      </div>
      
      <div className={classes.sliderContainer}>
        <div className={classes.progressValue}>
          <div className={classes.valueArea}>
            {percentComplete}%
          </div>
        </div>
        <Slider
          value={percentComplete}
          className={`${classes.slider} ${getSliderClass(percentComplete)}`}
          disabled
          valueLabelDisplay="off"
        />
      </div>
    </div>
  );
};

export default StatusCell;
