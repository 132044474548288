// src/components/MapWithDrawing/DrawingList.js
import React from 'react';
import {
    List, ListItem, ListItemText, IconButton, Typography, Button
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';

// Memoize this component
const DrawingList = React.memo(({
    drawings,
    selectedDrawingId,
    onSelectDrawing,
    onDeleteDrawing,
    onClearAll,
    isDrawingMode,
    listStyle // Pass list style class via prop
}) => {
    return (
        <>
            <Typography variant="subtitle1" gutterBottom>Saved Areas ({drawings.length})</Typography>
            <List dense className={listStyle}>
                {drawings.length === 0 ? (
                    <ListItem><ListItemText primary="No areas drawn yet." /></ListItem>
                ) : (
                    drawings.map(drawing => (
                        <ListItem
                            key={drawing.id}
                            button
                            selected={selectedDrawingId === drawing.id}
                            onClick={() => onSelectDrawing(drawing)}
                           // className={selectedDrawingId === drawing.id ? classes.listItemActive : ''} // Apply active class if needed
                            disabled={isDrawingMode}
                        >
                            <ListItemText
                                primary={drawing.name || `Unnamed ${drawing.type}`}
                                primaryTypographyProps={{ noWrap: true, style: { overflow: 'hidden', textOverflow: 'ellipsis' } }}
                                secondary={`Type: ${drawing.type}`}
                            />
                            <IconButton
                                edge="end"
                                aria-label="delete"
                                size="small"
                                onClick={(e) => { e.stopPropagation(); onDeleteDrawing(drawing.id); }}
                                disabled={isDrawingMode}
                                title="Delete Area"
                            >
                                <Delete fontSize="small" />
                            </IconButton>
                        </ListItem>
                    ))
                )}
            </List>
            <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={onClearAll}
                disabled={drawings.length === 0 || isDrawingMode}
                style={{ marginTop: '8px' }}
            >
                Clear All Areas
            </Button>
        </>
    );
});

export default DrawingList;
