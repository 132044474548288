import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL || '';

class ShopInventoryService {
  constructor() {
    this.api = axios.create({
      baseURL: `${baseURL}/api/shop`
    });
  }

  // Get all inventory items
  async getInventoryItems() {
    try {
      const response = await this.api.get('/fetchInventoryItems');
      return response.data;
    } catch (error) {
      console.error('Error fetching inventory items:', error);
      throw error;
    }
  }

  // Get items needing reorder
  async getItemsNeedingReorder() {
    try {
      const response = await this.api.get('/itemsNeedingReorder');
      return response.data;
    } catch (error) {
      console.error('Error fetching items needing reorder:', error);
      throw error;
    }
  }

  // Add a new inventory item
  async addInventoryItem(itemData) {
    try {
      const response = await this.api.post('/addInventoryItem', itemData);
      return response.data;
    } catch (error) {
      console.error('Error adding inventory item:', error);
      throw error;
    }
  }

  // Update an existing inventory item
  async updateInventoryItem(id, itemData) {
    try {
      const response = await this.api.put(`/updateInventoryItem/${id}`, itemData);
      return response.data;
    } catch (error) {
      console.error('Error updating inventory item:', error);
      throw error;
    }
  }

  // Delete an inventory item
  async deleteInventoryItem(id) {
    try {
      const response = await this.api.delete(`/deleteInventoryItem/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error deleting inventory item:', error);
      throw error;
    }
  }

  // Use an inventory item (reduce quantity)
  async useInventoryItem(id, amount) {
    try {
      const response = await this.api.put(`/useInventoryItem/${id}`, { amount });
      return response.data;
    } catch (error) {
      console.error('Error using inventory item:', error);
      throw error;
    }
  }

  // Get categories
  async getCategories() {
    try {
      const response = await this.api.get('/categories');
      return response.data;
    } catch (error) {
      console.error('Error fetching categories:', error);
      throw error;
    }
  }

  // Add a new category
  async addCategory(categoryData) {
    try {
      const response = await this.api.post('/categories', categoryData);
      return response.data;
    } catch (error) {
      console.error('Error adding category:', error);
      throw error;
    }
  }

  // Add a subcategory to a category
  async addSubcategory(categoryId, subcategoryData) {
    try {
      const response = await this.api.post(`/categories/${categoryId}/subcategories`, subcategoryData);
      return response.data;
    } catch (error) {
      console.error('Error adding subcategory:', error);
      throw error;
    }
  }

  // Search inventory items
  async searchInventory(query) {
    try {
      const response = await this.api.get(`/search?q=${encodeURIComponent(query)}`);
      return response.data;
    } catch (error) {
      console.error('Error searching inventory:', error);
      throw error;
    }
  }

  async getItemsByCategory(categoryId, items) {
    console.log('Get Item by category', categoryId)
    let itemsToReturn = [...items].filter(item => {
      console.log('include?', item)
      if (item.category === categoryId) return item
    })
    return itemsToReturn
  }
}

export default new ShopInventoryService();
