import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper';
import moment from 'moment'
import ChooseEntityComponent from '../Entities/ChooseEntityComponent'
import { Button, CircularProgress, TextField, IconButton }  from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import PlusIcon from '@material-ui/icons/Add';
import { fade, makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import PrintIcon from '@material-ui/icons/Print'
import MapIcon from '@material-ui/icons/Map';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputMask from "react-input-mask";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { isEven } from '../../services/common'
import { Link } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DirectionsIcon from '@material-ui/icons/Directions';
import ListOfHouses from './RouteList'
import PrintOptionsModal from './PrintOptionsModal'
import AddMtcWaitListCustomer from './AddMtcWaitListCustomer'
import JobSiteList from './JobSiteList';
import Tooltip from '@material-ui/core/Tooltip';

const defaultCrew = {
  _id: 'All',
  name: 'All'
}

const MtcRouteDashboard = (props) => {
  console.log('Route props', props)
  const classes = styles()
  const [newProjectModalOpen, setNewProjectModalOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [clients, setClientList] = React.useState([])
  const [crew, setCrew] = React.useState(defaultCrew)
  const [crews, setCrews] = React.useState([])
  const [day, setDay] = React.useState('Monday')
  const [salesList, setSalesList] = React.useState([])
  const [projects, setProjects] = React.useState([])
  const [mtcAgreements, setMtcAgreements] = React.useState([])
  const [pdf, setPDF] = React.useState({})
  const [showPdf, setShowPDF] = React.useState(false)
  const [state, setState] = React.useState({
    counter: 0,
    projects: [],
    day: 'All',
    crew: defaultCrew,
    list: [],
    chosenWeek: 'All',
    crews: []
  })
  const [vendorInvoiceInputModalOpen, setVendorInvoiceInputModalOpen] = React.useState(false)

  React.useEffect(() => {
    let isMounted = true

    const fetchMtcAgreements = async () => {
    // console.log('Fetch Mtc Agreements')
      setLoading(true)
      axios.get(`${props.url}/api/mtcroutes/getActiveMtcAgreements?includeSiteDetails=true`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
        // console.log('Got em!', response.data)
          if (isMounted) {
            setMtcAgreements(response.data)
            setState({...state, list: response.data, counter: state.counter++ })
          }
        }
      })
    }

    // let mtcCrews = []
    let mtcCrews = []
    //   {
    //     _id: 'All',
    //     name: 'All'
    //   }
    // ]
    props.crews.map(item => {
      if (item.division === 'Maintenance') {
         mtcCrews.push(item)
      }
    })

    if (isMounted) {
      // console.log('SEt our crews', mtcCrews)
      setCrews(mtcCrews)
    }
    // fetchClients()
    // fetchSalesReps()
    // fetchProjects()
    fetchMtcAgreements()
    // fetchCrews()
    // fetchSupplies()
    return () => {
      isMounted = false
    }
  }, [])

 const updateOurListOfHouses = (theDay, theCrew, typeOfWeek) => {
// console.log('Update our houses for: %s - and Crew: %s ---- Week Tyep: %s', theDay, theCrew.name, typeOfWeek)
  // console.log('State updated...', state)
  const crewId = (theCrew && theCrew._id) ? theCrew._id : null
// console.log('CrewID:', crewId)
  if (crewId) {
    let oldList = [...mtcAgreements]
  // console.log('We have a list:', oldList.length)
    let newList = oldList.filter(item => {
    // console.log('Filter this: 162', item)
      if (item && item.crew && (item.crew === crewId && (item.preferredDay === theDay || theDay === 'All'))) {
        // console.log('This one!!')
        if (item.frequency === 'biweekly') {
          if (typeOfWeek !== 'All' && item.evenOrOddWeeks !== typeOfWeek) {
          // console.log('Skip it - not the week for it - %s vs %s', item.evenOrOddWeeks, typeOfWeek)
          } else { 
          return item
          }
        } else {
          return item
        }
      } else {
        // console.log('%s -- %s', item.crew, crewId)
      }
    })
  // console.log('New LIst:', newList.length)
  console.log('152')
    setCrew(theCrew)
    setState({...state, list: newList, crew: theCrew, day: theDay, chosenWeek: typeOfWeek, counter: state.counter++ })
  } else {
    let oldList = [...mtcAgreements]
  // console.log('We have a list: 183', oldList.length)
    let newList = oldList.filter(item => {
    // console.log('Filter this: 185', item)
      if (item && (item.preferredDay === theDay || theDay === 'All')) {
      // console.log('This one!!')
        if (item.frequency === 'biweekly') {
          if (typeOfWeek !== 'All' && item.evenOrOddWeeks !== typeOfWeek) {
          // console.log('Skip it - not the week for it - This is a %s vs %s (SELECTED)', item.evenOrOddWeeks, typeOfWeek)
          } else { 
          return item
          }
        } else {
          return item
        }

      } else {
      // console.log('%s -- %s', item.crew, crewId)
      }
    })
  // console.log('New LIst:', newList.length)
  console.log('175')
    setCrew(defaultCrew)
    setState({...state, list: newList, crew: defaultCrew, day: theDay, chosenWeek: typeOfWeek, counter: state.counter++ })
  }

 }


  const handleUpdateDay = (newDay) => {
    setState({...state, day: newDay})
    updateOurListOfHouses(newDay, state.crew, state.chosenWeek)
  }

  const handleUpdateCrew = (newCrew) => {
    console.log('Update crew:', newCrew)
    if (newCrew && newCrew._id === 'All') {
      setState({...state, crew: defaultCrew })
      updateOurListOfHouses(state.day, null, state.chosenWeek)
    } else {
      setState({...state, crew: newCrew })
      updateOurListOfHouses(state.day, newCrew, state.chosenWeek)
    }

  }

  const handleUpdateWeek = (weekChosen) => {
  // console.log('Chosen Week:', weekChosen)
    setState({...state, chosenWeek: weekChosen})
    updateOurListOfHouses(state.day, state.crew, weekChosen)
  }

  const handlePrint = (pdf) => {
    setPDF(pdf)
    setShowPDF(true)
    // let data = {...state}
    console.log('Print this bitch')
    // axios.post(`${props.url}/api/company/printRouteSheet`, data).then(response => {
    //   console.log('Print Response', response.data)
    //   setPDF(response.data)
    //   setShowPDF(true)
    // }).catch(e => {
    //   console.log('Error printing: ', e)
    // })
  }

  const handleClose = () => {
    setShowPDF(false)
  }
  const crewIds = []
  mtcAgreements.forEach(ag => {
    if (ag && ag.crew) crewIds.push(ag.crew)
  })
  // console.log('Crewids', crewIds)
  const uniqueCrews = Array.from(new Set(crewIds))
  // console.log('Unique crews', uniqueCrews)
  const crewsWithContracts = [...props.crews].filter(item => {
    if (uniqueCrews.includes(item._id)) return item
  })
  // console.log('Crews w/ a contract', crewsWithContracts)
  return (
    <Grid container style={{margin: 5}} spacing={2}>

        <Grid item lg={6} xs={12} sm={12} md={6}>
          <h1>Maintenance Dashboard </h1>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Paper style={{ minHeight: '80vh', marginRight: 20, padding: 10  }}>
            {
              loading && <CircularProgress size="48" color="#accacd" /> 
            }
            <ActionRow handlePrintCurrentRoute={handlePrint} state={state} url={props.url} crews={crewsWithContracts} />
            <PDFArea pdf={pdf} open={showPdf} closeModal={handleClose} />
            <JobSiteList ags={mtcAgreements} crews={props.crews} />
       
            
           
          </Paper>
        </Grid>
    </Grid>
  )
}


const PDFArea = ({ pdf, open, closeModal }) => {

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      fullWidth
      onClose={closeModal}
    >
      <DialogTitle>Route Sheet</DialogTitle>
      <DialogContent>
        <div style={{ height: '1000px' }}>
          <object style={{padding: '15px'}} aria-label="PDF" data={ pdf } type="application/pdf" width="100%" height="100%" />
         
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>CLOSE</Button>
      </DialogActions>
    </Dialog>
  )
}

const ActionRow = ({ handlePrintCurrentRoute, state, url, crews }) => {
  const [showModal, setShowModal] = React.useState(false)

  const handleMapView = () => {
    console.log('Map View:', state)
    window.open(`/routeview?chosenWeek=${state.chosenWeek}&day=${state.day}`, "_blank")
  }

  const handleOptimizeRoute = () => {
    console.log('Optimize route')
    let data = {
      chosenWeek: state.chosenWeek,
      day: state.day,
      crew: state.crew
    }
    console.log('Optimize this bitch')
    axios.post(`${url}/api/mtcRoutes/optimizeRoute`, data).then(response => {
      console.log('Optimize Response', response.data)
    }).catch(e => {
      console.log('Error Optimizing: ', e)
    })
  }

  const handlePrintRoutes = () => {
    setShowModal(true)
  }
  const handleClose = () => {
    setShowModal(false)
  }

  return (
    <div>
      <PrintOptionsModal open={showModal} closeModal={handleClose} url={url} crews={crews} handleShowPDF={handlePrintCurrentRoute}  />
      <Tooltip title="Print Route Sheets">
        <IconButton
        onClick={handlePrintRoutes}
        disabled={!state.day || !state.chosenWeek || !state.list.length}
      >
        <PrintIcon size={24} />
      </IconButton>
      </Tooltip>
      <Tooltip title="View Map">
      <IconButton
        onClick={handleMapView}
        disabled={!state.day || !state.chosenWeek || !state.list.length}
      >
        <MapIcon size={24} />
      </IconButton>
      </Tooltip>
      <AddMtcWaitListCustomer />
      {/* <IconButton
        onClick={handleOptimizeRoute}
        disabled={!state.day || !state.chosenWeek || !state.list.length}
      >
        <DirectionsIcon size={24} />
      </IconButton> */}
    </div>
  )
}

const DateChooser = ({ date, changeDate }) => {

  return (
    <TextField
      id="date"
      label="Date"
      type="date"
      fullWidth
      value={moment(date).format('YYYY-MM-DD') }
      onChange={ changeDate }
      InputLabelProps={{
        shrink: true,
      }}
    />
  )
}

const WeekChooser = (props) => {
// console.log('Week Chooser ', props)
  const classes = styles()

  const handleChangeWeek = (e) => {
    props.changeWeek(e.target.value)
  }

  return (
    <div className={classes.dayChooserArea}>
       <FormControl className={classes.formControl} fullWidth>
        <Select
          value={props.weekChosen}
          onChange={handleChangeWeek}
          // onChange={(e) => setState({...state, phone['type']: e.target.value })}
          className={classes.selectEmpty}
          inputProps={{ 'aria-label': 'Day of the Week' }}
          fullWidth
          placeholder="Choose Which Day"
        >
           <MenuItem value={"All"}>
            All
          </MenuItem>
          <MenuItem value={"Odd"}>
            Odd (1, 3, 5, 7...)
          </MenuItem>
          <MenuItem value={"Even"}>Even (2, 4, 6, 8...)</MenuItem>
        </Select>
        <FormHelperText>Odd Or Even Weeks of the Year</FormHelperText>
      </FormControl>
    </div>
  )
}

const DayChooser = (props) => {
// console.log('Day Chooser ', props)
  const classes = styles()

  const handleChangeDay = (e) => {
    props.changeDay(e.target.value)
  }

  return (
    <div className={classes.dayChooserArea}>
       <FormControl className={classes.formControl} fullWidth>
        <Select
          value={props.day}
          onChange={handleChangeDay}
          // onChange={(e) => setState({...state, phone['type']: e.target.value })}
          className={classes.selectEmpty}
          inputProps={{ 'aria-label': 'Day of the Week' }}
          fullWidth
          placeholder="Choose Which Day"
        >
           <MenuItem value={"All"}>
            All
          </MenuItem>
          <MenuItem value={"Monday"}>
            Monday
          </MenuItem>
          <MenuItem value={"Tuesday"}>Tuesday</MenuItem>
          <MenuItem value={"Wednesday"}>Wednesday</MenuItem>
          <MenuItem value={"Thursday"}>Thursday</MenuItem>
          <MenuItem value={"Friday"}>Friday</MenuItem>
        </Select>
        <FormHelperText>Day of the Week</FormHelperText>
      </FormControl>
    </div>
  )
}

const CrewChooser = (props) => {
  console.log('props.crew', props.crew)
  const classes = styles()
  const handleChangeCrew = (e) => {
    const selectedCrew = props.crews.find(crew => crew._id === e.target.value);
    props.changeCrew(selectedCrew)
  }
  return (
    <div className={classes.dayChooserArea}>
       <FormControl className={classes.formControl} fullWidth>
        <Select
          value={props.crew._id}
          // defaultValue="All"
          onChange={handleChangeCrew}
          // onChange={(e) => setState({...state, phone['type']: e.target.value })}
          className={classes.selectEmpty}
          inputProps={{ 'aria-label': 'Assigned Crew' }}
          fullWidth
          placeholder="Choose Which Crew"
        >
           <MenuItem key={'All'} value={'All'}>All</MenuItem>
        {
            props.crews.map((item, index) => {
              // console.log(item)
              return (
                <MenuItem key={item._id} value={item._id}>{item.name}</MenuItem>
              )
            })
          }
        </Select>
        <FormHelperText>Assigned Crew</FormHelperText>
      </FormControl>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    crews: state.crews
  }
}

export default React.memo(connect(mapStateToProps)(MtcRouteDashboard))



// const useStyles = makeStyles(theme => ({
  const styles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        // backgroundColor: theme.palette.secondary.main,
        textAlign: 'center'
      },
      [theme.breakpoints.down('md')]: {
        margin: 10,
        // backgroundColor: theme.palette.secondary.main,
      },
      [theme.breakpoints.up('md')]: {
        // backgroundColor: theme.palette.primary.main,
      },
      [theme.breakpoints.up('lg')]: {
        // backgroundColor: '#ccc',
      },
    },
    topButtons: {
      [theme.breakpoints.down('sm')]: {
        // backgroundColor: theme.palette.secondary.main,
        // textAlign: 'center',
        margin: 5,
      },
      [theme.breakpoints.down('md')]: {
        margin: 5,
        // backgroundColor: theme.palette.secondary.main,
      },
      [theme.breakpoints.up('md')]: {
        // backgroundColor: theme.palette.primary.main,
      },
      [theme.breakpoints.up('lg')]: {
        // backgroundColor: '#ccc',
      },
    },
    mainContainer: {
      border: '3px solid #aaa',
      height: '80vh',
      width: '100%',
      padding: 5
    },
    topRowArea: {
      display: 'flex',
      flexDirection: 'row',
      alignSelf: 'stretch',
      justifyContent: 'flext-start',
      // backgroundColor: 'red'
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    dayChooserArea: {
      width: '30%'
    },
    agreementContainer: {
      padding: 5,
      margin: 10,
      border: '2px solid #bbb',
      borderRadius: 30
      // backgroundColor: 'blue'
    },
    mainDetailsArea: {
      minHeight: 800,
      overflow: 'scroll'
    }
    })
  );