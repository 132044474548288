import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  IconButton,
  Collapse,
  Box,
  Grid,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Snackbar,
  CircularProgress
} from '@material-ui/core';
import {
  KeyboardArrowDown as ExpandMoreIcon,
  KeyboardArrowUp as ExpandLessIcon,
  Build as BuildIcon,
  Speed as SpeedIcon,
  CalendarToday as CalendarIcon,
  Timer as TimerIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@material-ui/icons';
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios';
import moment from 'moment';
import ProtectedButton from '../../../actions/protectedButtons';
import { useHistory } from 'react-router-dom';

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  header: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: 600,
  },
  summaryCards: {
    marginBottom: theme.spacing(3),
  },
  card: {
    height: '100%',
  },
  cardIcon: {
    fontSize: 40,
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  tableContainer: {
    maxHeight: 400,
    marginTop: theme.spacing(2),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  expandedRow: {
    backgroundColor: theme.palette.action.hover,
  },
  detailsBox: {
    padding: theme.spacing(2),
  },
  detailsGrid: {
    marginTop: theme.spacing(1),
  },
  serviceLabel: {
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  serviceValue: {
    fontWeight: 400,
  },
  noRecords: {
    padding: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
  editButton: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
  actionsCell: {
    width: 100,
  },
  headerActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  addButton: {
    marginLeft: theme.spacing(1),
  },
}));

const MaintenanceRow = ({ record, classes, onDelete, onEdit, perms }) => {
  const [expanded, setExpanded] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const getServicesPerformed = (services) => {
    return Object.entries(services)
      .filter(([_, service]) => service.performed === 'yes')
      .map(([key, service]) => ({
        name: key.replace(/([A-Z])/g, ' $1').trim(),
        details: service
      }));
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = async () => {
    setIsDeleting(true);
    try {
      await onDelete(record._id);
    } finally {
      setIsDeleting(false);
      setDeleteConfirmOpen(false);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
  };

  const handleEditClick = (e) => {
    e.stopPropagation();
    onEdit(record);
  };

  return (
    <>
      <TableRow hover>
        <TableCell>
          <IconButton size="small" onClick={() => setExpanded(!expanded)}>
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{formatDate(record.serviceDate)}</TableCell>
        <TableCell>{record.currentMileage.toLocaleString()} mi</TableCell>
        <TableCell>{record.nextServiceMileage.toLocaleString()} mi</TableCell>
        <TableCell>
          {getServicesPerformed(record.services).slice(0, 2).map((service, index) => (
            <Chip
              key={index}
              label={formatLabel(service.name)}
              size="small"
              className={classes.chip}
            />
          ))}
          {getServicesPerformed(record.services).length > 2 && (
            <Chip
              label={`+${getServicesPerformed(record.services).length - 2} more`}
              size="small"
              className={classes.chip}
            />
          )}
        </TableCell>
        <TableCell className={classes.actionsCell}>
          <ProtectedButton type="Equipment" kind="update" perms={perms}>
            <IconButton 
              size="small" 
              className={classes.editButton} 
              onClick={handleEditClick}
              aria-label="edit maintenance record"
            >
              <EditIcon />
            </IconButton>
          </ProtectedButton>
          <ProtectedButton type="Equipment" kind="delete" perms={perms}>
            <IconButton 
              size="small" 
              className={classes.deleteButton} 
              onClick={handleDeleteClick}
              aria-label="delete maintenance record"
            >
              <DeleteIcon />
            </IconButton>
          </ProtectedButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box className={classes.detailsBox}>
              <Typography variant="h6" gutterBottom>
                Service Details
              </Typography>
              <Grid container spacing={2} className={classes.detailsGrid}>
                {getServicesPerformed(record.services).map((service, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Typography className={classes.serviceLabel}>
                      {formatLabel(service.name)}
                    </Typography>
                    {service.details.notes && (
                      <Typography variant="body2" className={classes.serviceValue}>
                        Notes: {service.details.notes}
                      </Typography>
                    )}
                    {service.details.oilType && (
                      <Typography variant="body2" className={classes.serviceValue}>
                        Oil Type: {service.details.oilType}
                      </Typography>
                    )}
                    {service.details.filterType && (
                      <Typography variant="body2" className={classes.serviceValue}>
                        Filter: {service.details.filterType}
                      </Typography>
                    )}
                  </Grid>
                ))}
              </Grid>
              
              {record.tireReadings && (
                <>
                  <Typography variant="h6" gutterBottom style={{ marginTop: 16 }}>
                    Tire & Brake Readings
                  </Typography>
                  <Grid container spacing={2}>
                    {Object.entries(record.tireReadings).map(([position, data]) => (
                      <Grid item xs={12} sm={6} md={3} key={position}>
                        <Typography className={classes.serviceLabel}>
                          {formatLabel(position)}
                        </Typography>
                        <Typography variant="body2" className={classes.serviceValue}>
                          Tire Condition: {data.tireCondition || 'Not recorded'}
                        </Typography>
                        <Typography variant="body2" className={classes.serviceValue}>
                          Tire PSI: {data.tirePressure || 'Not recorded'}
                        </Typography>
                        <Typography variant="body2" className={classes.serviceValue}>
                          Brakes: {data.brakePadCondition || 'Not recorded'}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-maintenance-dialog-title"
      >
        <DialogTitle id="delete-maintenance-dialog-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the maintenance record from {formatDate(record.serviceDate)}? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={handleDeleteConfirm} 
            color="secondary" 
            disabled={isDeleting}
            startIcon={isDeleting ? <CircularProgress size={20} /> : null}
          >
            {isDeleting ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const formatDate = (date) => {
  return moment(date).add(9, 'hours').format('MM/DD/YYYY');
};

const EquipmentMaintenanceHistory = ({ equipmentId, url, perms }) => {
  const classes = useStyles();
  const history = useHistory();
  const [maintenanceHistory, setMaintenanceHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    totalServices: 0,
    averageMileage: 0,
    lastService: null,
    nextServiceDue: null,
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  useEffect(() => {
    fetchMaintenanceHistory();
  }, [equipmentId]);

  const fetchMaintenanceHistory = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${url}/api/equipment/getMaintenanceHistory?equipmentId=${equipmentId}`);
      setMaintenanceHistory(response.data);
      calculateStats(response.data);
    } catch (error) {
      console.error('Error fetching maintenance history:', error);
      setSnackbar({
        open: true,
        message: 'Failed to load maintenance history',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const calculateStats = (data) => {
    if (data.length === 0) return;

    const sortedByDate = [...data].sort((a, b) => new Date(b.serviceDate) - new Date(a.serviceDate));
    const lastRecord = sortedByDate[0];
    console.log('Last Record', lastRecord)
    setStats({
      totalServices: data.length,
      averageMileage: Math.round(data.reduce((acc, curr) => acc + curr.currentMileage, 0) / data.length),
      lastService: lastRecord.serviceDate,
      lastServiceMileage: lastRecord?.currentMileage || 0,
      nextServiceDue: lastRecord.nextServiceMileage,
    });
  };

  const handleDeleteRecord = async (recordId) => {
    try {
      await axios.delete(`${url}/api/equipment/deleteMaintenanceRecord`, {
        data: { recordId, equipmentId }
      });
      
      // Update local state by removing the deleted record
      const updatedHistory = maintenanceHistory.filter(record => record._id !== recordId);
      setMaintenanceHistory(updatedHistory);
      calculateStats(updatedHistory);
      
      setSnackbar({
        open: true,
        message: 'Maintenance record deleted successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error deleting maintenance record:', error);
      setSnackbar({
        open: true,
        message: 'Failed to delete the maintenance record',
        severity: 'error'
      });
    }
  };

  const handleEditRecord = (record) => {
    // Navigate to the edit form with the record data
    localStorage.setItem('maintenanceRecordToEdit', JSON.stringify(record));
    history.push(`/vehiclemaintenance/${equipmentId}?edit=${record._id}`);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleAddNewRecord = () => {
    // Navigate to the inspection form to create a new record
    history.push(`/vehiclemaintenance/${equipmentId}`);
  };
console.log('stats', stats)
  return (
    <div className={classes.root}>
      <Paper className={classes.header}>
        <Box className={classes.headerActions}>
          <Typography variant="h5" className={classes.title}>
            Maintenance History
          </Typography>
          <ProtectedButton type="Equipment" kind="add" perms={perms}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<BuildIcon />}
              onClick={handleAddNewRecord}
              className={classes.addButton}
            >
              Add Service
            </Button>
          </ProtectedButton>
        </Box>
      </Paper>

      {maintenanceHistory.length > 0 && (
        <Grid container spacing={2} className={classes.summaryCards}>
          <Grid item xs={12} sm={6} md={3}>
            <Card className={classes.card}>
              <CardContent>
                <BuildIcon className={classes.cardIcon} />
                <Typography variant="h6">{stats.totalServices}</Typography>
                <Typography color="textSecondary">Total Services</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card className={classes.card}>
              <CardContent>
                <SpeedIcon className={classes.cardIcon} />
                <Typography variant="h6">{stats.lastServiceMileage?.toLocaleString() || 'N/A'} mi</Typography>
                <Typography color="textSecondary">Last Service Mileage</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card className={classes.card}>
              <CardContent>
                <CalendarIcon className={classes.cardIcon} />
                <Typography variant="h6">{stats.lastService ? moment(stats.lastService).format('MM/DD/YYYY') : 'N/A'}</Typography>
                <Typography color="textSecondary">Last Service Date</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card className={classes.card}>
              <CardContent>
                <TimerIcon className={classes.cardIcon} />
                <Typography variant="h6">{stats.nextServiceDue?.toLocaleString() || 'N/A'} mi</Typography>
                <Typography color="textSecondary">Next Service Due</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 50 }} />
              <TableCell>Service Date</TableCell>
              <TableCell>Service Mileage</TableCell>
              <TableCell>Next Service</TableCell>
              <TableCell>Services Performed</TableCell>
              <TableCell className={classes.actionsCell}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={6} align="center" padding="normal">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : maintenanceHistory.length > 0 ? (
              maintenanceHistory.map((record) => (
                <MaintenanceRow 
                  key={record._id} 
                  record={record} 
                  classes={classes}
                  onDelete={handleDeleteRecord} 
                  onEdit={handleEditRecord}
                  perms={perms}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} className={classes.noRecords}>
                  <BuildIcon style={{ fontSize: 40, opacity: 0.5, marginBottom: 8 }} />
                  <Typography variant="body1">
                    No maintenance records found for this vehicle
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Snackbar for notifications */}
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

const formatLabel = (serviceKey) => {
  let label = serviceKey
  let trimmed = serviceKey.replace(/([A-Z])/g, ' $1').trim()

  let firstLetter = trimmed?.charAt(0)?.toUpperCase()
  let restOfWord = trimmed?.toString()?.slice(1, trimmed.length)

  label = `${firstLetter}${restOfWord}`
  return label
}

export default EquipmentMaintenanceHistory;
