import React, { useState, useEffect } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  IconButton,
  Chip,
  TextField,
  Button,
  InputAdornment
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Rating } from '@material-ui/lab';
import SearchIcon from '@material-ui/icons/Search';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import moment from 'moment';
import _ from 'lodash';
import BigImageComponent from '../Uploads/BigImageComponent'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  card: {
    height: '100%'
  },
  statsCard: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  statValue: {
    fontSize: '2rem',
    fontWeight: 'bold'
  },
  trendIcon: {
    marginLeft: theme.spacing(1)
  },
  trendUp: {
    color: theme.palette.success.main
  },
  trendDown: {
    color: theme.palette.error.main
  },
  searchField: {
    marginBottom: theme.spacing(2)
  },
  photoPreview: {
    width: 40,
    height: 40,
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(1)
  },
  tableCell: {
    minWidth: 100
  },
  issueChip: {
    backgroundColor: theme.palette.error.light,
    marginRight: theme.spacing(1)
  },
  photoCount: {
    display: 'flex',
    alignItems: 'center'
  },
  ratingContainer: {
    marginBottom: theme.spacing(2)
  },
  ratingHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(0.5)
  },
  ratingNotes: {
    marginLeft: theme.spacing(3),
    fontStyle: 'italic'
  }
}));

const SiteReviewDashboard = ({ data }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState(data || []);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [activeItem, setActiveItem] = useState({})
  const [showBigImages, setShowBigImages] = useState(false) 

  useEffect(() => {
    setFilteredData(data || []);
  }, [data]);

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortData = (data) => {
    return _.orderBy(data, [
      (item) => {
        if (orderBy === 'location') {
          return _.get(item, 'jobSite.name', '').toLowerCase();
        }
        if (orderBy === 'created_at') {
          return new Date(item.created_at);
        }
        return _.get(item, orderBy, '').toLowerCase();
      }
    ], [order]);
  };

  const calculateMetrics = () => {
    const metrics = {
      totalReviews: data.length,
      averageRatings: {},
      recentTrend: 0,
      issuesCount: _.sumBy(data, review => _.get(review, 'issues.length', 0))
    };

    // Calculate average ratings per category
    const allCategories = new Set();
    const ratingsData = {};

    data.forEach(review => {
      _.forEach(review.reviewData, item => {
        if (item.rating > 0) {
          allCategories.add(item.name);
          if (!ratingsData[item.name]) {
            ratingsData[item.name] = [];
          }
          ratingsData[item.name].push(item.rating);
        }
      });
    });

    allCategories.forEach(category => {
      metrics.averageRatings[category] = _.mean(ratingsData[category]);
    });

    return metrics;
  };

  const metrics = calculateMetrics();

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    
    if (!term) {
      setFilteredData(data);
    } else {
      console.log('Search term...', term)
      const filtered = data.filter(review => {
        console.log('Review...', review)

        return (
        review.created_by?.toLowerCase().includes(term) ||
        review?.crew?.name?.toLowerCase()?.includes(term) ||
        review?.crew?.foremanName?.toLowerCase()?.includes(term) ||
        review.jobSite?.name?.toLowerCase().includes(term) ||
        review.jobSite?.address?.toLowerCase().includes(term) ||
        _.some(review.reviewData, item => 
          item.notes?.toLowerCase().includes(term)
        )
      )});
      setFilteredData(filtered);
    }
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getTrendIcon = (trend) => {
    switch(trend) {
      case 'up':
        return <TrendingUpIcon className={`${classes.trendIcon} ${classes.trendUp}`} />;
      case 'down':
        return <TrendingDownIcon className={`${classes.trendIcon} ${classes.trendDown}`} />;
      default:
        return <TrendingFlatIcon className={classes.trendIcon} />;
    }
  };

  const handleOpenImage = (review) => {
    setActiveItem(review)
    setShowBigImages(true)
  }
  const handleCloseBigImageModal = () => {
    setActiveItem({})
    setShowBigImages(false)
  }
  let images = activeItem?.uploads || []
  console.log('206 images', images)

  return (
    <div className={classes.root}>
      <BigImageComponent open={showBigImages} item={activeItem} images={images} closeModal={handleCloseBigImageModal} />
      {/* Dashboard Summary */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Paper className={classes.statsCard}>
            <div>
              <Typography variant="subtitle2" color="textSecondary">
                Total Reviews
              </Typography>
              <Typography className={classes.statValue}>
                {metrics.totalReviews}
              </Typography>
            </div>
          </Paper>
        </Grid>
        
        {Object.entries(metrics.averageRatings).map(([category, rating]) => (
          <Grid item xs={12} md={3} key={category}>
            <Paper className={classes.statsCard}>
              <div>
                <Typography variant="subtitle2" color="textSecondary">
                  {category.charAt(0).toUpperCase() + category.slice(1)}
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography className={classes.statValue}>
                    {rating.toFixed(1)}
                  </Typography>
                  <Rating value={rating} precision={0.25} readOnly size="small" style={{ marginLeft: 8 }} />
                </div>
              </div>
            </Paper>
          </Grid>
        ))}

        <Grid item xs={12} md={3}>
          <Paper className={classes.statsCard}>
            <div>
              <Typography variant="subtitle2" color="textSecondary">
                Open Issues
              </Typography>
              <Typography className={classes.statValue} color="error">
                {metrics.issuesCount}
              </Typography>
            </div>
          </Paper>
        </Grid>
      </Grid>

      {/* Search and Table */}
      <Paper style={{ marginTop: 24, padding: 24 }}>
        <TextField
          className={classes.searchField}
          fullWidth
          variant="outlined"
          placeholder="Search reviews..."
          value={searchTerm}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'created_at'}
                  direction={orderBy === 'created_at' ? order : 'asc'}
                  onClick={() => handleSort('created_at')}
                >
                  Date
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'location'}
                  direction={orderBy === 'location' ? order : 'asc'}
                  onClick={() => handleSort('location')}
                >
                  Location
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'created_by'}
                  direction={orderBy === 'created_by' ? order : 'asc'}
                  onClick={() => handleSort('created_by')}
                >
                  Reviewer
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'crew'}
                  direction={orderBy === 'crew' ? order : 'asc'}
                  onClick={() => handleSort('crew')}
                >
                  Last Crew
                </TableSortLabel>
              </TableCell>
              <TableCell>Ratings & Notes</TableCell>
              <TableCell>Issues</TableCell>
              <TableCell>Photos</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortData(filteredData)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((review, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {moment(review.created_at).format('MM/DD/YYYY')}
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle2">
                      {review.jobSite?.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {review.jobSite?.address}
                    </Typography>
                  </TableCell>
                  <TableCell>{review.created_by}</TableCell>
                  <TableCell>{ review?.workorders?.length ? <CrewInfo workorders={review?.workorders || []} /> : 'N/A' }</TableCell>
                  <TableCell>
                    {review.reviewData?.map((item, idx) => (
                      <div key={idx} className={classes.ratingContainer}>
                        <div className={classes.ratingHeader}>
                          <Typography variant="body2" style={{ marginRight: 8 }}>
                            {item.name.charAt(0).toUpperCase() + item.name.slice(1)}:
                          </Typography>
                          <Rating value={item.rating} precision={0.5} readOnly size="small" />
                        </div>
                        {item.notes && (
                          <Typography variant="body2" color="textSecondary" className={classes.ratingNotes}>
                            {item.notes}
                          </Typography>
                        )}
                      </div>
                    ))}
                  </TableCell>
                  <TableCell>
                    {review.issues?.map((issue, idx) => (
                      <Chip
                        key={idx}
                        label={issue?.label}
                        className={classes.issueChip}
                        size="small"
                      />
                    ))}
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => handleOpenImage(review)}>
                      <div className={classes.photoCount}>
                        <PhotoLibraryIcon />
                        <Typography style={{ marginLeft: 8 }}>
                          {review.photos?.length || 0}
                        </Typography>
                      </div>
                    </Button>
                  </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>

        <TablePagination
          component="div"
          count={filteredData.length}
          page={page}
          onChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
        />
      </Paper>
    </div>
  );
};

const CrewInfo = ({ workorders }) => {
  // console.log('Get Crew info...', workorders)
  let lastWO = {}
  let crew = {}
  workorders.forEach(wo => {
    // console.log('is this the most reacent', wo)
    if (wo?.crew?._id) {
      lastWO = wo
      crew = wo.crew
    }
  })
  // console.log('Crew Name:', crew)
  return (
    <div>
     {`${crew?.name} ${crew?.foremanName ? `(${crew.foremanName})` : ''}`}
    </div>
  )
}

export default SiteReviewDashboard;
