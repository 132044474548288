// InventoryManagement.jsx
import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Box,
  Snackbar,
  Fab,
  Tooltip,
  CircularProgress
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Refresh as RefreshIcon,
  Category as CategoryIcon,
  Warning as WarningIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import ShopInventoryService from './ShopInventoryService'
import AddShopInventoryDialog from './AddShopInventory';
import { connect } from 'react-redux'
import axios from 'axios'
import EditInventoryItemDialog from './EditItemDialog';
import InventoryItemDetailsDialog from './InventoryItemDetails';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

// console.log('Service methods:', Object.keys(ShopInventoryService));
// console.log('Is deleteInventoryItem a function?', typeof ShopInventoryService.deleteInventoryItem === 'function');

let defaultCategories = [
  {
    name: "Filters",
    _id: 'filters',
    subcategories: [
      "Air Filters",
      "Oil Filters",
      "Fuel Filters",
      "Cabin Air Filters",
      "Hydraulic Filters",
      "Transmission Filters",
      "DEF Filters"
    ]
  },
  {
    name: "Fluids",
    _id: 'fluids',
    subcategories: [
      "Engine Oil",
      "Transmission Fluid",
      "Brake Fluid",
      "Power Steering Fluid",
      "Coolant/Antifreeze",
      "Windshield Washer Fluid",
      "DEF (Diesel Exhaust Fluid)",
      "Differential Fluid",
      "Grease/Lubricants"
    ]
  },
  {
    name: "Brakes",
    _id: 'brakes',
    subcategories: [
      "Brake Pads",
      "Brake Rotors",
      "Brake Calipers",
      "Brake Lines",
      "Brake Hardware Kits",
      "Master Cylinders",
      "Brake Boosters"
    ]
  },
  {
    name: "Engine Components",
    _id: 'enginestuff',
    subcategories: [
      "Spark Plugs",
      "Ignition Coils",
      "Belts",
      "Hoses",
      "Thermostats",
      "Water Pumps",
      "Gaskets",
      "Sensors (O2, MAP, MAF)",
      "Injectors",
      "Batteries",
      "Alternators",
      "Starters"
    ]
  },
  {
    name: "Suspension & Steering",
    _id: 'sussteer',
    subcategories: [
      "Shock Absorbers",
      "Struts",
      "Control Arms",
      "Ball Joints",
      "Tie Rod Ends",
      "Sway Bar Links",
      "Bushings",
      "Wheel Bearings",
      "Steering Pumps",
      "Alignment Parts"
    ]
  },
  {
    name: "Electrical",
    _id: 'elec',
    subcategories: [
      "Fuses",
      "Relays",
      "Switches",
      "Bulbs/Lighting",
      "Wiring Harnesses",
      "Sensors",
      "Batteries",
      "Battery Terminals",
      "Alternators"
    ]
  },
  {
    name: "HVAC",
    _id: 'hvac',
    subcategories: [
      "Compressors",
      "Condensers",
      "Evaporators",
      "Heater Cores",
      "Blower Motors",
      "A/C Recharge Kits",
      "Cabin Filters"
    ]
  },
  {
    name: "Exhaust",
    _id: 'exhaust',
    subcategories: [
      "Mufflers",
      "Catalytic Converters",
      "Exhaust Pipes",
      "Exhaust Gaskets",
      "O2 Sensors",
      "DPF Components",
      "EGR Components"
    ]
  },
  {
    name: "Tires & Wheels",
    _id: 'tires',
    subcategories: [
      "Tires",
      "Wheels/Rims",
      "Lug Nuts/Bolts",
      "Valve Stems",
      "TPMS Sensors",
      "Wheel Weights",
      "Tire Repair Kits"
    ]
  },
  {
    name: "Preventive Maintenance",
    _id: 'mtc',
    subcategories: [
      "Wiper Blades",
      "Belts",
      "Hoses",
      "Caps (Oil, Radiator)",
      "Bulbs",
      "Fuses"
    ]
  },
  {
    name: "Tools & Equipment",
    _id: 'tools',
    subcategories: [
      "Diagnostic Tools",
      "Hand Tools",
      "Power Tools",
      "Jacks & Stands",
      "Fluid Service Equipment",
      "Battery Chargers",
      "Air Compressors"
    ]
  },
  {
    name: "Cleaning & Detailing",
    _id: 'detaiing',
    subcategories: [
      "Wash Solutions",
      "Interior Cleaners",
      "Glass Cleaners",
      "Degreasers",
      "Microfiber Towels",
      "Brushes",
      "Pressure Washer Supplies"
    ]
  },
  {
    name: "Safety Equipment",
    _id: 'safety',
    subcategories: [
      "First Aid Kits",
      "Fire Extinguishers",
      "Hazard Triangles",
      "Safety Vests",
      "Gloves",
      "Eye Protection",
      "Spill Kits"
    ]
  }
];


// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  formControl: {
    marginBottom: theme.spacing(2),
    minWidth: 200,
    width: '100%',
  },
  tabContent: {
    padding: theme.spacing(2),
  },
  warningIcon: {
    color: theme.palette.warning.main,
    marginRight: theme.spacing(1),
  },
  actionButton: {
    marginRight: theme.spacing(1),
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 200,
  },
}));

// Tab Panel Component
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`inventory-tabpanel-${index}`}
      aria-labelledby={`inventory-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

// Main Component
const InventoryManagement = (props) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [categories, setCategories] = useState([]);
  const [inventoryItems, setInventoryItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [loading, setLoading] = useState(false );
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('');
  
  // Dialog states
  const [openItemDialog, setOpenItemDialog] = useState(false);
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [openSubcategoryDialog, setOpenSubcategoryDialog] = useState(false);
  const [openUseItemDialog, setOpenUseItemDialog] = useState(false);
  const [openShopInventoryDialog, setOpenShopInventoryDialog] = useState(false);

  // Form states
  const [newItem, setNewItem] = useState({
    barcode: '',
    title: '',
    description: '',
    brand: '',
    model: '',
    partNumber: '',
    categoryName: '',
    subcategoryName: '',
    quantity: 1,
    minStockLevel: 1,
    costPrice: 0,
    preferredSupplier: '',
    location: '',
  });
  
  const [newCategory, setNewCategory] = useState({
    name: '',
    description: '',
  });
  
  const [newSubcategory, setNewSubcategory] = useState({
    name: '',
    description: '',
  });
  
  const [useItemData, setUseItemData] = useState({
    id: '',
    itemName: '',
    currentQuantity: 0,
    amount: 1,
  });
  
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  // Load initial data
  useEffect(() => {
    fetchCategories();
    
    fetchInventoryItems();
  }, []);

  // Fetch categories
  const fetchCategories = () => {
    // setCategories(defaultCategories)
    
    // setLoading(true);
    ShopInventoryService.getCategories()
      .then(data => {
        let allCats = [
          ...defaultCategories,
          ...data
        ]
        setCategories(allCats);
        setSelectedCategory('')
        // if (data.length > 0 && !selectedCategory) {
        //   setSelectedCategory(data[0]._id);
        // }
      })
      .catch(err => {
        setError('Failed to load categories');
        console.error(err);
      })
      .finally(() => setLoading(false));
  };


  const handleOpenEditDialog = (item) => {
    setSelectedItem(item);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedItem(null);
  };

  const handleOpenDetailsDialog = (item) => {
    setSelectedItem(item);
    setOpenDetailsDialog(true);
  };
  
  const handleCloseDetailsDialog = () => {
    setOpenDetailsDialog(false);
    setSelectedItem(null);
  };

  const handleUpdateItem = (updatedItem) => {
    console.log('Update item', updatedItem);
    axios({
      method: 'put',
      url: `${props.url}/api/shop/updateInventoryItem/${updatedItem._id}`,
      data: updatedItem
    }).then(response => {
      console.log('Axios response from updating shop item: ', response);
      if (response.data?.item?._id) {
        console.log('Item updated');
        // fetchInventoryItems();
        handleCloseEditDialog();
        setSnackbar({
          open: true,
          message: 'Item updated successfully',
          severity: 'success',
        });
      } else if (response.data.error) {
        handleCloseEditDialog();
        setSnackbar({
          open: true,
          message: `${response.data.error.code} - ${response?.data.error?.errorResponse?.errmsg}`,
          severity: 'error',
        });
      }
    }).catch(err => {
      console.error('Error updating item:', err);
      setSnackbar({
        open: true,
        message: 'Failed to update item',
        severity: 'error',
      });
    });
  };

  // Fetch inventory items
  const fetchInventoryItems = async () => {
   console.log('fetchInventoryItems')
    const resp = await axios.get(`${props.url}/api/shop/fetchInventoryItems`)
    console.log('fetchInventoryItems resposne', resp)
    if (resp?.data?.length) {
      setInventoryItems(resp.data)
      setFilteredItems(resp.data)
    } else {
      setInventoryItems([])
      setFilteredItems([])
    }
  };

  // Fetch items by category
  const fetchItemsByCategory = (categoryId) => {
    if (!categoryId) return;
    console.log('Fetch Items by category.', categoryId)
    setLoading(true);
    ShopInventoryService.getItemsByCategory(categoryId, inventoryItems)
      .then(data => {
        console.log('filtered..', data)
        // filterOut(data);
        setFilteredItems(data)
      })
      .catch(err => {
        setError('Failed to load items for this category');
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  // Fetch items needing reorder
  const fetchItemsNeedingReorder = () => {
    // setLoading(true);
    // ShopInventoryService.getItemsNeedingReorder()
    //   .then(data => {
    //     setInventoryItems(data);
    //   })
    //   .catch(err => {
    //     setError('Failed to load items needing reorder');
    //     console.error(err);
    //   })
    //   .finally(() => setLoading(false));
  };

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    
    // Load appropriate data based on selected tab
    if (newValue === 0) {
      fetchInventoryItems();
    } else if (newValue === 1) {
      fetchItemsNeedingReorder();
    }
  };

  // Handle category change
  const handleCategoryChange = (event) => {
    const categoryId = event.target.value;
    console.log('Handle category change', categoryId)
    if (!categoryId) {
      setSelectedCategory('')
      setFilteredItems(inventoryItems)
    } else {
         
      setSelectedCategory(categoryId);
      fetchItemsByCategory(categoryId);
    }
  };

  // Handle new item dialog
  const handleOpenItemDialog = () => {
    setOpenItemDialog(true);
  };

  const handleCloseItemDialog = () => {
    setOpenItemDialog(false);
    setNewItem({
      barcode: '',
      title: '',
      description: '',
      brand: '',
      model: '',
      partNumber: '',
      categoryName: '',
      subcategoryName: '',
      quantity: 1,
      minStockLevel: 1,
      costPrice: 0,
      preferredSupplier: '',
      location: '',
    });
  };

  // Handle new category dialog
  const handleOpenCategoryDialog = () => {
    setOpenCategoryDialog(true);
  };

  const handleCloseCategoryDialog = () => {
    setOpenCategoryDialog(false);
    setNewCategory({
      name: '',
      description: '',
    });
  };

  // Handle new subcategory dialog
  const handleOpenSubcategoryDialog = () => {
    setOpenSubcategoryDialog(true);
  };

  const handleViewAudits = () => {
    window.location.href = '/shopaudits'
  }

  const handleCloseSubcategoryDialog = () => {
    setOpenSubcategoryDialog(false);
    setNewSubcategory({
      name: '',
      description: '',
    });
  };

  // Handle use item dialog
  const handleOpenUseItemDialog = (item) => {
    setUseItemData({
      id: item._id,
      itemName: item.title,
      currentQuantity: item.quantity,
      amount: 1,
    });
    setOpenUseItemDialog(true);
  };

  const handleCloseUseItemDialog = () => {
    setOpenUseItemDialog(false);
  };

  // Form change handlers
  const handleItemFormChange = (e) => {
    const { name, value } = e.target;
    setNewItem({
      ...newItem,
      [name]: value,
    });
  };

  const handleCategoryFormChange = (e) => {
    const { name, value } = e.target;
    setNewCategory({
      ...newCategory,
      [name]: value,
    });
  };

  const handleSubcategoryFormChange = (e) => {
    const { name, value } = e.target;
    setNewSubcategory({
      ...newSubcategory,
      [name]: value,
    });
  };

  const handleUseItemFormChange = (e) => {
    const { name, value } = e.target;
    setUseItemData({
      ...useItemData,
      [name]: name === 'amount' ? parseInt(value, 10) || 1 : value,
    });
  };

  // Submit handlers
  const handleAddItem = () => {
    console.log('Add item', newItem)
    axios({
      method: 'post',
      url:  `${props.url}/api/shop/addInventoryItem`,
      data: newItem
    }).then(response => {
      console.log('Axios response from adding shop item: ', response)
      if (response.data?.item?._id) {
        console.log('Item added')
        fetchInventoryItems();
        handleCloseItemDialog();
        setSnackbar({
          open: true,
          message: 'Item added successfully',
          severity: 'success',
        });
      }
    })

    // ShopInventoryService.addInventoryItem(newItem)
    //   .then(data => {
    //     fetchInventoryItems();
    //     handleCloseItemDialog();
    //     setSnackbar({
    //       open: true,
    //       message: 'Item added successfully',
    //       severity: 'success',
    //     });
    //   })
    //   .catch(err => {
    //     setSnackbar({
    //       open: true,
    //       message: 'Failed to add item',
    //       severity: 'error',
    //     });
    //     console.error(err);
    //   });
  };

  const handleAddCategory = () => {
    ShopInventoryService.addCategory(newCategory)
      .then(data => {
        fetchCategories();
        handleCloseCategoryDialog();
        setSnackbar({
          open: true,
          message: 'Category added successfully',
          severity: 'success',
        });
      })
      .catch(err => {
        setSnackbar({
          open: true,
          message: 'Failed to add category',
          severity: 'error',
        });
        console.error(err);
      });
  };

  const handleAddSubcategory = () => {
    if (!selectedCategory) {
      setSnackbar({
        open: true,
        message: 'Please select a category first',
        severity: 'warning',
      });
      return;
    }

    ShopInventoryService.addSubcategory(selectedCategory, newSubcategory)
      .then(data => {
        fetchCategories();
        handleCloseSubcategoryDialog();
        setSnackbar({
          open: true,
          message: 'Subcategory added successfully',
          severity: 'success',
        });
      })
      .catch(err => {
        setSnackbar({
          open: true,
          message: 'Failed to add subcategory',
          severity: 'error',
        });
        console.error(err);
      });
  };

  const handleUseItem = () => {
    ShopInventoryService.useInventoryItem(useItemData.id, useItemData.amount)
      .then(data => {
        fetchInventoryItems();
        handleCloseUseItemDialog();
        setSnackbar({
          open: true,
          message: `Item used successfully. Remaining: ${data.quantity}`,
          severity: 'success',
        });
      })
      .catch(err => {
        setSnackbar({
          open: true,
          message: 'Failed to use item',
          severity: 'error',
        });
        console.error(err);
      });
  };

  // Snackbar close
  const handleCloseSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  // Get subcategories for selected category
  const getSubcategoriesForSelectedCategory = () => {
    if (!selectedCategory) return [];
    
    const category = categories.find(cat => cat._id === selectedCategory);
    return category ? category.subcategories : [];
  };

  // Check if item needs reorder
  const needsReorder = (item) => {
    return item.quantity <= item.minStockLevel;
  };
  const handleOpenShopInventoryDialog = () => {
    setOpenShopInventoryDialog(true);
  };
  
  const handleCloseShopInventoryDialog = () => {
    setOpenShopInventoryDialog(false);
  };
  
  const handleAddShopInventoryItem = (item) => {
    console.log('handleAddShopInventoryItem', item)
    axios({
      method: 'post',
      url:  `${props.url}/api/shop/addInventoryItem`,
      data: item
    }).then(response => {
      console.log('Axios response from adding shop item: ', response)
      console.log('Axios response from adding shop item: ', response)
      if (response.data?.item?._id) {
        console.log('Item added')
        fetchInventoryItems();
        handleCloseItemDialog();
        setSnackbar({
          open: true,
          message: 'Item added successfully',
          severity: 'success',
        });
      }
      if (response.data.error) {
        handleCloseItemDialog();
        setSnackbar({
          open: true,
          message: `${response.data.error.code} - ${response?.data.error?.errorResponse?.errmsg}`,
          severity: 'error',
        });
      }
    })
  }

    const handleOpenDeleteDialog = (item) => {
      setItemToDelete(item);
      setOpenDeleteDialog(true);
    };
    
    const handleCloseDeleteDialog = () => {
      setOpenDeleteDialog(false);
      setItemToDelete(null);
    };
    
    const handleDeleteItem = () => {
      if (!itemToDelete) return;
      
      setLoading(true);
      console.log('Delete this bitch', itemToDelete)
      console.log(ShopInventoryService)
      ShopInventoryService.deleteInventoryItem(itemToDelete._id)
        .then(response => {
          // fetchInventoryItems();
          let updatedItems = inventoryItems.filter(item => (item._id !== itemToDelete._id))
          setInventoryItems(updatedItems)
          filterOut(updatedItems, searchQuery)
          handleCloseDeleteDialog();
          setSnackbar({
            open: true,
            message: 'Item deleted successfully',
            severity: 'success',
          });
        })
        .catch(err => {
          console.error('Error deleting item:', err);
          setSnackbar({
            open: true,
            message: 'Failed to delete item',
            severity: 'error',
          });
        })
        .finally(() => {
          setLoading(false);
        });
      }

      const filterOut = (r, searchText) => {
        console.log('Search:', searchText)
        if (!searchText) setFilteredItems(inventoryItems);
        let rowsToUse = r || inventoryItems;
        // const letter = passedLetter || selectedLetter;
      
        // Split search text into individual terms
        const searchTerms = searchText?.toLowerCase().split(/\s+/).filter(Boolean) || [];
      
        let newRows = rowsToUse.filter(item => {
          
      
          // If no search terms, just return letter filter result
          // if (searchTerms.length === 0) {
          //   return true;
          // }
      
          // Create a single string of all searchable values
          // const searchableFields = ['firstName', 'lastName', 'companyName', 'displayName'];
          // const searchableValues = searchableFields
          //   .map(field => client[field])
          //   .filter(Boolean)
          //   .join(' ')
          //   .toLowerCase();
          const searchableValues = Object.entries(item)
          .map(([key, value]) => {
            // Handle null/undefined
            if (value == null) return '';
            
            // Handle arrays (like phone numbers, addresses, etc)
            if (Array.isArray(value)) {
              return value.map(v => String(v)).join(' ');
            }
            
            // Handle objects (like nested data)
            if (typeof value === 'object') {
              return Object.values(value).map(v => String(v)).join(' ');
            }
            
            // Convert everything else to string
            return String(value);
          })
          .join(' ')
          .toLowerCase();
      
          // Check if ALL search terms are found in any of the fields
          const matchesAllTerms = searchTerms.every(term => 
            searchableValues.includes(term)
          );
      
          return matchesAllTerms;
        });
      
        setFilteredItems(newRows);
      };

    const handleSearch = (e) => {
      e.preventDefault();
      
      if (!searchQuery.trim()) {
        // fetchInventoryItems();
        setFilteredItems(inventoryItems)
        return;
      }
      
      setIsSearching(true);
      setLoading(true);
      filterOut(inventoryItems, searchQuery)
      // ShopInventoryService.searchInventory(searchQuery)
      //   .then(data => {
      //     setInventoryItems(data);
      //   })
      //   .catch(err => {
      //     console.error('Error searching inventory:', err);
      //     setSnackbar({
      //       open: true,
      //       message: 'Error searching inventory',
      //       severity: 'error',
      //     });
      //   })
      //   .finally(() => {
      //     setLoading(false);
      //   });
    };
    

    // Handle the API call to add the new item
    // ShopInventoryService.addInventoryItem(item)
      // .then(data => {
      //   fetchInventoryItems();
      //   handleCloseShopInventoryDialog();
      //   setSnackbar({
      //     open: true,
      //     message: 'Shop inventory item added successfully',
      //     severity: 'success',
      //   });
      // })
      // .catch(err => {
      //   setSnackbar({
      //     open: true,
      //     message: 'Failed to add shop inventory item',
      //     severity: 'error',
      //   });
      //   console.error(err);
      // });
  // };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    
    // Clear search results when query is empty
    if (!e.target.value.trim()) {
      if (isSearching) {
        // fetchInventoryItems();
        setFilteredItems(inventoryItems)
        // filterOut(inventoryItems, e.target.value)
        setIsSearching(false);
      }
    } else {
      filterOut(inventoryItems, e.target.value)
    }
  };

  return (
    <Container className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={8}>
            <Typography variant="h4" className={classes.title}>
              Fleet Inventory Management
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<CategoryIcon />}
                  onClick={handleOpenCategoryDialog}
                  // disabled={true}
                >
                  Add Category
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<CategoryIcon />}
                  onClick={handleOpenSubcategoryDialog}
                  // disabled={true}
                >
                  Add Subcategory
                </Button>
              </Grid>
              <Grid item>
                {/* <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<RefreshIcon />}
                  onClick={fetchInventoryItems}
                >
                  Refresh
                </Button> */}
                 <Button
                  variant="contained"
                  color="primary"
                  startIcon={<VerifiedUserIcon />}
                  onClick={handleViewAudits}
                >
                  Audit History
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleOpenShopInventoryDialog}
                >
                  Add Shop Inventory
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <Paper className={classes.paper}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="All Items" />
          <Tab label="Needs Reorder" />
        </Tabs>
        
        <TabPanel value={tabValue} index={0}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6}>
              {/* <FormControl variant="outlined" className={classes.formControl}> */}
                {/* <InputLabel>Filter by Category</InputLabel> */}
                <TextField
                  select
                  fullWidth
                  variant='outlined'
                  margin="normal"
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  label="Filter by Category"
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                >
                  <MenuItem value={null}>
                    <em>All Categories</em>
                  </MenuItem>
                  {categories.map((category, index) => (
                    <MenuItem key={index} value={category._id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </TextField>
              {/* </FormControl> */}
            </Grid>
            <Grid item xs={12} sm={6}>
                {/* <form onSubmit={handleSearch} style={{ display: 'flex' }}> */}
                  <TextField
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    label="Search Inventory"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    InputProps={{
                      endAdornment: (
                        <IconButton 
                          type="submit" 
                          edge="end" 
                          aria-label="search"
                        >
                          <SearchIcon />
                        </IconButton>
                      ),
                    }}
                  />
                {/* </form> */}
              </Grid>
          </Grid>
          
          {loading ? (
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          ) : (
            <TableContainer component={Paper} className={classes.tableContainer}>
              <Table className={classes.table} aria-label="inventory table">
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Brand/Model</TableCell>
                    <TableCell>Part Number</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>Subcategory</TableCell>
                    <TableCell align="right">Quantity</TableCell>
                    <TableCell align="right">Min Level</TableCell>
                    <TableCell align="right">Cost</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredItems.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        No items found
                      </TableCell>
                    </TableRow>
                  ) : (
                    filteredItems.map((item, index) => (
                      <TableRow 
                        key={index} 
                        hover
                        onClick={() => handleOpenDetailsDialog(item)}
                        style={{ cursor: 'pointer' }}
                      >
                        <TableCell>
                          {needsReorder(item) && (
                            <WarningIcon className={classes.warningIcon} fontSize="small" />
                          )}
                          {item.title}
                        </TableCell>
                        <TableCell>{`${item.brand || ''} ${item.model || ''}`}</TableCell>
                        <TableCell>{item.partNumber}</TableCell>
                        <TableCell>{item?.category?.name || item.category}</TableCell>
                        <TableCell>{item.subcategory}</TableCell>
                        <TableCell align="right">{item.quantity}</TableCell>
                        <TableCell align="right">{item.minStockLevel}</TableCell>
                        <TableCell align="right">
                          {item.costPrice ? `$${item.costPrice.toFixed(2)}` : '-'}
                        </TableCell>
                        <TableCell onClick={(e) => e.stopPropagation()}>
                          <Tooltip title="Edit Item">
                            <IconButton 
                              size="small" 
                              onClick={() => handleOpenEditDialog(item)}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete Item">
                            <IconButton 
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleOpenDeleteDialog(item);
                              }}
                              className={classes.actionButton}
                              style={{ color: red[500] }}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </TabPanel>
        
        <TabPanel value={tabValue} index={1}>
          {loading ? (
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          ) : (
            <TableContainer component={Paper} className={classes.tableContainer}>
              <Table className={classes.table} aria-label="reorder table">
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell>Brand/Model</TableCell>
                    <TableCell>Part Number</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>Subcategory</TableCell>
                    <TableCell align="right">Quantity</TableCell>
                    <TableCell align="right">Min Level</TableCell>
                    <TableCell align="right">Cost</TableCell>
                    <TableCell>Supplier</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredItems.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        No items need reordering
                      </TableCell>
                    </TableRow>
                  ) : (
                    filteredItems.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <WarningIcon className={classes.warningIcon} fontSize="small" />
                          {item.title}
                        </TableCell>
                        <TableCell>{`${item.brand || ''} ${item.model || ''}`}</TableCell>
                        <TableCell>{item.partNumber}</TableCell>
                        <TableCell>{item.category.name}</TableCell>
                        <TableCell>{item.subcategory}</TableCell>
                        <TableCell align="right">{item.quantity}</TableCell>
                        <TableCell align="right">{item.minStockLevel}</TableCell>
                        <TableCell align="right">
                          {item.costPrice ? `$${item.costPrice.toFixed(2)}` : '-'}
                        </TableCell>
                        <TableCell>{item.preferredSupplier || '-'}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </TabPanel>
      </Paper>

      {/* Add Item FAB */}
      <Tooltip title="Add Inventory Item">
        <Fab color="primary" className={classes.fab} onClick={handleOpenItemDialog}>
          <AddIcon />
        </Fab>
      </Tooltip>

      {/* Add Item Dialog */}
      <Dialog open={openItemDialog} onClose={handleCloseItemDialog} maxWidth="md" fullWidth>
        <DialogTitle>Add New Inventory Item</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="barcode"
                label="Barcode"
                value={newItem.barcode}
                onChange={handleItemFormChange}
                fullWidth
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="title"
                label="Title"
                value={newItem.title}
                onChange={handleItemFormChange}
                fullWidth
                margin="normal"
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="description"
                label="Description"
                value={newItem.description}
                onChange={handleItemFormChange}
                fullWidth
                margin="normal"
                variant="outlined"
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                name="brand"
                label="Brand"
                value={newItem.brand}
                onChange={handleItemFormChange}
                fullWidth
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                name="model"
                label="Model"
                value={newItem.model}
                onChange={handleItemFormChange}
                fullWidth
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                name="partNumber"
                label="Part Number"
                value={newItem.partNumber}
                onChange={handleItemFormChange}
                fullWidth
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" fullWidth margin="normal">
                <InputLabel>Category</InputLabel>
                <Select
                  name="categoryName"
                  value={newItem.categoryName}
                  onChange={handleItemFormChange}
                  label="Category"
                  required
                >
                  <MenuItem value="">
                    <em>Select a category</em>
                  </MenuItem>
                  {categories.map((category, index) => (
                    <MenuItem key={index} value={category.name}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" fullWidth margin="normal">
                <InputLabel>Subcategory</InputLabel>
                <Select
                  name="subcategoryName"
                  value={newItem.subcategoryName}
                  onChange={handleItemFormChange}
                  label="Subcategory"
                  required
                  disabled={!newItem.categoryName}
                >
                  <MenuItem value="">
                    <em>Select a subcategory</em>
                  </MenuItem>
                  {categories
                    .find(cat => cat.name === newItem.categoryName)
                    ?.subcategories.map((subcat, index) => (
                      <MenuItem key={index} value={subcat.name}>
                        {subcat.name}
                      </MenuItem>
                    )) || []}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                name="quantity"
                label="Quantity"
                type="number"
                value={newItem.quantity}
                onChange={handleItemFormChange}
                fullWidth
                margin="normal"
                variant="outlined"
                InputProps={{ inputProps: { min: 0 } }}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                name="minStockLevel"
                label="Minimum Stock Level"
                type="number"
                value={newItem.minStockLevel}
                onChange={handleItemFormChange}
                fullWidth
                margin="normal"
                variant="outlined"
                InputProps={{ inputProps: { min: 0 } }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                name="costPrice"
                label="Cost Price"
                type="number"
                value={newItem.costPrice}
                onChange={handleItemFormChange}
                fullWidth
                margin="normal"
                variant="outlined"
                InputProps={{ inputProps: { min: 0, step: 0.01 } }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="preferredSupplier"
                label="Preferred Supplier"
                value={newItem.preferredSupplier}
                onChange={handleItemFormChange}
                fullWidth
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="location"
                label="Storage Location"
                value={newItem.location}
                onChange={handleItemFormChange}
                fullWidth
                margin="normal"
                variant="outlined"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseItemDialog} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={handleAddItem} 
            color="primary" 
            variant="contained"
            disabled={!newItem.title || !newItem.categoryName || !newItem.subcategoryName}
          >
            Add Item
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Category Dialog */}
      <Dialog open={openCategoryDialog} onClose={handleCloseCategoryDialog}>
        <DialogTitle>Add New Category</DialogTitle>
        <DialogContent>
          <TextField
            name="name"
            label="Category Name"
            value={newCategory.name}
            onChange={handleCategoryFormChange}
            fullWidth
            margin="normal"
            variant="outlined"
            required
          />
          <TextField
            name="description"
            label="Description"
            value={newCategory.description}
            onChange={handleCategoryFormChange}
            fullWidth
            margin="normal"
            variant="outlined"
            multiline
            rows={3}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCategoryDialog} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={handleAddCategory} 
            color="primary" 
            variant="contained"
            disabled={!newCategory.name}
          >
            Add Category
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Subcategory Dialog */}
      <Dialog open={openSubcategoryDialog} onClose={handleCloseSubcategoryDialog}>
        <DialogTitle>Add New Subcategory</DialogTitle>
        <DialogContent>
          <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel>Parent Category</InputLabel>
            <TextField
              value={selectedCategory}
              select
              onChange={handleCategoryChange}
              label="Parent Category"
              required
              InputLabelProps={{
                shrink: true,
              }}
            >
              {categories.map((category, index) => (
                <MenuItem key={index} value={category._id}>
                  {category.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          <TextField
            name="name"
            label="Subcategory Name"
            value={newSubcategory.name}
            onChange={handleSubcategoryFormChange}
            fullWidth
            margin="normal"
            variant="outlined"
            required
          />
          <TextField
            name="description"
            label="Description"
            value={newSubcategory.description}
            onChange={handleSubcategoryFormChange}
            fullWidth
            margin="normal"
            variant="outlined"
            multiline
            rows={3}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSubcategoryDialog} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={handleAddSubcategory} 
            color="primary" 
            variant="contained"
            disabled={!selectedCategory || !newSubcategory.name}
          >
            Add Subcategory
          </Button>
        </DialogActions>
      </Dialog>
            
            {/* Use Item Dialog */}
<Dialog open={openUseItemDialog} onClose={handleCloseUseItemDialog}>
<DialogTitle>Use Item from Inventory</DialogTitle>
<DialogContent>
  <Typography variant="subtitle1" gutterBottom>
    {useItemData.itemName}
  </Typography>
  <Typography variant="body2" gutterBottom>
    Current quantity: {useItemData.currentQuantity}
  </Typography>
  <TextField
    name="amount"
    label="Amount to Use"
    type="number"
    value={useItemData.amount}
    onChange={handleUseItemFormChange}
    fullWidth
    margin="normal"
    variant="outlined"
    InputProps={{ 
      inputProps: { 
        min: 1, 
        max: useItemData.currentQuantity 
      } 
    }}
    required
  />
</DialogContent>
<DialogActions>
  <Button onClick={handleCloseUseItemDialog} color="primary">
    Cancel
  </Button>
  <Button 
    onClick={handleUseItem} 
    color="primary" 
    variant="contained"
    disabled={useItemData.amount <= 0 || useItemData.amount > useItemData.currentQuantity}
  >
    Use Item
  </Button>
</DialogActions>
</Dialog>

{/* Snackbar for notifications */}
<Snackbar 
open={snackbar.open} 
autoHideDuration={6000} 
onClose={handleCloseSnackbar}
anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
>
<Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
  {snackbar.message}
</Alert>
</Snackbar>
<AddShopInventoryDialog
  open={openShopInventoryDialog}
  onClose={handleCloseShopInventoryDialog}
  onSubmit={handleAddShopInventoryItem}
  categories={categories}
  url={props.url}
/>
<InventoryItemDetailsDialog
  open={openDetailsDialog}
  onClose={handleCloseDetailsDialog}
  item={selectedItem}
  onEdit={handleOpenEditDialog}
  onDelete={handleOpenDeleteDialog}
/>
<EditInventoryItemDialog
  open={openEditDialog}
  onClose={handleCloseEditDialog}
  onSubmit={handleUpdateItem}
  item={selectedItem}
  categories={categories}
/>
<Dialog
  open={openDeleteDialog}
  onClose={handleCloseDeleteDialog}
  aria-labelledby="delete-dialog-title"
  aria-describedby="delete-dialog-description"
>
  <DialogTitle id="delete-dialog-title">
    Confirm Delete
  </DialogTitle>
  <DialogContent>
    <Typography variant="body1">
      Are you sure you want to delete "{itemToDelete?.title}"? This action cannot be undone.
    </Typography>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseDeleteDialog} color="primary">
      Cancel
    </Button>
    <Button 
      onClick={handleDeleteItem} 
      color="secondary" 
      variant="contained"
      disabled={loading}
    >
      {loading ? <CircularProgress size={24} /> : 'Delete'}
    </Button>
  </DialogActions>
</Dialog>
</Container>
);
};


const mapStateToProps = (state) => {
  // console.log('Mat to customer: ', state)
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    perms: state.perms
  }
}

export default connect(mapStateToProps)(InventoryManagement)
