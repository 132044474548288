// src/components/MapWithDrawing/MapProviderControls.js
import React from 'react';
import {
    FormControl, InputLabel, Select, MenuItem, Grid, Box,
    FormControlLabel, Checkbox, Typography
} from '@material-ui/core';

// Memoize this component as it only depends on props
const MapProviderControls = React.memo(({
    providers,
    currentProvider, // Pass the full provider object
    isHybridMode,
    isDrawingMode,
    onProviderChange,
    onHybridToggle
}) => {

    const selectedProviderName = currentProvider
        ? (currentProvider.name === "Google Hybrid" ? "Google Satellite" : currentProvider.name)
        : "";

    const showHybridCheckbox = currentProvider?.group === "google" && currentProvider.name !== "Google Maps";

    return (
        <Box>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6} md={5}>
                    <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel id="provider-select-label">Map Provider</InputLabel>
                        <Select
                            labelId="provider-select-label"
                            value={selectedProviderName}
                            onChange={(e) => {
                                const selected = providers.find(p => p.name === e.target.value);
                                if (selected) {
                                    onProviderChange(selected); // Pass the full provider object back
                                }
                            }}
                            label="Map Provider"
                            disabled={isDrawingMode}
                        >
                            {providers.map((p) => (
                                // Don't show Google Hybrid as a selectable option directly
                                p.name !== "Google Hybrid" &&
                                <MenuItem key={p.name} value={p.name}>
                                    {p.displayName}{p.group === 'maricopa' ? ` (${p.year})` : ''}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                    {showHybridCheckbox && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isHybridMode}
                                    onChange={(e) => onHybridToggle(e.target.checked)}
                                    color="primary"
                                    size="small"
                                    disabled={isDrawingMode}
                                />
                            }
                            label="Hybrid"
                        />
                    )}
                </Grid>
                <Grid item xs={6} sm={3} md={5}>
                    {currentProvider && (
                        <Typography variant="caption" color="textSecondary" align="right" noWrap>
                            {currentProvider.description || `Viewing: ${currentProvider.displayName}`}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
});

export default MapProviderControls;
