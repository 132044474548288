import React from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import axios from 'axios'
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
import AdbIcon from '@material-ui/icons/Adb';
import { Button, CircularProgress, IconButton }  from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
// import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/AddCircle';
// import FilterListIcon from '@material-ui/icons/FilterList';
import moment from 'moment'
import { Link } from 'react-router-dom'
// import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { socket } from '../SocketIo/Socket'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AddAlarmIcon from '@material-ui/icons/AddAlarm';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import AddCircleIcon from '@material-ui/icons/AddCircle';
// import HoursWorkedModal from '../BHL/HoursWorkedModal'
// import ListOfClients from '../BHL/ListOfClients'
// // import ServiceInputModal from './ServiceInputModal'
// import InvoiceInputModal from '../BHL/InvoiceInputModal'
// import ScanDetails from './UploadStuff/ScanDetailsPage'
import { FileUploader } from 'react-drag-drop-files';
import OpenInBrowser from '@material-ui/icons/OpenInBrowser'
import AddNewPoolMtcModal from './AddNewPoolMtcModal'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {useHistory, useLocation  } from 'react-router-dom'
import DeleteComponent from '../Common/DeleteComponent'

const PoolMtcContainer = (props) => {
// console.log('Pool Mtc Container!!', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [showAddNewModal, setShowAddNewModal] = React.useState(false)
  const [contract, setContract] = React.useState(null)
  const [contracts, setContracts] = React.useState([])

  React.useEffect(() => {
    const fetchContracts = async (id) => {
    // console.log('Fetch Pool agreements', id)
      axios.get(`${props.url}/api/pools/getAgreements?id=${id}`).then(response => {
      console.log('Got agreements', response.data)
        if (response && response.data && response.data.length) {
          setContracts(response.data)
        }
      })
  
    }
    if (props.client && props.client._id) fetchContracts(props.client._id)
  }, [])
  const handleOpenNewModal = () => {
  // console.log('Open it!')
    setShowAddNewModal(true)
  }

  const handleAddNewResponse = (type, d) => {
  // console.log('resp', d)
    // if (d === 'close')
    setContract(null)
    setShowAddNewModal(false)
  }

  const handleSaveAgreement = (savedAgreement) => {
  console.log('Agreement to save:', savedAgreement)
    // setContract(savedAgreement)
    let existingAgreements = [...contracts]
    let index = existingAgreements.findIndex(item => (item._id === savedAgreement._id))
    if (index > -1) {
      existingAgreements[index] = savedAgreement
    } else {
      existingAgreements.unshift(savedAgreement)
    }
    console.log('Update our contracts...', existingAgreements)
    setContracts(existingAgreements)
    setContract(savedAgreement)
  }

  const handleEditAgreement = (ag) => {
    setContract(ag)
    setShowAddNewModal(true)
  }

  const handleDeleteAgreement = (ag) => {
    console.log('Delete this bitch', ag)
    let existingAgreements = contracts
    console.log(existingAgreements)
    let remainingAgreements = existingAgreements.filter(item => (item._id !== ag._id))
    console.log(remainingAgreements)
    setContracts(remainingAgreements)
  }

  return (
    <div className={classes.root}>
     <AddNewPoolMtcModal open={showAddNewModal} entity={props.entity} contract={contract} updateParent={handleAddNewResponse} jobSites={props.jobSites} saveAgreement={handleSaveAgreement} />
      <div className={classes.topRow}>
        <h3>Pool Maintenance</h3>
        <AddNewButton openModal={handleOpenNewModal}/>
      </div>
       
        <Paper className={classes.paper}>
          <AgreementList contracts={contracts} editItem={handleEditAgreement} deleteItem={handleDeleteAgreement} />
        </Paper>
        
        {/* <span>Signed Agreements Will Show Up Here</span> */}
    </div>
  )
}

const AgreementList = (props) => {
// console.log('Agreements:', props)
const classes = useStyles()
  if (props.contracts && props.contracts.length) {
    return (
      <div style={{ backgroundColor: '#eee', margin: 5, padding: 10 }}>
        {
          props.contracts.map((item, index) => {
          console.log('Agreemetn::: ', item)
            return (
              <div key={index} className={classes.contractContainer}>
                
                  <div>
                    
                    <div>
                    {item.isCurrent ? <Chip style={{  color: '#fff', background: '#2a9e0d' }} variant="outlined" size="small" label="Active" />
                    :  <Chip style={{color: '#fff', background: '#ceb67b' }} variant="outlined" size="small" label="Inactive" /> }
                    </div>
                    <div>
                      Preferred Day: {item.preferredDay}
                    </div>
                    <div>
                      Spools: { item.numberOfSpools}
                    </div>
                    <div>
                      Spas: { item.numberOfSpas}
                    </div>
                    <div>
                      Water Features: { item.numberOfWaterFeatures}
                    </div>
                    <div>
                      <RateDetails item={item} />
                    </div>
                    <div>
                      { item.servicePlan ? <ListOfTasks item={item} /> : null } 
                    </div>
                    {/* <OtherActions {...props} item={item} /> */}
                  </div>
                  <div >
                    <OtherActions {...props} item={item} />
                  </div>
                  <div>Other Stuff</div>
                
              </div>
             
            )
          })
        }
      </div>
    )
  } else {
    return null
  }

}

const ListOfTasks = ({ item }) => {
  // console.log('Tasks:', item)
  const classes = useStyles();
  let list = item.servicePlan === 'Basic' ? item.basicPlanList : item.advantagePlanList
  return (
    <div className={classes.taskListConatiner}>
      {
      list.map((line, index) => {
        console.log('Line item...', line)
        const text = line?.text || line
        return (
          <div key={index} className={classes.taskItemConatiner}>
            {text}
          </div>
        )
      })}
    </div>
  )
}
const OtherActions = (props) => {

  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}} >
      <ViewButton {...props}  />  
      <EditButton {...props} />
      <DeleteButton {...props} />
    </div>
  )
}

const RateDetails = ({ item }) => {
  if (item.servicePlan) {
    if (item.servicePlan === 'Basic') {
      return <div>Basic Rate: {item.basicRate} </div>
    } else {
      return <div>Advantage Rate: {item.advantageRate}</div>
    }
  } else {
    return (
      <div>
        <div>
            Basic Rate: {item.basicRate}
        </div>
        <div>
            Advantage Rate: {item.advantageRate}
        </div>
      </div>
       
    )
  }
}

const ViewButton = (props) => {
// console.log('View Button props', props)
  const item = props.item
  const history = useHistory()
// console.log('HISTORY:', history)
  const handleView = () => {
    // props.editItem(item)
    // history.push('./estimate/pool/' + item._id)
    // const url = 
  // console.log('HISTORY :', history)
    // window.history.replaceState({}, document.title)
    window.open('../../../estimate/pool/' + item._id, "_blank")
  }
  
  return (
     <div>
      <Tooltip
        title="View Agreement Online"
        >
             <IconButton
     
          variant="contained"
          size="small"
          
          onClick={handleView}
          style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }}
        ><OpenInBrowser sx={{ fontSize: 10 }} /></IconButton>

        </Tooltip>
   
    </div>
    // <div>
    //   <Button
    //     startIcon={<SearchIcon sx={{ fontSize: 10 }} />}
    //     variant="contained"
    //     size="small"
    //     color="primary"
    //     onClick={handleView}
    //     style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }}
    //   >View</Button>
    // </div>
  )
}

const DeleteButton = (props) => {
  const item = props.item

  const handleDelete = () => {
    console.log('Delete this one!!', item)
    props.deleteItem(item)
  }
  return (
    <DeleteComponent
    type="Pool Maintenance Agreements"
    item={item}
    title="Delete Pool Maintenance Agreement?"
    verificationText={`Are you sure you wish to Delete this agreement?`}
    confirmationOfIntentText="This action is irreversible, this item will be gone forever!"
    confirmationButton="DELETE"
    denyText="NO"
    ifApproved={handleDelete}
    deleteFunction={'poolMtcAgreement'}
  />
  )
}

const EditButton = (props) => {
  const item = props.item
  const handleEdit = () => {
    props.editItem(item)
  }
  return (
    <div>
      <Tooltip
        title="Edit Agreement"
        >
             <IconButton
     
          variant="contained"
          size="small"
          
          onClick={handleEdit}
          style={{ marginLeft: 5, marginRight: 5, marginTop: 10 }}
        ><EditIcon sx={{ fontSize: 10 }} /></IconButton>

        </Tooltip>
   
    </div>
  )
}

const AddNewButton = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.addNewButtonContainer}>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={props.openModal} 
      >Add New</Button>
    </div>
   
  )
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url
  }
}

export default connect(mapStateToProps)(PoolMtcContainer)

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: '5px',
    padding: 5
  },
  paper: {
    width: '100%',
    // height: '100%',
    // minHeight: '80vh',
    // padding: 10,
    marginBottom: '3rem',
  },
  grid: {
    // margin: '10px',
    // padding: '10px',
    // width: '95%'
  },
  topRow: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'space-between'
  },
  topRowButton: {
    margin: 45,
    marginTop: 10,
    marginBottom: 10,
    width: 276
  },
  addNewButtonContainer: {
    // backgroundColor: 
  },
  contractContainer: {
    display: 'flex',
    flexDirection: 'row', 
    alignSelf: 'stretch', 
    justifyContent: 'space-between', 
    backgroundColor: '#fff', 
    border: '3px solid #aaa', 
    borderRadius: 15, 
    margin: '10px', 
    padding: '10px'
  },
  taskListConatiner: {
    padding: '5px',
    border: '2px solid #eee',
    borderRadius: 5
  },
  taskItemConatiner: {
    padding: '5px'
  }
}))