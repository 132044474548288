/**
 * MapContainer - Handles the Google Maps rendering and interactions
 * 
 * This component is responsible for:
 * - Rendering the Google Map
 * - Managing the drawing manager
 * - Rendering polygons
 * - Handling polygon interactions
 */

import React, { memo, useState } from 'react';
import { GoogleMap, LoadScript, Polygon, Circle, Marker, DrawingManagerF } from '@react-google-maps/api';
import { 
  Tooltip, 
  IconButton,
  Menu, 
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Button
} from '@material-ui/core';
import { 
  Undo, 
  Redo, 
  SaveAlt, 
  Close,
  Create,
  Help,
  Layers,
  Timeline as PolylineIcon,
  Gesture as GestureIcon,
  OpenInBrowser,
  Edit,
  OpenInNewRounded,
  DeleteForever as Delete
} from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/core/styles';

// Import constants for map configuration
import { 
  mapLibraries,
  containerStyle
} from './constants';

// Styles
const useStyles = makeStyles(theme => ({
  drawingControls: {
    position: 'absolute',
    left: theme.spacing(2),
    top: theme.spacing(2),
    display: 'flex',
    backgroundColor: 'white',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    zIndex: 20,
    padding: theme.spacing(0.5),
  },
  drawingButton: {
    margin: theme.spacing(0.5),
  },
  toolbarContainer: {
    position: 'absolute',
    top: theme.spacing(2),
    left: '26%',
    backgroundColor: 'white',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5),
    zIndex: 10,
    display: 'flex',
    boxShadow: theme.shadows[3],
  },
  toolbarButton: {
    margin: theme.spacing(0.5),
  },
  undoRedoContainer: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    display: 'flex',
    backgroundColor: 'white',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    zIndex: 10,
  },
  undoRedoButton: {
    margin: theme.spacing(0.5),
  },
  drawingToolIcon: {
    marginRight: theme.spacing(1)
  },
  drawingToolActive: {
    backgroundColor: theme.palette.action.selected,
  },
  freehandDrawingIndicator: {
    position: 'absolute',
    top: theme.spacing(2),
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
    boxShadow: theme.shadows[2],
  },
}));

const MapContainer = memo(({
  isGoogleMapsLoaded,
  drawingState,
  drawingActions,
  polygons,
  selectedPolygon,
  center,
  zoom,
  activeTab,
  geofenceRadius,
  handleMapPolygonClick,
  handlePolygonPathChange,
  handlePolygonComplete,
  onMapLoad,
  handleZoomChanged,
  onCircleRadiusChanged,
  getPolygonOptions,
  getPolygonCenter,
  calculateNetArea,
  registerPolygonRef,
  circleRef,
  mapRef,
  drawingManagerRef,
  drawingToolType,
  setDrawingToolType,
  selectedVertex,
  deleteSelectedVertex,
  updateParent
}) => {
  const styles = useStyles();
  const theme = useTheme()
  // Create a label icon for polygon when GoogleMaps is loaded
  const createLabelIcon = (text) => {
    if (!window.google) return null;
    
    const svg = `
      <svg xmlns="http://www.w3.org/2000/svg" width="200" height="30">
        <rect x="0" y="0" width="200" height="30" rx="5" ry="5" fill="white" opacity="0.8" />
        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-family="Arial" font-size="14" fill="black">${text}</text>
      </svg>`;
    
    return {
      url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg),
      scaledSize: new window.google.maps.Size(200, 30),
      anchor: new window.google.maps.Point(100, 15)
    };
  };
  const DrawingModeMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    
    const handleMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
    
    const handleMenuClose = () => {
      setAnchorEl(null);
    };
    
    const handleSelectDrawingType = (type) => {
      setDrawingToolType(type);
      handleMenuClose();
    };
    
    return (
      <>
        <Tooltip title="Select Drawing Tool">
          <span>
          <IconButton
            className={styles.toolbarButton}
            onClick={handleMenuOpen}
            disabled={drawingState.isDrawing || drawingState.editingPolygonIndex !== null}
          >
            <Create />
          </IconButton>
          </span>
        </Tooltip>
        
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => {
            handleSelectDrawingType('polygon');
            drawingActions.startDrawing('polygon');
          }}>
            <ListItemIcon>
              <PolylineIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Polygon Tool" secondary="Click to place points" />
          </MenuItem>
          
          <MenuItem onClick={() => {
            handleSelectDrawingType('freehand');
            drawingActions.startDrawing('freehand');
          }}>
            <ListItemIcon>
              <GestureIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Freehand Tool" secondary="Draw by dragging" />
          </MenuItem>
        </Menu>
      </>
    );
  };

  const saveAreas = () => {
    console.log('Save areasy.....')
  }
  const openModal = () => {
    console.log('Open edit modal...')
    updateParent('open')
  }

  const deletePolygon = () => {
    console.log('Delete the polygon..')
    updateParent('delete')
  }

  let isDraggable = true
  if (drawingState.isDrawing && drawingState.mode === 'freehand') {
    console.log('disable dragging')
    isDraggable = false
  }

  console.log('Drawing State now:::: ', drawingState)
  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GMAPKEY}
      libraries={mapLibraries}
      id="google-map-script"
    >
      {/* Drawing Controls */}
      {drawingState.isDrawing && (
        <div className={styles.drawingControls}>
          <Tooltip title="Undo last point (Ctrl+Z)">
            <span>
              <IconButton 
                className={styles.drawingButton}
                onClick={() => {
                  console.log("Undo button clicked");
                  drawingActions.undoPoint();
                }}
                disabled={!drawingState.isDrawing || drawingState.historyPosition <= 0}
              >
                <Undo />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Redo point (Ctrl+Y)">
            <span>
              <IconButton 
                className={styles.drawingButton}
                onClick={() => {
                  console.log("Redo button clicked");
                  drawingActions.redoPoint();
                }}
                disabled={!drawingState.isDrawing || drawingState.historyPosition >= drawingState.history.length - 1}
              >
                <Redo />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Complete Drawing (Enter)">
            <IconButton 
              className={styles.drawingButton}
              onClick={drawingActions.completeDrawing}
              color="primary"
            >
              <SaveAlt />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancel Drawing (Esc)">
            <IconButton 
              className={styles.drawingButton}
              onClick={drawingActions.cancelDrawing}
              color="secondary"
            >
              <Close />
            </IconButton>
          </Tooltip>
        </div>
      )}
      {selectedVertex && <DeletePointButton selectedVertex={selectedVertex} deleteSelectedVertex={deleteSelectedVertex} />}
      {/* {activeTab === 1 && (
          <div style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}>
            <Typography variant="subtitle2">Drawing Tool:</Typography>
            <div style={{ display: 'flex', gap: theme.spacing(1), marginTop: theme.spacing(0.5) }}>
              <Button
                variant={drawingToolType === 'polygon' ? 'contained' : 'outlined'}
                size="small"
                color="primary"
                startIcon={<PolylineIcon />}
                onClick={() => setDrawingToolType('polygon')}
                disabled={drawingState.isDrawing}
              >
                Polygon
              </Button>
              <Button
                variant={drawingToolType === 'freehand' ? 'contained' : 'outlined'}
                size="small"
                color="primary"
                startIcon={<GestureIcon />}
                onClick={() => setDrawingToolType('freehand')}
                disabled={drawingState.isDrawing}
              >
                Freehand
              </Button>
            </div>
          </div>
        )} */}

      {activeTab === 1 && (
          <div className={styles.toolbarContainer}>
            <DrawingModeMenu />
            {/* Keep other buttons */}
            {/* <Tooltip title="Save All Areas">
              <IconButton 
                className={styles.toolbarButton} 
                onClick={saveAreas}
                disabled={drawingState.isDrawing}
              >
                <SaveAlt />
              </IconButton>
            </Tooltip> */}
            {
              drawingState?.editingPolygonIndex !== null ? (
                <>
                 {/* <Tooltip title="Edit">
                  <IconButton className={styles.toolbarButton}>
                    <Edit />
                  </IconButton>
                </Tooltip> */}
                <Tooltip title="View Details">
                  <IconButton className={styles.toolbarButton} onClick={openModal}>
                    <OpenInNewRounded />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete Polygon">
                  <IconButton className={styles.toolbarButton} onClick={deletePolygon}>
                    <Delete />
                  </IconButton>
                </Tooltip>
                </>
               
              ) : null
            }
            
          </div>
        )}
      {drawingState.isDrawing && drawingState.mode === 'freehand' && (
        <div className={styles.freehandDrawingIndicator}>
          <GestureIcon style={{ marginRight: 8 }} />
          <Typography variant="body2">
            Freehand Drawing Mode - Click and drag to draw
          </Typography>
        </div>
      )}

      {/* Main Google Map */}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        onZoomChanged={handleZoomChanged}
        onLoad={onMapLoad}
        options={{
          streetViewControl: true,
          draggable: isDraggable,
          zoomControl: true,
          keyboardShortcuts: false,
          scaleControl: true,
          scrollwheel: true,
          mapTypeId: 'satellite'
        }}
      >
        {/* Center Marker */}
        {center && (
          <Marker
            position={center}
            draggable={true}
            icon={{
              path: 'M 0,0 m -10,0 a 10,10 0 1,0 20,0 a 10,10 0 1,0 -20,0',
              scale: 1,
              fillColor: "#f33",
              fillOpacity: 1,
              strokeColor: "#FFFFFF",
              strokeWeight: 2,
            }}
            zIndex={1}
          />
        )}
        
        {/* Geofence Circle or Marker based on active tab */}
        {activeTab === 0 ? (
          <Circle
            onLoad={circle => circleRef.current = circle}
            onRadiusChanged={onCircleRadiusChanged}
            center={center}
            radius={geofenceRadius}
            options={{
              strokeColor: '#FF0000',
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: '#FF0000',
              fillOpacity: 0.1,
              editable: true,
              draggable: false,
            }}
            
            />
        ) : (
          <Marker
            position={center}
            draggable={false}
            icon={{
              path: 'M 0,0 m -10,0 a 10,10 0 1,0 20,0 a 10,10 0 1,0 -20,0',
              scale: 1,
              fillColor: "#f33",
              fillOpacity: 1,
              strokeColor: "#FFFFFF",
              strokeWeight: 2,
            }}
            label={'Property'}
            zIndex={1}
          />
        )}
        
        {/* Polygons */}
        {isGoogleMapsLoaded && polygons.map((polygon, index) => {
          if (polygon.isHidden) return null;
          const netArea = polygon.type === 'propertyLine' ? 0 : calculateNetArea(polygon);
          const isSelected = drawingState.editingPolygonIndex === index;
          const polygonCenter = getPolygonCenter(polygon.paths, center);
          const isPropLine = polygon.type === 'propertyLine'
          return (
            <React.Fragment key={polygon.id || index}>
              <Polygon
                paths={polygon.paths}
                options={getPolygonOptions(polygon, isSelected, isPropLine)}
                onClick={() => handleMapPolygonClick(index)}
                onLoad={(polygonObj) => registerPolygonRef(index, polygonObj)}
                onMouseUp={handlePolygonPathChange(index)}
                onDragEnd={handlePolygonPathChange(index)}
                style={{
                  zIndex: isPropLine ? 0 : 1
                }}
              />
              {zoom >= 15 && polygon.type !== 'subtraction' && polygon.type !== 'propertyLine' && (
                <Marker
                  position={polygonCenter}
                  icon={createLabelIcon(`${polygon.name}: ${netArea.toFixed(2)} sq ft`)}
                />
              )}
            </React.Fragment>
          );
        })}

        {/* Drawing Manager */}
        {isGoogleMapsLoaded && (
          <DrawingManagerF
            onLoad={manager => {
              drawingManagerRef.current = manager;
              console.log('Drawing manager loaded');
            }}
            onPolygonComplete={handlePolygonComplete}
            options={{
              drawingControl: activeTab === 1 && !drawingState.isDrawing && drawingState.editingPolygonIndex === null,
              drawingMode: drawingState.drawingMode,
              drawingControlOptions: {
                position: window.google?.maps?.ControlPosition?.TOP_CENTER || 1,
                drawingModes: window.google?.maps?.drawing?.OverlayType
                  ? [
                      window.google.maps.drawing.OverlayType.POLYGON,
                      window.google.maps.drawing.OverlayType.RECTANGLE
                    ]
                  : []
              },
              polygonOptions: {
                fillColor: '#3388ff',
                fillOpacity: 0.4,
                strokeColor: '#3388ff',
                strokeOpacity: 1,
                strokeWeight: 2,
                editable: true,
                draggable: true
              }
            }}
          />
        )}
      </GoogleMap>
    </LoadScript>
  );
});

const DeletePointButton = ({ selectedVertex, deleteSelectedVertex }) => {
  if (!selectedVertex) return null;
  
  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={deleteSelectedVertex}
      style={{
        position: 'absolute',
        top: '100px',
        right: '10px',
        zIndex: 1000
      }}
    >
      Delete Selected Point
    </Button>
  );
};

MapContainer.displayName = 'MapContainer';

export default MapContainer;