/**
 * Sidebar - Displays the controls and polygon list
 * 
 * This component is responsible for:
 * - Displaying the list of polygons
 * - Showing polygon properties
 * - Handling polygon selection
 * - Providing controls for polygon management
 */

import React, { useState, useEffect, useCallback, memo } from 'react';
import {
  Button,
  Typography,
  TextField,
  Tabs,
  Tab,
  Checkbox,
  FormControlLabel,
  Paper,
  IconButton,
  Tooltip,
  Divider
} from '@material-ui/core';
import {
  Visibility,
  VisibilityOff,
  Search,
  Add,
  SaveAlt,
  MergeType as MergeIcon
} from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';

// Import polygon types and constants
import { POLYGON_TYPES } from './constants';

// Styles
const useStyles = makeStyles(theme => ({
  sideBarDetails: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  },
  tabContent: {
    // margin: 10,
    // padding: 10,
    // backgroundColor: 'red', // #eee',
    width: '100%',
    // height: '100%',
    height: '45vh',
    // overflowY: 'auto'
  },
  areaItemContainer: {
    height: '20vh',
    maxHeight: '40vh',
    overflowY: 'scroll',
    marginTop: theme.spacing(2),
    // backgroundColor: 'blue'
  },
  sidebarItemContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    borderRadius: 8,
    border: '2px solid #ccc',
    marginBottom: 10,
    marginTop: 5,
    padding: theme.spacing(1),
    alignSelf: 'stretch',
    transition: 'all 0.2s ease-in-out'
  },
  selectedSidebarItem: {
    border: '2px solid blue',
    borderRadius: 8,
    backgroundColor: 'rgba(0, 0, 255, 0.05)',
  },
  visibilityToggle: {
    marginLeft: 'auto',
    marginRight: 0,
  },
  exclusionArea: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.15)',
    }
  },
  propertyLine: {
    borderColor: '#0000FF',
    backgroundColor: 'rgba(0, 0, 255, 0.05)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 255, 0.1)',
    }
  },
  propertyLineHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderBottom: '1px solid #ccc',
  },
  propertyLinesSection: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    backgroundColor: '#eef',
    borderRadius: theme.spacing(1),
  
  },
  propertyLineContent: {
    maxHeight: '15vh',
    overflowY: 'auto'
  },
  saveGeofenceArea: {
    marginTop: 50,
    display: 'flex',
    alignSelf: 'stretch',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: theme.spacing(1)
  },
  saveArea: {
    // marginTop: 50,
    // margin: 10,
    // padding: 10,
    // backgroundColor: 'blue',
    // position: 'absolute',
    bottom: 30,
    display: 'flex',
    alignSelf: 'stretch',
    // width: 300,
    justifyContent: 'center',
    flexDirection: 'column',
    gap: theme.spacing(1)
  },
}));

const Sidebar = memo(({
  polygons,
  selectedPolygonIndex,
  editingPolygonIndex,
  jobSite,
  geofenceRadius,
  activeTab,
  onStartDrawing,
  onOpenEditDialog,
  onToggleVisibility,
  onSaveAreas,
  onSaveGeofenceArea,
  onUpdateGeofenceRadius,
  onTabChange,
  onChangeSite,
  onSearchMCAssessor,
  calculateNetArea,
  clients,
  url,
  handleMergeAreas
}) => {
  const styles = useStyles();
  
  // Local state
  const [selectedClient, setSelectedClient] = useState({});
  const [availableSites, setAvailableSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState({});
  const [totalArea, setTotalArea] = useState(0);
  const [netArea, setNetArea] = useState(0);
  
  // Get polygon type label
  const getPolygonTypeLabel = useCallback((type) => {
    switch (type) {
      case POLYGON_TYPES.TURF:
        return 'Turf';
      case POLYGON_TYPES.ORNAMENTAL:
        return 'Ornamental';
      case POLYGON_TYPES.BARE_GROUND:
        return 'Bare Ground';
      default:
        return 'Unknown';
    }
  }, []);
  
  // Calculate area in square feet
  const calculateAreaInFeet = useCallback((paths) => {
    if (!window.google || !window.google.maps || !window.google.maps.geometry) {
      return 0;
    }
    
    if (!paths || !Array.isArray(paths) || paths.length < 3) {
      return 0;
    }
    
    try {
      const latLngPaths = paths.map(path => new window.google.maps.LatLng(path.lat, path.lng));
      const areaMeters = window.google.maps.geometry.spherical.computeArea(latLngPaths);
      return areaMeters * 10.7639; // Convert square meters to square feet
    } catch (err) {
      console.error("Error calculating area:", err);
      return 0;
    }
  }, []);
  
  // Calculate total and net areas
  useEffect(() => {
    if (!window.google || !window.google.maps || !window.google.maps.geometry) {
      return; // Exit early if Google Maps geometry isn't loaded
    }
  
    let total = 0;
    let net = 0;
  
    // Process each polygon
    polygons.forEach(polygon => {
      if (polygon.type !== 'propertyLine') {
        const areaFeet = calculateAreaInFeet(polygon.paths);
        
        if (polygon.type === 'area') {
          const netAreaValue = calculateNetArea(polygon);
          net += netAreaValue;
          total += areaFeet;
        }
      }
    });
    
    setTotalArea(total);
    setNetArea(net);
  }, [polygons, calculateAreaInFeet, calculateNetArea]);
  
  // Update client and site when job site changes
  useEffect(() => {
    const jobSiteClient = jobSite?.client;
    if (jobSiteClient) {
      let clientInList = clients?.find(client => {
        return (client._id === jobSiteClient._id);
      });
      
      if (clientInList) {
        setSelectedClient(clientInList);
      } else {
        setSelectedClient(jobSite?.client || {});
      }
      
      setSelectedSite(jobSite || {});
    }
  }, [jobSite, clients]);
  
  // Handle click on polygon in sidebar
  const handleClick = useCallback((index) => {
    onOpenEditDialog(index);
  }, [onOpenEditDialog]);
  
  // Handle visibility toggle
  const handleToggleVisibility = useCallback((event, index) => {
    event.stopPropagation(); // Prevent click from selecting the polygon
    onToggleVisibility(index);
  }, [onToggleVisibility]);
  
  // Update customer
  const updateCustomer = useCallback(async (e, newClient) => {
    setSelectedSite({});
    
    if (!newClient || !newClient._id) {
      setSelectedClient({});
      setAvailableSites([]);
      setSelectedSite({});
      onChangeSite(null);
    } else {
      try {
        const response = await axios.get(`${url}/api/customer/getAllJobsites?id=${newClient?._id}&sitesOnly=true`);
        const clientJobsites = response.data;
        
        if (clientJobsites?.length) {
          setAvailableSites(clientJobsites);
          updateJobsite(null, clientJobsites[0]);
        } else {
          setAvailableSites([]);
        }
      } catch (error) {
        console.error('Error fetching job sites:', error);
      }
    }
  }, [url, onChangeSite]);
  
  // Update job site
  const updateJobsite = useCallback(async (e, newSite) => {
    setSelectedSite(newSite);
    onChangeSite(newSite);
  }, [onChangeSite]);
  
  // Property Lines Section component
  const PropertyLinesSection = useCallback(({ propertyLines }) => {
    if (!propertyLines || propertyLines.length === 0) return null;
    
    return (
      <div className={styles.propertyLinesSection}>
        <div className={styles.propertyLineHeader}>
          <div><strong>Property Lines</strong></div>
        </div>
        <div className={styles.propertyLineContent}>
        {propertyLines.map((line, index) => {
          // Find the actual index in the main polygons array
          const mainIndex = polygons.findIndex(p => p.APN === line.APN);
          
          return (
            <div key={`property-${line.id || index}`} className={`${styles.sidebarItemContainer} ${styles.propertyLine}`}>
              <div>
                <div>APN: {line.APN}</div>
                <div style={{ fontSize: '0.8em', color: '#666' }}>Property Boundary</div>
              </div>
              
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<Visibility />}
                    checkedIcon={<VisibilityOff />}
                    checked={line.isHidden}
                    onChange={(e) => handleToggleVisibility(e, mainIndex)}
                    onClick={(e) => e.stopPropagation()}
                  />
                }
                label=""
                className={styles.visibilityToggle}
              />
            </div>
          );
        })}
        </div>
      </div>
    );
  }, [polygons, styles, onSearchMCAssessor, handleToggleVisibility]);
  
  const onMergeAreas = () => {
    console.log('Merge areas...')
    handleMergeAreas()
  }
  return (
    <div className={styles.sideBarDetails}>
      <div>
        <Autocomplete
          id="customer"
          options={clients || []}
          getOptionLabel={(option) => {
            let displayname = `${option?.displayName ? option?.displayName : option?.searchName ? `${option.searchName}` : ''}`;
            return displayname;
          }}
          className={styles.paddedStuff}
          onChange={updateCustomer}
          fullWidth
          selectOnFocus
          value={selectedClient}
          renderInput={(params) => <TextField variant="outlined" {...params} label="Customer" />}
        />
        
        <Autocomplete
          id="jobsite"
          options={availableSites || []}
          getOptionLabel={(site) => {
            let siteInfo = `${site?.name || ''} ${site?.address || ''} ${site?.buildingNumber || ''}`;
            return siteInfo;
          }}
          className={styles.paddedStuff}
          onChange={updateJobsite}
          fullWidth
          selectOnFocus
          value={selectedSite}
          renderInput={(params) => <TextField variant="outlined" {...params} label="Jobsite" />}
        />
      </div>
      
      {selectedSite && selectedSite._id && (
        <Paper elevation={2} style={{ padding: '10px', margin: '10px 0', backgroundColor: '#f8f8f8' }}>
          <div>
            {selectedClient && selectedClient._id && (
              <Typography variant="subtitle1">{selectedClient.displayName}</Typography>
            )}
            <Typography variant="body2">
              {selectedSite?.areas?.length ? `(${selectedSite.areas.length} Areas Mapped)` : ''}
            </Typography>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="body2" style={{ maxWidth: '80%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {selectedSite.name} {selectedSite?.address}
            </Typography>
            <Tooltip title="Search MC Assessor (Higher-Res Images)">
              <IconButton onClick={onSearchMCAssessor} size="small">
                <Search />
              </IconButton>
            </Tooltip>
          </div>
        </Paper>
      )}
      
      <Tabs 
        value={activeTab} 
        onChange={onTabChange}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="Measure Property" />
        <Tab label="Draw Areas" />
      </Tabs>
 
              <PropertyLinesSection 
                propertyLines={polygons.filter(p => p.type === 'propertyLine')}
              />

      
      {activeTab === 0 && (
        <div className={styles.tabContent}>
          <Typography variant="subtitle2" gutterBottom>
            Set the geofence radius for this property
          </Typography>
          
          <TextField
            type="number"
            label="Geofence Radius (meters)"
            value={geofenceRadius}
            onChange={onUpdateGeofenceRadius}
            variant="outlined"
            size="small"
            fullWidth
            margin="normal"
            helperText="Drag the circle on the map or enter a value here"
          />
          
          <div className={styles.saveGeofenceArea}>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={onSaveGeofenceArea}
              startIcon={<SaveAlt />}
              fullWidth
            >
              Save Geofence Area
            </Button>
          </div>
        </div>
      )}
      
      {activeTab === 1 && (
        <div className={styles.tabContent}>
          {/* <Button 
            variant="contained" 
            color="primary" 
            onClick={onStartDrawing}
            startIcon={<Add />}
            fullWidth
          >
            Create New Area
          </Button> */}
          
          {polygons.filter(p => p.type !== 'propertyLine').length > 0 && (
            <Paper elevation={1} style={{ padding: '8px', margin: '16px 0', backgroundColor: '#accacd' }}>
          
              <Typography variant="subtitle2">Areas Summary</Typography>
              <Button
                  variant="contained"
                  color="primary"
                  onClick={onMergeAreas}
                  disabled={polygons.filter(p => p.type !== 'propertyLine').length < 2}
                  startIcon={<MergeIcon />}
                >
                  Merge Areas
                </Button>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2">Total Area:</Typography>
                <Typography variant="body2">{totalArea.toFixed(2)} sq ft</Typography>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2">Net Area:</Typography>
                <Typography variant="body2">{netArea.toFixed(2)} sq ft</Typography>
              </div>
            </Paper>
          )}
                     
          <div className={styles.areaItemContainer}>
            {/* Render non-property line polygons */}
            {polygons
              .filter(item => item.type !== 'propertyLine')
              .map((item, index) => {
                // Find the actual index in the main polygons array
                const mainIndex = polygons.findIndex(p => p.id === item.id);
                
                const areaFeet = calculateAreaInFeet(item.paths);
                const itemColorBlock = {
                  height: 30,
                  width: 30,
                  borderRadius: 5,
                  backgroundColor: item.fillColor,
                  border: `2px solid ${item.strokeColor}`
                };
                
                const isExclusionArea = item?.type === 'subtraction';
                
                const itemStyles = `${styles.sidebarItemContainer} ${
                  mainIndex === selectedPolygonIndex ? styles.selectedSidebarItem : ''
                } ${isExclusionArea ? styles.exclusionArea : ''}`;
                
                return (
                  <div key={item.id || index} className={itemStyles} onClick={() => handleClick(mainIndex)}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'hidden' }}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography 
                          variant="subtitle2" 
                          style={{ 
                            textOverflow: 'ellipsis', 
                            overflow: 'hidden', 
                            whiteSpace: 'nowrap',
                            maxWidth: '70%'
                          }}
                        >
                          {item.name}
                        </Typography>
                        <div style={itemColorBlock}></div>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="body2" style={{ color: '#666' }}>
                          {getPolygonTypeLabel(item.polygonType)}
                        </Typography>
                        <Typography variant="body2">
                          {areaFeet.toFixed(2)} SF
                        </Typography>
                      </div>
                    </div>
                    
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<Visibility />}
                          checkedIcon={<VisibilityOff />}
                          checked={item.isHidden}
                          onChange={(e) => handleToggleVisibility(e, mainIndex)}
                          onClick={(e) => e.stopPropagation()}
                        />
                      }
                      label=""
                      className={styles.visibilityToggle}
                    />
                  </div>
                );
              })
            }
            
          </div>
          
          { activeTab === 1 && polygons?.length > 0 && (
            <div className={styles.saveArea}>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={onSaveAreas}
                startIcon={<SaveAlt />}
                fullWidth
                // style={{ width: '100%' }}
              >
                Save Areas
              </Button>
            </div>
          )}
        </div>
      )}

    </div>
  );
});

// Set display name for debugging
Sidebar.displayName = 'Sidebar';

export default Sidebar;
