import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
    fontWeight: 500
  },
  paper: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: 8
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
  },
  helpText: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
    marginTop: theme.spacing(0.5),
  },
  questionText: {
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  }
}));

// Get current season based on date
function getCurrentSeason() {
  const month = new Date().getMonth();
  if (month >= 2 && month <= 4) return 'spring';
  if (month >= 5 && month <= 7) return 'summer';
  if (month >= 8 && month <= 10) return 'fall';
  return 'winter';
}

const Questions = (props) => {
  const classes = useStyles();
  const [formState, setFormState] = useState({
    soilType: 'loamy',
    season: getCurrentSeason(),
    showAdvancedOptions: false
  });
  
  // Only load questions on calculator change
  const questionsToAsk = props.calculator ? questionOptions[props.calculator] || [] : [];

  // Only update parent when needed and with minimized structure
  const sendUpdateToParent = (name, value) => {
    // Create a copy of current formState
    const updatedAnswers = { ...formState };
    updatedAnswers[name] = value;
    
    // Update local state
    setFormState(updatedAnswers);
    
    // Transform into the format parent component expects
    const dataForParent = {
      totalQuestions: questionsToAsk.length,
      savedCalculations: {}
    };
    
    // Add each question answer to savedCalculations
    questionsToAsk.forEach(q => {
      if (updatedAnswers[q.name]) {
        dataForParent.savedCalculations[q.name] = {
          question: q.question,
          value: updatedAnswers[q.name],
          name: q.name
        };
      }
    });
    
    // Add soil type and season
    dataForParent.savedCalculations.soilType = {
      value: updatedAnswers.soilType, 
      name: 'soilType'
    };
    
    dataForParent.savedCalculations.season = {
      value: updatedAnswers.season, 
      name: 'season'
    };
    
    // Send to parent
    props.updateParent(dataForParent);
  };

  // Handle soil type change
  const handleSoilTypeChange = (event) => {
    const newSoilType = event.target.value;
    setFormState(prev => ({ ...prev, soilType: newSoilType }));
    sendUpdateToParent('soilType', newSoilType);
  };

  // Handle season change
  const handleSeasonChange = (event) => {
    const newSeason = event.target.value;
    setFormState(prev => ({ ...prev, season: newSeason }));
    sendUpdateToParent('season', newSeason);
  };

  // Toggle advanced options
  const handleToggleAdvanced = () => {
    setFormState(prev => ({ 
      ...prev, 
      showAdvancedOptions: !prev.showAdvancedOptions 
    }));
  };

  // Handle direct input changes (text, number, select)
  const handleInputChange = (question, event) => {
    const value = event.target.value;
    sendUpdateToParent(question.name, value);
  };

  // Render a single question
  const renderQuestion = (question) => {
    // For boolean/yes-no questions
    if (question.type === 'boolean') {
      return (
        <div key={question.name}>
          <Typography variant="subtitle1" className={classes.questionText}>
            {question.question}
          </Typography>
          <FormControl component="fieldset" className={classes.formControl}>
            <RadioGroup
              row
              aria-label={question.name}
              name={question.name}
              value={formState[question.name] || ''}
              onChange={(e) => handleInputChange(question, e)}
            >
              <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
              <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
            </RadioGroup>
          </FormControl>
          {question.helperText && (
            <Typography variant="body2" className={classes.helpText}>
              {question.helperText}
            </Typography>
          )}
        </div>
      );
    }
    
    // For select/dropdown questions
    if (question.type === 'select' && question.options && question.options.length) {
      return (
        <div key={question.name}>
          <Typography variant="subtitle1" className={classes.questionText}>
            {question.question}
          </Typography>
          <FormControl className={classes.formControl} fullWidth>
            <TextField
              select
              label={question.question}
              value={formState[question.name] || question.defaultValue || ''}
              onChange={(e) => handleInputChange(question, e)}
              variant="outlined"
              fullWidth
            >
              {question.options.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
          {question.helperText && (
            <Typography variant="body2" className={classes.helpText}>
              {question.helperText}
            </Typography>
          )}
        </div>
      );
    }
    
    // Default to text/number input
    return (
      <div key={question.name}>
        <Typography variant="subtitle1" className={classes.questionText}>
          {question.question}
        </Typography>
        <FormControl className={classes.formControl} fullWidth>
          <TextField
            label={question.question}
            value={formState[question.name] || question.defaultValue || ''}
            onChange={(e) => handleInputChange(question, e)}
            variant="outlined"
            type={question.type === 'number' ? 'number' : 'text'}
            inputProps={{ min: 0 }}
            fullWidth
          />
        </FormControl>
        {question.helperText && (
          <Typography variant="body2" className={classes.helpText}>
            {question.helperText}
          </Typography>
        )}
      </div>
    );
  };

  // If no calculator selected, show a placeholder
  if (!props.calculator || !props.calculator.length) {
    return (
      <Paper className={classes.paper}>
        <Typography variant="body1" align="center">
          Please select a calculation type to continue
        </Typography>
      </Paper>
    );
  }

  return (
    <>
      <Paper className={classes.paper}>
        <Typography variant="h6" className={classes.sectionTitle}>
          {props.calculator} Calculation
        </Typography>
        
        <Divider className={classes.divider} />
        
        {/* Required Questions */}
        <Grid container spacing={2}>
          {questionsToAsk.map((item) => (
            <Grid item xs={12} key={item.name}>
              {renderQuestion(item)}
            </Grid>
          ))}
        </Grid>
        
        {/* Advanced Options Toggle */}
        {(props.calculator === 'Plant Material Labor' || props.calculator === 'Irrigation') && (
          <>
            <Divider className={classes.divider} />
            <FormControlLabel
              control={
                <Switch
                  checked={formState.showAdvancedOptions}
                  onChange={handleToggleAdvanced}
                  color="primary"
                />
              }
              label="Show Advanced Options"
            />
            
            {/* Advanced Options */}
            {formState.showAdvancedOptions && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth className={classes.inputField}>
                    <InputLabel id="soil-type-label">Soil Type</InputLabel>
                    <Select
                      labelId="soil-type-label"
                      id="soil-type"
                      value={formState.soilType}
                      onChange={handleSoilTypeChange}
                      label="Soil Type"
                    >
                      <MenuItem value="sandy">Sandy Soil (0.9x labor)</MenuItem>
                      <MenuItem value="loamy">Loamy Soil (Standard)</MenuItem>
                      <MenuItem value="clay">Clay Soil (1.3x labor)</MenuItem>
                      <MenuItem value="rocky">Rocky Soil (1.5x labor)</MenuItem>
                    </Select>
                    <Typography className={classes.helpText}>
                      Soil type affects planting labor requirements
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth className={classes.inputField}>
                    <InputLabel id="season-label">Installation Season</InputLabel>
                    <Select
                      labelId="season-label"
                      id="season"
                      value={formState.season}
                      onChange={handleSeasonChange}
                      label="Installation Season"
                    >
                      <MenuItem value="spring">Spring (Standard)</MenuItem>
                      <MenuItem value="summer">Summer (1.1x labor)</MenuItem>
                      <MenuItem value="fall">Fall (Standard)</MenuItem>
                      <MenuItem value="winter">Winter (1.2x labor)</MenuItem>
                    </Select>
                    <Typography className={classes.helpText}>
                      Season affects working conditions and labor requirements
                    </Typography>
                  </FormControl>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Paper>
    </>
  );
};

// Question definitions for each calculator type
const questionOptions = {
  'Plant Material Labor': [
    {
      question: 'Have You Entered ALL Plant Material?',
      value: null,
      name: 'done',
      type: 'boolean',
      helperText: 'We need to know what trees/plants/cacti we are planting to calculate labor requirements.'
    }
  ],
  'Gravel/Rip-Rap': [
    {
      question: 'How Deep?',
      name: 'depth',
      value: null,
      type: 'number',
      defaultValue: null,
      helperText: 'We need the depth to determine the tonnage (in inches). Typically we do 2 inches deep.'
    },
    {
      question: 'What Size?',
      value: null,
      name: 'size',
      type: 'select',
      defaultValue: null,
      helperText: 'What size of gravel/rip-rap are we using?',
      options: [`1/4"`, `1/2"`, `3/4"`, `1"`, `Rip-Rap`]
    },
    {
      question: 'How Many Square Feet?',
      value: null,
      name: 'sf',
      type: 'number',
      defaultValue: '',
      helperText: 'How many square feet do you need to cover?'
    }
  ],
  'Irrigation': [
    {
      question: 'Have You Entered ALL Plant Material?',
      value: null,
      name: 'done',
      type: 'boolean',
      helperText: 'We need to know what trees/plants/cacti we are planting to calculate irrigation requirements.'
    },
    {
      question: 'Square Footage of Sod?',
      value: null,
      name: 'sod',
      type: 'number',
      defaultValue: '0',
      helperText: 'We need to know if we have any sod to calculate irrigation requirements.'
    }
  ],
  'Lighting': [
    {
      question: 'How Many Lights?',
      value: null,
      name: 'qty',
      type: 'number',
      defaultValue: '',
      helperText: 'We need the total number of lights'
    }
  ],
  'Boulders': [
    {
      question: 'How Many Tons?',
      value: null,
      name: 'tons',
      type: 'number',
      defaultValue: '',
      helperText: 'How many tons of boulders will be installed?'
    }
  ],
  'Firepit': [
    {
      question: 'Size of Fire Pit?',
      value: null,
      name: 'size',
      type: 'select',
      defaultValue: '36"',
      helperText: 'What size fire pit are we building?',
      options: ['36"', '42"', '48"', '60"', 'Custom']
    },
    {
      question: 'Material Type',
      value: null,
      name: 'material',
      type: 'select',
      defaultValue: 'Natural Stone',
      helperText: 'What material will the fire pit be made from?',
      options: ['Natural Stone', 'Concrete Block', 'Brick', 'Metal']
    }
  ],
  'BBQ': [
    {
      question: 'Size of BBQ Area',
      value: null,
      name: 'size',
      type: 'number',
      defaultValue: '',
      helperText: 'What is the size of the BBQ area in square feet?'
    },
    {
      question: 'Material Type',
      value: null,
      name: 'material',
      type: 'select',
      defaultValue: 'Concrete Block',
      helperText: 'What material will the BBQ be made from?',
      options: ['Natural Stone', 'Concrete Block', 'Brick', 'Stucco Finish']
    }
  ],
  'Pool/Spa': [
    {
      question: 'Type',
      value: null,
      name: 'type',
      type: 'select',
      defaultValue: 'Pool',
      helperText: 'Are we building a pool or spa?',
      options: ['Pool', 'Spa', 'Pool & Spa Combo']
    },
    {
      question: 'Size in Square Feet',
      value: null,
      name: 'sf',
      type: 'number',
      defaultValue: '',
      helperText: 'What is the size of the pool/spa in square feet?'
    }
  ],
  'Spray': [
    {
      question: 'Gallons of Water?',
      value: null,
      name: 'gallonsOfWater',
      type: 'number',
      defaultValue: '',
      helperText: 'How many gallons of water are in the tank?'
    },
    {
      question: 'Square Footage?',
      value: null,
      name: 'sf',
      type: 'number',
      defaultValue: '',
      helperText: 'How large is the area of coverage?'
    },
    {
      question: 'Chemical Ratio Percentage?',
      value: null,
      name: 'ratio',
      type: 'number',
      defaultValue: '',
      helperText: 'Amount of Chemical to Water Ratio (1:10 = 10)'
    }
  ],
};

export default Questions;