import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import CloseIcon from '@material-ui/icons/Close'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
// import AddCustomerPaymentMethods from './AddCustomerPaymentMethods'
// import AddCustomerEcheck from './AddCustomerEcheck'
import ServiceDetailList from './ServiceDetailList'
import InvoiceOptionsModal from './InvoiceOptionsModal'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import VariableBilling from './VariableBilling'
import Radio from '@material-ui/core/Radio';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { isEven } from '../../services/common'
import SelectedMtcTasks from '../MtcRoutes/SelectedMtcTasks'
import VariableBillingOptions from './VariableBillingOptions'

// import Autocomplete from '@material-ui/lab/Autocomplete';
// import DialogContentText from '@material-ui/core/DialogContentText';

// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '10px',
    // margin: '5px'
  }
}));

const frequencyOptions = [
  {
    name: 'Weekly',
    num: 1,
    value: 'weekly'
  },
  {
    name: 'Bi-Weekly',
    num: 2,
    value: 'biweekly'
  },
  {
    name: 'Monthly',
    num: 3,
    value: 'monthly'
  },
  {
    name: 'Variable',
    num: 3,
    value: 'variable'
  }
]

const months = [
  {
    name: 'January',
    abbr: 'Jan',
    frequency: 'Bi-Weekly'
  },
  {
    name: 'February',
    abbr: 'Feb',
    frequency: 'Bi-Weekly'
  },
  {
    name: 'March',
    abbr: 'Mar',
    frequency: 'Bi-Weekly'
  },
  {
    name: 'April',
    abbr: 'Apr',
    frequency: 'Bi-Weekly'
  },
  {
    name: 'May',
    abbr: 'May',
    frequency: 'Bi-Weekly'
  },
  {
    name: 'June',
    abbr: 'Jun',
    frequency: 'Bi-Weekly'
  },
  {
    name: 'July',
    abbr: 'Jul',
    frequency: 'Bi-Weekly'
  },
  {
    name: 'August',
    abbr: 'Aug',
    frequency: 'Bi-Weekly'
  },
  {
    name: 'September',
    abbr: 'Sep',
    frequency: 'Bi-Weekly'
  },
  {
    name: 'October',
    abbr: 'Oct',
    frequency: 'Bi-Weekly'
  },
  {
    name: 'November',
    abbr: 'Nov',
    frequency: 'Bi-Weekly'
  },
  {
    name: 'December',
    abbr: 'Dec',
    frequency: 'Bi-Weekly'
  }
]


const daysOfTheWeek = [
  {
    name: 'No Preference',
    num: 0
  },
  {
    name: 'Sunday',
    num: 1
  },
  {
    name: 'Monday',
    num: 2
  },
  {
    name: 'Tuesday',
    num: 3
  },
  {
    name: 'Wednesday',
    num: 4
  },
  {
    name: 'Thursday',
    num: 5
  },
  {
    name: 'Friday',
    num: 6
  },
  {
    name: 'Saturday',
    num: 7
  }
]
const servicePlans = [
  {
    name: 'Basic Service Plan',
    num: 1,
    value: 'BSP'
  },
  {
    name: 'Advantage Service Plan',
    num: 2,
    value: 'ASP'
  },
  // {
  //   name: 'Super Service Plan',
  //   num: 3,
  //   value: 'SSP'
  // }
]
const scheduleOptions = [
  {
    name: 'None',
    value: 0
  },
  {
    name: 'Monthly',
    value: 1
  },
  {
    name: 'Quarterly',
    value: 2
  },
  {
    name: 'Bi-Annually',
    value: 3
  },
  {
    name: 'Annually',
    value: 4
  }
]

let weekOfTheYear = moment(new Date()).week()
// console.log('Week of the year: ', weekOfTheYear)

const defaultStateValues = {
  notes: '',
  siteNotes: '',
  frequencyOptions: frequencyOptions,
  daysOfTheWeek: daysOfTheWeek,
  preferredDay: {name: 'No Preference', num: 0},
  jobSiteAddress: '',
  jobSites: [],
  servicePlan: {name: 'Standard Service Plan', num: 1, value: 'BSP'},
  servicePlans: [],
  monthlyPrice: 270,
  estMinutes: 60,
  jobSite: {},
  startDate: new Date(),
  variableBillingOptions: [{
    name: 'No'
  }, {name: 'Yes'}],
  variableBilling: {
    name: 'No'
  },
  firstMonthPrice: 0,
  savedVariableBilling: false,
  waterFeatureSchedule: 0,
  irrigationCheckSchedule: 0,
  snoutWeevil: false,
  fertilizations: false,
  lightingCheckSchedule: 0,
  crewList: [ {name: 'Noneya'}],
  assignedCrew: { name: 'None' },
  evenOrOddWeeks: 'All',
  techNotes: '',
  enterTime: null,
  exitTime: null,
  variableOptions: {
    isVariable: false,
    weeklyPrice: 0,
    biWeeklyPrice: 0,
    weeklyMins: 0,
    biWeeklyMins: 0,
    variableDetails: months
  },
  requiresApptForTechWork: false
}




const EditMtcAgreement = (props) => {
  // console.log('EditMtcAgreement Mtc Agreement: ', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [jobName, setJobName] = React.useState('')
  const [list, setList] = React.useState([])
  const [isCurrent, setIsCurrent] = React.useState(false)
  const [gasSurcharge, setGasSurcharge] = React.useState(false)
  const [requiresApptForTechWork, setRequiresApptForTechWork] = React.useState(false)
  const [environmentalSurcharge, setEnvironmentalSurcharge] = React.useState(false)
  const [selectedTasks, setSelectedTasks] = React.useState([])
  const [state, setState] = React.useState(defaultStateValues)  
  
  React.useEffect(() => {
    let isMounted = false

    const fetchDefaultList = async () => {
      const response = await axios.get(`${props.url}/api/settings/getDefaultMtcChecklistItems`)
      // console.log('Response', response.data)
      setList(response.data)
    }
    fetchDefaultList()
    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    if (props.contract) {
      // console.log('edit contract... USE EFFECT::', props.contract);

      const {
        snoutWeevil = false,
        fertilizations = false,
        irrigationChecks = 0,
        waterFeatureChecks = 0,
        lightingChecks = 0,
        jobName = '',
        jobSite: jobSiteId,
        selectedTasks: contractSelectedTasks = [],
        firstMonthPrice = 0,
        estMinutes = null,
        paymentAmount: monthlyPrice = null,
        servicePlan: servicePlanValue,
        firstPaymentDate = null,
        variableBilling,
        crew: crewId,
        preferredDay,
        preferredDayNum,
        enterTime = null,
        exitTime = null,
        frequency: frequencyValue,
        isCurrent,
        gasSurcharge,
        environmentalSurcharge,
        variableOptions: contractVariableOptions,
        weeklyPrice = 0,
        biWeeklyPrice = 0,
        weeklyMins,
        biWeeklyMins,
        notes,
        siteNotes,
        evenOrOddWeeks,
        techNotes,
        requiresApptForTechWork
      } = props.contract;
      
      const jobSite = props.jobSites.find(site => site._id === jobSiteId) || {};
      const assignedCrew = props.crewList.find(crew => crew._id === crewId) || false;
      const preferredDayBasedonNum = daysOfTheWeek.find(day => day.num === preferredDayNum) || daysOfTheWeek[0];
      const servicePlan = servicePlans.find(plan => plan.value === servicePlanValue) || servicePlans[0];
      const frequency = frequencyOptions.find(opt => opt.value === frequencyValue) || null;
      const variableBillingOption = variableBilling ? state.variableBillingOptions[1] : state.variableBillingOptions[0];
      // console.log('Day by num', preferredDayBasedonNum)
      // console.log(preferredDay)
      if (preferredDay !== preferredDayBasedonNum.name) {
        // console.log('Days dont match!!!')
      }
      if (contractSelectedTasks?.length) {
        setSelectedTasks(contractSelectedTasks);
        const newList = [...list];
        contractSelectedTasks.forEach(task => {
          if (!newList.find(listItem => listItem._id.toString() === task._id.toString())) {
            newList.push(task);
          }
        });
        setList(newList);
      }

      const varOpts = {
        isVariable: contractVariableOptions && contractVariableOptions.length > 0,
        weeklyPrice,
        biWeeklyPrice,
        weeklyMins,
        biWeeklyMins,
        variableDetails: contractVariableOptions || months
      };

      const newState = {
        jobSite,
        frequency,
        firstPaymentDate,
        firstMonthPrice,
        monthlyPrice,
        preferredDay: { name: preferredDay },
        servicePlan,
        estMinutes,
        startDate: firstPaymentDate,
        variableBilling: variableBillingOption,
        fertilizations,
        snoutWeevil,
        notes,
        siteNotes,
        waterFeatureSchedule: waterFeatureChecks,
        irrigationCheckSchedule: irrigationChecks,
        lightingCheckSchedule: lightingChecks,
        jobSites: props.jobSites,
        assignedCrew,
        crewList: props.crewList,
        evenOrOddWeeks,
        techNotes,
        enterTime,
        exitTime,
        variableOptions: varOpts,
        requiresApptForTechWork
      };

      // console.log('Set New State', newState)

      setJobName(jobName);
      setIsCurrent(!!isCurrent);
      setGasSurcharge(!!gasSurcharge);
      setRequiresApptForTechWork(!!requiresApptForTechWork)
      setEnvironmentalSurcharge(!!environmentalSurcharge);
      setState(prevState => ({ ...prevState, ...newState }));
    }
  }, [props.contract, props.jobSites, props.crewList, state.variableBillingOptions, list, months]);

  // React.useEffect(() => {
  //   // console.log('Use Effect Updte Mtc Agreement')
  //   // let jobName = 'Job - '
  //   if (props.contract) {
  //     console.log('USE EFFECT::', props.contract)
  //     let newState = {}
  //     let contract = props.contract
  //     let jobSite = {}
  //     let firstMonthPrice = 0
  //     let firstPaymentDate = null
  //     let preferredDay = daysOfTheWeek[0]
  //     let servicePlan = servicePlans[0]
  //     let estMinutes = null
  //     let monthlyPrice = null
  //     let variableBilling = state.variableBillingOptions[0]
  //     let irrigationChecks = 0
  //     let lightingChecks = 0
  //     let waterFeatureChecks = 0
  //     let fertilizations = false
  //     let snoutWeevil = false
  //     let assignedCrew = false
  //     let frequency = null
  //     let enterTime = null
  //     let exitTime = null
  //     if (contract.snoutWeevil) snoutWeevil = contract.snoutWeevil
  //     if (contract.fertilizations) fertilizations = contract.fertilizations
  //     if (contract.irrigationChecks) irrigationChecks = contract.irrigationChecks
  //     if (contract.waterFeatureChecks) waterFeatureChecks = contract.waterFeatureChecks
  //     if (contract.lightingChecks) lightingChecks = contract.lightingChecks
  //     if (contract.jobName) setJobName(contract.jobName)
  //     // console.log('Set Job Site and everything else...', contract)
  //     if (contract.jobSite) {
  //       let index = props.jobSites.findIndex(site => (site._id === contract.jobSite));
  //       jobSite = props.jobSites[index]
  //       // console.log('Job Site!!', jobSite)
  //     }
  //     // Add checklist items
  //     let contractChecklist = []
  //     if (contract && contract.selectedTasks && contract.selectedTasks.length) {
  //       setSelectedTasks(contract.selectedTasks)
  //       if (list && list.length) {
  //         // Add our One Off items to the default list
  //         let newList = [...list]
  //         contract.selectedTasks.map(task => {
  //           let index = newList.findIndex(listitem => (listitem._id.toString() === task._id.toString()))
  //           if (index === -1) newList.push(task)
  //         })
  //         // console.log('Update our list to this:', newList)
  //         setList(newList)
  //       }
  //     }
  //     if (contract.firstMonthPrice) firstMonthPrice = contract.firstMonthPrice
  //     if (contract.estMinutes) estMinutes = contract.estMinutes
  //     if (contract.paymentAmount) monthlyPrice = contract.paymentAmount
  //     if (contract.servicePlan) {
  //       let index = servicePlans.findIndex(plan => (plan.value === contract.servicePlan));
  //       servicePlan = servicePlans[index]
  //     }
  //     if (contract.firstPaymentDate) firstPaymentDate = contract.firstPaymentDate
  //     if (contract.variableBilling) variableBilling = state.variableBillingOptions[1]
  //     if (contract.crew) {
  //       let index = props.crewList.findIndex(crew => (crew._id === contract.crew));
  //       assignedCrew = props.crewList[index]
  //     }
  //     if (contract.preferredDay) {
  //       let index = daysOfTheWeek.findIndex(day => (day.num === contract.preferredDayNum));
  //       preferredDay = daysOfTheWeek[index]
  //     }
  //     if (contract.enterTime) enterTime = contract.enterTime
  //     if (contract.exitTime) exitTime = contract.exitTime
  //     if (contract.frequency) {
  //       let index = frequencyOptions.findIndex(day => (day.value === contract.frequency));
  //       frequency = frequencyOptions[index]
  //     }
  //     if (contract.isCurrent) setIsCurrent(true)
  //     if (contract.gasSurcharge) setGasSurcharge(true)
  //     if (contract.environmentalSurcharge) setEnvironmentalSurcharge(true)
  //     let varOpts = {
  //       isVariable: false,
  //       weeklyPrice: 0,
  //       biWeeklyPrice: 0,
  //       variableDetails: months
  //     }
  //     if (contract && contract.frequency === 'variable') {
  //       // console.log('Set the damn variable options:', contract)
  //       const variableOptions = contract.variableOptions
  //       // console.log(variableOptions)
  //       varOpts.weeklyPrice = contract.weeklyPrice
  //       varOpts.biWeeklyPrice = contract.biWeeklyPrice
  //       varOpts.variableDetails = contract.variableOptions
  //       varOpts.isVariable = true
  //     }
  //     newState = {
  //       ...state,
  //       jobSite: jobSite,
  //       frequency: frequency,
  //       firstPaymentDate: firstPaymentDate,
  //       firstMonthPrice: firstMonthPrice,
  //       monthlyPrice: monthlyPrice,
  //       preferredDay: preferredDay,
  //       servicePlan: servicePlan,
  //       estMinutes: estMinutes,
  //       startDate: firstPaymentDate,
  //       variableBilling: variableBilling,
  //       fertilizations: fertilizations,
  //       snoutWeevil: snoutWeevil,
  //       notes: contract.notes,
  //       siteNotes: contract.siteNotes,
  //       waterFeatureSchedule: waterFeatureChecks,
  //       irrigationCheckSchedule: irrigationChecks,
  //       lightingCheckSchedule: lightingChecks,
  //       jobSites: props.jobSites,
  //       assignedCrew: assignedCrew,
  //       crewList: props.crewList,
  //       evenOrOddWeeks: contract.evenOrOddWeeks,
  //       techNotes: contract.techNotes,
  //       enterTime: enterTime,
  //       exitTime: exitTime,
  //       variableOptions: varOpts
  //     }
  //     console.log('New State to set', newState)
  //     console.log('CrewList:', props.crewList)
  //     console.log('Assigned crew', assignedCrew)
  //     console.log('Jobsites...', props.jobSites)
  //     console.log('Jobsite:', jobSite)
  //     setState(prevState => ({...prevState, ...newState}))
  //   }
  // }, [props])

const handleClose = () => {
  // console.log('Closing New Note Modal')
  // setOpen(false)
  // setState({
  //   note: ''
  // })
  props.updateParent('Close')
}

const handleConfirm = (data) => {
  // console.log('CONFIRMED UPDATE MTC AGREEMENT!!', data)
}
const handleSave = () => {
  setLoading(true)
  // console.log('handle save', state)
  // console.log(props)
  let obj = {
    client: props.client,
    details: state,
    jobName: jobName,
    user: props.user,
    props: props,
    isCurrent: isCurrent,
    gasSurcharge,
    environmentalSurcharge,
    selectedTasks,
    variableOptions: state.variableOptions,
    requiresApptForTechWork
  }
  // console.log('Save this', obj)
  axios({
    method: 'post',
    url:  `${props.url}/api/jobs/updateMtcAgreement`,
    data: obj,
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    // console.log('Axios response to create new Note: ', response)
    setLoading(false)
    // setState({})
    // setJobNumber(response.data)
    props.updateParent('Saved', response.data)
    if (response.data._id) {
      props.notification({
        type: 'success',
        title: 'Maintenance Agreement Has Been Updated!',
        message: 'Well Done!!'
      })
      // setOpen(false)
    } else {
      // console.log('No fucking workorder')
      props.notification({
        type: 'warning',
        title: 'Uh Oh!',
        message: 'Something messed up creating this workorder...not sure why!'
      })
    }
    
  }).catch(err => {
    // console.log('Error saving workorder: ', err)
  })
}
const formatOption = (opt) => {
  // console.log('Format this: ', opt)
  if (opt && opt.address) {
    return `${opt.name} - ${opt.address} ${opt.city}`
  } else {
    return ''
  }
}
const updateAutoComplete = (id, value) => {
  // console.log('Update our autocomplete: ', id)
  // console.log(value)
  let split = id.split('-')
  // console.log('Split: ', split)
  let optNum = split[2]
  let optItem = split[0]
  // console.log('Update: ', optItem)
  // console.log('To number: ', optNum)
  let newItem = null
  if (optItem === 'preferredDay') {
    newItem = daysOfTheWeek[optNum]
    // console.log('Set PReferred day to ', newItem)
    setState({...state, preferredDay: newItem})
  }
  if (optItem === 'jobSite') {
    newItem = state.jobSites[optNum]
    // console.log('New job site: ', newItem)
    setState({...state, jobSite: newItem})
  }
  if (optItem === 'frequency') {
    newItem = frequencyOptions[optNum]
    setState({...state, frequency: newItem})
  }
  if (optItem === 'servicePlan') {
    newItem = servicePlans[optNum]
    setState({...state, servicePlan: newItem})
  }
  if (optItem === 'variableBilling') {
    newItem = state.variableBillingOptions[optNum]
    setState({...state, variableBilling: newItem})
  }
  if (optItem === 'assignedCrew') {
    newItem = state.crewList[optNum]
    // console.log('New Crew:', newItem)
    setState({...state, assignedCrew: newItem})
  }
  // state[optItem] = newItem
  console.log('Item to update: to: ', newItem)
}

const updateMonthlyPrice = (id, value) => {
  // console.log('Update our Monthly Price', id)
  // console.log(value)
  setState({...state, monthlyPrice: value})
  // ,
  //   janPrice: value,
  //   febPrice: value,
  //   marchPrice: value,
  //   aprilPrice: value,
  //   mayPrice: value,
  //   junePrice: value,
  //   julyPrice: value,
  //   augPrice: value,
  //   septPrice: value,
  //   octPrice: value,
  //   novPrice: value,
  //   decPrice: value
  // })
}

const updateJobSiteAddress = (e, addy) => {
  // console.log(e)
  // console.log('addy: ', addy)
  setState({...state, jobSite: addy})
}

const saveVariableBilling = (data) => {
  // console.log('Save our variable billing!!', data)
  setState({...state, savedVariableBilling: true, variablePricing: data })
}

const handleChangeWeek = (e) => {
// console.log('Change Week')
  setState({...state, evenOrOddWeeks: e.target.value })
}
const handleChangeCrew = (e, newVal) => {
  // console.log('Change crew;', e)
  // console.log(newVal)
  setState({...state, assignedCrew: newVal })
}
const handleTaskSelections = (updatedSelections) => {
  // console.log('Selected:', updatedSelections)
  setSelectedTasks(updatedSelections)

}

const handleAddNewTaskItem = (newItem) => {
  // console.log('Add this', newItem)
  let newList = [...list, newItem]
  // console.log('New list', newList)
  setList(newList)
  setSelectedTasks([...selectedTasks, newItem])
}

const handleChange = (e) => {
  setState({...state, [e.target.id]: e.target.value})
}

let evenWeek = isEven(weekOfTheYear)
let weekType = evenWeek ? 'Even' : 'Odd'

return (
  <div className={classes.root}>
    <Dialog open={props.open} fullWidth={true} onClose={handleClose} maxWidth='sm' aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Modify Maintenance Agreement</DialogTitle>
        <DialogContent >
        <IconButton onClick={handleClose} style={{ 'position': 'absolute', 'top': '15px', 'right': '30px' }} edge='end' alt="Close" aria-label="Close">
          <CloseIcon />
        </IconButton>
          <DialogContentText>
            Please Modify The Necesarry Fields Below
          </DialogContentText>
          <Grid container>
          <Grid item lg={12} xs={12} className={classes.gridItem}>
          <FormControlLabel
              control={
                <Switch
                  checked={isCurrent}
                  onChange={() => setIsCurrent(!isCurrent)}
                  name="Active"
                  color="primary"
                />
              }
              label={isCurrent ? 'Active' : 'Inactive'}
            />
            </Grid>
          <Grid item lg={12} xs={12} className={classes.gridItem}>
              <TextField
                id="name"
                label="Job Name"
                type="text"
                fullWidth
                value={jobName}
                className={classes.textField}
                variant="standard"
                onChange={ e => setJobName(e.target.value) }
                // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          <Grid item lg={12}>
            <Autocomplete
            id="jobSite"
            options={state.jobSites}
            getOptionLabel={(option) => (formatOption(option))}
            // style={{ width: 200 }}
            className={classes.paddedStuff}
            // onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
            onChange={updateJobSiteAddress}
            // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
            value = {state.jobSite}
            // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
            renderInput={(params) => <TextField {...params} label="Job Site" />}
          />

            </Grid>
            <Grid item lg={6} xs={12} className={classes.gridItem}>
              <TextField
                id="date"
                label="First Payment Date"
                type="date"
                fullWidth
                value={moment(state.startDate).format('YYYY-MM-DD') } // "2017-05-24"}
                className={classes.textField}
                variant="standard"
                onChange={ e => setState({...state, startDate: e.target.value }) }
                // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item lg={6} xs={12} className={classes.gridItem}>
            <TextField
              id="firstMonthPrice"
              label="First Month Price"
              fullWidth
              // className={classes.paddedStuff}
              // type="number"
              variant="standard"
              value= {state.firstMonthPrice}
              onChange={ e => setState({...state, firstMonthPrice:e.target.value } ) }
              // onChange={ (e) => updateMonthlyPrice( e.target.id, e.target.value) }
              />
            </Grid>
            <Grid item lg={12}>
            <Autocomplete
              id="assignedCrew"
              options={state.crewList}
              getOptionLabel={(option) => `${option?.name ? option.name : 'No-Name'} ${option.foremanName ? `(${option.foremanName})` : ''}`}
              className={classes.paddedStuff}
              // style={{ width: 200 }}
              // onChange={updateServiceType}
              onChange={handleChangeCrew}
              value = {state.assignedCrew}
              // onChange={ e => setState({...state, assignedCrew: e.target.value }) }
              renderInput={(params) => <TextField {...params} label="Assigned Crew" variant="standard" />}
            />
            </Grid>
            <Grid item lg={12}>
            <Autocomplete
              id="frequency"
              options={frequencyOptions}
              getOptionLabel={(option) => option.name}
              className={classes.paddedStuff}
              // style={{ width: 200 }}
              // onChange={updateServiceType}
              // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
              value = {state.frequency}
              onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
              // onChange={ e => setState({...state, preferredDay: e.target.value }) }
              renderInput={(params) => <TextField {...params} label="Visit Frequency" variant="standard" />}
            />
            {
              (state.frequency && state.frequency.value === 'variable') ? (
                <VariableBillingOptions state={state} setState={setState} months={months} />
              ) : null
            }
            
            {
              (state.frequency && (state.frequency.value === 'biweekly' || state.frequency.value === 'variable')) ? (
                <FormControl className={classes.paddedStuff} fullWidth>
                      <TextField
                        type="select"
                        select
                        value={state.evenOrOddWeeks}
                        onChange={handleChangeWeek}
                        label="Week Assignment"
                        // onChange={(e) => setState({...state, phone['type']: e.target.value })}
                        // className={classes.paddedStuff}
                        inputProps={{ 'aria-label': 'Odd or Even Weeks' }}
                        fullWidth
                        placeholder="Choose Which Week"
                      >
                       
                        <MenuItem value={"Odd"}>
                          Odd
                        </MenuItem>
                        <MenuItem value={"Even"}>Even</MenuItem>
                      </TextField>
                      <FormHelperText>Odd Or Even Weeks of the Year (This week is {weekType})</FormHelperText>
                    </FormControl>

              ) : ''
            }
            </Grid>
            <Grid item lg={12}>
            <Autocomplete
              id="preferredDay"
              options={daysOfTheWeek}
              getOptionLabel={(option) => option.name}
              className={classes.paddedStuff}
              // style={{ width: 200 }}
              // onChange={updateServiceType}
              // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
              value = {state.preferredDay}
              onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
              // onChange={ e => setState({...state, preferredDay: e.target.value }) }
              renderInput={(params) => <TextField {...params} label="Assigned Day Of The Week" variant="standard" />}
            />
            </Grid>
            {/* <Grid item lg={12} md={12} sm={12} xs={12} className={classes.paddedStuff}>
              Preferred Time Window
            </Grid> */}
            <Grid item lg={6} md={6} sm={6} xs={12} className={classes.paddedStuff}>
             
              <TextField
                type="time"
                variant="outlined"
                id="enterTime"
                label="Earliest Time to Arrive"
                onChange={ e => setState({...state, enterTime: e.target.value })}
                name="entertime"
                value={ state.enterTime }
                fullWidth
                // defaultValue={a}
                style={{ marginTop: 10 }}
                // onBlur={updateParent}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300
                }}
              />

            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12} className={classes.paddedStuff}>
             
            <TextField
                type="time"
                variant="outlined"
                id="exitTIme"
                label="Latest Time to Arrive"
                onChange={ e => setState({...state, exitTime: e.target.value })}
                name="exitTime"
                value={ state.exitTime }
                fullWidth
                // defaultValue={a}
                style={{ marginTop: 10 }}
                // onBlur={updateParent}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300
                }}
              />


           </Grid>
            <Grid item lg={12}>
            <Autocomplete
              id="servicePlan"
              options={servicePlans}
              getOptionLabel={(option) => option.name}
              className={classes.paddedStuff}              
              // style={{ width: 200 }}
              // onChange={updateServiceType}
              onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
              value = {state.servicePlan}
              // onChange={ e => setState({...state, servicePlan: e.target.value }) }
              renderInput={(params) => <TextField {...params} label="Service Plan" variant="standard" />}
            />
            </Grid>
            <Grid item lg={6} className={classes.paddedStuff}>
            <TextField
              label="Estimated Minutes"
              fullWidth
              id="estMinutes"
              // type="number"
              variant="standard"
              value= {state.estMinutes}
              // onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
              onChange={ e => setState({...state, estMinutes:e.target.value } ) }
              />
            </Grid>
            <Grid item lg={6} className={classes.paddedStuff}>
            <TextField
              id="monthlyPrice"
              label="Monthly Price"
              fullWidth
              // className={classes.paddedStuff}
              // type="number"
              variant="standard"
              value= {state.monthlyPrice}
              // onChange={ e => setState({...state, monthlyPrice:e.target.value } ) }
              onChange={ (e) => updateMonthlyPrice( e.target.id, e.target.value) }
              />
            </Grid>
           
            {/* <Grid item lg={12}>
            <Autocomplete
              id="variableBilling"
              options={state.variableBillingOptions}
              getOptionLabel={(option) => option.name}
              className={classes.paddedStuff}
              
              // style={{ width: 200 }}
              // onChange={updateServiceType}
              onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
              value = {state.variableBilling}
              // onChange={ e => setState({...state, variableBilling: e.target.value }) }
              renderInput={(params) => <TextField {...params} label="Variable Billing" variant="standard" />}
            />
            </Grid> */}
            {/* <Grid item lg={12}>
              { state.variableBilling.name === 'Yes' ? (
          
                <VariableBilling client={props.client} contract={props.contract} saveVariableBilling={saveVariableBilling} monthlyFee={state.monthlyPrice} stuff={state} />

              ) : '' }
            </Grid> */}
            <Grid item lg={12} xs={12} className={classes.gridItem}>
              <TextField
              id="notes"
              label="Notes"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              // className={classes.textSaveLineBreaks}
              value={state.notes}
              onChange={e => setState({...state, notes: e.target.value })}
              // onChange={ e => handleServiceChange(state.workorder, 'serviceDescription', e.target.value, state.serviceIndex) }
              // defaultValue="Default Value"
            />
            </Grid>
            <Grid item lg={12} xs={12} className={classes.gridItem}>
              <TextField
              id="sitenotes"
              label="Site Notes"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              // className={classes.textSaveLineBreaks}
              value={state.siteNotes}
              onChange={e => setState({...state, siteNotes: e.target.value })}
              // onChange={ e => handleServiceChange(state.workorder, 'serviceDescription', e.target.value, state.serviceIndex) }
              // defaultValue="Default Value"
            />
            </Grid>
            <Grid item lg={12} xs={12} className={classes.gridItem}>
              <TextField
              id="technotes"
              label="Notes For Techs"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              // className={classes.textSaveLineBreaks}
              value={state.techNotes}
              onChange={e => setState({...state, techNotes: e.target.value })}
              // onChange={ e => handleServiceChange(state.workorder, 'serviceDescription', e.target.value, state.serviceIndex) }
              // defaultValue="Default Value"
            />
            </Grid>
            <Grid item lg={12}>
                <h3>Service Options</h3>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>

              <SelectedMtcTasks url={props.url} list={list} selected={selectedTasks} addItem={handleAddNewTaskItem} updateParent={handleTaskSelections} />
            </Grid>
            <Grid item lg={12} className={classes.paddedStuff}>
              <FormControl fullWidth className={classes.formControl}>
                  <InputLabel id="requires-appt-label">Requires Appt for Tech Work</InputLabel>
              </FormControl>
              </Grid>
              <Grid item lg={12} className={classes.paddedStuff}>
              <FormControlLabel
                control={
                  <Switch
                    checked={requiresApptForTechWork}
                    onChange={() => setRequiresApptForTechWork(!requiresApptForTechWork)}
                    name="Appt Required for Tech Work"
                    color="primary"
                  />
                }
                label={requiresApptForTechWork ? 'Requires Appointment' : 'No Appointment'}
              />
             
            </Grid>
           <Grid item lg={12} className={classes.paddedStuff}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel id="demo-simple-select-helper-label">Water Features</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={state.waterFeatureSchedule}
                  onChange={(e) => setState({...state, waterFeatureSchedule: e.target.value })}
                >
                  <MenuItem value={0}>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={5}>Weekly</MenuItem>
                  <MenuItem value={7}>Bi-Weekly</MenuItem>
                  <MenuItem value={1}>Monthly</MenuItem>
                  <MenuItem value={6}>Bi-Monthly</MenuItem>
                  <MenuItem value={2}>Quarterly</MenuItem>
                  <MenuItem value={3}>Bi-Annually</MenuItem>
                  <MenuItem value={4}>Annually</MenuItem>
                </Select>
                <FormHelperText>Water Feature Service Schedule </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item lg={12} className={classes.paddedStuff}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel id="irrigation-check">Irrigation Checks</InputLabel>
                <Select
                  labelId="irrigation-check-opetions"
                  id="irrigation-check--schedule"
                  value={state.irrigationCheckSchedule}
                  onChange={(e) => setState({...state, irrigationCheckSchedule: e.target.value })}
                >
                  <MenuItem value={0}>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={5}>Weekly</MenuItem>
                  <MenuItem value={7}>Bi-Weekly</MenuItem>
                  <MenuItem value={1}>Monthly</MenuItem>
                  <MenuItem value={6}>Bi-Monthly</MenuItem>
                  <MenuItem value={2}>Quarterly</MenuItem>
                  <MenuItem value={3}>Bi-Annually</MenuItem>
                  <MenuItem value={4}>Annually</MenuItem>
                </Select>
                <FormHelperText>Irrigation Check Schedule </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item lg={12} className={classes.paddedStuff}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel id="lighting-check">Lighting Checks</InputLabel>
                <Select
                  labelId="lighting-check-opetions"
                  id="lighting-check--schedule"
                  value={state.lightingCheckSchedule}
                  onChange={(e) => setState({...state, lightingCheckSchedule: e.target.value })}
                >
                  <MenuItem value={0}>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={5}>Weekly</MenuItem>
                  <MenuItem value={7}>Bi-Weekly</MenuItem>
                  <MenuItem value={1}>Monthly</MenuItem>
                  <MenuItem value={6}>Bi-Monthly</MenuItem>
                  <MenuItem value={2}>Quarterly</MenuItem>
                  <MenuItem value={3}>Bi-Annually</MenuItem>
                  <MenuItem value={4}>Annually</MenuItem>
                </Select>
                <FormHelperText>Lighting Check Schedule </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item lg={12} className={classes.paddedStuff}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel id="fertilizations-check">Fertilizations</InputLabel>
                <Select
                  labelId="fertilizations-check-opetions"
                  id="fertilizations-check--schedule"
                  value={state.fertilizations}
                  onChange={(e) => setState({...state, fertilizations: e.target.value })}
                >
                  <MenuItem value={false}>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={true}>Twice a Year</MenuItem>
                </Select>
                <FormHelperText>Include Fertilizaitons (2x Per Year)</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item lg={12} className={classes.paddedStuff}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel id="snoutweevil-check">Snout Weevil</InputLabel>
                <Select
                  labelId="snoutweevil-check-opetions"
                  id="snoutweevil-check--schedule"
                  value={state.snoutWeevil}
                  onChange={(e) => setState({...state, snoutWeevil: e.target.value })}
                >
                  <MenuItem value={false}>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={true}>Twice a Year</MenuItem>
                </Select>
                <FormHelperText>Include Snout Weevil (2x Per Year)</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item lg={6} className={classes.paddedStuff}>
              Gas Surcharge<br/>
              <FormControlLabel
              control={
                <Switch
                  checked={gasSurcharge}
                  onChange={() => setGasSurcharge(!gasSurcharge)}
                  name="Active"
                  color="primary"
                />
              }
              label={gasSurcharge ? 'Active' : 'No Surcharge'}
            />
            </Grid>

          </Grid>

        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => setState({...state, editJobName: false})} color="primary">
            Cancel
          </Button> */}
          <Button color="primary" disabled={loading} onClick={handleSave}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
  </div>
  )
  }


  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default React.memo(connect(mapStateToProps)(EditMtcAgreement))