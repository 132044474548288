
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { 
  List, 
  ListItem, 
  ListItemText, 
  Collapse, 
  Typography, 
  Paper,
  makeStyles
} from '@material-ui/core';
import RepairList from './RepairList';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  container: {
    padding: 15,
    margin: 15
  }
}));

// const repairsData = [
//   {
//     id: 1,
//     equipmentName: 'Truck 1 (TEST DATA) - NOT REAL',
//     date: '2023-07-17',
//     type: 'Engine',
//     description: 'Engine overhaul',
//     cost: 2500,
//     purchases: [
//       { item: 'Engine parts', quantity: 1, cost: 1500 },
//       { item: 'Oil', quantity: 5, cost: 100 },
//     ],
//     mechanicNotes: 'Replaced worn pistons and rings. Recommend more frequent oil changes.'
//   },
// ];


const RepairComponent = ({ equipmentId, url, equipmentName }) => {
  const classes = useStyles();
  const [repairData, setRepairData] = React.useState([])

  useEffect(() => {
    let isMounted = true

    if (equipmentId && isMounted) fetchRepairs(equipmentId)
    return () => {
      isMounted = false
    }
  }, equipmentId)

  const fetchRepairs = async (equipmentId) => {
    console.log('Fetch Repairs', equipmentId)
    const resp = await axios.get(`${url}/api/equipment/getEquipmentRepairs?id=${equipmentId}`)
    console.log('Got our repairs!!!', resp)
    setRepairData(resp.data)
  }

  return (
   <Paper className={classes.container}>
     <Typography variant="h6" style={{ marginTop: '16px' }}>Repair Details</Typography>
      <RepairList repairs={repairData} equipmentName={equipmentName} />
   </Paper>
  );
};

export default RepairComponent;