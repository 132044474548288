import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  makeStyles
} from '@material-ui/core';
import { Build as AddIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

const AddRepairButton = ({ equipmentId, equipmentName, onAddRepair }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [repairData, setRepairData] = useState({
    date: '',
    type: '',
    description: '',
    cost: '',
    mechanicNotes: '',
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setRepairData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onAddRepair({
      ...repairData,
      equipmentId,
      equipmentName,
    });
    handleClose();
    // Reset form
    setRepairData({
      date: '',
      type: '',
      description: '',
      cost: '',
      mechanicNotes: '',
      mileage: ''
    });
  };

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        startIcon={<AddIcon />}
        onClick={handleOpen}
      >
        Add Repair
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add New Repair for {equipmentName}</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <div className={classes.form}>
              <TextField
                name="date"
                label="Repair Date"
                type="date"
                value={repairData.date}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                required
              />
              <TextField
                name="type"
                label="Repair Type"
                select
                value={repairData.type}
                onChange={handleChange}
                required
              >
                <MenuItem value="Engine">Engine</MenuItem>
                <MenuItem value="Transmission">Transmission</MenuItem>
                <MenuItem value="Brakes">Brakes</MenuItem>
                <MenuItem value="Brakes">Battery</MenuItem>
                <MenuItem value="Brakes">Tires</MenuItem>
                <MenuItem value="Electrical">Electrical</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </TextField>
              <TextField
                name="description"
                label="Repair Description"
                multiline
                rows={2}
                value={repairData.description}
                onChange={handleChange}
                required
              />
              <TextField
                name="cost"
                label="Repair Cost (Estimate)"
                type="number"
                value={repairData.cost}
                onChange={handleChange}
                InputProps={{ startAdornment: '$' }}
                required
              />
               <TextField
                name="mileage"
                label="Current Mileage"
                value={repairData.mileage}
                onChange={handleChange}
              />
              <TextField
                name="mechanicNotes"
                label="Mechanic's Notes"
                multiline
                rows={3}
                value={repairData.mechanicNotes}
                onChange={handleChange}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Add Repair
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default AddRepairButton;