class ConvexHullGrahamScan {
  constructor() {
    this.points = [];
  }
  
  addPoint(x, y) {
    this.points.push({ x, y });
  }
  
  getHull() {
    const points = [...this.points];
    if (points.length < 3) return points;
    
    // Find the point with the lowest y-coordinate
    points.sort((a, b) => {
      if (a.y === b.y) return a.x - b.x;
      return a.y - b.y;
    });
    
    const pivot = points[0];
    
    // Sort points by polar angle with respect to pivot
    points.sort((a, b) => {
      const angleA = Math.atan2(a.y - pivot.y, a.x - pivot.x);
      const angleB = Math.atan2(b.y - pivot.y, b.x - pivot.x);
      
      if (angleA === angleB) {
        // If angles are the same, sort by distance from pivot
        const distA = Math.sqrt(Math.pow(a.x - pivot.x, 2) + Math.pow(a.y - pivot.y, 2));
        const distB = Math.sqrt(Math.pow(b.x - pivot.x, 2) + Math.pow(b.y - pivot.y, 2));
        return distA - distB;
      }
      
      return angleA - angleB;
    });
    
    // Graham scan algorithm
    const hull = [pivot, points[1]];
    
    for (let i = 2; i < points.length; i++) {
      while (hull.length > 1 && this.ccw(hull[hull.length - 2], hull[hull.length - 1], points[i]) <= 0) {
        hull.pop();
      }
      hull.push(points[i]);
    }
    
    return hull;
  }
  
  // Returns > 0 if points make a counter-clockwise turn, < 0 for clockwise, 0 for collinear
  ccw(p1, p2, p3) {
    return (p2.x - p1.x) * (p3.y - p1.y) - (p2.y - p1.y) * (p3.x - p1.x);
  }
}

export default ConvexHullGrahamScan
