import React, { useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  TextField,
  FormControl,
  IconButton,
  Grid,
  FormHelperText,
  Paper,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Chip,
  Divider
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import EventIcon from '@material-ui/icons/Event';
import RoomIcon from '@material-ui/icons/Room';
import NotesIcon from '@material-ui/icons/Notes';
import ListIcon from '@material-ui/icons/List';
import MapIcon from '@material-ui/icons/Map';
import axios from 'axios';
import { currencyFormat, getFrequencyLabelByValue } from '../../../services/common';
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: theme.spacing(1)
  },
  dialogContent: {
    padding: theme.spacing(3)
  },
  formControl: {
    marginBottom: theme.spacing(3)
  },
  areasList: {
    height: '40vh',
    overflow: 'auto',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(1)
  },
  selectedAreaChip: {
    margin: theme.spacing(0.5)
  },
  selectedAreasContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  sectionHeader: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  sectionIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main
  },
  notesField: {
    marginTop: theme.spacing(2)
  },
  mapContainer: {
    height: '40vh',
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(1),
    position: 'relative',
    overflow: 'hidden'
  },
  polygon: {
    cursor: 'pointer',
    transition: 'all 0.2s',
    '&:hover': {
      opacity: 0.7
    }
  },
  selectedPolygon: {
    opacity: 0.9,
    stroke: theme.palette.secondary.main,
    strokeWidth: 3
  },
  viewsContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(1)
  },
  noAreasMessage: {
    padding: theme.spacing(2),
    textAlign: 'center'
  },
  listHeader: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    alignItems: 'center'
  },
  listHeaderIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main
  },
  jobSiteDetailsContainer: {
    backgroundColor: '#accacd',
    padding: 10,
    marign: 5,
    borderRadius: 15
  }
}));

const ScheduleSprayModal = ({ 
  open, 
  onClose, 
  selectedJobsite, 
  onSave,
  url
}) => {
  const classes = useStyles();
  
  // Form state
  const [scheduleDate, setScheduleDate] = useState(new Date());
  // const [siteAreas, setSiteAreas] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [notes, setNotes] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  
  // Map-specific state
  const [mapLoaded, setMapLoaded] = useState(false);
  const [map, setMap] = useState(null);
  const [polygons, setPolygons] = useState([]);
  
  // Reference to track if we need to re-initialize the map
  const mapInitializedRef = React.useRef(false);
  const areasDrawnRef = React.useRef(false);
  
// Handle Google Maps loading
useEffect(() => {
  // Only load Google Maps if the modal is open
  if (!open) return;

  // Check if Google Maps API is already loaded
  if (window.google && window.google.maps) {
    setMapLoaded(true);
    return;
  }

  // Load Google Maps API if not already loaded
  if (!mapLoaded) {
    // Check if the script is already in the document
    const existingScript = document.querySelector('script[src*="maps.googleapis.com/maps/api/js"]');
    
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GMAPKEY}&libraries=drawing,geometry`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        setMapLoaded(true);
      };
      document.head.appendChild(script);
    } else {
      // If script already exists, consider the API loaded
      setMapLoaded(true);
    }
  }
}, [open, mapLoaded]);


useEffect(() => {
  if (map) {
    console.log('Map state updated');
  }
}, [map]);

useEffect(() => {
  console.log('Selected jobsite changed....', selectedJobsite)
  if (selectedJobsite) {
    console.log('Selected jobsite areas count:', selectedJobsite?.areas?.length || 0);
    console.log('Do we have polygons???', polygons.length)
    // initializeMap()
  }
}, [selectedJobsite]);
    
const handleSelectArea = useCallback((area) => {
  setSelectedAreas(prev => {
    const isAlreadySelected = prev.some(a => a._id === area._id);
    
    if (isAlreadySelected) {
      return prev.filter(a => a._id !== area._id);
    } else {
      return [...prev, area];
    }
  });
}, []);

useEffect(() => {
  if (!polygons.length) return;
  
  polygons.forEach(polygon => {
    const isSelected = selectedAreas.some(area => area._id === polygon.areaId);
    polygon.setOptions({
      strokeWeight: isSelected ? 4 : 2,
      strokeColor: isSelected ? '#4CAF50' : polygon.get('originalStrokeColor'),
      zIndex: isSelected ? 10 : 1
    });
  });
}, [selectedAreas, polygons]);


// Update polygon appearance when selections change
const updatePolygonSelection = useCallback((polygonArray, selectedAreasArray) => {
  polygonArray.forEach(polygon => {
    const isSelected = selectedAreasArray.some(area => area._id === polygon.areaId);
    polygon.setOptions({
      strokeWeight: isSelected ? 4 : 2,
      strokeColor: isSelected ? '#4CAF50' : polygon.get('originalStrokeColor'),
      zIndex: isSelected ? 10 : 1
    });
  });
}, []);

useEffect(() => {
  if (selectedJobsite?.areas?.length > 0) {
    const invalidAreas = selectedJobsite.areas.filter(area => !area.paths || area.paths.length < 3);
    if (invalidAreas.length > 0) {
      console.log('Found areas with invalid paths:', invalidAreas);
    }
  }
}, [selectedJobsite?.areas]);

// Draw areas on the map
// Draw areas on the map - simplified version
const drawAreasOnMap = useCallback((mapInstance, areas) => {
  console.log('drawAreasOnMap called with', areas.length, 'areas');
  if (!mapInstance || !window.google || !window.google.maps) {
    console.log('Map or Google Maps not loaded - cannot draw areas');
    return;
  }

  // Clear any existing polygons
  // if (polygons.length > 0) {
  //   console.log('Clearing existing polygons');
  //   polygons.forEach(poly => poly.setMap(null));
  // }
  
  const newPolygons = [];
  const bounds = new window.google.maps.LatLngBounds();
  let validPolygonsCount = 0;

  // Draw each area as a polygon
  areas.forEach(area => {
    if (!area.paths || area.paths.length < 3) {
      console.log('Skipping area with invalid paths');
      return;
    }

    const pathCoordinates = area.paths.map(point => {
      const latLng = new window.google.maps.LatLng(point.lat, point.lng);
      bounds.extend(latLng);
      return latLng;
    });

    const originalStrokeColor = area.strokeColor || '#FF0000';
    const originalFillColor = area.fillColor || '#FF0000';

    const polygon = new window.google.maps.Polygon({
      paths: pathCoordinates,
      strokeColor: originalStrokeColor,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: originalFillColor,
      fillOpacity: 0.35,
      map: mapInstance,
      areaId: area._id,
      areaName: area.name || `Area ${area.number || ''}`,
      originalStrokeColor,
      originalFillColor
    });

    polygon.addListener('click', () => {
      handleSelectArea(area);
    });

    newPolygons.push(polygon);
    validPolygonsCount++;
  });

  if (validPolygonsCount > 0 && !bounds.isEmpty()) {
    mapInstance.fitBounds(bounds);
    console.log('Map fitted to bounds of', validPolygonsCount, 'areas');
  } else {
    console.log('No valid polygons to draw');
  }

  console.log('Setting polygons state with', newPolygons.length, 'polygons');
  setPolygons(newPolygons);
  
  // Update polygon appearance based on selected areas
  updatePolygonSelection(newPolygons, selectedAreas);
}, [handleSelectArea, updatePolygonSelection]); // Include polygons for cleanup

// Simplified effect to draw polygons when the map is available
useEffect(() => {
  if (!map || !selectedJobsite?.areas?.length) return;
  
  console.log('Map ready and jobsite has areas, drawing polygons');
  
  // Clear any existing polygons
  polygons.forEach(poly => poly?.setMap(null));
  
  const newPolygons = [];
  const bounds = new window.google.maps.LatLngBounds();
  
  selectedJobsite.areas.forEach(area => {
    if (!area.paths || area.paths.length < 3) return;
    
    const pathCoordinates = area.paths.map(point => {
      const latLng = new window.google.maps.LatLng(point.lat, point.lng);
      bounds.extend(latLng);
      return latLng;
    });
    
    const originalStrokeColor = area.strokeColor || '#FF0000';
    const originalFillColor = area.fillColor || '#FF0000';
    
    const polygon = new window.google.maps.Polygon({
      paths: pathCoordinates,
      strokeColor: originalStrokeColor,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: originalFillColor,
      fillOpacity: 0.35,
      map: map,
      areaId: area._id,
      areaName: area.name || `Area ${area.number || ''}`,
      originalStrokeColor,
      originalFillColor
    });
    
    polygon.addListener('click', () => {
      handleSelectArea(area);
    });
    
    newPolygons.push(polygon);
  });
  
  if (newPolygons.length > 0 && !bounds.isEmpty()) {
    map.fitBounds(bounds);
  }
  
  setPolygons(newPolygons);
}, [map, selectedJobsite?.areas, handleSelectArea]);

useEffect(() => {
  if (open) {
    setTimeout(() => {
      const mapElement = document.getElementById('area-selection-map');
      console.log('Map element exists?', !!mapElement);
    }, 500);
  }
}, [open]);

// This effect handles cleanup when the modal closes
useEffect(() => {
  if (!open && map) {
    console.log('Modal closed, cleaning up map instance');
    // Clear polygons
    polygons.forEach(poly => poly?.setMap(null));
    setPolygons([]);
    
    // Destroy map instance (this is key)
    setMap(null);
    mapInitializedRef.current = false;
    areasDrawnRef.current = false;
  }
}, [open, map, polygons]);

// Initialize map
// This effect reinitializes the map each time the modal opens
useEffect(() => {
  if (open && mapLoaded && !map) {
    console.log('Modal opened and API loaded, creating new map');
    
    // Use setTimeout to ensure DOM is fully rendered
    setTimeout(() => {
      const mapElement = document.getElementById('area-selection-map');
      
      if (mapElement && window.google && window.google.maps) {
        console.log('Map element found, initializing map');
        
        const mapInstance = new window.google.maps.Map(mapElement, {
          zoom: 18,
          center: selectedJobsite?.location?.coordinates ? 
            { 
              lat: selectedJobsite.location.coordinates[1], 
              lng: selectedJobsite.location.coordinates[0] 
            } : 
            { lat: 33.41, lng: -111.98 },
          mapTypeId: 'satellite',
          mapTypeControl: true,
          streetViewControl: false,
          fullscreenControl: true,
          zoomControl: true
        });
        
        setMap(mapInstance);
      } else {
        console.error('Map element or Google Maps API not available');
      }
    }, 500); // Longer timeout to ensure DOM is ready
  }
}, [open, mapLoaded, map, selectedJobsite]);

// Initialize the map
// const initializeMap = useCallback(() => {
//   console.log('initializeMap called');
//   if (!window.google || !window.google.maps) {
//     console.log('Google Maps not yet loaded');
//     return;
//   }

//   const mapElement = document.getElementById('area-selection-map');
//   if (!mapElement) {
//     console.log('Map element not found in DOM');
//     return;
//   }

//   console.log('Creating new map instance');
  
//   // Create map instance
//   const mapInstance = new window.google.maps.Map(mapElement, {
//     zoom: 18,
//     center: selectedJobsite?.location?.coordinates ? 
//       { 
//         lat: selectedJobsite.location.coordinates[1], 
//         lng: selectedJobsite.location.coordinates[0] 
//       } : 
//       { lat: 33.41, lng: -111.98 },
//     mapTypeId: 'satellite',
//     mapTypeControl: true,
//     streetViewControl: false,
//     fullscreenControl: true,
//     zoomControl: true
//   });

//   // No cleanup of polygons here - let the other effects handle that
  
//   mapInitializedRef.current = true;
//   console.log('Setting map state');
//   setMap(mapInstance);
  
// }, [selectedJobsite]); // Remove polygons & drawAreasOnMap dependencies


    useEffect(() => {
      if (open && selectedJobsite) {
        console.log('clear the form.')
        setScheduleDate(new Date());
        setSelectedAreas([]);
        setNotes('');
        setErrors({});
      }
    }, [open, selectedJobsite]);


    useEffect(() => {
      return () => {
        console.log('Component unmounting');
        polygons.forEach(poly => poly?.setMap(null));
      };
    }, []);


    // useEffect(() => {
    //   return () => {
    //     console.log('Component unmounting, cleaning up resources');
    //     cleanupMapResources();
    //   };
    // }, [cleanupMapResources]);

  // Update polygon styles when selections change
  useEffect(() => {
    updatePolygonSelection(polygons, selectedAreas);
  }, [selectedAreas, polygons, updatePolygonSelection]);



  const handleRemoveArea = (areaId) => {
    setSelectedAreas(prev => prev.filter(area => area._id !== areaId));
  };

  const validateForm = () => {
    const newErrors = {};
    
    if (!scheduleDate) {
      newErrors.scheduleDate = 'Schedule date is required';
    }
    
    if (selectedAreas.length === 0) {
      newErrors.selectedAreas = 'At least one area must be selected';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const scheduleData = {
        jobsiteId: selectedJobsite._id,
        jobsiteName: selectedJobsite.name,
        contract: selectedJobsite?.contract?._id || selectedJobsite?.contract || null,
        clientId: selectedJobsite?.client,
        scheduleDate: scheduleDate,
        areas: selectedAreas.map(area => ({
          _id: area._id,
          name: area.name,
          type: area.type,
          size: area.size
        })),
        notes: notes,
        status: 'requested'
      };
      
      onSave(scheduleData);
      onClose();
    }
  };
  const siteAreas = selectedJobsite?.areas || []
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="lg" // Changed from 'md' to 'lg' to accommodate the side-by-side layout
      fullWidth
    >
      <DialogTitle disableTypography className={classes.titleContainer}>
       
          <Typography variant="h4">
            Schedule Spray Job
          </Typography>
         

        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      
      <DialogContent className={classes.dialogContent}>
        <Paper className={classes.jobSiteDetailsContainer}>
         
          <Typography variant="h5">
            {selectedJobsite?.clientName} {selectedJobsite?.name ? ` - ${selectedJobsite.name}` : '' }
          </Typography>

      
          <Typography variant="h8">
            {selectedJobsite?.address || ''} {selectedJobsite?.buildingNumber ? `Bldg #${selectedJobsite.buildingNumber}` : ''}
            </Typography>
            <Typography variant="h8"> 
            {selectedJobsite?.city ? `  ${selectedJobsite.city}` : ''},  {selectedJobsite?.state || ''} {selectedJobsite?.zip || ''}
          </Typography>
          <SiteSprayDetails site={selectedJobsite} />
        </Paper> 
    
        
          {/* Date Section */}
          <Typography variant="subtitle1" className={classes.sectionHeader}>
            <EventIcon className={classes.sectionIcon} />
            Earliest Date to Spray
          </Typography>
          
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="schedule-date"
            label="Select Date"
            value={scheduleDate}
            onChange={setScheduleDate}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            className={classes.formControl}
            fullWidth
            error={!!errors.scheduleDate}
            helperText={errors.scheduleDate}
            minDate={new Date()}
          />
       
        
        {/* Areas Section */}
        <Typography variant="subtitle1" className={classes.sectionHeader}>
          <RoomIcon className={classes.sectionIcon} />
          Select Areas to Spray
        </Typography>
        
        {errors.selectedAreas && (
          <FormHelperText error>{errors.selectedAreas}</FormHelperText>
        )}

        {selectedAreas.length > 0 && (
          <Box className={classes.selectedAreasContainer}>
            {selectedAreas.map((area) => (
              <Chip
                key={area._id}
                label={area.name || `Area ${area.number || ''}`}
                onDelete={() => handleRemoveArea(area._id)}
                className={classes.selectedAreaChip}
                color="primary"
              />
            ))}
          </Box>
        )}
        
        {loading ? (
          <Typography className={classes.noAreasMessage}>Loading areas...</Typography>
        ) : siteAreas.length > 0 ? (
          <Grid container spacing={2} className={classes.viewsContainer} direction="row">
            {/* Map View (Left Side) */}
            <Grid item xs={12} md={8} style={{ display: 'flex', flexDirection: 'column' }}>
              <Paper elevation={1} style={{ width: '100%' }}>
                <Box className={classes.listHeader}>
                  <MapIcon className={classes.listHeaderIcon} />
                  <Typography variant="subtitle2">Map View</Typography>
                </Box>
                <div id="area-selection-map" className={classes.mapContainer}>
                  {/* Map will be initialized here */}
                </div>
              </Paper>
            </Grid>
            
            {/* List View (Right Side) */}
            <Grid item xs={12} md={4} style={{ display: 'flex', flexDirection: 'column' }}>
              <Paper elevation={1}>
                <Box className={classes.listHeader}>
                  <ListIcon className={classes.listHeaderIcon} />
                  <Typography variant="subtitle2">List View</Typography>
                </Box>
                <Paper className={classes.areasList}>
                  <List dense>
                    {siteAreas.map((area) => (
                      <ListItem key={area._id} button onClick={() => handleSelectArea(area)}>
                        <Checkbox
                          edge="start"
                          checked={selectedAreas.some(a => a._id === area._id)}
                          tabIndex={-1}
                          disableRipple
                        />
                        <ListItemText 
                          primary={area.name || `Area ${area.number || ''}`} 
                          secondary={area.type || 'General Area'}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <Typography className={classes.noAreasMessage}>
            No areas available for this jobsite. Please add areas first.
          </Typography>
        )}
        
        {/* Notes Section */}
        <Typography variant="subtitle1" className={classes.sectionHeader}>
          <NotesIcon className={classes.sectionIcon} />
          Additional Notes
        </Typography>
        
        <TextField
          id="notes"
          label="Notes"
          multiline
          rows={4}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          variant="outlined"
          fullWidth
          className={classes.notesField}
          placeholder="Enter any special instructions or notes for this spray job..."
        />
      </DialogContent>
      
      <DialogActions>
        <Button onClick={onClose} color="default">
          Cancel
        </Button>
        <Button 
          onClick={handleSubmit} 
          color="primary" 
          variant="contained"
          disabled={!selectedAreas?.length}
          startIcon={<AddIcon />}
        >
          Schedule Spray Job
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const SiteSprayDetails = ({ site }) => {
  console.log('Get our spray details', site)
  let sprayFrequency = 'N/A'
  let lastSprayDate = site?.lastHerbicideApplication ? formatLastSprayDate(site.lastHerbicideApplication) :  'N/A'
  let herbicideFeeType = site?.herbicideFeeType ? getFeeTypeLabel(site.herbicideFeeType, site.herbicideApplicationFee) : getFeeTypeLabel(site.contract?.sprayFeeType, site.contract?.sprayFee)
  if (site?.herbicideApplicationFrequency) {
    sprayFrequency = getFrequencyLabelByValue(site.herbicideApplicationFrequency)
  } else {
    sprayFrequency = getFrequencyLabelByValue(site.contract.spraySchedule)
  }
  return (
    <div>
      <div>

      </div>
      <div>
        Contracted Spray Rate: {herbicideFeeType}
      </div>
      <div>
          Contracted Spray Frequency: {sprayFrequency}
      </div>
      <div>
        Last Spray Date: {lastSprayDate}
      </div>
     
    </div>
  )
}

const getFeeTypeLabel = (type, rate = 0) => {
  console.log('getFeeTypeLabel', type)
  const labelMap = {
    'included': 'Included w/ Mtc Services',
    'flat': 'Flat Rate Fee',
    'tm': 'Time & Materials'
  }
  if (type === 'flat') {
    console.log('Flat rate...add the rate!', rate)
    return `Flat Fee of ${currencyFormat(rate)}`
  }
  return labelMap[type] || 'Unknown';
}

const formatLastSprayDate = (date) => {
  console.log('format this bitch', date)
  let formattedDate = moment(date).format('MM/DD/YYYY')
  console.log('formatted date', formattedDate)
  return formattedDate
}

export default ScheduleSprayModal;
