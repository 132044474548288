import React, { useState } from "react";
import {
  Modal,
  Backdrop,
  Fade,
  Paper,
  Typography,
  Button,
  CircularProgress,
  Tabs,
  Tab,
  Divider,
  Chip,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Info as InfoIcon, Launch as LaunchIcon } from "@material-ui/icons";

const BarcodeLookupModal = ({ open, onClose, onConfirm, isLoading, items }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setSelectedTabIndex(newValue);
  };

  // Format price with proper currency symbol
  const formatPrice = (item, price) => {
    // Find the first offer with currency symbol or default to $
    const currencySymbol = item.offers?.length > 0 && item.offers[0].currency_symbol 
      ? item.offers[0].currency_symbol 
      : "$";
    
    return `${currencySymbol}${price !== null && !isNaN(price) ? price.toFixed(2) : "N/A"}`;
  };

  // Render a more detailed product view for single item
  const renderDetailedView = (item) => {
    return (
      <Paper
        style={{
          padding: 20,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start", // Left-aligned content for better readability
          maxWidth: "100%",
        }}
      >
        <div style={{ display: "flex", width: "100%" }}>
          {/* Left column - Image */}
          <div style={{ flex: "0 0 350px", marginRight: 30 }}>
            <img
              src={item.images?.[0] || "https://via.placeholder.com/350"}
              alt={item.title}
              style={{
                width: "100%",
                maxHeight: "350px",
                objectFit: "contain",
                borderRadius: 5,
              }}
            />
            {item.images?.length > 1 && (
              <div style={{ display: "flex", marginTop: 10, overflow: "auto" }}>
                {item.images.slice(1, 4).map((img, idx) => (
                  <img
                    key={idx}
                    src={img}
                    alt={`${item.title} - Image ${idx + 2}`}
                    style={{
                      width: "75px",
                      height: "75px",
                      objectFit: "contain",
                      margin: "0 5px",
                      cursor: "pointer",
                      border: "1px solid #ddd",
                      borderRadius: 3,
                    }}
                  />
                ))}
                {item.images.length > 4 && (
                  <div style={{ 
                    width: "75px", 
                    height: "75px", 
                    display: "flex", 
                    alignItems: "center", 
                    justifyContent: "center",
                    backgroundColor: "#f5f5f5",
                    border: "1px solid #ddd",
                    borderRadius: 3,
                    marginLeft: 5
                  }}>
                    +{item.images.length - 4}
                  </div>
                )}
              </div>
            )}
          </div>

          {/* Right column - Product details */}
          <div style={{ flex: 1, textAlign: "left" }}>
            <Typography variant="h5" gutterBottom>
              {item.title}
            </Typography>
            
            <div style={{ display: "flex", flexWrap: "wrap", marginBottom: 15 }}>
              {item.brand && (
                <Chip 
                  label={`Brand: ${item.brand}`} 
                  style={{ margin: "0 5px 5px 0" }} 
                  variant="outlined" 
                />
              )}
              {item.mpn && (
                <Chip 
                  label={`Part #: ${item.mpn}`} 
                  style={{ margin: "0 5px 5px 0" }} 
                  variant="outlined" 
                />
              )}
              {item.model && item.model !== item.mpn && (
                <Chip 
                  label={`Model: ${item.model}`} 
                  style={{ margin: "0 5px 5px 0" }} 
                  variant="outlined" 
                />
              )}
              {item.category && (
                <Chip 
                  label={item.category} 
                  size="small"
                  style={{ margin: "0 5px 5px 0" }} 
                />
              )}
            </div>

            <Typography variant="h6" style={{ marginTop: 10, color: "#4CAF50" }}>
              {formatPrice(item, item.lowest_recorded_price)}
              {item.highest_recorded_price && (
                <span style={{ fontSize: "0.8em", marginLeft: 10, color: "#757575" }}>
                  Highest: {formatPrice(item, item.highest_recorded_price)}
                </span>
              )}
            </Typography>

            {/* Product specifications in a 2-column layout */}
            <div style={{ marginTop: 15 }}>
              <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                Specifications
              </Typography>
              <div style={{ 
                display: "grid", 
                gridTemplateColumns: "1fr 1fr", 
                columnGap: 20,
                rowGap: 5 
              }}>
                {item.weight && (
                  <div>
                    <Typography variant="body2" color="textSecondary">Weight:</Typography>
                    <Typography variant="body2">{item.weight}</Typography>
                  </div>
                )}
                {item.size && (
                  <div>
                    <Typography variant="body2" color="textSecondary">Size:</Typography>
                    <Typography variant="body2">{item.size}</Typography>
                  </div>
                )}
                {(item.length || item.width || item.height) && (
                  <div>
                    <Typography variant="body2" color="textSecondary">Dimensions:</Typography>
                    <Typography variant="body2">
                      {[
                        item.length && `L: ${item.length}`,
                        item.width && `W: ${item.width}`,
                        item.height && `H: ${item.height}`
                      ].filter(Boolean).join(', ') || 'N/A'}
                    </Typography>
                  </div>
                )}
                {item.manufacturer && (
                  <div>
                    <Typography variant="body2" color="textSecondary">Manufacturer:</Typography>
                    <Typography variant="body2">{item.manufacturer}</Typography>
                  </div>
                )}
                {item.color && (
                  <div>
                    <Typography variant="body2" color="textSecondary">Color:</Typography>
                    <Typography variant="body2">{item.color}</Typography>
                  </div>
                )}
                {item.material && (
                  <div>
                    <Typography variant="body2" color="textSecondary">Material:</Typography>
                    <Typography variant="body2">{item.material}</Typography>
                  </div>
                )}
              </div>
            </div>

            {/* UPC Information */}
            <div style={{ marginTop: 15 }}>
              <Typography variant="body2" color="textSecondary">
                UPC: {item.upc || item.barcode_number || 'N/A'}
                {item.barcode_formats && (
                  <Tooltip title={item.barcode_formats}>
                    <IconButton size="small">
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </Typography>
            </div>

            {/* Action buttons */}
            <div style={{ marginTop: 20, display: "flex", gap: 10 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onConfirm(item)}
                style={{ flex: 1 }}
              >
                Select This Item
              </Button>
              
              {item.upc && (
                <Button
                  variant="outlined"
                  color="primary"
                  size="medium"
                  href={`https://barcodelookup.com/${item.upc}`}
                  target="_blank"
                  startIcon={<LaunchIcon />}
                >
                  View UPC Details
                </Button>
              )}
            </div>
          </div>
        </div>

        {/* Description and offers tabs */}
        <div style={{ width: "100%", marginTop: 30 }}>
          <Tabs
            value={selectedTabIndex}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Description" />
            <Tab 
              label={`Offers (${item.offers?.length || 0})`} 
              disabled={!item.offers || item.offers.length === 0} 
            />
            {item.features && item.features.length > 0 && <Tab label="Features" />}
          </Tabs>
          
          <div style={{ padding: 15, minHeight: 100, maxHeight: 200, overflowY: "auto" }}>
            {selectedTabIndex === 0 && (
              item.description ? (
                <div dangerouslySetInnerHTML={{ __html: item.description }} />
              ) : (
                <Typography variant="body2" color="textSecondary">No description available.</Typography>
              )
            )}
            
            {selectedTabIndex === 1 && (
              <div>
                {item.offers && item.offers.length > 0 ? (
                  <div style={{ display: "flex", flexWrap: "wrap", gap: 15 }}>
                    {item.offers.map((offer, idx) => (
                      <Paper key={idx} style={{ padding: 10, flex: "1 0 200px", maxWidth: 300 }}>
                        <Typography variant="subtitle1">
                          {offer.merchant || offer.name || "Unknown Seller"}
                        </Typography>
                        <Typography variant="h6" style={{ color: "#4CAF50" }}>
                          {offer.currency_symbol || "$"}{parseFloat(offer.price).toFixed(2)}
                        </Typography>
                        {offer.sale_price && (
                          <Typography variant="body2" style={{ textDecoration: "line-through" }}>
                            Regular: {offer.currency_symbol || "$"}{parseFloat(offer.sale_price).toFixed(2)}
                          </Typography>
                        )}
                        <div style={{ margin: "10px 0", display: "flex", justifyContent: "space-between" }}>
                          <Typography variant="body2">{offer.condition || "N/A"}</Typography>
                          <Typography variant="body2">{offer.availability || "N/A"}</Typography>
                        </div>
                        {offer.link && (
                          <Button 
                            variant="outlined" 
                            color="primary" 
                            fullWidth
                            href={offer.link}
                            target="_blank"
                            size="small"
                            startIcon={<LaunchIcon />}
                          >
                            View Offer
                          </Button>
                        )}
                      </Paper>
                    ))}
                  </div>
                ) : (
                  <Typography variant="body2" color="textSecondary">No offers available.</Typography>
                )}
              </div>
            )}
            
            {selectedTabIndex === 2 && (
              <div>
                {item.features && item.features.length > 0 ? (
                  <ul style={{ paddingLeft: 20 }}>
                    {item.features.map((feature, idx) => (
                      <li key={idx}><Typography variant="body2">{feature}</Typography></li>
                    ))}
                  </ul>
                ) : (
                  <Typography variant="body2" color="textSecondary">No features listed.</Typography>
                )}
              </div>
            )}
          </div>
        </div>
      </Paper>
    );
  };

  // Render items in a grid for multiple results
  const renderItemGrid = (items) => {
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap", // Changed from overflowX to flexWrap for better layout
          gap: "20px",
          padding: "10px",
          justifyContent: "center",
        }}
      >
        {items.map((item, index) => (
          <Paper
            key={index}
            style={{
              width: "300px",
              padding: 15,
              textAlign: "center",
              border: "1px solid #ddd",
              borderRadius: 10,
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <img
              src={item.images?.[0] || "https://via.placeholder.com/250"}
              alt={item.title}
              style={{
                width: "100%",
                height: "200px",
                objectFit: "contain",
                borderRadius: 5,
              }}
            />
            <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
              <Typography 
                variant="h6" 
                style={{ 
                  marginTop: 10, 
                  whiteSpace: 'normal', // Changed from pre-wrap
                  height: "4.5em", // Fixed height for title
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: "vertical",
                }}
              >
                {item.title}
              </Typography>
              
              <div style={{ marginTop: "auto" }}>
                <Typography variant="body2" color="textSecondary">
                  Brand: {item.brand || "N/A"}
                </Typography>
                {item.mpn && (
                  <Typography variant="body2" color="textSecondary">
                    Part Number: {item.mpn || "N/A"}
                  </Typography>
                )}
                <Typography
                  variant="body1"
                  style={{ fontWeight: "bold", marginTop: 5, color: "#4CAF50" }}
                >
                  {formatPrice(item, item.lowest_recorded_price)}
                </Typography>
              </div>
            </div>
            
            <Divider style={{ margin: "10px 0" }} />
            
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px'
              }}
            >
              {item.offers?.[0]?.link && (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  href={item.offers[0].link}
                  target="_blank"
                  startIcon={<LaunchIcon />}
                >
                  View Online
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={() => onConfirm(item)}
              >
                Select
              </Button>
            </div>
          </Paper>
        ))}
      </div>
    );
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <Paper
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: 20,
            width: "90vw",
            maxWidth: "1300px",
            maxHeight: "90vh",
            overflowY: "auto",
            textAlign: "center",
          }}
        >
          {isLoading ? (
            <>
              <CircularProgress />
              <Typography variant="h6" style={{ marginTop: 10 }}>
                Searching for barcode...
              </Typography>
            </>
          ) : items.length > 0 ? (
            <>
              <Typography variant="h5" style={{ marginBottom: 20 }}>
                {items.length === 1 ? "Is this the correct item?" : "Select the Correct Item"}
              </Typography>

              {items.length === 1 ? renderDetailedView(items[0]) : renderItemGrid(items)}

              <Button
                variant="outlined"
                color="secondary"
                onClick={onClose}
                fullWidth
                style={{ marginTop: 20 }}
              >
                Cancel
              </Button>
            </>
          ) : (
            <>
              <Typography variant="h6" color="error">
                No items found!
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                onClick={onClose}
                fullWidth
                style={{ marginTop: 20 }}
              >
                Close
              </Button>
            </>
          )}
        </Paper>
      </Fade>
    </Modal>
  );
};

export default BarcodeLookupModal;
