import React, { useEffect } from "react";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { connect } from 'react-redux'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import { fade, makeStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import MenuItem from "@material-ui/core/MenuItem";
import Questions from './CalculationQuestions'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  textSaveLineBreaks: {
    whiteSpace: 'pre-line'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  }
}));

const calculationOptions = [
  'Plant Material Labor',
  'Gravel/Rip-Rap',
  'Irrigation',
  'Lighting',
  'Boulders',
  'Firepit',
  'BBQ',
  'Pool/Spa',
  'Spray'
]

const defaultStateValues = {
  calculator: '',
  savedCalculations: {},
  isComplete: false
}

function CalculationModal(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [state, setState] = React.useState(defaultStateValues);
  
  // Debug the current state when it changes
  React.useEffect(() => {
    if (state.calculator) {
      console.log('Choose calculation type,', state.calculator);
    }
  }, [state]);

  const handleClose = () => {
    setState({...defaultStateValues});
    props.closeModal();
  }

  const handleSelect = (e) => {
    setState({
      ...defaultStateValues,
      calculator: e.target.value
    });
  }

  const answerTheQuestions = (data) => {
    console.log("answerTheQuestions");
    console.log('Answers:', data);
    
    // Extract the saved calculations directly
    const savedCalculations = data.savedCalculations || {};
    
    // Check if we have all required questions answered
    // This is now handled inside the Questions component
    const isComplete = data.totalQuestions > 0;
    
    // Update state with the new data
    setState({
      ...state,
      savedCalculations: savedCalculations,
      isComplete: isComplete
    });
  }

  const handleCalculation = () => {
    setLoading(true);
    
    // Prepare data for API call
    const requestData = {
      question: {
        calculator: state.calculator,
        savedCalculations: state.savedCalculations
      },
      estimate: props.estimate,
      service: props.service
    };
    
    axios({
      method: 'post',
      url: `${props.url}/api/proposal/handleCalculation`,
      data: requestData
    }).then(response => {
      setLoading(false);
      
      if (response.data && response.data.success) {
        props.notification({
          type: 'success',
          title: 'Calculation Complete',
          message: 'Check Out The Line Item Details!'
        });
        
        // Reset state
        setState({...defaultStateValues});
        
        // Update parent component with results
        if (props.service) {
          props.updateParent(response.data);
        } else {
          props.updateParent(response.data.lines);
        }
      } else {
        // Show error notification if request was not successful
        props.notification({
          type: 'error',
          title: 'Calculation Failed',
          message: response.data?.error || 'An unknown error occurred'
        });
      }
    }).catch(error => {
      setLoading(false);
      props.notification({
        type: 'error',
        title: 'Calculation Error',
        message: error.message || 'Failed to process calculation'
      });
    });
  }

  return (
    <div>
      <Dialog
        maxWidth='lg'
        open={props.open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">DFLAZ Calculators</DialogTitle>
        <DialogContent> 
          <DialogContentText>
            Please choose the type of calculation that you are trying to accomplish
          </DialogContentText>
          <TextField
            select
            fullWidth
            variant="outlined"
            label="Calculation Type"
            value={state.calculator}
            helperText="What Are You Trying To Calculate?"
            onChange={handleSelect}
          >
            {calculationOptions.map((item, index) => (
              <MenuItem key={index} value={item}>{item}</MenuItem>
            ))}
          </TextField>
          
          <Questions 
            calculator={state.calculator} 
            updateParent={answerTheQuestions} 
          />
        </DialogContent>
        <DialogActions>
          {loading ? (
            <CircularProgress color="primary" size={36} />
          ) : (
            <React.Fragment>
              <Button autoFocus onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button 
                disabled={!state.isComplete || props.estimate.accepted} 
                onClick={handleCalculation} 
                color="primary" 
                autoFocus
              >
                Calculate
              </Button>
            </React.Fragment>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(CalculationModal);
