/**
 * MapWithDrawing - A component for creating and editing polygon areas on a map
 * 
 * This component provides functionality for:
 * - Drawing polygons on a Google Map
 * - Editing existing polygons
 * - Managing polygon metadata (name, type, etc.)
 * - Calculating areas of polygons
 * - Saving polygons to the server
 * 
 * State Management:
 * - drawingState: Controls the current drawing state and mode
 * - polygons: Array of saved polygons
 * - selectedPolygon: Currently selected polygon
 * - newArea: Form data for creating/editing areas
 */

import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import clsx from 'clsx';

// Material UI imports
import { makeStyles } from '@material-ui/core/styles';
import {
  Backdrop,
  CircularProgress,
  Snackbar,
  Grow,
  Typography,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

// Sub-components (will be defined separately)
import MapContainer from './MapContainer';
import Sidebar from './Sidebar';
import DialogForm from './DialogForm';

// Constants
import { 
  POLYGON_TYPES, 
  DEFAULT_COLORS,
  DRAWING_MODES,
  defaultCenter,
  containerStyle,
  mapLibraries
} from './constants';

// Utilities
import {
  getPolygonCenter,
  calculatePolygonArea,
  getPolygonArea,
  generateUniqueId
} from './utils';

import ConvexHullGrahamScan from './ConvexHullGrahamScan'
// Styles
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: '100vh',
    margin: 5,
    position: 'relative',
    justifyContent: 'flex-start'
  },
  mapContainer: {
    width: '75%',
    height: '100vh',
    position: 'relative'
  },
  sidebarContainer: {
    width: '25%',
    padding: theme.spacing(2),
    boxSizing: 'border-box',
    height: '100%',
    backgroundColor: '#f5f5f5',
    boxShadow: theme.shadows[3],
    zIndex: 10,
    overflowY: 'auto'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingText: {
    marginTop: theme.spacing(2),
    color: '#fff'
  }
}));

const MapWithDrawing = (props) => {
  const styles = useStyles();
  const history = useHistory();
  
  // Initialize refs
  const mapRef = useRef(null);
  const circleRef = useRef(null);
  const drawingManagerRef = useRef(null);
  const polygonRefs = useRef({});
  const drawingActionsRef = useRef({})

  // ****** CONSOLIDATED DRAWING STATE ******
  const [drawingState, setDrawingState] = useState({
    isDrawing: false,
    mode: null, // 'polygon', 'editing', 'idle'
    currentPoints: [],
    history: [],
    historyPosition: -1,
    activePolygonRef: null,
    editingPolygonIndex: null,
    isDialogOpen: false,
    dialogMode: 'create', // 'create', 'edit'
    drawingMode: null,
  });
  const [drawingToolType, setDrawingToolType] = useState('polygon'); // 'polygon' or 'freehand'
  
  // ****** OTHER STATE ******
  const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false);
  const [selectedPolygon, setSelectedPolygon] = useState(null);
  const [jobSite, setJobSite] = useState({});
  const [center, setCenter] = useState(defaultCenter);
  const [zoom, setZoom] = useState(15);
  const [geofenceRadius, setGeofenceRadius] = useState(100);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', type: 'success' });
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [activeTab, setActiveTab] = useState(1);
  const [selectedVertex, setSelectedVertex] = useState(null);

  // Form state for new areas with unique ID
  const [newArea, setNewArea] = useState({
    id: generateUniqueId(),
    name: '', 
    strokeColor: DEFAULT_COLORS[POLYGON_TYPES.ORNAMENTAL], 
    fillColor: DEFAULT_COLORS[POLYGON_TYPES.ORNAMENTAL], 
    paths: [],
    polygonType: POLYGON_TYPES.ORNAMENTAL,
    type: 'area',
    parentArea: null
  });
  
  // Polygon state with history tracking
  const [polygons, setPolygonsState] = useState([]);
  const [historyStack, setHistoryStack] = useState([]);
  const [historyPosition, setHistoryPosition] = useState(-1);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  
  // Tracking flags
  const propertyLinesLoaded = useRef(false);
  const jobSiteAreasLoaded = useRef(false);
  
  // ****** UI FEEDBACK FUNCTIONS ******
  // Show loading indicator
  const showLoading = useCallback((message = 'Loading...') => {
    setLoading(true);
    setLoadingMessage(message);
  }, []);
  
  // Hide loading indicator
  const hideLoading = useCallback(() => {
    setLoading(false);
    setLoadingMessage('');
  }, []);
  
  // Show snackbar notification
  const showSnackbar = useCallback((message, type = 'success') => {
    setSnackbar({ open: true, message, type });
  }, []);
  
  // Hide snackbar
  const hideSnackbar = useCallback(() => {
    setSnackbar(prev => ({ ...prev, open: false }));
  }, []);
  
  // Confirmation dialog helpers
  const confirmWith = useCallback((message, action) => {
    setConfirmMessage(message);
    setConfirmAction(() => action);
    setIsConfirmDialogOpen(true);
  }, []);
  
  const cancelConfirm = useCallback(() => {
    setIsConfirmDialogOpen(false);
    setConfirmAction(null);
  }, []);
  
  const executeConfirmed = useCallback(() => {
    if (confirmAction) {
      confirmAction();
    }
    setIsConfirmDialogOpen(false);
    setConfirmAction(null);
  }, [confirmAction]);
  
  // ===== POLYGON MANAGEMENT FUNCTIONS ===== (These need to be defined before drawingActions)
  // Set polygons with history handling
  const setPolygons = useCallback((newPolygons) => {
    setPolygonsState(newPolygons);
    
    // Save to session storage
    const jobsiteId = props?.match?.params?.jobsiteId;
    if (jobsiteId) {
      try {
        sessionStorage.setItem(`polygons_${jobsiteId}`, JSON.stringify(newPolygons));
      } catch (e) {
        console.error('Error saving polygons to session storage:', e);
      }
    }
    
    // Update history - initialize it if it's the first action
    setHistoryStack([newPolygons]);
    setHistoryPosition(0);
    setCanUndo(false);
    setCanRedo(false);
  }, [props?.match?.params?.jobsiteId]);
  
  // Add a polygon with unique ID
  const addPolygon = useCallback((polygon) => {
    const polygonWithId = {
      ...polygon,
      id: polygon.id || generateUniqueId()
    };
    
    setPolygonsState(prev => {
      const newPolygons = [...prev, polygonWithId];
      
      // Save to session storage
      const jobsiteId = props?.match?.params?.jobsiteId;
      if (jobsiteId) {
        try {
          sessionStorage.setItem(`polygons_${jobsiteId}`, JSON.stringify(newPolygons));
        } catch (e) {
          console.error('Error saving polygons to session storage:', e);
        }
      }
      
      // Update history
      setHistoryStack(stack => [...stack.slice(0, historyPosition + 1), newPolygons]);
      setHistoryPosition(pos => pos + 1);
      setCanUndo(true);
      setCanRedo(false);
      
      return newPolygons;
    });
  }, [props?.match?.params?.jobsiteId, historyPosition]);
  
  // Update a polygon by index
  const updatePolygon = useCallback((index, polygon) => {
    setPolygonsState(prev => {
      const newPolygons = prev.map((p, i) => i === index ? polygon : p);
      
      // Save to session storage
      const jobsiteId = props?.match?.params?.jobsiteId;
      if (jobsiteId) {
        try {
          sessionStorage.setItem(`polygons_${jobsiteId}`, JSON.stringify(newPolygons));
        } catch (e) {
          console.error('Error saving polygons to session storage:', e);
        }
      }
      
      // Update history
      setHistoryStack(stack => [...stack.slice(0, historyPosition + 1), newPolygons]);
      setHistoryPosition(pos => pos + 1);
      setCanUndo(true);
      setCanRedo(false);
      
      return newPolygons;
    });
  }, [props?.match?.params?.jobsiteId, historyPosition]);
  
  // Delete a polygon by index
  const deletePolygon = useCallback((index) => {
    setPolygonsState(prev => {
      const newPolygons = prev.filter((_, i) => i !== index);
      
      // Save to session storage
      const jobsiteId = props?.match?.params?.jobsiteId;
      if (jobsiteId) {
        try {
          sessionStorage.setItem(`polygons_${jobsiteId}`, JSON.stringify(newPolygons));
        } catch (e) {
          console.error('Error saving polygons to session storage:', e);
        }
      }
      
      // Update history
      setHistoryStack(stack => [...stack.slice(0, historyPosition + 1), newPolygons]);
      setHistoryPosition(pos => pos + 1);
      setCanUndo(true);
      setCanRedo(false);
      
      return newPolygons;
    });
  }, [props?.match?.params?.jobsiteId, historyPosition]);
  
  // Toggle polygon visibility
  const toggleVisibility = useCallback((index) => {
    setPolygonsState(prev => {
      const newPolygons = prev.map((p, i) => 
        i === index ? { ...p, isHidden: !p.isHidden } : p
      );
      
      // Update history
      setHistoryStack(stack => [...stack.slice(0, historyPosition + 1), newPolygons]);
      setHistoryPosition(pos => pos + 1);
      setCanUndo(true);
      setCanRedo(false);
      
      return newPolygons;
    });
  }, [historyPosition]);
  
  // Polygon history undo
  const undo = useCallback(() => {
    if (historyPosition > 0) {
      setHistoryPosition(pos => {
        const newPos = pos - 1;
        setPolygonsState(historyStack[newPos]);
        setCanRedo(true);
        setCanUndo(newPos > 0);
        return newPos;
      });
    }
  }, [historyStack, historyPosition]);
  
  // Polygon history redo
  const redo = useCallback(() => {
    if (historyPosition < historyStack.length - 1) {
      setHistoryPosition(pos => {
        const newPos = pos + 1;
        setPolygonsState(historyStack[newPos]);
        setCanUndo(true);
        setCanRedo(newPos < historyStack.length - 1);
        return newPos;
      });
    }
  }, [historyStack, historyPosition]);

  // Improved freehand drawing implementation for better control
// Simple pure freehand drawing implementation
const setupFreehandDrawing = useCallback(() => {
  if (!mapRef.current || !window.google) return;
  
  const map = mapRef.current;
  let isDrawing = false;
  let points = [];
  let tempLine = null;
  let markers = [];
  
  // Create listeners as persistent refs so we can remove them later
  const listeners = {
    mouseDown: null,
    mouseMove: null,
    mouseUp: null,
    dblClick: null
  };
  
  // Clean up function to remove all listeners and temporary objects
  const cleanupListeners = () => {
    Object.values(listeners).forEach(listener => {
      if (listener) {
        window.google.maps.event.removeListener(listener);
      }
    });
    
    // Clear temp line
    if (tempLine) {
      tempLine.setMap(null);
      tempLine = null;
    }
    
    // Clear markers
    markers.forEach(marker => marker.setMap(null));
    markers = [];
  };
  
  // Start drawing when mouse is pressed
  const startDrawing = (e) => {
    // Don't start if control/meta key is pressed (allow map panning)
    if (e.domEvent && (e.domEvent.ctrlKey || e.domEvent.metaKey)) return;
    
    // Prevent map dragging while drawing
    map.setOptions({ draggable: false });
    
    isDrawing = true;
    points = [];
    
    // Add the first point where the user clicked
    const firstPoint = {
      lat: e.latLng.lat(), 
      lng: e.latLng.lng()
    };
    points.push(firstPoint);
    
    // Create a polyline to show the drawing progress
    tempLine = new window.google.maps.Polyline({
      path: points,
      geodesic: true,
      strokeColor: '#FF0000',
      strokeOpacity: 1.0,
      strokeWeight: 3,
      map
    });
    
    // Show instructions
    showSnackbar('Move mouse to draw. Double-click or press Enter to complete.', 'info');
  };
  
  // Add points as mouse moves
  const continueDrawing = (e) => {
    if (!isDrawing || !tempLine) return;
    
    // Add current point to the line
    const currentPoint = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng()
    };
    
    // Only add points if we've moved a minimum distance
    if (points.length > 0) {
      const lastPoint = points[points.length - 1];
      const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
        new window.google.maps.LatLng(lastPoint.lat, lastPoint.lng),
        new window.google.maps.LatLng(currentPoint.lat, currentPoint.lng)
      );
      
      // Only add if we've moved at least 1 meter (adjust based on zoom/scale needs)
      if (distance >= 1) {
        points.push(currentPoint);
        tempLine.setPath(points);
      }
    }
  };
  
  // End drawing when mouse is released
  const endDrawing = (e) => {
    if (!isDrawing) return;
    
    // Re-enable map dragging
    map.setOptions({ draggable: true });
    
    // If we don't have enough points, just stop here without creating a polygon
    if (points.length < 3) {
      showSnackbar('Need at least 3 points to create an area. Drawing cancelled.', 'error');
      cleanupListeners();
      isDrawing = false;
      return;
    }
    
    // Remove the temporary polyline
    if (tempLine) {
      tempLine.setMap(null);
      tempLine = null;
    }
    
    // Create a proper polygon from the points
    const polygon = new window.google.maps.Polygon({
      paths: points,
      strokeColor: DEFAULT_COLORS[POLYGON_TYPES.ORNAMENTAL],
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: DEFAULT_COLORS[POLYGON_TYPES.ORNAMENTAL],
      fillOpacity: 0.4,
      editable: true,
      map
    });
    
    // End drawing state
    isDrawing = false;
    
    // Call polygonComplete handler
    if (drawingActionsRef.current.polygonComplete) {
      drawingActionsRef.current.polygonComplete(polygon);
    }
    
    // Clean up the listeners
    cleanupListeners();
  };
  
  // Complete drawing on double-click
  const completeOnDoubleClick = (e) => {
    if (!isDrawing) return;
    
    // Prevent the event from being handled by other listeners
    if (e.stop) e.stop();
    endDrawing();
  };
  
  // Cancel the current drawing operation
  const cancelDrawing = () => {
    if (!isDrawing) return;
    
    // Re-enable map dragging
    map.setOptions({ draggable: true });
    
    // Remove temporary objects
    if (tempLine) {
      tempLine.setMap(null);
      tempLine = null;
    }
    
    // Reset state
    isDrawing = false;
    points = [];
    
    // Call cancelDrawing handler
    if (drawingActionsRef.current.cancelDrawing) {
      drawingActionsRef.current.cancelDrawing();
    }
    
    showSnackbar('Drawing cancelled', 'info');
    
    // Clean up the listeners
    cleanupListeners();
  };
  
  // Set up listeners
  
  // Mouse down to start drawing
  listeners.mouseDown = window.google.maps.event.addListener(map, 'mousedown', startDrawing);
  
  // Mouse move to add points as we go
  listeners.mouseMove = window.google.maps.event.addListener(map, 'mousemove', continueDrawing);
  
  // Mouse up to finish drawing
  listeners.mouseUp = window.google.maps.event.addListener(map, 'mouseup', endDrawing);
  
  // Double click to complete drawing
  listeners.dblClick = window.google.maps.event.addListener(map, 'dblclick', completeOnDoubleClick);
  
  // Add a listener to handle keyboard events
  const keyDownHandler = (e) => {
    if (isDrawing) {
      // ESC to cancel
      if (e.key === 'Escape') {
        cancelDrawing();
      }
      // ENTER to complete
      else if (e.key === 'Enter') {
        endDrawing();
      }
    }
  };
  
  document.addEventListener('keydown', keyDownHandler);
  
  // Return a cleanup function
  return () => {
    // Re-enable map dragging in case we're cleaning up during drawing
    map.setOptions({ draggable: true });
    cleanupListeners();
    document.removeEventListener('keydown', keyDownHandler);
  };
}, [mapRef, showSnackbar]);

    // ****** ACTION CREATORS ******
    const drawingActions = {
      // Start a new drawing
      startDrawing: useCallback((mode = 'polygon') => {
        // First, clean up any existing drawing state
        if (drawingState.activePolygonRef) {
          drawingState.activePolygonRef.setMap(null);
        }
        
        // Initialize common drawing state
        setDrawingState(prev => ({
          ...prev,
          isDrawing: true,
          mode: mode, // 'polygon' or 'freehand'
          currentPoints: [],
          history: [],
          historyPosition: -1,
          activePolygonRef: null,
          editingPolygonIndex: null,
        }));
        
        if (mode === 'polygon') {
          // Use Google's drawing manager for polygon drawing
          if (window.google?.maps?.drawing?.OverlayType?.POLYGON) {
            setDrawingState(prev => ({
              ...prev,
              drawingMode: window.google.maps.drawing.OverlayType.POLYGON
            }));
          }
        } else if (mode === 'freehand') {
          // For freehand, we'll handle drawing ourselves
          // Set drawing mode to null to disable Google's drawing manager
          setDrawingState(prev => ({
            ...prev,
            drawingMode: null
          }));
          
          // Set up for freehand drawing with mouse events
          setupFreehandDrawing();
        }
        
        console.log(`Starting new drawing - Mode: ${mode}`);
      }, [drawingState.activePolygonRef, setupFreehandDrawing]),
      
      // Polygon complete handler
      polygonComplete: useCallback((polygon) => {
        console.log("Polygon complete called");
        
        // Get paths from polygon
        const paths = polygon.getPath().getArray().map(latlng => ({ 
          lat: latlng.lat(), 
          lng: latlng.lng() 
        }));
        
        // Update drawing state
        setDrawingState(prev => ({
          ...prev,
          isDrawing: true,
          mode: 'editing',
          currentPoints: paths,
          activePolygonRef: polygon,
          history: [paths],
          historyPosition: 0,
          drawingMode: null,
          isDialogOpen: true,
          dialogMode: 'create'
        }));
        
        // Make polygon editable
        polygon.setOptions({
          editable: true,
          draggable: false
        });
        
        // Update new area form
        setNewArea(prev => ({
          ...prev,
          id: generateUniqueId(),
          name: `Area ${polygons.length + 1}`,
          paths: paths,
          strokeColor: DEFAULT_COLORS[POLYGON_TYPES.ORNAMENTAL],
          fillColor: DEFAULT_COLORS[POLYGON_TYPES.ORNAMENTAL],
          polygonType: POLYGON_TYPES.ORNAMENTAL,
        }));
        
        // Setup vertex event listeners
        if (window.google) {
          const updatePointsFromPath = () => {
            if (!polygon) return;
            
            const path = polygon.getPath();
            const updatedPoints = [];
            
            for (let i = 0; i < path.getLength(); i++) {
              const vertex = path.getAt(i);
              updatedPoints.push({ lat: vertex.lat(), lng: vertex.lng() });
            }
            
            // Update state if points changed
            setDrawingState(prevState => {
              // Only update if points actually changed
              if (JSON.stringify(prevState.currentPoints) === JSON.stringify(updatedPoints)) {
                return prevState;
              }
              
              // Create new history entry
              const newHistory = [...prevState.history.slice(0, prevState.historyPosition + 1), updatedPoints];
              const newPosition = prevState.historyPosition + 1;
              
              console.log(`Drawing history updated: ${newHistory.length} entries, position ${newPosition}`);
              
              return {
                ...prevState,
                currentPoints: updatedPoints,
                history: newHistory,
                historyPosition: newPosition
              };
            });
            
            // Also update newArea
            setNewArea(prev => ({
              ...prev,
              paths: updatedPoints
            }));
          };
          
          const path = polygon.getPath();
          window.google.maps.event.addListener(path, 'insert_at', updatePointsFromPath);
          window.google.maps.event.addListener(path, 'set_at', updatePointsFromPath);
          window.google.maps.event.addListener(path, 'remove_at', updatePointsFromPath);
        }
      }, [polygons.length]),
      
      // Undo drawing point
      undoPoint: useCallback(() => {
        setDrawingState(prev => {
          console.log(`Attempting undo. Position: ${prev.historyPosition}, History length: ${prev.history.length}`);
          
          // Check if we can undo
          if (!prev.isDrawing || prev.historyPosition <= 0 || !prev.activePolygonRef) {
            console.log("Cannot undo - no more history or not drawing");
            return prev;
          }
          
          const newPosition = prev.historyPosition - 1;
          const previousPoints = prev.history[newPosition];
          
          if (!previousPoints) {
            console.log("Error: No points at position", newPosition);
            return prev;
          }
          
          console.log(`Undoing to position ${newPosition} with ${previousPoints.length} points`);
          
          // Update the polygon
          try {
            const path = prev.activePolygonRef.getPath();
            path.clear();
            
            previousPoints.forEach(point => {
              path.push(new window.google.maps.LatLng(point.lat, point.lng));
            });
            
            // Update newArea as well
            setNewArea(prevArea => ({
              ...prevArea,
              paths: previousPoints
            }));
            
            // Return updated state
            return {
              ...prev,
              currentPoints: previousPoints,
              historyPosition: newPosition
            };
          } catch (err) {
            console.error("Error during undo:", err);
            return prev;
          }
        });
      }, []),
      
      // Redo drawing point
      redoPoint: useCallback(() => {
        setDrawingState(prev => {
          console.log(`Attempting redo. Position: ${prev.historyPosition}, History length: ${prev.history.length}`);
          
          // Check if we can redo
          if (!prev.isDrawing || prev.historyPosition >= prev.history.length - 1 || !prev.activePolygonRef) {
            console.log("Cannot redo - at end of history or not drawing");
            return prev;
          }
          
          const newPosition = prev.historyPosition + 1;
          const nextPoints = prev.history[newPosition];
          
          if (!nextPoints) {
            console.log("Error: No points at position", newPosition);
            return prev;
          }
          
          console.log(`Redoing to position ${newPosition} with ${nextPoints.length} points`);
          
          // Update the polygon
          try {
            const path = prev.activePolygonRef.getPath();
            path.clear();
            
            nextPoints.forEach(point => {
              path.push(new window.google.maps.LatLng(point.lat, point.lng));
            });
            
            // Update newArea as well
            setNewArea(prevArea => ({
              ...prevArea,
              paths: nextPoints
            }));
            
            // Return updated state
            return {
              ...prev,
              currentPoints: nextPoints,
              historyPosition: newPosition
            };
          } catch (err) {
            console.error("Error during redo:", err);
            return prev;
          }
        });
      }, []),
      
      // Continue editing without saving
      continueEditing: useCallback(() => {
        setDrawingState(prev => {
          if (!prev.activePolygonRef) {
            console.log("Cannot continue editing - no active polygon");
            showSnackbar("Error: No polygon to edit", "error");
            return prev;
          }
          
          console.log("Continuing editing polygon");
          
          // Make sure polygon is editable
          prev.activePolygonRef.setOptions({
            editable: true,
            draggable: false,
            strokeWeight: 2,
            fillOpacity: 0.5
          });
          
          return {
            ...prev,
            isDialogOpen: false,
            isDrawing: true,
            mode: 'editing',
            drawingMode: null
          };
        });
        
        showSnackbar("Continuing to edit the shape. Press Enter when done.", "info");
      }, []),
      
      // Complete drawing (Enter key or Complete button)
      completeDrawing: useCallback(() => {
        setDrawingState(prev => {
          if (!prev.activePolygonRef) {
            return prev;
          }
          
          console.log("Completing drawing");
          
          // Get final paths
          const finalPaths = prev.activePolygonRef.getPath().getArray().map(
            latlng => ({ lat: latlng.lat(), lng: latlng.lng() })
          );
          
          // Update new area with final paths
          setNewArea(current => ({
            ...current,
            paths: finalPaths
          }));
          
          return {
            ...prev,
            isDialogOpen: true,
            dialogMode: 'create'
          };
        });
      }, []),
      
      // Cancel drawing
      cancelDrawing: useCallback(() => {
        setDrawingState(prev => {
          // Remove polygon from map
          if (prev.activePolygonRef) {
            prev.activePolygonRef.setMap(null);
          }
          
          return {
            ...prev,
            isDrawing: false,
            mode: 'idle',
            currentPoints: [],
            history: [],
            historyPosition: -1,
            activePolygonRef: null,
            editingPolygonIndex: null,
            drawingMode: null
          };
        });
        
        showSnackbar("Drawing cancelled", "info");
      }, []),
      
      // Open dialog for editing
      openEditDialog: useCallback((polygonIndex) => {
        const polygon = polygons[polygonIndex];
        if (!polygon) return;
        
        setSelectedPolygon(polygonIndex);
        
        setNewArea({
          id: polygon.id || generateUniqueId(),
          name: polygon.name,
          strokeColor: polygon.strokeColor, 
          fillColor: polygon.fillColor, 
          paths: polygon.paths,
          polygonType: polygon.polygonType,
          parentArea: polygon.parentArea,
          type: polygon.type
        });
        
        setDrawingState(prev => ({
          ...prev,
          isDialogOpen: true,
          dialogMode: 'edit',
          editingPolygonIndex: polygonIndex
        }));
      }, [polygons]),
      
      // Close dialog
      closeDialog: useCallback(() => {
        setDrawingState(prev => ({
          ...prev,
          isDialogOpen: false
        }));
      }, []),
      
      // Save area (both create and edit)
      saveArea: useCallback(() => {
        // Validate form
        if (newArea.type === 'subtraction' && !newArea.parentArea) {
          showSnackbar('Please select a parent area for subtraction', 'error');
          return;
        }
        
        if (!newArea.name || newArea.name.trim() === '') {
          showSnackbar('Please provide a name for the area', 'error');
          return;
        }
        
        // Make a copy of the area data
        const areaToSave = {...newArea};
        
        // Check if editing or creating
        if (selectedPolygon !== null) {
          // Update existing polygon
          updatePolygon(selectedPolygon, areaToSave);
          setSelectedPolygon(null);
          showSnackbar('Area updated successfully', 'success');
        } else {
          // Add new polygon
          addPolygon(areaToSave);
          showSnackbar('Area saved successfully', 'success');
        }
        
        // Clean up drawing state
        setDrawingState(prev => {
          // Remove polygon from map
          if (prev.activePolygonRef) {
            prev.activePolygonRef.setMap(null);
          }
          
          return {
            ...prev,
            isDrawing: false,
            mode: 'idle',
            isDialogOpen: false,
            currentPoints: [],
            history: [],
            historyPosition: -1,
            activePolygonRef: null,
            editingPolygonIndex: null,
            drawingMode: null
          };
        });
        
        // Reset new area form
        setNewArea({
          id: generateUniqueId(),
          name: '', 
          strokeColor: DEFAULT_COLORS[POLYGON_TYPES.ORNAMENTAL], 
          fillColor: DEFAULT_COLORS[POLYGON_TYPES.ORNAMENTAL], 
          paths: [],
          polygonType: POLYGON_TYPES.ORNAMENTAL,
          type: 'area',
          parentArea: null
        });
      }, [newArea, selectedPolygon, updatePolygon, addPolygon, showSnackbar]),
      // Add to drawingActions
      startFreehandDrawing: useCallback(() => {
        setDrawingState(prev => ({
          ...prev,
          isDrawing: true,
          mode: 'freehand',
          currentPoints: [],
          history: [],
          historyPosition: -1,
          activePolygonRef: null,
          drawingMode: null // Don't use Google's drawing manager for this
        }));
        
        // Set up mouse event listeners on the map
        if (mapRef.current) {
          const map = mapRef.current;
          let isDrawing = false;
          let points = [];
          
          const mouseDownListener = map.addListener('mousedown', (e) => {
            isDrawing = true;
            points = [{lat: e.latLng.lat(), lng: e.latLng.lng()}];
          });
          
          const mouseMoveListener = map.addListener('mousemove', (e) => {
            if (!isDrawing) return;
            points.push({lat: e.latLng.lat(), lng: e.latLng.lng()});
            
            // Update temporary polygon visualization
            // ...
          });
          
          const mouseUpListener = map.addListener('mouseup', () => {
            isDrawing = false;
            
            if (points.length > 2) {
              // Complete the polygon and make it editable
              const polygon = new window.google.maps.Polygon({
                paths: points,
                editable: true,
                map: map,
                // Other styling options...
              });
              
              // Update state with new polygon
              drawingActionsRef.current.polygonComplete(polygon);
            }
            
            // Remove listeners
            window.google.maps.event.removeListener(mouseDownListener);
            window.google.maps.event.removeListener(mouseMoveListener);
            window.google.maps.event.removeListener(mouseUpListener);
          });
        }
      }, [mapRef]),
    };
  
    useEffect(() => {
      drawingActionsRef.current = drawingActions;
    }, [drawingActions]);
    
  // Get default properties for a new area
  const getNewAreaDefaults = useCallback(() => {
    return {
      id: generateUniqueId(),
      name: '', 
      strokeColor: DEFAULT_COLORS[POLYGON_TYPES.ORNAMENTAL], 
      fillColor: DEFAULT_COLORS[POLYGON_TYPES.ORNAMENTAL], 
      paths: [],
      polygonType: POLYGON_TYPES.ORNAMENTAL,
      type: 'area',
      parentArea: null
    };
  }, []);
  
  // Calculate net area (excluding subtractions)
  const calculateNetArea = useCallback((polygon) => {
    if (!window.google?.maps?.geometry) return 0;
    const mainArea = getPolygonArea(polygon.paths).areaFeet;
    const subtractions = polygons.filter(p => p.type === 'subtraction' && p.parentArea === polygon.name);
    const totalSubtracted = subtractions.reduce((sum, p) => sum + getPolygonArea(p.paths).areaFeet, 0);
    return mainArea - totalSubtracted;
  }, [polygons]);

  
  // ****** MAP EVENT HANDLERS ******
  // Handle map load
  const onMapLoad = useCallback((map) => {
    console.log('Map loaded!');
    mapRef.current = map;
    setIsGoogleMapsLoaded(true);
    
    // Initialize history if empty when map is loaded
    if (historyStack.length === 0 && polygons.length > 0) {
      setHistoryStack([polygons]);
      setHistoryPosition(0);
      setCanUndo(false);
      setCanRedo(false);
    }
  }, [polygons, historyStack.length]);
  
  // Handle zoom change
  const handleZoomChanged = useCallback(() => {
    if (mapRef.current) {
      setZoom(mapRef.current.getZoom());
    }
  }, []);
  
  // Handle circle radius change
  const onCircleRadiusChanged = useCallback(() => {
    if (circleRef.current) {
      const newRadius = circleRef.current.getRadius();
      setGeofenceRadius(Math.round(newRadius));
    }
  }, []);
  
  // Register polygon reference
  const registerPolygonRef = useCallback((index, polygonObj) => {
    polygonRefs.current[index] = polygonObj;
  }, []);
  
  // Handle polygon path change (when editing)
  const handlePolygonPathChange = useCallback((index) => {
    return () => {
      // Only proceed if we're editing this polygon
      if (drawingState.editingPolygonIndex === index) {
        // Access the polygon through our refs
        const polygonObj = polygonRefs.current[index];
        if (!polygonObj) return;
        
        // Get the updated coordinates
        const path = polygonObj.getPath();
        const updatedPaths = [];
        
        for (let i = 0; i < path.getLength(); i++) {
          const point = path.getAt(i);
          updatedPaths.push({ lat: point.lat(), lng: point.lng() });
        }
        
        // Update the polygon
        const updatedPolygon = {
          ...polygons[index],
          paths: updatedPaths
        };
        
        updatePolygon(index, updatedPolygon);
      }
    };
  }, [drawingState.editingPolygonIndex, polygons, updatePolygon]);
  
  const handlePolygonVertexEdit = useCallback((index) => {
    console.log("handlePolygonVertexEdit")
    if (!polygonRefs.current[index]) return;
    const polygon = polygonRefs.current[index];
    
    // Add right-click listener to vertices
    const listener = window.google.maps.event.addListener(polygon, 'rightclick', (event) => {
      console.log("Right click!!!")
      event.stopPropagation()
      const path = polygon.getPath();
      const vertices = path.getArray();
      
      // Get click location
      const clickLat = event.latLng.lat();
      const clickLng = event.latLng.lng();
      
      // Find the closest vertex (with a threshold)
      const threshold = 0.00005; // Adjust based on zoom level
      let vertexIndex = -1;
      
      for (let i = 0; i < vertices.length; i++) {
        const vertex = vertices[i];
        const vertexLat = vertex.lat();
        const vertexLng = vertex.lng();
        
        // Check if click is close enough to a vertex
        if (Math.abs(clickLat - vertexLat) < threshold && 
            Math.abs(clickLng - vertexLng) < threshold) {
          vertexIndex = i;
          break;
        }
      }
      
      if (vertexIndex !== -1) {
        // Confirm vertex deletion
        if (window.confirm(`Delete this point?`)) {
          // Need to ensure we have at least 3 vertices for a valid polygon
          if (path.getLength() > 3) {
            // Remove the vertex
            path.removeAt(vertexIndex);
            
            // Update polygon in state
            const updatedPaths = path.getArray().map(latLng => ({
              lat: latLng.lat(),
              lng: latLng.lng()
            }));
            
            // Update state
            updatePolygon(index, {
              ...polygons[index],
              paths: updatedPaths
            });
            
            showSnackbar('Vertex deleted', 'success');
          } else {
            showSnackbar('Cannot delete vertex: Minimum 3 vertices required', 'error');
          }
        }
      }
    });
    
    // Return cleanup function to remove the listener
    return () => {
      window.google.maps.event.removeListener(listener);
    };
  }, [polygons, updatePolygon, showSnackbar]);

  const deleteSelectedVertex = useCallback(() => {
    if (!selectedVertex) {
      console.log('No vertex selected');
      return;
    }
    
    console.log('Deleting vertex:', selectedVertex);
    
    const { polygonIndex, vertexIndex } = selectedVertex;
    const polygonObj = polygonRefs.current[polygonIndex];
    
    if (!polygonObj) {
      console.log('Polygon reference not found');
      return;
    }
    
    const path = polygonObj.getPath();
    
    // Need to ensure we have at least 3 vertices for a valid polygon
    if (path.getLength() <= 3) {
      showSnackbar('Cannot delete vertex: Minimum 3 vertices required', 'error');
      return;
    }
    
    // Remove the vertex
    path.removeAt(vertexIndex);
    
    // Update polygon in state
    const updatedPaths = path.getArray().map(latLng => ({
      lat: latLng.lat(),
      lng: latLng.lng()
    }));
    
    // Update state
    updatePolygon(polygonIndex, {
      ...polygons[polygonIndex],
      paths: updatedPaths
    });
    
    // Clear selected vertex
    setSelectedVertex(null);
    
    showSnackbar('Vertex deleted', 'success');
  }, [selectedVertex, polygonRefs, polygons, updatePolygon, showSnackbar]);

  const setupVertexSelection = useCallback((polygonObj, polygonIndex) => {
    if (!mapRef.current || !window.google) return;
    
    console.log('Setting up vertex selection for polygon', polygonIndex);
    
    // Add a click handler to the map to detect clicks near vertices
    const mapClickListener = window.google.maps.event.addListener(
      mapRef.current,
      'click',
      function(event) {
        // Skip if we're not editing this polygon anymore
        if (drawingState.editingPolygonIndex !== polygonIndex) return;
        
        // Get the path vertices
        const path = polygonObj.getPath();
        const vertices = path.getArray();
        
        // Get click location
        const clickLatLng = event.latLng;
        
        // Find the closest vertex
        let closestIndex = -1;
        let closestDistance = Infinity;
        
        vertices.forEach((vertex, index) => {
          const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
            clickLatLng,
            vertex
          );
          
          if (distance < closestDistance) {
            closestDistance = distance;
            closestIndex = index;
          }
        });
        
        // Only select if within reasonable distance (10 meters)
        if (closestDistance < 10) {
          console.log(`Selected vertex ${closestIndex} at distance ${closestDistance.toFixed(2)}m`);
          
          setSelectedVertex({
            polygonIndex: polygonIndex,
            vertexIndex: closestIndex,
            position: {
              lat: vertices[closestIndex].lat(),
              lng: vertices[closestIndex].lng()
            }
          });
        } else {
          console.log(`No vertex nearby. Closest was ${closestDistance.toFixed(2)}m away`);
          setSelectedVertex(null);
        }
      }
    );
    
    // Store the listener for cleanup
    const oldListeners = polygonObj.get('listeners') || [];
    polygonObj.set('listeners', [...oldListeners, mapClickListener]);
    
  }, [drawingState.editingPolygonIndex, mapRef, setSelectedVertex]);
  

  // Handle polygon click on map
  const handleMapPolygonClick = useCallback((index) => {
    console.log('Polygon clicked, index:', index);
    console.log(polygons[index])
    // Don't allow editing property lines
    if (polygons[index].type === 'propertyLine') {
      return;
    }
    // Exit edit mode for any existing polygon first
    setDrawingState(prev => ({
      ...prev, 
      editingPolygonIndex: null
    }));
    setSelectedPolygon(null);
    setSelectedVertex(null); // Clear any selected vertex
    

    
    // If not in drawing mode and on the areas tab, enable editing
    if (activeTab === 1 && !drawingState.isDrawing) {
      console.log('Enabling edit mode for polygon:', index);
      setSelectedPolygon(index);
      // Get the polygon reference
      const polygonObj = polygonRefs.current[index];
      if (!polygonObj) {
        console.error('Polygon reference not found for index:', index);
        return;
      }
      
      // Make the polygon editable
      polygonObj.setOptions({
        editable: true,
        draggable: false
      });
      
      // Get the edit handles (markers) that Google Maps creates
      // We need to delay this slightly to allow Google Maps to create all handles
      setTimeout(() => {
        // Set up the vertex selection system
        setupVertexSelection(polygonObj, index);
      }, 100);
      
      // Update state
      setDrawingState(prev => ({
        ...prev,
        editingPolygonIndex: index
      }));
      // drawingActions.openEditDialog(index);
      showSnackbar('Polygon edit mode activated. Click on a point to select it, then use the delete button.', 'info');
    } else {
      console.log('1355...')
      // Otherwise just select and open dialog
      drawingActions.openEditDialog(index);
    }
  }, [activeTab, drawingState.isDrawing, polygons, showSnackbar, drawingActions.openEditDialog]);
  

// Add this to handle cleanup of event listeners when exiting edit mode
useEffect(() => {
  // This runs when editingPolygonIndex changes (especially when set to null)
  return () => {
    // Clean up any listeners on previously edited polygon
    if (drawingState.editingPolygonIndex !== null) {
      const polygonObj = polygonRefs.current[drawingState.editingPolygonIndex];
      if (polygonObj) {
        // Get and remove all listeners we added
        const listeners = polygonObj.get('listeners') || [];
        listeners.forEach(listener => {
          window.google.maps.event.removeListener(listener);
        });
        polygonObj.set('listeners', []);
      }
    }
    const mapDiv = mapRef.current?.div || document.querySelector('.gm-style');
    if (mapDiv) {
      const markerElements = mapDiv.querySelectorAll('[data-vertex-listener="true"]');
      markerElements.forEach(marker => {
        // Clean up by cloning and replacing (removes all listeners)
        const newMarker = marker.cloneNode(true);
        if (marker.parentNode) {
          marker.parentNode.replaceChild(newMarker, marker);
        }
      });
    }
  };
}, [drawingState.editingPolygonIndex, mapRef]);
  
  // Handle tab change
  const handleTabChange = useCallback((event, newValue) => {
    setActiveTab(newValue);
    
    // Clear any direct polygon editing when changing tabs
    setDrawingState(prev => ({
      ...prev,
      editingPolygonIndex: null
    }));
    
    if (newValue === 1) {
      if (window.google?.maps?.drawing?.OverlayType?.POLYGON) {
        setDrawingState(prev => ({
          ...prev,
          drawingMode: window.google.maps.drawing.OverlayType.POLYGON
        }));
      }
    } else {
      setDrawingState(prev => ({
        ...prev,
        drawingMode: null
      }));
    }
  }, []);
  
  // Handle geofence radius change
  const handleGeofenceChange = useCallback((event) => {
    setGeofenceRadius(Number(event.target.value));
  }, []);
  
  // Handle polygon complete (from drawing manager)
  const handlePolygonComplete = useCallback((polygon) => {
    drawingActions.polygonComplete(polygon);
  }, [drawingActions.polygonComplete]);
  
  // Handle copy polygon
  const handleCopyPolygon = useCallback(() => {
    if (selectedPolygon !== null) {
      const polygonToCopy = polygons[selectedPolygon];
      const newPolygonName = `${polygonToCopy.name} (Copy)`;
      
      const copiedPolygon = {
        ...polygonToCopy,
        id: generateUniqueId(),
        name: newPolygonName
      };
      
      addPolygon(copiedPolygon);
      drawingActions.closeDialog();
      showSnackbar('Area copied successfully', 'success');
    }
  }, [selectedPolygon, polygons, addPolygon, drawingActions.closeDialog, showSnackbar]);
  
  // Handle delete polygon
  const handleDeletePolygon = useCallback(() => {
    confirmWith(
      'Are you sure you want to delete this area? This action cannot be undone.',
      () => {
        deletePolygon(selectedPolygon);
        setSelectedPolygon(null);
        setDrawingState(prev => ({
          ...prev,
          editingPolygonIndex: null,
          isDialogOpen: false
        }));
        showSnackbar('Area deleted successfully', 'success');
      }
    );
  }, [confirmWith, deletePolygon, selectedPolygon, showSnackbar]);

  // Get polygon options (styling)
  const getPolygonOptions = useCallback((polygon, isSelected, isPropertyline) => {
    // Base options
    const options = {
      fillColor: polygon.fillColor,
      fillOpacity: polygon.type === 'subtraction' ? 0.7 : 0.4,
      strokeColor: polygon.strokeColor,
      strokeOpacity: 1,
      strokeWeight: 2,
      editable: isPropertyline ? false : isSelected, // Will be true for direct editing
      draggable: isPropertyline ? false : isSelected
    };
    
    // Override for property lines
    if (polygon.type === 'propertyLine') {
      console.log('This is propertyline!!!!')
      options.fillColor = 'rgba(0, 0, 255, 0.2)';
      options.strokeWeight = 3;
      options.strokeOpacity = 0.8;
      options.strokeColor = 'rgb(0, 0, 255)';
      options.editable = false;
      options.draggable = false;
      options.zIndex = 1; // Keep property lines at the bottom
    }
    
    return options;
  }, []);

  // Handle form input change
  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    
    if (name === 'polygonType') {
      // Update colors based on polygon type
      setNewArea(prev => ({ 
        ...prev, 
        [name]: value,
        fillColor: DEFAULT_COLORS[value],
        strokeColor: DEFAULT_COLORS[value]
      }));
    } else {
      setNewArea(prev => ({ ...prev, [name]: value }));
    }
  }, []);

  // ****** API INTERACTION ******
  // Save areas
  const saveAreas = useCallback(async () => {
    try {
      showLoading('Saving areas...');
      
      // Calculate total and net areas
      let totalArea = 0;
      let netArea = 0;
      let filteredPolygons = polygons.filter(p => (p.type !== 'propertyLine'))
      let polygonsWithAreas = filteredPolygons.map(item => {
        const area = getPolygonArea(item.paths);
        const netAreaOfThisItem = calculateNetArea(item);
        
        if (item?.type !== 'subtraction') {
          netArea += Number(netAreaOfThisItem);
          totalArea += Number(area.areaFeet);
        }
        
        return {
          ...item,
          area: area.areaFeet.toFixed(2)
        };
      });
      
      let obj = {
        areas: polygonsWithAreas,
        jobSite,
        totalArea,
        netArea
      };
      
      const response = await axios({
        method: 'post',
        url: `${props.url}/api/customer/updateJobSite?areasOnly=1`,
        data: obj,
      });
      
      showSnackbar('Areas saved successfully', 'success');
      
      props.notification({
        type: 'success',
        title: 'Areas Saved',
        message: 'All areas have been saved successfully'
      });
      
      return response.data;
    } catch (error) {
      console.error('Error saving areas:', error);
      showSnackbar('Failed to save areas', 'error');
      
      props.notification({
        type: 'error',
        title: 'Error',
        message: 'Failed to save areas'
      });
    } finally {
      hideLoading();
    }
  }, [polygons, jobSite, calculateNetArea, props.url, props.notification, showLoading, hideLoading, showSnackbar]);

  // Handle save geofence area
  const saveGeofenceArea = useCallback(async () => {
    try {
      showLoading('Saving geofence data...');
      
      let centerOfCircle;
      if (circleRef.current) {
        centerOfCircle = circleRef.current.getCenter().toJSON();
      } else {
        centerOfCircle = center;
      }
      
      let obj = {
        center: centerOfCircle,
        geofenceRadius,
        jobSite,
      };
      
      const response = await axios({
        method: 'post',
        url: `${props.url}/api/customer/updateJobSite?siteRadiusOnly=1`,
        data: obj,
      });
      
      props.notification({
        type: 'success',
        title: 'Jobsite Radius Updated',
        message: 'We have saved that information. Great job!'
      });
      
      showSnackbar('Geofence saved successfully', 'success');
      return response.data;
    } catch (error) {
      console.error('Error saving geofence:', error);
      
      props.notification({
        type: 'warning',
        title: 'Jobsite Radius Failed to Save',
        message: `We Ran Into An Issue...${error?.error || '586 CMP'}`
      });
      
      showSnackbar('Failed to save geofence', 'error');
    } finally {
      hideLoading();
    }
  }, [circleRef, center, geofenceRadius, jobSite, props.url, props.notification, showLoading, hideLoading, showSnackbar]);

  // Fetch job site areas
  const fetchJobSiteAreas = useCallback(async (siteId) => {
    console.log("Fetching job site areas for:", siteId);
    try {
      showLoading('Loading job site data...');
      
      const response = await axios.get(`${props.url}/api/customer/fetchJobSiteAreas?id=${siteId}`);
      
      if (response && response.data && response.data._id) {
        setJobSite(response.data);
        
        if (response.data && response.data.areas && response.data.areas.length) {
          // Add IDs to any polygons that don't have them
          const areasWithIds = response.data.areas.map(area => ({
            ...area,
            id: area.id || generateUniqueId()
          }));
          
          setPolygons(areasWithIds);
          showSnackbar(`Loaded ${areasWithIds.length} areas successfully`, 'success');
        }
        
        if (response.data && response.data.radius) {
          setGeofenceRadius(response.data.radius);
        }
        
        if (response.data && response.data.location && response.data.location.coordinates) {
          setCenter({
            lat: response.data.location.coordinates[1],
            lng: response.data.location.coordinates[0]
          });
        }
        
        return response.data;
      }
    } catch (error) {
      console.error('Error fetching job site areas:', error);
      showSnackbar('Failed to load job site data', 'error');
    } finally {
      hideLoading();
    }
    
    return null;
  }, [props.url, setPolygons, showLoading, hideLoading, showSnackbar]);
  
  // Fetch property lines
  const fetchPropertyLines = useCallback(async (siteId) => {
    try {
      showLoading('Fetching property boundaries...');
      
      props.notification({
        type: 'info',
        title: 'Property Lines',
        message: 'Fetching Property Boundaries from Maricopa County'
      });
      
      const response = await axios.get(`${props.url}/api/customer/getAPNAndParcelInfo?id=${siteId}`);
      
      if (response?.data?.results?.length &&
          response.data.results[0]?.googleMapsFormat?.length > 0) {
        let newAreas = [];
        
        for (const property of response.data.results) {
          const parcelData = property;
          const paths = parcelData.googleMapsFormat;
          
          // Create property line polygon
          const propertyLine = {
            id: generateUniqueId(),
            APN: parcelData.properties.APN,
            address: parcelData.properties.address,
            paths: paths,
            polygonType: 'propertyLine',
            type: 'propertyLine',
            fillColor: 'rgba(0, 0, 255, 0.5)',
            strokeColor: '#0022FF',
            strokeWeight: 4,
            isHidden: false
          };
          
          newAreas.push(propertyLine);
        }
        
        // Save to session storage
        try {
          sessionStorage.setItem(`propertyLines_${siteId}`, JSON.stringify(newAreas));
        } catch (e) {
          console.error('Error saving property lines to session storage:', e);
        }
        
        // Center the map based on simple averaging of all points
        if (newAreas.length > 0 && mapRef.current) {
          // Calculate center manually
          let allLats = 0;
          let allLngs = 0;
          let pointCount = 0;
          
          newAreas.forEach(area => {
            if (area.paths && area.paths.length > 0) {
              area.paths.forEach(point => {
                allLats += point.lat;
                allLngs += point.lng;
                pointCount++;
              });
            }
          });
          
          if (pointCount > 0) {
            const newCenter = {
              lat: allLats / pointCount,
              lng: allLngs / pointCount
            };
            
            setCenter(newCenter);
            
            // Set zoom instead of using fitBounds
            if (mapRef.current) {
              // A higher zoom value = more zoomed in
              mapRef.current.setZoom(18);
            }
          }
        }
        
        showSnackbar('Property boundaries loaded successfully', 'success');
        return newAreas;
      } else {
        showSnackbar('No property boundary data available', 'info');
      }
    } catch (error) {
      console.error('Error fetching property lines:', error);
      showSnackbar('Failed to fetch property boundaries', 'error');
    } finally {
      hideLoading();
    }
    
    return [];
  }, [props.url, props.notification, mapRef, showLoading, hideLoading, showSnackbar]);

  // Check if we have property lines
  const checkIfWeHavePropertyLines = useCallback((jobSite) => {
    // Check session storage first
    const sessionKey = `propertyLines_${jobSite?._id}`;
    const storedPropertyLines = sessionStorage.getItem(sessionKey);
    
    if (storedPropertyLines) {
      console.log('Loading property lines from session storage');
      try {
        const parsedLines = JSON.parse(storedPropertyLines);
        if (parsedLines && parsedLines.length > 0) {
          // Use functional update to safely add property lines
          setPolygonsState(currentPolygons => [...currentPolygons, ...parsedLines]);
          return;
        }
      } catch (e) {
        console.error('Error parsing stored property lines:', e);
      }
    }
    
    // Only fetch if we haven't already loaded them
    if (jobSite && jobSite._id && !propertyLinesLoaded.current) {
      console.log('Should we fetch some property lines??')
      propertyLinesLoaded.current = true; 
      fetchPropertyLines(jobSite._id).then(newPropertyLines => {
        if (newPropertyLines && newPropertyLines.length > 0) {
          // Use functional update to safely add property lines
          setPolygonsState(currentPolygons => [...currentPolygons, ...newPropertyLines]);
        }
      });
    }
  }, [fetchPropertyLines]);

  // Handle change site
  const handleChangeSite = useCallback((newSite) => {
    setPolygons([]);
    setDrawingState(prev => ({
      ...prev,
      editingPolygonIndex: null
    }));
    setSelectedPolygon(null);
    
    if (newSite?._id) {
      fetchJobSiteAreas(newSite._id).then(() => {
        setZoom(18);
      });
    } else {
      setJobSite({});
      setCenter(defaultCenter);
    }
  }, [setPolygons, fetchJobSiteAreas]);
  
  // Handle search assessor
  const handleSearchMCAssessor = useCallback(() => {
    for (const site of polygons) {
      if (site?.type === "propertyLine" && site?.address) {
        // Remove commas, extra spaces, and encode properly
        let addy = site.address.replace(/,/g, "").replace(/\s+/g, " ").trim();
        addy = encodeURIComponent(addy).replace(/%2C/g, ""); // Remove encoded commas
  
        const lat = jobSite?.location?.coordinates?.length
          ? jobSite.location.coordinates[1]
          : jobSite.latitude;
        const lng = jobSite?.location?.coordinates?.length
          ? jobSite.location.coordinates[0]
          : jobSite.longitude;
  
        let url2 = `https://maps.mcassessor.maricopa.gov/ipa.aspx?1=${lat}&2=${lng}&a=${addy}`;
        window.open(url2, "_blank").focus();
        break;
      }
    }
  }, [polygons, jobSite]);
  
  // ****** KEYBOARD HANDLING ******
  const handleKeyDown = useCallback((e) => {
    // Don't capture keyboard events when focusing inputs
    if (
      e.target.tagName === 'INPUT' || 
      e.target.tagName === 'TEXTAREA' || 
      e.target.isContentEditable
    ) {
      return;
    }
    
    // ESC key to cancel current action
    if (e.key === 'Escape') {
      if (drawingState.isDrawing) {
        drawingActions.cancelDrawing();
      } else if (drawingState.editingPolygonIndex !== null) {
        setDrawingState(prev => ({
          ...prev,
          editingPolygonIndex: null
        }));
        showSnackbar('Editing cancelled', 'info');
      }
      return;
    }
  
    // Z key for undo
    if ((e.metaKey || e.ctrlKey) && e.key === 'z' && !e.shiftKey) {
      e.preventDefault();
      
      // If we're drawing, use point-level undo
      if (drawingState.isDrawing) {
        drawingActions.undoPoint();
        showSnackbar('Undid last point', 'info');
      } 
      // Otherwise use polygon-level undo
      else if (canUndo) {
        undo();
        showSnackbar('Undo successful', 'success');
      }
    }
    
    // Y or Shift+Z for redo
    if (((e.metaKey || e.ctrlKey) && e.key === 'y') || 
        ((e.metaKey || e.ctrlKey) && e.key === 'z' && e.shiftKey)) {
      e.preventDefault();
      
      // If we're drawing, use point-level redo
      if (drawingState.isDrawing) {
        drawingActions.redoPoint();
        showSnackbar('Redid point', 'info');
      } 
      // Otherwise use polygon-level redo
      else if (canRedo) {
        redo();
        showSnackbar('Redo successful', 'success');
      }
    }
    
    // Enter key to complete drawing
    if (e.key === 'Enter' && drawingState.isDrawing) {
      drawingActions.completeDrawing();
    }
  }, [
    drawingState, 
    drawingActions.cancelDrawing, 
    drawingActions.undoPoint, 
    drawingActions.redoPoint, 
    drawingActions.completeDrawing,
    undo, 
    redo, 
    canUndo, 
    canRedo, 
    showSnackbar
  ]);

  // ****** EFFECTS ******
  // Load initial data and setup event listeners
  useEffect(() => {
    let isMounted = true;
    
    // Define a function to handle the loading sequence
    const loadData = async () => {
      console.log('load data 1716...', props?.match?.params)
      console.log(jobSiteAreasLoaded)
      if (props?.match?.params?.jobsiteId && !jobSiteAreasLoaded.current) {
        jobSiteAreasLoaded.current = true;
        
        try {
          const data = await fetchJobSiteAreas(props.match.params.jobsiteId);
          if (!isMounted) return;
          console.log('Do we hvae data?? 1722', data)
          if (data) {
            // Only check for property lines after job site areas are loaded
            setTimeout(() => {
              // Use a timeout to break the synchronous call chain
              if (isMounted) {
                checkIfWeHavePropertyLines(data);
              }
            }, 0);
          }
        } catch (error) {
          console.error('Error loading job site data:', error);
        }
      }
    };
    
    // Call the function
    loadData();
    
    // Setup keyboard event listeners
    // document.addEventListener('keydown', handleKeyDown);
    
    return () => {
      isMounted = false;
    };
  }, [props?.match?.params?.jobsiteId]);


  useEffect(() => {
   
    // Setup keyboard event listeners
    document.addEventListener('keydown', handleKeyDown);
    
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const handleUpdateParent = (type) => {
    console.log('Update parent...', type)
    console.log('selected', selectedPolygon)
    console.log('Drawing state', drawingState)
    if (type === 'open' && drawingState?.editingPolygonIndex > -1) {
      console.log('Open that modal...')
      drawingActions.openEditDialog(drawingState.editingPolygonIndex)
    }
    if (type === 'delete' && drawingState?.editingPolygonIndex > -1) {
      handleDeletePolygon(drawingState.editingPolygonIndex)
    }
  }

  const handleMergeAreas = useCallback(() => {
    // Open a dialog with checkboxes for each area
    setDrawingState(prev => ({
      ...prev,
      isDialogOpen: true,
      dialogMode: 'merge' // New dialog mode
    }));
  }, []);

  const executeMerge = useCallback((selectedAreaIndices) => {
    if (!selectedAreaIndices || selectedAreaIndices.length < 2) {
      showSnackbar('Please select at least two areas to merge', 'error');
      return;
    }
    
    // Get the selected polygons
    const selectedPolygons = selectedAreaIndices.map(index => polygons[index]);
    
    // First, create Google Maps Polygon objects for each selected polygon
    const googlePolygons = selectedPolygons.map(polygon => {
      const paths = polygon.paths.map(point => 
        new window.google.maps.LatLng(point.lat, point.lng)
      );
      
      return new window.google.maps.Polygon({
        paths: paths
      });
    });
    
    // Create a new merged polygon by combining all paths
    // This works because Google Maps renders polygons with multiple paths correctly
    // Where paths overlap, it creates the correct union automatically
    const allPaths = [];
    googlePolygons.forEach(polygon => {
      const path = polygon.getPath();
      const pathArray = [];
      
      for (let i = 0; i < path.getLength(); i++) {
        pathArray.push({
          lat: path.getAt(i).lat(),
          lng: path.getAt(i).lng()
        });
      }
      
      allPaths.push(pathArray);
    });
    
    // Create a new polygon with all the paths
    const mergedPolygon = {
      id: generateUniqueId(),
      name: `Merged Area`,
      strokeColor: selectedPolygons[0].strokeColor,
      fillColor: selectedPolygons[0].fillColor,
      paths: allPaths.flat(), // Flatten all paths into one array
      polygonType: selectedPolygons[0].polygonType,
      type: 'area'
    };
    
    // Add the new polygon
    addPolygon(mergedPolygon);
    
    // Remove the original polygons (in reverse order to avoid index issues)
    [...selectedAreaIndices].sort((a, b) => b - a).forEach(index => {
      deletePolygon(index);
    });
    
    // Close the dialog
    setDrawingState(prev => ({
      ...prev,
      isDialogOpen: false
    }));
    
    showSnackbar('Areas merged successfully', 'success');
  }, [polygons, addPolygon, deletePolygon, showSnackbar]);

  const createPolygonUnion = useCallback((polygons) => {
    if (!window.google?.maps?.geometry) return null;
    
    // Create Google Maps LatLng objects for each polygon
    const googlePolygons = polygons.map(polygon => {
      // if (polygon.type === 'propertyLine') return
      return polygon.paths.map(point => 
        new window.google.maps.LatLng(point.lat, point.lng)
      );
    });
    
    // Use a library like turf.js or implement a custom solution using the Google Maps geometry library
    // This is a simplified approach and might not handle complex polygons correctly
    // For a production app, consider using a proper computational geometry library
    
    try {
      // For this example, we'll use a simple approach that works for basic cases
      // Combine all points from all polygons
      const allPoints = googlePolygons.flat();
      
      // Calculate the convex hull of all points
      // This is a simplified merge - a proper implementation would need more sophisticated geometry operations
      const hull = new ConvexHullGrahamScan();
      allPoints.forEach(point => {
        hull.addPoint(point.lng(), point.lat());
      });
      
      const hullPoints = hull.getHull();
      
      // Convert back to our format
      return hullPoints.map(point => ({
        lat: point.y,
        lng: point.x
      }));
    } catch (error) {
      console.error('Error creating polygon union:', error);
      return null;
    }
  }, []);

  // ****** RENDER ******
  return (
    <div className={styles.root}>
      {/* Loading Backdrop */}
      <Backdrop className={styles.backdrop} open={loading}>
        <CircularProgress color="inherit" size={60} />
        <Typography variant="h6" className={styles.loadingText}>
          {loadingMessage || 'Loading...'}
        </Typography>
      </Backdrop>
      
      {/* Snackbar for feedback */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={hideSnackbar}
        TransitionComponent={Grow}
        className={snackbar.type === 'success' ? styles.successSnackbar : styles.errorSnackbar}
        message={snackbar.message}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      />
      
      {/* Confirmation Dialog */}
      <Dialog open={isConfirmDialogOpen} onClose={cancelConfirm}>
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <Typography>{confirmMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelConfirm} color="primary">
            Cancel
          </Button>
          <Button onClick={executeConfirmed} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Map Container */}
      <div className={styles.mapContainer}>
        <MapContainer
          isGoogleMapsLoaded={isGoogleMapsLoaded}
          drawingState={drawingState}
          drawingActions={drawingActions}
          polygons={polygons}
          selectedPolygon={selectedPolygon}
          center={center}
          zoom={zoom}
          activeTab={activeTab}
          geofenceRadius={geofenceRadius}
          handleMapPolygonClick={handleMapPolygonClick}
          handlePolygonPathChange={handlePolygonPathChange}
          handlePolygonComplete={handlePolygonComplete}
          onMapLoad={onMapLoad}
          handleZoomChanged={handleZoomChanged}
          onCircleRadiusChanged={onCircleRadiusChanged}
          getPolygonOptions={getPolygonOptions}
          getPolygonCenter={getPolygonCenter}
          calculateNetArea={calculateNetArea}
          registerPolygonRef={registerPolygonRef}
          circleRef={circleRef}
          mapRef={mapRef}
          drawingManagerRef={drawingManagerRef}
          drawingToolType={drawingToolType}
          setDrawingToolType={setDrawingToolType}
          deleteSelectedVertex={deleteSelectedVertex}
          selectedVertex={selectedVertex}
          updateParent={handleUpdateParent}
        />
      </div>
      
      {/* Sidebar */}
      <div className={styles.sidebarContainer}>
        <Sidebar
          polygons={polygons}
          selectedPolygonIndex={selectedPolygon}
          editingPolygonIndex={drawingState.editingPolygonIndex}
          jobSite={jobSite}
          geofenceRadius={geofenceRadius}
          activeTab={activeTab}
          onStartDrawing={drawingActions.startDrawing}
          onOpenEditDialog={drawingActions.openEditDialog}
          onToggleVisibility={toggleVisibility}
          onSaveAreas={saveAreas}
          onSaveGeofenceArea={saveGeofenceArea}
          onUpdateGeofenceRadius={handleGeofenceChange}
          onTabChange={handleTabChange}
          onChangeSite={handleChangeSite}
          onSearchMCAssessor={handleSearchMCAssessor}
          calculateNetArea={calculateNetArea}
          clients={props.clients}
          url={props.url}
          handleMergeAreas={handleMergeAreas}
        />
      </div>
      
      {/* Area Dialog */}
      <DialogForm
        isOpen={drawingState.isDialogOpen && drawingState.dialogMode !== 'merge'}
        mode={drawingState.dialogMode}
        newArea={newArea}
        polygons={polygons}
        selectedPolygon={selectedPolygon}
        onInputChange={handleInputChange}
        onSave={drawingActions.saveArea}
        onContinueEditing={drawingActions.continueEditing}
        onClose={drawingActions.closeDialog}
        onCopy={handleCopyPolygon}
        onDelete={handleDeletePolygon}
        getPolygonArea={getPolygonArea}
      />
       <MergeDialog
        isOpen={drawingState.isDialogOpen && drawingState.dialogMode === 'merge'}
        polygons={polygons}
        onClose={drawingActions.closeDialog}
        onMerge={executeMerge}
      />
    </div>
  );
};

// New component for area merging
const MergeDialog = ({ isOpen, polygons, onClose, onMerge }) => {
  const [selectedAreas, setSelectedAreas] = useState([]);
  
  const handleToggleArea = (index) => {
    setSelectedAreas(prev => {
      if (prev.includes(index)) {
        return prev.filter(i => i !== index);
      } else {
        return [...prev, index];
      }
    });
  };
  
  const handleMerge = () => {
    onMerge(selectedAreas);
  };
  
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md">
      <DialogTitle>Merge Areas</DialogTitle>
      <DialogContent>
        <Typography paragraph>
          Select at least two areas to merge them into a single polygon.
        </Typography>
        
        <List>
          {polygons.filter(p => p.type !== 'propertyLine').map((polygon, index) => (
            <ListItem key={polygon.id} button onClick={() => handleToggleArea(index)}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selectedAreas.includes(index)}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText 
                primary={polygon.name} 
                secondary={`Type: ${polygon.polygonType}, Area: ${getPolygonArea(polygon.paths).areaFeet.toFixed(2)} sq ft`} 
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button 
          onClick={handleMerge} 
          color="primary"
          disabled={selectedAreas.length < 2}
        >
          Merge Selected Areas
        </Button>
      </DialogActions>
    </Dialog>
  );
};


// Set display name for debugging
MapWithDrawing.displayName = 'MapWithDrawing';

// Redux connection
const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    clients: state.clients
  };
};

export default connect(mapStateToProps)(React.memo(MapWithDrawing));