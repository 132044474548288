import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import { Card, CardContent, Typography } from '@material-ui/core'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import { fade, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import SearchIcon from '@material-ui/icons/Search';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { socket } from '../../SocketIo/Socket'
import { Link } from 'react-router-dom'

function desc(a, b, orderBy, isDate) {
  // console.log('desc: ', a)
  // console.log('desc b: ', b)
  if (isDate) {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      // console.log('B is < A')
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      // console.log('B is > A')
      return 1;
    }
    // console.log('B is ? A')
    // console.log('A: ', a[orderBy])
    // console.log('B: ', b[orderBy])
    return 0;
  } else {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      return 1;
    }
    return 0;
  }
}

function stableSort(array, cmp) {
// console.log('Stable sort: ', array)
// console.log('CMP: ', cmp)
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  if (orderBy==='lastUpdated') {
  // console.log('Last Updated')
    return order === 'desc' ? (a, b) => desc(a, b, orderBy, true) : (a, b) => -desc(a, b, orderBy, true);
  } else {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }
  
}

const headCells = [
  { id: 'Client Name', numeric: false, disablePadding: true, label: 'Client Name' },
  { id: 'PO #', numeric: false, disablePadding: true, label: 'PO #' },
  { id: 'Vendor', numeric: false, disablePadding: true, label: 'Vendor' },
  { id: 'Invoice Number', numeric: false, disablePadding: true, label: 'Invoice Number' },
  { id: 'Description', numeric: false, disablePadding: true, label: 'Description' },
  { id: 'Requestor', numeric: false, disablePadding: true, label: 'Requestor' },
  { id: 'Created Date', numeric: false, disablePadding: true, label: 'Created Date' },

];

function EnhancedTableHead(props) {
// console.log('Got our props', props)
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
  // console.log('Sort Property: ', property)
  // console.log('sort event; ', event)
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all leads' }}
          />
        </TableCell> */}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
                       <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {/* {orderBy === headCell.id ? (
                // <span className={classes.visuallyHidden}>
                //   {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                // </span>
              ) : null} */}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  sweetBorder: {
    border: '1px solid',
    borderRadius: '10px'
  },
  fakeLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue'
  },
  tableWrapper: {
    maxHeight: '55vh',
    overflow: 'scroll'
  }
}));

function currencyFormat(num) {
  if (!num) num = 0
  return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const getOurTotal = (data) => {
  // console.log('Get Total', data)
  const sum = data.reduce((accumulator, object) => {
    // console.log(object)
    if (object && object['Status'] === 'Paid') {
      return accumulator + object['Total Charge'];
    } else {
      return accumulator
    }
  }, 0);
  // console.log('Sum: ', sum)
  return sum
}

const PaymentDetailsReport = (props) => {
// console.log('Unreconciled POs', props)
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('-totalDue');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [searchValue, setsearchValue] = React.useState('');
  const [searchMatches, setsearchMatches] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [pdf, setPDF] = React.useState(null)
  const [filteredRows, setFilteredRows] = React.useState([]);
// console.log('Report props', props.data)
  const timeframe = props.data.timeframe
// console.log('Timeframe', timeframe)
  let start = null
// console.log(timeframe.start instanceof Date)
  if (timeframe.start instanceof Date) {
  // console.log('A date we should b egood')
    start = moment(timeframe.start).format('YYYY-MM-DD')
  } else {
  // console.log('Make a date')
    start = timeframe.start
  }
  let end = null
  if (timeframe.start instanceof Date) {
  // console.log('A date we should b egood')
    end = moment(timeframe.end).format('YYYY-MM-DD')
  } else {
  // console.log('Make a date')
    end = timeframe.end
  }
// console.log('Start', start)
// console.log('End: ', end)
  React.useEffect(() => {
  // console.log('Set our Unreconciled pos: ', props)
    if (props.data && props.data.data && props.data.data.length) {
      setRows(props.data.data)
      setFilteredRows(props.data.data)
    }

  }, [props.data])

  // React.useEffect(() => {
  //   socket.on('acctBalanceReport', function (data) {
  //   // console.log('GOT A SOCKET acctBalanceReport: ', data)
  //     setRows(data)
  //     setFilteredRows(data)
  //   })

  //   return () => {
  //     socket.off('acctBalance')
  //     // socket.off('customerUpdate')
  //   }
  // }, [])
  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = 0// rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const filterOut = (r) => {
    // console.log('FIlter out: ', r)
    let string = searchValue
    let newArr = []
    r.filter(o => {
      // console.log('Object:  ', o)
      Object.keys(o).some(k => {
        // console.log(o[k])
        if(o[k]) {
          // console.log('OK: ', o[k])
          let str = o[k].toString().toLowerCase() 
          // console.log(str)
          if (str.includes(string.toLowerCase())) {
            // console.log('Mathc')
            return newArr.push(o)
          } else {
            return null
          }
        } else {
          return null
        }
      })
      // return newArr
    })
    // return newArr
    setFilteredRows(newArr)
    // setsearchMatches(newArr.length)
    // let newArr = r.filter(i => {
    //   if (i.includes('test')) return i
    // })
    // return newArr
    // return r.filter((obj)=>{
    //   return Object.keys(obj).reduce((acc, curr)=>{
    //         return acc || obj[curr] ? obj[curr].toString().toLowerCase().includes(string) : obj[curr];
    //   }, false);
    // })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = (name) => (event) => {
  // console.log('Searching: ', event.target.value)
    setsearchValue(event.target.value)
    if (event.target.value === '') {
      setFilteredRows(rows)
    } else {
      filterOut(rows, event.target.value)
    }
    setPage(0)
  }

  function downloadFile(absoluteUrl) {
    var link = document.createElement('a');
    link.href = absoluteUrl;
    link.download = 'true';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // delete link;
 };

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
  // console.log('Set order by property: ' + property + ' in ' + isDesc + ' order')
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n._id);
      // console.log(newSelecteds)
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleExport = async () => {
  // console.log('Handle Export')
    // downloadFile(`${props.url}/api/reports/accountBalanceToCSV`)
    const response = await axios.get(`${props.url}/api/purchaseorders/generateUnreconciledPOPDF`)
    if (response && response.data) {
    // console.log('PDF Export', response.data)
      setPDF(response.data)
    }
  }

  return (
      <Card style={{margin: '5px'}}>
        <CardContent>
          <Grid container>
            <Grid item lg={6}>
            <Typography variant="h5" component="h2" gutterBottom>
            Unreconciled Purchase Order Report:
          </Typography>
            </Grid>
            <Grid item lg={6}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<ImportExportIcon />}
                onClick={handleExport}
              >
                Export Report
              </Button>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              Start Date: {moment(start).format('MM/DD/YYYY')}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              End Date: {moment(end).format('MM/DD/YYYY')}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              Total POs: {rows.length}
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {
              pdf ? <object style={{padding: '15px'}} aria-label="PDF" data={ pdf } type="application/pdf" width="100%" height="860" /> : null
            }
          </Grid>
          
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size='small'
            aria-label="enhanced table"
            stickyHeader
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody 
              >
              {stableSort(filteredRows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                // console.log('Row map: ', row)
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  // setsearchMatches(rows.length)
                  // console.log(row)
                  return (
                    <TableRow
                      hover
                      // onClick={event => handleClick(event, row.number)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                      style ={ index % 2? { background : "#ddd" }:{ background : "#fff" }}
                    >
                      <TableCell component="td" id={labelId} scope="row" padding="none">


                        <Link 
                      style={{pointer: 'cursor'}}
                      target="_blank"
                      to={{
                            pathname: '/clients/' + row['Client ID'],
                            // search: '?sort=name',
                            // hash: '#the-hash',
                            // state:? {customer: client, stuff: 'Search Engine'}
                          }}> { row['Client'] || 'Un-Named Client' }
                          
                        </Link> 

                      </TableCell>
                      <TableCell component="td" id={labelId} scope="row" padding="none">
                        
                        <Link 
                          style={{pointer: 'cursor'}}
                          target="_blank"
                          to={{
                                pathname: '/purchaseorders?id=' + row['ID'],
                                // search: '?sort=name',
                                // hash: '#the-hash',
                                // state:? {customer: client, stuff: 'Search Engine'}
                              }}> {row['PO #'] ||  ''}
                              
                            </Link> 
                      </TableCell>  
                      <TableCell component="td" id={labelId} scope="row" padding="none">
                        {row['Vendor'] ||  ''}
                      </TableCell>
                      <TableCell component="td" id={labelId} scope="row" padding="none">
                        {row['Invoice Number'] ||  ''}
                      </TableCell>
                      <TableCell component="td" id={labelId} scope="row" padding="none">
                        {row['Description'] ||  ''}
                      </TableCell>
                      <TableCell component="td" id={labelId} scope="row" padding="none">
                      {row['Requestor'] || ''} 
                      </TableCell>  
                      <TableCell component="td" id={labelId} scope="row" padding="none">
                        {row['Created Date']}
                      </TableCell>  
      
                    </TableRow>
                  );
                })
              }
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50, 100, 500, 1000, 1500]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        </CardContent>
      </Card>
  )
}

  const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(PaymentDetailsReport)
