import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Chip,
  CircularProgress,
  IconButton,
  Tooltip,
  Box
} from '@material-ui/core';
import { Person, NavigateNextOutlined, NavigateBeforeOutlined, CloseOutlined } from '@material-ui/icons';
import AssignmentConfirmationDialog from './AssignmentConfirmationDialog';
import { isEven } from '../../../services/common';
import moment from 'moment';

const convertMinutesToHours = (mins) => {
  if (!mins) mins = 0;
  let hours = mins / 60;
  hours = Math.round(hours * 100) / 100;
  return hours;
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative', // For absolute positioning of footer
    paddingBottom: '50px', // Reserve space for footer
  },
  colorBar: {
    height: '38px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
  },
  crewInfo: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 0,
  },
  crewName: {
    marginLeft: theme.spacing(1),
    color: '#fff',
    fontWeight: 'bold',
  },
  navControls: {
    display: 'flex',
    alignItems: 'center',
  },
  navButton: {
    color: '#fff',
    padding: 8,
  },
  dateInfo: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0.5, 1),
    backgroundColor: theme.palette.grey[100],
    display: 'flex',
    justifyContent: 'space-between',
  },
  statsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    padding: theme.spacing(0.5, 0),
    backgroundColor: '#eee',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  compactStatsContainer: {
    padding: 0,
    margin: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  stopsList: {
    flex: 1,
    overflow: 'auto',
    padding: 0,
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
    scrollbarWidth: 'thin',
  },
  compactStopItem: {
    padding: theme.spacing(0.5, 1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  highlightedStop: {
    backgroundColor: theme.palette.action.selected,
  },
  loadingContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  infoItem: {
    padding: theme.spacing(0.5),
    textAlign: 'center',
  },
  infoValue: {
    fontSize: '0.9rem',
    fontWeight: 600,
    lineHeight: 1.2,
  },
  infoLabel: {
    fontSize: '0.6rem',
    lineHeight: 1,
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
  },
  footer: {
    position: 'absolute',
    bottom: 5,
    left: 0,
    right: 0,
    padding: theme.spacing(0.5),
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#fff',
    // paddingBottom: 5,
    zIndex: 2,
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  smallChip: {
    height: 20,
    fontSize: '0.6rem',
  },
  miniButton: {
    minWidth: 'unset',
    padding: theme.spacing(0, 1),
    fontSize: '0.75rem',
    height: 24,
  },
  stopSecondary: {
    marginTop: 0,
  },
  unassignButton: {
    marginRight: -8,
  }
}));

const InfoItem = ({ label, value }) => {
  const classes = useStyles();
  return (
    <div className={classes.infoItem}>
      <Typography className={classes.infoValue}>{value}</Typography>
      <Typography className={classes.infoLabel}>{label}</Typography>
    </div>
  );
};

const SelectedCrewDetails = ({
  crew,
  stops,
  date,
  activeMarker,
  jobSiteDetails,
  handleUnassignment,
  handleAssignToCrew,
  crews,
  markers,
  activeRouteDetails,
  handleClickOnScheduledSite,
  highlightedSite,
  crewRoute,
  weeklyRoutes,
  loading,
  handleChangeCrew,
  closeCrewDetails
}) => {
  const classes = useStyles();
  const [isAssignmentDialogOpen, setIsAssignmentDialogOpen] = useState(false);

  const openAssignmentDialog = () => setIsAssignmentDialogOpen(true);
  const closeAssignmentDialog = () => setIsAssignmentDialogOpen(false);
  const handleConfirmAssignment = (assignmentDetails) => handleAssignToCrew(assignmentDetails);

  let crewStops = [];
  
  if (crewRoute?.stops?.length) {
    let crewRouteStops = [];
    crewRoute.stops.forEach(routeStop => {
      let associatedStop = stops.find(stopItem => (stopItem.siteId === routeStop.siteId));
      
      if (associatedStop?._id) {
        crewRouteStops.push(associatedStop);
      } else {
        associatedStop = markers.find(stopItem => (stopItem._id === routeStop.siteId));
        
        if (associatedStop?._id) {
          let mergedItem = {
            ...associatedStop,
            ...routeStop
          };
          crewRouteStops.push(mergedItem);
        }
      }
    });
    crewStops = crewRouteStops;
  }
  
  crewStops = crewStops.sort((a, b) => a.order - b.order);
  
  // Calculate hours and stats
  const currentTotalTime = Number(crewRoute?.totalDriveTime || 0) + Number(crewRoute?.totalServiceTime || 0);
  const hoursWithoutThisJob = convertMinutesToHours(currentTotalTime);
  const milesWithoutThisJob = crewRoute?.totalMilesDriven || 0;
  
  let totalHoursWDriveTime = (activeRouteDetails?.totalServiceTime && activeRouteDetails?.totalDriveTime) 
    ? convertMinutesToHours(Number(activeRouteDetails.totalServiceTime) + Number(activeRouteDetails.totalDriveTime)) 
    : 0;
    
  const withThisJobHours = totalHoursWDriveTime || 0;
  
  // Calculate weekly hours
  function calculateWeeklyHours(weeklyRoutes, crewId) {
    let weeklyHours = {
      serviceTime: 0,
      driveTime: 0
    };

    if (weeklyRoutes && Array.isArray(weeklyRoutes)) {
      weeklyRoutes.forEach(dayRoute => {
        if (dayRoute.routes && Array.isArray(dayRoute.routes)) {
          dayRoute.routes.forEach(route => {
            if (crewId === route.crewId) {
              const serviceTime = route.totalServiceTime / 60;
              const driveTime = route.totalDriveTime / 60;
              weeklyHours.serviceTime += serviceTime;
              weeklyHours.driveTime += driveTime;
            }
          });
        }
      });
    }
    
    return weeklyHours;
  }
  
  const crewWeeklyHours = calculateWeeklyHours(weeklyRoutes, crew._id);
  const withThisJobWeeklyHours = crewWeeklyHours?.serviceTime + Number(activeMarker?.estimatedHours || 0);
  const withThisJobWeeklyHoursIncludingDriveTime = crewWeeklyHours?.driveTime + Number(withThisJobWeeklyHours);
  
  const dayOfWeek = moment(date).format('dddd');
  let weekOfTheYear = moment(date).week();
  let evenWeek = isEven(weekOfTheYear);
  let thisWeek = evenWeek ? 'Even' : 'Odd';

  return (
    <Paper className={classes.root} elevation={3}>
      {/* Crew Header with Color */}
      <div className={classes.colorBar} style={{ backgroundColor: crew.color }}>
        <div className={classes.crewInfo}>
          <Person style={{ color: crew.textColor }} />
          <Typography variant="subtitle1" className={classes.crewName} style={{ color: crew.textColor }}>
            {crew.name}
          </Typography>
        </div>
        <div className={classes.navControls}>
          <Tooltip title="Previous Crew">
            <IconButton size="small" onClick={() => handleChangeCrew('prev')} className={classes.navButton}>
              <NavigateBeforeOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="Next Crew">
            <IconButton size="small" onClick={() => handleChangeCrew('next')} className={classes.navButton}>
              <NavigateNextOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="Close">
            <IconButton size="small" onClick={closeCrewDetails} className={classes.navButton}>
              <CloseOutlined />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      
      {/* Date Info */}
      <div className={classes.dateInfo}>
        <Typography variant="caption">
          {date.toLocaleDateString()} ({dayOfWeek} - {thisWeek})
        </Typography>
        <Typography variant="caption">
          {crewStops.length} Stops
        </Typography>
      </div>

      {/* Loading State */}
      {loading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress style={{ color: crew.color }} size={40} />
        </div>
      ) : (
        <>
          {/* Statistics */}
          <div className={classes.statsContainer}>
            <div className={classes.compactStatsContainer}>
              <InfoItem label="Hours" value={Math.round(hoursWithoutThisJob * 100) / 100} />
              <InfoItem label="Miles" value={Math.round(milesWithoutThisJob * 100) / 100} />
              <InfoItem label="Weekly Hours" value={Math.round((crewWeeklyHours.serviceTime + crewWeeklyHours.driveTime) * 100) / 100} />
            </div>
          </div>
          
          {/* New Job Stats (When Applicable) */}
          {activeMarker?.isUnassigned && (
            <div className={classes.statsContainer}>
              <div className={classes.compactStatsContainer}>
                <InfoItem label="With Job" value={Math.round(withThisJobHours * 100) / 100} />
                <InfoItem label="Week Total" value={Math.round(withThisJobWeeklyHoursIncludingDriveTime * 100) / 100} />
                <InfoItem label="New Miles" value={Math.round((activeRouteDetails?.totalMilesDriven || 0) * 100) / 100} />
              </div>
            </div>
          )}
          
          {/* Stops List */}
          <List className={classes.stopsList} disablePadding>
            {crewStops.map((site, index) => (
              <ListItem
                key={index}
                button
                dense
                disableGutters
                onClick={() => handleClickOnScheduledSite(site)}
                className={`${classes.compactStopItem} ${highlightedSite === site._id ? classes.highlightedStop : ''}`}
              >
                <ListItemText
                  primary={site.name}
                  secondary={
                    <Box component="span" className={classes.stopSecondary}>
                      {site.address?.length > 25 ? `${site.address.substring(0, 25)}...` : site.address}
                      <span> • {site.estimatedHours}hrs</span>
                      <br />
                      <Chip
                        size="small"
                        className={classes.smallChip}
                        label={`${site.frequency}${site.frequency === 'Bi-Weekly' ? ` ${site.evenOrOddWeeks}` : ''}`}
                        color="primary"
                      />
                    </Box>
                  }
                  primaryTypographyProps={{ variant: 'body2' }}
                  secondaryTypographyProps={{ variant: 'caption' }}
                />
                <ListItemSecondaryAction className={classes.unassignButton}>
                  <Button
                    size="small"
                    variant="outlined"
                    className={classes.miniButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleUnassignment(site);
                    }}
                  >
                    Un-Assign
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          
          {/* Footer with Assign Button */}
          {activeMarker?._id && (
            <div className={classes.footer}>
              <Button
                variant="contained"
                color="primary"
                onClick={openAssignmentDialog}
                fullWidth
              >
                Assign Job
              </Button>
            </div>
          )}
        </>
      )}

      <AssignmentConfirmationDialog
        open={isAssignmentDialogOpen}
        onClose={closeAssignmentDialog}
        crew={crew}
        site={activeMarker}
        selectedDate={date}
        onConfirm={handleConfirmAssignment}
        jobSiteDetails={jobSiteDetails}
        markers={markers}
      />
    </Paper>
  );
};

export default SelectedCrewDetails;
