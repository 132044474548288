import React, { useState, useEffect } from "react";
import { 
  Grid, 
  Paper, 
  Typography, 
  IconButton, 
  TextField, 
  MenuItem, 
  Button,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  Chip,
  Divider,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  makeStyles
} from '@material-ui/core';
import { 
  Delete as DeleteIcon, 
  Add as AddIcon,
  Search as SearchIcon,
  FilterList as FilterListIcon,
  Bookmarks as BookmarksIcon
} from "@material-ui/icons";
import axios from "axios";
import { connect } from 'react-redux';

const NHTSA_BASE_URL = "https://vpic.nhtsa.dot.gov/api/vehicles";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  vehicleItem: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1, 2)
  },
  vehicleList: {
    maxHeight: 300,
    overflow: 'auto',
    marginTop: theme.spacing(2)
  },
  yearRangeField: {
    marginLeft: theme.spacing(1),
    width: 80
  },
  chip: {
    margin: theme.spacing(0.5)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  },
  searchInput: {
    flex: 1
  },
  yearRangeContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  filterButton: {
    marginLeft: theme.spacing(1)
  },
  savedVehicleButton: {
    marginLeft: theme.spacing(1)
  },
  vehicleCount: {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  noVehicles: {
    textAlign: 'center',
    padding: theme.spacing(3),
    color: theme.palette.text.secondary
  },
  loadingMessage: {
    textAlign: 'center',
    padding: theme.spacing(1),
    color: theme.palette.text.secondary
  }
}));

// Utility for creating a range of years
const createYearRange = (start, end) => {
  return Array.from({ length: end - start + 1 }, (_, i) => start + i);
};

// API request function - this is the same as in your original code
const apiRequest = async (query, url) => {
  console.log('Make the api request...', query);
  try {
    const resp = await axios.post(`${url}/api/equipment/nhtsaapirequest`, query);
    console.log('Api Request results...', resp);
    return resp;
  } catch (e) {
    console.log('Error making api request...', e);
    return { data: [] };
  }
};

const EnhancedCompatibleVehicles = ({ url, notification, inventoryItem, setCompatibleVehicles }) => {
  const classes = useStyles();
  const [selectedYearRange, setSelectedYearRange] = useState("single");
  const [yearStart, setYearStart] = useState("");
  const [yearEnd, setYearEnd] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [savedVehiclesDialogOpen, setSavedVehiclesDialogOpen] = useState(false);
  const [savedVehicles, setSavedVehicles] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  
  // Generate years (from 1980 to current year)
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 1979 }, (_, i) => 1980 + i).reverse();
  
  // Filter vehicles based on search query
  const filteredVehicles = inventoryItem?.compatibleVehicles?.filter(vehicle => {
    if (!searchQuery) return true;
    const query = searchQuery.toLowerCase();
    return (
      vehicle.year?.toString()?.includes(query) || 
      vehicle.make?.toLowerCase()?.includes(query) || 
      vehicle.model?.toLowerCase()?.includes(query)
    );
  });

  // Fetch saved vehicle templates from your backend - right now using mock data
  useEffect(() => {
    // In a real implementation, you would fetch these from your backend
    // Example: axios.get(`${url}/api/equipment/savedVehicleTemplates`)
    const mockSavedVehicles = [
      { id: 1, name: "Common Fleet Vehicles", vehicles: [
        { year: 2020, make: "Ford", model: "F-150" },
        { year: 2020, make: "Chevrolet", model: "Silverado" },
        { year: 2020, make: "RAM", model: "1500" }
      ]},
      { id: 2, name: "Service Vans", vehicles: [
        { year: 2019, make: "Ford", model: "Transit" },
        { year: 2019, make: "Chevrolet", model: "Express" },
        { year: 2019, make: "Mercedes-Benz", model: "Sprinter" }
      ]}
    ];
    setSavedVehicles(mockSavedVehicles);
  }, [url]);

  // Fetch Makes by Year - uses your existing API
  const fetchMakesByYear = async (year) => {
    console.log('Fetch Makes by year...', year);
    setIsLoading(true);
    
    try {
      const resp = await apiRequest(
        {
          type: 'makesByYear',
          year: year
        },
        url
      );
      
      if (resp?.data?.length) {
        setMakes(resp.data);
      } else {
        setMakes([]);
      }
    } catch (error) {
      console.error('Error fetching makes:', error);
      setMakes([]);
      if (notification) {
        notification('error', 'Failed to fetch vehicle makes. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch Makes for a range of years
  const fetchMakesByYearRange = async (startYear, endYear) => {
    console.log('Fetch Makes by year range...', startYear, endYear);
    setIsLoading(true);
    
    try {
      // For a year range, we'll fetch makes for the startYear as a simplification
      // In a real implementation, you might want to modify your API to handle ranges
      // or combine results from multiple years
      const resp = await apiRequest(
        {
          type: 'makesByYear',
          year: startYear
        },
        url
      );
      
      if (resp?.data?.length) {
        setMakes(resp.data);
      } else {
        setMakes([]);
      }
    } catch (error) {
      console.error('Error fetching makes for year range:', error);
      setMakes([]);
      if (notification) {
        notification('error', 'Failed to fetch vehicle makes. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch Models by Make and Year - uses your existing API
  const fetchModelsByMakeAndYear = async (make, year) => {
    console.log('Fetch Models by make and year...', make, year);
    setIsLoading(true);
    
    try {
      const resp = await apiRequest(
        { 
          type: 'fetchModelsByMakeAndYear', 
          make, 
          year 
        },
        url
      );
      
      if (resp?.data?.models?.length) {
        // Use your existing data cleaning logic
        let cleanModels = [];
        resp.data.models.forEach(md => {
          let index = cleanModels.findIndex(it => (it.Model_ID === md.Model_ID));
          if (index < 0) cleanModels.push(md);
        });
        setModels(cleanModels);
      } else {
        setModels([]);
      }
    } catch (error) {
      console.error('Error fetching models:', error);
      setModels([]);
      if (notification) {
        notification('error', 'Failed to fetch vehicle models. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch Makes when Year is selected
  useEffect(() => {
    if (selectedYearRange === "single" && !yearStart) return;
    if (selectedYearRange === "range" && (!yearStart || !yearEnd)) return;
    
    if (selectedYearRange === "single") {
      fetchMakesByYear(yearStart);
    } else {
      fetchMakesByYearRange(yearStart, yearEnd);
    }
    
    setMake("");
    setModel("");
    setModels([]);
  }, [yearStart, yearEnd, selectedYearRange]);

  // Fetch Models when Make and Year are selected
  useEffect(() => {
    if (!make || !yearStart) return;
    
    // For a year range, we'll fetch models for the startYear as a simplification
    fetchModelsByMakeAndYear(make, yearStart);
  }, [make, yearStart]);

  // Add compatible vehicle
  const addCompatibleVehicle = () => {
    if (selectedYearRange === "single") {
      if (!yearStart || !make || !model) return;
      
      // Add a single year vehicle
      const newVehicle = { year: yearStart, make, model };
      
      // Check if this vehicle already exists to avoid duplicates
      const exists = (inventoryItem?.compatibleVehicles || []).some(
        v => v.year === newVehicle.year && v.make === newVehicle.make && v.model === newVehicle.model
      );
      
      if (exists) {
        if (notification) {
          notification('warning', 'This vehicle is already in the list.');
        }
        return;
      }
      
      setCompatibleVehicles([...inventoryItem?.compatibleVehicles || [], newVehicle]);
    } else {
      if (!yearStart || !yearEnd || !make || !model) return;
      
      // Add multiple vehicles for the year range
      const yearsArray = createYearRange(parseInt(yearStart), parseInt(yearEnd));
      const newVehicles = yearsArray.map(year => ({ year, make, model }));
      
      // Filter out any vehicles that already exist
      const currentVehicles = inventoryItem?.compatibleVehicles || [];
      const uniqueNewVehicles = newVehicles.filter(newVeh => 
        !currentVehicles.some(currVeh => 
          currVeh.year === newVeh.year && 
          currVeh.make === newVeh.make && 
          currVeh.model === newVeh.model
        )
      );
      
      if (uniqueNewVehicles.length === 0) {
        if (notification) {
          notification('warning', 'All vehicles in this range are already in the list.');
        }
        return;
      }
      
      setCompatibleVehicles([...currentVehicles, ...uniqueNewVehicles]);
    }
    
    // Reset form
    setYearStart("");
    setYearEnd("");
    setMake("");
    setModel("");
    setMakes([]);
    setModels([]);
  };

  // Remove a compatible vehicle
  const removeCompatibleVehicle = (index) => {
    setCompatibleVehicles(
      (inventoryItem?.compatibleVehicles || []).filter((_, i) => i !== index)
    );
  };

  // Add all vehicles from a saved group
  const addSavedVehicleGroup = (group) => {
    if (!group?.vehicles?.length) return;
    
    // Check for duplicates and add only new vehicles
    const currentVehicles = inventoryItem?.compatibleVehicles || [];
    const newVehicles = group.vehicles.filter(newVeh => 
      !currentVehicles.some(currVeh => 
        currVeh.year === newVeh.year && 
        currVeh.make === newVeh.make && 
        currVeh.model === newVeh.model
      )
    );
    
    if (newVehicles.length === 0) {
      if (notification) {
        notification('warning', 'All vehicles in this group are already in the list.');
      }
    } else {
      setCompatibleVehicles([...currentVehicles, ...newVehicles]);
      if (notification) {
        notification('success', `Added ${newVehicles.length} vehicles from "${group.name}".`);
      }
    }
    
    setSavedVehiclesDialogOpen(false);
  };

  // Group vehicles by make for easier browsing
  const groupVehiclesByMake = () => {
    const groups = {};
    
    (filteredVehicles || []).forEach(vehicle => {
      if (!groups[vehicle.make]) {
        groups[vehicle.make] = [];
      }
      groups[vehicle.make].push(vehicle);
    });
    
    return groups;
  };

  const vehicleGroups = groupVehiclesByMake();
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Typography variant="h6" gutterBottom>Compatible Vehicles</Typography>
          
          {/* Search and Filter Bar */}
          {inventoryItem?.compatibleVehicles?.length > 0 && (
            <div className={classes.searchBox}>
              <TextField
                className={classes.searchInput}
                label="Search vehicles"
                variant="outlined"
                size="small"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  startAdornment: <SearchIcon color="action" style={{ marginRight: 8 }} />
                }}
              />
              <Tooltip title="Saved vehicle templates">
                <Button 
                  variant="outlined" 
                  color="primary" 
                  className={classes.savedVehicleButton}
                  onClick={() => setSavedVehiclesDialogOpen(true)}
                >
                  <BookmarksIcon />
                </Button>
              </Tooltip>
            </div>
          )}
          
          <Typography variant="subtitle1" gutterBottom>
            Add Compatible Vehicle
            {filteredVehicles?.length > 0 && (
              <span className={classes.vehicleCount}>
                ({filteredVehicles.length} vehicles added)
              </span>
            )}
          </Typography>
          
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="year-range-type-label">Year Selection</InputLabel>
                <Select
                  labelId="year-range-type-label"
                  value={selectedYearRange}
                  onChange={(e) => setSelectedYearRange(e.target.value)}
                  label="Year Selection"
                >
                  <MenuItem value="single">Single Year</MenuItem>
                  <MenuItem value="range">Year Range</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} sm={3}>
              {selectedYearRange === "single" ? (
                <TextField
                  select
                  label="Year"
                  value={yearStart}
                  onChange={(e) => setYearStart(e.target.value)}
                  fullWidth
                  variant="outlined"
                >
                  {years.map((yr) => (
                    <MenuItem key={yr} value={yr}>{yr}</MenuItem>
                  ))}
                </TextField>
              ) : (
                <div className={classes.yearRangeContainer}>
                  <TextField
                    select
                    label="From"
                    value={yearStart}
                    onChange={(e) => {
                      const value = e.target.value;
                      setYearStart(value);
                      // Ensure yearEnd is not less than yearStart
                      if (yearEnd && parseInt(yearEnd) < parseInt(value)) {
                        setYearEnd(value);
                      }
                    }}
                    fullWidth
                    variant="outlined"
                  >
                    {years.map((yr) => (
                      <MenuItem key={yr} value={yr}>{yr}</MenuItem>
                    ))}
                  </TextField>
                  
                  <TextField
                    select
                    label="To"
                    value={yearEnd}
                    onChange={(e) => setYearEnd(e.target.value)}
                    className={classes.yearRangeField}
                    variant="outlined"
                    disabled={!yearStart}
                  >
                    {years
                      .filter(yr => yr >= parseInt(yearStart || 0))
                      .map((yr) => (
                        <MenuItem key={yr} value={yr}>{yr}</MenuItem>
                      ))
                    }
                  </TextField>
                </div>
              )}
            </Grid>
            
            <Grid item xs={12} sm={3}>
              {isLoading && yearStart ? (
                <TextField
                  label="Loading Makes..."
                  variant="outlined"
                  fullWidth
                  disabled
                />
              ) : (
                <TextField
                  select
                  label="Make"
                  value={make}
                  onChange={(e) => setMake(e.target.value)}
                  fullWidth
                  variant="outlined"
                  disabled={selectedYearRange === "single" ? !yearStart : (!yearStart || !yearEnd) || makes.length === 0}
                >
                  <MenuItem value="">
                    <em>Select a make</em>
                  </MenuItem>
                  {makes.map((m) => (
                    <MenuItem key={m.MakeId} value={m?.MakeName}>{m?.MakeName}</MenuItem>
                  ))}
                </TextField>
              )}
            </Grid>
            
            <Grid item xs={12} sm={2}>
              {isLoading && make ? (
                <TextField
                  label="Loading Models..."
                  variant="outlined"
                  fullWidth
                  disabled
                />
              ) : (
                <TextField
                  select
                  label="Model"
                  value={model}
                  onChange={(e) => setModel(e.target.value)}
                  fullWidth
                  variant="outlined"
                  disabled={!make || models.length === 0}
                >
                  <MenuItem value="">
                    <em>Select a model</em>
                  </MenuItem>
                  {models.map((m) => (
                    <MenuItem key={m.Model_ID} value={m?.Model_Name}>{m?.Model_Name}</MenuItem>
                  ))}
                </TextField>
              )}
            </Grid>
            
            <Grid item xs={12} sm={1}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={addCompatibleVehicle}
                disabled={
                  isLoading ||
                  (selectedYearRange === "single" 
                    ? (!yearStart || !make || !model)
                    : (!yearStart || !yearEnd || !make || !model))
                }
                style={{ height: 56 }}
              >
                <AddIcon />
              </Button>
            </Grid>
          </Grid>
          
          {isLoading && (
            <Typography variant="body2" className={classes.loadingMessage}>
              Loading data from NHTSA database...
            </Typography>
          )}
          
          {/* Display compatible vehicles */}
          {filteredVehicles?.length > 0 ? (
            <div className={classes.vehicleList}>
              {Object.keys(vehicleGroups).sort().map(makeName => (
                <div key={makeName}>
                  <Typography variant="subtitle2" gutterBottom style={{ marginTop: 16 }}>
                    {makeName} ({vehicleGroups[makeName].length})
                  </Typography>
                  
                  <Grid container spacing={1}>
                    {vehicleGroups[makeName].map((vehicle, idx) => {
                      const globalIndex = filteredVehicles.findIndex(
                        v => v.year === vehicle.year && v.make === vehicle.make && v.model === vehicle.model
                      );
                      
                      return (
                        <Grid item xs={12} sm={6} md={4} key={`${vehicle.make}-${vehicle.model}-${vehicle.year}-${idx}`}>
                          <Paper variant="outlined" className={classes.vehicleItem}>
                            <Grid container justify="space-between" alignItems="center">
                              <Grid item>
                                <Typography variant="body2">
                                  {vehicle.year} {vehicle.make} {vehicle.model}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <IconButton 
                                  size="small" 
                                  onClick={() => removeCompatibleVehicle(globalIndex)}
                                  aria-label="remove vehicle"
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              ))}
            </div>
          ) : (
            <Paper variant="outlined" className={classes.noVehicles}>
              <Typography>No compatible vehicles added yet.</Typography>
            </Paper>
          )}
        </Paper>
      </Grid>
      
      {/* Saved Vehicles Dialog */}
      <Dialog
        open={savedVehiclesDialogOpen}
        onClose={() => setSavedVehiclesDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Saved Vehicle Templates</DialogTitle>
        <DialogContent>
          <List>
            {savedVehicles.length > 0 ? (
              savedVehicles.map(group => (
                <div key={group.id}>
                  <ListItem>
                    <ListItemText
                      primary={group.name}
                      secondary={`${group.vehicles.length} vehicles`}
                    />
                    <ListItemSecondaryAction>
                      <Button 
                        color="primary" 
                        variant="contained" 
                        size="small"
                        onClick={() => addSavedVehicleGroup(group)}
                      >
                        Add All
                      </Button>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Box pl={2} pr={2} pb={1}>
                    {group.vehicles.slice(0, 3).map((vehicle, idx) => (
                      <Chip
                        key={idx}
                        label={`${vehicle.year} ${vehicle.make} ${vehicle.model}`}
                        size="small"
                        className={classes.chip}
                      />
                    ))}
                    {group.vehicles.length > 3 && (
                      <Chip
                        label={`+${group.vehicles.length - 3} more`}
                        size="small"
                        variant="outlined"
                        className={classes.chip}
                      />
                    )}
                  </Box>
                  <Divider component="li" />
                </div>
              ))
            ) : (
              <Typography className={classes.noVehicles}>
                No saved vehicle templates available.
              </Typography>
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSavedVehiclesDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    perms: state.perms
  };
};

export default connect(mapStateToProps)(EnhancedCompatibleVehicles);
