// ===== CONSTANTS =====

// Polygon types
export const POLYGON_TYPES = {
  TURF: 'turf',
  ORNAMENTAL: 'ornamental',
  BARE_GROUND: 'bare_ground'
};

// Default colors for polygon types
export const DEFAULT_COLORS = {
  [POLYGON_TYPES.TURF]: '#00FF00', // Green
  [POLYGON_TYPES.ORNAMENTAL]: '#FFFF00', // Yellow
  [POLYGON_TYPES.BARE_GROUND]: '#FFA500' // Orange
};

// Drawing modes
export const DRAWING_MODES = {
  POLYGON: 'polygon',
  FREEHAND: 'freehand',
  RECTANGLE: 'rectangle',
  CIRCLE: 'circle'
};

// Default center coordinates
export const defaultCenter = {
  lat: 33.91023013848473,
  lng: -112.084847523098
};

// Map container style
export const containerStyle = {
  width: '100%',
  height: '100%'
};

// Map libraries
export const mapLibraries = ['drawing', 'geometry'];
