import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  IconButton,
  Typography,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
  FormHelperText,
  Chip,
  Divider
} from '@material-ui/core';
import {
  Delete as DeleteIcon,
  Add as AddIcon
} from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    minWidth: 600,
  },
  form: {
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2),
    },
  },
  rateSection: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius,
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  addButton: {
    marginTop: theme.spacing(2),
  },
  tableSection: {
    marginTop: theme.spacing(3),
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
  helperText: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  divider: {
    margin: theme.spacing(3, 0),
  }
}));

const DEFAULT_CALIBRATION_RATE = 20; // gal/acre
const SITE_OPTIONS = ['Ornamental/Right-of-Way', 'Turf', 'Bare Ground'];

const BlendModal = ({ open, onClose, availableChemicals, onSave }) => {
  const classes = useStyles();
  // const [selectedChemical, setSelectedChemical] = useState('');
  const [blendData, setBlendData] = useState({
    name: '',
    season: '',
    type: '',
    applicationAreas: [],
    chemicals: [],
    isHerbicide: true,
    // Spot application rates
    spotRates: [],
    // Broadcast application rates
    broadcastRates: [],
    calibrationRate: DEFAULT_CALIBRATION_RATE,
  });

  // Chemical being added
  const [currentChemical, setCurrentChemical] = useState({
    chemical: null,
    // Spot application
    spotRate: '',
    spotUnit: 'oz',
    spotPer: 'gal',
    // Broadcast application
    broadcastRate: '',
    broadcastUnit: 'oz',
    broadcastPer: 'acre',
  });


  // Adjust chemical rates when calibration rate changes
  useEffect(() => {
    if (blendData.application === 'Broadcast' && blendData.chemicals.length > 0) {
      const adjustedChemicals = blendData.chemicals.map(chem => {
        const originalRate = chem.originalRate || chem.rate;
        const adjustmentFactor = blendData.calibrationRate / DEFAULT_CALIBRATION_RATE;
        
        return {
          ...chem,
          originalRate: originalRate,
          rate: parseFloat((originalRate * adjustmentFactor).toFixed(2))
        };
      });

      setBlendData(prev => ({
        ...prev,
        chemicals: adjustedChemicals
      }));
    }
  }, [blendData.calibrationRate, blendData.application]);

  const resetForm = () => {
    setBlendData({
      name: '',
      season: '',
      type: '',
      application: '',
      chemicals: [],
      isHerbicide: true,
      calibrationRate: DEFAULT_CALIBRATION_RATE,
      applicationAreas: []
    });
    // setSelectedChemical('');
    // setChemicalRate('');
    // setUnit('oz');
    // setPer('gal');
  };
  
  const handleClose = () => {
    resetForm();
    onClose();
  };

  const addChemical = () => {
    if (!currentChemical.chemical) return;
    if (currentChemical.spotRate && !isValidRate(currentChemical.spotRate)) {
      alert('Invalid spot rate. Must be greater than 0.');
      return;
    }
    if (currentChemical.broadcastRate && !isValidRate(currentChemical.broadcastRate)) {
      alert('Invalid broadcast rate. Must be greater than 0.');
      return;
    }
    const newChemical = {
      chemicalId: currentChemical.chemical._id,
      name: currentChemical.chemical.name,
      spot: currentChemical.spotRate ? {
        rate: parseFloat(currentChemical.spotRate),
        unit: currentChemical.spotUnit,
        per: currentChemical.spotPer
      } : null,
      broadcast: currentChemical.broadcastRate ? {
        rate: parseFloat(currentChemical.broadcastRate),
        unit: currentChemical.broadcastUnit,
        per: currentChemical.broadcastPer
      } : null
    };

    setBlendData(prev => ({
      ...prev,
      chemicals: [...prev.chemicals, newChemical]
    }));

    // Reset current chemical
    setCurrentChemical({
      chemical: null,
      spotRate: '',
      spotUnit: 'oz',
      spotPer: 'gal',
      broadcastRate: '',
      broadcastUnit: 'oz',
      broadcastPer: 'acre',
    });
  };

  const removeChemical = (index) => {
    setBlendData(prev => ({
      ...prev,
      chemicals: prev.chemicals.filter((_, i) => i !== index)
    }));
  };

  // const handleSave = () => {
  //   const mobileBlend = {
  //     name: `${blendData.season} ${blendData.type} Blend - ${blendData.name}`,
  //     isHerbicide: true,
  //     applicationAreas: blendData.applicationAreas,
  //     chemicals: blendData.chemicals,
  //     calibrationRate: blendData.calibrationRate
  //   };
  //   console.log('Save', mobileBlend)
  //   onSave(mobileBlend);
  //   handleClose();
  // };

  const isValidRate = (rate) => {
    const num = parseFloat(rate);
    return !isNaN(num) && num > 0;
  };
  
  const getValidationErrors = () => {
    const errors = [];
    
    if (!blendData.name) errors.push('Name is required');
    if (!blendData.season) errors.push('Season is required');
    if (!blendData.type) errors.push('Type is required');
    if (blendData.applicationAreas.length === 0) errors.push('At least one site is required');
    if (blendData.chemicals.length === 0) errors.push('At least one chemical is required');
    
    // Validate each chemical
    blendData.chemicals.forEach((chem, index) => {
      if (!chem.spot && !chem.broadcast) {
        errors.push(`Chemical ${index + 1} (${chem.name}) must have either spot or broadcast rate`);
      }
      if (chem.spot && !isValidRate(chem.spot.rate)) {
        errors.push(`Chemical ${index + 1} (${chem.name}) has invalid spot rate`);
      }
      if (chem.broadcast && !isValidRate(chem.broadcast.rate)) {
        errors.push(`Chemical ${index + 1} (${chem.name}) has invalid broadcast rate`);
      }
    });
  
    return errors;
  };

// Modify your save handler
const handleSave = async () => {
  const errors = getValidationErrors();
  if (errors.length > 0) {
    alert('Please fix the following errors:\n' + errors.join('\n'));
    return;
  }
  console.log('All blend data', blendData)
  const mobileBlend = {
    name: `${blendData.season} ${blendData.type} Blend - ${blendData.name}`,
    isHerbicide: true,
    applicationAreas: blendData.applicationAreas,
    chemicals: blendData.chemicals,
    calibrationRate: blendData.calibrationRate,
    season: blendData.season,
    type: blendData.type

  };
  console.log('Save our blend!!', mobileBlend)
  onSave(mobileBlend);
  handleClose();
};


  const isValid = () => {
    return (
      blendData.name &&
      blendData.season &&
      blendData.type &&
      blendData.applicationAreas.length > 0 &&
      blendData.chemicals.length > 0 &&
      blendData.chemicals.every(chem => chem.spot || chem.broadcast) // Must have at least one rate type
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      classes={{ paper: classes.dialogContent }}
    >
      <DialogTitle>Create Chemical Blend</DialogTitle>
      <DialogContent>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Blend Name"
                value={blendData.name}
                onChange={(e) => setBlendData(prev => ({ ...prev, name: e.target.value }))}
              />
            </Grid>
            
            <Grid item xs={12}>
              <Autocomplete
                multiple
                id="applicationAreas-select"
                options={SITE_OPTIONS}
                value={blendData.applicationAreas}
                onChange={(event, newValue) => {
                  setBlendData(prev => ({ ...prev, applicationAreas: newValue }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Application applicationAreas"
                    placeholder="Select applicationAreas"
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={option}
                      {...getTagProps({ index })}
                      className={classes.chip}
                    />
                  ))
                }
              />
            </Grid>
            
            <Grid item xs={6}>
              <TextField
                fullWidth
                select
                label="Season"
                value={blendData.season}
                onChange={(e) => setBlendData(prev => ({ ...prev, season: e.target.value }))}
              >
                <MenuItem value="Warm">Warm Season</MenuItem>
                <MenuItem value="Cold">Cold Season</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                select
                label="Type"
                value={blendData.type}
                onChange={(e) => setBlendData(prev => ({ ...prev, type: e.target.value }))}
              >
                <MenuItem value="Pre Emergent">Pre Emergent</MenuItem>
                <MenuItem value="Post">Post</MenuItem>
              </TextField>
            </Grid>
          </Grid>

          <Divider className={classes.divider} />

          <Typography variant="h6" gutterBottom>Add Chemicals</Typography>
          
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                id="chemical-select"
                options={availableChemicals}
                getOptionLabel={(option) => option.name}
                value={currentChemical.chemical}
                onChange={(event, newValue) => {
                  setCurrentChemical(prev => ({ ...prev, chemical: newValue }));
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Select Chemical" />
                )}
              />
            </Grid>
          </Grid>

          <div className={classes.rateSection}>
            <Typography variant="subtitle1" className={classes.sectionTitle}>
              Spot Spray Rate (Concentration)
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  type="number"
                  label="Rate"
                  value={currentChemical.spotRate}
                  onChange={(e) => setCurrentChemical(prev => ({ ...prev, spotRate: e.target.value }))}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  select
                  label="Unit"
                  value={currentChemical.spotUnit}
                  onChange={(e) => setCurrentChemical(prev => ({ ...prev, spotUnit: e.target.value }))}
                >
                  <MenuItem value="oz">oz</MenuItem>
                  <MenuItem value="gal">gal</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  select
                  label="Per"
                  value={currentChemical.spotPer}
                  onChange={(e) => setCurrentChemical(prev => ({ ...prev, spotPer: e.target.value }))}
                >
                  <MenuItem value="gal">per gal</MenuItem>
                  <MenuItem value="100gal">per 100 gal</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </div>

          <div className={classes.rateSection}>
            <Typography variant="subtitle1" className={classes.sectionTitle}>
              Broadcast Rate (Coverage)
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  type="number"
                  label="Rate"
                  value={currentChemical.broadcastRate}
                  onChange={(e) => setCurrentChemical(prev => ({ ...prev, broadcastRate: e.target.value }))}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  select
                  label="Unit"
                  value={currentChemical.broadcastUnit}
                  onChange={(e) => setCurrentChemical(prev => ({ ...prev, broadcastUnit: e.target.value }))}
                >
                  <MenuItem value="oz">oz</MenuItem>
                  <MenuItem value="gal">gal</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  select
                  label="Per"
                  value={currentChemical.broadcastPer}
                  onChange={(e) => setCurrentChemical(prev => ({ ...prev, broadcastPer: e.target.value }))}
                >
                  <MenuItem value="acre">per acre</MenuItem>
                  <MenuItem value="1000sqft">per 1000 sq ft</MenuItem>
                </TextField>
              </Grid>
            </Grid>

            <TextField
              fullWidth
              type="number"
              label="Calibration Rate (gal/acre)"
              value={blendData.calibrationRate}
              onChange={(e) => setBlendData(prev => ({
                ...prev,
                calibrationRate: parseFloat(e.target.value) || DEFAULT_CALIBRATION_RATE
              }))}
              helperText={`Default rate is ${DEFAULT_CALIBRATION_RATE} gal/acre. Adjust based on equipment calibration.`}
              style={{ marginTop: '16px' }}
            />
          </div>

          <Button
            variant="contained"
            color="primary"
            onClick={addChemical}
            disabled={!currentChemical.chemical || (!currentChemical.spotRate && !currentChemical.broadcastRate)}
            className={classes.addButton}
            startIcon={<AddIcon />}
          >
            Add Chemical
          </Button>

          {blendData.chemicals.length > 0 && (
            <div className={classes.tableSection}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Chemical</TableCell>
                      <TableCell>Spot Rate</TableCell>
                      <TableCell>Broadcast Rate</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {blendData.chemicals.map((chem, idx) => (
                      <TableRow key={idx}>
                        <TableCell>{chem.name}</TableCell>
                        <TableCell>
                          {chem.spot ? `${chem.spot.rate}${chem.spot.unit}/${chem.spot.per}` : '-'}
                        </TableCell>
                        <TableCell>
                          {chem.broadcast ? `${chem.broadcast.rate}${chem.broadcast.unit}/${chem.broadcast.per}` : '-'}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            size="small"
                            onClick={() => removeChemical(idx)}
                            className={classes.deleteButton}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="default">
          Cancel
        </Button>
        <Button 
          onClick={handleSave} 
          color="primary" 
          variant="contained"
          disabled={!isValid()}
        >
          Save Blend
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BlendModal;
