import React, { useState, useEffect } from 'react';
import { 
  makeStyles, 
  Card, 
  CardContent, 
  Typography, 
  Grid, 
  Chip, 
  Divider, 
  ExpansionPanel, 
  ExpansionPanelSummary, 
  ExpansionPanelDetails,
  IconButton,
  Tooltip,
  Paper,
  Box,
  Avatar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  RadioGroup,
  Radio,
  Snackbar
} from '@material-ui/core';
import { 
  ExpandMore as ExpandMoreIcon, 
  FilterList as FilterListIcon,
  LocalOffer as LocalOfferIcon,
  Eco as EcoIcon,
  Terrain as TerrainIcon,
  Opacity as OpacityIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Close as CloseIcon
} from '@material-ui/icons';
import axios from 'axios'
import { connect } from 'react-redux'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.grey[100],
    minHeight: '100vh'
  },
  header: {
    marginBottom: theme.spacing(3)
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: theme.shadows[8]
    }
  },
  cardContent: {
    flexGrow: 1
  },
  title: {
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center'
  },
  seasonWarm: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.contrastText,
    marginRight: theme.spacing(1)
  },
  seasonCold: {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.info.contrastText,
    marginRight: theme.spacing(1)
  },
  seasonAll: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.contrastText,
    marginRight: theme.spacing(1)
  },
  typeChip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  divider: {
    margin: theme.spacing(1, 0)
  },
  chemicalItem: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.default
  },
  rateInfo: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  rateType: {
    fontWeight: 500,
    marginRight: theme.spacing(1)
  },
  rateValue: {
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[200],
    fontSize: '0.85rem'
  },
  applicationArea: {
    margin: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText
  },
  filterContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  filterTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  },
  filterForm: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2)
  },
  formControl: {
    minWidth: 220,
    marginBottom: theme.spacing(2)
  },
  statusIcon: {
    fontSize: 'small',
    marginRight: theme.spacing(1)
  },
  avatar: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  editButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1)
  },
  dialogContent: {
    overflowY: 'auto',
    maxHeight: '80vh'
  },
  dialogPaper: {
    width: '100%',
    maxWidth: 800
  },
  chemicalSection: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    position: 'relative'
  },
  rateField: {
    width: 90,
    marginRight: theme.spacing(1)
  },
  unitField: {
    width: 80,
    marginRight: theme.spacing(1)
  },
  perField: {
    width: 110
  },
  removeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1)
  },
  actionButton: {
    margin: theme.spacing(1)
  }
}));

// Sample data - replace with your actual data fetching logic
const sampleBlends = [
  {
    _id: '1',
    name: 'Summer Weed Control',
    season: 'Warm',
    type: 'Pre Emergent',
    applicationAreas: ['Turf', 'Ornamental/Right-of-Way'],
    isHerbicide: true,
    calibrationRate: 25,
    active: true,
    createdByName: 'John Doe',
    chemicals: [
      {
        chemicalId: 'c1',
        name: 'Prodiamine 65 WDG',
        broadcast: {
          rate: 1.5,
          unit: 'oz',
          per: 'acre'
        }
      },
      {
        chemicalId: 'c2',
        name: 'Roundup Pro',
        spot: {
          rate: 2.5,
          unit: 'oz',
          per: '100gal'
        }
      }
    ]
  },
  {
    _id: '2',
    name: 'Winter Turf Treatment',
    season: 'Cold',
    type: 'Post',
    applicationAreas: ['Turf'],
    isHerbicide: true,
    calibrationRate: 20,
    active: true,
    createdByName: 'Jane Smith',
    chemicals: [
      {
        chemicalId: 'c3',
        name: 'Dithiopyr 40',
        broadcast: {
          rate: 2,
          unit: 'oz',
          per: '1000sqft'
        }
      }
    ]
  },
  {
    _id: '3',
    name: 'All-Season Bare Ground',
    season: 'All',
    type: 'All',
    applicationAreas: ['Bare Ground'],
    isHerbicide: true,
    calibrationRate: 30,
    active: false,
    createdByName: 'Robert Johnson',
    chemicals: [
      {
        chemicalId: 'c4',
        name: 'Glyphosate 41%',
        broadcast: {
          rate: 4,
          unit: 'gal',
          per: 'acre'
        }
      },
      {
        chemicalId: 'c5',
        name: 'Esplanade 200 SC',
        broadcast: {
          rate: 5,
          unit: 'oz',
          per: 'acre'
        }
      }
    ]
  }
];

const ChemicalBlendsList = (props) => {
  const classes = useStyles();
  const [blends, setBlends] = useState([]);
  const [filteredBlends, setFilteredBlends] = useState([]);
  const [filters, setFilters] = useState({
    season: '',
    type: '',
    applicationArea: '',
    active: ''
  });
  const [showFilters, setShowFilters] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [currentBlend, setCurrentBlend] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const fetchBlends = async () => {
      const resp = await axios.get(`${props.url}/api/mobileapp/getGenericInfo?type=Chemical_Blends`)
      console.log('245 resp.', resp)
      if (resp.data?.length) {
        setBlends(resp.data);
        setFilteredBlends(resp.data);
      }
    }
    fetchBlends()
  }, []);

  useEffect(() => {
    let result = [...blends];
    
    if (filters.season) {
      result = result.filter(blend => blend.season === filters.season);
    }
    
    if (filters.type) {
      result = result.filter(blend => blend.type === filters.type);
    }
    
    if (filters.applicationArea) {
      result = result.filter(blend => 
        blend.applicationAreas.includes(filters.applicationArea)
      );
    }
    
    if (filters.active !== '') {
      const isActive = filters.active === 'true';
      result = result.filter(blend => blend.active === isActive);
    }
    
    setFilteredBlends(result);
  }, [filters, blends]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const getSeasonClass = (season) => {
    switch(season) {
      case 'Warm': return classes.seasonWarm;
      case 'Cold': return classes.seasonCold;
      case 'All': return classes.seasonAll;
      default: return '';
    }
  };

  const getSeasonIcon = (season) => {
    switch(season) {
      case 'Warm': return '🌞';
      case 'Cold': return '❄️';
      case 'All': return '🌱';
      default: return '';
    }
  };

  const handleEditClick = (blend) => {
    setCurrentBlend(JSON.parse(JSON.stringify(blend))); // Deep copy to avoid reference issues
    setEditDialogOpen(true);
  };

  const handleDialogClose = () => {
    setEditDialogOpen(false);
    setCurrentBlend(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentBlend(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCurrentBlend(prev => ({
      ...prev,
      [name]: checked
    }));
  };

  const handleApplicationAreaChange = (area) => {
    let newAreas;
    if (currentBlend.applicationAreas.includes(area)) {
      newAreas = currentBlend.applicationAreas.filter(a => a !== area);
    } else {
      newAreas = [...currentBlend.applicationAreas, area];
    }
    setCurrentBlend(prev => ({
      ...prev,
      applicationAreas: newAreas
    }));
  };

  const handleChemicalChange = (index, field, value) => {
    const updatedChemicals = [...currentBlend.chemicals];
    updatedChemicals[index] = {
      ...updatedChemicals[index],
      [field]: value
    };
    setCurrentBlend(prev => ({
      ...prev,
      chemicals: updatedChemicals
    }));
  };

  const handleRateChange = (index, rateType, field, value) => {
    const updatedChemicals = [...currentBlend.chemicals];
    
    if (!updatedChemicals[index][rateType]) {
      updatedChemicals[index][rateType] = { rate: 0, unit: 'oz', per: 'acre' };
    }
    
    updatedChemicals[index][rateType] = {
      ...updatedChemicals[index][rateType],
      [field]: field === 'rate' ? parseFloat(value) || 0 : value
    };
    
    setCurrentBlend(prev => ({
      ...prev,
      chemicals: updatedChemicals
    }));
  };

  const handleAddChemical = () => {
    const newChemical = {
      chemicalId: `temp-${Date.now()}`, // Temporary ID for new chemicals
      name: '',
      broadcast: { rate: 0, unit: 'oz', per: 'acre' }
    };
    
    setCurrentBlend(prev => ({
      ...prev,
      chemicals: [...prev.chemicals, newChemical]
    }));
  };

  const handleRemoveChemical = (index) => {
    const updatedChemicals = [...currentBlend.chemicals];
    updatedChemicals.splice(index, 1);
    setCurrentBlend(prev => ({
      ...prev,
      chemicals: updatedChemicals
    }));
  };

  const handleToggleRateType = (index, rateType) => {
    const updatedChemicals = [...currentBlend.chemicals];
    
    if (updatedChemicals[index][rateType]) {
      // Remove the rate type
      const { [rateType]: _, ...rest } = updatedChemicals[index];
      updatedChemicals[index] = rest;
      
      // Ensure at least one rate type exists
      if (!updatedChemicals[index].spot && !updatedChemicals[index].broadcast) {
        const defaultRateType = rateType === 'spot' ? 'broadcast' : 'spot';
        updatedChemicals[index][defaultRateType] = { rate: 0, unit: 'oz', per: 'acre' };
      }
    } else {
      // Add the rate type
      updatedChemicals[index][rateType] = { rate: 0, unit: 'oz', per: 'acre' };
    }
    
    setCurrentBlend(prev => ({
      ...prev,
      chemicals: updatedChemicals
    }));
  };

  const handleSaveBlend = () => {
    // Validate blend data
    if (!currentBlend.name || !currentBlend.season || !currentBlend.type) {
      setSnackbarMessage('Please fill in all required fields');
      setSnackbarOpen(true);
      return;
    }
    
    if (currentBlend.applicationAreas.length === 0) {
      setSnackbarMessage('Please select at least one application area');
      setSnackbarOpen(true);
      return;
    }
    
    if (currentBlend.chemicals.length === 0) {
      setSnackbarMessage('Please add at least one chemical');
      setSnackbarOpen(true);
      return;
    }
    
    // Check if all chemicals have valid names and at least one rate type
    const invalidChemical = currentBlend.chemicals.find(chem => 
      !chem.name || (!chem.spot && !chem.broadcast)
    );
    
    if (invalidChemical) {
      setSnackbarMessage('All chemicals must have a name and at least one rate type');
      setSnackbarOpen(true);
      return;
    }
    
    // In a real app, you would send data to your API
    // Example: axios.put(`/api/chemical-blends/${currentBlend._id}`, currentBlend)
    
    // Update local state
    const updatedBlends = blends.map(blend => 
      blend._id === currentBlend._id ? currentBlend : blend
    );
    
    setBlends(updatedBlends);
    setSnackbarMessage('Blend updated successfully');
    setSnackbarOpen(true);
    handleDialogClose();
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div className={classes.root}>
      <Box className={classes.header}>
        <Typography variant="h4" gutterBottom>
          Chemical Blends
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1" color="textSecondary">
            {filteredBlends.length} blends found
          </Typography>
          <Box>
            <Tooltip title="Toggle Filters">
              <IconButton 
                color={showFilters ? "primary" : "default"} 
                onClick={() => setShowFilters(!showFilters)}
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      
      {showFilters && (
        <Paper className={classes.filterContainer} elevation={2}>
          <div className={classes.filterTitle}>
            <FilterListIcon color="primary" style={{ marginRight: 8 }} />
            <Typography variant="h6">Filter Blends</Typography>
          </div>
          
          <div className={classes.filterForm}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel>Season</InputLabel>
              <Select
                name="season"
                value={filters.season}
                onChange={handleFilterChange}
                label="Season"
              >
                <MenuItem value="">Any</MenuItem>
                <MenuItem value="Warm">Warm</MenuItem>
                <MenuItem value="Cold">Cold</MenuItem>
                <MenuItem value="All">All</MenuItem>
              </Select>
            </FormControl>
            
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel>Type</InputLabel>
              <Select
                name="type"
                value={filters.type}
                onChange={handleFilterChange}
                label="Type"
              >
                <MenuItem value="">Any</MenuItem>
                <MenuItem value="Pre Emergent">Pre Emergent</MenuItem>
                <MenuItem value="Post">Post</MenuItem>
                <MenuItem value="All">All</MenuItem>
              </Select>
            </FormControl>
            
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel>Application Area</InputLabel>
              <Select
                name="applicationArea"
                value={filters.applicationArea}
                onChange={handleFilterChange}
                label="Application Area"
              >
                <MenuItem value="">Any</MenuItem>
                <MenuItem value="Ornamental/Right-of-Way">Ornamental/Right-of-Way</MenuItem>
                <MenuItem value="Turf">Turf</MenuItem>
                <MenuItem value="Bare Ground">Bare Ground</MenuItem>
              </Select>
            </FormControl>
            
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel>Status</InputLabel>
              <Select
                name="active"
                value={filters.active}
                onChange={handleFilterChange}
                label="Status"
              >
                <MenuItem value="">Any</MenuItem>
                <MenuItem value="true">Active</MenuItem>
                <MenuItem value="false">Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>
        </Paper>
      )}
      
      <Grid container spacing={3}>
        {filteredBlends.map(blend => (
          <Grid item xs={12} sm={6} md={4} key={blend._id}>
            <Card className={classes.card} elevation={3}>
              <CardContent className={classes.cardContent}>
                <Tooltip title="Edit Blend">
                  <IconButton 
                    size="small" 
                    className={classes.editButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditClick(blend);
                    }}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                  <Box display="flex" alignItems="center">
                    <Chip 
                      size="small"
                      label={blend.season}
                      className={getSeasonClass(blend.season)}
                      icon={<LocalOfferIcon />}
                    />
                    {blend.active ? (
                      <Chip 
                        size="small" 
                        label="Active" 
                        color="primary" 
                        variant="outlined"
                        icon={<CheckCircleIcon className={classes.statusIcon} />}
                      />
                    ) : (
                      <Chip 
                        size="small" 
                        label="Inactive" 
                        color="default" 
                        variant="outlined"
                        icon={<CancelIcon className={classes.statusIcon} />}
                      />
                    )}
                  </Box>
                </Box>
                
                <Typography variant="h6" className={classes.title} gutterBottom>
                  {blend.name}
                </Typography>
                
                <Box my={1}>
                  <Chip 
                    size="small" 
                    label={blend.type} 
                    className={classes.typeChip}
                    icon={<EcoIcon fontSize="small" />}
                  />
                  <Typography variant="body2" color="textSecondary">
                    Calibration Rate: {blend.calibrationRate} gal/acre
                  </Typography>
                </Box>
                
                <Divider className={classes.divider} />
                
                <Box mb={1}>
                  <Typography variant="subtitle2" gutterBottom>
                    Application Areas:
                  </Typography>
                  <Box>
                    {blend.applicationAreas.map(area => (
                      <Chip 
                        key={area} 
                        size="small" 
                        label={area} 
                        className={classes.applicationArea}
                        icon={<TerrainIcon fontSize="small" />}
                      />
                    ))}
                  </Box>
                </Box>
                
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="chemicals-content"
                    id="chemicals-header"
                  >
                    <Typography variant="subtitle2">
                      Chemicals ({blend.chemicals.length})
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Box width="100%">
                      {blend.chemicals.map((chemical, index) => (
                        <Paper key={chemical.chemicalId} className={classes.chemicalItem} variant="outlined">
                          <Typography variant="subtitle2">{chemical.name}</Typography>
                          
                          {chemical.broadcast && (
                            <div className={classes.rateInfo}>
                              <OpacityIcon fontSize="small" color="primary" style={{ marginRight: 4 }} />
                              <span className={classes.rateType}>Broadcast:</span>
                              <span className={classes.rateValue}>
                                {chemical.broadcast.rate} {chemical.broadcast.unit}/{chemical.broadcast.per}
                              </span>
                            </div>
                          )}
                          
                          {chemical.spot && (
                            <div className={classes.rateInfo}>
                              <OpacityIcon fontSize="small" color="secondary" style={{ marginRight: 4 }} />
                              <span className={classes.rateType}>Spot:</span>
                              <span className={classes.rateValue}>
                                {chemical.spot.rate} {chemical.spot.unit}/{chemical.spot.per}
                              </span>
                            </div>
                          )}
                        </Paper>
                      ))}
                    </Box>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                
                <Box mt={2} display="flex" alignItems="center" justifyContent="flex-end">
                  <Typography variant="caption" color="textSecondary">
                    Created by {blend.createdByName}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      
      {/* Edit Dialog */}
      <Dialog 
        open={editDialogOpen} 
        onClose={handleDialogClose}
        fullWidth 
        maxWidth="md"
        classes={{ paper: classes.dialogPaper }}
      >
        {currentBlend && (
          <>
            <DialogTitle>
              Edit Chemical Blend
              <IconButton 
                aria-label="close" 
                className={classes.closeButton}
                onClick={handleDialogClose}
                style={{ position: 'absolute', right: 8, top: 8 }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent} dividers>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Blend Name"
                    name="name"
                    value={currentBlend.name}
                    onChange={handleInputChange}
                    variant="outlined"
                    required
                    margin="normal"
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <FormControl 
                    fullWidth 
                    variant="outlined" 
                    margin="normal" 
                    required
                  >
                    <InputLabel>Season</InputLabel>
                    <Select
                      name="season"
                      value={currentBlend.season}
                      onChange={handleInputChange}
                      label="Season"
                    >
                      <MenuItem value="Warm">Warm</MenuItem>
                      <MenuItem value="Cold">Cold</MenuItem>
                      <MenuItem value="All">All</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <FormControl 
                    fullWidth 
                    variant="outlined" 
                    margin="normal" 
                    required
                  >
                    <InputLabel>Type</InputLabel>
                    <Select
                      name="type"
                      value={currentBlend.type}
                      onChange={handleInputChange}
                      label="Type"
                    >
                      <MenuItem value="Pre Emergent">Pre Emergent</MenuItem>
                      <MenuItem value="Post">Post</MenuItem>
                      <MenuItem value="All">All</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Calibration Rate (gal/acre)"
                    name="calibrationRate"
                    type="number"
                    value={currentBlend.calibrationRate}
                    onChange={handleInputChange}
                    variant="outlined"
                    margin="normal"
                    InputProps={{ inputProps: { min: 0, step: 0.1 } }}
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <FormControl component="fieldset" margin="normal" required>
                    <FormLabel component="legend">Application Areas</FormLabel>
                    <FormGroup row>
                      {['Ornamental/Right-of-Way', 'Turf', 'Bare Ground'].map((area) => (
                        <FormControlLabel
                          key={area}
                          control={
                            <Checkbox
                              checked={currentBlend.applicationAreas.includes(area)}
                              onChange={() => handleApplicationAreaChange(area)}
                              color="primary"
                            />
                          }
                          label={area}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={currentBlend.isHerbicide}
                        onChange={handleCheckboxChange}
                        name="isHerbicide"
                        color="primary"
                      />
                    }
                    label="Is Herbicide"
                  />
                  
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={currentBlend.active}
                        onChange={handleCheckboxChange}
                        name="active"
                        color="primary"
                      />
                    }
                    label="Active"
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center" mb={2}>
                    <Typography variant="h6">Chemicals</Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<AddIcon />}
                      onClick={handleAddChemical}
                      style={{ marginLeft: 16 }}
                    >
                      Add Chemical
                    </Button>
                  </Box>
                  
                  {currentBlend.chemicals.map((chemical, index) => (
                    <Paper key={index} className={classes.chemicalSection}>
                      <IconButton 
                        className={classes.removeButton}
                        size="small"
                        onClick={() => handleRemoveChemical(index)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                      
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="Chemical Name"
                            value={chemical.name}
                            onChange={(e) => handleChemicalChange(index, 'name', e.target.value)}
                            variant="outlined"
                            required
                            margin="dense"
                          />
                        </Grid>
                        
                        <Grid item xs={12} sm={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={!!chemical.broadcast}
                                onChange={() => handleToggleRateType(index, 'broadcast')}
                                color="primary"
                              />
                            }
                            label="Broadcast Rate"
                          />
                          
                          {chemical.broadcast && (
                            <Box display="flex" alignItems="center" mt={1}>
                              <TextField
                                label="Rate"
                                type="number"
                                value={chemical.broadcast.rate}
                                onChange={(e) => handleRateChange(index, 'broadcast', 'rate', e.target.value)}
                                variant="outlined"
                                className={classes.rateField}
                                margin="dense"
                                InputProps={{ inputProps: { min: 0, step: 0.1 } }}
                              />
                              <FormControl 
                                variant="outlined" 
                                className={classes.unitField}
                                margin="dense"
                              >
                                <InputLabel>Unit</InputLabel>
                                <Select
                                  value={chemical.broadcast.unit}
                                  onChange={(e) => handleRateChange(index, 'broadcast', 'unit', e.target.value)}
                                  label="Unit"
                                >
                                  <MenuItem value="oz">oz</MenuItem>
                                  <MenuItem value="gal">gal</MenuItem>
                                </Select>
                              </FormControl>
                              <FormControl 
                                variant="outlined" 
                                className={classes.perField}
                                margin="dense"
                              >
                                <InputLabel>Per</InputLabel>
                                <Select
                                  value={chemical.broadcast.per}
                                  onChange={(e) => handleRateChange(index, 'broadcast', 'per', e.target.value)}
                                  label="Per"
                                >
                                  <MenuItem value="gal">gal</MenuItem>
                                  <MenuItem value="100gal">100gal</MenuItem>
                                  <MenuItem value="acre">acre</MenuItem>
                                  <MenuItem value="1000sqft">1000sqft</MenuItem>
                                </Select>
                              </FormControl>
                            </Box>
                          )}
                        </Grid>
                        
                        <Grid item xs={12} sm={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={!!chemical.spot}
                                onChange={() => handleToggleRateType(index, 'spot')}
                                color="primary"
                              />
                            }
                            label="Spot Rate"
                          />
                          
                          {chemical.spot && (
                            <Box display="flex" alignItems="center" mt={1}>
                              <TextField
                                label="Rate"
                                type="number"
                                value={chemical.spot.rate}
                                onChange={(e) => handleRateChange(index, 'spot', 'rate', e.target.value)}
                                variant="outlined"
                                className={classes.rateField}
                                margin="dense"
                                InputProps={{ inputProps: { min: 0, step: 0.1 } }}
                              />
                              <FormControl 
                                variant="outlined" 
                                className={classes.unitField}
                                margin="dense"
                              >
                                <InputLabel>Unit</InputLabel>
                                <Select
                                  value={chemical.spot.unit}
                                  onChange={(e) => handleRateChange(index, 'spot', 'unit', e.target.value)}
                                  label="Unit"
                                >
                                  <MenuItem value="oz">oz</MenuItem>
                                  <MenuItem value="gal">gal</MenuItem>
                                </Select>
                              </FormControl>
                              <FormControl 
                                variant="outlined" 
                                className={classes.perField}
                                margin="dense"
                              >
                                <InputLabel>Per</InputLabel>
                                <Select
                                  value={chemical.spot.per}
                                  onChange={(e) => handleRateChange(index, 'spot', 'per', e.target.value)}
                                  label="Per"
                                >
                                  <MenuItem value="gal">gal</MenuItem>
                                  <MenuItem value="100gal">100gal</MenuItem>
                                  <MenuItem value="acre">acre</MenuItem>
                                  <MenuItem value="1000sqft">1000sqft</MenuItem>
                                </Select>
                              </FormControl>
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                    </Paper>
                  ))}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button 
                onClick={handleDialogClose} 
                color="default"
                className={classes.actionButton}
              >
                Cancel
              </Button>
              <Button 
                onClick={handleSaveBlend} 
                color="primary" 
                variant="contained"
                startIcon={<SaveIcon />}
                className={classes.actionButton}
              >
                Save Changes
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      
      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <IconButton 
            size="small" 
            color="inherit" 
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url,
    entities: state.entities,
    perms: state.perms
  }
}

export default connect(mapStateToProps)(ChemicalBlendsList)
