import React, { useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  Grid,
  TextField,
  MenuItem,
  Button,
  makeStyles,
  Divider,
  FormControl,
  InputLabel,
  Select,
  Box,
  Chip,
  Avatar,
  CircularProgress,
  Snackbar
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios';
import { connect } from 'react-redux';
import {
  DirectionsCar as CarIcon,
  Speed as SpeedIcon,
  DateRange as DateIcon,
  VpnKey as VinIcon,
  LocalOffer as PlateIcon,
  Build as ServiceIcon,
  Group as GroupIcon,
  LibraryBooks as LibraryBooksIcon,
  Timeline as TreadIcon,
  CompareArrows as PressureIcon,
  Warning as WarningIcon,
  ArrowBack as ArrowBackIcon
} from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const TIRE_CONDITIONS = [
  "Excellent - Like New (90-100%)",
  "Good - Minor Wear (70-89%)",
  "Fair - Moderate Wear (50-69%)",
  "Poor - Significant Wear (30-49%)",
  "Critical - Needs Replacement (<30%)",
  "Damaged - Immediate Attention Required",
  "Uneven Wear Pattern",
  "Bulging/Bubbling Present",
  "Cracking Present",
  "Foreign Objects Present"
];

const OIL_WEIGHTS = [
  "0W-20",
  "5W-20",
  "5W-30",
  "15W-30",
  "15W-40",
  "0W-40",
  "5W-40"
];

const OIL_FILTERS = [
  "MGL 51372",
  "MGL 57060",
  "MGL 57145",
  "MGL 10255",
  "MGL 51040",
  "MGL 10351",
  "PF 63",
  "MSL 10255"
];

const AIR_FILTERS = [
  "MGA 10697",
  "MGA 49314",
  "MGA 49883",
  "MGA 10906",
  "MGA 4107",
  "MGA 42725",
  "WA 10942 4062",
  "WA 11200 3696",
  "WA 11653 4151"
];

const CABIN_FILTERS = [
  "WP10129",
  "WIX 24211"
];

const autoFocus = (e) => { e.target.select() };

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    maxWidth: 1200,
    margin: '0 auto',
  },
  headerPaper: {
    background: `linear-gradient(45deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(4),
    color: theme.palette.primary.contrastText,
  },
  companyTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(3),
    color: '#fff',
    textAlign: 'center',
    textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
  },
  vehicleHeader: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
    backgroundColor: '#fff',
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    boxShadow: theme.shadows[3],
  },
  imageSection: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 300,
    },
    position: 'relative',
  },
  vehicleImage: {
    width: '100%',
    height: '100%',
    minHeight: 200,
    objectFit: 'cover',
  },
  imageOverlay: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    background: 'linear-gradient(transparent, rgba(0,0,0,0.7))',
    padding: theme.spacing(2),
    color: '#fff',
  },
  vehicleDetails: {
    flex: 1,
    padding: theme.spacing(3),
    backgroundColor: '#fff',
  },
  infoGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    gap: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  infoCard: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius,
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: theme.shadows[2],
    },
  },
  infoIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    borderRadius: '50%',
  },
  infoContent: {
    flex: 1,
  },
  infoLabel: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
    marginBottom: theme.spacing(0.5),
  },
  infoValue: {
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  serviceInputs: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gap: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  statusChip: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    backgroundColor: theme.palette.success.main,
    color: '#fff',
    fontWeight: 600,
  },
  vehicleInfo: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  infoItem: {
    '& .MuiTypography-subtitle2': {
      color: theme.palette.text.secondary,
      marginBottom: theme.spacing(0.5),
    },
    '& .MuiTypography-body1': {
      fontWeight: 500,
    },
  },
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing(3),
    fontWeight: 700,
  },
  subtitle: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(4),
    fontWeight: 500,
  },
  section: {
    marginTop: theme.spacing(4),
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  formRow: {
    marginBottom: theme.spacing(2),
    '& .MuiTypography-root': {
      fontWeight: 500,
    },
  },
  serviceLabel: {
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  fullWidth: {
    width: '100%',
  },
  divider: {
    margin: theme.spacing(3, 0),
  },
  submit: {
    marginTop: theme.spacing(4),
  },
  truckContainer: {
    position: 'relative',
    margin: theme.spacing(4, 0),
    height: 400, // Adjust based on your truck image
  },
  truckImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  tireInput: {
    position: 'absolute',
    width: 400,
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    padding: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    border: `1px solid ${theme.palette.grey[300]}`,
    '&.front-left': { top: '5%', left: '5%' },
    '&.front-right': { top: '5%', right: '5%' },
    '&.rear-left': { bottom: '5%', left: '5%' },
    '&.rear-right': { bottom: '5%', right: '5%' },
  },
  tireInputGroup: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.spacing(1),
  },
  tireInputField: {
    backgroundColor: theme.palette.background.paper,
    '& .MuiInputBase-root': {
      borderRadius: theme.shape.borderRadius,
    },
    '& .MuiInputAdornment-root': {
      marginRight: theme.spacing(1),
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.grey[300],
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  tirePosition: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0.5, 1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: theme.shape.borderRadius,
    textAlign: 'center',
    fontWeight: 500,
  },
  warningIcon: {
    color: theme.palette.warning.main,
  },
  headerButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 200,
  },
  editModeIndicator: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
    marginLeft: theme.spacing(2),
    fontWeight: 'bold',
  },
  formActionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
  }
}));

// Define which services need specific input fields
const serviceFields = {
  oilFilter: ['filterType'],
  airFilter: ['filterType'],
  oilChange: ['oilType', 'quantity'],
  cabinAirFilter: ['filterType'],
  battery: ['voltage'],
  alternator: ['voltage']
  // All other services will just have 'performed' and 'notes'
};

const VehicleInspectionForm = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [equipment, setEquipment] = useState({});
  const [vehicleData, setVehicleData] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [recordId, setRecordId] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  
  // Default form state
  const defaultFormState = {
    serviceDate: new Date().toISOString().split('T')[0],
    currentMileage: '0',
    nextServiceMileage: '0',
    services: {
      battery: { performed: 'no', voltage: '', notes: '' },
      alternator: { performed: 'no', voltage: '', notes: '' },
      oilFilter: { performed: 'no', filterType: '', notes: '' },
      oilChange: { performed: 'no', oilType: '', quantity: '', notes: '' },
      airFilter: { performed: 'no', filterType: '', notes: '' },
      cabinAirFilter: { performed: 'no', filterType: '', notes: '' },
      transmissionFluid: { performed: 'no', notes: '' },
      powerSteering: { performed: 'no', notes: '' },
      greaseAllSteeringLinkage: { performed: 'no', notes: '' },
      sparkPlugs: { performed: 'no', notes: '' },
      wires: { performed: 'no', notes: '' },
      washerFluid: { performed: 'no', notes: '' },
      tires: { performed: 'no', notes: '' },
      tirePressure: { performed: 'no', notes: '' },
      windshieldWipers: { performed: 'no', notes: '' },
      belts: { performed: 'no', notes: '' },
      differentialFluids: { performed: 'no', notes: '' },
      brakeFluids: { performed: 'no', notes: '' },
      allLights: { performed: 'no', notes: '' }
    },
    tireReadings: {
      frontLeft: { 
        tireCondition: '', 
        brakePadCondition: '',
        treadDepth: '',
        tirePressure: ''
      },
      frontRight: { 
        tireCondition: '', 
        brakePadCondition: '',
        treadDepth: '',
        tirePressure: ''
      },
      rearLeft: { 
        tireCondition: '', 
        brakePadCondition: '',
        treadDepth: '',
        tirePressure: ''
      },
      rearRight: { 
        tireCondition: '', 
        brakePadCondition: '',
        treadDepth: '',
        tirePressure: ''
      }
    }
  };

  const [formData, setFormData] = useState(defaultFormState);

  async function fetchData(equipmentId) {
    console.log('Fetch our equipment', equipmentId)
    setLoading(true)
    setEquipment({})
    try {
      const response = await axios.get(`${props.url}/api/equipment/getEquipmentDetails?id=${equipmentId}`)

      if (response.data && response.data?._id) {
        console.log('Got Equipment!!', response.data)
        
        if (response?.data?.mileage) {
          let svcInterval = Number(response.data.mileage) > 10000 ? 3000 : 5000
          setFormData(prev => ({
            ...prev,
            currentMileage: response.data.mileage,
            nextServiceMileage: Number(response.data.mileage) + svcInterval
          }))
        }
        setEquipment(response.data)
        setVehicleData(response.data)
        
        // Check if we are in edit mode
        checkForEditMode(equipmentId);
      }
    } catch (e) {
      console.log('error getting equipment...', e)
      setSnackbar({
        open: true,
        message: 'Error loading equipment data',
        severity: 'error'
      });
    } finally {
      setLoading(false)
    }
  }
  
  const checkForEditMode = (equipmentId) => {
    // Check URL params for edit mode
    const queryParams = new URLSearchParams(location.search);
    const editRecordId = queryParams.get('edit');
    
    // Check if we have a record to edit
    if (editRecordId) {
      setIsEditMode(true);
      setRecordId(editRecordId);
      
      // First try to get from localStorage (comes from the maintenance history component)
      const savedRecord = localStorage.getItem('maintenanceRecordToEdit');
      if (savedRecord) {
        try {
          const parsedRecord = JSON.parse(savedRecord);
          if (parsedRecord._id === editRecordId) {
            // Format the date from ISO to YYYY-MM-DD for the form
            const formattedDate = moment(parsedRecord.serviceDate).format('YYYY-MM-DD');
            
            // Load the record data into the form
            setFormData({
              serviceDate: formattedDate,
              currentMileage: parsedRecord.currentMileage.toString(),
              nextServiceMileage: parsedRecord.nextServiceMileage.toString(),
              services: parsedRecord.services,
              tireReadings: parsedRecord.tireReadings || {
                frontLeft: { tireCondition: '', brakePadCondition: '', treadDepth: '', tirePressure: '' },
                frontRight: { tireCondition: '', brakePadCondition: '', treadDepth: '', tirePressure: '' },
                rearLeft: { tireCondition: '', brakePadCondition: '', treadDepth: '', tirePressure: '' },
                rearRight: { tireCondition: '', brakePadCondition: '', treadDepth: '', tirePressure: '' }
              }
            });
            
            // Clear the localStorage item to prevent stale data
            localStorage.removeItem('maintenanceRecordToEdit');
            return;
          }
        } catch (e) {
          console.error('Error parsing saved record:', e);
        }
      }
      
      // If we didn't get the record from localStorage, fetch it from the API
      fetchMaintenanceRecord(equipmentId, editRecordId);
    }
  };
  
  const fetchMaintenanceRecord = async (equipmentId, recordId) => {
    try {
      setLoading(true);
      const response = await axios.get(`${props.url}/api/equipment/getMaintenanceRecord?recordId=${recordId}&equipmentId=${equipmentId}`);
      
      if (response.data && response.data._id) {
        // Format the date from ISO to YYYY-MM-DD for the form
        const formattedDate = moment(response.data.serviceDate).format('YYYY-MM-DD');
        
        // Load the record data into the form
        setFormData({
          serviceDate: formattedDate,
          currentMileage: response.data.currentMileage.toString(),
          nextServiceMileage: response.data.nextServiceMileage.toString(),
          services: response.data.services,
          tireReadings: response.data.tireReadings || {
            frontLeft: { tireCondition: '', brakePadCondition: '', treadDepth: '', tirePressure: '' },
            frontRight: { tireCondition: '', brakePadCondition: '', treadDepth: '', tirePressure: '' },
            rearLeft: { tireCondition: '', brakePadCondition: '', treadDepth: '', tirePressure: '' },
            rearRight: { tireCondition: '', brakePadCondition: '', treadDepth: '', tirePressure: '' }
          }
        });
      } else {
        setSnackbar({
          open: true,
          message: 'Maintenance record not found',
          severity: 'error'
        });
      }
    } catch (error) {
      console.error('Error fetching maintenance record:', error);
      setSnackbar({
        open: true,
        message: 'Failed to load maintenance record',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (props.match && props.match.params && props.match.params.equipmentId) {
      console.log('We have some equipment to pull up!!', props.match.params.equipmentId)
      const equipmentId = props.match.params.equipmentId
      console.log('EquipmentId', equipmentId)
      
      if (equipment?._id?.toString() === equipmentId.toString()) {
        // Equipment already loaded, check for edit mode
        checkForEditMode(equipmentId);
      } else {
        // Load equipment and then check for edit mode in fetchData callback
        fetchData(equipmentId);
      }
    }
  }, [props.match?.params?.equipmentId]);

  const handleInputChange = (section, field, value) => {
    console.log("Input change", section, field, value)
    if (section === 'serviceDate') {
      console.log('Set service date', value)
      setFormData(prev => ({
        ...prev,
        serviceDate: value,
      }));
    } else if (section === 'currentMileage') {
      // Directly set the value for currentMileage
      let svcInterval = Number(value) > 10000 ? 3000 : 5000
      setFormData(prev => ({
        ...prev,
        currentMileage: value,
        nextServiceMileage: (Number(value) + svcInterval).toString()
      }));
    } else {
      // Handle other fields as before
      setFormData(prev => ({
        ...prev,
        [section]: {
          ...prev[section],
          [field]: value
        }
      }));
    }
  };

  const handleServiceChange = (service, field, value) => {
    setFormData(prev => ({
      ...prev,
      services: {
        ...prev.services,
        [service]: {
          ...prev.services[service],
          [field]: value
        }
      }
    }));
  };

  const handleTireChange = (position, field, value) => {
    setFormData(prev => ({
      ...prev,
      tireReadings: {
        ...prev.tireReadings,
        [position]: {
          ...prev.tireReadings[position],
          [field]: value
        }
      }
    }));
  };

  const renderServiceFields = (serviceKey, serviceData) => {
    const fields = serviceFields[serviceKey] || [];
    
    return (
      <Grid container spacing={2} key={serviceKey} className={classes.formRow}>
        <Grid item xs={12} sm={3}>
          <Typography className={classes.serviceLabel}>
           {formatLabel(serviceKey)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth>
            <InputLabel>Performed</InputLabel>
            <Select
              value={serviceData?.performed || 'no'}
              onChange={(e) => handleServiceChange(serviceKey, 'performed', e.target.value)}
            >
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {serviceKey === 'oilChange' ? (
          <>
            <Grid item xs={12} sm={2}>
              <FormControl fullWidth>
                <InputLabel>Oil Weight</InputLabel>
                <Select
                  value={serviceData?.oilType || ''}
                  onChange={(e) => handleServiceChange(serviceKey, 'oilType', e.target.value)}
                >
                  {OIL_WEIGHTS.map(weight => (
                    <MenuItem key={weight} value={weight}>{weight}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                fullWidth
                label="Quantity (qts)"
                type="number"
                value={serviceData?.quantity || ''}
                onChange={(e) => handleServiceChange(serviceKey, 'quantity', e.target.value)}
              />
            </Grid>
          </>
        ) : serviceKey === 'oilFilter' ? (
          <Grid item xs={12} sm={2}>
            <FormControl fullWidth>
              <InputLabel>Filter Type</InputLabel>
              <Select
                value={serviceData?.filterType || ''}
                onChange={(e) => handleServiceChange(serviceKey, 'filterType', e.target.value)}
              >
                {OIL_FILTERS.map(filter => (
                  <MenuItem key={filter} value={filter}>{filter}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        ) : serviceKey === 'airFilter' ? (
          <Grid item xs={12} sm={2}>
            <FormControl fullWidth>
              <InputLabel>Filter Type</InputLabel>
              <Select
                value={serviceData?.filterType || ''}
                onChange={(e) => handleServiceChange(serviceKey, 'filterType', e.target.value)}
              >
                {AIR_FILTERS.map(filter => (
                  <MenuItem key={filter} value={filter}>{filter}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        ) : serviceKey === 'cabinAirFilter' ? (
          <Grid item xs={12} sm={2}>
            <FormControl fullWidth>
              <InputLabel>Filter Type</InputLabel>
              <Select
                value={serviceData?.filterType || ''}
                onChange={(e) => handleServiceChange(serviceKey, 'filterType', e.target.value)}
              >
                {CABIN_FILTERS.map(filter => (
                  <MenuItem key={filter} value={filter}>{filter}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        ) : (
          fields.map(field => (
            <Grid item xs={12} sm={2} key={field}>
              <TextField
                fullWidth
                label={field.replace(/([A-Z])/g, ' $1').trim()}
                value={serviceData[field] || ''}
                onChange={(e) => handleServiceChange(serviceKey, field, e.target.value)}
              />
            </Grid>
          ))
        )}

        <Grid item xs={12} sm={fields.length ? 3 : 5}>
          <TextField
            fullWidth
            label="Notes"
            value={serviceData?.notes || ''}
            onChange={(e) => handleServiceChange(serviceKey, 'notes', e.target.value)}
          />
        </Grid>
      </Grid>
    );
  };

  const handleFormSubmission = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    
    try {
      // Format the data object to match backend schema
      const formattedData = {
        equipmentId: props.match.params.equipmentId,
        serviceDate: formData.serviceDate,
        currentMileage: formData.currentMileage,
        nextServiceMileage: formData.nextServiceMileage,
        
        // Include all services
        services: {
          // Oil change with type and quantity
          oilChange: {
            performed: formData.services?.oilChange?.performed || 'no',
            oilType: formData.services?.oilChange?.oilType || '',
            quantity: formData.services?.oilChange?.quantity || '',
            notes: formData.services?.oilChange?.notes || ''
          },
  
          // Filters with types
          oilFilter: {
            performed: formData.services?.oilFilter?.performed || 'no',
            filterType: formData.services?.oilFilter?.filterType || '',
            notes: formData.services?.oilFilter?.notes || ''
          },
          airFilter: {
            performed: formData.services?.airFilter?.performed || 'no',
            filterType: formData.services?.airFilter?.filterType || '',
            notes: formData.services?.airFilter?.notes || ''
          },
          cabinAirFilter: {
            performed: formData.services?.cabinAirFilter?.performed || 'no',
            filterType: formData.services?.cabinAirFilter?.filterType || '',
            notes: formData.services?.cabinAirFilter?.notes || ''
          },
  
          // Standard services
          battery: {
            performed: formData.services?.battery?.performed || 'no',
            voltage: formData.services?.battery?.voltage || '',
            notes: formData.services?.battery?.notes || ''
          },
          alternator: {
            performed: formData.services?.alternator?.performed || 'no',
            voltage: formData.services?.alternator?.voltage || '',
            notes: formData.services?.alternator?.notes || ''
          },
          transmissionFluid: {
            performed: formData.services?.transmissionFluid?.performed || 'no',
            notes: formData.services?.transmissionFluid?.notes || ''
          },
          powerSteering: {
            performed: formData.services?.powerSteering?.performed || 'no',
            notes: formData.services?.powerSteering?.notes || ''
          },
          greaseAllSteeringLinkage: {
            performed: formData.services?.greaseAllSteeringLinkage?.performed || 'no',
            notes: formData.services?.greaseAllSteeringLinkage?.notes || ''
          },
          sparkPlugs: {
            performed: formData.services?.sparkPlugs?.performed || 'no',
            notes: formData.services?.sparkPlugs?.notes || ''
          },
          wires: {
            performed: formData.services?.wires?.performed || 'no',
            notes: formData.services?.wires?.notes || ''
          },
          washerFluid: {
            performed: formData.services?.washerFluid?.performed || 'no',
            notes: formData.services?.washerFluid?.notes || ''
          },
          tires: {
            performed: formData.services?.tires?.performed || 'no',
            notes: formData.services?.tires?.notes || ''
          },
          tirePressure: {
            performed: formData.services?.tirePressure?.performed || 'no',
            notes: formData.services?.tirePressure?.notes || ''
          },
          windshieldWipers: {
            performed: formData.services?.windshieldWipers?.performed || 'no',
            notes: formData.services?.windshieldWipers?.notes || ''
          },
          belts: {
            performed: formData.services?.belts?.performed || 'no',
            notes: formData.services?.belts?.notes || ''
          },
          differentialFluids: {
            performed: formData.services?.differentialFluids?.performed || 'no',
            notes: formData.services?.differentialFluids?.notes || ''
          },
          brakeFluids: {
            performed: formData.services?.brakeFluids?.performed || 'no',
            notes: formData.services?.brakeFluids?.notes || ''
          },
          allLights: {
            performed: formData.services?.allLights?.performed || 'no',
            notes: formData.services?.allLights?.notes || ''
          }
        },
          tireReadings: {
              frontLeft: {
                tireCondition: formData.tireReadings.frontLeft.tireCondition || '',
                brakePadCondition: formData.tireReadings.frontLeft.brakePadCondition || '',
                treadDepth: formData.tireReadings.frontLeft.treadDepth || '',
                tirePressure: formData.tireReadings.frontLeft.tirePressure || ''
              },
              frontRight: {
                tireCondition: formData.tireReadings.frontRight.tireCondition || '',
                brakePadCondition: formData.tireReadings.frontRight.brakePadCondition || '',
                treadDepth: formData.tireReadings.frontRight.treadDepth || '',
                tirePressure: formData.tireReadings.frontRight.tirePressure || ''
              },
              rearLeft: {
                tireCondition: formData.tireReadings.rearLeft.tireCondition || '',
                brakePadCondition: formData.tireReadings.rearLeft.brakePadCondition || '',
                treadDepth: formData.tireReadings.rearLeft.treadDepth || '',
                tirePressure: formData.tireReadings.rearLeft.tirePressure || ''
              },
              rearRight: {
                tireCondition: formData.tireReadings.rearRight.tireCondition || '',
                brakePadCondition: formData.tireReadings.rearRight.brakePadCondition || '',
                treadDepth: formData.tireReadings.rearRight.treadDepth || '',
                tirePressure: formData.tireReadings.rearRight.tirePressure || ''
              }
            }
          };
      
          // Add basic validation
          if (!formData.serviceDate) {
            throw new Error('Service date is required');
          }
      
          if (!formData.currentMileage) {
            throw new Error('Current mileage is required');
          }
      
          let response;
          
          if (isEditMode && recordId) {
            // If in edit mode, send an update request
            formattedData.recordId = recordId; // Add record ID for update
            
            response = await axios({
              method: 'put',
              url: `${props.url}/api/equipment/updateMaintenanceRecord`,
              data: formattedData,
              headers: {
                'Content-Type': 'application/json'
              }
            });
            console.log(response.data)
            setSnackbar({
              open: true,
              message: 'Maintenance record updated successfully',
              severity: 'success'
            });
          } else {
            // Create new record
            response = await axios({
              method: 'post',
              url: `${props.url}/api/equipment/logVehicleMaintenance`,
              data: formattedData,
              headers: {
                'Content-Type': 'application/json'
              }
            });
            
            setSnackbar({
              open: true,
              message: 'Maintenance record created successfully',
              severity: 'success'
            });
          }
      
          // Handle success
          if (response.data && response.data.success) {
            console.log('Maintenance record saved successfully:', response.data);
            
            // Redirect after short delay to show success message
            setTimeout(() => {
              // Navigate back to equipment detail page
              const equipmentId = props.match.params.equipmentId;
              history.push(`/equipment/details/${equipmentId}`);
            }, 1500);
          }
      
        } catch (error) {
          console.error('Error saving maintenance details:', error);
          
          // Show error message
          setSnackbar({
            open: true,
            message: error.response?.data?.error || error.message || 'Error saving maintenance record',
            severity: 'error'
          });
        } finally {
          setSubmitting(false);
        }
      };
      
      const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackbar({ ...snackbar, open: false });
      };
      
      const handleCancel = () => {
        // Navigate back to equipment detail page
        const equipmentId = props.match.params.equipmentId;
        history.push(`/equipment/details/${equipmentId}`);
      };
    
      console.log('Vehicle Data', vehicleData)
      let imageURI = `${process.env.PUBLIC_URL}/image_not_found.webp`
      // vehicleData.pictureURL || `${process.env.REACT_APP_HOST}/aciLogo.png`
      
      if (loading) {
        return (
          <Paper className={classes.root}>
            <div className={classes.loadingContainer}>
              <CircularProgress />
              <Typography variant="subtitle1" style={{ marginLeft: 16 }}>
                Loading vehicle data...
              </Typography>
            </div>
          </Paper>
        );
      }
      
      return (
        <Paper className={classes.root}>
          <div className={classes.headerButtons}>
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={handleCancel}
              className={classes.backButton}
            >
              Back to Vehicle
            </Button>
          </div>
          
          <Typography variant="h4" className={classes.title}>
            H.M.I Commercial Landscaping - Vehicle Service Sheet
            {isEditMode && (
              <Chip
                label="Edit Mode"
                className={classes.editModeIndicator}
                size="medium"
              />
            )}
          </Typography>
          
          <Typography variant="subtitle1" className={classes.subtitle}>
            Based on every 3 months or every 3000 miles (whichever is first)
          </Typography>
          
          <form onSubmit={handleFormSubmission}>
            <Box className={classes.vehicleHeader}>
              <Box className={classes.imageSection}>
                <img 
                  src={imageURI}
                  alt={`${vehicleData.manufacturer} ${vehicleData.model}`}
                  className={classes.vehicleImage}
                />
                <Chip
                  label="Active"
                  className={classes.statusChip}
                  icon={<ServiceIcon style={{ color: '#fff' }} />}
                />
                <Box className={classes.imageOverlay}>
                  <Typography variant="h6">
                    {vehicleData.year} {vehicleData.manufacturer} {vehicleData.model}
                  </Typography>
                </Box>
              </Box>
    
              <Box className={classes.vehicleDetails}>
                <Box className={classes.infoGrid}>
                  <Box className={classes.infoCard}>
                    <Avatar className={classes.infoIcon}>
                      <CarIcon />
                    </Avatar>
                    <Box className={classes.infoContent}>
                      <Typography className={classes.infoLabel}>Vehicle ID</Typography>
                      <Typography className={classes.infoValue}>
                        {vehicleData.name} #{vehicleData.number || 'N/A'}
                      </Typography>
                    </Box>
                  </Box>
    
                  <Box className={classes.infoCard}>
                    <Avatar className={classes.infoIcon}>
                      <VinIcon />
                    </Avatar>
                    <Box className={classes.infoContent}>
                      <Typography className={classes.infoLabel}>VIN</Typography>
                      <Typography className={classes.infoValue}>
                        {vehicleData.vin || 'N/A'}
                      </Typography>
                    </Box>
                  </Box>
    
                  <Box className={classes.infoCard}>
                    <Avatar className={classes.infoIcon}>
                      <PlateIcon />
                    </Avatar>
                    <Box className={classes.infoContent}>
                      <Typography className={classes.infoLabel}>License Plate</Typography>
                      <Typography className={classes.infoValue}>
                        {vehicleData.plate || 'N/A'}
                      </Typography>
                    </Box>
                  </Box>
    
                  <Box className={classes.infoCard}>
                    <Avatar className={classes.infoIcon}>
                      <SpeedIcon />
                    </Avatar>
                    <Box className={classes.infoContent}>
                      <Typography className={classes.infoLabel}>Last Known Mileage</Typography>
                      <Typography className={classes.infoValue}>
                        {vehicleData?.mileage?.toLocaleString() || '0'} miles
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={classes.infoCard}>
                    <Avatar className={classes.infoIcon}>
                      <LibraryBooksIcon />
                    </Avatar>
                    <Box className={classes.infoContent}>
                      <Typography className={classes.infoLabel}>Division</Typography>
                      <Typography className={classes.infoValue}>
                        {vehicleData?.division || 'N/A'}
                      </Typography>
                    </Box>
                  </Box>
    
                  <Box className={classes.infoCard}>
                    <Avatar className={classes.infoIcon}>
                      <GroupIcon />
                    </Avatar>
                    <Box className={classes.infoContent}>
                      <Typography className={classes.infoLabel}>Assigned Crew</Typography>
                      <Typography className={classes.infoValue}>
                        {vehicleData?.crewName || 'N/A'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
    
                <Box className={classes.serviceInputs}>
                  <TextField
                    label="Service Date"
                    type="date"
                    value={formData.serviceDate}
                    onChange={(e) => handleInputChange('serviceDate', '', e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      startAdornment: <DateIcon color="action" style={{ marginRight: 8 }} />,
                    }}
                  />
                  
                  <TextField
                    label="Current Mileage"
                    onFocus={autoFocus}
                    value={formData?.currentMileage || 0}
                    onChange={(e) => handleInputChange('currentMileage', '', e.target.value)}
                    helperText="Enter current odometer reading"
                    InputProps={{
                      startAdornment: <SpeedIcon color="action" style={{ marginRight: 8 }} />,
                    }}
                  />
                  
                  <TextField
                    label="Next Service Due"
                    value={formData.nextServiceMileage}
                    disabled
                    // helperText="Auto-calculated (current + 3000)"
                    InputProps={{
                      startAdornment: <ServiceIcon color="action" style={{ marginRight: 8 }} />,
                    }}
                  />
                </Box>
              </Box>
            </Box>
           
    
            <Divider className={classes.divider} />
    
            {/* Service Items Section */}
            <Typography variant="h6" className={classes.sectionTitle}>
              Service Items
            </Typography>
            
            {Object.entries(formData.services).map(([key, value]) => 
              renderServiceFields(key, value)
            )}
    
            <Divider className={classes.divider} />
    
            {/* Truck Diagram with Tire/Brake Inputs */}
            <Typography variant="h6" className={classes.sectionTitle}>
              Tire and Brake Inspection
            </Typography>
            
            <Box className={classes.truckContainer}>
              <img 
                src={`${process.env.PUBLIC_URL}/truckoutline.png`} // Replace with your truck image
                alt="Truck Diagram"
                className={classes.truckImage}
              />
              
              {Object.entries(formData.tireReadings).map(([position, data]) => {
                  const positionClass = position.replace(/([A-Z])/g, '-$1').toLowerCase();
                  return (
                    <Box key={position} className={`${classes.tireInput} ${positionClass}`}>
                      <Box className={classes.tireInputGroup}>
                        <FormControl 
                            fullWidth 
                            margin="dense" 
                            className={classes.tireInputField}
                          >
                            <InputLabel>Tire Condition</InputLabel>
                            <Select
                              value={data.tireCondition || ''}
                              onChange={(e) => handleTireChange(position, 'tireCondition', e.target.value)}
                              startAdornment={
                                <Box component="span" sx={{ mr: 1 }}>
                                  <WarningIcon className={classes.warningIcon} />
                                </Box>
                              }
                            >
                              <MenuItem value="">
                                <em>Select condition</em>
                              </MenuItem>
                              {TIRE_CONDITIONS.map((condition) => (
                                <MenuItem key={condition} value={condition}>
                                  {condition}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        <TextField
                          label="Tread Depth (32nds)"
                          value={data.treadDepth || ''}
                          onChange={(e) => handleTireChange(position, 'treadDepth', e.target.value)}
                          type="number"
                          fullWidth
                          margin="dense"
                          className={classes.tireInputField}
                          InputProps={{
                            startAdornment: (
                              <TreadIcon color="primary" />
                            ),
                            inputProps: { min: 0, max: 32 }
                          }}
                        />
                        <TextField
                          label="Tire Pressure (PSI)"
                          value={data.tirePressure || ''}
                          onChange={(e) => handleTireChange(position, 'tirePressure', e.target.value)}
                          type="number"
                          fullWidth
                          margin="dense"
                          className={classes.tireInputField}
                          InputProps={{
                            startAdornment: (
                              <PressureIcon color="primary" />
                            ),
                            inputProps: { min: 0, max: 120 }
                          }}
                        />
                        <TextField
                          label="Brake Pad Condition"
                          value={data.brakePadCondition || ''}
                          onChange={(e) => handleTireChange(position, 'brakePadCondition', e.target.value)}
                          fullWidth
                          margin="dense"
                          className={classes.tireInputField}
                          InputProps={{
                            startAdornment: (
                              <SpeedIcon color="primary" />
                            ),
                          }}
                        />
                      </Box>
                    </Box>
                  );
                })}
            </Box>
    
            <div className={classes.formActionButtons}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={submitting}
                startIcon={submitting ? <CircularProgress size={20} color="inherit" /> : null}
              >
                {submitting ? 'Saving...' : isEditMode ? 'Update Maintenance Record' : 'Submit Inspection'}
              </Button>
            </div>
          </form>
          
          {/* Snackbar for notifications */}
          <Snackbar 
            open={snackbar.open} 
            autoHideDuration={6000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          >
            <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Paper>
      );
    };
    
    const formatLabel = (serviceKey) => {
      let label = serviceKey
      let trimmed = serviceKey.replace(/([A-Z])/g, ' $1').trim()
    
      let firstLetter = trimmed?.charAt(0)?.toUpperCase()
      let restOfWord = trimmed?.toString()?.slice(1, trimmed.length)
    
      label = `${firstLetter}${restOfWord}`
      return label
    }
    
    const mapStateToProps = (state) => {
      return {
        authenticated: state.authenticated,
        notification: state.notification,
        url: state.url
      }
    }
    
    export default connect(mapStateToProps)(VehicleInspectionForm)
    