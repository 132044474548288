/**
 * DialogForm - Handles the form for creating and editing polygons
 * 
 * This component is responsible for:
 * - Displaying the form for creating/editing polygons
 * - Handling form input changes
 * - Managing form submission
 */

import React, { memo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Paper,
  MenuItem,
  IconButton
} from '@material-ui/core';
import {
  Close,
  Delete,
  FileCopy as ContentCopy
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

// Import polygon types
import { POLYGON_TYPES } from './constants';

// Styles
const useStyles = makeStyles(theme => ({
  formField: {
    marginBottom: theme.spacing(2)
  },
  colorPickers: {
    display: 'flex',
    gap: '16px',
    marginTop: '16px'
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8
  }
}));

const DialogForm = memo(({
  isOpen,
  mode,
  newArea,
  polygons,
  selectedPolygon,
  onInputChange,
  onSave,
  onContinueEditing,
  onClose,
  onCopy,
  onDelete,
  getPolygonArea
}) => {
  const styles = useStyles();
  
  const title = mode === 'edit' ? 'Edit Area' : 'Create New Area';
  
  // Creating debug logging for Continue Editing button
  const handleContinueEditing = () => {
    console.log("Continue Editing clicked");
    if (newArea) {
      console.log("Area data:", newArea);
    }
    onContinueEditing();
  };
  
  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {title}
        <IconButton 
          aria-label="close" 
          onClick={onClose}
          className={styles.closeButton}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="name"
          label="Area Name"
          type="text"
          fullWidth
          value={newArea.name || ''}
          onChange={onInputChange}
          variant="outlined"
          className={styles.formField}
        />
        <TextField
          fullWidth
          select
          margin="dense"
          name="polygonType"
          value={newArea.polygonType || POLYGON_TYPES.ORNAMENTAL}
          onChange={onInputChange}
          label="Surface Type"
          variant="outlined"
          helperText="Select the type of surface for this area"
          className={styles.formField}
        >
          <MenuItem value={POLYGON_TYPES.TURF}>Turf</MenuItem>
          <MenuItem value={POLYGON_TYPES.ORNAMENTAL}>Ornamental</MenuItem>
          <MenuItem value={POLYGON_TYPES.BARE_GROUND}>Bare Ground</MenuItem>
        </TextField>
        <TextField
          fullWidth
          margin="dense"
          select
          name="type"
          value={newArea.type || 'area'}
          onChange={onInputChange}
          label="Area Type"
          variant="outlined"
          helperText="Main areas are included in calculations, subtraction areas are excluded"
          className={styles.formField}
        >
          <MenuItem value="area">Main Area</MenuItem>
          <MenuItem value="subtraction">Subtraction Area</MenuItem>
        </TextField>
        {newArea.type === 'subtraction' && (
          <TextField
            fullWidth
            margin="dense"
            select
            name="parentArea"
            value={newArea.parentArea || ''}
            onChange={onInputChange}
            label="Parent Area"
            variant="outlined"
            helperText="Select the main area this subtraction applies to"
            className={styles.formField}
          >
            {polygons
              .filter(p => p.type === 'area')
              .map(p => (
                <MenuItem key={p.id || p.name} value={p.name}>{p.name}</MenuItem>
              ))
            }
          </TextField>
        )}
        <div className={styles.colorPickers}>
          <TextField
            margin="dense"
            name="strokeColor"
            label="Outline Color"
            type="color"
            fullWidth
            value={newArea.strokeColor || '#000000'}
            onChange={onInputChange}
            variant="outlined"
          />
          <TextField
            margin="dense"
            name="fillColor"
            label="Fill Color"
            type="color"
            fullWidth
            value={newArea.fillColor || '#000000'}
            onChange={onInputChange}
            variant="outlined"
          />
        </div>
        
        {newArea.paths && newArea.paths.length > 0 && (
          <Paper style={{ padding: '8px', marginTop: '16px' }}>
            <Typography variant="body2">
              Area: {getPolygonArea(newArea.paths).areaFeet.toFixed(2)} sq ft
            </Typography>
          </Paper>
        )}
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={handleContinueEditing} 
          color="primary"
        >
          Continue Editing
        </Button>
        <Button 
          onClick={onClose} 
          color="primary"
        >
          Cancel
        </Button>
        {selectedPolygon !== null ? (
          <>
            <Button onClick={onCopy} color="primary">
              <ContentCopy fontSize="small" style={{ marginRight: '4px' }} />
              Copy
            </Button>
            <Button onClick={onDelete} color="secondary">
              <Delete fontSize="small" style={{ marginRight: '4px' }} />
              Delete
            </Button>
            <Button onClick={onSave} color="primary" variant="contained">
              Update
            </Button>
          </>
        ) : (
          <Button onClick={onSave} color="primary" variant="contained">
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
});

// Set display name for debugging
DialogForm.displayName = 'DialogForm';

export default DialogForm;
