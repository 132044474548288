import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// import styles from './customer.module.scss'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  },
  buttonGroup: {
    margin: '2em'
  }
}));

const defaultInputValues = {
  projectName: ''
}

const statusOptions = [
  'New',
  'Pending Meeting',
  'Pending Estimate',
  'Estimate Sent',
  'Pending Revisions',
  'Revisions Sent',
  'Pending Plans',
  'Pending Deposit',
  'Pending HOA Sub.',
  'In Progress',
  'Pending Progress Payment',
  'Pending Final Walk',
  'Pending Final Payment',
  'Pending 3 Month',
  'Complete'
]

const defaultStateVals = {
  status: 'New',
  projectName: '',
  projectType: null,
  project: {}
}

const defaultRefValue = {}

const TrackProjectModal = (props) => {
// console.log('TrackProjectModal  Props: ', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [client, setClient] = React.useState(props.client)
  const [projects, setProjects] = React.useState([])
  const [state, setState] = React.useState(defaultStateVals)
  const inputRef = React.useRef(defaultRefValue)

  React.useEffect(() => {
    let isMounted = true
    const fetchProjects = async () => {
      axios({
        method: 'get',
        url: `${props.url}/api/projects/listProjects?clientId=${props.client._id}`
      }).then(response => {
        // console.log('Got our estimates; ', response.data)
        if (isMounted) {
          setProjects(response.data.projects)
        }
      })
    }
    fetchProjects()
    return () => {
      isMounted = false
    }
  }, [])

  const postData = async (data, isNew) => {
  // console.log('Post Data:', data)
    if (isNew) {
          axios({
      method: 'post',
      url:  `${props.url}/api/projects/newProject`,
      data: data
    }).then(response => {
      setLoading(false)
      setClient({})
      setState({...state, status: 'New', projectName: '', project: null})
      if (response.data && response.data._id) {
        props.notification({
          type: 'success',
          title: 'Project Saved',
          message: 'That Project Has Been Saved. Great Job!'
        })
        props.updateParent(response.data)
        // props.closeModal()
      }
    })
  } else {
  // console.log('Active project', data)
    props.updateParent(data)
  }
  }

  const handleClose = () => {
    // props.closeModal()
    setState(defaultStateVals)
    inputRef.current = defaultRefValue
    props.updateParent()
  }

  const handleSave = () => {
  // console.log(inputRef)
    setLoading(true)
    let data = null
    if (state.projectType === 'new') {
      data = inputRef.current
      data.client = props.client
      postData(data, true)
    } else if (state.projectType === 'existing') {
      data = state.project
      postData(data)
    } else {
    // console.log('No Project Linking')
      // props.closeModal()
    }
    setState(defaultStateVals)
    inputRef.current = defaultRefValue
    // let data = {
    //   data
    // }
  }

  const updateCustomer = (e, customer) => {
    if (customer && customer._id) {
      setClient(customer)
      inputRef.current.client = customer
    }
  }

  const updateSalesRep = (e, rep) => {
    if (rep && rep._id) {
      inputRef.current.salesRep = rep
    }
  }
  const handleTabInteraction = (e) => {
    if (e.keyCode == 9) {
    // console.log(e.target)
      if (e.target.id === 'customername') {
        // window.alert(`Tab on custoemr ${e.target.value}`)
        let newArr = []
        let string = e.target.value
        props.clients.filter(o => {
          let str = o['displayName'].toString().toLowerCase()
          if (str.includes(string.toLowerCase())) {
            return newArr.push(o)
          } else {
            return null
          }
        })
        // console.log(newArr)
        if (newArr && newArr.length) {
          setClient(newArr[0])
        }
      }
    }
  }

  const updateStatus = (e, status) => {
    inputRef.current.status = status
  }

  const updateRef = (e) => {
    inputRef.current[e.target.id] = e.target.value
    const ourProject = {
      ...inputRef.current
    }
    if (ourProject?.name || ourProject?.salesRep) {
      setState(prev => ({...prev, project: {...prev.project, ...ourProject}}))
    }
    // setState(prev => ({...prev, project: {...prev.project, ...ourProject}}))
  }

  const handleProject = (e, type) => {
    if (type === 'existing' && !projects) {

    }
    setState({...state, project: {}, projectType: type })
  }
  // console.log('276 state', state)
  // console.log('ref', inputRef)
  return (
    <div className={classes.root}>
      <Dialog open={props.open} fullWidth={false} onClose={props.closeModal} maxWidth='sm' aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Track This Job As a Project</DialogTitle>
          <DialogContent >
            <DialogContentText>
              Should Associate A Project To These Jobs?
            </DialogContentText>
            <ButtonGroup className={classes.buttonGroup} size="large" color="primary" aria-label="large outlined primary button group">
              <Button id="existing" onClick={e => handleProject(e, 'existing')}>Link to Existing</Button>
              <Button id="new" onClick={e => handleProject(e, 'new')}>Create New</Button>
              {/* <Button id="none" onClick={e => handleProject(e, 'none')}>No</Button> */}
            </ButtonGroup>
            {
              (state.projectType && state.projectType === 'new') ? (
                <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    id="name"
                    label="Project Name"
                    // style={{ margin: 8 }}
                    // value={inputRef.projectName}
                    onChange={updateRef}
                    // onChange={e=> setState({...state, projectName: e.target.value})}
                    // ref={inputRef['projectName']}
                    placeholder="Project Name"
                    helperText="What Should We Call This Project Internally?"
                    fullWidth
                    // value={inputRef.current['projectName']}
                    margin="normal"
                    variant="outlined"
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Autocomplete
                    id={`status`}
                    options={statusOptions || []}
                    getOptionLabel={(option) => {
                      // console.log('Options: ', option)
                      return option || ''
                    }}
                    // style={{ width: 200 }}
                    // style={{marginBottom: '20px'}}
                    onChange={updateStatus}
                    disabled={loading}
                    // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                    // value = {state.status}
                    // ref={inputRef.status}
                    // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                    renderInput={(params) => <TextField {...params} label="Project Status" helperText="What Is The Project Status?" onKeyDown={handleTabInteraction} variant="outlined" />}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Autocomplete
                    id={`salesperson`}
                    options={props.salesReps || []}
                    getOptionLabel={(option) => {
                    // console.log('Options: ', option)
                      return option.displayName || ''
                    }}
                    // style={{ width: 200 }}
                    // style={{marginBottom: '20px'}}
                    onChange={updateSalesRep}
                    disabled={loading}
                    // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                    // value = {state.status}
                    // ref={inputRef.salesperson}
                    // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                    renderInput={(params) => <TextField {...params} label="Salesperson" helperText="Who Is The Assigned Salesperson?" onKeyDown={handleTabInteraction} variant="outlined" />}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    id="description"
                    label="Project Description"
                    // style={{ margin: 8 }}
                    // ref={inputRef.projectDescription}
                    placeholder="Project Description"
                    helperText="What Is This Project?"
                    fullWidth
                    multiline
                    rows={3}
                    value={inputRef.current['description']}
                    onChange={updateRef}
                    margin="normal"
                    // value=""
                    variant="outlined"
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                  />
                </Grid>
              </Grid>
              ) : null
            }
            {
              (state.projectType && state.projectType === 'existing') ? (
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                  <FormControl variant="outlined" style={{width: '100%'}}>
                    <InputLabel id="demo-simple-select-outlined-label">Link Job(s) To Existing Project</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={state.project}
                      onChange={e=>setState({...state, project: e.target.value})}
                      label="Unit"
                    >
                      {
                        projects.map(project => {
                          return (
                          <MenuItem key={project._id} value={project} >{`${project.name} - ${moment(project.created_at).format('MM/DD/YYYY - hh:mm a')}`}</MenuItem>
                        )})
                      }
                      {/* {props.item && props.item.unitsOfMeasure && props.item.unitsOfMeasure.length ? (
                        // props.item.unitsOfMeasure
                        unitsOfMeasureAll.map(unit => {
                          return (
                          <MenuItem key={unit.name} value={unit.name} >{unit.name}</MenuItem>
                        )})
                      ) : (
                        <MenuItem value={'EA'} >Each</MenuItem>
                      )} */}
                    </Select>
                  </FormControl>
                  </Grid>
                </Grid>
              ) : null
            }

          </DialogContent>
          <DialogActions>
            {/* <Button onClick={() => setState({...state, editJobName: false})} color="primary">
              Cancel
            </Button> */}
            <Button color="primary" disabled={loading || (!state.project || !state?.project?.name)} onClick={handleSave}>
              {
                loading ? <CircularProgress /> : 'Save'
              }
            </Button>
            <Button color="primary" disabled={loading} onClick={handleClose}>
              No Thanks
            </Button>
          </DialogActions>
        </Dialog>
    </div>
  )
}


  const mapStateToProps = (state) => {
  // console.log('Redux state:', state)
    return {
      url: state.url,
      user: state.user,
      notification: state.notification,
      client: state.client,
      salesReps: state.salesReps
    }
  }
  
  export default connect(mapStateToProps)(TrackProjectModal)