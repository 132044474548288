import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  IconButton,
  Tooltip,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Warning from '@material-ui/icons/Warning';
import clsx from 'clsx';
import {
  WorkorderModal,
  InvoiceModal
} from './CreationModals'

const currencyFormat = (num) => {
  return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

const handleFocus = (event) => event.target.select();

const calculateUsedQuantity = (lineItemId, workorders = []) => {
  // console.log('Calculate used qty', lineItemId)
  // console.log(workorders)
  return workorders.reduce((total, wo) => {
    const lineItem = wo.lineItems?.find(item => item._id === lineItemId);
    return total + (lineItem ? parseFloat(lineItem.qty) || 0 : 0);
  }, 0);
};

const getRemainingQty = (item, workorders = []) => {
  const originalQty = parseFloat(item.qty) || 0;
  const usedQty = calculateUsedQuantity(item._id, workorders);
  return Math.max(0, originalQty - usedQty);
};

const calculateInvoicedAmount = (lineItemId, invoices = []) => {
  return invoices.reduce((total, invoice) => {
    const lineItem = invoice.estimateLineItems?.find(i => i._id === lineItemId);
    return total + (lineItem ? parseFloat(lineItem.invoiceAmount) || 0 : 0);
  }, 0);
};

const getRemainingAmount = (item, invoices = []) => {
  const totalAmount = parseFloat(item.totalPrice) || 0;
  const invoicedAmount = calculateInvoicedAmount(item._id, invoices);
  return Math.max(0, totalAmount - invoicedAmount);
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2)
  },
  tableContainer: {
    maxHeight: 440,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  },
  totalRow: {
    backgroundColor: theme.palette.action.hover
  },
  quantityCell: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  quantityField: {
    width: 80
  },
  typeSelect: {
    width: 100,
    marginRight: theme.spacing(1)
  },
  progress: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  remainingQuantity: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
    marginTop: theme.spacing(0.5)
  },
  modalContent: {
    padding: theme.spacing(2),
  },
  modalTable: {
    marginTop: theme.spacing(3),
  },
  formSection: {
    marginBottom: theme.spacing(3),
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  divider: {
    margin: theme.spacing(3, 0),
  },
  tableTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  // Add some spacing between form fields
  formField: {
    marginBottom: theme.spacing(2),
  },
  // Make the dialog larger
  dialogPaper: {
    minHeight: '80vh',
  },
  overAllocated: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
  },
  warningRow: {
    // backgroundColor: theme.palette.error.light,

    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  },
  overAllocatedProgress: {
    '& .MuiLinearProgress-bar': {
      backgroundColor: theme.palette.error.main,
      color: '#fff'
    },
  },
  quantityInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(0.5),
  },
  warningIcon: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(1),
  },
  quantityDetails: {
    marginLeft: theme.spacing(2),
  },
  overAllocatedText: {
    color: theme.palette.error.main,
    fontWeight: 500
  },
  summaryPaper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
  },
  progressSection: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius,
    height: '90px'
  },
  progressHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
}));

// const QuantitySelectionModal = ({ 
//   open, 
//   onClose, 
//   selectedItems, 
//   quantities, 
//   quantityTypes, 
//   onQuantityChange, 
//   onTypeChange, 
//   onConfirm, 
//   lineItems ,
//   serviceTypes,
//   validationErrors,
//   workorders
// }) => {
//   const classes = useStyles();
//   const [formData, setFormData] = useState({
//     name: '',
//     serviceType: null,
//     description: '',
//     notes: '',
//     workorderNotes: '',
//   });

//   const renderQuantityDetails = (item) => {
//     const remainingQty = getRemainingQty(item, workorders);
//     const usedQty = calculateUsedQuantity(item._id, workorders);
//     const originalQty = parseFloat(item.qty);
//     const isOverAllocated = usedQty > originalQty;
  
//     return (
//       <div className={classes.quantityDetails}>
//         <Typography variant="caption" display="block">
//           Original: {originalQty} {item.unit}
//         </Typography>
//         <Typography 
//           variant="caption" 
//           display="block"
//           className={isOverAllocated ? classes.overAllocatedText : undefined}
//         >
//           Used: {usedQty.toFixed(2)} {item.unit}
//           {isOverAllocated && ' (Over-allocated)'}
//         </Typography>
//         <Typography 
//           variant="caption" 
//           display="block" 
//           color={remainingQty >= 0 ? "textSecondary" : "error"}
//           className={remainingQty < 0 ? classes.overAllocatedText : undefined}
//         >
//           Remaining: {remainingQty.toFixed(2)} {item.unit}
//         </Typography>
//         {validationErrors[item._id] && (
//           <Typography variant="caption" color="error">
//             {validationErrors[item._id]}
//           </Typography>
//         )}
//       </div>
//     );
//   };

//   const handleFormChange = (field) => (event) => {
//     setFormData({
//       ...formData,
//       [field]: event.target.value
//     });
//   };

//   const handleServiceTypeChange = (event, newValue) => {
//     setFormData({
//       ...formData,
//       serviceType: newValue
//     });
//   };

//   const handleSubmit = () => {
//     onConfirm(formData);
//   };

//   const isFormValid = () => {
//     return formData.name && formData.serviceType && formData.description;
//   };

//   const getRemainingQty = (item) => {
//     return typeof item.remainingQty === 'number' ? item.remainingQty : parseFloat(item.qty);
//   };

//   const calculateAdjustedTotal = (item) => {
//     const type = quantityTypes[item._id];
//     const quantity = quantities[item._id] || 0;
//     const pricePerUnit = parseFloat(item.pricePer) || 0;
    
//     if (type === 'percentage') {
//       const actualQty = (getRemainingQty(item) * quantity) / 100;
//       return pricePerUnit * actualQty;
//     }
//     return pricePerUnit * quantity;
//   };

//   const getTotalSelected = () => {
//     return selectedItems.reduce((sum, item) => sum + calculateAdjustedTotal(item), 0);
//   };


//   return (
//     <Dialog 
//       open={open} 
//       onClose={onClose} 
//       maxWidth="lg" 
//       fullWidth
//       classes={{ paper: classes.dialogPaper }}
//     >
//       <DialogTitle>Create New Work Order</DialogTitle>
//       <DialogContent className={classes.modalContent}>
//         <div className={classes.formSection}>
//           <Typography variant="h6" className={classes.sectionTitle}>
//             Work Order Details
//           </Typography>
//           <Grid container spacing={3}>
//             <Grid item xs={12} md={6}>
//               <TextField
//                 className={classes.formField}
//                 label="Work Order Name"
//                 variant="outlined"
//                 fullWidth
//                 onFocus={handleFocus}
//                 value={formData.name}
//                 onChange={handleFormChange('name')}
//                 // required
//                 helperText="Enter a descriptive name for this work order"
//               />
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <Autocomplete
//                 value={formData.serviceType}
//                 onChange={handleServiceTypeChange}
//                 getOptionLabel={(option) => {
//                   // console.log('Options: ', option)
//                   return `${option.name ? option.name : 'Unknown Service'} (${option.serviceCode})` || ''
//                 }}
//                 options={serviceTypes || []}
//                 renderInput={(params) => (
//                   <TextField
//                     {...params}
//                     label="Service Type"
//                     variant="outlined"
//                     onFocus={handleFocus}
//                     // required
//                     helperText="Select the type of service"
//                   />
//                 )}
//                 className={classes.formField}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 className={classes.formField}
//                 label="Description"
//                 variant="outlined"
//                 onFocus={handleFocus}
//                 fullWidth
//                 multiline
//                 rows={2}
//                 value={formData.description}
//                 onChange={handleFormChange('description')}
//                 // required
//                 helperText="Description of Workorder (Customer Will See on Invoice)"
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField
//                 className={classes.formField}
//                 label="Workorder Notes"
//                 variant="outlined"
//                 fullWidth
//                 multiline
//                 onFocus={handleFocus}
//                 rows={3}
//                 value={formData.workorderNotes}
//                 onChange={handleFormChange('workorderNotes')}
//                 helperText="Notes for the Workorder (Field Workers Will See in App and on PDF"
//               />
//             </Grid>
//           </Grid>
//         </div>

//         <Divider className={classes.divider} />

//         <Typography variant="h6" className={classes.tableTitle}>
//           Line Items and Quantities
//         </Typography>
        
//         <TableContainer className={classes.modalTable}>
//           <Table>
//             <TableHead>
//               <TableRow>
//                 <TableCell>Type</TableCell>
//                 <TableCell>Description</TableCell>
//                 <TableCell align="right">Original Qty</TableCell>
//                 <TableCell>Assignment</TableCell>
//                 <TableCell>Unit</TableCell>
//                 {/* <TableCell align="right">Price/Unit</TableCell>
//                 <TableCell align="right">Total</TableCell> */}
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {selectedItems.map((item) => {
//                 const remainingQty = getRemainingQty(item);
//                 return (
//                   <TableRow key={item._id}>
//                     <TableCell>{item.lineItemType}</TableCell>
//                     <TableCell>{item.description}</TableCell>
//                     <TableCell align="right">{item.qty}</TableCell>
//                     <TableCell>
//                       <div className={classes.quantityCell}>
//                         <FormControl className={classes.typeSelect}>
//                           <Select
//                             value={quantityTypes[item._id]}
//                             onChange={(e) => onTypeChange(item._id, e.target.value)}
//                           >
//                             <MenuItem value="quantity">Qty</MenuItem>
//                             <MenuItem value="percentage">%</MenuItem>
//                           </Select>
//                         </FormControl>
//                         <TextField
//                           className={classes.quantityField}
//                           type="number"
//                           error={!validationErrors[item._id]}
//                           onFocus={handleFocus}
//                           value={quantities[item._id]}
//                           onChange={(e) => onQuantityChange(item._id, parseFloat(e.target.value) || 0)}
//                           variant="outlined"
//                           size="small"
//                         />
//                         {renderQuantityDetails(item)}
//                       </div>
//                     </TableCell>
//                     <TableCell>{item.unit}</TableCell>
//                     <TableCell align="right">{currencyFormat(item.pricePer)}</TableCell>
//                     <TableCell align="right">
//                       {currencyFormat(calculateAdjustedTotal(item))}
//                     </TableCell>
//                   </TableRow>
//                 );
//               })}
//               <TableRow className={classes.totalRow}>
//                 <TableCell colSpan={6} align="right">
//                   <strong>Total:</strong>
//                 </TableCell>
//                 <TableCell align="right">
//                   <strong>{currencyFormat(getTotalSelected())}</strong>
//                 </TableCell>
//               </TableRow>
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={onClose} color="primary">
//           Cancel
//         </Button>
//         <Button 
//           onClick={handleSubmit} 
//           color="primary" 
//           variant="contained"
//           disabled={!isFormValid()}
//         >
//           Create Work Order
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

// const QuantitySelectionModal = ({ open, onClose, selectedItems, quantities, quantityTypes, onQuantityChange, onTypeChange, onConfirm, lineItems, serviceTypes }) => {
//   const classes = useStyles();

//   const getRemainingQty = (item) => {
//     return typeof item.remainingQty === 'number' ? item.remainingQty : parseFloat(item.qty);
//   };

//   const calculateAdjustedTotal = (item) => {
//     const type = quantityTypes[item._id];
//     const quantity = quantities[item._id] || 0;
//     const pricePerUnit = parseFloat(item.pricePer) || 0;
    
//     if (type === 'percentage') {
//       const actualQty = (getRemainingQty(item) * quantity) / 100;
//       return pricePerUnit * actualQty;
//     }
//     return pricePerUnit * quantity;
//   };

//   const getTotalSelected = () => {
//     return selectedItems.reduce((sum, item) => sum + calculateAdjustedTotal(item), 0);
//   };

//   return (
//     <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
//       <DialogTitle>Assign Quantities for Work Order</DialogTitle>
//       <DialogContent className={classes.modalContent}>
//         <TableContainer>
//           <Table className={classes.modalTable}>
//             <TableHead>
//               <TableRow>
//                 <TableCell>Type</TableCell>
//                 <TableCell>Description</TableCell>
//                 <TableCell align="right">Original Qty</TableCell>
//                 <TableCell>Assignment</TableCell>
//                 <TableCell>Unit</TableCell>
//                 <TableCell align="right">Price/Unit</TableCell>
//                 <TableCell align="right">Total</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {selectedItems.map((item) => {
//                 const remainingQty = getRemainingQty(item);
//                 return (
//                   <TableRow key={item._id}>
//                     <TableCell>{item.lineItemType}</TableCell>
//                     <TableCell>{item.description}</TableCell>
//                     <TableCell align="right">{item.qty}</TableCell>
//                     <TableCell>
//                       <div className={classes.quantityCell}>
//                         <FormControl className={classes.typeSelect}>
//                           <Select
//                             value={quantityTypes[item._id]}
//                             onChange={(e) => onTypeChange(item._id, e.target.value)}
//                           >
//                             <MenuItem value="quantity">Qty</MenuItem>
//                             <MenuItem value="percentage">%</MenuItem>
//                           </Select>
//                         </FormControl>
//                         <TextField
//                           className={classes.quantityField}
//                           type="number"
//                           onFocus={handleFocus}
//                           value={quantities[item._id]}
//                           onChange={(e) => onQuantityChange(item._id, parseFloat(e.target.value) || 0)}
//                           variant="outlined"
//                           size="small"
//                         />
//                         <Tooltip title={
//                           quantityTypes[item._id] === 'percentage'
//                             ? `Will create workorder with ${((remainingQty * quantities[item._id]) / 100).toFixed(2)} ${item.unit}`
//                             : `Remaining quantity: ${remainingQty} ${item.unit}`
//                         }>
//                           <IconButton size="small">
//                             <InfoIcon fontSize="small" />
//                           </IconButton>
//                         </Tooltip>
//                       </div>
//                     </TableCell>
//                     <TableCell>{item.unit}</TableCell>
//                     <TableCell align="right">{currencyFormat(item.pricePer)}</TableCell>
//                     <TableCell align="right">
//                       {currencyFormat(calculateAdjustedTotal(item))}
//                     </TableCell>
//                   </TableRow>
//                 );
//               })}
//               <TableRow className={classes.totalRow}>
//                 <TableCell colSpan={6} align="right">
//                   <strong>Total:</strong>
//                 </TableCell>
//                 <TableCell align="right">
//                   <strong>{currencyFormat(getTotalSelected())}</strong>
//                 </TableCell>
//               </TableRow>
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={onClose} color="primary">
//           Cancel
//         </Button>
//         <Button onClick={onConfirm} color="primary" variant="contained">
//           Create Work Order
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

const ProposalLineItems = ({ lineItems = [], onCreateWorkorder, serviceTypes = [], workorders = [], invoices = [], onCreateInvoice }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [quantities, setQuantities] = useState({});
  const [quantityTypes, setQuantityTypes] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null); 
  const [validationErrors, setValidationErrors] = useState({});
  // console.log('Proposal line items - workorders', workorders)

  const getProgressInfo = (item) => {
    const originalQty = parseFloat(item.qty) || 0;
    const usedQty = calculateUsedQuantity(item._id, workorders);
    const qtyPercentage = ((usedQty) / originalQty) * 100;

    const totalAmount = parseFloat(item.totalPrice) || 0;
    const invoicedAmount = calculateInvoicedAmount(item._id, invoices);
    const amountPercentage = ((invoicedAmount) / totalAmount) * 100;

    const isOverAllocatedQty = usedQty > originalQty;
    const isOverInvoiced = invoicedAmount > totalAmount;

    return {
      usedQty,
      qtyPercentage,
      invoicedAmount,
      amountPercentage,
      isOverAllocatedQty,
      isOverInvoiced
    };
  };

  const getProgressPercentage = (item) => {
    const originalQty = parseFloat(item.qty) || 0;
    const usedQty = calculateUsedQuantity(item._id, workorders);
    return ((usedQty) / originalQty) * 100;
  };

  const handleQuantityChange = (id, value) => {
    const item = lineItems.find(item => item._id === id);
    let newValue = value;
    const remainingQty = getRemainingQty(item, workorders);
    
    if (quantityTypes[id] === 'percentage') {
      newValue = Math.min(100, Math.max(0, value));
    } else {
      newValue = Math.min(remainingQty, Math.max(0, value));
    }

    // Validate new quantity
    const errors = { ...validationErrors };
    if (newValue > remainingQty) {
      errors[id] = `Cannot exceed remaining quantity of ${remainingQty}`;
    } else {
      delete errors[id];
    }
    setValidationErrors(errors);

    setQuantities({
      ...quantities,
      [id]: newValue
    });
  };

  const handleQuantityTypeChange = (id, type) => {
    const item = lineItems.find(item => item._id === id);
    const remainingQty = getRemainingQty(item, workorders);
    
    setQuantityTypes({
      ...quantityTypes,
      [id]: type
    });
    
    setQuantities({
      ...quantities,
      [id]: type === 'percentage' ? 100 : remainingQty
    });
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const availableIds = lineItems
        .filter(item => isItemAvailable(item))
        .map(item => item._id);
      
      setSelected(availableIds);
    } else {
      setSelected([]);
    }
  };
  const handleSelect = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, id];
    } else {
      newSelected = selected.filter(itemId => itemId !== id);
    }

    setSelected(newSelected);
  };

  // const handleModalOpen = () => {
  //   const newQuantities = { ...quantities };
  //   const newTypes = { ...quantityTypes };
    
  //   selected.forEach(id => {
  //     const item = lineItems.find(item => item._id === id);
  //     const remainingQty = getRemainingQty(item, workorders);
      
  //     if (!newQuantities[id]) {
  //       newQuantities[id] = remainingQty;
  //     }
  //     if (!newTypes[id]) {
  //       newTypes[id] = 'quantity';
  //     }
  //   });
    
  //   setQuantities(newQuantities);
  //   setQuantityTypes(newTypes);
  //   setModalOpen(true);
  // };

  const handleInvoiceCreation = (d) => {
    console.log('Create an Invoice', d)
    setModalOpen(false)
    onCreateInvoice(d)
  }

  const handleWorkorderCreation = (d) => {
    console.log('Create a Workorder', d)
    setModalOpen(false)
    onCreateWorkorder(d)
  }


  const handleConfirmWorkorder = (formData) => {
    const selectedItems = lineItems
      .filter(item => selected.includes(item._id))
      .map(item => {
        const quantity = quantityTypes[item._id] === 'percentage'
          ? (getRemainingQty(item) * quantities[item._id] / 100)
          : quantities[item._id];
  
        return {
          ...item,
          originalQty: item.qty,
          qty: quantity,
        };
      });
  
    onCreateWorkorder({
      ...formData,
      items: selectedItems,
      total: selectedItems.reduce((sum, item) => sum + (item.qty * parseFloat(item.pricePer)), 0)
    });
    setModalOpen(false);
  };


  const handleModalOpen = (type) => {
    setModalType(type);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setModalType(null);
  };

  const isItemAvailable = (item) => {
    // Item is available if it has remaining quantity OR if it has no quantity set
    return getRemainingQty(item, workorders) > 0 || !item.qty;
  };

  return (
    <div className={classes.root}>
       <JobSummaryStats 
        lineItems={lineItems}
        workorders={workorders}
        invoices={invoices}
      />
      <div className={classes.header}>
        <Typography variant="h6">Proposal Line Items</Typography>
        <div>
          <Button
            variant="contained"
            color="primary"
            disabled={selected.length === 0}
            onClick={() => handleModalOpen('workorder')}
            style={{ marginRight: 10 }}
          >
            Create Workorder ({selected.length} items)
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={selected.length === 0}
            onClick={() => handleModalOpen('invoice')}
          >
            Create Invoice ({selected.length} items)
          </Button>
        </div>
      </div>
      
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selected.length > 0 && 
                    selected.length < lineItems.filter(item => isItemAvailable(item)).length
                  }
                  checked={
                    selected.length > 0 && 
                    selected.length === lineItems.filter(item => isItemAvailable(item)).length
                  }
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell>Unit</TableCell>
              <TableCell align="right">Price/Unit</TableCell>
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lineItems.map((item) => {
              const progress = getProgressInfo(item);
              const remainingQty = getRemainingQty(item, workorders);
              const progressPercentage = getProgressPercentage(item);
              const usedQty = calculateUsedQuantity(item._id, workorders);
              const isFullyAssigned = remainingQty === 0;
              const isOverAllocated = usedQty > parseFloat(item.qty);
              // console.log('item', item)
              // console.log('Progress:', progress)
              // console.log('Remaining Qty: %s   Original: %s -- Used qty', remainingQty, item.qty, usedQty)
              return (
                <TableRow 
                key={item._id}
                hover
                selected={selected.includes(item._id)}
                className={progress.isOverAllocatedQty || progress.isOverInvoiced ? classes.warningRow : undefined}
              >
                <TableCell padding="checkbox">
                  
                  <Checkbox 
                    checked={selected.includes(item._id)}
                    onChange={() => handleSelect(item._id)}
                    // disabled={!isItemAvailable(item)}
                  />
                </TableCell>
                <TableCell>{item.lineItemType}</TableCell>
                <TableCell>
                    {item.description}
                    <div className={classes.progress}>
                      <Typography variant="caption">Quantity Usage:</Typography>
                      <LinearProgress 
                        variant="determinate" 
                        value={Math.min(progress.qtyPercentage, 100)}
                        className={progress.isOverAllocatedQty ? classes.overAllocatedProgress : undefined}
                      />
                      <Typography variant="caption">Invoice Amount:</Typography>
                      <LinearProgress 
                        variant="determinate" 
                        value={Math.min(progress.amountPercentage, 100)}
                        className={progress.isOverInvoiced ? classes.overAllocatedProgress : undefined}
                        style={{ marginTop: 4 }}
                      />
                      <div className={classes.quantityInfo}>
                        <Typography className={classes.remainingQuantity}>
                          {progress.isOverAllocatedQty && (
                            <Tooltip title="This item is over-allocated">
                              <Warning className={classes.warningIcon} fontSize="small" />
                            </Tooltip>
                          )}
                          Used: {progress.usedQty.toFixed(2)} {item.unit}
                          {progress.isOverAllocatedQty && ' (Exceeds original quantity)'}
                        </Typography>
                        <Typography className={classes.remainingQuantity}>
                          Invoiced: {currencyFormat(progress.invoicedAmount)}
                          {progress.isOverInvoiced && ' (Exceeds total amount)'}
                        </Typography>
                      </div>
                    </div>
                  </TableCell>
                {/* <TableCell>
                  {item.description}
                  <div className={classes.progress}>
                    <LinearProgress 
                      variant="determinate" 
                      value={Math.min(progressPercentage, 100)}
                      className={isOverAllocated ? classes.overAllocatedProgress : undefined}
                    />
                    <div className={classes.quantityInfo}>
                      <Typography className={clsx(
                        classes.remainingQuantity,
                        isOverAllocated && classes.overAllocatedText
                      )}>
                        {isOverAllocated && (
                          <Tooltip title="This item is over-allocated">
                            <Warning className={classes.warningIcon} fontSize="small" />
                          </Tooltip>
                        )}
                        Used: {usedQty.toFixed(2)} {item.unit}
                        {isOverAllocated && ' (Exceeds original quantity)'}
                      </Typography>
                      <Typography className={clsx(
                        classes.remainingQuantity,
                        remainingQty < 0 && classes.overAllocatedText
                      )}>
                        Remaining: {remainingQty.toFixed(2)} {item.unit}
                      </Typography>
                    </div>
                  </div>
                </TableCell> */}
                <TableCell align="right">
                  <Typography className={isOverAllocated ? classes.overAllocatedText : undefined}>
                    {item.qty}
                  </Typography>
                </TableCell>
                <TableCell>{item.unit}</TableCell>
                <TableCell align="right">{currencyFormat(item.pricePer)}</TableCell>
                <TableCell align="right">{currencyFormat(item.totalPrice)}</TableCell>
              </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <QuantitySelectionModal
        open={modalOpen}
        onClose={handleModalClose}
        selectedItems={lineItems.filter(item => selected.includes(item._id))}
        quantities={quantities}
        quantityTypes={quantityTypes}
        onQuantityChange={handleQuantityChange}
        onTypeChange={handleQuantityTypeChange}
        onConfirm={handleConfirmWorkorder}
        lineItems={lineItems}
        serviceTypes={serviceTypes}
        validationErrors={validationErrors}
        workorders={workorders}
      /> */}
      {modalType === 'workorder' && (
        <WorkorderModal 
          open={modalOpen}
          onClose={handleModalClose}
          selectedItems={lineItems.filter(item => selected.includes(item._id))}
          serviceTypes={serviceTypes}
          workorders={workorders}
          onConfirm={handleWorkorderCreation}
          classes={classes}
          calculateUsedQuantity={calculateUsedQuantity}
        />
      )}

      {modalType === 'invoice' && (
        <InvoiceModal
          open={modalOpen}
          onClose={handleModalClose}
          selectedItems={lineItems.filter(item => selected.includes(item._id))}
          invoices={invoices}
          onConfirm={handleInvoiceCreation}
          classes={classes}
        />
      )}
    </div>
  );
};

const JobSummaryStats = ({ lineItems, workorders, invoices }) => {
  const classes = useStyles();

  // Calculate overall progress metrics
  const calculateOverallProgress = () => {
    const totals = lineItems.reduce((acc, item) => {
      const originalAmount = parseFloat(item.totalPrice) || 0;
      const originalQty = parseFloat(item.qty) || 0;
      const usedQty = calculateUsedQuantity(item._id, workorders);
      const invoicedAmount = calculateInvoicedAmount(item._id, invoices);

      return {
        totalAmount: acc.totalAmount + originalAmount,
        invoicedAmount: acc.invoicedAmount + invoicedAmount,
        totalQty: acc.totalQty + originalQty,
        allocatedQty: acc.allocatedQty + usedQty
      };
    }, { totalAmount: 0, invoicedAmount: 0, totalQty: 0, allocatedQty: 0 });

    return {
      invoiceProgress: (totals.invoicedAmount / totals.totalAmount) * 100,
      workorderProgress: (totals.allocatedQty / totals.totalQty) * 100,
      totalAmount: totals.totalAmount,
      invoicedAmount: totals.invoicedAmount,
    };
  };

  const progress = calculateOverallProgress();

  return (
    <Paper className={classes.summaryPaper} elevation={2}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>Job Progress Summary</Typography>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <div className={classes.progressSection}>
            <div className={classes.progressHeader}>
              <Typography variant="subtitle1">Workorder Allocation</Typography>
              <Typography variant="h6">
                {Math.round(progress.workorderProgress)}%
              </Typography>
            </div>
            <LinearProgress 
              variant="determinate" 
              value={Math.min(progress.workorderProgress, 100)}
              className={progress.workorderProgress > 100 ? classes.overAllocatedProgress : undefined}
            />
            <Typography variant="caption" color="textSecondary">
              {progress.workorderProgress > 100 ? 'Over-allocated' : ''}
            </Typography>
          </div>
        </Grid>

        <Grid item xs={12} md={6}>
          <div className={classes.progressSection}>
            <div className={classes.progressHeader}>
              <Typography variant="subtitle1">Invoice Progress</Typography>
              <Typography variant="h6">
                {Math.round(progress.invoiceProgress)}%
              </Typography>
            </div>
            <LinearProgress 
              variant="determinate" 
              value={Math.min(progress.invoiceProgress, 100)}
              className={progress.invoiceProgress > 100 ? classes.overAllocatedProgress : undefined}
            />
            <Typography variant="caption" color="textSecondary">
              {currencyFormat(progress.invoicedAmount)} / {currencyFormat(progress.totalAmount)}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ProposalLineItems;
