import React from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import axios from 'axios'
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
import AdbIcon from '@material-ui/icons/Adb';
import { Button, CircularProgress }  from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
// import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/AddCircle';
// import FilterListIcon from '@material-ui/icons/FilterList';
import moment from 'moment'
import { Link } from 'react-router-dom'
// import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { socket } from '../SocketIo/Socket'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Rating from '@material-ui/lab/Rating';
import BigImageComponent from '../Uploads/BigImageComponent'
import SiteReviewDashboard from './SiteReviewDashboard';

function desc(a, b, orderBy, isDate) {
  // console.log('desc: ', a)
  // console.log('desc b: ', b)
  if (isDate) {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      // console.log('B is < A')
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      // console.log('B is > A')
      return 1;
    }
    // console.log('B is ? A')
    // console.log('A: ', a[orderBy])
    // console.log('B: ', b[orderBy])
    return 0;
  } else {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      return 1;
    }
    return 0;
  }
}

function stableSort(array, cmp) {
  // console.log('Stable sort: ', array.length)
  // console.log('CMP: ', cmp)
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  // console.log('Matches: ', stabilizedThis.length)
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  if (orderBy==='lastUpdated') {
  // console.log('Last Updated')
    return order === 'desc' ? (a, b) => desc(a, b, orderBy, true) : (a, b) => -desc(a, b, orderBy, true);
  } else {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }
  
}

const headCells = [
  { id: 'created_at', numeric: false, disablePadding: true, label: 'Created' },
  { id: 'jobSiteAddress', numeric: false, disablePadding: true, label: 'JobSite' },
  { id: 'reviewData', numeric: false, disablePadding: false, label: 'Rating' },
  { id: 'photos', numeric: false, disablePadding: false, label: 'Photos' },
];

function EnhancedTableHead(props) {
// console.log('Got our props', props)
  
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
  // console.log('Sort Property: ', property)
  // console.log('sort event; ', event)
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all leads' }}
          />
        </TableCell> */}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fakeLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue'
  },
  ratingContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 5,
    margin: 5
  },
  photoListContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
    flexGrow: 3
  },
  photoListContainerItem: {
    objectFit: 'contain',
    maxWidth: '150px',
    maxHeight: '200px'
  },
  cellContainerItem: {
    // textAlign: 'center'
  },
  cellContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start'
  }
}));

function EnhancedTable(props) {
// console.log('Site Review List ', props)
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('-created');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [searchValue, setsearchValue] = React.useState('');
  const [searchMatches, setsearchMatches] = React.useState(0);
  const [issueDetailsModalOpen, setIssueDetailsModalOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [rows, setRows] = React.useState(props.data || [])
  const [filteredRows, setFilteredRows] = React.useState([])
  const [state, setState] = React.useState({
    searchMatches: 0
  })
  const [showBigImages, setShowBigImages] = React.useState(false)
  const [activeItem, setActiveItem] = React.useState({})
  const [siteReviewData, setSiteReviewData] = React.useState([])

  React.useEffect(() => {
    let isMounted = true
    async function fetchReviews () {
      setLoading(true)
      axios.get(`${props.url}/api/hmi/fetchSiteReviews?includeCrew=1`).then((response, status, data) => {
        console.log('REsponse', response.data)
        setLoading(false)
        if (isMounted) {
          getUploads()
          setSiteReviewData(response.data)
          setRows(response.data)
          setFilteredRows(response.data)
         
        }
      })
    }
    fetchReviews()

    return () => {
      isMounted = false
    }
  }, [])


  const getUploads = async () => {
    console.log('get our uploads now..')
    axios.get(`${props.url}/api/hmi/fetchSiteReviews?getUploads=1`).then((response, status, data) => {
      console.log('REsponse to uploads...', response.data)
      // setLoading(false)
      if (response?.data?.length) {
        setSiteReviewData(response.data)
        setRows(response.data)
        setFilteredRows(response.data)
      }
    })
  }
  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
  // console.log('Set order by property: ' + property + ' in ' + isDesc + ' order')
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n._id);
      // console.log(newSelecteds)
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  // // console.log('handle click ', name)
  // // console.log('Index: ', selectedIndex)
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1),
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const storeSearchMatches = (d) => {
  // console.log('Search matches: ', d)
    // setState({...state, searchMatches: d.length})
  }
  // const handleChangeDense = event => {
  //   setDense(event.target.checked);
  // };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = 0// rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const filterOut = (r, searchText) => {
  // console.log('FIlter out: %s - %s', r, searchText)
    let string = searchText
    let newArr = []
    r.filter(o => {
      // console.log('Object:  ', o)
      Object.keys(o).some(k => {
        // console.log(o[k])
        if(o[k]) {
          // console.log('OK: ', o[k])
          let str = o[k].toString().toLowerCase() 
          // console.log(str)
          if (str.includes(string.toLowerCase())) {
            // console.log('Mathc')
            return newArr.push(o)
          } else {
            return null
          }
        } else {
          return null
        }
      })
      // return newArr
    })
    // return newArr
    // console.log('Lenght: ', newArr.length)
    // console.log(newArr)
    // storeSearchMatches(newArr)
    // setsearchMatches(newArr.length)
    // return newArr
    setFilteredRows(newArr)
    // let newArr = r.filter(i => {
    //   if (i.includes('test')) return i
    // })
    // return newArr
    // return r.filter((obj)=>{
    //   return Object.keys(obj).reduce((acc, curr)=>{
    //         return acc || obj[curr] ? obj[curr].toString().toLowerCase().includes(string) : obj[curr];
    //   }, false);
    // })
  }
  const handleChange = (name) => (event) => {
  // console.log('Searching: ', event.target.value)
    setsearchValue(event.target.value)
    if (event.target.value === '') {
      setFilteredRows(rows)
    } else {
      filterOut(rows, event.target.value)
    }
    setPage(0)
  }

  const openSvcModal = (svc) => {
  // console.log('Open Svc modal: ', svc)

  }
  
const updateFromSvcModal = (t, d) => {
// console.log('Update from service modal: ', d)
  if (t === 'closeModal') {
  // console.log('Close Modal')
  }
  if (t === 'update') {
    let services = rows
    // console.log('Got %d clients: ', clients.length)
    let index = services.findIndex(service =>  (service._id === d._id))
    if (index > -1) {
    // console.log('Svc already exists', index)
    // console.log(services[index])
      services[index] = d
    // console.log('Agai: ', services[index])
      // console.log(services)
      setRows(services)
      setFilteredRows(services)
    } else {
      services.push(d)
      setRows(services)
      setFilteredRows(services)
    }
    // setSvcModalOpen(false)
  }
}

const handleAddNewService = () => {

}

const PhotoLine = (props) => {
  let photos = props.photos
  return (
    <>
      { photos.map((item, index) => {
        if (item.uri) {
          const containHTTPS = item.uri.includes('https://')
          const uri = containHTTPS ? item.uri : 'https://' + item.uri
          return (
            <img key={index} style={{margin: 5, borderRadius: 15}} src={uri} width="140" />
          )
        } else {
          return (
            'Invalid URI'
          )
        }
      })}

    </>
  )
}

const handleViewBigImage = (uploadItem) => {
  console.log('Blow this bithc up', uploadItem)
  // let index = rows.findIndex()
  setActiveItem(uploadItem)
  setShowBigImages(true)
}

const handleCloseBigImageModal = () => {
  setActiveItem({})
  setShowBigImages(false)
}

  let images = []
  rows.forEach(item => {
    if (item && item.uploads && item.uploads.length) {
      // console.log('Item.uploads', item.uploads)
      item.uploads.forEach(upload => {
        let url = upload.url
        if (url && !url.includes('https')) url = `https://${upload.url}`
        images.push({
          url: url,
          _id: upload._id,
          name: upload.name,
          created_at: upload.created_at
        })
      })
    }
  })
  // if (activeItem && activeItem.uploads && activeItem.uploads.length) {
  //   activeItem.uploads.forEach(upload => {
  //     let url = upload
  //     if (upload && !upload.includes('https')) url = `https://${upload}`
  //     images.push({
  //       url: url
  //     })
  //   })
  // }

  // console.log('Images', images)

  return (
    <div className={classes.root}>
      <h1>Site Reviews</h1>
      <BigImageComponent open={showBigImages} item={activeItem} images={images} closeModal={handleCloseBigImageModal} />
      {/* <IssueDetailsModal open={issueDetailsModalOpen} service={svc} updateParent={updateFromSvcModal} /> */}
     <Paper className={classes.paper}>
      
        {
          loading ? <LoadingIndicator />  : <SiteReviewDashboard data={siteReviewData} /> 
        }
      
      </Paper>
    </div>
  );
}

const LoadingIndicator = () => {

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div>
        <CircularProgress size={48} color={"#516e76"} />
      </div>
      <div>
        Loading Site Review Data (this might take a moment...)
      </div>
    </div>
  )
}
const NotesFromTheRating = ({ data }) => {
  const dataToUse = data.reviewData
  let formattedData = dataToUse.filter(item => {
    if (item && item.notes && item.notes.length) return item
  })
  console.log('Notes!!', formattedData)
  return (
    <div>
      {
        formattedData.map((item, index) => {
          return (
            <div key={index}>
              <strong>{item.name}:</strong> {item.notes}
            </div>
          )
        })
      }
    </div>
  )
}

const PhotoList = ({ uploads, openLargeImage }) => {
  // console.log('Photo list', uploads)
  const classes = useStyles()
  if (!uploads || uploads.length < 1) return null
  return (
    <div className={classes.photoListContainer}>
      {
        uploads.map((upload, index) => {
          let url = upload.url
          if (url && !url.includes('https')) url = `https://${upload.url}`
          const handleDoubleClick = () => {
            openLargeImage(upload)
          }
          return (
            <div key={index} className={classes.photoListContainerItem}>
              <img src={url} alt="Review Photo" className={classes.photoListContainerItem} onDoubleClick={handleDoubleClick} />
            </div>
            
          )
        })
      }
    </div>
  )
}
const Notes = ({ data }) => {
  let details = data.details
  return (
    <div>
      Job Name: {details.jobName} #{details.jobNumber} - Workorder #{details.woNumber}
    </div>
  )  
}

const DetailsAndShit = ({ data }) => {
  const classes = useStyles();
  // console.log('Details:', data)
  if (data && data.reviewData && data.reviewData.length) {
    return data.reviewData.map((item, index) => {
      return (
        <div key={index} className={classes.ratingContainer}>
          {item.name ? capitalName(item.name) : ''} <StarRating name={item.name} number={item.rating} /> {item.notes}
        </div>
      )
    }) 
  } else {
    return null
  }
}

const capitalName = (name) => {
  const firstLetter = name.substring(0, 1)
  const restOfWord = name.substring(1, name.length)
  const str = `${firstLetter.toUpperCase()}${restOfWord}`.trim()
  return str
}

const StarRating = ({ name, number }) => {
  
  return  <Rating name={`${name}-${number}`} value={number} readOnly />
}

const UploadsDetailsList = ({ uploads }) => {

  return (
    <div>
      {
        uploads.map(upload => {
          return <UploadDetails upload={upload} key={upload._id} />
        })
      }
    </div>
    
  )
}

const UploadDetails = ({ upload }) => {
  let url = upload.url
  if (url && !url.includes('https')) {
    url = `https://${url}`
  }
  return (
    <div>
       {
         url.includes('.mp4') ? <AudioItem url={url} /> :  <img src={url} height={150} />
       }
    </div>
  )
}

const AudioItem = ({ url }) => {
  return (
    <audio controls>
        <source src={url} type="audio/mp4" />
        Your browser does not support the audio element.
    </audio>
  )
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url
  }
}

export default connect(mapStateToProps)(EnhancedTable)

