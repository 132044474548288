import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Paper, 
  Typography, 
  Chip, 
  Avatar, 
  TextField, 
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Collapse,
  IconButton
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PersonIcon from '@material-ui/icons/Person';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import EmailIcon from '@material-ui/icons/Email';
import { validateEmail } from '../../../services/common';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: 'rgba(236, 246, 236, 0.5)',
    borderRadius: 7,
    border: '1px solid #ddd',
    position: 'relative'
  },
  disabled: {
    backgroundColor: 'rgba(238, 238, 238, 0.5)',
    color: 'rgba(0, 0, 0, 0.38)'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  },
  title: {
    color: '#555',
    fontWeight: 600,
    fontSize: '1.1rem'
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  chip: {
    margin: theme.spacing(0.5)
  },
  noContactsMsg: {
    color: theme.palette.text.secondary,
    fontStyle: 'italic',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  expandButton: {
    padding: 0,
    marginLeft: 'auto'
  },
  avatar: {
    backgroundColor: theme.palette.primary.light
  },
  icon: {
    marginRight: theme.spacing(1)
  }
}));

const HerbicideNotificationContacts = ({
  contacts = [],
  disabled = false,
  selectedContacts = [],
  onChange,
  notification
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [selected, setSelected] = useState(selectedContacts || []);
  
  useEffect(() => {
    setSelected(selectedContacts);
  }, [selectedContacts]);

  const handleChange = (event, newValue) => {
    if (typeof newValue === 'string') {
      // Do nothing for simple string input
    } else if (newValue && newValue.inputValue) {
      // Create a new value from user input
    } else {
      // Handle array of selected contacts
      setSelected(newValue);
      if (onChange) {
        onChange(newValue);
      }
    }
  };

  const handleExpandToggle = () => {
    setExpanded(!expanded);
  };

  const getInitials = (firstName, lastName) => {
    return `${firstName ? firstName.charAt(0) : ''}${lastName ? lastName.charAt(0) : ''}`;
  };

  // Function to handle adding a custom email address
  const handleAddCustomEmail = (email) => {
    if (validateEmail(email)) {
      const newContact = {
        _id: `custom-${Date.now()}`, // Create a unique ID
        firstName: 'Custom',
        lastName: 'Contact',
        email: email
      };
      
      const updatedContacts = [...selected, newContact];
      setSelected(updatedContacts);
      if (onChange) {
        onChange(updatedContacts);
      }
    } else if (notification) {
      notification({
        type: 'warning',
        title: 'Invalid Email Address',
        message: `Sorry, but ${email} is not a valid email address.`
      });
    }
  };

  return (
    <Paper 
      className={`${classes.container} ${disabled ? classes.disabled : ''}`}
      elevation={0}
    >
      <div className={classes.header}>
        <Typography className={classes.title}>
          <EmailIcon className={classes.icon} />
          Herbicide Notification Recipients
        </Typography>
        <IconButton 
          className={classes.expandButton}
          onClick={handleExpandToggle}
          disabled={disabled}
          size="small"
        >
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </div>

      <Collapse in={expanded || selected.length > 0}>
        {selected.length > 0 ? (
          <div className={classes.chipContainer}>
            {selected.map((contact) => (
              <Chip
                key={contact._id}
                avatar={
                  <Avatar className={classes.avatar}>
                    {getInitials(contact.firstName, contact.lastName)}
                  </Avatar>
                }
                label={`${contact.firstName} ${contact.lastName} (${contact.email})`}
                onDelete={disabled ? undefined : () => {
                  const filtered = selected.filter(c => c._id !== contact._id);
                  setSelected(filtered);
                  if (onChange) {
                    onChange(filtered);
                  }
                }}
                className={classes.chip}
                disabled={disabled}
              />
            ))}
          </div>
        ) : (
          <Typography className={classes.noContactsMsg}>
            No notification recipients selected
          </Typography>
        )}

        {expanded && !disabled && (
          <Autocomplete
            multiple
            id="herbicide-notification-contacts"
            options={contacts.filter(contact => 
              // Filter out contacts that are already selected
              !selected.some(s => s._id === contact._id)
            )}
            disableCloseOnSelect
            getOptionLabel={(option) => `${option.firstName} ${option.lastName} (${option.email})`}
            onChange={handleChange}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                <PersonIcon style={{ marginRight: 8, color: '#666' }} />
                {`${option.firstName} ${option.lastName} - ${option.email}`}
              </React.Fragment>
            )}
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Add Recipients"
                placeholder="Search contacts or enter email address"
                margin="normal"
                fullWidth
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && validateEmail(e.target.value)) {
                    handleAddCustomEmail(e.target.value);
                    e.target.value = '';
                  }
                }}
              />
            )}
            disabled={disabled}
          />
        )}
      </Collapse>

      {!expanded && selected.length === 0 && (
        <Button 
          color="primary" 
          size="small" 
          onClick={handleExpandToggle}
          disabled={disabled}
        >
          Add Recipients
        </Button>
      )}

      <FormHelperText>
        {disabled 
          ? "Herbicide notifications are disabled" 
          : "Select contacts who should receive herbicide application notifications"}
      </FormHelperText>
    </Paper>
  );
};

export default HerbicideNotificationContacts;
