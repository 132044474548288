import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress, MenuItem }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const defaultValues = {
  manHours: 0,
  date: new Date(),
  project: null,
  crew: null,
  jobSite: {},
  possibleProjects: []
}

const TrackLaborModal = (props) => {
  // console.log('Track Labor Modal', props)
  const [state, setState] = React.useState(defaultValues)
  const [projects, setProjects] = React.useState([])

  React.useEffect(() => {
    let isMounted = true

    const fetchProjects = async () => {
      console.log('Fetch Projects for track labor modal...')
      const resp = await axios.get(`${props.url}/api/projects/listProjects?simpleSearch=true`)
      console.log('All Projects::', resp)
      if (resp?.data?.projects?.length) {
        setProjects(resp.data.projects)
        setState(prev => ({...prev, possibleProjects: resp.data.projects }))
      }
    }
    fetchProjects()
    return () => {
      isMounted = false
    }
  }, [])

  React.useEffect(() => {
    if (props.lineToEdit) {
    // console.log('Set our Line to Edit:', props.lineToEdit)
      setState({...state, manHours: props.lineToEdit.hoursWorked, date: props.lineToEdit.date })
    } else {
      // setState({...state, possibleProjects: props.projects })
    }
  }, [props])

  const handleClose = () => {
    setState(prev => ({...prev, ...defaultValues, possibleProjects: prev.possibleProjects }))
    props.updateParent('close')
  }
  const handleSave = () => {
    props.updateParent('save', state)
    setState(prev => ({...prev, ...defaultValues, possibleProjects: prev.possibleProjects }))
  }

  const handleDateChange = (e) => {
    setState({...state, date: moment(e.target.value).format('YYYY-MM-DD')})
  }

  const handleChangeProject = (e, newProj) => {
    // console.log('set new project', newProj)
    setState({...state, project: newProj})
  }

  const handleChangeJobSite = (e, jobSite) => {
    // console.log('set new jobSite', jobSite)
    if (jobSite?._id) {

      // Find Projecst / this jobsite...
      // console.log(projects)
      const siteClient = jobSite?.client?.toString() || null
      // console.log('Find projects for this guy', siteClient)
      let clientProjects = projects.filter(proj => {
        // console.log('Project:79::', proj)
        const clientId = proj?.client?._id?.toString() || null
      
        // console.log('Client Id: %s     Site Client: %s  ', clientId, siteClient)
        // console.log("proj?.client?._id === jobSite?.client", proj?.client?._id, jobSite?.client)
        return (clientId === siteClient)
      })
      // console.log('Client Projects', clientProjects)
      setState({...state, jobSite: jobSite, possibleProjects: clientProjects})
      // setProjects(clientProjects)
    } else {
      setState({...state, jobSite: null, possibleProjects: projects, project: null })
    }
  }

  const updateCrew = (e, crew) => {
    setState({...state, crew: crew })
  }

  const handleTabInteraction = (e) => {
    if (e.keyCode == 9) {
      if (e.target.id === 'crewlistautocomplete') {
        // console.log('Set Our crew!!', e.target.value)
        let newArr = []
        let string = e.target.value
        props.crews.filter(o => {
          let str = o['name'].toString().toLowerCase() || null
          let num = o['number'].toString().toLowerCase() || null
          if (str.includes(string.toLowerCase()) || num.includes(string.toLowerCase())) {
            return newArr.push(o)
          } else {
            return null
          }
        })
        // console.log(newArr)
        if (newArr && newArr.length) {
          setState({...state, crew: newArr[0]})
          // fetchShifts(newArr[0]['_id'])
        }
      }
      if (e.target.id === 'projects') {
        // console.log('Set Our crew!!', e.target.value)
        let newArr = []
        let string = e.target.value
        props.projects.filter(o => {
          let str = o['name'].toString().toLowerCase()
          let num = o['number'].toString().toLowerCase()
          if (str.includes(string.toLowerCase()) || num.includes(string.toLowerCase())) {
            return newArr.push(o)
          } else {
            return null
          }
        })
        // console.log(newArr)
        if (newArr && newArr.length) {
          setState({...state, project: newArr[0]})
          // fetchShifts(newArr[0]['_id'], true)
        }
      }
    }
  }

  const handleFocus = (event) => event.target.select();
  return (
    <Dialog open={props.open} onClose={handleClose} maxWidth='md' aria-labelledby="form-dialog-title">
      <DialogTitle>
        { props.lineToEdit ? 'Edit Man Hours' : 'Add Man Hours' }
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Search By Jobsite Below
        </DialogContentText>
        <Autocomplete
          id={`jobsite`}
          options={props.jobSites || []}
          getOptionLabel={(option) => {
            return `${option.communityName ? option.communityName : ''} ${option.name ? option.name : ''} ${option.address ? option.address : ''} ${option.city ? option.city : ''} ${option.buildingNumber ? `(${option.buildingNumber})` : ''}`
          }}
          onChange={handleChangeJobSite}
          value = {state.jobSite}
          renderInput={(params) => <TextField {...params} label="JobSite" helperText="Find Projecs By JobSite" variant="outlined" />}
        />
        {
          (props.projectId) ? '' : (
            <React.Fragment>
              <DialogContentText>
                Please Choose the Appropriate Project
              </DialogContentText>
              <Autocomplete
                id={`project`}
                options={state.possibleProjects || []}
                getOptionLabel={(option) => {
                  return `${option.name ? option.name : 'Un-named Estimate'} #${option.number}`
                }}
                onChange={handleChangeProject}
                value = {state.project}
                renderInput={(params) => <TextField {...params} label="Project" helperText="Is This Associated With a Project?" variant="outlined" />}
              />
            </React.Fragment>
          )
        }
        <DialogContentText>
          Please { props.lineToEdit ? 'Edit' : 'Add' } the Total Man Hours
        </DialogContentText>
        <TextField
          id="name"
          label="Man Hours"
          // style={{ margin: 8 }}
          type="number"

          value={state.manHours}
          // onChange={updateRef}
          onChange={e=> setState({...state, manHours: e.target.value})}
          // ref={inputRef['name']}
          onFocus={handleFocus}
          placeholder="Total Hours Worked"
          helperText="How Many Hours Were Worked?"
          fullWidth
          // value={inputRef.current['name']}
          margin="normal"
          variant="outlined"
          // InputLabelProps={{
          //   shrink: true,
          // }}
        />
        <TextField
          id="date"
          label="Scheduled Date"
          type="date"
          fullWidth
          onFocus={handleFocus}
          value={moment(state.date).format('YYYY-MM-DD') } // "2017-05-24"}
          // className={classes.textField}
          variant="outlined"
          onChange={ handleDateChange }
          InputLabelProps={{
            shrink: true,
          }}
          style={{marginBottom: '20px'}}
          helperText={`What Date Was This Work Completed?`}
        />
          <Autocomplete
            id={`crewlistautocomplete`}
            options={props.crews || []}
            getOptionLabel={(option) => {
              // console.log('Options: ', option)
              return `${option.name} ${option.number ? `#${option.number}` : '' }` || ''
            }}
            
            // style={{ width: 200 }}
            style={{marginBottom: '20px'}}
            onChange={updateCrew}
            onFocus={handleFocus}
            // disabled={loading}
            // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
            value = {state.crew}
            // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
            renderInput={(params) => <TextField {...params} fullWidth label="Crew" onKeyDown={handleTabInteraction} variant="outlined" />}
          />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={((!state.project || !state.project._id) && !props.projectId) || !state.manHours}
          onClick={handleSave}
          >Save</Button>
      </DialogActions>
    </Dialog>
  )
}

export default TrackLaborModal