import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper';
import moment from 'moment'
import { Button, CircularProgress, TextField, Dialog, DialogContent, DialogActions, DialogTitle }  from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import PlusIcon from '@material-ui/icons/Add';
import AssessmentRounded from '@material-ui/icons/AssessmentRounded';
import { fade, makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import NewProjectModal from './NewProjectModal'
import ProjectList from './ProjectList'
import VendorInvoiceInput from './VendorInvoiceInput'
import LaborTrackerModal from './LaborTrackerModal'
import ChooseEntityComponent from '../Entities/ChooseEntityComponent'
import ProjectDashboardDetails from './ProjectDashboardDetails'
import { Link } from 'react-router-dom'
import { socket } from '../SocketIo/Socket'
import TrackLaborModal from './TrackLaborModal'

// const useStyles = makeStyles(theme => ({
const styles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: theme.palette.secondary.main,
      textAlign: 'center'
    },
    [theme.breakpoints.down('md')]: {
      margin: 10,
      // backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.up('md')]: {
      // backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up('lg')]: {
      // backgroundColor: '#ccc',
    },
  },
  topButtons: {
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: theme.palette.secondary.main,
      // textAlign: 'center',
      margin: 5,
    },
    [theme.breakpoints.down('md')]: {
      margin: 5,
      // backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.up('md')]: {
      // backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up('lg')]: {
      // backgroundColor: '#ccc',
    },
  }
  })
);


const TopRow = (props) => {
  const classes = styles()
  return (
    <Grid container className={classes.root}>
      <Grid item lg={3} sm={6} xs={12} md={3}>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          className={classes.topButtons}
          onClick={props.handleOpenNewProjectModal}
          startIcon={<FiberNewIcon  color="primary" />}
        >New Project</Button>
      </Grid>
      <Grid item lg={3} sm={6} xs={12} md={3}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.topButtons}
          onClick={props.runWIPReport}
          style={{ color: '#fff' }}
          startIcon={<AssessmentRounded  color="#fff" />}
        >WIP Report</Button>
      </Grid>
      <Grid item lg={3} sm={6} xs={12} md={3}>
      <ProposalsNotAccepted history={props.history} />
        {/* <Button
          variant="outlined"
          color="primary"
          size="small"
          className={classes.topButtons}
          style={{ color: '#1fbfac', borderColor: '#1fbfac'}}
          startIcon={<AddIcon  color="#1fbfac" />}
          onClick={e => props.setVendorInvoiceInputModalOpen(true)}
        >Vendor Invoice</Button> */}
      </Grid>
      <Grid item lg={3} sm={6} xs={12} md={3}>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          className={classes.topButtons}
          style={{ color: '#bf3253', borderColor: '#bf3253'}}
          startIcon={<CreateIcon  color="#bf3253" />}
          onClick={props.handleOpenLaborTrackerModal}
        >Track Labor</Button>
      </Grid>
    </Grid>
  )
}

// const ProjectList = (props) => {
//   if (props.projects && props.projects.length) {
//     return (
//       JSON.stringify(props.projects)
//     )
//   } else {
//     return null
//   }
// }

const ProjectDashbaord = (props) => {
  const [newProjectModalOpen, setNewProjectModalOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [clients, setClientList] = React.useState([])
  const [crews, setCrews] = React.useState([])
  const [employees, setEmployees] = React.useState([])
  const [salesList, setSalesList] = React.useState([])
  const [projects, setProjects] = React.useState([])
  const [vendors, setVendors] = React.useState([])
  const [state, setState] = React.useState({
    counter: 0,
    projects: [],
    gotArchived: false,
    includeArchived: false
  })
  const [vendorInvoiceInputModalOpen, setVendorInvoiceInputModalOpen] = React.useState(false)
  const [showLaborTrackerModal, setShowLaborTrackerModal] = React.useState(false)
  const [activeEntity, setActiveEntity] = React.useState({})
  const [laborLineToEdit, setLaborLineToEdit] = React.useState({})
  const [jobSites, setJobSites] = React.useState([])
  const [showDateSelectionModal, setShowDateSelectionModal] = React.useState(false)

  React.useEffect(() => {
    let isMounted = true

    socket.on('project-list', (data) => {
      // console.log('Project list updated!', data)
      fetchProjects()
    })
    const fetchClients = async () => {
      setLoading(true)
      // ?byEntity=610a290f45ef7427f94b2e78
      let url = `${props.url}/api/customer/clients?active=true`
      axios.get(url).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          if (isMounted) setClientList(response.data)
        }
      })
    }
    // const fetchCrews = async () => {
    //   setLoading(true)
    //   axios.get(`${props.url}/api/crews/listCrews`).then((response, status, data) => {
    //     setLoading(false)
    //     if (response && response.data) {
    //       if (isMounted) setCrews(response.data.crews)
    //     }
    //   })
    // }

    const fetchVendors = async () => {
      setLoading(true)
      axios.get(`${props.url}/api/company/getVendors`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          if (isMounted) setVendors(response.data)
        }
      })
    }

    const fetchSalesReps = async () => {
      setLoading(true)
      axios.get(`${props.url}/api/employees/getSalesReps`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          if (isMounted) setSalesList(response.data)
        }
      })
    }

    // const fetchCrews = async () => {
    //   setLoading(true)
    //   axios.get(`${props.url}/api/crews/listCrews`).then((response, status, data) => {
    //     setLoading(false)
    //     if (response && response.data) {
    //       if (isMounted) setCrews(response.data.crews)
    //     }
    //   })
    // }

    const fetchEmployees = async () => {
      setLoading(true)
      axios.get(`${props.url}/api/employees/listEmployees`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          if (isMounted) {
            setEmployees(response.data.employees)
            setCrews(response.data.crews)
            if (response.data.jobSites) setJobSites(response.data.jobSites)
          }
        }
      })
    }

    fetchClients()
    // fetchSalesReps()
    fetchProjects()
    fetchVendors()
    // fetchCrews()
    fetchEmployees()
    // fetchSupplies()
    return () => {
      isMounted = false
      socket.off('project-list')
    }
  }, [])

  const fetchProjects = async (includeArchived, updatedStateData) => {
    setLoading(true)
    axios.get(`${props.url}/api/projects/listProjects?${includeArchived ? '' : 'includeAllSites=1'}${includeArchived ? '&includeArchived=true' : ''}`).then((response, status, data) => {
      setLoading(false)
      if (response && response.data && response.data.projects && response.data.projects.length) {
          setProjects(response.data.projects)
          setState({...state, projects: response.data.projects, ...updatedStateData })
      }
      if (response.data && response.data.jobSites && response.data.jobSites.length) setJobSites(response.data.jobSites)
    })
  }

  const filterOut = (r, searchText) => {
    // console.log('FIlter out: %s - %s', r, searchText)
    let string = searchText
    let newArr = []
    r.filter(o => {
      // console.log('Object:  ', o)
      Object.keys(o).some(k => {
        // console.log(o[k])
        if(o[k]) {
          // console.log('OK: ', o[k])
          let str = o[k].toString().toLowerCase() 
          // console.log('String', str)
          if (str.includes(string.toLowerCase())) {
            // console.log('Mathc')
            return newArr.push(o)
          } else {
            return null
          }
        } else {
          return null
        }
      })
      // return newArr
    })
    setState({...state, projets: newArr })
  }



  const handleOpenNewProjectModal = () => {
    setNewProjectModalOpen(true)
  }

  const handleAddProject = (d) => {
    // console.log('New Project to save!', d)
    if (d && d._id) {
      let projs = state.projects
      projs.unshift(d)
      setProjects(projs)
      // console.log('New Projects', projs)
      setNewProjectModalOpen(false)
      setState({...state, projects: projs, counter: state.counter++ })
    }

  }

  const handleOpenLaborTrackerModal = () => {
    setShowLaborTrackerModal(true)
  }

  const handleCloseLaborTrackerModal = () => {
    setShowLaborTrackerModal(false)
  }

  const handleUpdateVisibleEntity = (entityChosen) => {
  // console.log('UPdate Visibile Projects::: ', entityChosen)
  // console.log('Current Active Entity', activeEntity)
    let projectList = projects
    if (entityChosen.name === 'All Assigned') {
      // console.log('Show all projects')
      // Show all available Customers
      // console.log('We have a bunch!! %d', rows.length)
      // filterOut(projectList, searchValue)
      // setRows(projectList)
      
    } else {
    // console.log('Only show %s projects', entityChosen.name)
      // Filter by the active Entity
      let filteredProjects = projectList.filter(item => { 
        // console.log('Entity: ', item.entity)
        
        return item.entity.toString() === entityChosen._id.toString()
      })

    // console.log('New Projects: ', filteredProjects)
      projectList = filteredProjects
      // filterOut(filteredProjects, searchValue)
      // setRows(filteredProjects)
    }
  // console.log('SEt Entity', entityChosen)
  // console.log('Project list', projectList)
    setActiveEntity(entityChosen)
    setState({...state, projects: projectList})
  }

  const handleSaveLabor = (type, data) => {
    console.log('Save this shit', data)
    setShowLaborTrackerModal(false)
    setLoading(true)    
    if (data && data.date && data.manHours) {
      let stuff = {
        manHours: data.manHours,
        date: data.date,
        project: data.project._id
      }
      if (data.crew && data.crew._id) {
        stuff.crew = data.crew._id
        stuff.crewName = data.crew.name
      }
      axios({
        method: 'post',
        url:  `${props.url}/api/projects/addManHours`,
        data: stuff
      }).then(response => {
        setLoading(false)
      // console.log('Add Man hours', response.data)
        if (response && response.data && response.data._id) {
          // recalculateLaborShit(response.data)
          // setState({...state, totalManHours: response.data.manHoursWorked, manHoursByDay: response.data.manHoursByDay,  counter: state.counter++ })
        }
        props.notification({
          type: 'success',
          title: 'Man Hours Saved',
          message: 'Saved Labor Hours'
        })
      })
    }
  }

  const handleProjectListResponse = (data) => {
    // console.log('Project list response...', data)
    if (data === 'fetchAll') {
      fetchProjects(true)
    }
    if (data === 'toggleViewArchived') {
        // let updatedProjects = [ ...projects ]
        let updatedStateData = {
          includeArchived: !state.includeArchived
        }
        if (state.includeArchived) {
          // Set Active 
          let activeProjects = projects.filter(proj => (proj.isActive))
          updatedStateData.projects  = activeProjects
        } else {
         
          if (state.gotArchived) {
            // We already have archies in our projects
            updatedStateData.projects = projects
          } else {
            // console.log('Fetch em!')
            fetchProjects(true, updatedStateData)
          }
        }
        setState(prev => ({...prev, ...updatedStateData }))
    }
  }

  const runWIPReport = async () => {
    console.log('Run WIP')
    setShowDateSelectionModal(true)
  }

  const handleCloseDateModal = () => {
    setShowDateSelectionModal(false)
  }

  return (
    <Paper style={{  margin: 10, padding: 10 , border: '2px solid #ddd', borderRadius: 5}}>
      <TimeFrameSelectionModal open={showDateSelectionModal} closeModal={handleCloseDateModal} notification={props.notification} url={props.url} />
      <Grid container style={{margin: 5}} spacing={2}>
      <ProjectDashboardDetails />
      
        <Grid item lg={6} xs={12} sm={12} md={6}>
          <h1>Projects</h1>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
         
            {
              (props?.user?.entities?.length > 1) ? <ChooseEntityComponent allowAll={true} title={'Visible Entities'} user={props.user} updateParent={handleUpdateVisibleEntity} /> : null
            }
            <TopRow history={props.history} runWIPReport={runWIPReport} handleOpenNewProjectModal={handleOpenNewProjectModal} setVendorInvoiceInputModalOpen={setVendorInvoiceInputModalOpen} handleOpenLaborTrackerModal={handleOpenLaborTrackerModal} />
            
            <ProjectList
              projects={state.projects}
              statePreojc={state}
              updateParent={handleProjectListResponse}
              viewArchivedProjects={state.includeArchived}
              gotArchived={state.gotArchived}  
            />
         
        </Grid>
        <TrackLaborModal url={props.url} open={showLaborTrackerModal} jobSites={jobSites} crews={crews} employees={employees} closeModal={handleCloseLaborTrackerModal} updateParent={handleSaveLabor} />
        <NewProjectModal open={newProjectModalOpen} clients={clients} updateParent={handleAddProject} closeModal={e => setNewProjectModalOpen(false) } />
        <VendorInvoiceInput clients={clients} open={vendorInvoiceInputModalOpen} vendors={vendors} closeModal={e => setVendorInvoiceInputModalOpen(false)} />
      </Grid>
      </Paper>
  )
}

const TimeFrameSelectionModal = (props) => {
  const classes = useStyles()
  const now = new Date()
  const start = moment(now).subtract(24, 'hours')
  const [state, setState] = React.useState({
    start: start,
    end: now
  })
  const handleSubmit = () => {
    console.log('SUbmit', state)
    // props.submit(state)
    const obj = {
      date: state.start,
      type: 'all'
    }
   axios({
      method: 'post',
      url:  `${props.url}/api/projects/runWIP`,
      data: obj
    }).then(response => {
      console.log('Wip Report', response.data)
      props.notification({
        type: 'success',
        title: 'WIP Report Running',
        message: 'We are running the WIP now. When ready, it will be downloaded to your machine.'
      })
    }).catch(e => {
      props.notification({
        type: 'error',
        title: 'Error Running WIP',
        message: JSON.stringify(e)
      })
    })
  }

  const handleCloseModal = () => {
    props.closeModal()
  }

  return (
    <Dialog
          // fullScreen={true}
          maxWidth='sm'
          open={props.open}
          // onClose={handleClose}
          PaperProps={{
            style: {
              overflow: 'hidden',
              height: '300px'
            }}}
          aria-labelledby="responsive-dialog-title"
          >
          <DialogTitle id="responsive-dialog-title">Project WIP Report</DialogTitle>
          <DialogContent>
            <Grid container spacing={6}>
            <Grid item lg={12} xs={12}>
              Please choose the As Of Date:
            </Grid>
              <Grid item lg={12} xs={12}>
                <TextField
                  id="date"
                  label="As of Date"
                  type="date"
                  // className={classes.paddedStuff}
                  fullWidth
                  value={moment(state.start).format('YYYY-MM-DD') } // "2017-05-24"}
                  onChange={ e => setState({...state, start: e.target.value }) }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
          {/* <Button onClick={() => handleSubmit(0)} color="primary">
            Submit
          </Button> */}
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
          {/* <Button onClick={ handleConfirm } color="primary">
            Confirm
          </Button> */}
        </DialogActions>
          </Dialog>
  )
}

const ProposalsNotAccepted = (props) => {
  const goToOpenEstimates = () => {
    props.history.push('/openEstimates')
  }
  return (
    <Button onClick={goToOpenEstimates} variant="contained" color="primary" size="small" >Open Estimates</Button>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: '20px',
    zIndex: 8
  },
  paper: {
    width: '100%',
    minWidth: '500px',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  }
}));

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default React.memo(connect(mapStateToProps)(ProjectDashbaord))