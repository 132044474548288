// src/components/MapWithDrawing/DrawingEditor.js
import React, { useState, useCallback } from 'react';
import {
    Box, TextField, Typography, Popover, Button
} from '@material-ui/core';
import { SketchPicker } from 'react-color';
import { Save } from '@material-ui/icons';
import { defaultStyle } from './mapUtils'; // Import default style

// Memoize this component
const DrawingEditor = React.memo(({
    editingShape,
    onEditChange,
    onStyleChange,
    onSave,
    onCancel,
    isDrawingMode,
    colorSwatchStyle // Pass style class via prop
}) => {
    const [fillColorAnchorEl, setFillColorAnchorEl] = useState(null);
    const [strokeColorAnchorEl, setStrokeColorAnchorEl] = useState(null);

    const handleOpenFillColorPicker = useCallback((event) => setFillColorAnchorEl(event.currentTarget), []);
    const handleCloseFillColorPicker = useCallback(() => setFillColorAnchorEl(null), []);
    const handleOpenStrokeColorPicker = useCallback((event) => setStrokeColorAnchorEl(event.currentTarget), []);
    const handleCloseStrokeColorPicker = useCallback(() => setStrokeColorAnchorEl(null), []);

    const handleFillColorChangeComplete = useCallback((color) => {
        onStyleChange('fillColor', color.hex);
        onStyleChange('fillOpacity', color.rgb.a ?? 1);
    }, [onStyleChange]);

     const handleStrokeColorChangeComplete = useCallback((color) => {
        onStyleChange('color', color.hex); // 'color' is stroke color
        onStyleChange('opacity', color.rgb.a ?? 1);
    }, [onStyleChange]);

    // Get current styles safely, falling back to defaults
    const currentStyle = { ...defaultStyle, ...(editingShape?.style || {}) };
    const showStyleControls = editingShape && editingShape.type !== 'marker';

    if (!editingShape && !isDrawingMode) {
         return (
             <>
                <Typography variant="subtitle1" gutterBottom>Edit Selected Area</Typography>
                <Typography variant="body2" color="textSecondary" style={{ marginTop: '8px' }}>
                    Select a saved area from the list to view or edit its details.
                </Typography>
             </>
         );
    }
     if (!editingShape && isDrawingMode) {
         return (
             <>
                 <Typography variant="subtitle1" gutterBottom>Edit Selected Area</Typography>
                 <Typography variant="body2" color="textSecondary" style={{ marginTop: '8px' }}>
                     Drawing... Finish or cancel the current operation.
                 </Typography>
             </>
         );
     }

    // If we have an editingShape
    return (
        <>
            <Typography variant="subtitle1" gutterBottom>Edit Selected Area</Typography>
            <Box component="form" noValidate autoComplete="off" display="flex" flexDirection="column" gap={2}>
                <TextField
                    label="Area Name"
                    name="name" // Ensure name attribute matches state key
                    variant="outlined"
                    size="small"
                    value={editingShape.name || ''} // Handle potential undefined name
                    onChange={onEditChange} // Pass event directly
                    fullWidth
                    disabled={isDrawingMode}
                />

                {showStyleControls && (
                    <>
                        {/* Fill Color */}
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Typography variant="body2">Fill Color & Opacity:</Typography>
                            <Box
                                className={colorSwatchStyle}
                                style={{ backgroundColor: currentStyle.fillColor, opacity: currentStyle.fillOpacity }}
                                onClick={handleOpenFillColorPicker}
                                title={`Fill: ${currentStyle.fillColor} @ ${(currentStyle.fillOpacity * 100).toFixed(0)}%`}
                            />
                        </Box>
                        <Popover
                            open={Boolean(fillColorAnchorEl)} anchorEl={fillColorAnchorEl} onClose={handleCloseFillColorPicker}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                        >
                            <SketchPicker color={currentStyle.fillColor} onChangeComplete={handleFillColorChangeComplete} disableAlpha={false} />
                        </Popover>

                        {/* Stroke Color */}
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Typography variant="body2">Stroke Color & Opacity:</Typography>
                            <Box
                                className={colorSwatchStyle}
                                style={{ backgroundColor: currentStyle.color, opacity: currentStyle.opacity }}
                                onClick={handleOpenStrokeColorPicker}
                                title={`Stroke: ${currentStyle.color} @ ${(currentStyle.opacity * 100).toFixed(0)}%`}
                            />
                        </Box>
                        <Popover
                            open={Boolean(strokeColorAnchorEl)} anchorEl={strokeColorAnchorEl} onClose={handleCloseStrokeColorPicker}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                        >
                            <SketchPicker color={currentStyle.color} onChangeComplete={handleStrokeColorChangeComplete} disableAlpha={false} />
                        </Popover>

                        {/* Stroke Weight */}
                        <TextField
                            label="Stroke Weight (px)"
                            name="weight" // Name matches style key
                            type="number" variant="outlined" size="small"
                            value={currentStyle.weight}
                            // Handle direct style change for weight
                            onChange={(e) => onStyleChange('weight', Math.max(0, parseInt(e.target.value, 10) || 0))}
                            inputProps={{ min: 0, step: 1 }} fullWidth disabled={isDrawingMode}
                        />
                    </>
                )}

                {/* Action Buttons */}
                <Box display="flex" gap={1} marginTop={1}>
                    <Button variant="contained" color="primary" startIcon={<Save />} onClick={onSave} disabled={isDrawingMode} size="small"> Save Changes </Button>
                    <Button variant="outlined" size="small" onClick={onCancel} disabled={isDrawingMode}> Cancel Edit </Button>
                </Box>
            </Box>
        </>
    );
});

export default DrawingEditor;
