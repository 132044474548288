import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  IconButton,
  Paper,
  InputAdornment,
  makeStyles,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Divider,
  Card,
  CardContent,
} from '@material-ui/core';
import {
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
  CheckCircle as CheckCircleIcon,
  Add as AddIcon,
  PhotoCamera as PhotoCameraIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import axios from 'axios';

// Import the compatible vehicles component - use your actual path
import EnhancedCompatibleVehicles from './CompatibleVehicles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
  },
  divider: {
    margin: theme.spacing(3, 0, 2),
  },
  uploadInput: {
    display: 'none',
  },
  uploadButton: {
    marginTop: theme.spacing(1),
  },
  imagePreview: {
    width: '100%',
    height: 200,
    objectFit: 'contain',
    marginTop: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    background: theme.palette.background.default,
  },
  accordionRoot: {
    marginBottom: theme.spacing(2),
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
  },
  accordionContent: {
    width: '100%',
  },
  nextButton: {
    marginTop: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  stepContent: {
    width: '100%',
  },
  barcodeValid: {
    '& input': {
      borderColor: theme.palette.success.main,
      color: theme.palette.success.main,
    },
  },
  barcodeInvalid: {
    '& input': {
      borderColor: theme.palette.text.secondary,
    },
  },
  barcodeSuccess: {
    color: theme.palette.success.main,
  },
  barcodePending: {
    color: theme.palette.grey[500],
  },
  requiredLabel: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(0.5),
  },
  completedStep: {
    color: theme.palette.success.main,
  },
  activeStep: {
    fontWeight: 'bold',
  },
  accordionCompletedIcon: {
    color: theme.palette.success.main,
    marginLeft: theme.spacing(1),
  },
  offerCard: {
    marginBottom: theme.spacing(2),
    position: 'relative',
  },
  offerDeleteButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  addOfferButton: {
    marginTop: theme.spacing(2),
  }
}));

// Helper function to check if a step is completed
const isStepComplete = (step, inventoryItem) => {
  switch(step) {
    case 0: // Basic Information
      return inventoryItem.barcode && inventoryItem.title;
    case 1: // Category
      return inventoryItem.category && inventoryItem.subcategory;
    case 2: // Inventory Details
      return inventoryItem.quantity > 0;
    case 3: // Pricing & Supplier
      return true; // Optional section
    case 4: // Image
      return true; // Optional section
    case 5: // Maintenance
      return true; // Optional section
    case 6: // Compatible Vehicles
      return true; // Optional section
    case 7: // Notes
      return true; // Optional section
    default:
      return false;
  }
};

const EditInventoryItemDialog = ({ open, onClose, onSubmit, item, categories, url, notification }) => {
  const classes = useStyles();
  
  const initialInventoryItem = {
    _id: '',
    barcode: '',
    title: '',
    description: '',
    brand: '',
    model: '',
    partNumber: '',
    category: '',
    subcategory: '',
    quantity: 1,
    location: '',
    minStockLevel: 1,
    costPrice: '',
    preferredSupplier: '',
    supplierPartNumber: '',
    imageUrl: '',
    imageFile: null, // For local file handling
    lastOrdered: '',
    lastUsed: '',
    compatibleVehicles: [],
    maintenanceInterval: {
      miles: '',
      months: ''
    },
    replacementFrequency: '',
    notes: '',
    offers: [] // Added offers array for multiple suppliers
  };
  
  const [inventoryItem, setInventoryItem] = useState(initialInventoryItem);
  const [errors, setErrors] = useState({});
  const [imagePreview, setImagePreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [barcodeCheckStatus, setBarcodeCheckStatus] = useState('valid'); // 'pending', 'valid', 'searching'
  const [activeStep, setActiveStep] = useState(0);
  
  // For accordion view
  const [expandedPanel, setExpandedPanel] = useState('basic');
  // Default to accordion view as in your add dialog
  const [viewMode, setViewMode] = useState('accordion'); // 'stepper' or 'accordion'

  // Set form values when item changes
  useEffect(() => {
    if (item) {
      const categoryName = item.category?.name || '';
      setInventoryItem({
        _id: item._id || '',
        barcode: item.barcode || '',
        title: item.title || '',
        description: item.description || '',
        brand: item.brand || '',
        model: item.model || '',
        partNumber: item.partNumber || '',
        category: categoryName,
        subcategory: item.subcategory || '',
        quantity: item.quantity || 0,
        minStockLevel: item.minStockLevel || 1,
        costPrice: item.costPrice || '',
        preferredSupplier: item.preferredSupplier || '',
        location: item.location || '',
        notes: item.notes || '',
        supplierPartNumber: item.supplierPartNumber || '',
        imageUrl: item.imageUrl || '',
        imageFile: null,
        lastOrdered: item.lastOrdered ? new Date(item.lastOrdered).toISOString().split('T')[0] : '',
        lastUsed: item.lastUsed ? new Date(item.lastUsed).toISOString().split('T')[0] : '',
        compatibleVehicles: item.compatibleVehicles || [],
        replacementFrequency: item.replacementFrequency || '',
        maintenanceInterval: item.maintenanceInterval || { miles: '', months: '' },
        offers: item.offers || [] // Initialize offers array from item
      });
      
      if (item.imageUrl) {
        setImagePreview(item.imageUrl);
      }
    }
  }, [item]);

  // Handle accordion change
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false);
  };

  // Step handling for stepper
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Get subcategories for the selected category
  const getSubcategories = () => {
    if (!inventoryItem.category) return [];
    
    const category = categories.find(cat => cat.name === inventoryItem.category);
    return category ? category.subcategories : [];
  };
  
  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // Special handling for barcode field
    if (name === 'barcode') {
      const isValidLength = value.length === 12;
      setBarcodeCheckStatus(isValidLength ? 'valid' : 'pending');
    }
    
    // Handle nested fields
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setInventoryItem(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }));
    } else {
      setInventoryItem(prev => ({
        ...prev,
        [name]: value
      }));
    }
    
    // Clear error for this field if exists
    if (errors[name]) {
      setErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  // Handle changes in offer fields
  const handleOfferChange = (index, field, value) => {
    setInventoryItem(prev => {
      const updatedOffers = [...prev.offers];
      updatedOffers[index] = {
        ...updatedOffers[index],
        [field]: field === 'price' || field === 'sale_price' ? Number(value) : value
      };
      return {
        ...prev,
        offers: updatedOffers
      };
    });
  };

  // Add a new empty offer
  const handleAddOffer = () => {
    setInventoryItem(prev => ({
      ...prev,
      offers: [
        ...prev.offers,
        {
          merchant: '',
          price: '',
          sale_price: '',
          link: '',
          availability: 'In Stock',
          condition: 'New',
          country: 'US',
          currency: 'USD',
          currency_symbol: '$',
          last_update: new Date()
        }
      ]
    }));
  };

  // Remove an offer
  const handleRemoveOffer = (index) => {
    setInventoryItem(prev => {
      const updatedOffers = prev.offers.filter((_, i) => i !== index);
      return {
        ...prev,
        offers: updatedOffers
      };
    });
  };
  
  // Handle image upload
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    
    setInventoryItem(prev => ({
      ...prev,
      imageFile: file,
      imageUrl: URL.createObjectURL(file) // Temporary URL for preview
    }));
    
    setImagePreview(URL.createObjectURL(file));
  };

  const handleSearchBarcode = async (barcode, isPartNumber) => {
    console.log('Search for this barcode...', barcode);
    setBarcodeCheckStatus('searching');
    
    try {
      const resp = await axios.get(`${url}/api/equipment/scanUPCForShopInventory?${isPartNumber ? 'pn' : 'upc'}=${barcode}`);
      console.log('Barcode search results', resp.data);
      const {
        possibleMatches,
        existingInventory,
        error
      } = resp.data;
      
      if (existingInventory) {
        console.log('We already have this item...', existingInventory);
        // Add notification here if needed
      }
      
      if (possibleMatches?.length) {
        console.log('We have some possible matches...', possibleMatches);
        if (isPartNumber) {
          setBarcodeCheckStatus('')
        } else {
          setBarcodeCheckStatus('valid');
        }
      } else {
        if (isPartNumber) {
          setBarcodeCheckStatus('')
        } else {
          setBarcodeCheckStatus('valid');
        }
      }
    } catch (e) {
      console.error('Error searching barcode:', e);
      setBarcodeCheckStatus('pending');
    }
  };
  
  // Validate the form
  const validateForm = () => {
    const newErrors = {};
    
    // Required fields
    if (!inventoryItem.barcode) newErrors.barcode = "Barcode is required";
    if (!inventoryItem.title) newErrors.title = "Title is required";
    if (!inventoryItem.category) newErrors.category = "Category is required";
    if (!inventoryItem.subcategory) newErrors.subcategory = "Subcategory is required";
    
    // Number validation
    if (inventoryItem.quantity < 0) newErrors.quantity = "Quantity cannot be negative";
    if (inventoryItem.minStockLevel < 0) newErrors.minStockLevel = "Minimum stock level cannot be negative";
    if (inventoryItem.costPrice && inventoryItem.costPrice < 0) {
      newErrors.costPrice = "Cost price cannot be negative";
    }
    
    // Validate offers
    inventoryItem.offers.forEach((offer, index) => {
      if (!offer.merchant) {
        newErrors[`offers[${index}].merchant`] = "Merchant name is required";
      }
      if (offer.price && offer.price < 0) {
        newErrors[`offers[${index}].price`] = "Price cannot be negative";
      }
      if (offer.sale_price && offer.sale_price < 0) {
        newErrors[`offers[${index}].sale_price`] = "Sale price cannot be negative";
      }
    });
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  // Handle form submission
  const handleSubmit = () => {
    if (!validateForm()) return;
    
    onSubmit(inventoryItem);
  };
  
  // Close dialog
  const handleClose = () => {
    onClose();
  };
  
  // Define the steps for the stepper
  const steps = [
    { label: 'Basic Information', content: (
      <Grid container spacing={2} className={classes.stepContent}>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            name="barcode"
            label={<span>Barcode <span className={classes.requiredLabel}>*</span></span>}
            value={inventoryItem.barcode}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            error={!!errors.barcode}
            helperText={errors.barcode || (inventoryItem.barcode.length > 0 && inventoryItem.barcode.length < 12 ? 
              `UPC should be 12 digits (${inventoryItem.barcode.length}/12)` : '')}
            className={barcodeCheckStatus === 'valid' ? classes.barcodeValid : classes.barcodeInvalid}
            disabled={true} // Disabling barcode in edit mode as it's the unique identifier
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={
                    inventoryItem.barcode.length < 12 
                      ? `Valid UPC Should Be 12 Digits (${inventoryItem.barcode.length}/12)` 
                      : `Search by UPC For Product Info`
                  }> 
                    <div>
                      <IconButton 
                        onClick={() => handleSearchBarcode(inventoryItem.barcode)} 
                        disabled={inventoryItem.barcode.length < 12 || barcodeCheckStatus === 'searching' || true}
                        className={barcodeCheckStatus === 'valid' ? classes.barcodeSuccess : classes.barcodePending}
                      >
                        {barcodeCheckStatus === 'searching' ? 
                          <HourglassEmptyIcon size={24} /> : <SearchIcon />
                        }
                      </IconButton>
                    </div>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
          {barcodeCheckStatus === 'valid' && 
            <Typography variant="caption" className={classes.barcodeSuccess}>
              Valid UPC format ✓
            </Typography>
          }
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="partNumber"
            label="Part Number"
            value={inventoryItem.partNumber}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={ `Search by Part Number`}> 
                    <div>
                      <IconButton 
                        onClick={() => handleSearchBarcode(inventoryItem.partNumber, true)} 
                        disabled={inventoryItem.partNumber.length < 4 || barcodeCheckStatus === 'searching'}
                        className={barcodeCheckStatus === 'valid' ? classes.barcodeSuccess : classes.barcodePending}
                      >
                        {barcodeCheckStatus === 'searching' ? 
                          <HourglassEmptyIcon size={24} /> : <SearchIcon />
                        }
                      </IconButton>
                    </div>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
          
        <Grid item xs={12} sm={6} md={8}>
          <TextField
            name="title"
            label={<span>Item Title <span className={classes.requiredLabel}>*</span></span>}
            value={inventoryItem.title}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            error={!!errors.title}
            helperText={errors.title}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="brand"
            label="Brand"
            value={inventoryItem.brand}
            onChange={handleChange}
            fullWidth
            variant="outlined"
          />
        </Grid>
          
        <Grid item xs={12} sm={6}>
          <TextField
            name="model"
            label="Model"
            value={inventoryItem.model}
            onChange={handleChange}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="description"
            label="Description"
            value={inventoryItem.description}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
    )},
    { label: 'Category', content: (
      <Grid container spacing={2} className={classes.stepContent}>
        <Grid item xs={12} sm={6}>
          <FormControl variant="outlined" className={classes.formControl} error={!!errors.category}>
            <InputLabel id="category-label">Category <span className={classes.requiredLabel}>*</span></InputLabel>
            <Select
              labelId="category-label"
              name="category"
              value={inventoryItem.category}
              onChange={handleChange}
              label="Category *"
            >
              <MenuItem value="">
                <em>Select a category</em>
              </MenuItem>
              {categories.map((category, index) => (
                <MenuItem key={index} value={category.name}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
            {errors.category && <Typography color="error" variant="caption">{errors.category}</Typography>}
          </FormControl>
        </Grid>
            
        <Grid item xs={12} sm={6}>
          <FormControl variant="outlined" className={classes.formControl} error={!!errors.subcategory} disabled={!inventoryItem.category}>
            <InputLabel id="subcategory-label">Subcategory <span className={classes.requiredLabel}>*</span></InputLabel>
            <Select
              labelId="subcategory-label"
              name="subcategory"
              value={inventoryItem.subcategory}
              onChange={handleChange}
              label="Subcategory *"
            >
              <MenuItem value="">
                <em>Select a subcategory</em>
              </MenuItem>
              {getSubcategories().map((subcat, index) => (
                <MenuItem key={index} value={subcat}>
                  {subcat}
                </MenuItem>
              ))}
            </Select>
            {errors.subcategory && <Typography color="error" variant="caption">{errors.subcategory}</Typography>}
          </FormControl>
        </Grid>
      </Grid>
    )},
    { label: 'Inventory Details', content: (
      <Grid container spacing={2} className={classes.stepContent}>
        <Grid item xs={12} sm={4}>
          <TextField
            name="quantity"
            label={<span>Quantity <span className={classes.requiredLabel}>*</span></span>}
            type="number"
            value={inventoryItem.quantity}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            InputProps={{
              inputProps: { min: 0 }
            }}
            error={!!errors.quantity}
            helperText={errors.quantity}
          />
        </Grid>
            
        <Grid item xs={12} sm={4}>
          <TextField
            name="minStockLevel"
            label="Minimum Stock Level"
            type="number"
            value={inventoryItem.minStockLevel}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            InputProps={{
              inputProps: { min: 0 }
            }}
            error={!!errors.minStockLevel}
            helperText={errors.minStockLevel}
          />
        </Grid>
            
        <Grid item xs={12} sm={4}>
          <TextField
            name="location"
            label="Storage Location"
            value={inventoryItem.location}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            placeholder="e.g., Shelf A3, Bin 12"
          />
        </Grid>
      </Grid>
    )},
    { label: 'Pricing & Suppliers', content: (
      <Grid container spacing={2} className={classes.stepContent}>
        <Grid item xs={12} sm={4}>
          <TextField
            name="costPrice"
            label="Cost Price"
            type="number"
            value={inventoryItem.costPrice}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              inputProps: { min: 0, step: 0.01 }
            }}
            error={!!errors.costPrice}
            helperText={errors.costPrice}
          />
        </Grid>
        
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Supplier Offers
          </Typography>
          
          {inventoryItem?.offers.map((offer, index) => (
            <Card key={index} className={classes.offerCard}>
              <IconButton 
                className={classes.offerDeleteButton}
                onClick={() => handleRemoveOffer(index)}
                aria-label="Delete offer"
              >
                <DeleteIcon />
              </IconButton>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={<span>Merchant <span className={classes.requiredLabel}>*</span></span>}
                      value={offer.merchant}
                      onChange={(e) => handleOfferChange(index, 'merchant', e.target.value)}
                      fullWidth
                      variant="outlined"
                      error={!!errors[`offers[${index}].merchant`]}
                      helperText={errors[`offers[${index}].merchant`]}
                    />
                  </Grid>
                  
                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="Price"
                      type="number"
                      value={offer.price}
                      onChange={(e) => handleOfferChange(index, 'price', e.target.value)}
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{offer.currency_symbol || '$'}</InputAdornment>,
                        inputProps: { min: 0, step: 0.01 }
                      }}
                      error={!!errors[`offers[${index}].price`]}
                      helperText={errors[`offers[${index}].price`]}
                    />
                  </Grid>
                  
                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="Sale Price"
                      type="number"
                      value={offer.sale_price}
                      onChange={(e) => handleOfferChange(index, 'sale_price', e.target.value)}
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{offer.currency_symbol || '$'}</InputAdornment>,
                        inputProps: { min: 0, step: 0.01 }
                      }}
                      error={!!errors[`offers[${index}].sale_price`]}
                      helperText={errors[`offers[${index}].sale_price`]}
                    />
                  </Grid>
                  
                  <Grid item xs={12}>
                    <TextField
                      label="Link"
                      value={offer.link}
                      onChange={(e) => handleOfferChange(index, 'link', e.target.value)}
                      fullWidth
                      variant="outlined"
                      placeholder="https://example.com/product"
                    />
                  </Grid>
                  
                  <Grid item xs={12} sm={4}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Availability</InputLabel>
                      <Select
                        value={offer.availability}
                        onChange={(e) => handleOfferChange(index, 'availability', e.target.value)}
                        label="Availability"
                      >
                        <MenuItem value="In Stock">In Stock</MenuItem>
                        <MenuItem value="Out of Stock">Out of Stock</MenuItem>
                        <MenuItem value="Pre-order">Pre-order</MenuItem>
                        <MenuItem value="Limited Stock">Limited Stock</MenuItem>
                        <MenuItem value="Discontinued">Discontinued</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  
                  <Grid item xs={12} sm={4}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Condition</InputLabel>
                      <Select
                        value={offer.condition}
                        onChange={(e) => handleOfferChange(index, 'condition', e.target.value)}
                        label="Condition"
                      >
                        <MenuItem value="New">New</MenuItem>
                        <MenuItem value="Used">Used</MenuItem>
                        <MenuItem value="Refurbished">Refurbished</MenuItem>
                        <MenuItem value="Open Box">Open Box</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  
                  <Grid item xs={12} sm={4}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Currency</InputLabel>
                      <Select
                        value={offer.currency}
                        onChange={(e) => {
                          const currencyValue = e.target.value;
                          let currencySymbol = '$';
                          
                          // Set currency symbol based on currency code
                          switch(currencyValue) {
                            case 'USD': currencySymbol = '$'; break;
                            case 'EUR': currencySymbol = '€'; break;
                            case 'GBP': currencySymbol = '£'; break;
                            case 'JPY': currencySymbol = '¥'; break;
                            case 'CAD': currencySymbol = 'CA$'; break;
                            default: currencySymbol = '$';
                          }
                          
                          handleOfferChange(index, 'currency', currencyValue);
                          handleOfferChange(index, 'currency_symbol', currencySymbol);
                        }}
                        label="Currency"
                      >
                        <MenuItem value="USD">USD ($)</MenuItem>
                        <MenuItem value="EUR">EUR (€)</MenuItem>
                        <MenuItem value="GBP">GBP (£)</MenuItem>
                        <MenuItem value="JPY">JPY (¥)</MenuItem>
                        <MenuItem value="CAD">CAD (CA$)</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ))}
          
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddOffer}
            className={classes.addOfferButton}
          >
            Add Supplier Offer
          </Button>
        </Grid>
            
        <Grid item xs={12} sm={6}>
          <TextField
            name="lastOrdered"
            label="Last Ordered Date"
            type="date"
            value={inventoryItem.lastOrdered}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
            
        <Grid item xs={12} sm={6}>
          <TextField
            name="lastUsed"
            label="Last Used Date"
            type="date"
            value={inventoryItem.lastUsed}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>
    )},
    { label: 'Image', content: (
      <Grid container spacing={2} className={classes.stepContent}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="imageUrl"
            label="Image URL"
            value={inventoryItem.imageUrl}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            placeholder="https://example.com/image.jpg"
          />
              
          <input
            accept="image/*"
            className={classes.uploadInput}
            id="image-upload"
            type="file"
            onChange={handleImageUpload}
          />
          <label htmlFor="image-upload">
            <Button
              variant="outlined"
              component="span"
              className={classes.uploadButton}
              startIcon={<PhotoCameraIcon />}
            >
              Upload Image
            </Button>
          </label>
        </Grid>
            
        <Grid item xs={12} sm={6}>
          {(imagePreview || inventoryItem.imageUrl) && (
            <img 
              src={imagePreview || inventoryItem.imageUrl} 
              alt="Item preview" 
              className={classes.imagePreview}
            />
          )}
        </Grid>
      </Grid>
    )},
    { label: 'Maintenance Information', content: (
      <Grid container spacing={2} className={classes.stepContent}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="maintenanceInterval.miles"
            label="Maintenance Interval (Distance in Miles)"
            type="number"
            value={inventoryItem.maintenanceInterval.miles}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            InputProps={{
              inputProps: { min: 0 }
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="maintenanceInterval.months"
            label="Maintenance Interval (Duration in Months)"
            type="number"
            value={inventoryItem.maintenanceInterval.months}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            InputProps={{
              inputProps: { min: 0 }
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            name="replacementFrequency"
            label="Replacement Frequency"
            value={inventoryItem.replacementFrequency}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            placeholder="E.g. Every 5,000 miles"
          />
        </Grid>
      </Grid>
    )},
    { label: 'Compatible Vehicles', content: (
      <Grid container spacing={2} className={classes.stepContent}>
        <Grid item xs={12}>
          <EnhancedCompatibleVehicles
            inventoryItem={inventoryItem}
            setCompatibleVehicles={(vehicles) => {
              setInventoryItem(prev => ({
                ...prev,
                compatibleVehicles: vehicles
              }));
            }}
            url={url}
            notification={notification}
          />
        </Grid>
      </Grid>
    )},
    { label: 'Additional Notes', content: (
      <Grid container spacing={2} className={classes.stepContent}>
        <Grid item xs={12}>
          <TextField
            name="notes"
            label="Notes"
            value={inventoryItem.notes}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            placeholder="Enter any additional information about this item..."
          />
        </Grid>
      </Grid>
    )}
  ];

  // Accordion View Content
  const accordionContent = [
    {
      id: 'basic',
      title: 'Basic Information',
      isCompleted: isStepComplete(0, inventoryItem),
      content: steps[0].content
    },
    {
      id: 'category',
      title: 'Category',
      isCompleted: isStepComplete(1, inventoryItem),
      content: steps[1].content
    },
    {
      id: 'inventory',
      title: 'Inventory Details',
      isCompleted: isStepComplete(2, inventoryItem),
      content: steps[2].content
    },
    {
      id: 'pricing',
      title: 'Pricing & Suppliers',
      isCompleted: isStepComplete(3, inventoryItem),
      content: steps[3].content
    },
    {
      id: 'image',
      title: 'Image',
      isCompleted: isStepComplete(4, inventoryItem),
      content: steps[4].content
    },
    {
      id: 'maintenance',
      title: 'Maintenance Information',
      isCompleted: isStepComplete(5, inventoryItem),
      content: steps[5].content
    },
    {
      id: 'vehicles',
      title: 'Compatible Vehicles',
      isCompleted: isStepComplete(6, inventoryItem),
      content: steps[6].content
    },
    {
      id: 'notes',
      title: 'Additional Notes',
      isCompleted: isStepComplete(7, inventoryItem),
      content: steps[7].content
    }
  ];

  return (
    <Dialog 
      open={open} 
      onClose={handleClose} 
      fullWidth 
      maxWidth="lg"
      aria-labelledby="edit-shop-inventory-dialog"
    >
      <DialogTitle id="edit-shop-inventory-dialog">
        <Grid container justify="space-between" alignItems="center">
          <Grid item>Edit Inventory Item</Grid>
          <Grid item>
            <Button 
              color="primary" 
              onClick={() => setViewMode(viewMode === 'stepper' ? 'accordion' : 'stepper')}
            >
              Switch to {viewMode === 'stepper' ? 'Accordion' : 'Step-by-Step'} View
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      
      <DialogContent>
        {viewMode === 'stepper' ? (
          // Step by Step View
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel
                  optional={index === steps.length - 1 ? (
                    <Typography variant="caption">Last step</Typography>
                  ) : null}
                  className={
                    activeStep === index 
                      ? classes.activeStep 
                      : isStepComplete(index, inventoryItem) 
                        ? classes.completedStep 
                        : ''
                  }
                >
                  {step.label}
                </StepLabel>
                <StepContent>
                  {step.content}
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.backButton}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.nextButton}
                    >
                      {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                  </div>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        ) : (
          // Accordion View
          <div>
            {accordionContent.map((section) => (
              <Accordion 
                key={section.id}
                expanded={expandedPanel === section.id}
                onChange={handleAccordionChange(section.id)}
                className={classes.accordionRoot}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${section.id}-content`}
                  id={`${section.id}-header`}
                >
                  <Typography className={classes.accordionHeading}>
                    {section.title}
                    {section.isCompleted && (
                      <CheckCircleIcon className={classes.accordionCompletedIcon} fontSize="small" />
                    )}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={classes.accordionContent}>
                    {section.content}
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        )}
      </DialogContent>
      
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button 
          onClick={handleSubmit} 
          color="primary" 
          variant="contained"
          disabled={!inventoryItem.barcode || !inventoryItem.title || !inventoryItem.category || !inventoryItem.subcategory}
        >
          Update Inventory Item
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditInventoryItemDialog;
