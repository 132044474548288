import React, { useEffect, useState } from 'react';
import {
  Button,
  Paper,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  InputAdornment,
  Tabs,
  Tab,
  Box,
  Chip,
  Grid,
  Card,
  CardContent,
  Divider,
  IconButton,
  Tooltip,
  CircularProgress
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { 
  currencyFormat, 
  isEven, 
  daysOfTheWeek, 
  mtcFrequencies 
} from '../../services/common';
import { connect } from 'react-redux';
import axios from 'axios';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import AssignmentIcon from '@material-ui/icons/Assignment';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditJobSiteModal from './MaintenanceStuff/EditJobSiteModal';
import moment from 'moment';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import RemoveIcon from '@material-ui/icons/Remove';
import StarIcon from '@material-ui/icons/Star';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

let weekOfTheYear = moment(new Date()).week()

const defaultVals = {
  sites: [],
  searchValue: '',
  crewToConfirm: null,
  siteToConfirm: null,
  evenOrOddWeeks: 'Odd',

}

const StarRating = ({ rating, small = false }) => {
  const classes = useStyles();
  const starClass = `${classes.starIcon} ${small ? classes.starIconSmall : ''}`;
  
  return (
    <Box display="flex" alignItems="center">
      {[...Array(5)].map((_, index) => {
        const value = rating - index;
        if (value >= 1) {
          return <StarIcon key={index} className={starClass} />;
        } else if (value > 0) {
          return <StarHalfIcon key={index} className={starClass} />;
        }
        return <StarBorderIcon key={index} className={starClass} />;
      })}
    </Box>
  );
};

// const getNumberOfVisitsPerYear = (site, customerType) => {
//   // // console.log('calculate # of Visits', site)
//   const frequency = site?.frequency?.toLowerCase() || 'bi-weekly'
//   // console.log('Get Visits pEr year: ', frequency)
//   let numVisits = 26
//   // Daily
//   if (frequency === 'daily') numVisits = 260
//   if (frequency === 'every other day (m-w-f)') numVisits = 156
//   // Weekly
//   if (frequency === 'weekly') numVisits = 52
//   // Bi-Weekly
//   if (frequency === 'bi-weekly' || frequency === 'biweekly') numVisits = 26
//   // Monthly
//   if (frequency === 'monthly') numVisits = 12
//   // Quarterly
//   if (frequency === 'quarterly') numVisits = 4
//   if (frequency === 'semi-annually') numVisits = 2
//   if (frequency === 'twice a week') numVisits = 104
//   if (frequency === 'on demand') numVisits = 0
//   return numVisits
// }

function calculateMonthlyValue(amount, origFrequency, amountType) {
  // console.log('Amount: ', amount)
  // console.log('Frequency', origFrequency)
  // console.log('Amount TYpe', amountType)
  // Convert amount to number to ensure proper calculation
  amount = Number(amount);
  const frequency = origFrequency.toLowerCase().split(' ').join('')
  // console.log('Adjusted frequency', frequency)
  // const annualVisits = getNumberOfVisitsPerYear(frequency)
  // Frequency multipliers (times per year)
  const frequencyMultipliers = {
      'daily': 260,
      'everyotherday(m-w-f)': 156,
      'twiceaweek': 104,
      'weekly': 52,
      'bi-weekly': 26,
      'monthly': 12,
      'quarterly': 4,
      'semi-annually': 2,
      'annually': 1,
      'ondemand': 0
  };
 
  // Validate inputs
  if (!amount || isNaN(amount)) {
      if (amount === 0) return 0
      throw new Error('Invalid amount provided');
  }
  if (!frequency || !frequencyMultipliers.hasOwnProperty(frequency.toLowerCase())) {
      throw new Error('Invalid frequency provided');
  }
  if (!['perVisit', 'monthly'].includes(amountType)) {
      throw new Error('Invalid amountType. Must be either "perVisit" or "monthly"');
  }
  
  // Get the frequency multiplier
  const annualMultiplier = frequencyMultipliers[frequency.toLowerCase()];
  
  // Calculate monthly value based on amountType
  if (amountType === 'perVisit') {
      // For perVisit: multiply by annual frequency and divide by 12 to get monthly
      return (amount * annualMultiplier) / 12;
  } else {
      // For monthly amounts: multiply by the number of payments per year and divide by 12
      return amount
  }
}


const HMICrewAssignmentComponent = (props) => {
  const classes = useStyles();
  const theme = useTheme()
  const [state, setState] = useState(defaultVals);
  const [tabValue, setTabValue] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [filteredRows, setFilteredRows] = useState([]);
  const [showEditJobsiteModal, setShowEditJobsiteModal] = useState(false);
  const [activeSite, setActiveSite] = useState({});
  const [loading, setLoading] = useState(false);
  const [siteReviews, setSiteReviews] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'asc'
  });

  const hmiCrews = props.crews.filter(crew => crew.entity === "640a56404965b71a9edd0e7d");

  // Site Reviews Stats
  const pendingReviews = siteReviews.filter(review => review.status === 'Pending').length;
  const requiresResolution = siteReviews.filter(review => review.requiresResolution).length;
  const completedReviews = siteReviews.filter(review => review.status === 'Completed').length;


  useEffect(() => {
    let isMounted = true;
    
    const fetchData = async () => {
      setLoading(true);
      try {
        const [sitesResp, reviewsResp] = await Promise.all([
          axios.get(`${props.url}/api/hmi/getActiveSites`),
          axios.get(`${props.url}/api/hmi/fetchSiteReviews`)
        ]);
        
        if (isMounted) {
          const sites = sitesResp.data;
          const sitesWTotalValue = sites.map(site => {
            // console.log('Site to do ', site)
            const totalValue = calculateMonthlyValue(site.amount, site.frequency, site.amountType)
            // console.log('Total Value', site.name, totalValue)
            return {
              ...site,
              monthlyValue: totalValue
            }
          })
          // console.log('Sites w/ total value', sitesWTotalValue)
          filterOut(sitesWTotalValue, searchValue);
          setState(prev => ({ ...prev, sitesWTotalValue }));
          setSiteReviews(reviewsResp.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        props.notification({
          type: 'error',
          title: 'Error',
          message: 'Failed to fetch data'
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    return () => {
      isMounted = false;
    };
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getSiteReviewMetrics = (siteId) => {
    // console.log('Get Site Review Metrics...')
    const siteReviewHistory = siteReviews.filter(review => 
      ((review?.reviewData?.length) && review.jobSite && review.jobSite._id === siteId)
    ).sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      // console.log('Site review hisor', siteReviewHistory)
    if (siteReviewHistory.length === 0) {
      return { lastReview: null, trend: 'neutral', score: null, hasIssues: false };
    }

    const lastReview = siteReviewHistory[0];
    
    // Calculate average rating (1-5 scale)
    const getReviewScore = (review) => {
      if (!review.reviewData || !Array.isArray(review.reviewData)) {
        return null;
      }

      const validRatings = review.reviewData.filter(item => item.rating > 0);
      if (validRatings.length === 0) return null;

      return validRatings.reduce((sum, item) => sum + item.rating, 0) / validRatings.length;
    };

    const lastScore = getReviewScore(lastReview);
    const hasIssues = lastReview.issues && lastReview.issues.length > 0;
    
    // Calculate trend
    let trend = 'neutral';
    if (siteReviewHistory.length > 1) {
      const previousScore = getReviewScore(siteReviewHistory[1]);
      if (lastScore !== null && previousScore !== null) {
        trend = lastScore > previousScore ? 'up' : lastScore < previousScore ? 'down' : 'neutral';
      }
    }

    return {
      lastReview,
      trend,
      score: lastScore,
      hasIssues,
      daysAgo: moment(lastReview.created_at).fromNow(),
      detailedRatings: lastReview.reviewData
        .filter(item => item.rating > 0)
        .map(item => ({
          name: item.name.charAt(0).toUpperCase() + item.name.slice(1),
          rating: item.rating,
          notes: item.notes
        }))
    };
  };

  const sortedRows = React.useMemo(() => {
    if (!sortConfig.key) return filteredRows;

    return [...filteredRows].sort((a, b) => {
      console.log('Sort:', sortConfig.key)
      if (sortConfig.key === 'score') {
        const scoreA = getSiteReviewMetrics(a._id).score || 0;
        const scoreB = getSiteReviewMetrics(b._id).score || 0;
        return sortConfig.direction === 'asc' ? scoreA - scoreB : scoreB - scoreA;
      }
      if (sortConfig.key === 'amount') {
        const scoreA = a.monthlyValue || 0;
        const scoreB = b.monthlyValue || 0;
        return sortConfig.direction === 'asc' ? scoreA - scoreB : scoreB - scoreA;
        
      }
      if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
      if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
  }, [filteredRows, sortConfig]);

  const requestSort = (key) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  const TrendIndicator = ({ trend }) => {
    const classes = useStyles();
    switch (trend) {
      case 'up':
        return <TrendingUpIcon className={classes.trendUp} />;
      case 'down':
        return <TrendingDownIcon className={classes.trendDown} />;
      default:
        return <RemoveIcon className={classes.trendNeutral} />;
    }
  };

  const renderTableHeader = () => (
    <TableHead>
      <TableRow>
        {[
          { key: 'clientName', label: 'Client Name' },
          { key: 'name', label: 'Site Name' },
          { key: 'jobName', label: 'Job Name' },
          { key: 'customerType', label: 'Contract Type' },
          { key: 'address', label: 'Address Details' },
          { key: 'preferredDay', label: 'Preferred Day' },
          { key: 'frequency', label: 'Frequency' },
          { key: 'evenOrOddWeeks', label: 'Even/Odd Weeks' },
          { key: 'amount', label: 'Pricing Details' },
          { key: 'crew', label: 'Crew (Foreman)' },
          { key: 'score', label: 'Site Review' }
        ].map(({ key, label }) => (
          <TableCell 
            key={key}
            className={`${classes.headerRow} ${classes.headerCell}`}
            onClick={() => requestSort(key)}
          >
            {label}
            {sortConfig.key === key && (
              <ArrowDropDownIcon 
                className={classes.sortIcon}
                style={{ 
                  transform: sortConfig.direction === 'desc' ? 'rotate(180deg)' : 'none' 
                }}
              />
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );

  const renderReviewCell = (site) => {
    // console.log('Review cell..', site.name)
    const { score, trend, hasIssues, daysAgo, detailedRatings } = getSiteReviewMetrics(site._id);
    
    if (!score) {
      return (
        <Typography variant="body2" color="textSecondary">
          No reviews
        </Typography>
      );
    }

    return (
      <Box className={classes.reviewScore}>
        <Tooltip title={
          <div className={classes.tooltipContent}>
            {detailedRatings.map((rating, idx) => (
              <div key={idx} className={classes.ratingRow}>
                <Typography variant="body2" style={{minWidth: '80px'}}>
                  {rating.name}:
                </Typography>
                <StarRating rating={rating.rating} small />
                {rating.notes && (
                  <Typography variant="caption" color="#eee">
                    - {rating.notes}
                  </Typography>
                )}
              </div>
            ))}
          </div>
        }>
          <Box>
            <Box className={classes.ratingRow}>
              <StarRating rating={score} />
              {trend !== 'neutral' && (
                trend === 'up' ? <TrendingUpIcon color="primary" /> : <TrendingDownIcon color="error" />
              )}
              {hasIssues && (
                <Tooltip title={`Has outstanding issues`}>
                  <WarningIcon className={classes.warningIcon} fontSize="small" />
                </Tooltip>
              )}
            </Box>
            <Typography className={classes.daysAgo}>
              {daysAgo}
            </Typography>
          </Box>
        </Tooltip>
      </Box>
    );
  };

  const saveThisToTheBackEnd = async (updatedSiteInfo) => {
    console.log('save this', updatedSiteInfo)
    axios({
      method: 'post',
      url:  `${props.url}/api/hmi/assignCrewToAg`,
      data: updatedSiteInfo,
    }).then(response => {
      // console.log("Update", response.data)
      if (response && response.status === 200) {
        props.notification({
          type: 'success',
          title: 'Saved That!',
          message: 'Great job!'
        })
      } else {
        props.notification({
          type: 'warning',
          title: 'Failed to Save That Update!',
          message: response?.data?.message || 'Unknown Error Occurred'
        })
      }

    })
  }
  
  const handleUpdateAssignedCrew = (site, crew) => {
    // console.log('Updat the assignment here:', site, crew)
    setState({...state, crewToConfirm: crew, siteToConfirm: site})
  }

  const handleCancel = () => {
    setState({...state, siteToConfirm: null })
  }

  const handleConfirm = () => {
    // console.log('Confirm this shit', state)
    let crewToAssign = null
    if (state.crewToConfirm && state.crewToConfirm._id) {
      crewToAssign = {
        _id: state.crewToConfirm._id,
        name: `${state.crewToConfirm.name} ${state.crewToConfirm.foremanName ? `(${state.crewToConfirm.foremanName})` : ''}`
      } 
    }
    let newSites = [...state.sites]
    if (state.siteToConfirm) {
      let index = newSites.findIndex(item => {
        if (item._id === state.siteToConfirm._id && state.siteToConfirm.contract === item.contract) {
          return true
        } else {
          return false
        }
      })
      if (index > -1) {
        let siteToUpdate = {...newSites[index]}
        siteToUpdate.crew = crewToAssign?._id
        siteToUpdate.crewName = siteToUpdate.name
        saveThisToTheBackEnd(siteToUpdate)
        newSites[index] = {...siteToUpdate}
        // console.log('w/ our crew:', siteToUpdate)
      } else {
        console.log('Set no Crew on this bitch!!!!!')
      }
    }
    // console.log('New Sites', newSites)
    filterOut(newSites, searchValue)
    setState({...state, siteToConfirm: null, crewToConfirm: null, sites: newSites })
  }

  const handleUpdateTheAssignedWeek = (site, newWeek) => {
    // console.log("Update this", site)
    console.log(newWeek)
    let newSites = [...state.sites]

    let index = newSites.findIndex(item => (item._id === site._id && item.contract === site.contract))
    if (index > -1) {
      let siteToUpdate = {...newSites[index]}
      siteToUpdate.evenOrOddWeeks = newWeek
      // console.log('site to update', siteToUpdate)
      saveThisToTheBackEnd(siteToUpdate)
      newSites[index] = {...siteToUpdate}
      // console.log('w/ our crew:', siteToUpdate)
      setState({...state, sites: newSites })
    }
  }

  const handleUpdateTheSite = (siteToUpdate) => {
    console.log('Update this one', siteToUpdate)
    let siteId = (siteToUpdate && siteToUpdate._id) ? siteToUpdate._id : (siteToUpdate && siteToUpdate.jobSite && siteToUpdate.jobSite._id) ? siteToUpdate.jobSite._id : siteToUpdate.jobSiteId
    console.log('Site ID', siteId)
    siteToUpdate._id = siteId
    console.log('gotta updat our sites...', state)
    let newSites = [...state.sitesWTotalValue].map(item => {
      // console.log(item)
      let itemId = item._id || item.jobSiteId
      if (itemId === siteId) {
        console.log('This is ti!!', item)
        const {
          frequency,
          evenOrOddWeeks,
          crew,
          preferredDay,
          preferredDays,
          amountType,
          amount,
          irrigationCheckFrequency,
          mowingRequirements,
          mowingTime,
          crewSize,
          estimatedHours,
          herbicideApplicationFrequency,
          herbicideApplicationFee,
          herbicideFeeType,
          doNotSentHerbicideInvoices,
          doNotSendHerbicideNotifications
        } = siteToUpdate
        const newSite = {
          ...item,
          frequency,
          evenOrOddWeeks,
          crew,
          preferredDay,
          preferredDays,
          amountType,
          amount,
          irrigationCheckFrequency,
          mowingRequirements,
          mowingTime,
          crewSize,
          estimatedHours,
          herbicideApplicationFrequency,
          herbicideApplicationFee,
          herbicideFeeType,
          doNotSentHerbicideInvoices,
          doNotSendHerbicideNotifications
        }
        // console.log('site we are using:', newSite)
        // if (newSite && newSite.crew && newSite.crew._id) {
        //   newSite.crew = newSite.crew._id
         
        // }
        if (siteToUpdate?.crew?._id) {
            newSite.crew = siteToUpdate.crew._id
           newSite.crewName = `${siteToUpdate.crew.name} ${siteToUpdate.crew.foremanName ? `(${siteToUpdate.crew.foremanName})` : ''}`
        } else {
          newSite.crewName = 'Unassigned'
        }
        saveThisToTheBackEnd(newSite)

        return newSite
      } else {
        return item
      }
    })
    console.log('Updated sites', newSites)
    filterOut(newSites, searchValue)
    setState(prevState => ({...prevState, sitesWTotalValue: newSites}))
  }

  const filterOut = (r, searchText) => {
    // console.log('FIlter out: %s - %s', r, searchText)
    let string = searchText
    let newArr = []
    r.filter(o => {
      // console.log('Object:  ', o)
      Object.keys(o).some(k => {
        // console.log(o[k])
        if(o[k]) {
          // console.log('OK: ', o[k])
          let str = o[k].toString().toLowerCase() 
          // console.log('String', str)
          if (str.includes(string.toLowerCase())) {
            // console.log('Mathc')
            return newArr.push(o)
          } else {
            return null
          }
        } else {
          return null
        }
      })
      // return newArr
    })
    setFilteredRows(newArr)
    setLoading(false)
  }

  const handleSearch = (e) => {
    if (e.target.value === '') {
      setSearchValue('')
      setFilteredRows(state.sites)
    } else {
      setSearchValue(e.target.value)
      filterOut(state.sites, e.target.value)
    }
  }

  const handleOpenEditSiteModal = (site) => {
    // console.log('Open Edit Site Modal', site)
    setActiveSite(site)
    setShowEditJobsiteModal(true)
  }

  const handleToggleEditJobSiteModal = () => {
    setShowEditJobsiteModal(!showEditJobsiteModal)
  }

  const updateJobSiteAfterEdit = (siteToUpdate) => {
    console.log('Update jobsite... ', siteToUpdate)
    setShowEditJobsiteModal(false)
    handleUpdateTheSite(siteToUpdate)
  }

  const ListOfJobsites = ({ crews, state, filteredRows, ...props }) => {
    const classes = useStyles();
    
    return (
      <Table size="small">
        {renderTableHeader()}
        <TableBody>
          {filteredRows.map((site, index) => {
            // console.log('site', site.name)
            // const { lastReview, trend, score, detailedRatings, hasIssues } = getSiteReviewMetrics(site._id);
            
            return (
              <TableRow 
                key={index} 
                className={classes.tableRow}
                onDoubleClick={() => props.handleOpenSite(site)}
              >
                {/* Keep existing cells */}
                <TableCell>{site.clientName}</TableCell>
                <TableCell>{site.name}</TableCell>
                <TableCell>{site.jobName}</TableCell>
                <TableCell>{site.customerType}</TableCell>
                <TableCell>
                  {site.communityName ? `${site.communityName} ` : ''}
                  {site.address}
                  {site.buildingNumber ? ` ${site.buildingNumber}` : ''}
                </TableCell>
                <TableCell>
                  <PreferredDays site={site} />
                </TableCell>
                <TableCell>{site.frequency}</TableCell>
                <TableCell>{site.evenOrOddWeeks || ''}</TableCell>
                <TableCell>
                  <div style={{ display: 'flex', flexDirection: 'column'}}>
                      <div>
                            {site.amount ? currencyFormat(site.amount) : ''} 
                        {site.amountType ? 
                          ` (${site.amountType === 'perVisit' ? 'Per Visit' : 'Per Month'})` 
                          : ''}
                      </div>
                      <div style={{ fontWeight: 300, color: '#516e76', fontSize: '0.85em' }}>
                      {site.monthlyValue ? `${currencyFormat(site.monthlyValue)}/mo` : ''} 
                      </div>
                  </div>
                 
                </TableCell>
                <TableCell>
                  {site.crew ? `${site.crewName}` : 'Unassigned'}
                </TableCell>
                
                {/* New Site Review Status Cell */}
                <TableCell>
                {renderReviewCell(site)}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };
  // console.log('Sored Rows', sortedRows)
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.header}>
          <Typography variant="h5" component="h2">
            HMI Crew Assignment
          </Typography>
        </div>

        <div className={classes.searchContainer}>
          <TextField
            name="search"
            value={searchValue}
            onChange={handleSearch}
            label="Search Sites"
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Typography variant="body2">
            Matching Sites: {filteredRows.length}
          </Typography>
        </div>

        {loading ? (
          <Box display="flex" justifyContent="center" m={3}>
            <CircularProgress />
          </Box>
        ) : (
          <ListOfJobsites
            crews={hmiCrews}
            state={state}
            filteredRows={sortedRows}
            updateTheAssignedCrew={handleUpdateAssignedCrew}
            handleCancel={handleCancel}
            handleConfirm={handleConfirm}
            updateTheAssignedWeek={handleUpdateTheAssignedWeek}
            updateTheSite={handleUpdateTheSite}
            handleOpenSite={handleOpenEditSiteModal}
          />
        )}
      </Paper>

      <EditJobSiteModal
        open={showEditJobsiteModal}
        {...props}
        activeSite={activeSite}
        closeAddJobsiteModal={handleToggleEditJobSiteModal}
        updateParent={updateJobSiteAfterEdit}
      />
    </div>
  );
}

const PreferredDays = ({ site }) => {
  if (site && (site.frequency === 'Twice a Week' || site.frequency === 'Four Days a Week')) {
    return site.preferredDays.join(', ')
  } else {
    return site.preferredDay
  }

}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url,
    crews: state.crews
  }
}

export default connect(mapStateToProps)(HMICrewAssignmentComponent)

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2),
  },
  paper: {
    width: '100%',
    height: '100%',
    minHeight: '80vh',
    padding: theme.spacing(3),
  },
  headerRow: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
  },
  headerCell: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  headerContent: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  starIcon: {
    color: theme.palette.primary.main,
    fontSize: '1.2rem',
  },
  starIconSmall: {
    fontSize: '1rem',
  },
  warningIcon: {
    color: theme.palette.warning.main,
    marginLeft: theme.spacing(1),
  },
  reviewScore: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  ratingRow: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  daysAgo: {
    color: theme.palette.text.secondary,
    fontSize: '0.75rem',
  },
  sortIcon: {
    marginLeft: theme.spacing(1),
    transition: 'transform 0.2s',
  },
  tooltipContent: {
    padding: theme.spacing(1),
  }
}));
