import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios'
import { connect } from 'react-redux'
import {
  Paper,
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Chip,
  Box,
  InputAdornment,
} from '@material-ui/core';
import { 
  Build as BuildIcon,
  Warning as WarningIcon,
  Error as ErrorIcon,
  Search as SearchIcon,
  Timeline as TimelineIcon,
} from '@material-ui/icons';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import RepairDetailDialog from './RepairDetailsModal'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  statsIcon: {
    width: 40,
    height: 40,
  },
  cardContent: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  filterContainer: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  searchField: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  tableContainer: {
    marginTop: theme.spacing(3),
  },
  priorityHigh: {
    backgroundColor: theme.palette.error.light,
  },
  priorityMedium: {
    backgroundColor: theme.palette.warning.light,
  },
  priorityLow: {
    backgroundColor: theme.palette.success.light,
  },
  statusChip: {
    margin: theme.spacing(0.5),
  },
  chartContainer: {
    height: 300,
    marginBottom: theme.spacing(3),
  },
}));

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RepairsDashboard = ({ repairs = [], onRowClick, TableRowComponent }) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [priorityFilter, setPriorityFilter] = useState('all');

  // Calculate summary statistics
  const totalRepairs = repairs.length;
  const openRepairs = repairs.filter(r => r.status !== 'Completed').length;
  const highPriorityRepairs = repairs.filter(r => r.priority === 'High').length;
  const outOfServiceCount = repairs.filter(r => r.outOfService).length;

  // Status distribution for pie chart
  const statusData = Object.entries(
    repairs.reduce((acc, repair) => {
      acc[repair.status] = (acc[repair.status] || 0) + 1;
      return acc;
    }, {})
  ).map(([name, value]) => ({ name, value }));

  // Filter repairs based on search and filters
  const filteredRepairs = repairs.filter(repair => {
    const matchesSearch = 
      repair.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
      repair.notes.toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesStatus = statusFilter === 'all' || repair.status === statusFilter;
    const matchesPriority = priorityFilter === 'all' || repair.priority === priorityFilter;
    
    return matchesSearch && matchesStatus && matchesPriority;
  });

  const getPriorityChipColor = (priority) => {
    switch (priority) {
      case 'High':
        return classes.priorityHigh;
      case 'Medium':
        return classes.priorityMedium;
      case 'Low':
        return classes.priorityLow;
      default:
        return '';
    }
  };

  const handleOpenRow = (row, index) => {
    console.log('Open this row', row)
    // setSelectedRepair(row)
    onRowClick(row)
  }

  return (
    <div className={classes.root}>
      {/* Summary Cards */}
      <Grid container spacing={3} style={{ marginBottom: '24px' }}>
        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div>
                <Typography color="textSecondary" gutterBottom>
                  Total Repairs
                </Typography>
                <Typography variant="h4">
                  {totalRepairs}
                </Typography>
              </div>
              <BuildIcon className={classes.statsIcon} color="primary" />
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div>
                <Typography color="textSecondary" gutterBottom>
                  Open Repairs
                </Typography>
                <Typography variant="h4">
                  {openRepairs}
                </Typography>
              </div>
              <TimelineIcon className={classes.statsIcon} style={{ color: '#FFB020' }} />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div>
                <Typography color="textSecondary" gutterBottom>
                  High Priority
                </Typography>
                <Typography variant="h4">
                  {highPriorityRepairs}
                </Typography>
              </div>
              <WarningIcon className={classes.statsIcon} color="error" />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div>
                <Typography color="textSecondary" gutterBottom>
                  Out of Service
                </Typography>
                <Typography variant="h4">
                  {outOfServiceCount}
                </Typography>
              </div>
              <ErrorIcon className={classes.statsIcon} style={{ color: '#FF6B6B' }} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Charts */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper className={classes.chartContainer}>
            <Typography variant="h6" style={{ padding: '16px' }}>
              Repair Status Distribution
            </Typography>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={statusData}
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  label={({name, value}) => `${name}: ${value}`}
                >
                  {statusData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>

      {/* Filters */}
      <Paper className={classes.filterContainer}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <TextField
              className={classes.searchField}
              variant="outlined"
              fullWidth
              placeholder="Search repairs..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel>Status</InputLabel>
              <Select
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
                label="Status"
              >
                <MenuItem value="all">All Statuses</MenuItem>
                <MenuItem value="Reported">Reported</MenuItem>
                <MenuItem value="In Progress">In Progress</MenuItem>
                <MenuItem value="Completed">Completed</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel>Priority</InputLabel>
              <Select
                value={priorityFilter}
                onChange={(e) => setPriorityFilter(e.target.value)}
                label="Priority"
              >
                <MenuItem value="all">All Priorities</MenuItem>
                <MenuItem value="Low">Low</MenuItem>
                <MenuItem value="Medium">Medium</MenuItem>
                <MenuItem value="High">High</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>

      {/* Repairs Table */}
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Equipment</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Priority</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Reported By</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRepairs.map((repair, index) => (
              <TableRow key={index} hover
                onDoubleClick={() => handleOpenRow(repair, index)}
              >
                <TableCell>{repair?.equipmentId?.name || ''} {repair?.equipmentId?.number || ''}</TableCell>
                <TableCell>{repair.description}</TableCell>
                <TableCell>
                  <Chip
                    label={repair.priority}
                    className={getPriorityChipColor(repair.priority)}
                  />
                </TableCell>
                <TableCell>
                  <Chip
                    label={repair.status}
                    className={classes.statusChip}
                    color={repair.status === 'Completed' ? 'primary' : 'default'}
                  />
                </TableCell>
                <TableCell>{repair.reportedBy}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const RepairsComponent = (props) => {
  const [repairs, setRepairs] = React.useState([])
  const [selectedRepair, setSelectedRepair] = React.useState(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      const resp = await axios.get(`${props.url}/api/equipment/getRepairs`)
      console.log('Got the repair data', resp)
      setRepairs(resp.data)
    }
    fetchData()
  }, [])


  const handleRowClick = (repair) => {
    setSelectedRepair(repair);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedRepair(null);
  };

  const handleUpdateRepair = async (updatedRepair) => {
    try {
      await axios.put(`${props.url}/api/equipment/updateRepair/${updatedRepair._id}`, updatedRepair);
      // Refresh the repairs list
      fetchRepairs();
      handleCloseDialog();
    } catch (error) {
      console.error('Error updating repair:', error);
    }
  };

  const TableRowComponent = ({ repair, onClick }) => (
    <TableRow 
      hover 
      onClick={() => onClick(repair)}
      style={{ cursor: 'pointer' }}
    >
      <TableCell>{repair?.equipmentId?.name || ''} {repair?.equipmentId?.number || ''}</TableCell>
      <TableCell>{repair.description}</TableCell>
      <TableCell>
        <Chip
          label={repair.priority}
          className={getPriorityChipColor(repair.priority)}
        />
      </TableCell>
      <TableCell>
        <Chip
          label={repair.status}
          className={classes.statusChip}
          color={repair.status === 'Completed' ? 'primary' : 'default'}
        />
      </TableCell>
      <TableCell>{repair.reportedBy}</TableCell>
    </TableRow>
  );

  return (
    <>
      <RepairsDashboard 
        repairs={repairs} 
        onRowClick={handleRowClick}
        TableRowComponent={TableRowComponent}
      />
      <RepairDetailDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        repair={selectedRepair}
        onUpdate={handleUpdateRepair}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url
  }
}

export default connect(mapStateToProps)(RepairsComponent)
