import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import { Card, CardContent, Typography } from '@material-ui/core'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import { fade, makeStyles } from '@material-ui/core/styles';
import WaivedLateFeesModal from '../WaivedLateFeesModal'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  sweetBorder: {
    border: '1px solid',
    borderRadius: '10px'
  },
  fakeLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue'
  }
}));

function currencyFormat(num) {
  if (!num) num = 0
  return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const AccountSummaryReport = (props) => {
  const classes = useStyles();
  const [arStuff, setArStuff] = React.useState({
    all: 0,
    zeroToThirty: 0,
    thirtyToSixty: 0,
    sixtyToNinety: 0,
    ninetyPlus: 0
  })
  const [depositStuff, setDepositStuff] = React.useState({
    thisYear: 0,
    unused: 0
  })

  const [creditStuff, setCreditStuff] = React.useState({
    thisYear: 0,
    thisMonth: 0,
    thisWeek: 0,
    today: 0
  })

  const [cashStuff, setCashStuff] = React.useState({
    thisYear: 0,
    thisMonth: 0,
    thisWeek: 0,
    today: 0,
    totalSales: 0,
    averageSale: 0
  })

  const [invoiceStuff, setInvoiceStuff] = React.useState({
    thisYear: 0,
    thisMonth: 0,
    thisWeek: 0,
    today: 0
  })

  const [writeOffStuff, setWriteOffStuff] = React.useState({
    thisYear: 0,
    thisMonth: 0,
    thisWeek: 0,
    today: 0
  })

  const [collectedLateFees, setCollectedLateFees] = React.useState({
    thisYear: 0,
    thisMonth: 0,
    thisWeek: 0,
    today: 0
  })

  const [waivedLateFees, setWaivedLateFees] = React.useState({
    thisYear: 0,
    thisMonth: 0,
    thisWeek: 0,
    today: 0
  })

  const [showWaivedLateFees, setShowWaivedLateFees] = React.useState(false)
  const [lateFees, setLateFees] = React.useState([])
  const [lateFeeAmount, setLateFeeAmount] = React.useState(0)

  React.useEffect(() => {
    // console.log('SEt Aour Acct Summary: ', props)
    if (props.data && props.data.arStuff) setArStuff(props.data.arStuff)
    if (props.data && props.data.depositStuff) setDepositStuff(props.data.depositStuff)
    if (props.data && props.data.invoiceStuff) setInvoiceStuff(props.data.invoiceStuff)
    if (props.data && props.data.cashStuff) setCashStuff(props.data.cashStuff)
    if (props.data && props.data.creditStuff) setCreditStuff(props.data.creditStuff)
    if (props.data && props.data.writeOffStuff) setWriteOffStuff(props.data.writeOffStuff)
    if (props.data && props.data.waivedLateFees) setWaivedLateFees(props.data.waivedLateFees)
    if (props.data && props.data.collectedLateFees) setCollectedLateFees(props.data.collectedLateFees)
  }, [props.data])

  const handleShowWaivedLateFees = async (type, feeAmountType) => {
    // console.log('Show late fees: ', type)
    let response = await axios.get(`${props.url}/api/payment/getWaivedLateFees?timeframe=${type}`)
    // console.log('Res: ', response)
    setLateFees(response.data.lateFees || [])
    setLateFeeAmount(currencyFormat(waivedLateFees[feeAmountType]))
    setShowWaivedLateFees(true)
  }

  const closeWaivedLateFeesModal = (d) => {

    setShowWaivedLateFees(false)
  }

  return (
    <>
      <WaivedLateFeesModal open={showWaivedLateFees} lateFees={lateFees} lateFeeAmount={lateFeeAmount} closeModal={closeWaivedLateFeesModal} />
      <Card style={{margin: '5px'}}>
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom>
            Account Summary Details:
          </Typography>
          <Grid container spacing={1} wrap={'nowrap'}>
            <Grid item lg={3} sm={6} style={{margin: '5px'}} className={classes.sweetBorder}>
              <Grid container spacing={1} style={{margin: '5px'}}>
                <Grid item lg={12} xs={12}>
                  Account Receivable All
                </Grid>
                <Grid item lg={12} xs={12}>
                  {currencyFormat(arStuff.all)}
                </Grid>
                <Grid item lg={12} xs={12}>
                  Account Receivable Under 30 Days
                </Grid>
                <Grid item lg={12} xs={12}>
                  {currencyFormat(arStuff['0-30'])}
                </Grid>
                <Grid item lg={12} xs={12}>
                  Account Receivable 31-60 Days
                </Grid>
                <Grid item lg={12} xs={12}>
                  {currencyFormat(arStuff['31-60'])}
                </Grid>
                <Grid item lg={12} xs={12}>
                  Account Receivable 61-90 Days
                </Grid>
                <Grid item lg={12} xs={12}>
                  {currencyFormat(arStuff['61-90'])}
                </Grid>
                <Grid item lg={12} xs={12}>
                  Account Receivable Over 90 Days
                </Grid>
                <Grid item lg={12} xs={12}>
                  {currencyFormat(arStuff['90+'])}
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={3} sm={6} style={{margin: '5px'}} className={classes.sweetBorder}>
              <Grid container spacing={1} style={{margin: '5px', padding: '5px'}}>
                <Grid item lg={12} xs={12}>
                  Prepayments This Year
                </Grid>
                <Grid item lg={12} xs={12}>
                  {currencyFormat(depositStuff.thisYear)}
                </Grid>
                <Grid item lg={12} xs={12}>
                  Unused Prepayments
                </Grid>
                <Grid item lg={12} xs={12}>
                  {currencyFormat(depositStuff.unused)}
                </Grid>
                <Grid item lg={12} xs={12}>
                  <br />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <br />
                </Grid>
                <Grid item lg={12} xs={12}>
                  Unscheduled Jobs
                </Grid>
                <Grid item lg={12} xs={12}>
                  {currencyFormat(depositStuff.unscheduledJobs)}
                </Grid>
                <Grid item lg={12} xs={12}>
                  Jobs Ready To Invoice
                </Grid>
                <Grid item lg={12} xs={12}>
                  {currencyFormat(depositStuff.jobsReadyToInvoice)}
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={3} sm={6} style={{margin: '5px'}} className={classes.sweetBorder}>
              <Grid container spacing={1} style={{margin: '5px', padding: '5px'}}>
                <Grid item lg={12} xs={12}>
                  Invoice Today
                </Grid>
                <Grid item lg={12} xs={12}>
                  {currencyFormat(invoiceStuff['today'])}
                </Grid>
                <Grid item lg={12} xs={12}>
                  Invoice This Week
                </Grid>
                <Grid item lg={12} xs={12}>
                {currencyFormat(invoiceStuff['thisWeek'])}
                </Grid>
                <Grid item lg={12} xs={12}>
                  Invoice This Month
                </Grid>
                <Grid item lg={12} xs={12}>
                {currencyFormat(invoiceStuff['thisMonth'])}
                </Grid>
                <Grid item lg={12} xs={12}>
                  Invoice This Year
                </Grid>
                <Grid item lg={12} xs={12}>
                {currencyFormat(invoiceStuff['thisYear'])}
                </Grid>
                <br /><br />
                <Grid item lg={12} xs={12}>
                  Credit Memos Today
                </Grid>
                <Grid item lg={12} xs={12}>
                  {currencyFormat(creditStuff['today'])}
                </Grid>
                <Grid item lg={12} xs={12}>
                  Credit Memos This Week
                </Grid>
                <Grid item lg={12} xs={12}>
                  {currencyFormat(creditStuff['thisWeek'])}
                </Grid>
                <Grid item lg={12} xs={12}>
                  Credit Memos This Month
                </Grid>
                <Grid item lg={12} xs={12}>
                  {currencyFormat(creditStuff['thisMonth'])}
                </Grid>
                <Grid item lg={12} xs={12}>
                  Credit Memos This Year
                </Grid>
                <Grid item lg={12} xs={12}>
                  {currencyFormat(creditStuff['thisYear'])}
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={3} sm={6} style={{margin: '5px'}} className={classes.sweetBorder}>
              <Grid container spacing={1} style={{margin: '5px', padding: '5px'}}>
                <Grid item lg={12} xs={12}>
                  Cash Receipts Today
                </Grid>
                <Grid item lg={12} xs={12}>
                {currencyFormat(cashStuff['today'])}
                </Grid>
                <Grid item lg={12} xs={12}>
                  Cash Receipts This Week
                </Grid>
                <Grid item lg={12} xs={12}>
                {currencyFormat(cashStuff['thisWeek'])}
                </Grid>
                <Grid item lg={12} xs={12}>
                  Cash Receipts This Month
                </Grid>
                <Grid item lg={12} xs={12}>
                {currencyFormat(cashStuff['thisMonth'])}
                </Grid>
                <Grid item lg={12} xs={12}>
                  Cash Receipts This Year
                </Grid>
                <Grid item lg={12} xs={12}>
                {currencyFormat(cashStuff['thisYear'])}
                </Grid>
                <br /><br />
                <Grid item lg={12} xs={12}>
                  Total Sales
                </Grid>
                <Grid item lg={12} xs={12}>
                {currencyFormat(cashStuff['totalSales'])}
                </Grid>
                <Grid item lg={12} xs={12}>
                  Average Sale
                </Grid>
                <Grid item lg={12} xs={12}>
                {currencyFormat(cashStuff['averageSale'])}
                </Grid>
                <br /><br />
                <Grid item lg={12} xs={12}>
                  Bad Debt Today - {currencyFormat(writeOffStuff['today'])}
                </Grid>
                <Grid item lg={12} xs={12}>
                  Bad Debt This Week - {currencyFormat(writeOffStuff['thisWeek'])}
                </Grid>
                <Grid item lg={12} xs={12}>
                  Bad Debt This Month - {currencyFormat(writeOffStuff['thisMonth'])}
                </Grid>
                <Grid item lg={12} xs={12}>
                  Bad Debt This Year - {currencyFormat(writeOffStuff['thisYear'])}
                </Grid>
                
                <br /><br />
                <Grid item lg={12} xs={12}>
                  Collected Late Fees Today - {currencyFormat(collectedLateFees['today'])}
                </Grid>
                <Grid item lg={12} xs={12}>
                Collected Late Fees This Week - {currencyFormat(collectedLateFees['thisWeek'])}
                </Grid>
                <Grid item lg={12} xs={12}>
                Collected Late Fees This Month - {currencyFormat(collectedLateFees['thisMonth'])}
                </Grid>
                <Grid item lg={12} xs={12}>
                Collected Late Fees This Year - {currencyFormat(collectedLateFees['thisYear'])}
                </Grid>
                <br /><br />
                <Grid item lg={12} xs={12} onClick={e => handleShowWaivedLateFees('day', 'today')} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }} >
                  Waived Late Fees Today - {currencyFormat(waivedLateFees['today'])}
                </Grid>
                <Grid item lg={12} xs={12} onClick={e => handleShowWaivedLateFees('week', 'thisWeek')} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
                  Waived Late Fees This Week - {currencyFormat(waivedLateFees['thisWeek'])}
                </Grid>
                <Grid item lg={12} xs={12} onClick={e => handleShowWaivedLateFees('month', 'thisMonth')} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
                    Waived Late Fees This Month - {currencyFormat(waivedLateFees['thisMonth'])}
                </Grid>
                <Grid item lg={12} xs={12} onClick={e => handleShowWaivedLateFees('year', 'thisYear')} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
                  Waived Late Fees This Year - {currencyFormat(waivedLateFees['thisYear'])}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}

  const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(AccountSummaryReport)
