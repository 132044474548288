import React from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  List,
  ListItem,
  ListItemText,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  section: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  paragraph: {
    marginBottom: theme.spacing(2),
  },
  list: {
    marginLeft: theme.spacing(4),
    paddingLeft: theme.spacing(2),
  },
  listItem: {
    display: 'list-item',
    listStyleType: 'disc',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  tableContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  address: {
    marginLeft: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    borderLeft: `4px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(2),
  },
  footer: {
    marginTop: theme.spacing(6),
    paddingTop: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }
}));

const PrivacyPolicy = () => {
  const classes = useStyles();

  return (
    <Container className={classes.root} maxWidth="md" component={Paper} elevation={2}>
      <Box p={4}>
        <Box mb={4}>
          <Typography variant="h3" component="h1" className={classes.title}>
            PRIVACY POLICY
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            Last updated February 04, 2024
          </Typography>
        </Box>

        <Box className={classes.section}>
          <Typography variant="body1" className={classes.paragraph}>
            This Privacy Notice for Mariani Enterprises LLC (doing business as HMI of AZ) ("we," "us," or "our"), 
            describes how and why we might access, collect, store, use, and/or share ("process") your personal 
            information when you use our services ("Services"), including when you:
          </Typography>
          <List className={classes.list}>
            <ListItem className={classes.listItem}>
              <ListItemText primary="Download and use our mobile application (DFL), or any other application of ours that links to this Privacy Notice" />
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText primary="Use DFL Mobile Application. The DFL Mobile Application is designed to be used by Employees of ACI, and its subsidiaries. The information contained within the mobile app is private and should not be shared outside of the company." />
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText primary="Engage with us in other related ways, including any sales, marketing, or events" />
            </ListItem>
          </List>
          <Typography variant="body1" className={classes.paragraph}>
            <strong>Questions or concerns?</strong> Reading this Privacy Notice will help you understand your privacy rights and choices. 
            We are responsible for making decisions about how your personal information is processed. If you do not agree with our policies and practices, 
            please do not use our Services. If you still have any questions or concerns, please contact us at office@dflaz.com.
          </Typography>
        </Box>

        <Divider />

        <Box className={classes.section}>
          <Typography variant="h4" component="h2" className={classes.title}>
            SUMMARY OF KEY POINTS
          </Typography>
          <Typography variant="body1" className={classes.paragraph}>
            <em><strong>This summary provides key points from our Privacy Notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our <Link href="#toc" className={classes.link}>table of contents</Link> below to find the section you are looking for.</strong></em>
          </Typography>
          
          <Box mb={2}>
            <Typography variant="body1" className={classes.paragraph}>
              <strong>What personal information do we process?</strong> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about <Link href="#personalinfo" className={classes.link}>personal information you disclose to us</Link>.
            </Typography>
            
            <Typography variant="body1" className={classes.paragraph}>
              <strong>Do we process any sensitive personal information?</strong> Some of the information may be considered "special" or "sensitive" in certain jurisdictions, for example your racial or ethnic origins, sexual orientation, and religious beliefs. We do not process sensitive personal information.
            </Typography>
            
            <Typography variant="body1" className={classes.paragraph}>
              <strong>Do we collect any information from third parties?</strong> We do not collect any information from third parties.
            </Typography>
            
            <Typography variant="body1" className={classes.paragraph}>
              <strong>How do we process your information?</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about <Link href="#infouse" className={classes.link}>how we process your information</Link>.
            </Typography>
            
            <Typography variant="body1" className={classes.paragraph}>
              <strong>In what situations and with which parties do we share personal information?</strong> We may share information in specific situations and with specific third parties. Learn more about <Link href="#whoshare" className={classes.link}>when and with whom we share your personal information</Link>.
            </Typography>
            
            <Typography variant="body1" className={classes.paragraph}>
              <strong>How do we keep your information safe?</strong> We have adequate organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about <Link href="#infosafe" className={classes.link}>how we keep your information safe</Link>.
            </Typography>
            
            <Typography variant="body1" className={classes.paragraph}>
              <strong>What are your rights?</strong> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about <Link href="#privacyrights" className={classes.link}>your privacy rights</Link>.
            </Typography>
            
            <Typography variant="body1" className={classes.paragraph}>
              <strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by submitting a <Link href="https://app.termly.io/notify/8d27bc09-64ba-4c8a-8f63-cd063d455664" className={classes.link} target="_blank" rel="noopener noreferrer">data subject access request</Link>, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.
            </Typography>
          </Box>
          
          <Typography variant="body1" className={classes.paragraph}>
            Want to learn more about what we do with any information we collect? <Link href="#toc" className={classes.link}>Review the Privacy Notice in full</Link>.
          </Typography>
        </Box>

        <Divider />

        <Box id="toc" className={classes.section}>
          <Typography variant="h4" component="h2" className={classes.title}>
            TABLE OF CONTENTS
          </Typography>
          <List>
            <ListItem>
              <ListItemText>
                <Typography variant="body1">
                  1. <Link href="#infocollect" className={classes.link}>WHAT INFORMATION DO WE COLLECT?</Link>
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Typography variant="body1">
                  2. <Link href="#infouse" className={classes.link}>HOW DO WE PROCESS YOUR INFORMATION?</Link>
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Typography variant="body1">
                  3. <Link href="#whoshare" className={classes.link}>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</Link>
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Typography variant="body1">
                  4. <Link href="#cookies" className={classes.link}>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</Link>
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Typography variant="body1">
                  5. <Link href="#inforetain" className={classes.link}>HOW LONG DO WE KEEP YOUR INFORMATION?</Link>
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Typography variant="body1">
                  6. <Link href="#infosafe" className={classes.link}>HOW DO WE KEEP YOUR INFORMATION SAFE?</Link>
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Typography variant="body1">
                  7. <Link href="#infominors" className={classes.link}>DO WE COLLECT INFORMATION FROM MINORS?</Link>
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Typography variant="body1">
                  8. <Link href="#privacyrights" className={classes.link}>WHAT ARE YOUR PRIVACY RIGHTS?</Link>
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Typography variant="body1">
                  9. <Link href="#DNT" className={classes.link}>CONTROLS FOR DO-NOT-TRACK FEATURES</Link>
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Typography variant="body1">
                  10. <Link href="#uslaws" className={classes.link}>DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</Link>
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Typography variant="body1">
                  11. <Link href="#policyupdates" className={classes.link}>DO WE MAKE UPDATES TO THIS NOTICE?</Link>
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Typography variant="body1">
                  12. <Link href="#contact" className={classes.link}>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</Link>
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Typography variant="body1">
                  13. <Link href="#request" className={classes.link}>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</Link>
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Box>

        <Divider />

        <Box id="infocollect" className={classes.section}>
          <Typography variant="h4" component="h2" className={classes.title}>
            1. WHAT INFORMATION DO WE COLLECT?
          </Typography>
          
          <Typography variant="h5" component="h3" id="personalinfo" className={classes.subtitle}>
            Personal information you disclose to us
          </Typography>
          
          <Typography variant="body1" className={classes.paragraph}>
            <em><strong>In Short:</strong> We collect personal information that you provide to us.</em>
          </Typography>
          
          <Typography variant="body1" className={classes.paragraph}>
            We collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
          </Typography>
          
          <Box id="sensitiveinfo" mb={2}>
            <Typography variant="body1" className={classes.paragraph}>
              <strong>Sensitive Information.</strong> We do not process sensitive information.
            </Typography>
          </Box>
          
          <Box mb={2}>
            <Typography variant="body1" className={classes.paragraph}>
              <strong>Application Data.</strong> If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:
            </Typography>
            <List className={classes.list}>
              <ListItem className={classes.listItem}>
                <ListItemText 
                  primary={
                    <Typography variant="body1">
                      <em>Geolocation Information.</em> We may request access or permission to track location-based information from your mobile device, either continuously or while you are using our mobile application(s), to provide certain location-based services. If you wish to change our access or permissions, you may do so in your device's settings.
                    </Typography>
                  } 
                />
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemText 
                  primary={
                    <Typography variant="body1">
                      <em>Mobile Device Access.</em> We may request access or permission to certain features from your mobile device, including your mobile device's camera, microphone, and other features. If you wish to change our access or permissions, you may do so in your device's settings.
                    </Typography>
                  } 
                />
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemText 
                  primary={
                    <Typography variant="body1">
                      <em>Push Notifications.</em> We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device's settings.
                    </Typography>
                  } 
                />
              </ListItem>
            </List>
            <Typography variant="body1" className={classes.paragraph}>
              This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.
            </Typography>
          </Box>
          
          <Typography variant="body1" className={classes.paragraph}>
            All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
          </Typography>
        </Box>

        <Divider />

        <Box id="infouse" className={classes.section}>
          <Typography variant="h4" component="h2" className={classes.title}>
            2. HOW DO WE PROCESS YOUR INFORMATION?
          </Typography>
          
          <Typography variant="body1" className={classes.paragraph}>
            <em><strong>In Short:</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</em>
          </Typography>
          
          <Typography variant="body1" className={classes.paragraph}>
            <strong>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</strong>
          </Typography>
          
          <List className={classes.list}>
            <ListItem className={classes.listItem}>
              <ListItemText 
                primary={
                  <Typography variant="body1">
                    <strong>To deliver and facilitate delivery of services to the user.</strong> We may process your information to provide you with the requested service.
                  </Typography>
                } 
              />
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText 
                primary={
                  <Typography variant="body1">
                    <strong>To send administrative information to you.</strong> We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.
                  </Typography>
                } 
              />
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText 
                primary={
                  <Typography variant="body1">
                    <strong>To fulfill and manage your orders.</strong> We may process your information to fulfill and manage your orders, payments, returns, and exchanges made through the Services.
                  </Typography>
                } 
              />
            </ListItem>
          </List>
        </Box>

        <Divider />

        <Box id="whoshare" className={classes.section}>
          <Typography variant="h4" component="h2" className={classes.title}>
            3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </Typography>
          
          <Typography variant="body1" className={classes.paragraph}>
            <em><strong>In Short:</strong>We respect your privacy, and no mobile opt-in data will be shared with third parties. Your consent to receive SMS communications is obtained directly from you, and your information will only be used in accordance with our policy.</em>
          </Typography>
          
          <Typography variant="body1" className={classes.paragraph}>
            We may need to share your personal information in the following situations:
          </Typography>
          
          <List className={classes.list}>
            <ListItem className={classes.listItem}>
              <ListItemText 
                primary={
                  <Typography variant="body1">
                    <strong>Business Transfers.</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
                  </Typography>
                } 
              />
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText 
                primary={
                  <Typography variant="body1">
                    <strong>When we use Google Maps Platform APIs.</strong> We may share your information with certain Google Maps Platform APIs (e.g., Google Maps API, Places API). Google Maps uses GPS, Wi-Fi, and cell towers to estimate your location. GPS is accurate to about 20 meters, while Wi-Fi and cell towers help improve accuracy when GPS signals are weak, like indoors. This data helps Google Maps provide directions, but it is not always perfectly precise.
                  </Typography>
                } 
              />
            </ListItem>
          </List>
        </Box>

        <Divider />

        <Box id="cookies" className={classes.section}>
          <Typography variant="h4" component="h2" className={classes.title}>
            4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          </Typography>
          
          <Typography variant="body1" className={classes.paragraph}>
            <em><strong>In Short:</strong> We may use cookies and other tracking technologies to collect and store your information.</em>
          </Typography>
          
          <Typography variant="body1" className={classes.paragraph}>
            We may use cookies and similar tracking technologies (like web beacons and pixels) to gather information when you interact with our Services. Some online tracking technologies help us maintain the security of our Services, prevent crashes, fix bugs, save your preferences, and assist with basic site functions.
          </Typography>
          
          <Typography variant="body1" className={classes.paragraph}>
            We also permit third parties and service providers to use online tracking technologies on our Services for analytics and advertising, including to help manage and display advertisements, to tailor advertisements to your interests, or to send abandoned shopping cart reminders (depending on your communication preferences). The third parties and service providers use their technology to provide advertising about products and services tailored to your interests which may appear either on our Services or on other websites.
          </Typography>
          
          <Typography variant="body1" className={classes.paragraph}>
            To the extent these online tracking technologies are deemed to be a "sale"/"sharing" (which includes targeted advertising, as defined under the applicable laws) under applicable US state laws, you can opt out of these online tracking technologies by submitting a request as described below under section "<Link href="#uslaws" className={classes.link}>DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</Link>"
          </Typography>
          
          <Typography variant="body1" className={classes.paragraph}>
            Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.
          </Typography>
        </Box>

        <Divider />

        <Box id="inforetain" className={classes.section}>
          <Typography variant="h4" component="h2" className={classes.title}>
            5. HOW LONG DO WE KEEP YOUR INFORMATION?
          </Typography>
          
          <Typography variant="body1" className={classes.paragraph}>
            <em><strong>In Short:</strong> We keep your information for as long as necessary to fulfill the purposes outlined in this Privacy Notice unless otherwise required by law.</em>
          </Typography>
          
          <Typography variant="body1" className={classes.paragraph}>
            We will only keep your personal information for as long as it is necessary for the purposes set out in this Privacy Notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).
          </Typography>
          
          <Typography variant="body1" className={classes.paragraph}>
            When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
          </Typography>
        </Box>

        <Divider />

        <Box id="infosafe" className={classes.section}>
          <Typography variant="h4" component="h2" className={classes.title}>
            6. HOW DO WE KEEP YOUR INFORMATION SAFE?
          </Typography>
          
          <Typography variant="body1" className={classes.paragraph}>
            <em><strong>In Short:</strong> We aim to protect your personal information through a system of organizational and technical security measures.</em>
          </Typography>
          
          <Typography variant="body1" className={classes.paragraph}>
            We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.
          </Typography>
        </Box>

        <Divider />

        <Box id="infominors" className={classes.section}>
          <Typography variant="h4" component="h2" className={classes.title}>
            7. DO WE COLLECT INFORMATION FROM MINORS?
          </Typography>
          
          <Typography variant="body1" className={classes.paragraph}>
            <em><strong>In Short:</strong> We do not knowingly collect data from or market to children under 18 years of age.</em>
          </Typography>
          
          <Typography variant="body1" className={classes.paragraph}>
            We do not knowingly collect, solicit data from, or market to children under 18 years of age, nor do we knowingly sell such personal information. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent's use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at office@dflaz.com.
          </Typography>
        </Box>

        <Divider />

        <Box id="privacyrights" className={classes.section}>
          <Typography variant="h4" component="h2" className={classes.title}>
            8. WHAT ARE YOUR PRIVACY RIGHTS?
          </Typography>
          
          <Typography variant="body1" className={classes.paragraph}>
            <em><strong>In Short:</strong> You may review, change, or terminate your account at any time, depending on your country, province, or state of residence.</em>
          </Typography>
          
          <Box id="withdrawconsent" mb={2}>
            <Typography variant="body1" className={classes.paragraph}>
              <strong><u>Withdrawing your consent:</u></strong> If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section "<Link href="#contact" className={classes.link}>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</Link>" below.
            </Typography>
            <Typography variant="body1" className={classes.paragraph}>
              However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
            </Typography>
          </Box>
          
          <Typography variant="body1" className={classes.paragraph}>
            If you have questions or comments about your privacy rights, you may email us at office@dflaz.com.
          </Typography>
        </Box>

        <Divider />

        <Box id="DNT" className={classes.section}>
          <Typography variant="h4" component="h2" className={classes.title}>
            9. CONTROLS FOR DO-NOT-TRACK FEATURES
          </Typography>
          
          <Typography variant="body1" className={classes.paragraph}>
            Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Notice.
          </Typography>
          
          <Typography variant="body1" className={classes.paragraph}>
            California law requires us to let you know how we respond to web browser DNT signals. Because there currently is not an industry or legal standard for recognizing or honoring DNT signals, we do not respond to them at this time.
          </Typography>
        </Box>

        <Divider />

        <Box id="uslaws" className={classes.section}>
          <Typography variant="h4" component="h2" className={classes.title}>
            10. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </Typography>
          
          <Typography variant="body1" className={classes.paragraph}>
            <em><strong>In Short:</strong> If you are a resident of California, Colorado, Connecticut, Delaware, Florida, Indiana, Iowa, Kentucky, Minnesota, Montana, Nebraska, New Hampshire, New Jersey, Oregon, Tennessee, Texas, Utah, or Virginia, you may have the right to request access to and receive details about the personal information we maintain about you and how we have processed it, correct inaccuracies, get a copy of, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. More information is provided below.</em>
          </Typography>
          
          <Typography variant="h5" component="h3" className={classes.subtitle}>
            Categories of Personal Information We Collect
          </Typography>
          
          <Typography variant="body1" className={classes.paragraph}>
            We have collected the following categories of personal information in the past twelve (12) months:
          </Typography>
          
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table aria-label="privacy information categories">
              <TableHead>
                <TableRow>
                  <TableCell><strong>Category</strong></TableCell>
                  <TableCell><strong>Examples</strong></TableCell>
                  <TableCell align="center"><strong>Collected</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>A. Identifiers</TableCell>
                  <TableCell>Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name</TableCell>
                  <TableCell align="center">NO</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>B. Personal information as defined in the California Customer Records statute</TableCell>
                  <TableCell>Name, contact information, education, employment, employment history, and financial information</TableCell>
                  <TableCell align="center">NO</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>C. Protected classification characteristics under state or federal law</TableCell>
                  <TableCell>Gender, age, date of birth, race and ethnicity, national origin, marital status, and other demographic data</TableCell>
                  <TableCell align="center">NO</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>D. Commercial information</TableCell>
                  <TableCell>Transaction information, purchase history, financial details, and payment information</TableCell>
                  <TableCell align="center">NO</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>E. Biometric information</TableCell>
                  <TableCell>Fingerprints and voiceprints</TableCell>
                  <TableCell align="center">NO</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>F. Internet or other similar network activity</TableCell>
                  <TableCell>Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements</TableCell>
                  <TableCell align="center">NO</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>G. Geolocation data</TableCell>
                  <TableCell>Device location</TableCell>
                  <TableCell align="center">YES</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>H. Audio, electronic, sensory, or similar information</TableCell>
                  <TableCell>Images and audio, video or call recordings created in connection with our business activities</TableCell>
                  <TableCell align="center">NO</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>I. Professional or employment-related information</TableCell>
                  <TableCell>Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us</TableCell>
                  <TableCell align="center">NO</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>J. Education Information</TableCell>
                  <TableCell>Student records and directory information</TableCell>
                  <TableCell align="center">NO</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>K. Inferences drawn from collected personal information</TableCell>
                  <TableCell>Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual's preferences and characteristics</TableCell>
                  <TableCell align="center">NO</TableCell>
                </TableRow>
                  <TableRow>
                  <TableCell>L. Sensitive personal Information</TableCell>
                  <TableCell></TableCell>
                  <TableCell align="center">NO</TableCell>
                  </TableRow>
                  </TableBody>
                  </Table>
                  </TableContainer>
                            
                  <Typography variant="body1" className={classes.paragraph}>
                  We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:
                  </Typography>
                            
                  <List className={classes.list}>
                  <ListItem className={classes.listItem}>
                  <ListItemText primary="Receiving help through our customer support channels;" />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                  <ListItemText primary="Participation in customer surveys or contests; and" />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                  <ListItemText primary="Facilitation in the delivery of our Services and to respond to your inquiries." />
                  </ListItem>
                  </List>
                            
                  <Typography variant="body1" className={classes.paragraph}>
                  We will use and retain the collected personal information as needed to provide the Services or for:
                  </Typography>
                            
                  <List className={classes.list}>
                  <ListItem className={classes.listItem}>
                  <ListItemText primary="Category G - As long as required to complete the current job. Locations are not stored for future usage, but are used to show distance to jobs, etc." />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                  <ListItemText primary="Category H - As long as the user has an account with us" />
                  </ListItem>
                  </List>
                            
                  <Typography variant="h5" component="h3" className={classes.subtitle}>
                  Sources of Personal Information
                  </Typography>
                  <Typography variant="body1" className={classes.paragraph}>
                  Learn more about the sources of personal information we collect in "<Link href="#infocollect" className={classes.link}>WHAT INFORMATION DO WE COLLECT?</Link>"
                  </Typography>
                            
                  <Typography variant="h5" component="h3" className={classes.subtitle}>
                  How We Use and Share Personal Information
                  </Typography>
                  <Typography variant="body1" className={classes.paragraph}>
                  Learn more about how we use your personal information in the section, "<Link href="#infouse" className={classes.link}>HOW DO WE PROCESS YOUR INFORMATION?</Link>"
                  </Typography>
                            
                  <Typography variant="body1" className={classes.paragraph}>
                  <strong>Will your information be shared with anyone else?</strong>
                  </Typography>
                            
                  <Typography variant="body1" className={classes.paragraph}>
                  We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Learn more about how we disclose personal information to in the section, "<Link href="#whoshare" className={classes.link}>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</Link>"
                  </Typography>
                            
                  <Typography variant="body1" className={classes.paragraph}>
                  We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be "selling" of your personal information.
                  </Typography>
                            
                  <Typography variant="body1" className={classes.paragraph}>
                  We have not disclosed, sold, or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. We will not sell or share personal information in the future belonging to website visitors, users, and other consumers.
                  </Typography>
                            
                  <Typography variant="h5" component="h3" className={classes.subtitle}>
                  Your Rights
                  </Typography>
                  <Typography variant="body1" className={classes.paragraph}>
                  You have rights under certain US state data protection laws. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law. These rights include:
                  </Typography>
                            
                  <List className={classes.list}>
                  <ListItem className={classes.listItem}>
                  <ListItemText 
                    primary={
                      <Typography variant="body1">
                        <strong>Right to know</strong> whether or not we are processing your personal data
                      </Typography>
                    } 
                  />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                  <ListItemText 
                    primary={
                      <Typography variant="body1">
                        <strong>Right to access</strong> your personal data
                      </Typography>
                    } 
                  />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                  <ListItemText 
                    primary={
                      <Typography variant="body1">
                        <strong>Right to correct</strong> inaccuracies in your personal data
                      </Typography>
                    } 
                  />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                  <ListItemText 
                    primary={
                      <Typography variant="body1">
                        <strong>Right to request</strong> the deletion of your personal data
                      </Typography>
                    } 
                  />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                  <ListItemText 
                    primary={
                      <Typography variant="body1">
                        <strong>Right to obtain a copy</strong> of the personal data you previously shared with us
                      </Typography>
                    } 
                  />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                  <ListItemText 
                    primary={
                      <Typography variant="body1">
                        <strong>Right to non-discrimination</strong> for exercising your rights
                      </Typography>
                    } 
                  />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                  <ListItemText 
                    primary={
                      <Typography variant="body1">
                        <strong>Right to opt out</strong> of the processing of your personal data if it is used for targeted advertising (or sharing as defined under California's privacy law), the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")
                      </Typography>
                    } 
                  />
                  </ListItem>
                  </List>
                            
                  <Typography variant="body1" className={classes.paragraph}>
                  Depending upon the state where you live, you may also have the following rights:
                  </Typography>
                            
                  <List className={classes.list}>
                  <ListItem className={classes.listItem}>
                  <ListItemText primary="Right to access the categories of personal data being processed (as permitted by applicable law, including Minnesota's privacy law)" />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                  <ListItemText primary="Right to obtain a list of the categories of third parties to which we have disclosed personal data (as permitted by applicable law, including California's and Delaware's privacy law)" />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                  <ListItemText primary="Right to obtain a list of specific third parties to which we have disclosed personal data (as permitted by applicable law, including Minnesota's and Oregon's privacy law)" />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                  <ListItemText primary="Right to review, understand, question, and correct how personal data has been profiled (as permitted by applicable law, including Minnesota's privacy law)" />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                  <ListItemText primary="Right to limit use and disclosure of sensitive personal data (as permitted by applicable law, including California's privacy law)" />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                  <ListItemText primary="Right to opt out of the collection of sensitive data and personal data collected through the operation of a voice or facial recognition feature (as permitted by applicable law, including Florida's privacy law)" />
                  </ListItem>
                  </List>
                            
                  <Typography variant="h5" component="h3" className={classes.subtitle}>
                  How to Exercise Your Rights
                  </Typography>
                  <Typography variant="body1" className={classes.paragraph}>
                  To exercise these rights, you can contact us by submitting a <Link href="https://app.termly.io/notify/8d27bc09-64ba-4c8a-8f63-cd063d455664" className={classes.link} target="_blank" rel="noopener noreferrer">data subject access request</Link>, by emailing us at office@dflaz.com, or by referring to the contact details at the bottom of this document.
                  </Typography>
                            
                  <Typography variant="body1" className={classes.paragraph}>
                  Under certain US state data protection laws, you can designate an authorized agent to make a request on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with applicable laws.
                  </Typography>
                            
                  <Typography variant="h5" component="h3" className={classes.subtitle}>
                  Request Verification
                  </Typography>
                  <Typography variant="body1" className={classes.paragraph}>
                  Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. We will only use personal information provided in your request to verify your identity or authority to make the request. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes.
                  </Typography>
                            
                  <Typography variant="body1" className={classes.paragraph}>
                  If you submit the request through an authorized agent, we may need to collect additional information to verify your identity before processing your request and the agent will need to provide a written and signed permission from you to submit such request on your behalf.
                  </Typography>
                            
                  <Typography variant="h5" component="h3" className={classes.subtitle}>
                  Appeals
                  </Typography>
                  <Typography variant="body1" className={classes.paragraph}>
                  Under certain US state data protection laws, if we decline to take action regarding your request, you may appeal our decision by emailing us at office@dflaz.com. We will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal is denied, you may submit a complaint to your state attorney general.
                  </Typography>
                            
                  <Typography variant="h5" component="h3" className={classes.subtitle}>
                  California "Shine The Light" Law
                  </Typography>
                  <Typography variant="body1" className={classes.paragraph}>
                  California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us by using the contact details provided in the section "<Link href="#contact" className={classes.link}>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</Link>"
                  </Typography>
                  </Box>

                  <Divider />

                  <Box id="policyupdates" className={classes.section}>
                  <Typography variant="h4" component="h2" className={classes.title}>
                  11. DO WE MAKE UPDATES TO THIS NOTICE?
                  </Typography>
                            
                  <Typography variant="body1" className={classes.paragraph}>
                  <em><strong>In Short:</strong> Yes, we will update this notice as necessary to stay compliant with relevant laws.</em>
                  </Typography>
                            
                  <Typography variant="body1" className={classes.paragraph}>
                  We may update this Privacy Notice from time to time. The updated version will be indicated by an updated "Revised" date at the top of this Privacy Notice. If we make material changes to this Privacy Notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this Privacy Notice frequently to be informed of how we are protecting your information.
                  </Typography>
                  </Box>

                  <Divider />

                  <Box id="contact" className={classes.section}>
                  <Typography variant="h4" component="h2" className={classes.title}>
                  12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                  </Typography>
                            
                  <Typography variant="body1" className={classes.paragraph}>
                  If you have questions or comments about this notice, you may email us at office@dflaz.com or contact us by post at:
                  </Typography>
                            
                  <Box className={classes.address}>
                  <Typography variant="body1" component="div">
                  Mariani Enterprises LLC<br />
                  36815 N Cave Creed Rd<br />
                  Cave Creek, AZ 85331<br />
                  United States
                  </Typography>
                  </Box>
                  </Box>

                  <Divider />

                  <Box id="request" className={classes.section}>
                  <Typography variant="h4" component="h2" className={classes.title}>
                  13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                  </Typography>
                            
                  <Typography variant="body1" className={classes.paragraph}>
                  Based on the applicable laws of your country or state of residence in the US, you may have the right to request access to the personal information we collect from you, details about how we have processed it, correct inaccuracies, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. To request to review, update, or delete your personal information, please fill out and submit a <Link href="https://app.termly.io/notify/8d27bc09-64ba-4c8a-8f63-cd063d455664" className={classes.link} target="_blank" rel="noopener noreferrer">data subject access request</Link>.
                  </Typography>
                  </Box>
                  </Box>
                        
                  <Box className={classes.footer}>
                  <Divider />
                  <Box mt={2}>
                  <Typography variant="body2" color="textSecondary">
                  &copy; {new Date().getFullYear()} Mariani Enterprises LLC (dba HMI of AZ). All rights reserved.
                  </Typography>
                  </Box>
                  </Box>
                  </Container>
  )}

  export default PrivacyPolicy
  