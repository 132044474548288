import React, { useState, useEffect } from 'react';
import { 
  makeStyles,
  Paper,
  Grid,
  Button,
  ButtonGroup,
  Typography,
  Card,
  CardContent,
  IconButton,
  Box,
  Collapse,
  CardMedia,
  Dialog,
  DialogContent,
  Chip,
  Divider,
  Tooltip
} from '@material-ui/core';
import { 
  PhotoCamera, 
  ChevronRight,
  ExpandMore as ExpandMoreIcon,
  ZoomIn as ZoomInIcon,
  Person as PersonIcon,
  Email as EmailIcon,
  LocalShipping as TruckIcon,
  AccessTime as TimeIcon
} from '@material-ui/icons';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  metadataSection: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
  },
  metadataGrid: {
    marginBottom: theme.spacing(2),
  },
  infoItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    '& svg': {
      marginRight: theme.spacing(1),
      color: theme.palette.primary.main,
    },
  },
  statusChip: {
    margin: theme.spacing(1),
  },
  viewControls: {
    marginBottom: theme.spacing(2),
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    height: 300,
    backgroundColor: theme.palette.grey[100],
    marginBottom: theme.spacing(2),
  },
  vehicleImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  marker: {
    position: 'absolute',
    width: 24,
    height: 24,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transform: 'translate(-50%, -50%)',
    border: `2px solid ${theme.palette.common.white}`,
    transition: theme.transitions.create(['background-color', 'transform'], {
      duration: theme.transitions.duration.shortest,
    }),
  },
  markerDefault: {
    backgroundColor: theme.palette.primary.main,
  },
  markerSelected: {
    backgroundColor: theme.palette.error.main,
    transform: 'translate(-50%, -50%) scale(1.2)',
  },
  markerNumber: {
    color: theme.palette.common.white,
    fontSize: 12,
    fontWeight: 'bold',
  },
  damageCard: {
    marginBottom: theme.spacing(1),
    cursor: 'pointer',
    transition: theme.transitions.create(['border', 'background-color'], {
      duration: theme.transitions.duration.shortest,
    }),
  },
  selectedCard: {
    borderColor: theme.palette.primary.main,
    borderWidth: 2,
    backgroundColor: theme.palette.primary.light + '20',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  photoCount: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary,
    '& > svg': {
      marginRight: theme.spacing(0.5),
    },
  },
  selectedMarkerSection: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius,
  },
  photoGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  photoThumbnail: {
    position: 'relative',
    height: 120,
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    cursor: 'pointer',
    '&:hover': {
      '& $zoomIcon': {
        opacity: 1,
      },
    },
  },
  zoomIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: 0,
    transition: theme.transitions.create('opacity'),
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '50%',
    padding: theme.spacing(1),
    color: theme.palette.common.white,
  },
  dialogImage: {
    maxWidth: '100%',
    maxHeight: '90vh',
  },
  viewControls: {
    marginBottom: theme.spacing(2),
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    height: 300,
    backgroundColor: theme.palette.grey[100],
    marginBottom: theme.spacing(2),
  },
  vehicleImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  marker: {
    position: 'absolute',
    width: 24,
    height: 24,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transform: 'translate(-50%, -50%)',
    border: `2px solid ${theme.palette.common.white}`,
    transition: theme.transitions.create(['background-color', 'transform'], {
      duration: theme.transitions.duration.shortest,
    }),
  },
  markerDefault: {
    backgroundColor: theme.palette.primary.main,
  },
  markerSelected: {
    backgroundColor: theme.palette.error.main,
    transform: 'translate(-50%, -50%) scale(1.2)',
  },
  markerNumber: {
    color: theme.palette.common.white,
    fontSize: 12,
    fontWeight: 'bold',
  },
  damageCard: {
    marginBottom: theme.spacing(1),
    cursor: 'pointer',
    transition: theme.transitions.create(['border', 'background-color'], {
      duration: theme.transitions.duration.shortest,
    }),
  },
  selectedCard: {
    borderColor: theme.palette.primary.main,
    borderWidth: 2,
    backgroundColor: theme.palette.primary.light + '20',
  },
  reportInfo: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
    '& > *': {
      marginRight: theme.spacing(2),
    },
    '& svg': {
      marginRight: theme.spacing(0.5),
    },
  },
  photoGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  photoThumbnail: {
    position: 'relative',
    height: 120,
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    cursor: 'pointer',
    '&:hover': {
      '& $zoomIcon': {
        opacity: 1,
      },
    },
  },
  zoomIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: 0,
    transition: theme.transitions.create('opacity'),
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '50%',
    padding: theme.spacing(1),
    color: theme.palette.common.white,
  },
  dialogImage: {
    maxWidth: '100%',
    maxHeight: '90vh',
  },
}));

const originalDimensions = { width: 730, height: 236 };

const ORIGINAL_IMAGE = {
  width: 730,
  height: 236
};

const vehicleImages = {
  front: `${process.env.PUBLIC_URL}/truckFront.png`,
  back: `${process.env.PUBLIC_URL}/truckBack.png`,
  left: `${process.env.PUBLIC_URL}/truckLeft.png`,
  right: `${process.env.PUBLIC_URL}/truckRight.png`
};

const VehicleDamageViewer = ({ equipmentId, url }) => {
  const classes = useStyles();
  const [selectedView, setSelectedView] = useState('front');
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [damageReports, setDamageReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const damageReport = damageReports[0] || null

  useEffect(() => {
    let isMounted = true;

    const fetchDamageData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${url}/api/equipment/getDamages?equipmentId=${equipmentId}`);
        if (isMounted && response.data?.reports) {
          setDamageReports(response.data.reports);
        }
      } catch (error) {
        console.error('Error fetching damage data:', error);
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    if (equipmentId) {
      fetchDamageData();
    }

    return () => {
      isMounted = false;
    };
  }, [equipmentId, url]);

  const calculateScaledPosition = (x, y) => {
    // Get container dimensions
    const imageContainer = document.querySelector(`.${classes.imageContainer}`);
    if (!imageContainer) return { x: 0, y: 0 };
    
    const containerWidth = imageContainer.clientWidth;
    const containerHeight = imageContainer.clientHeight;
  
    // Calculate scale ratios based on original image dimensions
    const widthRatio = containerWidth / ORIGINAL_IMAGE.width;
    const heightRatio = containerHeight / ORIGINAL_IMAGE.height;
  
    return {
      x: x * widthRatio,
      y: y * heightRatio
    };
  };

  const currentViewMarkers = damageReports.reduce((acc, report) => {
    const markers = report.markers
      .filter(marker => marker.view === selectedView)
      .map(marker => ({
        ...marker,
        reportId: report._id,
        reportedBy: report.reported_by_name,
        reportDate: report.created_at,
      }));
    return [...acc, ...markers];
  }, []);

  const handleViewChange = (view) => {
    setSelectedView(view);
    setSelectedMarker(null);
  };

  const handleMarkerClick = (marker) => {
    setSelectedMarker(selectedMarker?.id === marker.id ? null : marker);
  };

  const handlePhotoClick = (photo) => {
    setSelectedPhoto(photo);
  };

  if (isLoading) {
    return (
      <Paper className={classes.root}>
        <Typography>Loading damage reports...</Typography>
      </Paper>
    );
  }

  if (isLoading) {
    return (
      <Paper className={classes.root}>
        <Typography>Loading damage report...</Typography>
      </Paper>
    );
  }

  if (!damageReports) {
    return (
      <Paper className={classes.root}>
        <Typography>No damage report found.</Typography>
      </Paper>
    );
  }

  return (
    <Paper className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ButtonGroup 
            variant="contained" 
            color="primary" 
            className={classes.viewControls}
            size="small"
          >
            {Object.keys(vehicleImages).map((view) => (
              <Button
                key={view}
                onClick={() => handleViewChange(view)}
                color={selectedView === view ? 'primary' : 'default'}
                variant={selectedView === view ? 'contained' : 'outlined'}
              >
                {view.charAt(0).toUpperCase() + view.slice(1)}
              </Button>
            ))}
          </ButtonGroup>
        </Grid>

        <Grid item xs={12}>
          <Box className={classes.imageContainer}>
            <img
              src={vehicleImages[selectedView]}
              alt={`Vehicle ${selectedView} view`}
              className={classes.vehicleImage}
            />
            
            {currentViewMarkers.map((marker) => {
              const report = damageReports.find(r => r._id === marker.reportId);
              const { x, y } = calculateScaledPosition(marker.x, marker.y, report.containerDimensions);
              
              return (
                <Tooltip 
                  key={marker.id}
                  title={`Reported by ${marker.reportedBy} on ${new Date(marker.reportDate).toLocaleDateString()}`}
                >
                  <div
                    className={`${classes.marker} ${
                      selectedMarker?.id === marker.id 
                        ? classes.markerSelected 
                        : classes.markerDefault
                    }`}
                    style={{ left: x, top: y }}
                    onClick={() => handleMarkerClick(marker)}
                  >
                    <span className={classes.markerNumber}>{marker.number}</span>
                  </div>
                </Tooltip>
              );
            })}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Damage Points - {selectedView.charAt(0).toUpperCase() + selectedView.slice(1)} View
          </Typography>
          {currentViewMarkers.map((marker) => (
            <Card
              key={marker.id}
              className={`${classes.damageCard} ${
                selectedMarker?.id === marker.id ? classes.selectedCard : ''
              }`}
              onClick={() => handleMarkerClick(marker)}
              variant="outlined"
            >
              <CardContent>
                <div className={classes.reportInfo}>
                  <span>
                    <PersonIcon fontSize="small" />
                    {marker.reportedBy}
                  </span>
                  <span>
                    <TimeIcon fontSize="small" />
                    {new Date(marker.reportDate).toLocaleDateString()}
                  </span>
                  {marker.photos?.length > 0 && (
                    <span>
                      <PhotoCamera fontSize="small" />
                      {marker.photos.length}
                    </span>
                  )}
                </div>
                <Typography variant="subtitle1">
                  Point {marker.number}: {marker.details}
                </Typography>
                
                {selectedMarker?.id === marker.id && marker.photos?.length > 0 && (
                  <div className={classes.photoGrid}>
                    {marker.photos.map((photo, index) => (
                      <div 
                        key={index}
                        className={classes.photoThumbnail}
                        onClick={() => handlePhotoClick(photo)}
                      >
                        <img
                          src={photo.path || photo.uri}
                          alt={`Damage ${index + 1}`}
                          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        />
                        <ZoomInIcon className={classes.zoomIcon} />
                      </div>
                    ))}
                  </div>
                )}
              </CardContent>
            </Card>
          ))}
        </Grid>
      </Grid>

      <Dialog
        open={Boolean(selectedPhoto)}
        onClose={() => setSelectedPhoto(null)}
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          {selectedPhoto && (
            <img
              src={selectedPhoto.path || selectedPhoto.uri}
              alt="Damage detail"
              className={classes.dialogImage}
            />
          )}
        </DialogContent>
      </Dialog>
    </Paper>
  );
};

export default VehicleDamageViewer;
