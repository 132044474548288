import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Chip,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  IconButton,
  Tooltip,
  Divider,
  Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Today as TodayIcon, 
  NavigateNext as NavigateNextIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  Spreads
} from '@material-ui/icons';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
  },
  tableContainer: {
    maxHeight: 640,
    marginTop: theme.spacing(2),
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  filterContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    margin: theme.spacing(2),
  },
  headerContainer: {
    // display: 'flex',
    // justifyContent: 'space-between',
    // flexDirection: 'row',
    // alignContent: 'center',
  },
  headerItem: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignContent: 'center',
    // margin: 5
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center'
  },
  searchItem: {
    width: '50%',
    verticalAlign: 'center',
    backgroundColor: 'red'
  },
  invoiceCountArea: {
    width: '50%',
    // backgroundColor: 'blue',
    verticalAlign: 'center',
    marginLeft: 5,
    fontWeight: 500,
    fontSize: '0.9em'
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  agingBucketChip: {
    '&.age-0-30': { backgroundColor: '#4caf50', color: 'white' },
    '&.age-31-60': { backgroundColor: '#ff9800', color: 'white' },
    '&.age-61-90': { backgroundColor: '#f44336', color: 'white' },
    '&.age-90-plus': { backgroundColor: '#9c27b0', color: 'white' },
  },
  snapshotListTable: {
    '& .MuiTableCell-root': {
      padding: theme.spacing(1),
    },
  },
  selectedRow: {
    backgroundColor: theme.palette.action.selected,
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    },
  },
  clickableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  totalAmount: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  agingColumns: {
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
  listContainer: {
    maxHeight: 300,
    overflow: 'auto',
    marginBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(3, 0),
  },
}));

const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount);
};

const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

const SnapshotCard = ({ snapshot, isSelected, onClick }) => {
  const classes = useStyles();
  const totalAR = snapshot.agingBuckets.oneToThirty + 
                  snapshot.agingBuckets.thirtyToSixty + 
                  snapshot.agingBuckets.sixtyToNinety + 
                  snapshot.agingBuckets.overNinety;

  return (
    <Card 
      className={`${classes.snapshotCard} ${isSelected ? classes.selectedSnapshot : ''}`}
      onClick={onClick}
    >
      <CardContent className={classes.snapshotCardContent}>
        <Box>
          <Typography variant="h6">
            {snapshot.entityName}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {formatDate(snapshot.snapshotDate)}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography variant="h6" className={classes.totalAmount}>
            {formatCurrency(totalAR)}
          </Typography>
          <IconButton size="small">
            <NavigateNextIcon />
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  );
};

const SnapshotList = ({ snapshots, selectedSnapshot, onSelect }) => {
  const classes = useStyles();

  const calculateTotal = (snapshot) => {
    return snapshot.agingBuckets.oneToThirty +
           snapshot.agingBuckets.thirtyToSixty +
           snapshot.agingBuckets.sixtyToNinety +
           snapshot.agingBuckets.overNinety;
  };

  return (
    <Paper className={classes.listContainer}>
      <TableContainer>
        <Table className={classes.snapshotListTable} size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Entity</TableCell>
              <TableCell align="right">0-30 Days</TableCell>
              <TableCell align="right">31-60 Days</TableCell>
              <TableCell align="right">61-90 Days</TableCell>
              <TableCell align="right">90+ Days</TableCell>
              <TableCell align="right">Total AR</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {snapshots.map((snapshot) => (
              <TableRow 
                key={snapshot.snapshotDate}
                className={`${classes.clickableRow} ${
                  selectedSnapshot?.snapshotDate === snapshot.snapshotDate ? classes.selectedRow : ''
                }`}
                onClick={() => onSelect(snapshot)}
              >
                <TableCell>{formatDate(snapshot.snapshotDate)}</TableCell>
                <TableCell>{snapshot.entityName}</TableCell>
                <TableCell align="right">{formatCurrency(snapshot.agingBuckets.oneToThirty)}</TableCell>
                <TableCell align="right">{formatCurrency(snapshot.agingBuckets.thirtyToSixty)}</TableCell>
                <TableCell align="right">{formatCurrency(snapshot.agingBuckets.sixtyToNinety)}</TableCell>
                <TableCell align="right">{formatCurrency(snapshot.agingBuckets.overNinety)}</TableCell>
                <TableCell align="right" className={classes.totalAmount}>
                  {formatCurrency(calculateTotal(snapshot))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

const ARSnapshotDisplay = ({ snapshots, url, notification }) => {
  const classes = useStyles();
  const [selectedSnapshot, setSelectedSnapshot] = useState(null);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [agingFilter, setAgingFilter] = useState('all');
  const [sortConfig, setSortConfig] = useState({
    key: 'dueDate',
    direction: 'desc',
  });
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let result = [...selectedSnapshot?.unpaidInvoices || []];

    // Apply search filter
    if (searchTerm) {
      result = result.filter((invoice) =>
        invoice.customerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        invoice.invoiceNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
        invoice.jobName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Apply aging bucket filter
    if (agingFilter !== 'all') {
      result = result.filter((invoice) => invoice.agingBucket === agingFilter);
    }

    // Apply sorting
    result.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });

    setFilteredInvoices(result);
  }, [selectedSnapshot?.unpaidInvoices, searchTerm, agingFilter, sortConfig]);

  const handleSort = (key) => {
    setSortConfig({
      key,
      direction:
        sortConfig.key === key && sortConfig.direction === 'asc'
          ? 'desc'
          : 'asc',
    });
  };

  const getAgingBucketClass = (bucket) => {
    switch (bucket) {
      case '01-30':
        return 'age-0-30';
      case '31-60':
        return 'age-31-60';
      case '61-90':
        return 'age-61-90';
      case '90+':
        return 'age-90-plus';
      default:
        return '';
    }
  };

  const downloadInvoices = async () => {
    console.log('Download the active invoices...', filteredInvoices)
    setLoading(true)
    const obj = {
      entity: selectedSnapshot.entityName,
      invoices: filteredInvoices
    }
    try {
      const response = await axios.post(`${url}/api/invoice/exportInvoices`, obj, {
        responseType: 'blob', // Ensure the response is treated as a file blob
      });
      console.log('got our shit!!')
      if (response && response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Invoice_Details.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    
        notification({
          type: 'success',
          title: 'Report Generated',
          message: 'Spreadsheet has been downloaded',
        });
    
        setLoading(false);
      }
    } catch (e) {
      console.log('Error getting invoice spreadsheet', e);
    }
  }

  console.log('selectedSnapshot', selectedSnapshot)
  return (
    <>
    <Box className={classes.root}>
      <Typography variant="h4" gutterBottom>
        AR Snapshots
      </Typography>
     
    </Box>
    <SnapshotList 
        snapshots={snapshots} 
        selectedSnapshot={selectedSnapshot}
        onSelect={setSelectedSnapshot} 
      />
    {selectedSnapshot?._id && (
      <Paper className={classes.root}>
        <Box className={classes.header}>
          <Typography variant="h5" gutterBottom>
            AR Snapshot - {formatDate(selectedSnapshot.snapshotDate)}
          </Typography>
          <Typography variant="subtitle1">
            Entity: {selectedSnapshot.entityName}
          </Typography>
          <Box mt={2}>
            <Typography variant="h6">Aging Summary</Typography>
            <Box display="flex" gap={1} mt={1}>
              <Chip
                label={`0-30: ${formatCurrency(selectedSnapshot.agingBuckets.oneToThirty)}`}
                className={`${classes.chip} ${classes.agingBucketChip} age-0-30`}
              />
              <Chip
                label={`31-60: ${formatCurrency(selectedSnapshot.agingBuckets.thirtyToSixty)}`}
                className={`${classes.chip} ${classes.agingBucketChip} age-31-60`}
              />
              <Chip
                label={`61-90: ${formatCurrency(selectedSnapshot.agingBuckets.sixtyToNinety)}`}
                className={`${classes.chip} ${classes.agingBucketChip} age-61-90`}
              />
              <Chip
                label={`90+: ${formatCurrency(selectedSnapshot.agingBuckets.overNinety)}`}
                className={`${classes.chip} ${classes.agingBucketChip} age-90-plus`}
              />
            </Box>
          </Box>
          <Box className={classes.filterContainer}>
            <Grid container className={classes.headerContainer}>
              <Grid item lg={6} sm={12} xs={12} md={6} className={classes.searchContainer}>
                {/* <div className={classes.searchContainer}> */}
                  
                    <TextField
                      label="Search"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />

                  <div className={classes.invoiceCountArea}>
                    {filteredInvoices?.length} Invoices
                  </div>
                {/* </div> */}
                
                
           
              </Grid>
              <Grid item lg={4} sm={12} xs={12} md={4} className={classes.headerItem}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <InputLabel>Aging Bucket</InputLabel>
                  <Select
                    value={agingFilter}
                    fullWidth
                    onChange={(e) => setAgingFilter(e.target.value)}
                    label="Aging Bucket"
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="01-30">0-30 Days</MenuItem>
                    <MenuItem value="31-60">31-60 Days</MenuItem>
                    <MenuItem value="61-90">61-90 Days</MenuItem>
                    <MenuItem value="90+">90+ Days</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={2} sm={12} xs={12} md={2} className={classes.headerItem}>
                <Tooltip
                  title="Download to Spreadsheet">
                     <IconButton
                      onClick={downloadInvoices}
                     ><CloudDownloadIcon color={'#516e76'} size="24" /></IconButton>

                </Tooltip>
               
              </Grid>
            </Grid>
           
          </Box>

          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell onClick={() => handleSort('invoiceNumber')}>
                    Invoice #
                  </TableCell>
                  <TableCell onClick={() => handleSort('customerName')}>
                    Customer
                  </TableCell>
                  <TableCell onClick={() => handleSort('jobName')}>
                    Job Name
                  </TableCell>
                  <TableCell onClick={() => handleSort('created_at')}>
                    Created Date
                  </TableCell>
                  <TableCell onClick={() => handleSort('dueDate')}>
                    Due Date
                  </TableCell>
                  <TableCell onClick={() => handleSort('amount')}>
                    Amount
                  </TableCell>
                  <TableCell onClick={() => handleSort('balance')}>
                    Balance
                  </TableCell>
                  <TableCell onClick={() => handleSort('agingBucket')}>
                    Aging
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredInvoices.map((invoice) => (
                  <TableRow key={invoice.invoiceId}>
                    <TableCell>{invoice.invoiceNumber}</TableCell>
                    <TableCell>{invoice.customerName}</TableCell>
                    <TableCell>{invoice.jobName}</TableCell>
                    <TableCell>{formatDate(invoice.created_at)}</TableCell>
                    <TableCell>{formatDate(invoice.dueDate)}</TableCell>
                    <TableCell>{formatCurrency(invoice.amount)}</TableCell>
                    <TableCell>{formatCurrency(invoice.balance)}</TableCell>
                    <TableCell>
                      <Chip
                        label={invoice.agingBucket}
                        className={`${classes.agingBucketChip} ${getAgingBucketClass(invoice.agingBucket)}`}
                        size="small"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    )}
    </>
  );
};

export default ARSnapshotDisplay;