import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
// import AddCustomerPaymentMethods from './AddCustomerPaymentMethods'
// import AddCustomerEcheck from './AddCustomerEcheck'
// import ServiceDetailList from './ServiceDetailList'
import WorkOrderPrintModal from './WorkOrderPrintModal'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PrintIcon from '@material-ui/icons/Print';
import ViewInvoiceModal from './ViewInvoiceModal'
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import SupplyRequestIcon from '@material-ui/icons/AddShoppingCart';
import TaxDetails from './TaxDetails'
import MoneyIcon from '@material-ui/icons/AttachMoney';
import ProtectedButton from '../../actions/protectedButtons';
import PunchlistModal from './PunchListModal'
import WorkorderPhotos from '../Workorders/WorkorderPhotos'
import CustomerIssues from '../CustomerIssues/CustomerIssueComponent'
import WorkorderNotes from '../Workorders/WorkorderNotes'
import JobChat from '../Workorders/JobChat'
import ClientGeneratedWorkorderRequired from '../Workorders/ClientGeneratedWorkorderRequiredInput'
import WorkorderChecklist from '../MtcRoutes/WorkorderChecklist'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import PunchlistComponent from './PunchListModal'
import LineItemModal from '../Proposals/LineItemBuilder';

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  gridItemRow: {
    padding: '5px',
    display: 'flex',
    flexDirection: 'row'
  },
  textSaveLineBreaks: {
    whiteSpace: 'pre-line'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  }
}));

const ServiceVisitDetails = ({ visits, wo }) => {
  return null
  // (
  //   <div>
  //     <ul>
  //       {visits.map((item, index) => {
  //         return (
  //           <li key={index}>
  //             <Grid container spacing={2}>
  //               <Grid item lg={3}>
  //                 Created: {moment(item.created_at).format('MM/DD/YYYY [-] hh:mma')}
  //               </Grid>
  //               <Grid item lg={3}>
  //                 Crew: {(item.crew && item.crew.name) ? item.crew.name : ''}
  //               </Grid>
  //               <Grid item lg={3}>
  //                 Last Update: {moment(item.updated_at).format('MM/DD/YYYY [-] hh:mma')}
  //               </Grid>
                
  //             </Grid>
  //           </li>
  //         )
  //       })}
  //     </ul>
  //   </div>
  // )
}

const ServiceDetailComponent = (props) => {
  // console.log('Service Details: ', props)
  const classes = useStyles();
  const [pdf, setPdf] = React.useState({})
  const [printWOModalOpen, setPrintWOModalOpen] = React.useState(false)
  const [showInvoiceModal, setShowInvoiceModal] = React.useState(false)
  const [showPunchListModal, setShowPunchListModal] = React.useState(false)
  const [state, setState] = React.useState({
    // service: {},
    workorder: {price: 0, actHours: 0, estHours: 0},
    serviceIndex: props.serviceIndex,
    crewList: [{name: ' '}],
    serviceList: [{name: ''}],
    requiresUpdate: false,
    name: props.workorder.name,
    crew: {name: ' '},
    notes: '',
    serviceType: {name: ' '},
    checked: false,
    isComplete: false,
    isInvoiced: false,
    showInvoiceModal: false,
    estHours: 0,
    actHours: 0,
    price: 0,
    serviceDescription: '',
    serviceCategory: '',
    workorderNotes: ''
  })
  const [loading, setLoading] = React.useState(false)
  const [showTaxModal, setShowTaxModal] = React.useState(false)
  const [serviceVisits, setServiceVisits] = React.useState([])
  const [messages, setMessages] = React.useState([])
  const [lineItems, setLineItems] = React.useState([])
  const [lineItemModalOpen, setLineItemModalOpen] = React.useState(false)
  const [takeOffList, setTakeOffList] = useState([]);

  const priceRef = React.useRef(null)

  React.useEffect(() => {
    fetchTakeOffs()
  }, [])

  const fetchTakeOffs = async () => {
    const response = await axios.get(`${props.url}/api/company/listTakeOffs`)
    if (response && response.data && response.data.length) {
      // console.log('Takeoffs', response.data)
      setTakeOffList(response.data)
    }
  }

  React.useEffect(() => {
    // console.log('Use Effect Service Detail', props)
    let isMounted = true

    const fetchServiceVisits = async (id) => {
      axios({
        method: 'get',
        url:  `${props.url}/api/jobs/getAssociatedServiceVisits?id=${id}`
      }).then(response => {
        // console.log('Got our Service Visits!!', response.data)
        if (response.data && response.data && isMounted) {
          if (response.data.visits && response.data.visits.length) {
            setServiceVisits(response.data.visits)
            setMessages(response.data.messages)
          }
          
        }
      })
    }
    let isComplete = false
    if (props.workorder && props.workorder.completedDate) {
      isComplete = true
    }
    let isInvoiced = false
    if (props.workorder && props.workorder.invoiceDate) {
      isInvoiced = true
    }
    let list = (props.serviceList && props.serviceList.length) || (props.services && props.services.length) ? props.services : []
    const serviceCode = props.workorder.serviceCode
    console.log('Find SErvice Num: ', serviceCode)
    let serviceIndex = list.findIndex(svcItem =>  {
      if (svcItem['name'] === props.workorder['serviceName']) {
        return svcItem
      }
      console.log('Service Item', svcItem)
      if (serviceCode?.toString() === svcItem?.serviceCode?.toString()) {
        return svcItem
      }
    });
    // console.log('Service Index: ', serviceIndex)
    let serviceType = list[serviceIndex]
    let ourCrew = null
    if (props.workorder && props.workorder.crew) {
      let index = props.crews.findIndex(crewItem =>  (crewItem['_id'].toString() === props.workorder['crew'].toString()));
      ourCrew = props.crews[index]
      // console.log('Our Crew: ', ourCrew)
    }
    // console.log('ServiceType', serviceType)
    if (isMounted) {
      if (props.workorder?.supplies?.length) setLineItems(props.workorder.supplies)
      setState({...state, 
        // service: props.workorder,
        workorder: props.workorder,
        serviceIndex: props.serviceIndex,
        crewList: props.crews,
        serviceList: props.serviceList,
        requiresUpdate: false,
        name: props.workorder.name,
        crew: ourCrew,
        serviceType: serviceType,
        notes: props.workorder.serviceNotes,
        checked: false,
        isComplete: isComplete,
        showInvoiceModal: false,
        isInvoiced: isInvoiced,
        estHours: props.workorder.estHours,
        actHours: props.workorder.actHours,
        price: props.workorder.price,
        workorderNotes: props.workorder.serviceNotes || ' ',
        serviceDescription: props.workorder.serviceDescription || ' ',
        serviceCategory: props.workorder.serviceCategory || ' '
      })
    }
    // console.log('Set Service Type: ', serviceType)


    if (props.workorder && props.workorder._id) fetchServiceVisits(props.workorder._id)

    return () => {
      isMounted = false
    }
  }, [props.workorder])

  const handleUpdateThisService = (typeOfChange) => {
    console.log('Update this service', state)
    if (typeOfChange === 'complete') {
      let newState = { ...state }
      let newWO = { ...state.workorder, complete: true, status: 'Complete', completedDate: new Date(), invoiceDate: new Date() }
      newState.workorder = newWO
      props.updateAService(newState, 'complete')
      setState({...newState, requiresUpdate: false, isComplete: true, checked: false})
     
    } else if (typeOfChange === 'noInvoice') {
    // console.log('Complete with NO INVOICE')
      let newState = { ...state }
      let newWO = { ...state.workorder, complete: true, status: 'Complete', completedDate: new Date(), completedWithoutInvoice: true, invoiceDate: new Date()  }
      newState.workorder = newWO
      props.updateAService(newState, 'noInvoice')
      setState({...newState, requiresUpdate: false, isComplete: true, checked: false})
    } else if (typeOfChange === 'duplicate') {
      // console.log('Complete with NO INVOICE')
        let newState = { ...state }
        let newWO = { ...state.workorder, price: 0, notes: `Marked as Duplicate by ${props?.user?.name}`, complete: true, status: 'Complete', completedDate: new Date(), completedWithoutInvoice: true, invoiceDate: new Date()  }
        newState.workorder = newWO
        props.updateAService(newState, 'save')
        setState({...newState, requiresUpdate: false, isComplete: true, checked: false})
      
    } else {
      props.updateAService(state, 'save', (wo) => {
        console.log('We got a workorder number for this new guy!!!', wo)
        if (wo && wo.data) {
          console.log('Ignore this thing, right???')
          setState({...state, isComplete: false, requiresUpdate: false})
        } else {
          let updatedService = wo
          console.log('update state', updatedService)
          // updatedService.number = num
          setState({...state, workorder: updatedService, isComplete: false, checked: false, requiresUpdate: false})
        }
      })
    }
    
  }

  const handleMarkComplete = () => {
    // console.log('Mark this service as complete')
    // let wo = state.workorder
    // wo.invoiceDate = new Date()
    // wo.invoiceNumber = 'Pending'
    // setState({...state, workorder: wo})
    setLoading(true)
    handleUpdateThisService('complete')
    // setTimeout(() => {
      setLoading(false)
    // }, 1000)
  }

  const handleMarkCompleteNoInvoice = () => {
    handleUpdateThisService('noInvoice')
  }

  const handleMarkAsDupe = () => {
    console.log('Workorders tatus', state.workorder)
    let wo = state.workorder
    if (!wo.invoiceDate && !wo.completedDate) {
      handleUpdateThisService('duplicate')
    } else {
      props.notification({
        type: 'warning',
        title: 'Cannot Mark as Duplicate',
        message: 'This Workorder Is Already Completed/Invoiced'
      })
    }
  }

  const CompletedDetails = (obj) => {
    let svc = obj.workorder
    // console.log('Completed Dets:', svc)
    if (svc && svc.completedDate && svc.invoiceDate) {
      let completeDate = moment(svc.completedDate).format('MM/DD/YYYY')
      let invoiceDate = moment(svc.invoiceDate).format('MM/DD/YYYY')
      // console.log('COmplete & Invoiced')
      return <span>Completed ({completeDate}) And Invoiced ({invoiceDate}{svc.invoiceNumber ? ` Inv #${svc.invoiceNumber}` : ''})</span>
    } else if (svc && svc.workComplete) {
      let completeDate = moment(svc.work_complete_at).format('MM/DD/YYYY')
      // console.log('COmplete')
      return <span>Work Completed ({completeDate})</span>
    } else if (svc && svc.completedDate) {
      // console.log('Invoiced')
      let completeDate = moment(svc.completedDate).format('MM/DD/YYYY')
      return <span>Completed ({completeDate})</span>
    } else if (svc && svc.invoiceDate) {
      // console.log('Invoiced')
      let invoiceDate = moment(svc.invoiceDate).format('MM/DD/YYYY')
      return <span>Invoiced ({invoiceDate})</span>
    } else {
      // console.log('NAda')
      return <span></span>
    }
    // {state.service.completeDate ? (
    //   'Completed on ' { moment(state.service.completeDate).format('MM/DD/YYYY')}
    // ) : ''}
    // &nbsp;&nbsp;&nbsp;&nbsp;
    // {state.service.invoiceDate ? 'Invoiced on ' moment(state.service.invoiceDate).format('MM/DD/YYYY') : ''}
  }

  const UpdateButton = (props) => {
    let wo = props.workorder
    let requiresWorkorder = props.requiresWorkorder
    const loading = props.loading
    // console.log('Update Button: ', props)
    let invoicingDisabled = false
    if (requiresWorkorder && !wo.clientApprovedWorkorderNumber) invoicingDisabled = true
    let isComplete = false
    if (wo && wo.completedDate) {
      isComplete = true
    }
    // console.log('Is Complete: ', isComplete)
     if (state.requiresUpdate) {
      return (
        <React.Fragment>
          <Grid item lg={4} xs={8} className={classes.gridItem}> 
          &nbsp;
            {/* { state.isComplete ? '' : 
            // <Button
            //   variant="contained"
            //   style={{backgroundColor: 'green', visible: false}}
            //   className={classes.button}
            //   size="small"
            //   startIcon={<CloudDoneIcon />}
            //   onClick={handleMarkComplete}
            // >
            //   Fix this for DUSTIN (if complete - INvoice - else Complete and Invoice)
            // </Button>
          } */}
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12} className={classes.gridItem}> 
          { (wo && wo.number !== 'TBD') ? (<Button
              variant="contained"
              style={{backgroundColor: 'green', visible: false}}
              className={classes.button}
              size="small"
              startIcon={<PrintIcon />}
              onClick={handlePrintWorkorder}
            >
              Print Workorder
            </Button>  ) : ''}
             
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12} className={classes.gridItem}> 
          { wo.completedDate ? <Button
              variant="contained"
              style={{backgroundColor: '#d2b48c', visible: false}}
              className={classes.button}
              size="small"
              startIcon={<PrintIcon />}
              onClick={handlePrintInvoice}
            >
              Print Invoice
            </Button>   
            : '' }
            </Grid>
    <Grid item lg={2} xs={4} className={classes.gridItem}> 
    <Button
      variant="contained"
      style={{backgroundColor: '#78c255', visible: false}}
      className={classes.button}
      size="small"
      // disabled={!state.service.date || !state.crew || !state.service.price}
      startIcon={<SaveAltIcon />}
      onClick={handleUpdateThisService}
    >
      Save
    </Button> 
    </Grid>
    </React.Fragment>
     )

     } else {
        // NO UPDATE REQUIRED
        // console.log('WO: ', wo)
        // console.log('Completed Date: ', wo.completedDate)
      return (
        <React.Fragment>
      <Grid item lg={4} xs={8} className={classes.gridItem}> 
        { wo.completedDate ? (
          (wo.invoiceDate || wo.completedWithoutInvoice) ? '' : (
            <Button
            variant="contained"
            style={{backgroundColor: 'green', visible: false}}
            className={classes.button}
            size="small"
            startIcon={<CloudDoneIcon />}
            onClick={handleMarkComplete}
            disabled={invoicingDisabled || loading}
          >
             {
                  loading ? <CircularProgress size={18} /> : 'Invoice'
              }
          </Button>
          )
        ) : (
          <React.Fragment>
             <Button
              variant="contained"
              style={{backgroundColor: 'green', visible: false, marginRight: 20}}
              className={classes.button}
              size="small"
              startIcon={<CloudDoneIcon />}
              onClick={handleMarkComplete}
              disabled={invoicingDisabled || loading}
            >
              {
                  loading ? <CircularProgress size={18} /> : 'Complete & Invoice'
              }
            
            </Button>
            <ProtectedButton type="Complete No Invoice" kind="view" perms={props.perms || []}>
              <Button
                variant="contained"
                style={{backgroundColor: '#d2B48c' }}
                className={classes.button}
                size="small"
                startIcon={<CloudDoneIcon />}
                onClick={handleMarkCompleteNoInvoice}
                disabled={invoicingDisabled || loading}
              >
                {
                  loading ? <CircularProgress size={18} /> : 'Complete (NO INVOICE)'
                }
               
              </Button>
            </ProtectedButton>
            
          </React.Fragment>
         
        ) 
       
     }
     </Grid>
     <Grid item lg={3} xs={6} className={classes.gridItem}> 
     { (wo && wo.number !== 'TBD') ? (
       <Button
       variant="contained"
       style={{backgroundColor: 'green', visible: false}}
       className={classes.button}
       size="small"
       startIcon={<PrintIcon />}
       onClick={handlePrintWorkorder}
     >
       Print Workorder
     </Button>  
     ) : ''}
        
     </Grid>
        {/* <Grid item lg={3} xs={6} className={classes.gridItem}> 
          { wo.completedDate ? <Button
            variant="contained"
            style={{backgroundColor: '#d2b48c', visible: false}}
            className={classes.button}
            size="small"
            startIcon={<PrintIcon />}
            onClick={handlePrintInvoice}
          >
            Print Invoice
          </Button>   
          : '' }
        </Grid> */}
    {/* <Grid item lg={10} xs={8} className={classes.gridItem}> 
        Mark As Complete
    </Grid> */}
    <Grid item lg={2} xs={4} className={classes.gridItem}> 
      &nbsp;
    </Grid>
        </React.Fragment>
     )
     }
  }

const handlePrintWorkorder = () => {
  // console.log('Print it!!', state)
  axios({
    method: 'post',
    url:  `${props.url}/api/jobs/printWorkOrder`,
    data: {
      workorder: state.workorder,
      job: props.job,
      client: props.client
    }
  }).then(response => {
    // console.log('Axios response from printing: ', response)
    // let ourPDF = encodeURIComponent(response.data)
    // // console.log(response.data)
    if (response && response.data) {
      setPdf(response.data)
      setPrintWOModalOpen(true)
    }
  })
}

const handlePrintInvoice = () => {
  // console.log('Print invoice now')
  axios({
    method: 'post',
    url:  `${props.url}/api/invoice/printInvoice`,
    data: { workorder: state.workorder, client: props.client, job: props.job }
  }).then(response => {
    // console.log('Axios response from printing Invoice: ', response)
    // let ourPDF = encodeURIComponent(response.data)
    // // console.log(response.data)
    setPdf(response.data)
    // setPrintWOModalOpen(true)
    setShowInvoiceModal(true)
  })
}

const handleServiceChange = (service, kind, value, index) => {
// console.log('Service Change:', service)
// console.log('Kind: ', kind)
// console.log('Value: ', value)
// console.log('Index: ', index)
  let newService = state.workorder
  newService[kind] = value
  // // console.log('Service to Update: ', serviceToUpdate)
  newService['requiresUpdate'] = true
  setState({...state, workorder: newService, requiresUpdate: true})
  // state.jobDetails.map(item => {
  //   if (item.number === service.number) {
  //     // console.log('This is the one')

  //   }
  // })
}

const updateCrew = (event, newCrew) => {
  // console.log('Update crew: ', crew)
  // console.log('newCrew Crew: ', newCrew)
  // console.log('Event: ', event)
  // console.log(event.target.id)
  // let split = event.target.id.split('-')
  // let crewNum = split[3]
  // let optIndex = split[1]
  // console.log('Crew Numb: ', crewNum)
  // console.log('Opt Index: ', optIndex)
  // let newCrew = props.crews[crewNum]
  // console.log('nEW Crew:', newCrew)
  if (newCrew && newCrew.agValue) {
    let newWO = state.workorder
    newWO.crewName = newCrew['name']
    newWO.crewNumber = newCrew['agValue']
    newWO.crew = newCrew
    setState({...state, workorder: newWO, crew: newCrew, requiresUpdate: true})
  } else {
    // setState({...state, crew: {name: ' '}})
  }
  // // console.log('E: ', e)
  // // console.log('Update the crew on this serice: ', service)
  // // console.log('To this: ', value)
  // // console.log('Index: ', index)
  // let newJobDetails = state.jobDetails
  // let ourIndex = state.jobDetails.map(function (e) { 
  //   // console.log('WHHH?? ', e)
  //   return e.number
  // }).indexOf(service.number)
  // // console.log('Our Service: ', newJobDetails[ourIndex])
  // let serviceToUpdate = newJobDetails[ourIndex]
  // serviceToUpdate[kind] = value
  // // console.log('Service to Update: ', serviceToUpdate)
  // setState({...state, jobDetails: newJobDetails})

}
const updateServiceType = (e, svc) => {
  // console.log('Update updateServiceType: ', svc)
  // // console.log('Crew: ', crew)
  // Create DEscription
  if (svc && svc._id) {
    let newService = state.workorder
    newService.servicetype = svc
    newService.serviceDescription = svc.description
    newService.serviceCategory = svc.categoryName
    setState({...state, workorder: newService, serviceDescription: svc.description, serviceCategory: svc.categoryName, serviceType: svc, name: svc.name, requiresUpdate: true})
  } else {
    // console.log('Nothing')
    // setState({...state, serviceType: {name: ' '}})
  }
}

const handleDeleteService = () => {
  // console.log('Gotta delete this service: ', state)
  props.handleDelete(state.workorder, state.serviceIndex)
}

const handleChange = () => {
  // console.log('Check the box bro')
  if (state.checked) {
    props.updateAService(state.service, 'unselect')
  } else {
    props.updateAService(state.service, 'select')
  }
  setState({...state, checked: !state.checked})
}

const offerDeleteOption = () => {
  if (window.confirm('Are you sure you wish to delete this item?')) handleDeleteService()
}

const responseFromWOPrint = (d) => {
  // console.log('Response from print WO', d)
  setPrintWOModalOpen(false)
}
const approveInvoice = () => {
    // setLoading(true)
    let updateObject = {
      client: props.client,
      user: props.user,
      // pdf: pdf,
      workorder: state.workorder
    }
    // console.log('Update Object: ', updateObject)
    axios({
      method: 'post',
      url:  `${props.url}/api/invoice/approveInvoice`,
      data: updateObject,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
      // setLoading(false)
      // console.log('Approve INVOICE RSPONSE: ', response)
      props.notification({
        type: 'success',
        title: 'Invoice Approved',
        message: 'Done!'
      })
    })
}
const updateFromInvoiceModal = (d) => {
  // console.log('Update from modal', d)
  setShowInvoiceModal(false)
}

const crewNameAndForeman = (crew) => {
  // console.log('Crew Name: ', crew)
  if (crew) {
    if (crew && crew.foremanName) {
      return crew.name + ' (' + crew.foremanName + ')'
    } else {
      return crew.name
    }
  } else {
    return ''
  }
}

const handleSupplyRequest = () => {
  // console.log('Supply Request')
    axios({
      method: 'post',
      url:  `${props.url}/api/jobs/exportToCSV`,
      data: state.workorder,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
      // console.log('Export response: ', response.data)
      let newBlob = new Blob([response.data], {type: "text/csv"})
      // console.log(newBlob)
      const data = window.URL.createObjectURL(newBlob);
      // console.log(data)
      var link = document.createElement('a');
      link.href = data;
      let fileName = 'workorderSuppliesList' // state.workorder..replace(/[^a-z0-9]/gi, '_').toLowerCase()
      link.download=`${fileName}.csv`;
      link.click();
    })
  }


const handleEditTaxes = () => {
  // Show Tax Modal
  setShowTaxModal(true)
}

const updateFromTaxes = (d) => {
  if (d && d._id) {
    setState({...state, workorder: d})
    setShowTaxModal(false)
  }
}

const handleTabInteractionForCrew = (e) => {
  if (e.keyCode == 9) {
    // e.preventDefault()
    // console.log(e.target.value)
    let list = state.crewList
    // let index = list.findIndex(item => item.serviceCode.toString() === e.target.value)
    // if (index > -1) {
    //   // console.log(list[index])
    //   let svc = list[index]
    //   if (svc && svc._id) {
    //     let newService = state.workorder
    //     newService.servicetype = svc
    //     newService.serviceDescription = svc.description
    //     newService.serviceCategory = svc.categoryName
    //     setState({...state, workorder: newService, serviceDescription: svc.description, serviceCategory: svc.categoryName, serviceType: svc, name: svc.name, requiresUpdate: true})
    //     // console.log('Price Ref: ', priceRef)
    //     // priceRef.current.focus()
    //   } else {
    //     // console.log('Nothing')
    //     // setState({...state, serviceType: {name: ' '}})
    //   }
    // } else {
      let newArr = []
      let string = e.target.value
      list.filter(o => {
        let str = crewNameAndForeman(o).toString().toLowerCase()
        if (str.includes(string.toLowerCase())) {
          return newArr.push(o)
        } else {
          return null
        }
        // Object.keys(o).some(k => {
        //   if(o[k]) {
        //     let str = o[k].toString().toLowerCase()
        //     if (str.includes(string.toLowerCase())) {
        //       return newArr.push(o)
        //     } else {
        //       return null
        //     }
        //   } else {
        //     return null
        //   }
        // })
      })
      // console.log(newArr)
      if (newArr && newArr.length) {
        let newWO = state.workorder
        let newCrew = newArr[0]
        newWO.crewName = newCrew['name']
        newWO.crewNumber = newCrew['agValue']
        newWO.crew = newCrew
        setState({...state, workorder: newWO, crew: newCrew, requiresUpdate: true})
      }
    // }
  }
}
const handleTabInteraction = (e) => {

  if (e.keyCode == 9) {
    // e.preventDefault()
    // console.log(e.target.value)
    let list = (props.serviceList && props.serviceList.length) || (props.services && props.services.length) ? props.services : []
    let index = list.findIndex(item => item.serviceCode.toString() === e.target.value)
    if (index > -1) {
      // console.log(list[index])
      let svc = list[index]
      if (svc && svc._id) {
        let newService = state.workorder
        newService.servicetype = svc
        newService.serviceDescription = svc.description
        newService.serviceCategory = svc.categoryName
        setState({...state, workorder: newService, serviceDescription: svc.description, serviceCategory: svc.categoryName, serviceType: svc, name: svc.name, requiresUpdate: true})
        // console.log('Price Ref: ', priceRef)
        // priceRef.current.focus()
      } else {
        // console.log('Nothing')
        // setState({...state, serviceType: {name: ' '}})
      }
    } else {
      let newArr = []
      let string = e.target.value
      list.filter(o => {
        let str = o['name'].toString().toLowerCase()
        if (str.includes(string.toLowerCase())) {
          return newArr.push(o)
        } else {
          return null
        }
        // Object.keys(o).some(k => {
        //   if(o[k]) {
        //     let str = o[k].toString().toLowerCase()
        //     if (str.includes(string.toLowerCase())) {
        //       return newArr.push(o)
        //     } else {
        //       return null
        //     }
        //   } else {
        //     return null
        //   }
        // })
      })
      // console.log(newArr)
      if (newArr && newArr.length) {
        let svc = newArr[0]
        // console.log(svc)
        let newService = state.workorder
        newService.servicetype = svc
        newService.serviceDescription = svc.description
        newService.serviceCategory = svc.categoryName
        setState({...state, workorder: newService, serviceDescription: svc.description, serviceCategory: svc.categoryName, serviceType: svc, name: svc.name, requiresUpdate: true})
      }
    }
  }
}

const handleOpenPunchList = () => {
  setShowPunchListModal(true)
}

const handleClosePunchListModal = () => {
  setShowPunchListModal(false)
}

const handleUpdateOurWorkorer = (newWoWClientGeneratedNumber) => {
  // props.addClientGeneratedWorkorder(newWoWClientGeneratedNumber)
  setState({...state, workorder: newWoWClientGeneratedNumber})
}

const handleCloseLineItemModal = () => {
  setLineItemModalOpen(false)
}

const handleSubmitLineItems = (d) => {
  console.log('Handle Submit Line Items', d)
  //These become our lineItems
  const formattedItems = formatOurLineItems(d)
  // console.log('Formatted Items', formattedItems)
  console.log('WO', state.workorder)
  console.log('Props', props)
  axios({
    method: 'post',
    url:  `${props.url}/api/jobs/updateLineItems`,
    data: {
      workorderId: state.workorder._id,
      lineItems: formattedItems
    }
  }).then(response => {
    console.log('Axios response from updating line items: ', response)
    // let ourPDF = encodeURIComponent(response.data)
    setState(prevState => ({...prevState, workorder: response.data }))
    // // console.log(response.data)

  })
}

const formatOurLineItems = (list) => {
  let cleanItems = []
  list.forEach(item => {
    let lineItemType = item?.lineItemType || item?.type || 'materials'
    // console.log('Line item type:', lineItemType)
    lineItemType = lineItemType.toLowerCase()
    // console.log(lineItemType)
    let line = {
      ...item,
      unit: 'Each',
      totalPrice: Math.round(100 * (Number(item?.qty || 1) * Number(item?.pricePer || 0))) / 100,
      lineItemType: lineItemType
    }
    cleanItems.push(line)
  })
  return cleanItems
}

const handleOpenLineItemModal = () => {
  setLineItemModalOpen(true)
}

// console.log('845 state', state)
// console.log('858 props', props)
let serviceList = (props.serviceList && props.serviceList.length) || (props.services && props.services.length) ? props.services : []
// console.log(loading)
// console.log('Service ]list', serviceList)
// console.log('Uplaods', props.uploads)
const filteredUploads = props.uploads?.filter(upload => (upload && upload.punchlistId))
// console.log('filtered Uploads', filteredUploads)
// console.log('961 state', state)
// console.log('serviceList', serviceList)
return (
    <div>
         <TaxDetails client={props.client} wo={state.workorder} open={showTaxModal} taxLocales={props.taxLocales} closeModal={e => setShowTaxModal(false)} updateFromTaxes={updateFromTaxes} />
        <ViewInvoiceModal client={props.client} svcDetailsResponse={updateFromInvoiceModal} pdf={pdf} open={showInvoiceModal} />
        <WorkOrderPrintModal pdf={pdf} updateParent={responseFromWOPrint} open={printWOModalOpen} />

        <Grid  container className={classes.gridParent}>
     
                       <Grid item lg={2} md={4} sm={4} xs={6} className={classes.gridItem}> 
                          {/* <FormControlLabel
                            control={
                              <Checkbox
                                checked={state.checked}
                                onChange={handleChange}
                                name="checked"
                                color="primary"
                              />
                            }
                            label="Select"
                          /> */}
                          Workorder Number: {state.workorder.number}
                          {
                            (state.workorder && state.workorder.workComplete) ? <ProofOfCompletion wo={state.workorder} /> : null
                          }
                        </Grid>
                        <Grid item lg={2} md={4} sm={6} xs={12} className={classes.gridItem}> 
                            <CompletedDetails workorder={state.workorder} />
                        </Grid>


                        <Grid item lg={2} md={4} sm={6} xs={12} className={classes.gridItem}>
                          
                          <PunchlistComponent
                            client={props.client}
                            workorder={state.workorder} 
                            closeModal={handleClosePunchListModal}
                            uploads={filteredUploads}
                          />
                        </Grid>
                        <Grid item lg={2} md={4} sm={6} xs={12} className={classes.gridItem}>
                          {
                              (state.workorder && state.workorder._id) && <Button
                              variant="contained"
                              style={{backgroundColor: '#477531'}}
                              className={classes.button}
                              color="secondary"
                              size="small"
                              startIcon={<MoneyIcon />}
                              // onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) handleDeleteService() } }
                              onClick={handleEditTaxes}
                              // onClick={offerDeleteOption}
                              // onClick={ () => { window.confirm('Are you sure you wish to delete this item?') ? handleDeleteService() : null } }
                            >
                              Edit Taxes {state.workorder.taxAmount > 0 ? `$${state.workorder.taxAmount}` : null}
                            </Button> 
                          }
                          
                        </Grid>
                        <Grid item lg={2} md={4} sm={6} xs={12} className={classes.gridItem}>  
                                                      
                            <Button
                              variant="contained"
                              // style={{backgroundColor: '#ed4545'}}
                              className={classes.button}
                              color="secondary"
                              size="small"
                              startIcon={<PrintIcon />}
                              // onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) handleDeleteService() } }
                              onClick={handleSupplyRequest}
                              // onClick={offerDeleteOption}
                              // onClick={ () => { window.confirm('Are you sure you wish to delete this item?') ? handleDeleteService() : null } }
                            >
                              Export Line Items
                            </Button> 
                        </Grid>
                        <Grid item lg={2} md={4} sm={6} xs={12} className={classes.gridItem}>
                          <ProtectedButton type="Jobs" kind="delete" perms={props.perms || []}>

                            <Button
                              variant="contained"
                              style={{backgroundColor: '#ed4545'}}
                              className={classes.button}
                              size="small"
                              startIcon={<DeleteIcon />}
                              onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) handleDeleteService() } }
                              // onClick={handleDeleteService}
                              // onClick={offerDeleteOption}
                              // onClick={ () => { window.confirm('Are you sure you wish to delete this item?') ? handleDeleteService() : null } }
                            >
                              Delete Workorder
                            </Button>
                          </ProtectedButton>
                          {
                            <ProtectedButton type="Complete No Invoice" kind="view" perms={props.perms || []}>
                              <Button
                                variant="contained"
                                style={{backgroundColor: '#d2B48c' }}
                                className={classes.button}
                                size="small"
                                startIcon={<CloudDoneIcon />}
                                onClick={handleMarkAsDupe}
                                disabled={state.isComplete || state.isInvoiced || loading}
                              >
                                {
                                  loading ? <CircularProgress size={18} /> : 'Mark As Duplicate'
                                }
                              
                              </Button>
                            </ProtectedButton>
                          }
                         
                          </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12} className={classes.gridItem}>
                          <Autocomplete
                            id={`servicename-${state.serviceIndex}`}
                            options={serviceList || []}
                            getOptionLabel={(option) => {
                              // console.log('Option label', option)
                              if (option.serviceCode && option.name) {
                                let svcCode = option?.serviceCode?.toString()
                                if (option.name.includes(svcCode)) {
                                  return `${option.name}`;
                                } else {
                                  return `${option.serviceCode} ${option.name}`;
                                }
                                
                              }
                              return option.name || '';
                            }}
                            filterOptions={(options, { inputValue }) => {
                              const searchTerm = inputValue.toLowerCase();
                              return options.filter(option => {
                                // console.log("Filter options", option)
                                let svcCode = option?.serviceCode?.toString() || ''
                                let name = option?.name || ''
                                // console.log(svcCode, name)
                                let matches = (svcCode.toLowerCase().includes(searchTerm) || name.toLowerCase().includes(searchTerm))
                                // console.log('matches', matches)
                                return matches
                                // return (option?.serviceCode?.toLowerCase()?.includes(searchTerm) || 
                                // option.name?.toLowerCase().includes(searchTerm))
                              }
                              );
                            }}
                            onChange={updateServiceType}
                            value={state.serviceType}
                            renderInput={(params) => (
                              <TextField 
                                {...params} 
                                label="Service Type" 
                                onKeyDown={handleTabInteraction}
                                variant="outlined" 
                              />
                            )}
                          />
                      </Grid>
                      {/* <Grid item lg={2} xs={3} className={classes.gridItem}>
                        { state.workorder.taxable ? 'Taxable' : 'Non Taxable'}
                      </Grid> */}
                      {/* <Grid item lg={1} xs={3} className={classes.gridItem}>
                        <TextField
                        label="Est Hours"
                        variant="outlined"
                        type="Number"
                        disabled={state.isInvoiced || state.isComplete}
                        value= {state.estHours}
                        onChange={ e => handleServiceChange(state.workorder, 'estHours', e.target.value, state.serviceIndex) }
                        // defaultValue="Default Value"
                        onFocus={event => {
                          event.target.select();
                        }}
                        />
                      </Grid> */}
                      {/* <Grid item lg={1} xs={3} className={classes.gridItem}>
                        <TextField
                        label="Act Hours"
                        variant="outlined"
                        type="Number"
                        // disabled={state.isInvoiced}
                        value= {state.workorder.actHours}
                        onFocus={event => {
                          event.target.select();
                        }}
                        onChange={ e => handleServiceChange(state.workorder, 'actHours', e.target.value, state.serviceIndex) }
                        // defaultValue="Default Value"
                        />
                      </Grid> */}
                      <Grid item lg={3} md={4} sm={6} xs={12} className={classes.gridItemRow}>
                        <TextField
                            id="price"
                            label="Price"
                            type="Number"
                            variant="outlined"
                            ref={priceRef}
                            disabled={state.isInvoiced || state.workorder.estimate}
                            value={state.workorder.price}
                            onFocus={event => {
                              event.target.select();
                            }}
                            onChange={ e => handleServiceChange(state.workorder, 'price', e.target.value, state.serviceIndex) } // setState({...state, price: e.target.value }) }
                            // defaultValue="Default Value"
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                     
                        <TextField
                            id="invoicedAmount"
                            label="Invoiced"
                            type="Number"
                            variant="outlined"
                            disabled={true}
                            value={state.workorder?.invoicedAmount || 0}
   
                          />
                      </Grid>
                      <Grid item lg={3} md={4} sm={6} xs={12} className={classes.gridItem}>
                        <Autocomplete
                          id={`crew-${state.serviceIndex}`}
                          options={state.crewList || []}
                          getOptionLabel={(option) => crewNameAndForeman(option)}
                          // style={{ width: 200 }}
                          onChange={updateCrew}
                          // onChange={ (e) => updateCrew(workorder, e.target.id, e.target.value, serviceIndex) }
                          value = {state.crew}
                          // onChange={ e => setState({...state, crew: e.target.value, requiresUpdate: true }) }
                          renderInput={(params) => <TextField {...params} label="Assigned Crew" onKeyDown={handleTabInteractionForCrew} variant="outlined" />}
                        />
                      </Grid>
                      <Grid item lg={3} md={4} sm={6} xs={12} className={classes.gridItem}>
                        <TextField
                          id="date"
                          label="Scheduled Date"
                          type="date"
                          value={moment(state.workorder.scheduledDate).format('YYYY-MM-DD') } // "2017-05-24"}
                          className={classes.textField}
                          variant="outlined"
                          onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      
                      <Grid item lg={12} xs={12} className={classes.gridItem}>
                        <Grid container>
                        <Grid item lg={6} xs={12} className={classes.gridItem}>
                            <TextField
                            id="description"
                            label="Description"
                            multiline
                            minRows={4}
                            maxRows={12}
                            variant="outlined"
                            fullWidth
                            disabled={state.workorder?.invoiceNumber}
                            className={classes.textSaveLineBreaks}
                            value={state.serviceDescription}
                            onChange={e => setState({...state, serviceDescription: e.target.value, requiresUpdate: true})}
                            // onChange={ e => handleServiceChange(state.workorder, 'serviceDescription', e.target.value, state.serviceIndex) }
                            // defaultValue="Default Value"
                          />
                          </Grid>
                          <Grid item lg={6} xs={12} className={classes.gridItem}>
                            <TextField
                            id="notes"
                            label="Work Order Notes"
                            multiline
                            minRows={4}
                            variant="outlined"
                            fullWidth
                            disabled={state.workorder?.invoiceNumber}
                            maxRows={12}
                            value={state.workorderNotes}
                            onChange={e => setState({...state, workorderNotes: e.target.value, requiresUpdate: true})}
                            // onChange={ e => handleServiceChange(state.workorder, 'serviceNotes', e.target.value, state.serviceIndex) }
                            // defaultValue="Default Value"
                          />
                          </Grid>
                          
                        </Grid>
                      </Grid>
                      <UpdateButton workorder={state.workorder} requiresWorkorder={props.client.requiresWorkorder} perms={props.perms} loading={loading} />
                      {
                        (props.client && props.client.requiresWorkorder && state.workorder.number && state.workorder._id) ? (
                          <Grid item lg={12} xs={12} className={classes.gridItem}>
                            <ClientGeneratedWorkorderRequired client={props.client} workorder={state.workorder} url={props.url} updateOurWorkorder={handleUpdateOurWorkorer} />
                          </Grid>
                        ) : null
                      }
                      <Grid item lg={12} xs={12} className={classes.gridItem}>
                        {
                          (state.workorder && state.workorder._id) ? <ActionRow workorder={state.workorder} /> : null
                        }
                      </Grid>
                      {
                        (state.isInvoiced || state.workorder.estimate || !state?.workorder?._id || state?.workorder?.invoiceDate) ? null : (
                          <Grid item lg={3} xs={12} className={classes.gridItem}>
                            <Button variant="contained" color="primary" onClick={handleOpenLineItemModal}>Open Line Item Builder 
                              {
                                state?.workorder?.lineItems?.length ? `  (${state.workorder.lineItems.length} Items)` : ''
                              }
                            </Button>
                            <LineItemModal
                              open={lineItemModalOpen}
                              onClose={handleCloseLineItemModal}
                              onSubmit={handleSubmitLineItems}
                              initialLineItems={state?.workorder?.lineItems || []}
                              client={props.client}
                              entities={props?.user?.entities}
                              takeOffList={takeOffList}
                            />
                          </Grid>
                        )
                      }
                      {/* <Grid item lg={3} xs={12} className={classes.gridItem}>
                        <Button
                          variant="contained"
                          style={{backgroundColor: 'green', visible: false}}
                          className={classes.button}
                          size="small"
                          startIcon={<VisibilityIcon />}
                        >
                          Preview Invoice
                        </Button> 
                      </Grid>
                      */}
                      <Grid item lg={12} xs={12} className={classes.gridItem}>
                        { ServiceVisitDetails({ visits: serviceVisits, wo: props.workorder }) }
                      </Grid>            
                      </Grid>
      </div>
    )
  }

  const ProofOfCompletion = ({ wo }) => {
    // let createdBy = (line && line.work_completed_by && line.work_completed_by._id) ? `${line.work_completed_by.firstName} ${line.work_completed_by.lastName}`
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <AssignmentTurnedInIcon size={24} />Completed In App {moment(wo.work_complete_at).fromNow()}
      </div>
    )
  }

const ActionRow = (props) => {
  console.log('Action Row', props)
  if (props.workorder && props.workorder._id) {
    return (
      <Grid container spacing={2} justifyContent="space-evenly">
        <Grid item xs={12} sm={6} md="auto">
          <WorkorderPhotos workorder={props.workorder} />
        </Grid>
        <Grid item xs={12} sm={6} md="auto">
          <CustomerIssues workorder={props.workorder} />
        </Grid>
        <Grid item xs={12} sm={6} md="auto">
          <WorkorderNotes workorder={props.workorder} />
        </Grid>
        <Grid item xs={12} sm={6} md="auto">
          <JobChat workorder={props.workorder} />
        </Grid>
        <Grid item xs={12} sm={6} md="auto">
          <WorkorderChecklist list={props.workorder.checklistItems || []} />
        </Grid>
      </Grid>
    )
  } else {
    return null
  }

}

const mapStateToProps = (state) => {
  // console.log('Entity??? ', state)
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    perms: state.perms,
    services: state.services,
    crews: state.crews
  }
}

export default connect(mapStateToProps)(ServiceDetailComponent)
