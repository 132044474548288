import React from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import axios from 'axios'
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
import AdbIcon from '@material-ui/icons/Adb';
import { Button, CircularProgress }  from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
// import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/AddCircle';
// import FilterListIcon from '@material-ui/icons/FilterList';
import moment from 'moment'
import { Link } from 'react-router-dom'
// import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { socket } from '../SocketIo/Socket'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import EditTakeOffItem from './EditTakeOffItem'
// import AddNewItemModal from '';
import AddToQueueIcon from '@material-ui/icons/AddToQueue';
// function createData(name, status, created, lastactivity, tourbooked) {
//   return { name, status, created, lastactivity, tourbooked };
// }
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

// const rows = [
//   createData('Tom Smith', 'New', '12/17/2019', null, null),
//   createData('Donut', 'Working', '12/18/2019', 51, 4.9),
//   createData('Eclair', 'New', '11/29/2019', 24, 6.0),
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
//   createData('Honeycomb', 408, 3.2, 87, 6.5),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Jelly Bean', 375, 0.0, 94, 0.0),
//   createData('KitKat', 518, 26.0, 65, 7.0),
//   createData('Lollipop', 392, 0.2, 98, 0.0),
//   createData('Marshmallow', 318, 0, 81, 2.0),
//   createData('Nougat', 360, 19.0, 9, 37.0),
//   createData('Oreo', 437, 18.0, 63, 4.0),
// ];

function desc(a, b, orderBy, isDate) {
  // console.log('desc: ', a)
  // console.log('desc b: ', b)
  if (isDate) {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      // console.log('B is < A')
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      // console.log('B is > A')
      return 1;
    }
    // console.log('B is ? A')
    // console.log('A: ', a[orderBy])
    // console.log('B: ', b[orderBy])
    return 0;
  } else {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      return 1;
    }
    return 0;
  }
}

function stableSort(array, cmp) {
  // console.log('Stable sort: ', array.length)
  // console.log('CMP: ', cmp)
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  // console.log('Matches: ', stabilizedThis.length)
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  if (orderBy==='lastUpdated') {
  // console.log('Last Updated')
    return order === 'desc' ? (a, b) => desc(a, b, orderBy, true) : (a, b) => -desc(a, b, orderBy, true);
  } else {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }
  
}

function currencyFormat(num) {
  if (!num) num = 0
  return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
  // { id: 'size', numeric: false, disablePadding: true, label: 'Size' },
  { id: 'scopeIds', numeric: false, disablePadding: true, label: 'Scopes' },
  { id: 'category', numeric: false, disablePadding: true, label: 'Category' },
  { id: 'description', numeric: false, disablePadding: true, label: 'Description' },
  { id: 'notes', numeric: false, disablePadding: true, label: 'Notes' },
  { id: 'photo', numeric: false, disablePadding: false, label: 'Photo' }
];

function EnhancedTableHead(props) {
// console.log('Got our props', props)
  
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
  // console.log('Sort Property: ', property)
  // console.log('sort event; ', event)
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all leads' }}
          />
        </TableCell> */}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fakeLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue'
  },
  rowPad: {
    height: '40px !important',
    // padding: 'px'
  },
  filterTabs: {
    marginBottom: theme.spacing(2),
    backgroundColor: '#f5f5f5',
    borderRadius: '4px 4px 0 0',
  },
  filterTab: {
    fontWeight: 500,
    minWidth: 100,
  },
  activeFilterCount: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    borderRadius: '50%',
    padding: '2px 8px',
    fontSize: '0.8rem',
  },

  
}));

function EnhancedTable(props) {
// console.log('Service List Props: ', props)
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('-created');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [searchValue, setsearchValue] = React.useState('');
  const [searchMatches, setsearchMatches] = React.useState(0);
  const [open, setOpen] = React.useState(false)
  const [snoutWeevilModalOpen, setSnoutWeevilModalOpen] = React.useState(false)
  const [inventoryItem, setInventoryItem] = React.useState({});
  // const [svcModalOpen, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [showAllEmployees, setShowAllEmployees] = React.useState(false)
  const [rows, setRows] = React.useState([])
  const [allEmployees, setAllEmployees] = React.useState([])
  const [filteredRows, setFilteredRows] = React.useState([])
  const [crews, setCrews] = React.useState([])
  const [state, setState] = React.useState({
    searchMatches: 0,
    openSnoutWeevil: false
  })
  const [showNewEmployeeModal, setShowNewEmployeeModal] = React.useState(false)
  const [showNewItemModal, setShowNewItemModal] = React.useState(false)
  const [scopeList, setScopes] = React.useState([])
  const [sizes, setSizes] = React.useState([])
  const [vendors, setVendors] = React.useState([])
  const [filterCategory, setFilterCategory] = React.useState('all'); // Add this line

  // async function fetchData(toReturn) {
  //   // console.log('Fetch our inventory')
  //   setLoading(true)
  //   const response = await axios.get(`${props.url}/api/company/listTakeOffs`)
  // // console.log(response)
  //   if (response.data && response.data.length) {
  //     setRows(response.data)
  //     setFilteredRows(response.data)
  //   } else {
  //   // console.log('Fucked up')
  //   }
  // }
  React.useEffect(() => {
    console.log('Connect to socket: takeoffitemupdates')
    socket.on('takeoffitemupdates', (data) => {
      dynamicTakeoffUpdate(data, rows, inventoryItem)
    })

    return () => {
      console.log('Disconnect from socket: takeoffitemupdates')
      socket.off('takeoffitemupdates')
    }
  }, [rows, inventoryItem])

  const dynamicTakeoffUpdate = (data, currentRowsItem, currentInventoryItem) => {
    console.log('Takeoff Item is updating!!', data)
    console.log(currentRowsItem)
    const updatedItem = data.item
    if (updatedItem && updatedItem._id) {
      console.log('Updated Item:', updatedItem)
      // Check if this is in our list of items or not
      if (currentInventoryItem && currentInventoryItem._id && currentInventoryItem._id === updatedItem._id) {
        console.log('OUR item is updating!!!')
        setInventoryItem({...currentInventoryItem, ...updatedItem})
      }
      let currentRows = [...currentRowsItem]
      console.log('Current Rows:', currentRows)
      let index = currentRows.findIndex(item => (item._id === updatedItem._id))
      console.log('Item index', index)
      if (index > -1) {
        currentRows[index] = updatedItem
      } else {
        currentRows.unshift(updatedItem)
      }
      console.log('UPdated takeoffs', currentRows)
      setRows(currentRows)
      filterOut(currentRows, searchValue)
    }
  }

  React.useEffect(() => {
    let isMounted = true

    async function fetchData(toReturn) {
      // console.log('Fetch our inventory')
      // setLoading(true)
      const response = await axios.get(`${props.url}/api/company/listTakeOffs`)
    // console.log(response)
      if (response.data && response.data.length && isMounted) {
        setRows(response.data)
        setFilteredRows(response.data)
      } else {
      // console.log('Fucked up')
      }
    }

    async function fetchScopes () {
      axios
      .get(
        `${props.url}/api/settings/getStuffForNewItem`
        )
      .then(({ data }) => {
        if (isMounted) {
        // console.log('Set scopes and shit', data)
          if (data && data.scopes) {
            setScopes(data.scopes)
          }
          if (data && data.sizes) {
            setSizes(data.sizes)
          }
          if (data && data.vendors) {
            setVendors(data.vendors)
          }
        }


      }).catch(function (err) {
      // console.log('Error getting stuff', err)
      });
    }

    fetchScopes()
    fetchData()
    return () => {
      isMounted = false
    }
  }, [])


  const handleFilterChange = (event, newValue) => {
    setFilterCategory(newValue);
    // Reset page when filter changes
    setPage(0);
  };

  const getFilteredRowsByCategory = (rows) => {
    console.log('getFilteredRowsByCategory', filterCategory)
    if (filterCategory === 'all') {
      return rows;
    }
    
    return rows.filter(row => {
      // Check for herbicide
      if (filterCategory === 'herbicide' && row.isHerbicide) {
        return true;
      }
      console.log('Category', row)
      if (filterCategory === 'cacti' && row?.category?.toLowerCase()?.includes('cact')) {
        console.log('Find by category...')
        return true
      }
      // For other categories, check the scopeIds
      if (!row.scopeIds || !row.scopeIds.length) {
        return false;
      }
      
      // Find if any scope in scopeList that matches the id in scopeIds contains our keyword
      return row.scopeIds.some(scopeId => {
        const scope = scopeList.find(item => item._id === scopeId);
        if (!scope) return false;
        
        const scopeName = scope.name.toLowerCase();
        console.log('Scope Name: %s   Filter Category: %s', scopeName, filterCategory)
        switch (filterCategory) {
          case 'plant material':
            return scopeName.includes('plant');
          case 'trees':
            return scopeName.includes('tree');
          case 'cacti':
            return scopeName.includes('cacti') || scopeName.includes('cactus');
          case 'irrigation':
            return scopeName.includes('irrigation');
          default:
            return false;
        }
      });
    });
  };
  
  const countItemsByCategory = (rows, category) => {
    if (category === 'all') {
      return rows.length;
    }
    
    if (category === 'herbicide') {
      return rows.filter(row => row.isHerbicide).length;
    }
    if (category === 'cacti') {
      // console.log('Find by category...')
      return rows.filter(row => row?.category?.toLowerCase()?.includes('cact')).length
    }
    // if (category === 'irrigation') {
    //   // console.log('Find by category...')
    //   return rows.filter(row => row?.category?.toLowerCase()?.includes('irrigation'))
    // }
    
    return rows.filter(row => {
      if (!row.scopeIds || !row.scopeIds.length) {
        return false;
      }
      
      return row.scopeIds.some(scopeId => {
        const scope = scopeList.find(item => item._id === scopeId);
        if (!scope) return false;
        
        const scopeName = scope.name.toLowerCase();
        switch (category) {
          case 'plant material':
            return scopeName.includes('plant');
          case 'trees':
            return scopeName.includes('tree');
          case 'cacti':
            return scopeName.includes('cacti') || scopeName.includes('cactus');
          case 'irrigation':
            return scopeName.includes('irrigation');
          default:
            return false;
        }
      });
    }).length;
  };


  // React.useEffect(() => {
  //   let isMounted = true
  // // console.log('Use effectset Inventory list: ', props.clients)
  //   async function fetchSizes () {
  //     axios
  //     .get(
  //       `${props.url}/api/settings/getGenericOptions?type=size`
  //       )
  //     .then(({ data }) => {
  //       // console.log("Data", data)
  //       if (isMounted) setSizes(data)
  //     }).catch(function (err) {
  //     // console.log('Error getting stuff', err)
  //     });
  //   }
  //   fetchData()
  //   fetchSizes()

  //   return () => {
  //     isMounted = false
  //   }
  // }, [])

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    // console.log('Set order by property: ' + property + ' in ' + isDesc + ' order')
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n._id);
      // console.log(newSelecteds)
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  // // console.log('handle click ', name)
  // // console.log('Index: ', selectedIndex)
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1),
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const storeSearchMatches = (d) => {
    // console.log('Search matches: ', d)
    // setState({...state, searchMatches: d.length})
  }
  // const handleChangeDense = event => {
  //   setDense(event.target.checked);
  // };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = 0// rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const filterOut = (r, searchText) => {
    // console.log('FIlter out: %s - %s', r, searchText)
    let string = searchText
    let newArr = []
    r.filter(o => {
      // console.log('Object:  ', o)
      Object.keys(o).some(k => {
        // console.log(o[k])
        if(o[k]) {
          // console.log('OK: ', o[k])
          let str = o[k].toString().toLowerCase() 
          // console.log(str)
          if (str.includes(string.toLowerCase())) {
            // console.log('Mathc')
            return newArr.push(o)
          } else {
            return null
          }
        } else {
          return null
        }
      })
      // return newArr
    })
    // return newArr
    // console.log('Lenght: ', newArr.length)
    // console.log(newArr)
    // storeSearchMatches(newArr)
    // setsearchMatches(newArr.length)
    // return newArr
    setFilteredRows(newArr)
    // let newArr = r.filter(i => {
    //   if (i.includes('test')) return i
    // })
    // return newArr
    // return r.filter((obj)=>{
    //   return Object.keys(obj).reduce((acc, curr)=>{
    //         return acc || obj[curr] ? obj[curr].toString().toLowerCase().includes(string) : obj[curr];
    //   }, false);
    // })
  }
  const handleChange = (name) => (event) => {
    // console.log('Searching: ', event.target.value)
    setsearchValue(event.target.value)
    if (event.target.value === '') {
      setFilteredRows(rows)
    } else {
      filterOut(rows, event.target.value)
    }
    setPage(0)
  }

  const openSvcModal = (svc) => {
  console.log('Open inventory modal: ', svc)
    setInventoryItem(svc)
    setOpen(true)
  }
  
// const updateFromSvcModal = (t, d) => {
// // console.log('Update from service modal: ', d)
//   if (t === 'closeModal') setOpen(false)
//   if (t === 'update') {
//     let services = rows
//     // console.log('Got %d clients: ', clients.length)
//     let index = services.findIndex(service =>  (service._id === d._id))
//     if (index > -1) {
//     // console.log('Svc already exists', index)
//     // console.log(services[index])
//       services[index] = d
//     // console.log('Agai: ', services[index])
//       // console.log(services)
//       setRows(services)
//       setFilteredRows(services)
//     } else {
//       services.push(d)
//       setRows(services)
//       setFilteredRows(services)
//     }
//     setOpen(false)
//   }
// }



const setShowAllOrNot = () => {
// console.log('To Shor all or not', state)
  setsearchValue('')
  if (showAllEmployees) {
  // console.log('Gonna just show the active now')
    setShowAllEmployees(false)
    let activeClients = allEmployees.filter(item => { return item.activeEmployee })
    // console.log('Got %d activeClients: ', activeClients.length)
    setFilteredRows(activeClients)
    setRows(activeClients)
  } else {
  // console.log('Show everyone')
    setShowAllEmployees(true)
    setFilteredRows(allEmployees)
    setRows(allEmployees)
  }
  setPage(0)
}

const handleAddEmployee = () => {
// console.log('Add employee')
  setShowNewEmployeeModal(true)
}

const updateParent = (d) => {
  if (d.employee) {
  // console.log('Open this mother fucker!!', d)
    setInventoryItem(d.employee)
    setOpen(true)
    setShowNewEmployeeModal(false)
  }
}

const responseFromEditItem = (d, savedItem) => {
  console.log('Update our item:', d)
  console.log('Saved item:', savedItem)
  if (d === 'closeModal') setOpen(false)
  if (d === 'saved') {
      // Update the list
      let inventoryItems = [...rows]
      inventoryItems.unshift(savedItem)
      setRows(inventoryItems)
      filterOut(inventoryItems, searchValue)
      setInventoryItem(savedItem)
    }
  if (d === 'updated' || d === 'updateOptions') {
  // console.log('Updated this bitch', savedItem)
    // Update the list
    let inventoryItems = rows
    // console.log('Got %d clients: ', clients.length)
    let index = inventoryItems.findIndex(item =>  (item._id === savedItem._id))
  console.log('Index:', index)
  console.log('Might need to search by Inventory item', inventoryItem)
    if (index < 0) {
    console.log('search by inventory item')
      index = inventoryItems.findIndex(item =>  (item._id === inventoryItem._id))
    console.log('Index!', index)
    }
    if (index > -1) {
      inventoryItems[index] = savedItem
      // console.log('Agai: ', services[index])
      // console.log(services)
      setRows(inventoryItems)
      filterOut(inventoryItems, searchValue)
      // setFilteredRows(inventoryItems)
    } else {
      // services.push(d)
      // setRows(services)
      // setFilteredRows(services)
    }
    // console.log('Did we update the item??')
    setInventoryItem(savedItem)
    if (d === 'updated') setOpen(false)
  }
  if (d === 'deleted') {
    // Update the list
    let inventoryItems = rows
    // console.log('Got %d clients: ', clients.length)
    let index = inventoryItems.findIndex(item =>  (item._id === savedItem._id))
    if (index > -1) {
      // inventoryItems[index] = savedItem
      inventoryItems.splice(index, 1)
      // console.log('Agai: ', services[index])
      // console.log(services)
      setRows(inventoryItems)
      filterOut(inventoryItems, searchValue)
      // setFilteredRows(inventoryItems)
    } else {
      // services.push(d)
      // setRows(services)
      // setFilteredRows(services)
    }
    setOpen(false)
  }
}

const handleCloseNewItemModal= (d) => {
// console.log('Close new item modal ', d)
  setOpen(false)
}

const addItem = (data) => {
// console.log('Add Item')
}
const openNewItemModal = () => {
  setInventoryItem({})
  setOpen(true)
}
const closeEditModal = () => {
  console.log('Close modal')
  setOpen(false)
}

const item = {}

  return (
    <div className={classes.root}>
      {/* <AddNewItemModal open={showNewItemModal} handleCloseModal={handleCloseNewItemModal} item={item} addItem={addItem} /> */}
      <EditTakeOffItem item={inventoryItem} sizes={sizes} scopes={scopeList} vendors={vendors} open={open} updateParent={responseFromEditItem} handleCloseModal={closeEditModal} addItem={addItem} />
     <Paper className={classes.paper}>
      
     <Box>
          <Tabs
            value={filterCategory}
            onChange={handleFilterChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="plant category filters"
            className={classes.filterTabs}
          >
              <Tab 
              label={
                <span>
                  All
                  <span className={classes.activeFilterCount}>{filteredRows.length}</span>
                </span>
              } 
              value="all" 
              className={classes.filterTab}
            />
            <Tab 
              label={
                <span>
                  Plant Material 
                  <span className={classes.activeFilterCount}>
                    {countItemsByCategory(filteredRows, 'plant material')}
                  </span>
                </span>
              } 
              value="plant material" 
              className={classes.filterTab}
            />
            <Tab 
              label={
                <span>
                  Trees
                  <span className={classes.activeFilterCount}>
                    {countItemsByCategory(filteredRows, 'trees')}
                  </span>
                </span>
              } 
              value="trees" 
              className={classes.filterTab}
            />
            <Tab 
              label={
                <span>
                  Cacti
                  <span className={classes.activeFilterCount}>
                    {countItemsByCategory(filteredRows, 'cacti')}
                  </span>
                </span>
              } 
              value="cacti" 
              className={classes.filterTab}
            />
            <Tab 
              label={
                <span>
                  Irrigation Parts
                  <span className={classes.activeFilterCount}>
                  {countItemsByCategory(filteredRows, 'irrigation')}
                  </span>
                </span>
              } 
              value="irrigation" 
              className={classes.filterTab}
            />
            <Tab 
              label={
                <span>
                  Herbicide
                  <span className={classes.activeFilterCount}>
                    {countItemsByCategory(filteredRows, 'herbicide')}
                  </span>
                </span>
              } 
              value="herbicide" 
              className={classes.filterTab}
            />
          </Tabs>
        </Box>
        <Grid container style={{padding: '10px', margin: 10 }}>
          <Grid item lg={12} xs={12} style={{textAlign: 'center'}}>
            <h2>TakeOff Inventory Items</h2>
          </Grid>
          <Grid item lg={3} xs={6}>
          Total Items: {rows.length}
          </Grid>
          <Grid item lg={4}>
          <Button
          variant="contained"
          // style="secondary"
          style={{ color: '#222'}}
          // className={classes.button}
          size="small"
          startIcon={<AddToQueueIcon  color="primary" />}
          disabled={loading}
          // onClick={handleSnoutWeevil }
          onClick={openNewItemModal}
          // onClick={handleDeleteService}
          // onClick={offerDeleteOption}
          // onClick={ () => { window.confirm('Are you sure you wish to delete this item?') ? handleDeleteService() : null } }
        >
          Add New Item
        </Button> 
          </Grid>
        </Grid>
        <Grid container style={{padding: '10px'}}>
         
          <Grid item lg={6} xs={6}>

            <div className={classes.search}>
                <div >
                  <SearchIcon /> Search { searchValue ? filteredRows.length > 1 ? `(${filteredRows.length} Matches)` : `(${filteredRows.length} Match)` : ''}
                </div>
                <input name="search" value={ searchValue } onChange={ handleChange('search') } type="text"></input>
              </div>
          </Grid>
          <Grid item lg={6}>

          </Grid>

        </Grid>
        <div className={classes.tableWrapper} style={{margin: '10px'}}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
            options={{
              rowStyle: {height: 55}
            }}
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody >
              { stableSort(getFilteredRowsByCategory(filteredRows), getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  // console.log('Row map: ', row)
                  // console.log('Total rows; ', rows)
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  let url = ''
                  let imgURL = ''
                  if (row && row.photos && row.photos.length) url = row.photos[0]['url']
                  if (row.pics && row.pics.length) {
                    imgURL = row.pics[0]
                  } else {
                    imgURL = row.pictureURL
                  }
                    return (
                    
                      <TableRow
                        hover
                        // onClick={event => handleClick(event, row._id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row._id}
                        className={classes.rowPad}
                        selected={isItemSelected}
                      >
                        <TableCell component="th" id={labelId} scope="row" padding="none">
                        <span className={classes.fakeLink} onClick={() => openSvcModal(row)}>{row.name}</span>
                        <div style={{ fontStyle: 'italic' }}>{row.altName ? row.altName : ''}</div>
                        </TableCell>
                        {/* <TableCell align="left">{row.size}</TableCell> */}
                        <TableCell align="left"><ListOfScopes scopeIds={row.scopeIds} scopes={scopeList} /></TableCell>
                        <TableCell align="left">{row.category}
                          {
                            (row.subCategory && row.subCategory !== '') ? `  (${row.subCategory})` : null
                          }
                        </TableCell>
                        <TableCell align="left">{row.description}</TableCell>
                        <TableCell align="left">{row.notes}</TableCell>
                        <TableCell align="left">
                          <img height="90" src={imgURL}></img>
                        </TableCell>
                      </TableRow>
                    );
                })
              }
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          { loading ?<CircularProgress style={{color: '#fff'}} size={ 16 } /> : ''}
                    
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100, 500]}
          component="div"
          count={getFilteredRowsByCategory(filteredRows).length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          // onPageChange={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          // onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </div>
  );
}

const ListOfScopes = ({ scopeIds, scopes }) => {
  // console.log('list of scopes', scopes)
  // console.log('scopeids', scopeIds)
  let scopeList = []
  if (scopeIds && scopeIds.length) {
    scopeIds.map(scopeId => {
      let scope = scopes.find(item => (item._id === scopeId))
      if (scope && scope._id) scopeList.push(scope)
    })
  }

  return (
    <React.Fragment>
      {
        scopeList.map((scopeObject, index) => {
         
          return (
            <div key={index}>
              {scopeObject.name}
            </div>
          )
        })
      }
    </React.Fragment>
  )
}
const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url
  }
}

export default connect(mapStateToProps)(EnhancedTable)

