import React, { useEffect, useRef, useCallback, memo } from 'react';
import L from 'leaflet';
import 'leaflet-draw';
import FreeDraw, { CREATE, NONE } from 'leaflet-freedraw'; // Import necessary modes
import { Box } from '@material-ui/core';
import { defaultStyle, getGeometryFromLayer } from './mapUtils';

const freehandDrawingStyle = { // Style WHILE drawing (for feedback)
    color: '#00FFFF', weight: 3, opacity: 0.7, fill: false, smoothFactor: 1,
};

const finalLineStyle = { // Final style for OUR polyline
    color: 'blue', weight: 4, opacity: 0.9
};
const finalPolygonStyle = { // Final style for OUR polygon
     color: 'red', weight: 3, opacity: 0.8, fillColor: '#f03', fillOpacity: 0.3
};

const MapComponent = memo(({
    provider,
    drawings,
    initialCenter = [33.55, -112.0],
    initialZoom = 12,
    drawingMode, // ADDED: Prop from parent ('polygon' or 'line')
    onMapReady,
    onDrawCreated,
    onDrawEdited,
    onDrawDeleted,
    onDrawStart,
    onDrawStop,
    onMapViewChange,
    isDrawingToolActive
}) => {
    const mapContainerRef = useRef(null);
    const mapInstanceRef = useRef(null);
    const baseLayerRef = useRef(null);
    const drawnItemsRef = useRef(null); // L.FeatureGroup ref
    const freeDrawRef = useRef(null);
    const drawControlRef = useRef(null);

    // updateBaseLayer and updateDisplayedDrawings remain the same
    // Function to add/update base layer
    const updateBaseLayer = useCallback((currentMap, providerToAdd) => {
        if (!currentMap || !providerToAdd) return;
        if (baseLayerRef.current) { currentMap.removeLayer(baseLayerRef.current); baseLayerRef.current = null; }
        let newLayer;
        try {
            console.log('Set a new provider', providerToAdd)
            if (providerToAdd.group === "maricopa") {
                const tileOptions = {
                    attribution: providerToAdd.attribution,
                    minZoom: providerToAdd.minZoom || 0,
                    maxZoom: 25,  // Max UI zoom level (what user can zoom to)
                    maxNativeZoom: 21,  // Max zoom level with actual tiles
                    errorTileUrl: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
                };

                if (providerToAdd.isExportService && L.esri?.dynamicMapLayer) {
                    newLayer = L.esri.dynamicMapLayer({
                        url: providerToAdd.serviceUrl,
                        opacity: 1.0,
                        useCors: false,
                        f: 'image',
                        format: 'png32',
                        transparent: true,
                        // maxZoom: 25,  // Max UI zoom 
                        maxNativeZoom: providerToAdd.maxZoom || 25,  // Max actual tiles
                        ...tileOptions
                    });
                    // newLayer = L.tileLayer(providerToAdd.url, { 
                    //     attribution: providerToAdd.attribution, 
                    //     minZoom: providerToAdd.minZoom || 0, 
                    //     maxZoom: 25,  // Max UI zoom
                    //     maxNativeZoom: providerToAdd.maxZoom || 19,  // Google typically maxes at 19
                    //     errorTileUrl: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7' 
                    // });
                    // { url: providerToAdd.serviceUrl, opacity: 1.0, useCors: false, f: 'image', format: 'png32', transparent: true, attribution: providerToAdd.attribution, minZoom: providerToAdd.minZoom, maxZoom: providerToAdd.maxZoom }
                    // newLayer = L.esri.dynamicMapLayer();
                } else {
                    newLayer = L.tileLayer(providerToAdd.url, { attribution: providerToAdd.attribution, minZoom: providerToAdd.minZoom, maxZoom: providerToAdd.maxZoom, errorTileUrl: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7', ...tileOptions });
                }
            } else if (providerToAdd.group === "google") {
                newLayer = L.tileLayer(providerToAdd.url, { 
                    attribution: providerToAdd.attribution, 
                    minZoom: providerToAdd.minZoom || 0, 
                    maxZoom: 25,  // Max UI zoom
                    maxNativeZoom: providerToAdd.maxZoom || 19,  // Google typically maxes at 19
                    errorTileUrl: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7' 
                });

                // newLayer = L.tileLayer(providerToAdd.url, { attribution: providerToAdd.attribution, minZoom: providerToAdd.minZoom, maxZoom: providerToAdd.maxZoom, errorTileUrl: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7' });
            }
            if (newLayer) {
                newLayer.on('tileerror loaderror', (e) => console.error(`MapComponent: Layer Error for ${providerToAdd.name}`, e));
                newLayer.addTo(currentMap);
                baseLayerRef.current = newLayer;
            }
        } catch (error) { console.error(`MapComponent: Critical error adding layer ${providerToAdd.name}:`, error); }
    }, []);

    const updateDisplayedDrawings = useCallback((featureGroup, drawingsToDisplay) => {
        if (!featureGroup) return;
        featureGroup.clearLayers();

        drawingsToDisplay.forEach(drawing => {
            let layer;
            try {
                const style = { ...defaultStyle, ...(drawing.style || {}) };
                const options = (drawing.type !== 'marker') ? { ...style } : {};

                switch (drawing.type) {
                    case 'polygon': case 'rectangle': case 'freedraw':
                    case 'freedraw-polygon': // Handle potential state value
                        layer = L.polygon(drawing.geometry.latlngs, options);
                        break;
                    case 'circle':
                        layer = L.circle(drawing.geometry.center, { ...options, radius: drawing.geometry.radius });
                        break;
                    case 'marker':
                        layer = L.marker(drawing.geometry.latlng);
                        break;
                    case 'polyline':
                    case 'freedraw-line': // Handle potential state value
                        layer = L.polyline(drawing.geometry.latlngs, options);
                        break;
                    default: return;
                }
                layer._drawingId = drawing.id;
                featureGroup.addLayer(layer);
            } catch (e) { console.error("MapComponent: Error creating layer for drawing:", drawing.id, e); }
        });
    }, []);

    // --- Map Initialization Effect ---
    useEffect(() => {
        if (!mapContainerRef.current || mapInstanceRef.current) return;

        console.log("MapComponent: Initializing Leaflet map...");
        const map = L.map(mapContainerRef.current, {
            center: initialCenter,
            zoom: initialZoom,
            zoomControl: true,
            attributionControl: false
        });
        mapInstanceRef.current = map;
        L.control.attribution({ position: 'bottomright' }).addTo(map);

        // Initialize FeatureGroup for drawings (L.Draw edits/display)
        drawnItemsRef.current = new L.FeatureGroup();
        map.addLayer(drawnItemsRef.current);

        // Initialize FreeDraw Instance
        freeDrawRef.current = new FreeDraw({
            mode: NONE, // Start passive
            smoothFactor: 0.3,
            simplifyFactor: 0.1,
            // Style for visual feedback *during* drawing
            polylineOptions: freehandDrawingStyle,
            polygonOptions: freehandDrawingStyle,
        });
        // Don't add FreeDraw layer initially, parent will do it via ref on activation

        // *** MODIFIED FreeDraw 'markers' Handler ***
        freeDrawRef.current.on('markers', event => {
            console.log("MapComponent: FreeDraw 'markers' event received.");
            let createdLayer = null;
            let createdLayerType = null;

            if (event.latLngs && event.latLngs.length > 0) {
                const polygonPoints = event.latLngs[0];

                if (polygonPoints.length > 1) {
                     if (drawingMode === 'line') { // Check prop from parent
                         if (polygonPoints.length > 1) { // Need > 1 for slice
                             const polylinePoints = polygonPoints.slice(0, -1); // Create OPEN line points
                             createdLayer = L.polyline(polylinePoints, finalLineStyle); // Use final style
                             createdLayerType = 'line';
                             console.log(`MapComponent: Created L.polyline with ${polylinePoints.length} points.`);
                         } else { console.log("MapComponent: Not enough points for a line."); }
                     } else { // Default to polygon
                         createdLayer = L.polygon(polygonPoints, finalPolygonStyle); // Use final style
                         createdLayerType = 'polygon';
                         console.log(`MapComponent: Created L.polygon with ${polygonPoints.length} points.`);
                     }

                     if (createdLayer && onDrawCreated) {
                         // Add the created layer to the main FeatureGroup IMMEDIATELY
                         // so the parent's state update will see it when it redraws.
                         createdLayer._drawingId = `temp-${Date.now()}`; // Temporary ID until parent assigns real one
                         if (drawnItemsRef.current) {
                            drawnItemsRef.current.addLayer(createdLayer);
                         } else {
                            console.error("MapComponent: drawnItemsRef is null, cannot add created layer.")
                         }

                         console.log("MapComponent: Calling onDrawCreated for FreeDraw shape.");
                         onDrawCreated({ layer: createdLayer, layerType: createdLayerType });
                         // Parent will now handle adding to state and permanent display
                     }

                } else { console.log("MapComponent: Not enough points drawn."); }

            } else { console.log("MapComponent: No latLngs in markers event."); }

            // Cleanup AFTER processing
            if (freeDrawRef.current) {
                freeDrawRef.current.clear(); // Clear FreeDraw's internal drawing
                freeDrawRef.current.mode = NONE; // Reset mode
                console.log("MapComponent: Cleared FreeDraw layer and reset mode to NONE.");
            }

             // Signal draw stop to parent AFTER processing and cleanup
             if (onDrawStop) {
                 console.log("MapComponent: Calling onDrawStop for FreeDraw completion.");
                 onDrawStop({ layerType: `freedraw-${drawingMode}` }); // Pass specific type back
             }
        });
        console.log("MapComponent: FreeDraw instance created and 'markers' listener attached.");


        // Initialize L.Control.Draw
        drawControlRef.current = new L.Control.Draw({
            edit: { featureGroup: drawnItemsRef.current, remove: true }, // Enable remove in edit toolbar
            draw: { polygon: { shapeOptions: defaultStyle }, polyline: { shapeOptions: defaultStyle }, rectangle: { shapeOptions: defaultStyle }, circle: { shapeOptions: defaultStyle }, marker: {}, circlemarker: false }
        });
        map.addControl(drawControlRef.current);

        // Attach L.Draw Event Listeners
        map.on(L.Draw.Event.CREATED, (event) => onDrawCreated && onDrawCreated(event));
        map.on(L.Draw.Event.EDITED, (event) => onDrawEdited && onDrawEdited(event));
        map.on(L.Draw.Event.DELETED, (event) => onDrawDeleted && onDrawDeleted(event));
        map.on('draw:drawstart', (event) => onDrawStart && onDrawStart(event));
        map.on('draw:drawstop', (event) => onDrawStop && onDrawStop(event));
        map.on('draw:editstart', (event) => onDrawStart && onDrawStart({ ...event, layerType: 'edit' }));
        map.on('draw:editstop', (event) => onDrawStop && onDrawStop({ ...event, layerType: 'edit' }));
        map.on('draw:deletestart', (event) => onDrawStart && onDrawStart({ ...event, layerType: 'delete' }));
        map.on('draw:deletestop', (event) => onDrawStop && onDrawStop({ ...event, layerType: 'delete' }));

        // Attach Map View Listener
        map.on('zoomend moveend', () => {
            if (onMapViewChange && mapInstanceRef.current) {
                const center = mapInstanceRef.current.getCenter();
                const zoom = mapInstanceRef.current.getZoom();
                const bounds = mapInstanceRef.current.getBounds();
                onMapViewChange({ center, zoom, bounds });
            }
        });

        // Call ready callback
        if (onMapReady) { onMapReady({ map, drawnItemsLayer: drawnItemsRef.current, freeDrawInstance: freeDrawRef.current, drawControlInstance: drawControlRef.current }); }

        // Add initial base layer & drawings
        updateBaseLayer(map, provider);
        updateDisplayedDrawings(drawnItemsRef.current, drawings);

        // Cleanup
        return () => {
            console.log("MapComponent: Cleaning up map instance...");
            if (mapInstanceRef.current) { mapInstanceRef.current.remove(); mapInstanceRef.current = null; }
            baseLayerRef.current = null; freeDrawRef.current = null; drawControlRef.current = null;
        };
    }, []); // Keep dependencies minimal for init

    // Update base layer effect (keep)
    useEffect(() => {
        if (mapInstanceRef.current && provider) {
            console.log("MapComponent: Provider changed, updating base layer:", provider.name);
            updateBaseLayer(mapInstanceRef.current, provider);
        }
    }, [provider, updateBaseLayer]);

    // Update displayed drawings effect (keep)
     useEffect(() => {
         if (mapInstanceRef.current && drawnItemsRef.current) {
             updateDisplayedDrawings(drawnItemsRef.current, drawings);
         }
     }, [drawings, updateDisplayedDrawings]);

     // Keep isDrawingToolActive effect (if needed for external control)
     useEffect(() => {
        // console.log("MapComponent: isDrawingToolActive prop changed:", isDrawingToolActive);
     }, [isDrawingToolActive]);

    return (
        <Box
            ref={mapContainerRef}
            sx={{
                height: '65vh', minHeight: 500, width: '100%',
                border: '1px solid #ccc', borderRadius: 1,
                position: 'relative', overflow: 'hidden', zIndex: 1,
                backgroundColor: '#f0f0f0'
            }}
        >
            {/* Drawing Mode Indicator */}
             {isDrawingToolActive && (
                 <Box sx={{ position: 'absolute', top: 10, left: '50%', transform: 'translateX(-50%)', zIndex: 1001, background: 'rgba(0,0,0,0.6)', color: 'white', padding: '4px 10px', borderRadius: '4px', fontSize: '0.9rem', pointerEvents: 'none' }}>
                     Drawing Mode Active...
                 </Box>
             )}
        </Box>
    );
});

export default MapComponent;
