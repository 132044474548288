import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Grid,
  Divider,
  Box,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import {
  Category as CategoryIcon,
  LocationOn as LocationIcon,
  TimeToLeave as VehicleIcon,
  Error as WarningIcon,
  InfoOutlined as InfoIcon
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  detailItem: {
    marginBottom: theme.spacing(1),
  },
  detailLabel: {
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
  },
  detailValue: {
    color: theme.palette.text.primary,
  },
  warningChip: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.contrastText,
    margin: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  compatibilityTable: {
    marginTop: theme.spacing(2),
  },
  actionButton: {
    margin: theme.spacing(1),
  },
}));

const InventoryItemDetailsDialog = ({ open, onClose, item, onEdit, onDelete }) => {
  const classes = useStyles();

  if (!item) return null;

  const needsReorder = item.quantity <= item.minStockLevel;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">{item.title}</Typography>
          </Grid>
          <Grid item>
            {needsReorder && (
              <Chip
                icon={<WarningIcon />}
                label="Low Stock"
                className={classes.warningChip}
                size="small"
              />
            )}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {/* Basic Details */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box className={classes.detailItem}>
              <Typography variant="body2" className={classes.detailLabel}>
                Barcode
              </Typography>
              <Typography variant="body1" className={classes.detailValue}>
                {item.barcode || 'N/A'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={classes.detailItem}>
              <Typography variant="body2" className={classes.detailLabel}>
                Part Number
              </Typography>
              <Typography variant="body1" className={classes.detailValue}>
                {item.partNumber || 'N/A'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={classes.detailItem}>
              <Typography variant="body2" className={classes.detailLabel}>
                Brand
              </Typography>
              <Typography variant="body1" className={classes.detailValue}>
                {item.brand || 'N/A'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={classes.detailItem}>
              <Typography variant="body2" className={classes.detailLabel}>
                Model
              </Typography>
              <Typography variant="body1" className={classes.detailValue}>
                {item.model || 'N/A'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.detailItem}>
              <Typography variant="body2" className={classes.detailLabel}>
                Description
              </Typography>
              <Typography variant="body1" className={classes.detailValue}>
                {item.description || 'No description available'}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        {/* Category and Location */}
        <Typography variant="h6" className={classes.sectionTitle}>
          <CategoryIcon className={classes.icon} />
          Classification
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box className={classes.detailItem}>
              <Typography variant="body2" className={classes.detailLabel}>
                Category
              </Typography>
              <Typography variant="body1" className={classes.detailValue}>
                {item.category?.name || 'N/A'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={classes.detailItem}>
              <Typography variant="body2" className={classes.detailLabel}>
                Subcategory
              </Typography>
              <Typography variant="body1" className={classes.detailValue}>
                {item.subcategory || 'N/A'}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Typography variant="h6" className={classes.sectionTitle}>
          <LocationIcon className={classes.icon} />
          Storage Details
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Box className={classes.detailItem}>
              <Typography variant="body2" className={classes.detailLabel}>
                Quantity in Stock
              </Typography>
              <Typography variant="body1" className={classes.detailValue}>
                {item.quantity}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box className={classes.detailItem}>
              <Typography variant="body2" className={classes.detailLabel}>
                Minimum Stock Level
              </Typography>
              <Typography variant="body1" className={classes.detailValue}>
                {item.minStockLevel}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box className={classes.detailItem}>
              <Typography variant="body2" className={classes.detailLabel}>
                Storage Location
              </Typography>
              <Typography variant="body1" className={classes.detailValue}>
                {item.location || 'N/A'}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        {/* Supplier Information */}
        <Typography variant="h6" className={classes.sectionTitle}>
          <InfoIcon className={classes.icon} />
          Supplier & Ordering Information
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Box className={classes.detailItem}>
              <Typography variant="body2" className={classes.detailLabel}>
                Preferred Supplier
              </Typography>
              <Typography variant="body1" className={classes.detailValue}>
                {item.preferredSupplier || 'N/A'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box className={classes.detailItem}>
              <Typography variant="body2" className={classes.detailLabel}>
                Supplier Part Number
              </Typography>
              <Typography variant="body1" className={classes.detailValue}>
                {item.supplierPartNumber || 'N/A'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box className={classes.detailItem}>
              <Typography variant="body2" className={classes.detailLabel}>
                Cost Price
              </Typography>
              <Typography variant="body1" className={classes.detailValue}>
                {item.costPrice ? `$${item.costPrice.toFixed(2)}` : 'N/A'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={classes.detailItem}>
              <Typography variant="body2" className={classes.detailLabel}>
                Last Ordered
              </Typography>
              <Typography variant="body1" className={classes.detailValue}>
                {item.lastOrdered 
                  ? new Date(item.lastOrdered).toLocaleDateString() 
                  : 'N/A'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={classes.detailItem}>
              <Typography variant="body2" className={classes.detailLabel}>
                Last Used
              </Typography>
              <Typography variant="body1" className={classes.detailValue}>
                {item.lastUsed 
                  ? new Date(item.lastUsed).toLocaleDateString() 
                  : 'N/A'}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Vehicle Compatibility */}
        {item.compatibleVehicles && item.compatibleVehicles.length > 0 && (
          <>
            <Divider className={classes.divider} />
            <Typography variant="h6" className={classes.sectionTitle}>
              <VehicleIcon className={classes.icon} />
              Compatible Vehicles
            </Typography>
            <TableContainer component={Paper} className={classes.compatibilityTable}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Make</TableCell>
                    <TableCell>Model</TableCell>
                    <TableCell>Year</TableCell>
                    <TableCell>Notes</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item.compatibleVehicles.map((vehicle, index) => (
                    <TableRow key={index}>
                      <TableCell>{vehicle.make || 'N/A'}</TableCell>
                      <TableCell>{vehicle.model || 'N/A'}</TableCell>
                      <TableCell>{vehicle.year || 'N/A'}</TableCell>
                      <TableCell>{vehicle.notes || 'N/A'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

        {/* Maintenance Information */}
        {(item.maintenanceInterval?.miles || item.maintenanceInterval?.months || item.replacementFrequency) && (
          <>
            <Divider className={classes.divider} />
            <Typography variant="h6" className={classes.sectionTitle}>
              <InfoIcon className={classes.icon} />
              Maintenance Information
            </Typography>
            <Grid container spacing={2}>
              {item.maintenanceInterval?.miles && (
                <Grid item xs={12} sm={4}>
                  <Box className={classes.detailItem}>
                    <Typography variant="body2" className={classes.detailLabel}>
                      Maintenance Interval (Miles)
                    </Typography>
                    <Typography variant="body1" className={classes.detailValue}>
                      {item.maintenanceInterval.miles.toLocaleString()} miles
                    </Typography>
                  </Box>
                </Grid>
              )}
              {item.maintenanceInterval?.months && (
                <Grid item xs={12} sm={4}>
                  <Box className={classes.detailItem}>
                    <Typography variant="body2" className={classes.detailLabel}>
                      Maintenance Interval (Months)
                    </Typography>
                    <Typography variant="body1" className={classes.detailValue}>
                      {item.maintenanceInterval.months} months
                    </Typography>
                  </Box>
                </Grid>
              )}
              {item.replacementFrequency && (
                <Grid item xs={12} sm={4}>
                  <Box className={classes.detailItem}>
                    <Typography variant="body2" className={classes.detailLabel}>
                      Replacement Frequency
                    </Typography>
                    <Typography variant="body1" className={classes.detailValue}>
                      {item.replacementFrequency}
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          </>
        )}

        {/* Notes */}
        {item.notes && (
          <>
            <Divider className={classes.divider} />
            <Typography variant="h6" className={classes.sectionTitle}>
              <InfoIcon className={classes.icon} />
              Notes
            </Typography>
            <Typography variant="body1">
              {item.notes}
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
        <Button 
          onClick={() => onEdit(item)}
          color="primary" 
          variant="outlined"
          className={classes.actionButton}
        >
          Edit
        </Button>
        <Button 
          onClick={() => onDelete(item)}
          color="secondary" 
          variant="outlined"
          className={classes.actionButton}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InventoryItemDetailsDialog;
