import React from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  root: {
    // Use theme variables
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    // ... more styles
  },
  paddedStuff: {
    padding: '10px'
  }
  // ... more classes
}));



const VariableBilling = (props) => {
  // console.log('Variable Billing ', props)
  const classes=useStyles()
  const state = props.state
  const setState = props.setState

  const handleChange = (e) => {
    // console.log('UPdate state::', e.target.value)
    // console.log('id', e.target.id)
    // console.log('State before update', state)
    let newState = {...state }
    let newOpts = {...newState.variableOptions, [e.target.id]: e.target.value}
    // console.log('Opts ', newOpts)
    newState.variableOptions = newOpts
   setState({...state, ...newState})
  }

  console.log('State 40::: ', state)
  // console.log('props.months', props.months)
  let monthsToSend = (state.variableOptions && state.variableOptions.variableDetails && state.variableOptions.variableDetails.length) ? state.variableOptions.variableDetails : props.months
  // console.log('Send the months to send', monthsToSend)
  return (

    <Grid container>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <TextField
          id="weeklyPrice"
          label="Weekly Price"
          type="number"
          variant="outlined"
          fullWidth
          className={classes.paddedStuff}
          value={state.variableOptions.weeklyPrice}
          onChange={handleChange}
          helperText={`What is the rate when we are on Weekly visits?`}
        />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <TextField
          id="weeklyMins"
          label="Weekly Est Minutes"
          type="number"
          variant="outlined"
          fullWidth
          className={classes.paddedStuff}
          value={state.variableOptions.weeklyMins}
          onChange={handleChange}
          helperText={`How many minutes are allotted when we are on Weekly visits?`}
        />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <TextField
          id="biWeeklyPrice"
          label="Bi-Weekly Price"
          type="number"
          variant="outlined"
          fullWidth
          className={classes.paddedStuff}
          value={state.variableOptions.biWeeklyPrice}
          onChange={handleChange}
          helperText={`What is the rate when we are on Bi-Weekly visits?`}
        />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <TextField
          id="biWeeklyMins"
          label="Bi-Weekly Est Minutes"
          type="number"
          variant="outlined"
          fullWidth
          className={classes.paddedStuff}
          value={state.variableOptions.biWeeklyMins}
          onChange={handleChange}
          helperText={`How many minutes are allotted when we are on Bi-Weekly visits?`}
        />
      </Grid>
      <MonthOptions state={state} setState={setState} months={monthsToSend} />
    </Grid>
  )
}

const MonthOptions = ({ state, setState, months }) => {
  // console.log('State', state)
  // console.log('Setstate', setStat)
  console.log(months)
  const classes = useStyles()
  
  const handleChange = (e) => {
    // console.log('Id', e.target.id)
    // console.log('value', e.target.value)
    // console.log('New Opt', newOpt)
    const newOpt = e.target.value
    let parts = newOpt.split('_')
    let abbr = parts[0]
    let val = parts[1]
    let newState = {...state}
    // console.log('State 76', state)
    
    const monthIndex = months.findIndex(item => (item.abbr === abbr))
    // console.log('Month index', monthIndex)

    let newOpts = {...state.variableOptions}
    let newArr = []
    if (newOpts && newOpts.variableDetails && newOpts.variableDetails.length) {
      newArr = newOpts.variableDetails
    } else {
      newArr = months
    }
    if (monthIndex > -1) {
      // newState.variableDetails[monthIndex]
      newArr[monthIndex] = {
        ...newArr[monthIndex],
        frequency: val
      }
      // newArr

    }
    newOpts.variableDetails = newArr
    // newOpts
    // console.log('New opts', newOpts)
    newState.variableOptions = newOpts
    // console.log('Set state', newState)
    setState(newState)
  }

  return (
    <Grid container>
      {
        months.map((monthObject, index) => {
          // console.log('Month Object', monthObject)
          const freq = monthObject.frequency.toLowerCase() === 'none' ? `${monthObject.abbr}_none` : (monthObject.frequency === 'weekly') ? `${monthObject.abbr}_weekly` : `${monthObject.abbr}_biWeekly`
          // console.log('Month Object', monthObject)
          return (
            <Grid item lg={4} key={index}>
              <TextField
                id={`${monthObject.abbr}Frequency`}
                label={`${monthObject.name} Frequency`}
                fullWidth
                className={classes.paddedStuff}
                select
                variant="standard"
                value= {freq}
                // onChange={ e => setJanPrice(e.target.value) }
                onChange={ handleChange }
                // onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
              >
                <MenuItem value={`${monthObject.abbr}_weekly`}>Weekly</MenuItem>
                <MenuItem value={`${monthObject.abbr}_biWeekly`}>Bi-Weekly</MenuItem>
                <MenuItem value={`${monthObject.abbr}_none`}>None</MenuItem>
              </TextField>
            </Grid>
          )
        })
      }
     
    </Grid>
  )
}


export default React.memo(VariableBilling)