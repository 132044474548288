import React, { useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import FreeDraw, { CREATE, NONE } from 'leaflet-freedraw';
import 'leaflet/dist/leaflet.css';

function DualDrawingModes() {
    const mapContainerRef = useRef(null);
    const mapRef = useRef(null);
    const freeDrawRef = useRef(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [drawingMode, setDrawingMode] = useState('polygon'); // 'polygon' or 'line'
    const drawnItemsRef = useRef([]);

    useEffect(() => {
        if (mapRef.current || !mapContainerRef.current) return; // Initialize only once

        // Initialize map
        mapRef.current = L.map(mapContainerRef.current).setView([33.55, -112.0], 13);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '© OpenStreetMap contributors'
        }).addTo(mapRef.current);

        // Create the FreeDraw instance in NONE mode initially
        freeDrawRef.current = new FreeDraw({
            mode: NONE,
            syncOnDrag: false,
            smoothFactor: 2
        });

        // Add FreeDraw to map
        mapRef.current.addLayer(freeDrawRef.current);

        // Setup event listeners
        setupEventListeners();

        console.log("Map and FreeDraw initialized");
        return () => {
            if (mapRef.current) {
                mapRef.current.remove();
                mapRef.current = null;
            }
        };
    }, []);

    const onDrawCreated = () => {
        console.log('onDrawCreated')
    }

    const onDrawEdited = () => {
        console.log('onDrawEdited')
    }
    const onDrawStart = () => {
        console.log('onDrawStart')
    }
    const onDrawDeleted = () => {
        console.log('onDrawDeleted')
    }

    // Setup event listeners for drawing
    const setupEventListeners = () => {
        if (!freeDrawRef.current) return;
        
        freeDrawRef.current.on('markers', function(event) {
            console.log('FreeDraw Markers Event:', event.latLngs);
            
            if (event.latLngs && event.latLngs.length > 0) {
                event.latLngs.forEach(latLngs => {
                    if (drawingMode === 'polygon') {
                        // Create a polygon (closed shape)
                        const polygon = L.polygon(latLngs, { 
                            color: 'red',
                            weight: 3,
                            opacity: 0.8,
                            fillColor: '#f03',
                            fillOpacity: 0.3
                        }).addTo(mapRef.current);
                        drawnItemsRef.current.push({ type: 'polygon', layer: polygon });
                        console.log('Added polygon to map');
                    } else if (drawingMode === 'line') {
                        // For line mode, we need to convert the polygon to a polyline
                        // This is the key change - we create a polyline instead
                        
                        // Create a polyline (open shape) using the same points
                        // But don't connect back to the first point
                        const polyline = L.polyline(latLngs, { 
                            color: 'blue',
                            weight: 3,
                            opacity: 0.8
                        }).addTo(mapRef.current);
                        
                        drawnItemsRef.current.push({ type: 'line', layer: polyline });
                        console.log('Added line to map');
                    }
                });
            }
           

            // Set mode back to NONE by recreating the instance
            if (freeDrawRef.current && mapRef.current) {
                mapRef.current.removeLayer(freeDrawRef.current);
                freeDrawRef.current = new FreeDraw({
                    mode: NONE,
                    syncOnDrag: false,
                    smoothFactor: 2
                });
                mapRef.current.addLayer(freeDrawRef.current);
                setupEventListeners(); // Re-attach event listeners
                setIsDrawing(false);
            }
        });
        const map = mapRef.current
        map.on('zoomend moveend', () => { if (onMapViewChange && mapInstanceRef.current) { /* ... */ } });
        map.on(L.Draw.Event.CREATED, (event) => onDrawCreated && onDrawCreated(event));
        map.on(L.Draw.Event.EDITED, (event) => onDrawEdited && onDrawEdited(event));
        map.on(L.Draw.Event.DELETED, (event) => onDrawDeleted && onDrawDeleted(event));
        map.on('draw:drawstart', (event) => onDrawStart && onDrawStart(event));
        map.on('draw:drawstop', (event) => onDrawStop && onDrawStop(event));
        map.on('draw:editstart', (event) => onDrawStart && onDrawStart({...event, layerType: 'edit'}));
        map.on('draw:editstop', (event) => onDrawStop && onDrawStop({...event, layerType: 'edit'}));
        map.on('draw:deletestart', (event) => onDrawStart && onDrawStart({...event, layerType: 'delete'}));
        map.on('draw:deletestop', (event) => onDrawStop && onDrawStop({...event, layerType: 'delete'}));
    };

    // Toggle drawing mode
    const toggleDrawMode = () => {
        if (!freeDrawRef.current || !mapRef.current) return;
        
        if (!isDrawing) {
            // Activate drawing mode
            console.log(`Activating ${drawingMode} drawing mode`);
            mapRef.current.removeLayer(freeDrawRef.current);
            
            // Always use FreeDraw with CREATE mode, but interpret the result differently
            freeDrawRef.current = new FreeDraw({
                mode: CREATE,
                syncOnDrag: false,
                smoothFactor: 2,
                // We can customize the appearance during drawing
                polylineOptions: {
                    color: drawingMode === 'polygon' ? '#f03' : 'blue',
                    weight: 3,
                    opacity: 0.8
                }
            });
            
            mapRef.current.addLayer(freeDrawRef.current);
            setupEventListeners();
            setIsDrawing(true);
        } else {
            // Deactivate drawing mode
            console.log("Deactivating drawing mode");
            mapRef.current.removeLayer(freeDrawRef.current);
            freeDrawRef.current = new FreeDraw({
                mode: NONE,
                syncOnDrag: false,
                smoothFactor: 2
            });
            mapRef.current.addLayer(freeDrawRef.current);
            setupEventListeners();
            setIsDrawing(false);
        }
    };

    // Switch between drawing modes
    const changeDrawingMode = (mode) => {
        if (isDrawing) {
            // If currently drawing, cancel first
            mapRef.current.removeLayer(freeDrawRef.current);
            freeDrawRef.current = new FreeDraw({
                mode: NONE,
                syncOnDrag: false,
                smoothFactor: 2
            });
            mapRef.current.addLayer(freeDrawRef.current);
            setupEventListeners();
            setIsDrawing(false);
        }
        
        setDrawingMode(mode);
        console.log(`Drawing mode changed to: ${mode}`);
    };

    // Clear all drawings
    const clearDrawings = () => {
        drawnItemsRef.current.forEach(item => {
            if (mapRef.current && item.layer) {
                mapRef.current.removeLayer(item.layer);
            }
        });
        drawnItemsRef.current = [];
        
        if (freeDrawRef.current) {
            freeDrawRef.current.clear();
        }
        
        console.log('All drawings cleared');
    };

    return (
        <div>
            <div className="controls" style={{ marginBottom: '10px' }}>
                <div style={{ marginBottom: '10px' }}>
                    <span style={{ marginRight: '10px' }}>Drawing Mode:</span>
                    <button 
                        onClick={() => changeDrawingMode('polygon')} 
                        style={{ 
                            marginRight: '10px',
                            backgroundColor: drawingMode === 'polygon' ? '#e0e0e0' : '#fff',
                            padding: '5px 10px',
                            border: '1px solid #ccc',
                            borderRadius: '4px'
                        }}
                    >
                        Polygon
                    </button>
                    <button 
                        onClick={() => changeDrawingMode('line')} 
                        style={{ 
                            backgroundColor: drawingMode === 'line' ? '#e0e0e0' : '#fff',
                            padding: '5px 10px',
                            border: '1px solid #ccc',
                            borderRadius: '4px'
                        }}
                    >
                        Line
                    </button>
                </div>
                
                <button 
                    onClick={toggleDrawMode} 
                    style={{ 
                        marginRight: '10px',
                        padding: '5px 10px',
                        backgroundColor: isDrawing ? '#ffcccc' : '#ccffcc',
                        border: '1px solid #999',
                        borderRadius: '4px'
                    }}
                >
                    {isDrawing 
                        ? `Cancel ${drawingMode === 'polygon' ? 'Polygon' : 'Line'} Drawing` 
                        : `Draw ${drawingMode === 'polygon' ? 'Polygon' : 'Line'}`}
                </button>
                
                <button 
                    onClick={clearDrawings}
                    style={{ 
                        padding: '5px 10px',
                        border: '1px solid #ccc',
                        borderRadius: '4px'
                    }}
                >
                    Clear All
                </button>
                
                <div style={{ marginTop: '5px' }}>
                    Status: {isDrawing ? `Drawing ${drawingMode}` : 'Not drawing'}
                </div>
            </div>
            
            <div 
                ref={mapContainerRef} 
                style={{ height: '400px', width: '100%', border: '1px solid #ccc' }}
            ></div>
            
            <div style={{ marginTop: '10px', padding: '10px', backgroundColor: '#f5f5f5', borderRadius: '4px' }}>
                <p><strong>How it works:</strong></p>
                <ul>
                    <li>In <strong>Polygon mode</strong>, drawings are created as closed shapes with fill.</li>
                    <li>In <strong>Line mode</strong>, drawings appear as open paths without fill.</li>
                    <li>FreeDraw automatically completes the drawing when you release the mouse.</li>
                    <li>Use "Cancel Drawing" to exit drawing mode without completing.</li>
                </ul>
            </div>
        </div>
    );
}

export default DualDrawingModes;

