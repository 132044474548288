import React, { useState, useEffect } from 'react';
import { 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  TablePagination,
  Typography,
  Card,
  CardContent,
  Chip,
  IconButton,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import InfoIcon from '@material-ui/icons/Info';
import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
import { connect } from 'react-redux'

// Define styles
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableContainer: {
    maxHeight: 'calc(100vh - 280px)',
  },
  header: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  title: {
    fontWeight: 'bold',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  itemTypeEmployee: {
    backgroundColor: theme.palette.info.light,
  },
  itemTypeEquipment: {
    backgroundColor: theme.palette.success.light,
  },
  filterSection: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  searchField: {
    marginRight: theme.spacing(2),
  },
  detailsColumn: {
    maxWidth: 250,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  infoButton: {
    padding: 4,
  },
  crewDetailsText: {
    fontSize: '0.9em',
    fontWeight: 400,
    color: '#999'
  }
}));

// ScanLog details modal component could be added here

const ScanLogsTable = (props) => {
  console.log('Scan Logs...', props)
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [scanLogs, setScanLogs] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    search: '',
    itemType: '',
    location: '',
    startDate: '',
    endDate: '',
  });
  const [locations, setLocations] = useState([]);
  const [itemTypes, setItemTypes] = useState([]);
  const [expandedFilters, setExpandedFilters] = useState(false);

  // Fetch scan logs from API
  const fetchScanLogs = async () => {
    setLoading(true);
    try {
      // Build query parameters for filtering and pagination
      const queryParams = new URLSearchParams({
        page: page + 1, // API pagination typically starts from 1
        limit: rowsPerPage,
        sort: '-scannedAt', // Sort by scannedAt in descending order (newest first)
        ...Object.fromEntries(Object.entries(filters).filter(([_, v]) => v)),
      });

      const response = await fetch(`${props.url}/api/company/getscanlogs?${queryParams}`);
      const data = await response.json();
      
      setScanLogs(data.logs || []);
      setTotalCount(data.totalCount || 0);
    } catch (error) {
      console.error('Error fetching scan logs:', error);
      // Handle error (show notification etc.)
    } finally {
      setLoading(false);
    }
  };

  // Fetch filter options (locations, item types)
  const fetchFilterOptions = async () => {
    try {
      const locationsResponse = await fetch('/api/locations');
      const locationsData = await locationsResponse.json();
      setLocations(locationsData || []);

      // Fetch distinct item types
      const itemTypesResponse = await fetch('/api/scan-logs/item-types');
      const itemTypesData = await itemTypesResponse.json();
      setItemTypes(itemTypesData || []);
    } catch (error) {
      console.error('Error fetching filter options:', error);
    }
  };

  // Load data on component mount and when filters or pagination changes
  useEffect(() => {
    fetchScanLogs();
  }, [page, rowsPerPage, filters]);

  useEffect(() => {
    fetchFilterOptions();
  }, []);

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handle filter changes
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
    setPage(0); // Reset to first page when filter changes
  };

  // Clear all filters
  const clearFilters = () => {
    setFilters({
      search: '',
      itemType: '',
      location: '',
      startDate: '',
      endDate: '',
    });
    setPage(0);
  };

  // Format date for display
  const formatDate = (dateString) => {
    try {
      return format(new Date(dateString), 'MMM dd, yyyy HH:mm');
    } catch (e) {
      return 'Invalid date';
    }
  };

  // Get chip color based on item type
  const getItemTypeClass = (itemType) => {
    switch (itemType) {
      case 'Employee':
        return classes.itemTypeEmployee;
      case 'Equipment':
        return classes.itemTypeEquipment;
      default:
        return '';
    }
  };

  console.log(scanLogs)

  return (
    <div className={classes.root}>
      <Card>
        <div className={classes.header}>
          <Typography variant="h5" className={classes.title}>
            Scan Logs
          </Typography>
          <IconButton 
            color="inherit" 
            onClick={() => setExpandedFilters(!expandedFilters)}
          >
            <FilterListIcon />
          </IconButton>
        </div>

        {expandedFilters && (
          <Card className={classes.filterSection}>
            <CardContent>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    label="Search"
                    name="search"
                    value={filters.search}
                    onChange={handleFilterChange}
                    InputProps={{
                      startAdornment: <SearchIcon color="action" />,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth>
                    <InputLabel>Item Type</InputLabel>
                    <Select
                      name="itemType"
                      value={filters.itemType}
                      onChange={handleFilterChange}
                    >
                      <MenuItem value="">All Types</MenuItem>
                      {itemTypes.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth>
                    <InputLabel>Location</InputLabel>
                    <Select
                      name="location"
                      value={filters.location}
                      onChange={handleFilterChange}
                    >
                      <MenuItem value="">All Locations</MenuItem>
                      {locations.map((location) => (
                        <MenuItem key={location} value={location}>
                          {location}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    label="Start Date"
                    type="date"
                    name="startDate"
                    value={filters.startDate}
                    onChange={handleFilterChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    label="End Date"
                    type="date"
                    name="endDate"
                    value={filters.endDate}
                    onChange={handleFilterChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Box display="flex" justifyContent="flex-start">
                    <Chip 
                      label="Clear Filters" 
                      onClick={clearFilters} 
                      variant="outlined" 
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}

        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table} stickyHeader aria-label="scan logs table">
            <TableHead>
              <TableRow>
                <TableCell>Item Type</TableCell>
                <TableCell>Item Details</TableCell>
                <TableCell>Scanned At</TableCell>
                <TableCell>Scanned By</TableCell>
                <TableCell>Location</TableCell>
                {/* <TableCell>Additional Details</TableCell> */}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    Loading...
                  </TableCell>
                </TableRow>
              ) : scanLogs.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No scan logs found
                  </TableCell>
                </TableRow>
              ) : (
                scanLogs.map((log) => (
                  <TableRow key={log._id} hover>
                    <TableCell>
                      <Chip 
                        label={log.itemType} 
                        className={`${classes.chip} ${getItemTypeClass(log.itemType)}`}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>{log.itemDetails?.name || 'Not available'}</TableCell>
                    <TableCell>{formatDate(log.scannedAt)}</TableCell>
                    <TableCell>{log.scannedBy?.name || 'Unknown'}
                      {log?.crewId && <CrewDetails crew={log.crewId} crews={props.crews} classes={classes} /> }
                    </TableCell>
                    
                    <TableCell>{log.location}</TableCell>
                    {/* <TableCell className={classes.detailsColumn}>
                      {log.additionalDetails ? 
                        JSON.stringify(log.additionalDetails).substring(0, 50) + 
                        (JSON.stringify(log.additionalDetails).length > 50 ? '...' : '')
                        : 'None'
                      }
                    </TableCell> */}
                    <TableCell>
                      <IconButton 
                        className={classes.infoButton}
                        // onClick={() => handleViewDetails(log)}
                      >
                        <InfoIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Card>
    </div>
  );
};

const CrewDetails = ({ crews, crew, classes }) => {
  const crewChosen = crews.find(item => (item?._id?.toString() === crew?.toString()))
  if (crewChosen) {
    return (
      <div className={classes.crewDetailsText}>
        {crewChosen.name} ({crewChosen?.foremanName || 'N/A'})
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  // console.log('Mat to customer: ', state)
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    perms: state.perms,
    crews: state.crews
  }
}

export default connect(mapStateToProps)(ScanLogsTable)

