import React, { useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import html2canvas from 'html2canvas';
import { getStroke } from 'perfect-freehand';

function FreehandDrawing() {
    const mapContainerRef = useRef(null);
    const mapRef = useRef(null);
    const [mapImage, setMapImage] = useState(null);
    const canvasRef = useRef(null);
    const [drawing, setDrawing] = useState(false);
    const [strokes, setStrokes] = useState([]);
    const [lineColor, setLineColor] = useState("#ff0000");
    const [lineWidth, setLineWidth] = useState(2);
    
    // Store color and width with each stroke
    const [strokeStyles, setStrokeStyles] = useState([]);

    useEffect(() => {
        if (mapRef.current || !mapContainerRef.current) return;

        // Initialize the map
        mapRef.current = L.map(mapContainerRef.current).setView([33.55, -112.0], 13);
        
        // Use a CORS-enabled tile server or one that matches your domain
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '© OpenStreetMap contributors',
            crossOrigin: 'anonymous' // Add this to request CORS headers
        }).addTo(mapRef.current);
    }, []);

    const captureMapImage = async () => {
        if (!mapContainerRef.current) return;
        
        // Option 1: Use html2canvas with proper settings
        const canvas = await html2canvas(mapContainerRef.current, {
            useCORS: true, // This tells html2canvas to request images with CORS
            allowTaint: true, // Allow potentially tainted images
            logging: true, // For debugging
            imageTimeout: 0 // No timeout for image loading
        });
        
        setMapImage(canvas.toDataURL());
    };

    const startDrawing = (e) => {
        setDrawing(true);
        const rect = canvasRef.current.getBoundingClientRect();
        
        // Add a new stroke
        const newStrokeIndex = strokes.length;
        setStrokes((prev) => [...prev, [[e.clientX - rect.left, e.clientY - rect.top]]]);
        
        // Store the current style settings with this stroke
        setStrokeStyles((prev) => [...prev, { color: lineColor, width: Number(lineWidth) }]);
    };

    const draw = (e) => {
        if (!drawing) return;
        const rect = canvasRef.current.getBoundingClientRect();
        setStrokes((prev) => {
            const newStrokes = [...prev];
            newStrokes[newStrokes.length - 1] = [...newStrokes[newStrokes.length - 1], [e.clientX - rect.left, e.clientY - rect.top]];
            return newStrokes;
        });
    };

    const stopDrawing = () => {
        setDrawing(false);
    };

    const clearDrawing = () => {
        setStrokes([]);
        setStrokeStyles([]);
    };

    const undoLastStroke = () => {
        if (strokes.length === 0) return;
        
        setStrokes(prev => prev.slice(0, -1));
        setStrokeStyles(prev => prev.slice(0, -1));
    };

    useEffect(() => {
        if (!canvasRef.current) return;
        const ctx = canvasRef.current.getContext('2d');
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

        // Draw each stroke with its own stored style
        strokes.forEach((stroke, index) => {
            if (stroke.length > 0) {
                const style = strokeStyles[index] || { color: "#ff0000", width: 2 };
                const smoothedStroke = getStroke(stroke, { 
                    size: style.width, 
                    thinning: 0.5, 
                    smoothing: 0.5 
                });
                
                ctx.beginPath();
                smoothedStroke.forEach(([x, y], i) => {
                    if (i === 0) ctx.moveTo(x, y);
                    else ctx.lineTo(x, y);
                });
                ctx.strokeStyle = style.color;
                ctx.lineWidth = style.width;
                ctx.stroke();
            }
        });
    }, [strokes, strokeStyles]);

    const saveDrawing = () => {
        if (!canvasRef.current) return;
        const finalCanvas = document.createElement("canvas");
        finalCanvas.width = canvasRef.current.width;
        finalCanvas.height = canvasRef.current.height;
        
        const ctx = finalCanvas.getContext("2d");

        // Draw the map image
        const img = new Image();
        img.onload = () => {
            ctx.drawImage(img, 0, 0, finalCanvas.width, finalCanvas.height);
            // Draw the user strokes on top
            ctx.drawImage(canvasRef.current, 0, 0);
            const finalImage = finalCanvas.toDataURL("image/png");

            // Trigger download
            const link = document.createElement("a");
            link.href = finalImage;
            link.download = "map_drawing.png";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };
        
        // Important: Set crossOrigin for the image
        img.crossOrigin = "anonymous";
        img.src = mapImage;
    };

    const resetToMap = () => {
        setStrokes([]);
        setStrokeStyles([]);
        setMapImage(null);
    };

    // Predefined color palette
    const colorPresets = [
        "#ff0000", // Red
        "#0000ff", // Blue
        "#00ff00", // Green
        "#ffff00", // Yellow
        "#ff00ff", // Magenta
        "#00ffff", // Cyan
        "#000000", // Black
        "#ffffff"  // White
    ];

    return (
        <div className="freehand-drawing-container">
            <div className="drawing-controls">
                {!mapImage ? (
                    <button 
                        onClick={captureMapImage} 
                        className="primary-button"
                    >
                        Capture Map & Draw
                    </button>
                ) : (
                    <div className="drawing-toolbar">
                        <div className="tool-section">
                            <button onClick={saveDrawing} className="primary-button">Download</button>
                            <button onClick={resetToMap} className="secondary-button">Back to Map</button>
                        </div>
                        
                        <div className="tool-section">
                            <button onClick={undoLastStroke} className="tool-button">Undo</button>
                            <button onClick={clearDrawing} className="tool-button">Clear All</button>
                        </div>
                        
                        <div className="tool-section style-controls">
                            <div className="color-picker">
                                <label>Color: </label>
                                <input 
                                    type="color" 
                                    value={lineColor} 
                                    onChange={(e) => setLineColor(e.target.value)} 
                                />
                                <div className="color-presets">
                                    {colorPresets.map((color, i) => (
                                        <div 
                                            key={i}
                                            className="color-preset"
                                            style={{ 
                                                backgroundColor: color,
                                                border: color === lineColor ? '2px solid #333' : '1px solid #ccc',
                                                outline: color === '#ffffff' ? '1px solid #ccc' : 'none'
                                            }}
                                            onClick={() => setLineColor(color)}
                                        />
                                    ))}
                                </div>
                            </div>
                            
                            <div className="width-control">
                                <label>Width: {lineWidth}</label>
                                <input 
                                    type="range" 
                                    min="1" 
                                    max="20" 
                                    value={lineWidth} 
                                    onChange={(e) => setLineWidth(e.target.value)} 
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            
            <div
                ref={mapContainerRef}
                style={{
                    height: '1400px',
                    width: '1800px',
                    border: '1px solid #ccc',
                    display: mapImage ? 'none' : 'block'
                }}
            />
            
            {mapImage && (
                <div style={{ position: "relative", width: "1800px", height: "1400px" }}>
                    <img 
                        src={mapImage} 
                        alt="Captured Map" 
                        crossOrigin="anonymous"
                        style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} 
                    />
                    <canvas
                        ref={canvasRef}
                        width={1800}
                        height={1400}
                        style={{ position: "absolute", top: 0, left: 0 }}
                        onMouseDown={startDrawing}
                        onMouseMove={draw}
                        onMouseUp={stopDrawing}
                        onMouseOut={stopDrawing}
                        onTouchStart={e => {
                            e.preventDefault();
                            const touch = e.touches[0];
                            startDrawing({
                                clientX: touch.clientX,
                                clientY: touch.clientY
                            });
                        }}
                        onTouchMove={e => {
                            e.preventDefault();
                            const touch = e.touches[0];
                            draw({
                                clientX: touch.clientX,
                                clientY: touch.clientY
                            });
                        }}
                        onTouchEnd={() => stopDrawing()}
                    />
                </div>
            )}
            
            <style>
                {`
                .freehand-drawing-container {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                }
                
                .drawing-controls {
                    margin-bottom: 10px;
                    padding: 10px;
                    background-color: #f5f5f5;
                    border: 1px solid #ddd;
                }
                
                .drawing-toolbar {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;
                    align-items: center;
                }
                
                .tool-section {
                    display: flex;
                    gap: 8px;
                    align-items: center;
                    margin-right: 15px;
                }
                
                button {
                    margin: 0 5px;
                    padding: 5px 10px;
                    cursor: pointer;
                }
                
                .color-presets {
                    display: flex;
                    gap: 4px;
                }
                
                .color-preset {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    cursor: pointer;
                    display: inline-block;
                    margin: 0 2px;
                }
                
                label {
                    margin-right: 5px;
                }
                `}
            </style>
        </div>
    );
}

export default FreehandDrawing;
