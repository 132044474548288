import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  IconButton,
  CircularProgress,
  Grid,
  Snackbar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  Select,
  Divider
} from '@material-ui/core';
import { 
  Search, 
  ExpandMore, 
  Check, 
  Close, 
  Add, 
  Edit, 
  FindInPage,
  ShoppingCart,
  PlaylistAddCheck,
  Help,
  ArrowBack
} from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { connect } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    marginBottom: theme.spacing(2),
  },
  statusChip: {
    margin: theme.spacing(0.5),
  },
  matched: {
    backgroundColor: theme.palette.success.light,
  },
  unidentified: {
    backgroundColor: theme.palette.warning.light,
  },
  error: {
    backgroundColor: theme.palette.error.light,
  },
  created: {
    backgroundColor: theme.palette.info.light,
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  formControl: {
    minWidth: 200,
    marginBottom: theme.spacing(2),
  },
  searchResult: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  potentialMatch: {
    padding: theme.spacing(1),
    margin: theme.spacing(1, 0),
    borderLeft: `4px solid ${theme.palette.info.main}`,
  },
  actionButtons: {
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  chipIcon: {
    marginRight: theme.spacing(1),
  }
}));

const AuditReview = (props) => {
  const classes = useStyles();
  const [audits, setAudits] = useState([]);
  const [selectedAudit, setSelectedAudit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState(''); // 'create', 'match', 'search'
  const [currentItem, setCurrentItem] = useState(null);
  const [newItemForm, setNewItemForm] = useState({
    title: '',
    description: '',
    brand: '',
    partNumber: '',
    category: '',
    subcategory: '',
    minStockLevel: 1
  });
  const [categories, setCategories] = useState([]);

  // Fetch audits that need review
  useEffect(() => {
    fetchAudits();
    fetchCategories();
  }, []);

  const fetchAudits = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${props.url}/api/shop/fetchAudits`);
      setAudits(response.data);
    } catch (error) {
      console.error('Error fetching audits:', error);
      setSnackbar({
        open: true,
        message: 'Failed to load audits',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${props.url}/api/shop/fetchCategories`);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleAuditSelect = (audit) => {
    setSelectedAudit(audit);
  };

  const getStatusChip = (status) => {
    let icon = <Help />;
    let label = status;
    let className = '';

    switch (status.toLowerCase()) {
      case 'matched':
        icon = <Check />;
        label = 'Matched';
        className = classes.matched;
        break;
      case 'unidentified':
      case 'pending':
        icon = <Help />;
        label = 'Needs Review';
        className = classes.unidentified;
        break;
      case 'created':
        icon = <Add />;
        label = 'Created';
        className = classes.created;
        break;
      case 'error':
        icon = <Close />;
        label = 'Error';
        className = classes.error;
        break;
      default:
        break;
    }

    return (
      <Chip
        icon={icon}
        label={label}
        className={`${classes.statusChip} ${className}`}
      />
    );
  };

  // Search for inventory items
  const handleSearch = async () => {
    if (!searchQuery || searchQuery.length < 3) {
      setSnackbar({
        open: true,
        message: 'Search query must be at least 3 characters',
        severity: 'warning'
      });
      return;
    }

    // setSearchLoading(true);
    // try {
    //   const response = await axios.get(`/api/audits/inventory/search?query=${searchQuery}`);
    //   setSearchResults(response.data);
    // } catch (error) {
    //   console.error('Error searching inventory:', error);
    //   setSnackbar({
    //     open: true,
    //     message: 'Search failed',
    //     severity: 'error'
    //   });
    // } finally {
    //   setSearchLoading(false);
    // }
  };

  // Handle selecting an existing item to match
  const handleSelectMatch = (inventoryItem) => {
    // First close the search dialog
    setDialogOpen(false);
    
    // Then open the confirmation dialog
    setDialogType('match');
    setDialogOpen(true);
    
    // Set the currentItem to include the selected inventory item
    setCurrentItem({
      ...currentItem,
      selectedInventoryItem: inventoryItem
    });
  };

  // Handle creating a new inventory item
  const handleNewItemChange = (e) => {
    const { name, value } = e.target;
    setNewItemForm({
      ...newItemForm,
      [name]: value
    });
  };

  // Open dialog for creating new item
  const handleOpenCreateDialog = (item) => {
    setCurrentItem(item);
    setNewItemForm({
      ...newItemForm,
      title: item.tempInfo?.title || '',
      partNumber: item.tempInfo?.partNumber || item.barcode || '',
      brand: item.tempInfo?.brand || ''
    });
    setDialogType('create');
    setDialogOpen(true);
  };

  // Open dialog for searching existing items
  const handleOpenSearchDialog = (item) => {
    setCurrentItem(item);
    setSearchQuery('');
    setSearchResults([]);
    setDialogType('search');
    setDialogOpen(true);
  };

  // Handle submitting a new item
  const handleCreateItem = async () => {
    if (!newItemForm.title || !newItemForm.category || !newItemForm.subcategory) {
      setSnackbar({
        open: true,
        message: 'Title, category, and subcategory are required',
        severity: 'warning'
      });
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        `/api/audits/${selectedAudit._id}/items/${currentItem._id}/review`,
        {
          action: 'create_new',
          data: newItemForm
        }
      );

      setSnackbar({
        open: true,
        message: 'Item created successfully',
        severity: 'success'
      });
      
      setDialogOpen(false);
      
      // Update the audit data
      await fetchAudits();
      
    } catch (error) {
      console.error('Error creating item:', error);
      setSnackbar({
        open: true,
        message: 'Failed to create item',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  // Handle matching to an existing item
  const handleMatchItem = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `/api/audits/${selectedAudit._id}/items/${currentItem._id}/review`,
        {
          action: 'match_existing',
          data: {
            inventoryItemId: currentItem.selectedInventoryItem._id
          }
        }
      );

      setSnackbar({
        open: true,
        message: 'Item matched successfully',
        severity: 'success'
      });
      
      setDialogOpen(false);
      
      // Update the audit data
      await fetchAudits();
      
    } catch (error) {
      console.error('Error matching item:', error);
      setSnackbar({
        open: true,
        message: 'Failed to match item',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  // Handle skipping an item
  const handleSkipItem = async (item) => {
    if (!window.confirm('Are you sure you want to skip this item? It will remain unidentified.')) {
      return;
    }
    
    setLoading(true);
    try {
      const response = await axios.post(
        `/api/audits/${selectedAudit._id}/items/${item._id}/review`,
        {
          action: 'skip',
          data: {
            reason: 'Manually skipped during review'
          }
        }
      );

      setSnackbar({
        open: true,
        message: 'Item skipped',
        severity: 'info'
      });
      
      // Update the audit data
      await fetchAudits();
      
    } catch (error) {
      console.error('Error skipping item:', error);
      setSnackbar({
        open: true,
        message: 'Failed to skip item',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  // Complete the audit
  const handleCompleteAudit = async () => {
    if (!window.confirm('Are you sure you want to complete this audit? This will update all inventory quantities.')) {
      return;
    }
    
    setLoading(true);
    try {
      const response = await axios.post(`/api/shop/audits/${selectedAudit._id}/complete`);
      console.log('Complete response', response)
      setSnackbar({
        open: true,
        message: 'Audit completed successfully',
        severity: 'success'
      });
      
      // Reset selected audit and refresh
      setSelectedAudit(null);
      await fetchAudits();
      
    } catch (error) {
      console.error('Error completing audit:', error);
      setSnackbar({
        open: true,
        message: 'Failed to complete audit',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  // Check if audit can be completed (no items needing review)
  const canCompleteAudit = () => {
    if (!selectedAudit) return false;
    
    const unidentifiedItems = selectedAudit.items.filter(
      item => item.status === 'unidentified' && item.needsReview
    );
    
    return unidentifiedItems.length === 0;
  };

  return (
    <div className={classes.root}>
      {loading && (
        <Box display="flex" justifyContent="center" alignItems="center" p={3}>
          <CircularProgress />
        </Box>
      )}
      
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar({...snackbar, open: false})}
      >
        <Alert onClose={() => setSnackbar({...snackbar, open: false})} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      
      {!selectedAudit ? (
        <Paper className={classes.paper}>
          <Typography variant="h5" className={classes.title}>
            Inventory Audits Requiring Review
          </Typography>
          
          {audits.length === 0 ? (
            <Box p={3} textAlign="center">
              <Typography>No audits requiring review</Typography>
            </Box>
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Audit Title</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Conducted By</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Items Needing Review</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {audits.map((audit) => {
                    const needsReviewCount = audit.items.filter(
                      item => item.status === 'unidentified' && item.needsReview
                    ).length;
                    
                    return (
                      <TableRow key={audit._id}>
                        <TableCell>{audit.title}</TableCell>
                        <TableCell>{new Date(audit.startDate).toLocaleDateString()}</TableCell>
                        <TableCell>{audit.conductorName}</TableCell>
                        <TableCell>{audit.status}</TableCell>
                        <TableCell>{needsReviewCount}</TableCell>
                        <TableCell>
                          <Button 
                            variant="contained" 
                            color="primary"
                            onClick={() => handleAuditSelect(audit)}
                          >
                            Review
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Paper>
      ) : (
        <>
          <Paper className={classes.paper}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6}>
                <Typography variant="h5">
                  {selectedAudit.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Conducted by: {selectedAudit.conductorName}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Date: {new Date(selectedAudit.startDate).toLocaleDateString()}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} container justifyContent="flex-end">
                <Button
                  variant="outlined"
                  color="default"
                  startIcon={<ArrowBack />}
                  onClick={() => setSelectedAudit(null)}
                  className={classes.actionButtons}
                >
                  Back to List
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<PlaylistAddCheck />}
                  onClick={handleCompleteAudit}
                  disabled={!canCompleteAudit()}
                  className={classes.actionButtons}
                >
                  Complete Audit
                </Button>
              </Grid>
            </Grid>
          </Paper>
          
          <Paper className={classes.paper}>
            <Typography variant="h6" className={classes.title}>
              Items Needing Review
            </Typography>
            
            {selectedAudit.items.filter(item => item.status === 'unidentified' && item.needsReview).length === 0 ? (
              <Box p={3} textAlign="center">
                <Typography>
                  No items need review! You can complete the audit.
                </Typography>
              </Box>
            ) : (
              <TableContainer className={classes.tableContainer}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Barcode</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Potential Matches</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedAudit.items
                      .filter(item => item.status === 'unidentified' && item.needsReview)
                      .map((item) => (
                        <TableRow key={item._id}>
                          <TableCell>{item.barcode}</TableCell>
                          <TableCell>{item.countedQuantity}</TableCell>
                          <TableCell>{getStatusChip(item.status)}</TableCell>
                          <TableCell>
                            {item.potentialMatches && item.potentialMatches.length > 0 ? (
                              <Accordion>
                                <AccordionSummary expandIcon={<ExpandMore />}>
                                  <Typography className={classes.accordionHeading}>
                                    {item.potentialMatches.length} Potential Matches
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Box width="100%">
                                    {item.potentialMatches.map((match, index) => (
                                      <Box key={index} className={classes.potentialMatch}>
                                        <Typography variant="subtitle2">
                                          {match.title || 'Unknown Item'}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                          Brand: {match.brand || 'Unknown'} | Part #: {match.partNumber || 'Unknown'}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                          Source: {match.source} | Confidence: {Math.round(match.confidence * 100)}%
                                        </Typography>
                                        <Button
                                          size="small"
                                          color="primary"
                                          onClick={() => {
                                            setNewItemForm({
                                              ...newItemForm,
                                              title: match.title || '',
                                              brand: match.brand || '',
                                              partNumber: match.partNumber || '',
                                              description: match.description || ''
                                            });
                                            handleOpenCreateDialog(item);
                                          }}
                                        >
                                          Use This Data
                                        </Button>
                                      </Box>
                                    ))}
                                  </Box>
                                </AccordionDetails>
                              </Accordion>
                            ) : (
                              <Typography variant="body2">No potential matches found</Typography>
                            )}
                          </TableCell>
                          <TableCell className={classes.actionButtons}>
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={<Add />}
                              onClick={() => handleOpenCreateDialog(item)}
                            >
                              Create New
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              startIcon={<FindInPage />}
                              onClick={() => handleOpenSearchDialog(item)}
                            >
                              Match Existing
                            </Button>
                            <Button
                              variant="outlined"
                              color="default"
                              startIcon={<Close />}
                              onClick={() => handleSkipItem(item)}
                            >
                              Skip
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Paper>
            
          <Paper className={classes.paper}>
            <Typography variant="h6" className={classes.title}>
              Matched Items
            </Typography>
            
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Item</TableCell>
                    <TableCell>Barcode</TableCell>
                    <TableCell>Part Number</TableCell>
                    <TableCell>Counted Quantity</TableCell>
                    <TableCell>System Quantity</TableCell>
                    <TableCell>Discrepancy</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedAudit.items
                    .filter(item => item.status === 'matched' || item.status === 'created')
                    .map((item) => (
                      <TableRow key={item._id}>
                        <TableCell>
                          {item.inventoryItem ? item.inventoryItem.title : 'Unknown Item'}
                        </TableCell>
                        <TableCell>{item.barcode}</TableCell>
                        <TableCell>
                          {item.inventoryItem ? item.inventoryItem.partNumber : ''}
                        </TableCell>
                        <TableCell>{item.countedQuantity}</TableCell>
                        <TableCell>
                          {item.inventoryItem ? item.inventoryItem.quantity : ''}
                        </TableCell>
                        <TableCell>
                          {item.inventoryItem && 
                            Math.abs(item.countedQuantity - item.inventoryItem.quantity)}
                        </TableCell>
                        <TableCell>{getStatusChip(item.status)}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </>
      )}
      
      {/* Dialog for creating a new item */}
      <Dialog 
        open={dialogOpen && dialogType === 'create'} 
        onClose={() => setDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Create New Inventory Item</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Title"
                name="title"
                value={newItemForm.title}
                onChange={handleNewItemChange}
                fullWidth
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Brand"
                name="brand"
                value={newItemForm.brand}
                onChange={handleNewItemChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Part Number"
                name="partNumber"
                value={newItemForm.partNumber}
                onChange={handleNewItemChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Min Stock Level"
                name="minStockLevel"
                type="number"
                value={newItemForm.minStockLevel}
                onChange={handleNewItemChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl} fullWidth margin="normal" required>
                <InputLabel>Category</InputLabel>
                <Select
                  name="category"
                  value={newItemForm.category}
                  onChange={handleNewItemChange}
                >
                  {categories.map((category) => (
                    <MenuItem key={category._id} value={category._id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Subcategory"
                name="subcategory"
                value={newItemForm.subcategory}
                onChange={handleNewItemChange}
                fullWidth
                margin="normal"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Description"
                name="description"
                value={newItemForm.description}
                onChange={handleNewItemChange}
                fullWidth
                margin="normal"
                multiline
                rows={3}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="default">
            Cancel
          </Button>
          <Button 
            onClick={handleCreateItem} 
            color="primary" 
            variant="contained"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Create Item'}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Dialog for searching existing items */}
      <Dialog 
        open={dialogOpen && dialogType === 'search'} 
        onClose={() => setDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Match to Existing Inventory Item</DialogTitle>
        <DialogContent>
          <Box display="flex" alignItems="center" mb={2}>
            <TextField
              label="Search inventory"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              fullWidth
              margin="normal"
              variant="outlined"
              placeholder="Search by title, brand, part number"
            />
            <Box ml={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSearch}
                disabled={searchLoading}
                startIcon={searchLoading ? <CircularProgress size={24} /> : <Search />}
              >
                Search
              </Button>
            </Box>
          </Box>
          
          <Divider />
          
          <Box mt={2}>
            {searchResults.length > 0 ? (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Title</TableCell>
                      <TableCell>Brand</TableCell>
                      <TableCell>Part #</TableCell>
                      <TableCell>Barcode</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchResults.map((item) => (
                      <TableRow key={item._id} className={classes.searchResult}>
                        <TableCell>{item.title}</TableCell>
                        <TableCell>{item.brand}</TableCell>
                        <TableCell>{item.partNumber}</TableCell>
                        <TableCell>{item.barcode}</TableCell>
                        <TableCell>{item.quantity}</TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => handleSelectMatch(item)}
                          >
                            Select
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Box p={3} textAlign="center">
                <Typography>
                  {searchQuery ? 'No results found' : 'Enter a search term to find inventory items'}
                </Typography>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="default">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Dialog for confirming a match */}
      <Dialog 
        open={dialogOpen && dialogType === 'match'} 
        onClose={() => setDialogOpen(false)}
      >
        <DialogTitle>Confirm Match</DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Are you sure you want to match this scanned item:
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            Barcode: {currentItem?.barcode}
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            Quantity: {currentItem?.countedQuantity}
          </Typography>
          
          <Divider style={{ margin: '16px 0' }} />
          
          <Typography gutterBottom>
            To this inventory item:
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            Title: {currentItem?.selectedInventoryItem?.title}
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            Brand: {currentItem?.selectedInventoryItem?.brand}
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            Part #: {currentItem?.selectedInventoryItem?.partNumber}
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            Current Quantity: {currentItem?.selectedInventoryItem?.quantity}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="default">
            Cancel
          </Button>
          <Button 
            onClick={handleMatchItem} 
            color="primary" 
            variant="contained"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Confirm Match'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    theme: state.theme,
    url: state.url
  }
}

export default connect(mapStateToProps)(AuditReview)
